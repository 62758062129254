
/// <reference path="MainViewController.ts" />

class DesktopViewController extends MainViewController 
{
    private workspaceIconView:MUIView = null;
    private workspaceButton:MUIButton = null;        
    private errorIcon:MUIView = null;
    private cautionIcon:MUIView = null;
    private activityIndicator:MUIActivityIndicatorView = null;
    private versionLabel:MUILabel = null;

    viewDidLoad() {
        super.viewDidLoad();   
        
        this.workspaceIconView = MUIOutlet(this, "workspace-icon", "MUIView");

        this.workspaceButton = MUIOutlet(this, "workspace-btn", "MUIButton");        
        this.workspaceButton.setAction(this, function () {
            AppHelper.sharedInstance().presentWorkspaceViewController(this);
        });

        this.activityIndicator = MUIOutlet(this, "sync-spinning", "MUIActivityIndicatorView");

        this.errorIcon = MUIOutlet(this, "error-icon", "MUIView");
        this.errorIcon.setHidden(true);

        this.cautionIcon = MUIOutlet(this, "caution-icon", "MUIView");
        this.cautionIcon.setHidden(true);

        this.activityIndicator = MUIOutlet(this, "sync-spinning", "MUIView");
        this.activityIndicator.setHidden(true);
        
        let data = MIOBundle.mainBundle().pathForResourceOfType("app", "plist");
        let config = MIOPropertyListSerialization.propertyListWithData(data, 0, 0, null);
        this.versionLabel = MUIOutlet(this, "version-lbl", "MUILabel");
        this.versionLabel.text = config["Version"];

        MIONotificationCenter.defaultCenter().addObserver(this, "WebServiceCommandBegin", this.webServiceCommandBeginNotification);
        MIONotificationCenter.defaultCenter().addObserver(this, "WebServiceCommandEnd", this.webServiceCommandEndNotification);

        MIONotificationCenter.defaultCenter().addObserver(this, "MWSRequestSentFetch", this.webServiceCommandBeginNotification);
        MIONotificationCenter.defaultCenter().addObserver(this, "MWSRequestReceivedFetch", this.webServiceCommandEndNotification);        

        MIONotificationCenter.defaultCenter().addObserver(this, "WebServiceCommandError", this.webServiceCommandErrorNotification);                
        MIONotificationCenter.defaultCenter().addObserver(this, "MWSRequestFetchError", this.webServiceCommandErrorNotification);

        MIONotificationCenter.defaultCenter().addObserver(this, "MWSPersistentStoreError", function(note:MIONotification){
            this.errorIcon.setHidden(false);
        });        
    }

    private executingCommands = 0;
    private webServiceCommandBeginNotification(notification:MIONotification){
        if (this.executingCommands == 0) this.activityIndicator.startAnimating();
        this.executingCommands++;
    }

    private webServiceCommandEndNotification(notification:MIONotification){
        this.executingCommands--;
        if (this.executingCommands == 0) this.activityIndicator.stopAnimating();
        
        if (this.executingCommands < 0) {
            this.executingCommands = 0;
            MIOLog("Warning: Finishing more web commands that were started");
        }
    }

    private webServiceCommandErrorNotification(notification:MIONotification){        
        let userInfo = notification.userInfo;
        if (userInfo != null && userInfo["Type"] == MWSRequestType.Save) {
            this.errorIcon.setHidden(false);
        }
        else {
            //this.cautionIcon.setHidden(false);
        }        
    }

    protected workspaceDidChange(business:Business){
        this.workspaceButton.setTitle('');
        this.workspaceIconView.layer.classList.remove(['place_icon_on', 'company_icon_on']);

        if (business != null) {
            if (business.type == BusinessType.Company) {
                this.workspaceIconView.layer.classList.remove('place_icon_on');
                this.workspaceIconView.layer.classList.add('company_icon_on');
            }
            else if (business.type == BusinessType.Place) {
                this.workspaceIconView.layer.classList.remove('company_icon_on');
                this.workspaceIconView.layer.classList.add('place_icon_on');
            }

            this.workspaceButton.setTitle(business.name);            
        }        
    }

    /*
    private supplierInvoiceViewController:MUISplitViewController = null;
    private supplierInvoicesButton:MUITabBarItem = null;
    private loadSupplierInvoicesViewController() {

        let mvc = new IssuedInvoiceListViewController("issued-invoice-list-view");
        mvc.mode = InvoiceType.IncomingInvoice;
        mvc.initWithResource("layout/issuedinvoice/IssuedInvoiceListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Invoice", NoItemSelectedViewControllerType.Invoice);

        this.supplierInvoiceViewController = new MUISplitViewController();
        this.supplierInvoiceViewController.init();
        this.supplierInvoiceViewController.setMasterViewController(mvc);
        this.supplierInvoiceViewController.setDetailViewController(dvc);

        let index = this._sectionsPageController.pageControllersCount;
        this._sectionsPageController.addPageViewController(this.supplierInvoiceViewController);

        this._supplierInvoicesButton = MUIOutlet(this, "dskv_supplier_invoices_tab_item", "MUITabBarItem");
        this._supplierInvoicesButton.setAction(this, function () {
            this._sectionsPageController.showPageAtIndex(index);
            MIOUserDefaults.standardUserDefaults().setValueForKey("LastDesktopViewSectionIndex", index);
        });
        this._supplierInvoicesButton.setHidden(true);
        this._toolbarButtons['99000000-0000-1111-1111-000000000032'] = this._supplierInvoicesButton;

    }

    private _loadLimitsViewController() {
        //LIMITS SECTION
        this._limitsViewController = new InvitationLimitsViewController("invitation-limits-view");
        this._limitsViewController.initWithResource("layout/invitations/InvitationLimitsView.html");

        let index = this._sectionsPageController.pageControllersCount;

        this._sectionsPageController.addPageViewController(this._limitsViewController);

        this._limitsButton = MUIOutlet(this, 'dskv_limits_tab_item', 'MUITabBarItem');
        this._limitsButton.setAction(this, function () {
            this._sectionsPageController.showPageAtIndex(index);
            MIOUserDefaults.standardUserDefaults().setValueForKey("LastDesktopViewSectionIndex", index);
        });
        this._limitsButton.setHidden(true);
        this._toolbarButtons['99000000-0000-1111-1111-000000000042'] = this._limitsButton;
    }

    private _loadSalesProfitViewController() {
        this._salesProfitViewController = new SalesProfitViewController('sales_margin_view');
        this._salesProfitViewController.initWithResource('layout/salesmargin/SalesMarginView.html');

        let index = this._sectionsPageController.pageControllersCount;

        this._sectionsPageController.addPageViewController(this._salesProfitViewController);

        this._salesProfitButton = MUIOutlet(this, 'dskv_sales_profit_tab_item', 'MUITabBarItem');
        this._salesProfitButton.setAction(this, function () {
            this._sectionsPageController.showPageAtIndex(index);
            MIOUserDefaults.standardUserDefaults().setValueForKey("LastDesktopViewSectionIndex", index);
        });
        this._salesProfitButton.setHidden(true);
        this._toolbarButtons['99000000-0000-1111-1111-000000000030'] = this._salesProfitButton;
    }

    private loadDesignerViewController() {
        //CONFIG SECTION

        this.editorViewController = new EditorViewController('editor_view');
        this.editorViewController.initWithResource("layout/editor/EditorView.html");

        let index = this._sectionsPageController.pageControllersCount;

        this._sectionsPageController.addPageViewController(this.editorViewController);

        this.editorButton = MUIOutlet(this, 'dskv_editor_tab_item', 'MUITabBarItem');
        this.editorButton.setAction(this, function () {
            this._sectionsPageController.showPageAtIndex(index);
            MIOUserDefaults.standardUserDefaults().setValueForKey("LastDesktopViewSectionIndex", index);
        });
        this.editorButton.setHidden(true);
        this._toolbarButtons['99000000-0000-1111-1111-000000000034'] = this.editorButton;
    }


    private loadBudgetViewController() {
        //CATEGORY SECTION

        this.budgetViewController = new MUIViewController('budget-view');
        this.budgetViewController.initWithResource("layout/budgets/BudgetView.html");

        let index = this._sectionsPageController.pageControllersCount;

        this._sectionsPageController.addPageViewController(this.budgetViewController);

        this._budgetButton = MUIOutlet(this, 'dskv_budget_tab_item', 'MUITabBarItem');
        this._budgetButton.setAction(this, function () {
            this._sectionsPageController.showPageAtIndex(index);
            MIOUserDefaults.standardUserDefaults().setValueForKey("LastDesktopViewSectionIndex", index);
        });
        this._budgetButton.setHidden(true);
        this._toolbarButtons['99000000-0000-1111-1111-000000000037'] = this._budgetButton;

    }
    

    private vipCardsViewController:MUISplitViewController = null;
    private vipCardsButton:MUITabBarItem = null;
    private loadVIPCardsViewController() {

        let mvc = new VIPCardListViewController('vip-cards-list-view');
        mvc.initWithResource("layout/vip-cards/VIPCardsListView.html");

        let dvc = new VIPCardDetailViewController("vip-cards-detail-view");
        dvc.initWithResource("layout/vip-cards/VIPCardsDetailView.html");

        this.vipCardsViewController = new MUISplitViewController();
        this.vipCardsViewController.init();
        this.vipCardsViewController.setMasterViewController(mvc);
        this.vipCardsViewController.setDetailViewController(dvc);

        let index = this._sectionsPageController.pageControllersCount;

        this._sectionsPageController.addPageViewController(this.vipCardsViewController);

        this.vipCardsButton = MUIOutlet(this, "dskv_vip_cards_tab_item", "MUITabBarItem");
        this.vipCardsButton.setAction(this, function () {
            this._sectionsPageController.showPageAtIndex(index);
            MIOUserDefaults.standardUserDefaults().setValueForKey("LastDesktopViewSectionIndex", index);
        });
        this.vipCardsButton.setHidden(true);
        this._toolbarButtons['99000000-0000-1111-1111-000000000045'] = this.vipCardsButton;
    }

    private vipSourcesViewController:MUIViewController = null;
    private vipSourcesButton:MUITabBarItem = null;
    private loadVIPSourcesViewController() {

        this.vipSourcesViewController = new VIPSourceViewController('vip-cards-responsibles-view');
        this.vipSourcesViewController.initWithResource("layout/vip-cards/VIPCardsResponsiblesView.html");

        let index = this._sectionsPageController.pageControllersCount;

        this._sectionsPageController.addPageViewController(this.vipSourcesViewController);

        this.vipSourcesButton = MUIOutlet(this, "dskv_vip_responsibles_tab_item", "MUITabBarItem");
        this.vipSourcesButton.setAction(this, function () {
            this._sectionsPageController.showPageAtIndex(index);
            MIOUserDefaults.standardUserDefaults().setValueForKey("LastDesktopViewSectionIndex", index);
        });
        this.vipSourcesButton.setHidden(true);
        this._toolbarButtons['99000000-0000-1111-1111-000000000046'] = this.vipSourcesButton;
    }

    private mapButton:MUITabBarItem = null;
    private loadMapViewController(){
        this.mapButton = MUIOutlet(this, "dskv_map_tab_item", "MUITabBarItem");
        // this.cardsButton.setAction(this, function () {
        //     this._sectionsPageController.showPageAtIndex(index);
        //     MIOUserDefaults.standardUserDefaults().setValueForKey("LastDesktopViewSectionIndex", index);
        // });
        this.mapButton.setHidden(true);
        //this._toolbarButtons['99000000-0000-1111-1111-000000000049'] = this.cardsButton;
    }

    private workerScheduleButton:MUITabBarItem = null;
    private loadWorkerScheduleViewController(){
        this.workerScheduleButton = MUIOutlet(this, "dskv_worker-schedule_tab_item", "MUITabBarItem");
        this.workerScheduleButton.setHidden(true);
    }

    // Product formats list
    //99000000-0000-1111-1111-000000000057 Product Formats (section id)

    private bankAccountsViewController:MUISplitViewController = null;
    private bankAccountsButton:MUITabBarItem = null;
    private loadBankAccountsViewController(){

        let mvc = new BankListViewController("bank-account-list-view");
        mvc.initWithResource("layout/bankaccount/BankAccountListView.html");

        let nc = new MUINavigationController();
        nc.initWithRootViewController(mvc);

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("BankAccount", NoItemSelectedViewControllerType.BankAccount);

        this.bankAccountsViewController = new MUISplitViewController();
        this.bankAccountsViewController.init();
        this.bankAccountsViewController.setMasterViewController(nc);
        this.bankAccountsViewController.setDetailViewController(dvc);

        let index = this._sectionsPageController.pageControllersCount;
        this._sectionsPageController.addPageViewController(this.bankAccountsViewController);
        
        this.bankAccountsButton = MUIOutlet(this, "dskv_bank-accounts_tab_item", "MUITabBarItem");
        this.bankAccountsButton.setAction(this, function () {
            this._sectionsPageController.showPageAtIndex(index);
            MIOUserDefaults.standardUserDefaults().setValueForKey("LastDesktopViewSectionIndex", index);
        });
        
        this.bankAccountsButton.setHidden(true);
        this._toolbarButtons['99000000-0000-1111-1111-000000000058'] = this.bankAccountsButton;
    }
*/
}