/// <reference path="Booking_ManagedObject.ts" />



enum BookingStatus {
    BookingRequest,
    BookingAssigned,
    BookingReserved,
    BookingConfirmed,
    BookingFinished,
    BookingWaiting,
    BookingCancelled,
    BookingDenied,
    BookingNoShow,
    BookingNoCC,
    BookingDeleted,
    BookingCCCanceled,
    BookingShow,
    BookingSitting
};

class Booking extends Booking_ManagedObject
{
    
    get totalPax(){
        return(this.pax || 0) - (this.paxInvited || 0);
    }
    
    get statusString(){
        switch(this.status){
            case BookingStatus.BookingRequest:
                return 'Request';
            case BookingStatus.BookingAssigned:
                return 'Assigned';
            case BookingStatus.BookingReserved:
                return 'Reserved';
            case BookingStatus.BookingConfirmed:
                return 'Confirmed';
            case BookingStatus.BookingFinished:
                return 'Finished';
            case BookingStatus.BookingWaiting:
                return 'Waiting';
            case BookingStatus.BookingCancelled:
                return 'Cancelled';
            case BookingStatus.BookingDenied:
                return 'Denied';
            case BookingStatus.BookingNoShow:
                return 'No Show';
            case BookingStatus.BookingNoCC:
                return 'No CC';
            case BookingStatus.BookingDeleted:
                return 'Deleted';
            case BookingStatus.BookingCCCanceled:
                return 'CC Cancelled';
            case BookingStatus.BookingShow:
                return 'Show';
            case BookingStatus.BookingSitting:
                return 'Sitting';
        }
    }

}
