/**
 * Created by godshadow on 04/08/16.
 */

class ProductSourceSetupViewController extends SingleEditViewController
{
    get title(){
        return "Product sources";
    }

    fetchEntity(){
        return "ProductSource";
    }

    sortDescriptors(){
        return [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
    }

    configureCellAtIndexPath(cell:SingleEditCell, indexPath:MIOIndexPath, item:MIOManagedObject){
        let d = item as Department;
        cell.setItem(d, "name");
    }

    addButtonDidPress(){
        this.addProductSource();
    }

    removeItem(item:MIOManagedObject){
        let ps = item as ProductSource;
        this.removeProductSource(ps);
    }

   private removeProductSource(productSource){
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        moc.deleteObject(productSource);
        moc.save();    
    }

    private addProductSource(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('SOURCE','Source'), MIOLocalizeString('CHOOSE A NAME','Choose a name '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Name");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                var productSource:ProductSource = MIOEntityDescription.insertNewObjectForEntityForName("ProductSource", moc) as ProductSource;
                productSource.identifier = MIOUUID.UUID().UUIDString;
                productSource.name = name;
                productSource.autosendOnQuickPay = false;
                moc.save();
                this.source = productSource;
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('CHECK NAME','Check name.'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
        
        this.presentViewController(avc, true);
    }
}
