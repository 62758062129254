
class SupplierNoteCell extends UITableViewCell
{
    private documentIDLabel:MUILabel = null;  
    private documentDateLabel:MUILabel = null;  
    private supplierNameLabel:MUILabel = null;

    awakeFromHTML(){
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.documentDateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.supplierNameLabel = MUIOutlet(this, "supplier-lbl", "MUILabel");

        this.separatorStyle = UITableViewCellSeparatorStyle.None;
    }

    set item (item:StockNote){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        this.documentIDLabel.text = item.documentID;
        this.documentDateLabel.text = ad.dateFormatter.stringFromDate(item.stockDate);
        this.supplierNameLabel.text = item.originName;
    }
}