//
// Generated class ProductListItem
//

/// <reference path="ProductListItem_ManagedObject.ts" />

enum ProductListItemType {

    Category = 0,
    Product = 1,
    StockCategory = 2    
}

class ProductListItem extends ProductListItem_ManagedObject
{
    private _product:Product = null;
    registerObserverForProduct(product:Product){
        this._product = product;
        product.addObserver(this, "name");
        product.addObserver(this, "price");
    }

    unregisterObserver(){
        if (this._product == null) return;
        this._product.removeObserver(this, "name");
        this._product.removeObserver(this, "price");
        this._product = null;
    }
    
    observeValueForKeyPath(keyPath, type, object, ctx){

        if (type != "did") return;

        switch (keyPath) {
            case "name":
            this.name = this._product.name;
            break;

            case "price":
            this.price = this._product.price;
            break;
        }
    }
}
