

// Generated class BookingGuest_ManagedObject

class BookingGuest_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: day
    set day(value:string) {
        this.setValueForKey(value, 'day');
    }
    get day():string {
        return this.valueForKey('day');
    }
    set dayPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'day');
    }
    get dayPrimitiveValue():string {
        return this.primitiveValueForKey('day');
    }

    // Property: hour
    set hour(value:string) {
        this.setValueForKey(value, 'hour');
    }
    get hour():string {
        return this.valueForKey('hour');
    }
    set hourPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'hour');
    }
    get hourPrimitiveValue():string {
        return this.primitiveValueForKey('hour');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: pax
    set pax(value:number) {
        this.setValueForKey(value, 'pax');
    }
    get pax():number {
        return this.valueForKey('pax');
    }
    set paxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'pax');
    }
    get paxPrimitiveValue():number {
        return this.primitiveValueForKey('pax');
    }

    // Property: paxAttend
    set paxAttend(value:number) {
        this.setValueForKey(value, 'paxAttend');
    }
    get paxAttend():number {
        return this.valueForKey('paxAttend');
    }
    set paxAttendPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'paxAttend');
    }
    get paxAttendPrimitiveValue():number {
        return this.primitiveValueForKey('paxAttend');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: client
    set client(value:Client) {
        this.setValueForKey(value, 'client');
    }
    get client():Client {
        return this.valueForKey('client') as Client;
    }
    // Relationship: source
    set source(value:BookingSource) {
        this.setValueForKey(value, 'source');
    }
    get source():BookingSource {
        return this.valueForKey('source') as BookingSource;
    }
    // Relationship: guestList
    set guestList(value:BookingGuestList) {
        this.setValueForKey(value, 'guestList');
    }
    get guestList():BookingGuestList {
        return this.valueForKey('guestList') as BookingGuestList;
    }
}
