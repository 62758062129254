

// Generated class Budget_ManagedObject

class Budget_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: documentId
    set documentId(value:string) {
        this.setValueForKey(value, 'documentId');
    }
    get documentId():string {
        return this.valueForKey('documentId');
    }
    set documentIdPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentId');
    }
    get documentIdPrimitiveValue():string {
        return this.primitiveValueForKey('documentId');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: approvalDate
    set approvalDate(value:Date) {
        this.setValueForKey(value, 'approvalDate');
    }
    get approvalDate():Date {
        return this.valueForKey('approvalDate');
    }
    set approvalDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'approvalDate');
    }
    get approvalDatePrimitiveValue():Date {
        return this.primitiveValueForKey('approvalDate');
    }

    // Property: clientName
    set clientName(value:string) {
        this.setValueForKey(value, 'clientName');
    }
    get clientName():string {
        return this.valueForKey('clientName');
    }
    set clientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientName');
    }
    get clientNamePrimitiveValue():string {
        return this.primitiveValueForKey('clientName');
    }

    // Property: clientDocument
    set clientDocument(value:string) {
        this.setValueForKey(value, 'clientDocument');
    }
    get clientDocument():string {
        return this.valueForKey('clientDocument');
    }
    set clientDocumentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientDocument');
    }
    get clientDocumentPrimitiveValue():string {
        return this.primitiveValueForKey('clientDocument');
    }

    // Property: clientAddress
    set clientAddress(value:string) {
        this.setValueForKey(value, 'clientAddress');
    }
    get clientAddress():string {
        return this.valueForKey('clientAddress');
    }
    set clientAddressPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientAddress');
    }
    get clientAddressPrimitiveValue():string {
        return this.primitiveValueForKey('clientAddress');
    }

    // Property: clientPhone
    set clientPhone(value:string) {
        this.setValueForKey(value, 'clientPhone');
    }
    get clientPhone():string {
        return this.valueForKey('clientPhone');
    }
    set clientPhonePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientPhone');
    }
    get clientPhonePrimitiveValue():string {
        return this.primitiveValueForKey('clientPhone');
    }

    // Property: clientEmail
    set clientEmail(value:string) {
        this.setValueForKey(value, 'clientEmail');
    }
    get clientEmail():string {
        return this.valueForKey('clientEmail');
    }
    set clientEmailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientEmail');
    }
    get clientEmailPrimitiveValue():string {
        return this.primitiveValueForKey('clientEmail');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: totalPriceBase
    set totalPriceBase(value:number) {
        this.setValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBase():number {
        return this.valueForKey('totalPriceBase');
    }
    set totalPriceBasePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBasePrimitiveValue():number {
        return this.primitiveValueForKey('totalPriceBase');
    }

    // Property: totalTaxes
    set totalTaxes(value:number) {
        this.setValueForKey(value, 'totalTaxes');
    }
    get totalTaxes():number {
        return this.valueForKey('totalTaxes');
    }
    set totalTaxesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTaxes');
    }
    get totalTaxesPrimitiveValue():number {
        return this.primitiveValueForKey('totalTaxes');
    }

    // Property: totalPrice
    set totalPrice(value:number) {
        this.setValueForKey(value, 'totalPrice');
    }
    get totalPrice():number {
        return this.valueForKey('totalPrice');
    }
    set totalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPrice');
    }
    get totalPricePrimitiveValue():number {
        return this.primitiveValueForKey('totalPrice');
    }

    // Property: totalDiscount
    set totalDiscount(value:number) {
        this.setValueForKey(value, 'totalDiscount');
    }
    get totalDiscount():number {
        return this.valueForKey('totalDiscount');
    }
    set totalDiscountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDiscount');
    }
    get totalDiscountPrimitiveValue():number {
        return this.primitiveValueForKey('totalDiscount');
    }

    // Property: discountString
    set discountString(value:string) {
        this.setValueForKey(value, 'discountString');
    }
    get discountString():string {
        return this.valueForKey('discountString');
    }
    set discountStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'discountString');
    }
    get discountStringPrimitiveValue():string {
        return this.primitiveValueForKey('discountString');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: event
    set event(value:BookingEvent) {
        this.setValueForKey(value, 'event');
    }
    get event():BookingEvent {
        return this.valueForKey('event') as BookingEvent;
    }
    // Relationship: client
    set client(value:Client) {
        this.setValueForKey(value, 'client');
    }
    get client():Client {
        return this.valueForKey('client') as Client;
    }

    // Relationship: budgetLines
    protected _budgetLines:MIOManagedObjectSet = null;
    get budgetLines():MIOManagedObjectSet {
        return this.valueForKey('budgetLines');
    }
    addBudgetLinesObject(value:BudgetLine) {
        this._addObjectForKey(value, 'budgetLines');
    }
    removeBudgetLinesObject(value:BudgetLine) {
        this._removeObjectForKey(value, 'budgetLines');
    }
}
