//
// Generated class ArchivedDocument
//

/// <reference path="ArchivedDocument_ManagedObject.ts" />

class ArchivedDocument extends ArchivedDocument_ManagedObject
{
    get zoneString(){
        let zone = [];
        if (this.locationCategoryName != null) zone.push(this.locationCategoryName);
        if (this.locationName != null) zone.push(this.locationName);

        return zone.join(" ");
    }
}
