class ProductSupplierCell extends MUITableViewCell
{    
    private nameLabel:MUILabel = null;
    private nameTextField:MUITextField = null;
    private referenceTextField:MUITextField = null;
    private lastPriceLabel:MUILabel = null;
    private priceTextField:MUITextField = null;
    private discountTextField:MUITextField = null;
            
    private cf = MUIWebApplication.sharedInstance().delegate.longCurrencyFormatter;    

    awakeFromHTML(){        
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.setupSupplierTextField(this.nameTextField, this, this.supplierDidSelect);

        this.referenceTextField = MUIOutlet(this, 'reference-tf', 'MUITextField');
        this.setupReferenceTextField(this.referenceTextField);

        this.priceTextField = MUIOutlet(this, 'price-tf', 'MUITextField');
        this.setupPriceTextField(this.priceTextField);

        this.lastPriceLabel = MUIOutlet(this, "last-price-lbl", "MUILabel");
        this.discountTextField = MUIOutlet(this, "discount-tf", "MUITextField");
        this.discountTextField.formatter = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter;
        this.setupDiscountTextField(this.discountTextField);
        
        this.selectionStyle = MUITableViewCellSelectionStyle.None;

        this.reset();
    }

    private reference:string = null;
    private price = null;
    private productPrice = null;
    private discount = null;

    private _item:SupplierProduct = null;
    set item(i:SupplierProduct){
        this._item = i;
            
        this.nameLabel.text = i.supplier != null ? i.supplier.name : MIOLocalizeString("SUPPLIER DELETED", "SUPPLIER DELETED");
        this.referenceTextField.text = i.supplierReference;
        this.lastPriceLabel.text = this.cf.stringFromNumber(i.productLastPrice);
        this.priceTextField.text = this.cf.stringFromNumber(i.productPrice);
        this.discountTextField.text = i.discountString;
    }

    private _product:Product = null;
    set product(product:Product){
        this._product = product;
    }

    private supplier:Supplier = null;
    supplierDidSelect(controller:SelectEntityViewController, supplier:Supplier) {
        this.supplier = supplier;        
        this.nameTextField.text = supplier.name;
        this.referenceTextField.becomeFirstResponder();
    }

    private enterDidPress(){
        if (this._item != null) return;
        if (this.supplier == null) return;
        if (this.price == null) return;
        
        let sp = DBHelper.createSupplierProduct(this.supplier, this._product, this.price, this.productPrice, this.reference, this.discount);
        DBHelper.saveMainContext();
        this.reset();
    }

    private setupSupplierTextField(textField:MUITextField, target, completion){
        if (textField == null) return;
        textField.setOnChangeText(this, function(control, value:string){
            AppHelper.sharedInstance().showSelectSupplierViewControllerFromView(textField, value, false, target, completion);
        });
    }

    private setupReferenceTextField(textField:MUITextField){
        textField.setOnChangeText(this, function(textfield, value){
            this.reference = value;
            if (this._item != null) this._item.supplierReference = value;
        });
        
        textField.setOnEnterPress(this, function(control:MUITextField){
            this.enterDidPress();
        });
    }
    
    private setupPriceTextField(textField:MUITextField){
        textField.formatter = this.cf;
        textField.setOnChangeText(this, function(textfield, value){
            this.productPrice = this.cf.numberFromString(value);
            this.price = DBHelper.calculateMinumCostFromProductPrice(this.productPrice, this._product);
            if (this._item != null) {
                this._item.productPrice = this.productPrice;
                this._item.price = this.price;                        
            }
        });
        
        textField.setOnEnterPress(this, function(control:MUITextField){
            this.enterDidPress();
        });
    }

    private setupDiscountTextField(textField:MUITextField){
        textField.setOnChangeText(this, function(textfield, value){
            this.discount = value;
            if (this._item != null) this._item.discountString = value;
        });

        textField.setOnEnterPress(this, function(control:MUITextField){
            this.enterDidPress();
        });
    }

    private reset(){
        if (this.nameTextField) this.nameTextField.text = null;
        this.referenceTextField.text = null;
        this.lastPriceLabel.text = null;
        this.priceTextField.text = null;
        this.discountTextField.text = null;
    }
}
