

/// <reference path="AccountLine.ts" />

// Generated class EconomicAccountLine_ManagedObject

class EconomicAccountLine_ManagedObject extends AccountLine
{

    // Property: invoiceID
    set invoiceID(value:string) {
        this.setValueForKey(value, 'invoiceID');
    }
    get invoiceID():string {
        return this.valueForKey('invoiceID');
    }
    set invoiceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invoiceID');
    }
    get invoiceIDPrimitiveValue():string {
        return this.primitiveValueForKey('invoiceID');
    }

    // Property: invoiceTaxID
    set invoiceTaxID(value:string) {
        this.setValueForKey(value, 'invoiceTaxID');
    }
    get invoiceTaxID():string {
        return this.valueForKey('invoiceTaxID');
    }
    set invoiceTaxIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invoiceTaxID');
    }
    get invoiceTaxIDPrimitiveValue():string {
        return this.primitiveValueForKey('invoiceTaxID');
    }

    // Property: payMethodName
    set payMethodName(value:string) {
        this.setValueForKey(value, 'payMethodName');
    }
    get payMethodName():string {
        return this.valueForKey('payMethodName');
    }
    set payMethodNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodName');
    }
    get payMethodNamePrimitiveValue():string {
        return this.primitiveValueForKey('payMethodName');
    }
    // Relationship: payMethod
    set payMethod(value:PayMethod) {
        this.setValueForKey(value, 'payMethod');
    }
    get payMethod():PayMethod {
        return this.valueForKey('payMethod') as PayMethod;
    }
    // Relationship: economicEntity
    set economicEntity(value:EconomicEntity) {
        this.setValueForKey(value, 'economicEntity');
    }
    get economicEntity():EconomicEntity {
        return this.valueForKey('economicEntity') as EconomicEntity;
    }
}
