/**
 * Created by miguel on 10/2/17.
 */



class AddNewCategoryOrderViewController extends MUIViewController
{
    private tableView:MUITableView = null;
    private _fetchedResultsController:MIOFetchedResultsController = null;
    private _category:Category = null;

    private selectedRow = -1;
    private selectedSection = -1;

    viewDidLoad()
    {
        super.viewDidLoad();
        
        this.tableView = MUIOutlet(this, 'ancov_c1_tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

    }

    public clear()
    {
        this.selectedRow = -1;
        this.selectedSection = -1;
        if(this._category != null && this.tableView != null)
        {
            this.fetchedResultsController = null;
            this.tableView.reloadData();
        }
    }

    set category(value)
    {
        this._category = value;
        this.clear();
    }
    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        if(this._category != null)
        {
            this.fetchedResultsController = null;
            this.tableView.reloadData();
        }
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell = tableview.dequeueReusableCellWithIdentifier("AddNewCategoryOrderCell");

        var item:CategoryTag = this.fetchedResultsController.objectAtIndexPath(indexPath);

        cell.item = item;

        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        
        if(this._category.tag == item) 
        {
            this.selectedRow = indexPath.row;
            this.selectedSection = indexPath.section;
            cell.setAccessoryType(MUITableViewCellAccessoryType.Checkmark);    
        }
        else
            cell.setAccessoryType(MUITableViewCellAccessoryType.None); 
        return cell;

    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        var obj = this.fetchedResultsController.objectAtIndexPath(indexPath) as CategoryTag;

        if(this.selectedSection != -1 && this.selectedRow != -1){
            this.tableView.cellAtIndexPath(MIOIndexPath.indexForRowInSection(this.selectedRow, this.selectedSection)).setAccessoryType(MUITableViewCellAccessoryType.None);    
        }
        this.selectedRow = indexPath.row;
        this.selectedSection = indexPath.section;
        
        this._category.tag = obj;
        
        this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.Checkmark);    
    }

    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        var ad = MUIWebApplication.sharedInstance().delegate;
        
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("orderindex", true),
                            MIOSortDescriptor.sortDescriptorWithKey("name", true)];
    
        var fetchRequest = DBHelper.listFetchRequestWithEntityName('CategoryTag', sortDescriptors, null);
        var fetchedResultsController = new MIOFetchedResultsController();
        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }
}