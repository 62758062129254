//
// Generated class PayMethod
//

/// <reference path="PayMethod_ManagedObject.ts" />

enum PayMethodType
{
    Custom      = 0, // Create by user
    Cash        = 1, 
    Card        = 2, 
    LapsoPay    = 3, 
    PayThunder  = 4, 
    Redsys      = 5, 
    Bytefactory = 6, 
    SumUp       = 7, 
    Hotel       = 8, 
    CashMachine = 9, 
}

class PayMethod extends PayMethod_ManagedObject
{
    subtypeStringWithValue(subType:string):string{
        if (this.type != PayMethodType.Redsys) return subType;

        // Redsys conversion
        let value = null;
        switch (subType){
            case "1":
                value = "VISA";
                break;

            case "2":
                value = "MASTERCARD";
                break;

            case "8":
                value = "AMEX";
                break;

            default:
                value = "Unkown (" + subType + ")";
                break;
        }

        return value;
    }
}
