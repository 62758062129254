
/// <reference path="../base/BaseTableViewController.ts" />

class LicensesListViewController extends BaseTableViewController
{    
    private businessLicense:BusinessLicense = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;   
        
        this.tableView.reloadData();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = null;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        if(item.type == 'company')
            cell = tableview.dequeueReusableCellWithIdentifier("CompanyLicenseCell") as CompanyLicenseCell;
        else
            cell = tableview.dequeueReusableCellWithIdentifier("PlaceLicenseCell") as PlaceLicenseCell;        
 
        cell.item = item;
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        
        return cell;
    }

    didSelectCellAtIndexPath(tableView,indexPath:MIOIndexPath){
        this.businessLicense = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showSelectedItem(this.businessLicense);
    }

    private detailViewController:LicensesDetailViewController = null;
    private showSelectedItem(item){
        if(this.detailViewController == null) {
            if (MIOCoreIsPhone() == true) {
                this.detailViewController = new LicensesDetailViewController('licenses-detail-view_phone');
                this.detailViewController.initWithResource('layout/licenses/LicensesDetailView_phone.html');                
            }
            else {
                this.detailViewController = new LicensesDetailViewController('licenses-detail-view');                
                this.detailViewController.initWithResource('layout/licenses/LicensesDetailView.html');                
            }
        }

        this.detailViewController.businessLicense = item;
        this.splitViewController.showDetailViewController(this.detailViewController);        
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("BusinessLicense");
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        fetchRequest.fetchLimit = 100;        

        if (this.searchString != null) {
            let predicateFormat = "name CONTAINS '" + this.searchString + "'";
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        }        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    public clientDidInserted(businessLicense:BusinessLicense){
        this.businessLicense = businessLicense;
        this.tableView.reloadData();
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("License", NoItemSelectedViewControllerType.License);        
        this.splitViewController.showDetailViewController(vc);
    }

}