

class CashDeskSessionSummaryViewController extends MUIViewController
{       
    private statusBar:MUIView = null;
    private appLabel:MUILabel = null;
    private openDateLabel:MUILabel = null;
    private closeDateLabel:MUILabel = null;
    private openWorkerLabel:MUILabel = null;
    private closeWorkerLabel:MUILabel = null;
    private sessionDateLabel:MUILabel = null;
 
    private tableView:MUITableView = null;
 
    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;
    private ldf = MUIWebApplication.sharedInstance().delegate.longDateFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.statusBar = MUIOutlet(this, "status-view", "MUIView");

        this.appLabel = MUIOutlet(this, "app-lbl", "MUILabel");
        this.openWorkerLabel = MUIOutlet(this, 'cdssv_open_worker_lbl','MUILabel');
        this.openDateLabel = MUIOutlet(this, 'cdssv_open_date_lbl','MUILabel');
        this.closeWorkerLabel = MUIOutlet(this, 'cdssv_close_worker_lbl','MUILabel');
        this.closeDateLabel = MUIOutlet(this, 'cdssv_close_date_lbl','MUILabel');
        this.sessionDateLabel = MUIOutlet(this, 'session-date-lbl', "MUILabel");

        this.tableView = MUIOutlet(this, 'table-view', 'MUITableView');

        this.tableView.dataSource = this;
        this.tableView.delegate = this;   
                    
        this.updateUI();
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _session:CashDeskSession = null;
    set session(value){
        this._session = value;
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this._session == null) return;
        
        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;
        ws.getCashDeskSessionSummary(ad.selectedPlace, this._session, this, function(code, json){
            this.fetchedResultsController = null;
            this.tableView.reloadData();
        }, ad.managedObjectContext);

        this.appLabel.text = this._session.name;
        this.openDateLabel.text = this.dtf.stringFromDate(this._session.beginDate);
        this.closeDateLabel.text = this.dtf.stringFromDate(this._session.endDate);
        this.openWorkerLabel.text = this._session.beginWorkerName;
        this.closeWorkerLabel.text = this._session.endWorkerName;
        this.sessionDateLabel.text = this.ldf.stringFromDate(this._session.serverSessionBeginDate);

        if (this._session.isOpen == true) {
            MUICoreLayerRemoveStyle(this.statusBar.layer, "alert");
            MUICoreLayerAddStyle(this.statusBar.layer, "active");
        }
        else if (this._session.isCashCounted == false) {
            MUICoreLayerRemoveStyle(this.statusBar.layer, "active");
            MUICoreLayerAddStyle(this.statusBar.layer, "alert");
        }
        else {
            MUICoreLayerRemoveStyle(this.statusBar.layer, "alert");
            MUICoreLayerRemoveStyle(this.statusBar.layer, "active");
        }
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let item:CashDeskSessionSummaryItem = this.fetchedResultsController.objectAtIndexPath(indexPath);        

        if (item.isHeader == true){
            let header = null;
            if (item.headerType == 1){
                header = tableview.dequeueReusableCellWithIdentifier("HeaderDoubleCell") as CashDeskSummaryHeaderCell;                         
            }
            else if (item.headerType == 2){
                header = tableview.dequeueReusableCellWithIdentifier("HeaderTripleCell") as CashDeskSummaryHeaderCell;                         
            }            
            else if (item.headerType == 3){
                header = tableview.dequeueReusableCellWithIdentifier("HeaderFourthCell") as CashDeskSummaryHeaderCell;                         
            }            
            else if (item.headerType == 4){
                header = tableview.dequeueReusableCellWithIdentifier("HeaderFifthCell") as CashDeskSummaryHeaderCell;                         
            }            
            else if (item.headerType == 5){
                header = tableview.dequeueReusableCellWithIdentifier("HeaderSixthCell") as CashDeskSummaryHeaderCell;                         
            }            
            else {
                header = tableview.dequeueReusableCellWithIdentifier("HeaderSingleCell") as CashDeskSummaryHeaderCell;
            }
            header.item = item;                
            return header;
        }
        
        let cell = null;
        if (item.cellType == 1){
            cell = tableview.dequeueReusableCellWithIdentifier("DoubleCell") as CashDeskSummaryCell;
        }
        else if (item.cellType == 2){
            cell = tableview.dequeueReusableCellWithIdentifier("TripleCell") as CashDeskSummaryCell;
        }
        else if (item.cellType == 3){
            cell = tableview.dequeueReusableCellWithIdentifier("FourthCell") as CashDeskSummaryCell;
        }
        else if (item.cellType == 4){
            cell = tableview.dequeueReusableCellWithIdentifier("FifthCell") as CashDeskSummaryCell;
        }
        else if (item.cellType == 5){
            cell = tableview.dequeueReusableCellWithIdentifier("SixthCell") as CashDeskSummaryCell;
        }
        else{
            cell = tableview.dequeueReusableCellWithIdentifier("SingleCell") as CashDeskSummaryCell;
        }
        cell.item = item;

        return cell;
    }

    canSelectCellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let item:CashDeskSessionSummaryItem = this.fetchedResultsController.objectAtIndexPath(indexPath);        

        return !item.isHeader;
    }
        
    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('CashDeskSessionSummaryItem');
    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderindex', true)];                                                
        
        fetchRequest.predicate = MIOPredicate.predicateWithFormat('cashDeskSession == ' + this._session.identifier);
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }
}