/**
 * Created by miguel on 23/2/17.
 */

class EventListViewController extends BaseTableViewController
{
    private addButton:MUIButton = null;            
        
    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'add_btn','MUIButton');
        this.addButton.setAction(this, function(){
            this.addEvent();
        });

        this.searchTextField = MUIOutlet(this, 'search_bar','MUITextField');
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this, 'tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;             
    }

    titleForHeaderInSection(tableview, section){
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let event = this.fetchedResultsController.objectAtIndexPath(ip);

        var ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        return ad.dateFormatter.stringFromDate(event.date);
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('EventCell') as EventCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as PartyEvent;    
        cell.item = item;
    
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as PartyEvent;
        this.showSelectedItem();
    }
    
    private detailViewController:EventDetailViewController = null;
    private showSelectedItem(){
        if (this.detailViewController == null) {
            this.detailViewController  = new EventDetailViewController("event-detail-view");
            this.detailViewController.initWithResource("layout/event/EventDetailView.html");
        }                

        this.detailViewController.event = this.selectedItem;
        this.splitViewController.showDetailViewController(this.detailViewController);
    }

    public showNoItem(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Events", NoItemSelectedViewControllerType.Events)        
        this.splitViewController.showDetailViewController(vc);
    }
        
    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("beginDate", true),
                                MIOSortDescriptor.sortDescriptorWithKey("name", true)];
    
        this.searchString = (this.searchString != null && this.searchString.length > 0)  ? this.searchString = 'name CONTAINS "' + this.searchString + '"' : null;

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("PartyEvent", sortDescriptors, this.searchString);
        fetchRequest.relationshipKeyPathsForPrefetching = ["party"];
        
        let fetchedResultsController = new MIOFetchedResultsController();                
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "beginDate");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    private addEvent(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW EVENT','New event'), MIOLocalizeString('CHOOSE A NAME','Choose a name'), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){

            textField.setPlaceholderText("name");
        });
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','Ok'), MUIAlertActionStyle.Default, this, function(){
            let tf =  avc.textFields[0];
            let eventName = tf.text.trim();

            if(eventName.length > 0){
                let ad = MUIWebApplication.sharedInstance().delegate;
                this._event = MIOEntityDescription.insertNewObjectForEntityForName("BookingEvent", ad.managedObjectContext) as BookingEvent;
        
                this._event.identifier = MIOUUID.UUID().UUIDString;
                this._event.name = eventName;
                this._event.pax = null;
                this._event.closePlace = false;
                this._event.date = MIODateNow();

                ad.managedObjectContext.save();
                this.showSelectedItem();

                /*var event = new DLEvent();
                event.name = eventName;
                var date = new Date();
                event.date = date;
                let ad = MUIWebApplication.sharedInstance().delegate;
                let ws = ad.webService;
                ws.insertEvent(ad.selectedPlace, event, this, function(code, json){
                   if(code == 200) 
                   {
                        var p = MIOEntityDescription.insertNewObjectForEntityForName("DLEvent", ad.managedObjectContext) as DLEvent;
                        p.setObject(json['data']);
                        this._event = p;
                        ad.managedObjectContext.save();
                        this._showSelectedItem();
                   }
                   else
                   {
                        var error = new MUIAlertViewController();
                        error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), json['error'], MUIAlertViewStyle.Default);
                        error.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Default, this, function(){}));
                        
                        this.presentViewController(error, true);
                   }
                });*/
            }
            else{
                AppHelper.showErrorMessage(this, 'Error', 'Must have a name');
            }
            
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, null, null));
        
        this.presentViewController(avc, true);        
    }


}