
/// <reference path="DBSynEntityHeaderView.ts" />


class DBSyncEntitiesQueueDataSource extends MIOObject
{
    dateFrom:string = null;
    dateTo:string = null;

    private tableView:UITableView = null;    
    private headerView:DBSynEntityHeaderView = null;

    initWithTableView(tableView:UITableView, headerView:DBSynEntityHeaderView){
        this.tableView = tableView;
        this.headerView = headerView;
        this.headerView.hidden = true;
    }    
    
    private entityName:string = null;
    private integrator:string = null;
    endpoint:string = null;

    setFilterValues(entityName:string, integrator:string){
        this.entityName = entityName;
        this.integrator = integrator;
        this.invalidateData();
    }

    invalidateData(){
        this.fetchedResultsController = null;
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){        
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    viewForHeaderInSection(tableview:UITableView, section){
        if (this.headerView == null) return null;

        let h = this.headerView.copy() as DBSynEntityHeaderView;
        h.awakeFromHTML();
        h.hidden = false;

        let item = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(0, section)) as DBSyncEntity;

        h.item = item;

        // let h = new MUIView();
        // h.init();
        // h.layer.background = "";
        // h.layer.style.position= "absolute";
        // h.setHeight(23);

        // let title = new MUILabel();
        // title.init();
        // title.layer.style.position = "absolute";
        // title.setX(8);
        // title.setWidth(500);
        // h.addSubview(title);

        // let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate

        // let ip = MIOIndexPath.indexForRowInSection(0, section);
        // let item = this.fetchedResultsController.objectAtIndexPath(ip) as IntegratorsSync;
        // title.text = " " + ad.longDateTimeFormatter.stringFromDate(item.date) + " # " + item.referenceConcept;        

        return h;
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("SyncCell") as DBSyncEntityCell;
        cell.syncType = DBSyncEntityCellType.queue;
        cell.delegate = this;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as DBSyncEntity;
        cell.item = item;        

        return cell;
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){                
        if (this._fetchedResultsController != null) return this._fetchedResultsController;            
        //if (this.entityName == null || this.integrator == null) return null;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    
        //let entityName = this.endpoint != null ? this.endpoint : this.entityName;
        //let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("date", true), MIOSortDescriptor.sortDescriptorWithKey("note.identifier", false)];
        //let predicateFormat = "integrator == '" + this.integrator + "' AND entityName  == '" + this.entityName + "'";
        //let predicateFormat = "type == 0 AND integratorEnv == 'PRO' AND integratorDate >= '" + this.dateFrom + "' AND integratorDate <= '" + this.dateTo + "'";
        let predicateFormat = null;
        if (ad.selectedIdentifierType == "place") predicateFormat = "placeID == " + ad.selectedIdentifier;

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("IntegratorSAPStockNoteLineQueue", null, predicateFormat);
        //let fetchRequest = DBHelper.listFetchRequestWithEntityName("DBSyncEntity", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 100;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "integratorReferenceID");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();        
    }

    syncItemFromCell(cell:DBSyncEntityCell, item:DBSyncEntity){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        ad.webService.syncQueueItem([item.identifier], this, function(code, data){
            AppHelper.showInfoMessage(this, "Server code " + code, data);
        });       
    }

}