

/// <reference path="../model/Product.ts" />
/// <reference path="../model/CashDeskSummaryItem.ts" />
/// <reference path="../model/CashDeskSessionSummaryItem.ts" />

class WebServiceCommand {    
    urlString = null;
    method = "GET";    
    headers = {};            
    completion = null;    

    params = null;
    formData = null;
    blobData = null;
        
    binary = false;
    download = false;        

    constructor(apiURL, command, binary?, headers?) {
        this.urlString = apiURL + command;
        if (binary != null) {
            this.binary = binary;
            if (binary == true) this.download = true;
        }
        this.headers =  (!headers) ? { "Content-Type": "application/json" }: this.headers = headers;
    }

    execute(target, completion?) {
        let request = MIOURLRequest.requestWithURL(MIOURL.urlWithString(this.urlString));
        request.httpMethod = this.method;
        request.binary = this.binary;
        request.download = this.download;
        
        for (let key in this.headers) {            
            let value = this.headers[key];
            request.setHeaderField(key, value);
        }

        MIOUserDefaults.standardUserDefaults().removeValueForKey('LastSelectedPlaceID');

        let token = MIOUserDefaults.standardUserDefaults().valueForKey("LoginToken");
        if (token != null){
            request.setHeaderField("Authorization", "Bearer " + token);
            if (SettingsHelper.sharedInstance().isNewServersLoaded) request.setHeaderField("DL-AUTH-SERVER", "new-login");
        }

        if (this.params != null)
            request.httpBody = JSON.stringify(this.params);
        else if (this.formData != null)
            request.httpBody = this.formData;
        else if (this.blobData != null)
            request.httpBody = this.blobData;

        console.log(request.httpBody);
        MIOLog("START " + this.urlString)
        MIONotificationCenter.defaultCenter().postNotification("WebServiceCommandBegin", this);

        let urlConnection = new MIOURLConnection();
        urlConnection.initWithRequestBlock(request, this, function (statusCode, data, blob) {

            // console.log(data);
            MIOLog("END " + this.urlString)
            MIONotificationCenter.defaultCenter().postNotification("WebServiceCommandEnd", this);
            if (statusCode < 200 || statusCode >= 300) MIONotificationCenter.defaultCenter().postNotification("WebServiceCommandError", null);

            if (completion != null) {
                let json = null;
                if (data != null) {
                    try {
                        json = JSON.parse(data.replace(/(\r\n|\n|\r)/gm, ""));
                    } catch (err) {
                        json = { 'error': 'Server error', 'data': {} };
                    }
                }
                // if (statusCode >= 200 && statusCode <= 300) {

                // }
                // else 
                if (statusCode == 401) {
                    MIONotificationCenter.defaultCenter().postNotification("ShowLoginController", null);
                    json = { "error": "Invalid token. The user need to login again" };
                    MIOUserDefaults.standardUserDefaults().removeValueForKey("LoginToken");
                    MIOUserDefaults.standardUserDefaults().removeValueForKey("LoginOK");
                }
                // else if (statusCode == 422)
                // {
                //     json = {"error" : "Unprocessable Entity. Check teh value of the parameters you send it"};
                // }
                // else
                // {
                //     json = {"error" : "Conection error. Check internet and server conections"};
                // }

                completion.call(target, statusCode, json, blob);
            }

        });
    }
}

class WebService extends MIOObject {

    authURL:string = null;    

    private _apiURL:string = null;
    set apiURL(value) {
        this._apiURL = value;
    }
    get apiURL():string {
        return SettingsHelper.sharedInstance().config["APIServerURL"];
        // if (this._apiURL != null) return this._apiURL;
        
        // let url = MIOURL.urlWithString(MIOCoreGetMainURLString());        
        // this._apiURL = url.scheme + "://" + url.host + "/api";
        // return this._apiURL;
    }

    // private _api2URL:string = null;
    // set api2URL(value) {
    //     this._api2URL = value;
    // }
    // get api2URL():string {
    //     if (this._api2URL != null) return this._api2URL;
        
    //     let url = MIOURL.urlWithString(MIOCoreGetMainURLString());        
    //     this._api2URL = url.scheme + "://" + url.host + "/api/v2";
    //     return this._api2URL;
    // }

    private _scriptURL:string = null;
    set scriptURL(value) {this._scriptURL = value;}
    get scriptURL():string {
        return SettingsHelper.sharedInstance().config["ScriptServerURL"];
        // if (this._scriptURL != null) return this._scriptURL;
        
        // let url = MIOURL.urlWithString(MIOCoreGetMainURLString());        
        // this._scriptURL = url.scheme + "://" + url.host + "/script";
        // return this._scriptURL;
    }
    
    clientID:string = null;
    clientSecret:string = null;
    clientGrantType:string = null;

    identifier: string = null;
    identifierType: string = null;
    
    private _scheme:string = null;    
    get scheme(){        
        return this._scheme;
    }
    set scheme(scheme:string){
        this._scheme = scheme;
    }

    token = null;
    private _isLoging = false;    

    userLogin(user, pass, target, completion) {
        let cmd = null;
        if (SettingsHelper.sharedInstance().isNewServersLoaded) {
            cmd = new WebServiceCommand(this.authURL, "/login");
            cmd.method = "POST";
            cmd.params = {
                "email": user,
                "password": pass
            };    
        }
        else {
            cmd = new WebServiceCommand(this.authURL, "/oauth/token");
            cmd.method = "POST";
            cmd.params = {
                "username": user,
                "password": pass,
                "grant_type": this.clientGrantType,
                "client_secret": this.clientSecret,
                "client_id": this.clientID
            };
        }        
        
        // Reset
        MIOUserDefaults.standardUserDefaults().removeValueForKey("LastProductDetailPageIndex");                
        MIOUserDefaults.standardUserDefaults().removeValueForKey("LastProductDetailSalesPageIndex");                

        cmd.execute(this, function (code, json) {
            let err = false;
            let result = false;
            if (code == 200) {
                // Login OK
                err = false;
                result = true;                                

                //let ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
                if (SettingsHelper.sharedInstance().isNewServersLoaded) {
                    this.token = json["data"]["token"];  
                    AppHelper.sharedInstance().userID = json["data"]["id"];
                }
                else {
                    this.token = json["access_token"];
                }                

                MIOUserDefaults.standardUserDefaults().setValueForKey("LastLoginEmail", user);
                MIOUserDefaults.standardUserDefaults().setValueForKey("LoginToken", this.token);
                MIOUserDefaults.standardUserDefaults().setBooleanForKey("LoginOK", true);                
            }
            else {
                // Error
                err = true;
                result = false;
                this.token = null;
                MIOUserDefaults.standardUserDefaults().removeValueForKey("LastLoginEmail");
                MIOUserDefaults.standardUserDefaults().removeValueForKey("LoginToken");
                MIOUserDefaults.standardUserDefaults().removeValueForKey("LoginOK");
            }

            // console.log(json);
            completion.call(target, err, result);
        });
    }

    signin(name, email, password, confirmed, target, completion) {        
        let cmd = new WebServiceCommand(this.authURL, "/api/signin");
        cmd.method = "POST";
        cmd.params = {
            "lang": "es",
            "name": name,
            "email": email,
            "password": password,
            "password_confirmation": confirmed
        };
        cmd.execute(this, function (code, json) {

            var err = false;
            var result = false;
            if (code == 200 || code == 201) {
                // Login OK
                err = false;
                result = true;
                // this.token = json["access_token"];
                // MIOUserDefaults.standardUserDefaults().setValueForKey("LoginToken", this.token);
                // MIOUserDefaults.standardUserDefaults().setBooleanForKey("LoginOK", true);
            }
            else {
                // Error
                err = true;
                result = false;
            }

            // console.log(json);
            completion.call(target, err, result, json);
        });
    }

    rememberpass(email, language, target, completion) {        
        let cmd = new WebServiceCommand(this.authURL, "/api/rememberpassword");
        cmd.method = "POST";
        cmd.params = {
            "lang": language,
            "email": email
        };
        cmd.execute(this, function (code, json) {

            var err = false;
            var result = false;
            if (code == 200 || code == 201) {
                // Remember OK
                err = false;
                result = true;
            }
            else {
                // Error
                err = true;
                result = false;
            }

            // console.log(json);
            completion.call(target, err, result, json);
        });
    }

    loginUserInfo(target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/userinfo");
        cmd.method = "GET";
        cmd.execute(this, function (code, json) {            

            if (target != null && completion != null)
                completion.call(target, code, json);
        });
    }
    
    userBusinessData(target, completion) {
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/userbusinessdata");
        cmd.method = "GET";
        cmd.execute(this, function (code, json) {            

            if (target != null && completion != null)
                completion.call(target, code, json);
        });
    }

    sendManagerUserInvitationToEmail(email:string, target?, completion?){        
        let cmd = new WebServiceCommand(this.authURL, "/api/invitation/" + this.identifier + "/en/" + email);
        cmd.method = "GET";
        cmd.execute(this, function (code, json) {            

            if (target != null && completion != null)
                completion.call(target, code, json);
        });        
    }    

    removeManagerUser(user:User, target?, completion?){
        let cmd = new WebServiceCommand(this.authURL, "/api/invitation-unlink/" + this.identifier + "/" + user.identifier);
        cmd.method = "GET";
        cmd.execute(this, function (code, json) {            

            if (target != null && completion != null)
                completion.call(target, code, json);
        });        
    }

    payDebtLineByImport(amount, payMethod, clientID:string, target?, completion?) {
        
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/debtline/pay");
        cmd.method = "PATCH";
        cmd.params = {};
        cmd.params["amount"] = amount;
        cmd.params["paymethodid"] = payMethod;
        cmd.params["clientid"] = clientID;

        cmd.execute(this, function (code, json) {
            this.updateObjectsFromWebService(code, json);
            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    payDebtLine(debtline:DebtLine, payMethod, clientID:string, target?, completion?) {
        
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/debtline/pay/" + debtline.identifier);
        cmd.method = "PATCH";
        cmd.params = {};
        cmd.params["paymethodid"] = payMethod;
        cmd.params["clientid"] = clientID;

        cmd.execute(this, function (code, json) {
            this.updateObjectsFromWebService(code, json);
            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    payPartialDebtLine(debtline:DebtLine, payMethod, quantity, clientID:string, target?, completion?) {
        
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/debtline/partialpay/" + debtline.identifier);
        cmd.method = "PATCH";
        cmd.params = {};
        cmd.params["paymethodid"] = payMethod;
        cmd.params["amount"] = quantity;
        cmd.params["clientid"] = clientID;

        cmd.execute(this, function (code, json) {
            this.updateObjectsFromWebService(code, json);
            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    discardDebtLine(debtline:DebtLine, target, completion) {        
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/debtline/discard/" + debtline.identifier);
        cmd.method = "PATCH";

        cmd.execute(this, function (code, json) {
            this.updateObjectsFromWebService(code, json);
            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }


    getCashDeskEntitiesByDate(identifier, identifierType, dateTimeFrom, dateTimeTo, target, completion, moc) {
        let cmd = new WebServiceCommand(this.authURL, "/api/" + identifierType + "/" + identifier + "/cashdesksession/totalprice");
        cmd.method = "POST";
        cmd.params = { from: dateTimeFrom, to: dateTimeTo };

        cmd.execute(this, function (code, json) {

            if (code == 200) {

                let items = json["data"];
                moc.removeAllObjectsForEntityName("CashDeskEntity");
                moc.save();

                // Parse objects
                let mos = {};
                for (let i = 0; i < items.length; i++) {                    
                    let p: CashDeskEntity = MIOEntityDescription.insertNewObjectForEntityForName("CashDeskEntity", moc) as CashDeskEntity;
                    p.setObject(items[i]);
                    mos[p.identifier] = p;
                }

                // Parse relationships
                for (let id in mos){
                    let cde:CashDeskEntity = mos[id];
                    if (cde.parentID != null){
                        cde.parent = mos[cde.parentID];
                    }
                }

                moc.save();
            }

            if (completion != null && target != null)
                completion.call(target, code, json);

        });

    }

    recoverySessions(sessions, target, completion, moc) {        
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/cashdesksession/recovery");
        cmd.method = "POST";
        cmd.params = {sessions:sessions};

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    backupSessions(sessions, target, completion, moc) {                
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/cashdesksession/backup");
        cmd.method = "POST";
        cmd.params = {sessions:sessions};

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    exportTicketFromDebt(debtline, target?, completion?) {
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifier + "/debtline/" + debtline.identifier + '/pdf', true);
        cmd.method = "GET";

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }    

    exportDocument(documentID, target?, completion?) {
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/archiveddocument/" + documentID + '/pdf', true);
        cmd.method = "GET";

        cmd.execute(this, function (code, json) {
            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    insertDocument(place, document, target?, completion?) {
        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.identifier + "/document");

        cmd.method = "POST";
        cmd.params = document.getObject();

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    updateDocument(place, document, target?, completion?) {
        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.identifier + "/document/" + document.identifier);

        cmd.method = "PUT";
        cmd.params = document.getObject();

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    addTicketToDocument(place, document, ticket, target?, completion?) {
        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.identifier + "/document/" + document.identifier + "/ticket/" + ticket.identifier);

        cmd.method = "PUT";

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    deleteDocument(place, document, target?, completion?) {
        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.identifier + "/document/" + document.identifier);

        cmd.method = "DELETE";

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

   
    //EXPORT
    exportBudget(place, budget, target?, completion?) {
        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.entity.managedObjectClassName.toLowerCase() + '/' +  place.identifier + "/budget/" + budget.identifier + '/pdf', true);
            
        cmd.method = "GET";

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    generateBkTicketsForEvent(place, event, begindate, enddate, quantity, target?, completion?) {
        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.className.toLowerCase() + "/" + place.identifier + "/bkticket");

        cmd.method = "PUT";
        cmd.params = {
            'begindate': begindate,
            'enddate': enddate,
            'quantity': quantity,
            'event': event.identifier
        }
        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    updateTicketPAX(identifier:string, pax:number, target, completion){
  
        let url = MIOURL.urlWithString(this.scriptURL + "/" + this.identifierType + "/" + this.identifier + "/python/update_pax.py");
        let request = new MWSJSONRequest();
        request.initWithURL(url, {"filters": {"id": identifier, "pax": pax}}, "POST");
    
        let token = MIOUserDefaults.standardUserDefaults().valueForKey("LoginToken");
        request.setHeaderValue("Bearer " + token, "Authorization");
        if (SettingsHelper.sharedInstance().isNewServersLoaded) request.setHeaderValue("new-login", "DL-AUTH-SERVER");        

        request.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }

    ////////////////////////////
    /// BOOKING: SAVE AND SEND
    ///////////////////////////
    sendEmailForBooking(booking, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/booking/" + booking.identifier + "/send");
        cmd.method = "GET";

        cmd.execute(this, function (code, json) {
            if (completion != null && target != null)
                completion.call(target, code, json);

        });
    }

    ///////////////////
    // BOOKINGSOURCE
    //////////////////

    exportBookingGuest(place, date, target?, completion?) {
        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.className.toLowerCase() + "/" + place.identifier + "/bookingguest/export", true);
        cmd.method = "POST";
        cmd.params = { date: date };

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    importBookingGuest(place, object, target?, completion?) {
        //let headers = [{'Content-Type': 'application/x-www-form-urlencoded'}];
        let headers = [];
        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.className.toLowerCase() + "/" + place.identifier + "/bookingguest/import", false, headers);
        cmd.method = "POST";
        cmd.formData = object;

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }
    //STOCKEXISTENCE

    getStockExistence(place, warehouse, target?, completion?) {
        var urlString = "/api/" + place.className.toLowerCase() + "/" + place.identifier + "/stockexistence";
        if (warehouse != null)
            urlString += '/' + warehouse.identifier;

        let cmd = new WebServiceCommand(this.authURL, urlString);

        cmd.execute(this, function (code, json) {

            let items = json["data"];
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            ad.managedObjectContext.removeAllObjectsForEntityName("StockProduct");

            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                var p: StockProduct = MIOEntityDescription.insertNewObjectForEntityForName("StockProduct", ad.managedObjectContext) as StockProduct;
                p.setObject(item);
            }
            ad.managedObjectContext.save();

            if (completion != null && target != null)
                completion.call(target, code, json);

        }); 
    }

    exportStockExistences(place, target, completion, moc) {

        let cmd = new WebServiceCommand(this.authURL, "/api/" + place.className.toLowerCase() + "/" + place.identifier + "/stockexistence/export", true);
        cmd.method = "GET";
        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);

        });
    }

    //CASHDESKSESSIONS

    getCashDeskSummary(place, entity: CashDeskEntity, dateTimeFrom: string, dateTimeTo: string, target, completion) {

        let cpdLevel = entity.type == CashDeskEntityType.TypeCompany ? 'company' : entity.type == CashDeskEntityType.TypePlace ? 'place' : 'device';

        let id = entity.identifier;
        let level = cpdLevel;
        let isManager = false;

        if (entity.type == 3){ // Manager cashdesk entity
            id = entity.identifier.substring(4);
            level = "place";
            isManager = true;

        }

        let cmd = new WebServiceCommand(this.authURL, "/api/" + level + "/" + id + "/cashdesksession/summary");
        cmd.method = "POST";
        cmd.params = {};
        cmd.params["from"] = dateTimeFrom;
        cmd.params["to"] = dateTimeTo;
        if (isManager) cmd.params["ismanager"] = isManager;

        cmd.execute(this, function (code, json) {

            if (json == null) return;
            let items = json["data"];

            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            ad.managedObjectContext.removeAllObjectsForEntityName("CashDeskSummaryItem");
            let section;
            let orderindex = 0;
            let i = 0;

            for (i = 0; i < items.length; i++) {

                let d: CashDeskSummaryItem = MIOEntityDescription.insertNewObjectForEntityForName("CashDeskSummaryItem", ad.managedObjectContext) as CashDeskSummaryItem;
                d.setObject(items[i]);
                d.cashDeskSession = null;
                d.orderindex = i;

            }
            ad.managedObjectContext.save();

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    getCashDeskSessionSummary(place, session: CashDeskSession, target, completion, moc) {


        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/cashdesksession/summary/" + session.identifier);
        cmd.method = "GET";

        cmd.execute(this, function (code, json) {

            if (json == null) return;
            let items = json["data"];

            moc.removeAllObjectsForEntityName("CashDeskSessionSummaryItem");
            let section;
            let orderindex = 0;
            let i = 0;

            for (i = 0; i < items.length; i++) {
                let d: CashDeskSessionSummaryItem = MIOEntityDescription.insertNewObjectForEntityForName("CashDeskSessionSummaryItem", moc) as CashDeskSessionSummaryItem;
                d.setObject(items[i]);
                d.cashDeskSession = session.identifier;
                d.orderindex = i;
            }
            moc.save();

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    exportCashDeskSessionByDate(place, date, target, completion, moc) {

        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.identifier + "/cashdesksession/summary/export", true);
        cmd.method = "POST";
        cmd.params = {
            date: date
        };
        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);

        });
    }
    
    exportXLSCashDeskSession(session, target, completion, moc) {
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + '/' + this.identifier + "/cashdesksession/" + session.identifier + "/xls", true);
        cmd.method = "GET";

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);

        });
    }

    exportPDFCashDeskSession(session, target, completion, moc) {
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + '/' + this.identifier + "/cashdesksession/" + session.identifier + "/pdf", true);
        cmd.method = "GET";

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);

        });
    }
    //Reports
    getReportResults(place, report, target, completion, moc) {

        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.identifier + "/documentannotation/report");
        cmd.method = "POST";
        cmd.params = report;
        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);

        });
    }

    getStockReportResults(place, date, target, completion, moc) {

        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.identifier + "/report/stockinventory");
        cmd.method = "POST";
        cmd.params = date;
        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    exportStockReportResults(place, date, target, completion, moc) {

        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.identifier + "/report/stockinventory/export");
        cmd.method = "POST";
        cmd.params = { date: date };
        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    getReports(place, target, completion) {

        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.className.toLowerCase() + "/" + place.identifier + "/report");
        cmd.method = "GET";
        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);

        });
    }

    loadReportResults(place, report, target, completion, moc) {

        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.className.toLowerCase() + "/" + place.identifier + "/report/export", true);
        cmd.method = "POST";
        cmd.params = report;
        cmd.download = false;
        cmd.execute(this, function (code, json, blob) {

            if (completion != null && target != null)
                completion.call(target, code, blob);
        });
    }


    exportReportResults(place, report, target, completion, moc) {

        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.className.toLowerCase() + "/" + place.identifier + "/report/export", true);
        cmd.method = "POST";
        cmd.params = report;
        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);

        });
    }

    //SALES PROFIT
    getSalesProfit(place, target, completion, moc) {

        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.identifier + "/salesprofit");
        cmd.method = "GET";

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);

        });
    }

    //TEMPORAL
    exportSupplierNotesBySupplier(place, filter, target, completion, moc) {

        var cmd = new WebServiceCommand(this.authURL, "/api/" + place.identifier + "/report/suppliernotes/export", true);
        cmd.method = "POST";
        cmd.params = filter;

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);

        });
    }

    private _parseChartsData(code, json, target, completion) {

        var error = true;
        var values = [];
        var labels = [];

        if (code == 200) {

            error = false;
            let items = json["data"];
            for (var index = 0; index < items.length; index++) {
                let i = items[index];
                let v = i["value"];
                let l = i["title"];
                values.push(v);
                labels.push(l);
            }

            if (completion != null && target != null)
                completion.call(target, error, labels, values);
        }
    }

    downloadSalesComparationDaysChart(identifier:string, identifierType, dayFrom, dayTo, mode, target, completion) {

        let id = identifier;
        let isManagerCashDesk = false;
        if (identifier.hasPreffix("MNG_") == true){
            id = identifier.substr(4);
            isManagerCashDesk = true;
        }

        let sdf: MIODateFormatter = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;

        let cmd = new WebServiceCommand(this.authURL, "/api/" + identifierType + "/" + id + "/dashboard/salescomparationdays");
        cmd.method = "POST";
        cmd.params = {};
        cmd.params["from"] = sdf.stringFromDate(dayFrom);
        cmd.params["to"] = sdf.stringFromDate(dayTo);
        cmd.params["mode"] = mode;
        if (isManagerCashDesk) cmd.params["ismanager"] = isManagerCashDesk;

        cmd.execute(this, function (code, json) {
            this._parseChartsData(code, json, target, completion);
        });
    }

    downloadSalesPerHour(identifier, identifierType, dayFrom, dayTo, target, completion) {

        let sdf: MIODateFormatter = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;

        let cmd = new WebServiceCommand(this.authURL, "/api/" + identifierType + "/" + identifier + "/dashboard/salesbyhourtoday");
        cmd.method = "POST";
        cmd.params = { "from": sdf.stringFromDate(dayFrom), "to": sdf.stringFromDate(dayTo) };

        cmd.execute(this, function (code, json) {
            this._parseChartsData(code, json, target, completion);
        });
    }

    downloadTopProducts(identifier, identifierType, dayFrom, dayTo, target, completion) {

        let sdf: MIODateFormatter = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;

        let cmd = new WebServiceCommand(this.authURL, "/api/" + identifierType + "/" + identifier + "/dashboard/toptenproductstoday");
        cmd.method = "POST";
        cmd.params = { "from": sdf.stringFromDate(dayFrom), "to": sdf.stringFromDate(dayTo) };

        cmd.execute(this, function (code, json) {
            this._parseChartsData(code, json, target, completion);
        });
    }

    downloadTopWorkers(identifier, identifierType, dayFrom, dayTo, target, completion) {

        let sdf: MIODateFormatter = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;

        let cmd = new WebServiceCommand(this.authURL, "/api/" + identifierType + "/" + identifier + "/dashboard/toptenworkerstoday");
        cmd.method = "POST";
        cmd.params = { "from": sdf.stringFromDate(dayFrom), "to": sdf.stringFromDate(dayTo) };

        cmd.execute(this, function (code, json) {
            this._parseChartsData(code, json, target, completion);
        });
    }

    exportFile(identifier, identifierType, body, target, completion) {

        var cmd = new WebServiceCommand(this.authURL, "/api/" + identifierType + "/" + identifier + "/report/excel", true);
        cmd.method = "POST";
        cmd.params = body;

        cmd.execute(this, function (code, json) {

            if (completion != null && target != null)
                completion.call(target, code, json);
        });
    }

    getReportItems(target?, completion?) {

        if (this.token == null) return;

        let url = MIOURL.urlWithString(this.authURL);
        url = url.urlByAppendingPathComponent("api");
        url = url.urlByAppendingPathComponent(this.identifierType);
        url = url.urlByAppendingPathComponent(this.identifier);
        url = url.urlByAppendingPathComponent("/reportitems");
        
        let request = new MWSJSONRequest();
        request.initWithURL(url, null, "GET");
        request.setHeaderValue("application/json", "Content-Type");
        request.setHeaderValue("application/json", "Accept");
        request.setHeaderValue("Bearer " + this.token, "Authorization");
        if (SettingsHelper.sharedInstance().isNewServersLoaded) request.setHeaderValue("new-login", "DL-AUTH-SERVER");

        request.execute(this, function(code, data) {

            if (completion != null) {
                completion.call(target, code, data);
            }
        });
    }

    executeQuery(body, target, completion) {
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/executereportquery");
        cmd.method = "POST";
        cmd.params = body;

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json);
        });
    }

    executeReportScript(identifier, filters, logLevel, target, completion) {
        let url = MIOURL.urlWithString(this.authURL + "/api/" + this.identifierType + "/" + this.identifier + "/reportscriptitem-execute/" + identifier);

        let request = new MWSJSONRequest();
        request.initWithURL(url, null, "POST");
        let token = MIOUserDefaults.standardUserDefaults().valueForKey("LoginToken");        
        request.setHeaderValue("Bearer " + token, "Authorization");
        if (SettingsHelper.sharedInstance().isNewServersLoaded) request.setHeaderValue("new-login", "DL-AUTH-SERVER");        
    
        let body = {};
        body["id"] = identifier;
        body["log-level"] = logLevel;        
        body["filters"] = filters;
        request.body = body;

        request.execute(this, function (code, data) {
            if (target != null && completion != null)
                completion.call(target, code, data);
        });
    }

    executeNewReportScript(name:string, filters, logLevel, target, completion) {
        let url = MIOURL.urlWithString(this.scriptURL + "/" + this.identifierType + "/" + this.identifier + "/script/execute");

        let request = new MWSJSONRequest();
        request.initWithURL(url, null, "POST");
        let token = MIOUserDefaults.standardUserDefaults().valueForKey("LoginToken");
        request.setHeaderValue("Bearer " + token, "Authorization");
        if (SettingsHelper.sharedInstance().isNewServersLoaded) request.setHeaderValue("new-login", "DL-AUTH-SERVER");
    
        let body = {};
        body["name"] = name;
        body["log-level"] = logLevel;        
        if (filters != null) body["filters"] = filters;
        request.body = body;

        request.execute(this, function (code, data) {
            if (target != null && completion != null)
                completion.call(target, code, data);
        });
    }

    executeReportScriptAtPath(path:string, filters, target, completion){

        let url = MIOURL.urlWithString(this.scriptURL + "/" + this.identifierType + "/" + this.identifier + "/" + path);

        MIOLog(url.absoluteString);

        let request = new MWSJSONRequest();
        request.initWithURL(url, null, "POST");
        let token = MIOUserDefaults.standardUserDefaults().valueForKey("LoginToken");
        request.setHeaderValue("Bearer " + token, "Authorization");
        if (SettingsHelper.sharedInstance().isNewServersLoaded) request.setHeaderValue("new-login", "DL-AUTH-SERVER");

        let body = {};
        body["AppID"] = this.identifier;
        body["AppType"] = this.identifierType;
        body["filters"] = filters;
        request.body = body;

        request.execute(this, function (code, data) {
            if (target != null && completion != null)
                completion.call(target, code, data);
        });
    }


    getPayMethodsFromSessionID(sessionID:string, target, completion){

        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/cashdesksession/counts/" + sessionID);
        cmd.method = "GET";

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });
    }

    adjustSession(sessionID, countedValues, target, completion){

        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/cashdesksession/close");
        cmd.method = "POST";
        cmd.params = {
            "id": sessionID,
            "counts":countedValues
            }

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });
    }

    executeScript(script:DBScript, keysAndValues, target, completion){

        // convert options into sql
        // let sql = script.query;
        // let func = script.code;        

        // replace options key for the value
        let filters = [];
        for (let key in keysAndValues){
            let value = keysAndValues[key];
            filters.push({"key":key, "value":value});
            // let regex = new RegExp("{{" + key + "}}", "gi");
            // if (sql != null) sql = sql.replace(regex, value);
            // if (func != null) func = func.replace(regex, value);
        }

        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/dbtool/createelements");
        cmd.method = "POST";
        cmd.params = {};
        cmd.params["dbscriptid"] = script.identifier;
        cmd.params["dbfilters"] = filters;

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });        
    }

    downloadScriptResults(script:DBScript, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/dbtool/getelements/" + script.identifier);
        cmd.method = "GET";

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                
    }

    applyFunctionToScriptResults(script:DBScript, action, values, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/dbtool/applyaction");
        cmd.method = "POST";
        cmd.params = {};
        cmd.params["action"] = action;
        cmd.params["dbscriptid"] = script.identifier;
        if (values != null) cmd.params["elementIDs"] = values;
        

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                
    }

    uploadImage(file, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/image/upload");
        cmd.method = "POST";
        cmd.blobData = file;

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }

    orderNoteChangeStatus(orderNoteID:string, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/ordernote/process/" + orderNoteID);
        cmd.method = "GET";

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }

    chargeAmountToBooking(bookingID, amount, concept:string, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/redsys/booking/" + bookingID + "/charge");
        cmd.method = "POST";
        cmd.params = {"amount" : amount, "concept" : concept, "type": 2};        

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)

                completion.call(target, code, json);
        });                        
    }

    changeCashDeskSessionDate(sessionID:string, beginDate:Date, endDate:Date, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/cashdesksession/move");
        cmd.method = "POST";
        cmd.params = {}; 
        cmd.params["id"] = sessionID;
        cmd.params["begindate"] = this.serverDateFormatter.stringFromDate(beginDate);
        cmd.params["enddate"] = this.serverDateFormatter.stringFromDate(endDate);

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }

    inviteManagerUserFromWorkerID(workerID, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "employee/invite");
        cmd.method = "POST";
        cmd.params = {"id" : workerID};        

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }

    createInvoiceFromTicket(ticketID:string, client:Client, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/archivedticket/" + ticketID + "/generateinvoice");
        cmd.method = "POST";
        cmd.params = {};

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
        cmd.params["legaldate"] = ad.serverDateTimeFormatter.stringFromDate(new Date());        
        cmd.params["clientid"] = client.identifier;

        cmd.execute(this, function (code, json) {
            if (code != 200) AppHelper.showErrorMessage(null, "Invoice error", "Check if all the required info from a customer exists");
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }

    createInvoiceFromMultipleTickets(tickets, client:Client, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/archivedticket-generateinvoice");
        cmd.method = "POST";
        cmd.params = {};

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
        cmd.params["legaldate"] = ad.serverDateTimeFormatter.stringFromDate(new Date());        
        cmd.params["clientid"] = client.identifier;
        cmd.params["invoicelinesoption"] = 1;
        let ticketIDs = [];

        for (let index = 0; index < tickets.length; index++) {
            let t = tickets[index] as ArchivedDocument;
            ticketIDs.push(t.identifier);
        }
        cmd.params["archiveddocumentids"] = ticketIDs;

        cmd.execute(this, function (code, json) {
            if (code != 200) AppHelper.showErrorMessage(null, "Invoice error", "Check if all the required info from a customer exists");
            if (target != null && completion != null)
                this.updateObjectsFromWebService(code, json);
                completion.call(target, code, json["data"]);
        });                        
    }

    nullifyReceipt(identifier:string, target, completion) {
            
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/archiveddocument/" + identifier + "/nullify");
        cmd.method = "GET";
        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });        
    }
    
    //Used to create a "nullify" document from an existing document, creates negative lines of the same type in a document and in cashdesk
    nullifyInvoice(identifier:string, target, completion){

        let url = MIOURL.urlWithString(this.scriptURL + "/" + this.identifierType + "/" + this.identifier + "/python/nullify_document.py");
        let request = new MWSJSONRequest();
        request.initWithURL(url, {"filters": {"id": identifier}}, "POST");
    
        let token = MIOUserDefaults.standardUserDefaults().valueForKey("LoginToken");
        request.setHeaderValue("Bearer " + token, "Authorization");
        if (SettingsHelper.sharedInstance().isNewServersLoaded) request.setHeaderValue("new-login", "DL-AUTH-SERVER");
        
        request.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        }); 
    }

    integratorSync(integrator:string, endpoint:string, items, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/integrators/process/" + integrator.toLowerCase() + "/" + endpoint.toLocaleLowerCase());
        cmd.method = "POST";
        let ids = [];
        for (let index = 0; index < items.count; index++){
            let i = items[index];
            ids.addObject(i.identifier);
        }
        cmd.params = {};
        cmd.params["integratorssyncids"] = ids;

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }

    loadSyncs(integrator:string, endpoint:string, from:string, to:string, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/integrators/loadsync/" + integrator.toLocaleLowerCase() + "/" + endpoint.toLocaleLowerCase());
        cmd.method = "POST";
        cmd.params = {};
        cmd.params["begindate"] = from;
        cmd.params["enddate"] = to;        

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }

    syncAddToQueue(entityID:string, entityName:string, target, completion){                
        let url = MIOURL.urlWithString(this.scriptURL + "/" + this.identifierType + "/" + this.identifier + "/python/sync_db_entity.py");
        let request = new MWSJSONRequest();
        request.initWithURL(url, {"filters": {"id": entityID}}, "POST");
        
        let token = MIOUserDefaults.standardUserDefaults().valueForKey("LoginToken");
        request.setHeaderValue("Bearer " + token, "Authorization");
        if (SettingsHelper.sharedInstance().isNewServersLoaded) request.setHeaderValue("new-login", "DL-AUTH-SERVER");

        request.execute(this, function (code, data) {
            if (target != null && completion != null)
                completion.call(target, code, data);
        });

    }

    syncAddEntitiesToTransaction(entityName:string, target, completion){                
        let url = MIOURL.urlWithString(this.scriptURL + "/" + this.identifierType + "/" + this.identifier + "/python/sync_db_transaction.py");
        let request = new MWSJSONRequest();
        request.initWithURL(url, null, "POST");
        
        let token = MIOUserDefaults.standardUserDefaults().valueForKey("LoginToken");
        request.setHeaderValue("Bearer " + token, "Authorization");
        if (SettingsHelper.sharedInstance().isNewServersLoaded) request.setHeaderValue("new-login", "DL-AUTH-SERVER");

        request.execute(this, function (code, data) {
            if (target != null && completion != null)
                completion.call(target, code, data);
        });

    }

    syncQueueItem(items, target, completion){
        let url = MIOURL.urlWithString(this.apiURL + "/integrator/" + this.identifierType + "/" + this.identifier + "/SAP/sync");
        let request = new MWSJSONRequest();
        request.initWithURL(url, {"queues": items}, "POST");        
        
        let token = MIOUserDefaults.standardUserDefaults().valueForKey("LoginToken");
        request.setHeaderValue("Bearer " + token, "Authorization");
        if (SettingsHelper.sharedInstance().isNewServersLoaded) request.setHeaderValue("new-login", "DL-AUTH-SERVER");

        request.execute(this, function (code, data) {
            if (target != null && completion != null)
                completion.call(target, code, data);
        });        
    }


    //
    // PRODUCTS
    //

    changeCostPriceMassively(type:number, predicate:MIOPredicate, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/updateproducts");
        cmd.method = "POST";
        cmd.params = {};
        cmd.params["values"] = {"costtype" : type};
        if (predicate != null) cmd.params["where"] = null; // TODO: Convert to where

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        

    }

    //
    // STOCKS
    //

    processStockNote(identifier:string, target, completion){
  
        let request = null;
        // if (SettingsHelper.sharedInstance().isNewServersLoaded == false) {
        //     //old process stockNote script
        //     request = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/stocknote/" + identifier + "/process");
        //     request.method = "GET";
        // }
        // else {        
            let body = {}
            body["AppID"] = this.identifier;
            body["AppType"] = this.identifierType;
            body["filters"] = {"id": identifier};
    
            let url = MIOURL.urlWithString(this.scriptURL + "/" + this.identifierType + "/" + this.identifier + "/python/stocknote_process.py");
            request = new MWSJSONRequest();
            request.initWithURL(url, body, "POST");
        
            let token = MIOUserDefaults.standardUserDefaults().valueForKey("LoginToken");
            request.setHeaderValue("Bearer " + token, "Authorization");
            if (SettingsHelper.sharedInstance().isNewServersLoaded) request.setHeaderValue("new-login", "DL-AUTH-SERVER");
//        }

        request.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"], json);
        });                        
    }

    unprocessStockNote(identifier:string, target, completion){
        let cmd = new WebServiceCommand(this.scriptURL, "/api/" + this.identifierType + "/" + this.identifier + "/stocknote/" + identifier + "/deprocess");
        cmd.method = "GET";

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }

    deleteStockNote(identifier:string, target, completion){
  
        let request = null;
        // if (SettingsHelper.sharedInstance().isNewServersLoaded == false) {
        //     //old delete stockNote script
        //     DBHelper.removeNote(target.stockNote);
        // }
        // else {        
            let body = {}
            body["AppID"] = this.identifier;
            body["AppType"] = this.identifierType;
            body["filters"] = {"id": identifier};

            let url = MIOURL.urlWithString(this.scriptURL + "/" + this.identifierType + "/" + this.identifier + "/python/stocknote_delete.py");
            request = new MWSJSONRequest();
            request.initWithURL(url, body, "POST");
        
            let token = MIOUserDefaults.standardUserDefaults().valueForKey("LoginToken");
            request.setHeaderValue("Bearer " + token, "Authorization");
            if (SettingsHelper.sharedInstance().isNewServersLoaded) request.setHeaderValue("new-login", "DL-AUTH-SERVER");
            
            request.execute(this, function (code, json) {
                if (target != null && completion != null)
                    completion.call(target, code, json["data"]);
            }); 
//        }                  
    }

    exportStockNotePDF(noteID:string, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/stocknote/" + noteID + "/pdf", true);        
        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }

    importWorkersCSI(workers, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "integrators/import/csi/worker");        
        cmd.method = "POST";
        cmd.params = workers;

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }
    
    //
    // BANKS
    // 

    sendPaymentLink(email:string, name:string, amount, concept:string, info, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/" + this.identifierType + "/" + this.identifier + "/template/sendbyname/noentity-email-no-reference/es");
        cmd.method = "POST";
        cmd.params = {};
        cmd.params["email"] = email;
        cmd.params["name"] = name;
        cmd.params["subject"] = "Pago desde email";
        cmd.params["body"] = {};
        cmd.params["body"]["concept"] = concept;
        cmd.params["body"]["amount"] = amount;
        
        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }

    //
    // External PMS
    //

    searchRoomBooking(room:string, name:string, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/integrators/" + this.identifierType + "/" + this.identifier + "/hotel/reservation");
        cmd.method = "POST";
        if (room != null) cmd.params = {"room":room};
        else if (name != null) cmd.params = {"name":name};
        

        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }


    importRoomBooking(room:string, name:string, target, completion){
        let cmd = new WebServiceCommand(this.authURL, "/api/integrators/" + this.identifierType + "/" + this.identifier + "/hotel/reservation");
        cmd.method = "POST";
        cmd.params = {};
        // cmd.params["roomReferenceID"];
        // cmd.params["beginDate"];
        // cmd.params["endDate"];
        // cmd.params["clientName"];
        // cmd.params["clientPhone"];
        // cmd.params["clientEmail"];
        // cmd.params["bookingReferenceID"];
        // cmd.params["maxCredit"];
        
        cmd.execute(this, function (code, json) {
            if (target != null && completion != null)
                completion.call(target, code, json["data"]);
        });                        
    }

    //
    // WEB PERSISTENT STORE CACHE 
    //

    private updateObjectsFromWebService(code:number, data:any){        
        if (code != 200) return;

        let items = data["data"];            
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        for (let index = 0; index < items.length; index++){
            let item = items[index];
            let entityName = item["classname"];
            if (entityName == "BookingClient") entityName = "Client";
            let entity = ad.managedObjectModel.entitiesByName[entityName];
            ad.webPersistentStore.updateObjectsInContext([item], entity, ad.managedObjectContext, []);
        }        
    }

    //
    //
    // WEB PERSISTENT STORE WEBSERVICE REQUEST DELEGATE
    //
    //

    private safeEntities = ["Configuration", 
                            "Country", 
                            "Preset", 
                            "PaymentEntity", 
                            "TimeRange", 
                            "TimeRangeGroup", 
                            "ProductListItem", 
                            "Product", 
                            "ProductPlace",
                            "EllaborationCenter", 
                            "ProductWarehouse", 
                            "Warehouse",
                            "Tag",
                            "ProductModifierCategory",
                            "PayMethod",
                            "Category",
                            "StockCategory",
                            "ProductFormat",
                            "ProductFormatRate",
                            "ProductModifier",
                            "ProductRate",
                            "CategoryTag",
                            "Tax",
                            "SubTax",
                            "Rate",
                            "Component",
                            "CashDesk",
                            "CashDeskSession",
                            "CheckPoint",
                            "CashDeskLine",
                            "StockNote",
                            "StockNoteLine",
                            "IntegratorSAPStockNoteLine",
                            "IntegratorSAPStockNoteLineQueue",
                            "DBSyncEntity",
                            "IntegratorSync",
                            "Supplier",
                            "SupplierProduct",
                            "Client",
                            "DebtLine",
                            "EconomicAccountLine",
                            "LoyaltyAccountLine",
                            "EconomicEntity",
                            "Employee",
                            "WorkSession",
                            "PayMethodName",
                            "WorkerRole",
                            "Department",
                            "Address",
                            "PhoneNumber",
                            "StockInputFormat",
                            "InventoryTemplate",
                            "InventoryTemplateItem"];

    private isEntityAvailableInNewServer(entityName:string):boolean{
        return this.safeEntities.containsObject(entityName);
    }

    fetchRequestForWebStore(store: MWSPersistentStore, request: MIOPersistentStoreRequest, referenceID?: string, identifierType?, identifier?): MWSRequest {

        let entity = null;
        let entityName = null;
        if (request instanceof MIOFetchRequest) {
            let fr = request as MIOFetchRequest;
            entityName = fr.entityName;
            entity = fr.entity;
        }

        if (entityName == null) return null;

        let ignore = this.ignoreEntityName(entityName);
        if (ignore == true) return null;

        ignore = this.ignoreEntityNameForFetch(entityName);        
        if (ignore == true) return null;

        entityName = this.customEntityName(entityName, request);    

        let url:MIOURL = null;
        let customHTTPMethod = null;
        if (entityName == "Business") {
            url = MIOURL.urlWithString(this.authURL);
            url = url.urlByAppendingPathComponent("api");
            url = url.urlByAppendingPathComponent(entityName.toLocaleLowerCase());
        }
        else if (entityName == "UserBusiness") {
            url = MIOURL.urlWithString(this.authURL);
            url = url.urlByAppendingPathComponent(entityName.toLocaleLowerCase());
            customHTTPMethod = "GET";
        }
        else if (this.isEntityAvailableInNewServer(entityName)){
            url = MIOURL.urlWithString(this.apiURL);            
            url = url.urlByAppendingPathComponent(this.identifierType);
            url = url.urlByAppendingPathComponent(this.identifier);
            url = url.urlByAppendingPathComponent(entityName);
        }
        else {
            url = MIOURL.urlWithString(this.authURL);
            url = url.urlByAppendingPathComponent("api");
            url = url.urlByAppendingPathComponent(this.identifierType);
            url = url.urlByAppendingPathComponent(this.identifier);
            url = url.urlByAppendingPathComponent(entityName.toLocaleLowerCase());
        }        
        
        if (referenceID != null) {
            url = url.urlByAppendingPathComponent(referenceID);
        }

        let select = null;
        let where = null;
        let sortBy = null;                
        let groupBy = null;        
        let limit = 0;
        let offset = 0;
        let includeRelationships = null;

        if (request instanceof MIOFetchRequest) {
            let fr = request as MIOFetchRequest;
            select = this.selectValuesForRequest(request);
            where = this.whereValuesForRequest(request);
            sortBy = this.sortValuesForRequest(request);
            groupBy = this.groupValuesForRequest(request);
            limit = fr.fetchLimit;
            offset = fr.fetchOffset;
            if (fr.relationshipKeyPathsForPrefetching.length > 0)
                includeRelationships = fr.relationshipKeyPathsForPrefetching;
        }

        let httpMethod = "GET";
        if (where != null || sortBy != null || limit > 0) httpMethod = "POST";
        if (referenceID != null) httpMethod = "GET";
        if (customHTTPMethod != null) httpMethod = customHTTPMethod;

        let body = null;
        if (httpMethod == "POST") {
            body = {};
            if (select != null) body["select"] = select;
            if (where != null) body["where"] = where;
            if (sortBy != null) body["orderby"] = sortBy;
            if (groupBy != null) body["groupby"] = groupBy;
            if (limit > 0) body["limit"] = limit;
            if (limit > 0 && offset > 0) {
                body["page"] = (offset / limit) + 1;
            }
            if (includeRelationships != null) {
                let array = [];
                for (let index = 0; index < includeRelationships.length; index++) {
                    let relName = includeRelationships[index];
                    let serverName = this.serverRelationshipName(null, relName, entity);
                    array.push(serverName);
                }
                body["includeRelationships"] = array;
            }
        }

        let urlRequest = new MWSJSONRequest();
        urlRequest.initWithURL(url, body, httpMethod);
        urlRequest.setHeaderValue("application/json", "Content-Type");
        urlRequest.setHeaderValue("application/json", "Accept");
        if (this.isEntityAvailableInNewServer(entityName) && this.identifierType == "place") {
            urlRequest.setHeaderValue(this.identifier, "DL-PLACE-ID");
            urlRequest.setHeaderValue("00000000-0000-0000-0000-000000000000", "DL-WORKER-ID");
            let user = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginEmail");
            urlRequest.setHeaderValue(user, "DL-WORKER-NAME");
        }

        
        if (this.token != null) {
            urlRequest.setHeaderValue("Bearer " + this.token, "Authorization");            
            if (SettingsHelper.sharedInstance().isNewServersLoaded) urlRequest.setHeaderValue("new-login", "DL-AUTH-SERVER");
        }
        
        return urlRequest;
    }

    requestDidFinishForWebStore(store: MWSPersistentStore, request: MIOPersistentStoreRequest, code, data?) {

        if (code != 200) {
            return [false, []];
        }

        if (data == null) {
            return [false, []];
        }

        let items = data["data"];
        
        //HACK: The country structure is not standard
        if (request instanceof MIOFetchRequest) {
            let r = request as MIOFetchRequest;
            if (r.entityName == "Language") {
                items = data;
            }
        }

        if (items == null) {
            return [false, []];
        }

        return [true, items];
    }

    serverIDForItem(store: MWSPersistentStore, item, entityName:string) {

        if (entityName == "Country") return item["iso2"];

        let id = item["id"];
        if (id == null) id = item["identifier"];

        if (typeof id !== "string") id = id.toString();
        return id;
    }

    serverVersionNumberForItem(store: MWSPersistentStore, item, entityName:string) {
        let version = item["version"] != null ? item["version"] : 1;

        return version == 0 ? 1 : version;
    }

    serverIDForObject(store: MWSPersistentStore, object:MIOManagedObject) {
        let serverID = object.valueForKey("identifier");
        if (serverID == null) {  
            serverID = MIOUUID.UUID().UUIDString;
            object.setValueForKey(serverID, "identifier");
            MIOLog("WebService: The object identifier is null, generating new one");
        }
        return serverID;
    }

    serverValuesForObject(store: MWSPersistentStore, object:MIOManagedObject, onlyChanges:boolean){
        return this.serverDataFromObject(object, onlyChanges, []);
    }

    serverRelationshipName(store: MWSPersistentStore, relationshipName: string, entity: MIOEntityDescription) {

        let keys = relationshipName.split('.');

        let key = keys[0];
        let rel:MIORelationshipDescription = entity.relationshipsByName[key];
        let name = rel.serverName;

        if (keys.length > 1) {
            let keyPath = relationshipName.substring(key.length + 1);
            name += "." + this.serverRelationshipName(store, keyPath, rel.destinationEntity);
        }

        return name;
    }

    private customEntityName(entityName:string, request?:MIOPersistentStoreRequest){
        if (entityName == "DBMappingEntity") return "integratorsmappings";
        else if (entityName == "ProductComponent") return "view/product_ingredients";        
        else if (entityName.hasPreffix("DBMappingEntity")) {
            let name = entityName.substring(15);
            return "mapping" + name.toLocaleLowerCase();
        }    
        else if (entityName == "IntegratorsSync"){
            let r = request as MIOFetchRequest;            
            let integrator = /integrator\s+=*\s+'(\w+)'/g.exec(r.predicate.predicateFormat)[1];
            let entity = /entityName\s+=*\s+'(\w+)'/g.exec(r.predicate.predicateFormat)[1];
            return "integrators/sync/" + integrator.toLocaleLowerCase() + "/" + entity.toLocaleLowerCase();
        }
        else if (entityName == "UserPermission") return "user_permission";
        else if (entityName == "ReportFilter") return "report_filter";
        else if (entityName == "EmailTemplate") return "emailtemplates";
        else if (entityName == "DBSyncEntity") return "sync_db_entity";
        else if (entityName == "DBSyncTransaction") return "sync_db_transaction";        

        return entityName;
    }

    private ignoreEntityName(entityName: string) {

        let entities = [
            "Module",            
            "Section",
            "Permission",
            "CashDeskEntity",
            "CashDeskSummaryItem",
            "CashDeskSessionSummaryItem",            
            "FilterTicket",
            "FilterTicketGroupLine",
            "FilterTicketLine",
            "SupplierInvoice",
            "SupplierInvoiceGroupLine",
            "SupplierInvoiceLine",
            "StockProduct",
            "LapsopayLocation",
            "LapsopayPlace",
            "ReportItem",
            "ReportChartItem",
            "ReportListItem"            
        ];

        let index = entities.indexOf(entityName);
        return index == -1 ? false : true;
    }

    private ignoreEntityNameForFetch(entityName: string) {
        let entities = [];
        
        let index = entities.indexOf(entityName);
        return index == -1 ? false : true;
    }

    private ignoreEntityNameForDelete(entityName: string) {

        let entities = [
            "User",
            "StockAnnotationView",
            "ProductListItem",
            "CashDeskOperation",
            "ProductComponent",
            "PermissionData",
            "IntegratorSAPStockNoteLine",
            "IntegratorSAPStockNoteLineQueue"
        ];
        let index = entities.indexOf(entityName);
        return index == -1 ? false : true;
    }

    private ignoreEntityNameForUpdate(entityName: string) {

        let entities = [
            "StockAnnotationView",
            "ProductListItem",
            "CashDeskOperation",
            "ProductComponent",
            "PermissionData",
            "IntegratorSAPStockNoteLine",
            "IntegratorSAPStockNoteLineQueue"
        ];
        let index = entities.indexOf(entityName);
        return index == -1 ? false : true;
    }

    private ignoreEntityNameForInsert(entityName: string) {

        let entities = ["Business",
            "StockAnnotationView",
            "ProductListItem",
            "CashDeskOperation",
            "ProductComponent",
            "PermissionData",
            "IntegratorSAPStockNoteLine",
            "IntegratorSAPStockNoteLineQueue"
        ];
        let index = entities.indexOf(entityName);
        return index == -1 ? false : true;
    }

    selectValuesForRequest(request: MIOFetchRequest) {

        let entityName = request.entityName;
        if (entityName == "StockAnnotationView") {
        return ["productid as id",
                "productid", 
                "SUM(version) as version",
                "MAX(productname) as  productname",
                "MAX(warehouseid) as warehouseid",
                "SUM(minmeasurequantity) as minmeasurequantity",
                "MAX(minmeasuretype) as minmeasuretype",
                "SUM(measurequantity) as measurequantity",
                "SUM(productquantity) as productquantity",
                "MAX(productcontainerquantity) as productcontainerquantity",
                "MAX(productmeasuretype) as productmeasuretype",
                "MAX(productcontainermeasuretype) as productcontainermeasuretype",
                "MAX(orderedquantity) as orderedquantity",
                "MAX(productmin) as productmin",
                "MAX(productmax) as productmax",
                "MAX(isavailable::integer) as isavailable",
                "AVG(costaverageprice) as costaverageprice",
                "AVG(costlastprice) as costlastprice",
                "AVG(costprice) as costprice"];                
        }

        return null;
    }

    private groupValuesForRequest(request: MIOFetchRequest) {

        let entityName = request.entityName;
        if (entityName == "StockAnnotationView") {
            return ["productid"];
        }

        return null;
    }

    private sortValuesForRequest(request: MIOFetchRequest) {

        let sorts = request.sortDescriptors;
        if (sorts == null) return null;

        let array = [];
        for (let index = 0; index < sorts.length; index++) {
            let sd: MIOSortDescriptor = sorts[index];
            let item = {};
            let key = this.transformSortKey(sd.key, request.entity);
            if (key == null) continue;
            let value = sd.ascending ? "asc" : "desc";
            item[key] = value;
            array.push(item);
        }

        return array;
    }

    private transformSortKey(key, entity: MIOEntityDescription) {

        let serverKey = entity.serverAttributeName(key);
        //        if (serverKey == null) return key;

        return serverKey;
    }

    private whereValuesForRequest(request: MIOFetchRequest) {

        let entity = request.entity;
        let predicate = request.predicate;

        if (predicate == null) return null;
        return this.parsePredicates(predicate.predicateGroup.predicates, entity);
    }

    private parsePredicates(predicates, entity: MIOEntityDescription) {
        let result = [];

        for (let count = 0; count < predicates.length; count++) {
            let o = predicates[count];

            if (o instanceof MIOPredicateGroup) {
                let group = o as MIOPredicateGroup;
                let i = {};
                i["type"] = "group";
                i["values"] = this.parsePredicates(group.predicates, entity);
                result.push(i);
            }
            else if (o instanceof MIOPredicateItem) {
                //result = o.evaluateObject(object);
                let item = o as MIOPredicateItem;
                let mapItemPredicateFormat = this.filterServerAttributeKey(entity.managedObjectClassName, item.key, item.value, item.comparator);
                if (mapItemPredicateFormat == null) {
                    let i = {};
                    i["type"] = "item";
                    this.transformPredicateItem(i, item, entity);
                    result.push(i);
                } else {
                    let p = MIOPredicate.predicateWithFormat(mapItemPredicateFormat);
                    let group: MIOPredicateGroup = p.predicateGroup;
                    let i = {};
                    i["type"] = "group";
                    i["values"] = this.parsePredicates(group.predicates, entity);
                    result.push(i);
                }
            }
            else if (o instanceof MIOPredicateOperator) {
                let op = o as MIOPredicateOperator;
                let i = {};
                i["type"] = "operator";
                i["value"] = this.transfromPredicateOperator(op.type);
                result.push(i)
            }
        }

        return result;
    }

    private transformPredicateItem(i, item, entity) {

        let value = item.value;
        let cmp = item.comparator;        

        let key = item.key;
        if (item.bitwiseOperation != null) {
            let index = item.key.indexOf(" ");
            let rigthExpresion = item.key.substring(index);
            key = item.key.substring(0, index);
            key = this.transformPredicateKeyPathAndValue(key, value, i, entity);
            i["key"] = "#" + key + " " + rigthExpresion;
        }        
        else {
            i["key"] = this.transformPredicateKeyPathAndValue(key, value, i, entity);
        }
                
        if (key == 1) {
            // ignore on server that key
            i["comparator"] = "=";
            i["value"] = 1;                
        }
        else {
            i["comparator"] = this.transfromPredicateComparator(cmp, value);
            //i["value"] = this.transformPredicateValue(item.value, entity.propertiesByName[key]);
        }
    }

    private transformPredicateKeyPathAndValue(keyPath:string, value:string, item:any, entity: MIOEntityDescription) {

        let serverKey = null;
        // Check server relationship        
        let keys = keyPath.split('.');

        // if (keys.length > 2) {
        //     throw new Error("MIOWebServicePersistentStore: It's not supported a key path with more than 2 keys.");
        // }        
        if (keys.length == 1) {            
            let property = entity.propertiesByName[keyPath];
            if (property instanceof MIOAttributeDescription) serverKey = entity.serverAttributeName(keyPath);
            else if (property instanceof MIORelationshipDescription) serverKey = entity.serverRelationshipName(keyPath);
            item["value"] = this.transformPredicateValue(value, property);
        }
        else {
            let relKey = keys[0];
            let key = keys[1];

            if (key == "identifier") {
                serverKey = entity.serverRelationshipName(relKey);
                item["value"] = value;
            }
            else {
                serverKey = entity.serverRelationshipName(relKey);
                let relKeyPath = keyPath.substr(relKey.length + 1);
                let rel = entity.relationshipsByName[relKey];                
                let relEntity = MIOEntityDescription.entityForNameInManagedObjectContext(rel.destinationEntityName, MUIWebApplication.sharedInstance().delegate.managedObjectContext);
                serverKey += "." + this.transformPredicateKeyPathAndValue(relKeyPath, value, item, relEntity);
            }
        }

        if (serverKey == null) {
            // HACK DEL GORDO:
            if (entity.name == "DebtLine" && keyPath == "pendingValue") serverKey = "#debtvalue-payedvalue";
            else throw new Error("MIOWebServicePersistentStore: Attribute or Relationship server key (" + keyPath + ") is invalid.");
        }

        return serverKey;
    }

    private transfromPredicateComparator(cmp, value?): string {

        switch (cmp) {

            case MIOPredicateComparatorType.Equal:
                if (value == null) return "is null";
                else return "=";

            case MIOPredicateComparatorType.Less:
                return "<";

            case MIOPredicateComparatorType.LessOrEqual:
                return "<=";

            case MIOPredicateComparatorType.Greater:
                return ">";

            case MIOPredicateComparatorType.GreaterOrEqual:
                return ">=";

            case MIOPredicateComparatorType.Distinct:
                if (value == null) return "not null";
                else return "!=";

            case MIOPredicateComparatorType.Contains:
                return "contains";

            case MIOPredicateComparatorType.NotContains:
                return "not contains";
        }

        return "";
    }

    private transfromPredicateOperator(op): string {

        switch (op) {

            case MIOPredicateOperatorType.AND:
                return "and";

            case MIOPredicateOperatorType.OR:
                return "or";
        }

        return "";
    }

    private transformPredicateValue(value:string, attribute:MIOAttributeDescription):any{
        if (value == null || value == "null") return null;

        switch (attribute.attributeType){
            case MIOAttributeType.Boolean:
                return value == "1" ? true : false;
                                            
            case MIOAttributeType.Number:
            case MIOAttributeType.Float:
                return parseFloat(value);

            case MIOAttributeType.Integer:
                return parseInt(value);
        }

        return value;
    }

    filterServerAttributeKey(entityName, property, value, comparator) {

        /*if ((entityName == "SupplierNote" || entityName == "InventoryNote" || entityName == "MovementNote") && property == "noteIdString") {

            let array = value.split('-');
            if (array.length == 1) {
                let v = parseInt(value);
                if (isNaN(v) == false) {
                    return ("prefix CONTAINS '" + value + "' OR noteId == " + parseInt(value));
                }
                else {
                    return ("prefix CONTAINS '" + value + "'");
                }
            }
            else {
                let prefix = array[0];
                let noteID = array[1];

                if (noteID == "") {
                    return ("prefix CONTAINS '" + prefix + "'");
                }
                else {
                    return ("prefix CONTAINS '" + prefix + "' AND noteId == " + parseInt(noteID));
                }
            }
        }*/

        return null;
    }

    insertRequestForWebStore(store: MWSPersistentStore, object: MIOManagedObject, dependencyIDs) {

        let entityName = object.entity.name;
        if (entityName == null) return null;

        let ignore = this.ignoreEntityName(entityName);
        if (ignore == true) return null;

        ignore = this.ignoreEntityNameForInsert(entityName);        
        if (ignore == true) return null;

        entityName = this.customEntityName(entityName);

        if (this.identifier == null || this.identifierType == null) return null;

        let url:MIOURL = null;
        if (entityName == "Business") {
            url = MIOURL.urlWithString(this.authURL);
            url = url.urlByAppendingPathComponent("api");
            url = url.urlByAppendingPathComponent(this.identifierType);
            url = url.urlByAppendingPathComponent(this.identifier);
            url = url.urlByAppendingPathComponent(entityName.toLocaleLowerCase());
        }
        else if (this.isEntityAvailableInNewServer(entityName)){
            url = MIOURL.urlWithString(this.apiURL);
            url = url.urlByAppendingPathComponent(this.identifierType);
            url = url.urlByAppendingPathComponent(this.identifier);
            url = url.urlByAppendingPathComponent(entityName);
        }
        else {
            url = MIOURL.urlWithString(this.authURL);
            url = url.urlByAppendingPathComponent("api");
            url = url.urlByAppendingPathComponent(this.identifierType);
            url = url.urlByAppendingPathComponent(this.identifier);
            url = url.urlByAppendingPathComponent(entityName.toLocaleLowerCase());
        }                

        let httpMethod = "PUT";
        let body = this.serverDataFromObject(object, false, dependencyIDs);
        //body["id"] = object.valueForKey("identifier");

        let urlRequest = new MWSJSONRequest();
        urlRequest.initWithURL(url, body, httpMethod);
        urlRequest.setHeaderValue("application/json", "Content-Type");
        urlRequest.setHeaderValue("application/json", "Accept");
        if (this.isEntityAvailableInNewServer(entityName) && this.identifierType == "place") {
            urlRequest.setHeaderValue(this.identifier, "DL-PLACE-ID");
            urlRequest.setHeaderValue("00000000-0000-0000-0000-000000000000", "DL-WORKER-ID");
            let user = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginEmail");
            urlRequest.setHeaderValue(user, "DL-WORKER-NAME");
        }


        if (this.token != null) {
            urlRequest.setHeaderValue("Bearer " + this.token, "Authorization");
            if (SettingsHelper.sharedInstance().isNewServersLoaded) urlRequest.setHeaderValue("new-login", "DL-AUTH-SERVER");
        }

        return urlRequest;
    }

    updateRequestForWebStore(store: MWSPersistentStore, object: MIOManagedObject, dependencyIDs) {

        let entityName = object.entity.name;
        if (entityName == null) return null;

        let ignore = this.ignoreEntityName(entityName);
        if (ignore == true) return null;

        ignore = this.ignoreEntityNameForUpdate(entityName);        
        if (ignore == true) return null;

        entityName = this.customEntityName(entityName);

        if (this.identifier == null || this.identifierType == null) return null;
        
        let url:MIOURL = null;
        if (entityName == "Business") {
            url = MIOURL.urlWithString(this.authURL);
            url = url.urlByAppendingPathComponent("api");
            url = url.urlByAppendingPathComponent(this.identifierType);
            url = url.urlByAppendingPathComponent(this.identifier);
            url = url.urlByAppendingPathComponent(entityName.toLocaleLowerCase());
        }
        else if (this.isEntityAvailableInNewServer(entityName)){
            url = MIOURL.urlWithString(this.apiURL);
            url = url.urlByAppendingPathComponent(this.identifierType);
            url = url.urlByAppendingPathComponent(this.identifier);
            url = url.urlByAppendingPathComponent(entityName);
        }
        else {
            url = MIOURL.urlWithString(this.authURL);
            url = url.urlByAppendingPathComponent("api");
            url = url.urlByAppendingPathComponent(this.identifierType);
            url = url.urlByAppendingPathComponent(this.identifier);
            url = url.urlByAppendingPathComponent(entityName.toLocaleLowerCase());
        }                
                
        let referenceID = object.valueForKey("identifier");
        url = url.urlByAppendingPathComponent(referenceID);

        let httpMethod = "PATCH";
        let body = this.serverDataFromObject(object, true, dependencyIDs);
        //body["id"] = referenceID.toUpperCase();

        let urlRequest = new MWSJSONRequest();
        urlRequest.initWithURL(url, body, httpMethod);
        urlRequest.setHeaderValue("application/json", "Content-Type");
        urlRequest.setHeaderValue("application/json", "Accept");
        if (this.isEntityAvailableInNewServer(entityName) && this.identifierType == "place") {
            urlRequest.setHeaderValue(this.identifier, "DL-PLACE-ID");
            urlRequest.setHeaderValue("00000000-0000-0000-0000-000000000000", "DL-WORKER-ID");
            let user = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginEmail");
            urlRequest.setHeaderValue(user, "DL-WORKER-NAME");
        }

        if (this.token != null) {
            urlRequest.setHeaderValue("Bearer " + this.token, "Authorization");
            if (SettingsHelper.sharedInstance().isNewServersLoaded) urlRequest.setHeaderValue("new-login", "DL-AUTH-SERVER");
        }

        return urlRequest;
    }

    deleteRequestForWebStore(store: MWSPersistentStore, object: MIOManagedObject) {

        let entityName = object.entity.name;
        if (entityName == null) return null;

        let ignore = this.ignoreEntityName(entityName);        
        if (ignore == true) return null;

        ignore = this.ignoreEntityNameForDelete(entityName);        
        if (ignore == true) return null;

        entityName = this.customEntityName(entityName);

        if (this.identifier == null || this.identifierType == null) return null;

        let url:MIOURL = null;
        if (this.isEntityAvailableInNewServer(entityName)){
            url = MIOURL.urlWithString(this.apiURL);
            url = url.urlByAppendingPathComponent(this.identifierType);
            url = url.urlByAppendingPathComponent(this.identifier);
            url = url.urlByAppendingPathComponent(entityName);
        }
        else {
            url = MIOURL.urlWithString(this.authURL);
            url = url.urlByAppendingPathComponent("api");
            url = url.urlByAppendingPathComponent(this.identifierType);
            url = url.urlByAppendingPathComponent(this.identifier);
            url = url.urlByAppendingPathComponent(entityName.toLocaleLowerCase());
        }                

        let serverID = object.valueForKey("identifier");
        url = url.urlByAppendingPathComponent(serverID);

        let httpMethod = "DELETE";
        //var body = this.serverDataFromObject(object, true, referenceID, dependencyIDs);
        //item["id"] = referenceID.toUpperCase();    

        let urlRequest = new MWSJSONRequest();
        urlRequest.initWithURL(url, null, httpMethod);
        urlRequest.setHeaderValue("application/json", "Content-Type");
        if (this.isEntityAvailableInNewServer(entityName) && this.identifierType == "place") {
            urlRequest.setHeaderValue(this.identifier, "DL-PLACE-ID");
            urlRequest.setHeaderValue("00000000-0000-0000-0000-000000000000", "DL-WORKER-ID");
            let user = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginEmail");
            urlRequest.setHeaderValue(user, "DL-WORKER-NAME");
        }

        if (this.token != null) {
            urlRequest.setHeaderValue("Bearer " + this.token, "Authorization");
            if (SettingsHelper.sharedInstance().isNewServersLoaded) urlRequest.setHeaderValue("new-login", "DL-AUTH-SERVER");
        }

        return urlRequest;
    }

    //
    // Managed Objects -> Server objects
    //
    private serverDataFromObject(obj: MIOManagedObject, onlyChanges: boolean, dependencies) {

        let entityName = obj.entity.name;
        let ed: MIOEntityDescription = obj.entity;

        let item = {};        

        let changedValues = obj.changedValues;
        for (let key in changedValues) {
            let attr = ed.attributesByName[key];
            if (attr != null) {
                this.serverValueForAttribute(attr, attr.serverName, item, changedValues[key]);
            }
            else {
                let rel = ed.relationshipsByName[key] as MIORelationshipDescription;
                if (rel != null) {
                    this.serverRelationship(rel, item, obj.valueForKey(key), dependencies);
                }
            }
        }

        return item;
    }

    private serverDateFormatter = MIOISO8601DateFormatter.iso8601DateFormatter();

    private serverValueForAttribute(attribute: MIOAttributeDescription, servername, item, value) {

        //if (attribute.name == "identifier") return;

        if (attribute.syncable == false) return;

        if (value == null && attribute.optional == false) {
            throw new Error("MIOWebPersistentStore: Couldn't set attribute value. Value is nil and it's not optional.");
        }

        //if (value == null) return;

        let type = attribute.attributeType;        
        if (value instanceof MIONull) {
            item[servername] = null;
        }
        else if(type == MIOAttributeType.Date){
            item[servername] = this.serverDateFormatter.stringFromDate(value);            
        }
        else {
            item[servername] = value;
        }
    }

    private serverRelationship(relationship:MIORelationshipDescription, item, value, dependencies) {

        if (value == null) {
            item[relationship.serverName] = null;
            return;
        }

        if (relationship.isToMany == false) {

            // Expected object
            let obj: MIOManagedObject = value as MIOManagedObject;
            let referenceID = this.serverIDForObject(null, obj);
            item[relationship.serverName] = referenceID;
            // if (relationship.inverseRelationship == null)
            //     dependencies.push(referenceID);    
            if (relationship.optional == false) {dependencies.push(referenceID);}
        }
        else {

            let objects: MIOManagedObjectSet = value;
            if (objects == null) return;

            let array = [];
            for (let index = 0; index < objects.length; index++) {

                let obj: MIOManagedObject = objects.objectAtIndex(index);
                let referenceID = this.serverIDForObject(null, obj);

                array.push(referenceID);
                // if (relationship.inverseRelationship == null)
                //     dependencies.push(referenceID);
            }

            item[relationship.serverName] = array;
        }
    }
}


