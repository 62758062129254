
enum CashDeskSessionStatusFilterType
{
    All,
    Open,
	NotCounted,
	Close,
	OpenAndNotCounted
}

class CashDeskSessionListViewController extends MUIViewController
{       
    private nameColumnTextField:ColumnFilterTextField = null;

    private tableView:MUITableView = null;
    private activityIndicator:MUIActivityIndicatorView = null;
    
    private header:CashDeskSessionListHeaderView = null;
    private footer:CashDeskSessionListFooterView = null;
        
    private dateTimeFrom = null;
    private dateTimeTo = null;
    private mode = null;

    private sdt = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;

    private filterController:ColumnFilterController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);
        
        this.nameColumnTextField = MUIOutlet(this, "name-filter-tf", "ColumnFilterTextField");
        this.nameColumnTextField.filterController = this.filterController;
        this.nameColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "name", null, null);

        this.header = MUIOutlet(this, "header-view", "CashDeskSessionListHeaderView");
        this.header.setHidden(true);

        this.footer = MUIOutlet(this, "footer-view", "CashDeskSessionListFooterView");
        this.footer.setHidden(true);

        this.tableView = MUIOutlet(this, 'table-view', 'MUITableView');

        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        
        // this.activityIndicator = MUIOutlet(this, 'cdsv_activity-indicator','MUIActivityIndicatorView');
        // this.activityIndicator.setHidden(true);
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }
    
    private cashDeskEntity:CashDeskEntity = null;    
    setItems(cashDeskEntity:CashDeskEntity, dateTimeFrom, dateTimeTo, mode){        
        this.cashDeskEntity = cashDeskEntity;
        this.dateTimeFrom = dateTimeFrom;
        this.dateTimeTo = dateTimeTo;
        this.mode = mode;
        this.updateUI();
    }

    private statusFilter = CashDeskSessionStatusFilterType.All;
    set filter(value:CashDeskSessionStatusFilterType){
        this.statusFilter = value;
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this.cashDeskEntity == null) return;        

        this.fetchedResultsController = null;
        this.tableView.reloadData();                
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('CashDeskSessionListCell') as CashDeskSessionListCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
        cell.item = item;
        cell.delegate = this;
    
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let session = this.fetchedResultsController.objectAtIndexPath(indexPath) as CashDeskSession;    

        let vc = new CashDeskSessionViewController('cash-desk-session-view');
        vc.initWithResource('layout/cashdesk/CashDeskSessionView.html');
        vc.session = session;

        this.parentViewController.parentViewController.navigationController.pushViewController(vc, true);

        tableView.deselectCellAtIndexPath(indexPath);
    }
    
    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('CashDeskSession');    
        fetchRequest.sortDescriptors = [    MIOSortDescriptor.sortDescriptorWithKey('deviceString', true),
                                            MIOSortDescriptor.sortDescriptorWithKey('beginDate', true)];
    
        let filterString = "";
        if (this.cashDeskEntity.type == CashDeskEntityType.TypePlace)
            filterString += "placeID == " + this.cashDeskEntity.identifier;
        else if(this.cashDeskEntity.type == CashDeskEntityType.TypeDevice)
            filterString += ("appID == " + this.cashDeskEntity.idApp);
        else if(this.cashDeskEntity.type == 3)
            filterString += ("isManager == true");

        filterString += " AND (serverSessionBeginDate >= '" + this.sdt.stringFromDate(this.dateTimeFrom) + "' AND serverSessionBeginDate <= '" + this.sdt.stringFromDate(this.dateTimeTo) + "')"; 

        if (this.statusFilter == CashDeskSessionStatusFilterType.Open) filterString += " AND isOpen == true";
        else if (this.statusFilter == CashDeskSessionStatusFilterType.NotCounted) filterString += " AND isOpen == false AND isCashCounted == false";
		else if (this.statusFilter == CashDeskSessionStatusFilterType.Close) filterString += " AND isOpen == false AND isCashCounted == true";
		else if (this.statusFilter == CashDeskSessionStatusFilterType.OpenAndNotCounted) filterString += " AND isCashCounted == false";

        let filterPredicateFormat = this.filterController.filterPredicateFormat();
        if (filterPredicateFormat != null){
            filterString += " AND (" + filterPredicateFormat + ")";
        }
    
        fetchRequest.predicate = MIOPredicate.predicateWithFormat(filterString);
        fetchRequest.relationshipKeyPathsForPrefetching = ["cashDesk", "sessionBalance"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "deviceString");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
}
