

// Generated class RelationBookingDay_ManagedObject

class RelationBookingDay_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: day
    set day(value:string) {
        this.setValueForKey(value, 'day');
    }
    get day():string {
        return this.valueForKey('day');
    }
    set dayPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'day');
    }
    get dayPrimitiveValue():string {
        return this.primitiveValueForKey('day');
    }

    // Property: zoneID
    set zoneID(value:string) {
        this.setValueForKey(value, 'zoneID');
    }
    get zoneID():string {
        return this.valueForKey('zoneID');
    }
    set zoneIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'zoneID');
    }
    get zoneIDPrimitiveValue():string {
        return this.primitiveValueForKey('zoneID');
    }

    // Property: maxPax
    set maxPax(value:number) {
        this.setValueForKey(value, 'maxPax');
    }
    get maxPax():number {
        return this.valueForKey('maxPax');
    }
    set maxPaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maxPax');
    }
    get maxPaxPrimitiveValue():number {
        return this.primitiveValueForKey('maxPax');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: chargePrice
    set chargePrice(value:string) {
        this.setValueForKey(value, 'chargePrice');
    }
    get chargePrice():string {
        return this.valueForKey('chargePrice');
    }
    set chargePricePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'chargePrice');
    }
    get chargePricePrimitiveValue():string {
        return this.primitiveValueForKey('chargePrice');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }

    // Relationship: locationTypes
    protected _locationTypes:MIOManagedObjectSet = null;
    get locationTypes():MIOManagedObjectSet {
        return this.valueForKey('locationTypes');
    }
    addLocationTypesObject(value:BookingLocationType) {
        this._addObjectForKey(value, 'locationTypes');
    }
    removeLocationTypesObject(value:BookingLocationType) {
        this._removeObjectForKey(value, 'locationTypes');
    }
}
