

/// <reference path="EconomicEntity.ts" />

// Generated class LegalEntity_ManagedObject

class LegalEntity_ManagedObject extends EconomicEntity
{

    // Property: document
    set document(value:string) {
        this.setValueForKey(value, 'document');
    }
    get document():string {
        return this.valueForKey('document');
    }
    set documentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'document');
    }
    get documentPrimitiveValue():string {
        return this.primitiveValueForKey('document');
    }

    // Property: email
    set email(value:string) {
        this.setValueForKey(value, 'email');
    }
    get email():string {
        return this.valueForKey('email');
    }
    set emailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'email');
    }
    get emailPrimitiveValue():string {
        return this.primitiveValueForKey('email');
    }

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: image
    set image(value:string) {
        this.setValueForKey(value, 'image');
    }
    get image():string {
        return this.valueForKey('image');
    }
    set imagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'image');
    }
    get imagePrimitiveValue():string {
        return this.primitiveValueForKey('image');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }
    // Relationship: address
    set address(value:Address) {
        this.setValueForKey(value, 'address');
    }
    get address():Address {
        return this.valueForKey('address') as Address;
    }

    // Relationship: cards
    protected _cards:MIOManagedObjectSet = null;
    get cards():MIOManagedObjectSet {
        return this.valueForKey('cards');
    }
    addCardsObject(value:Card) {
        this._addObjectForKey(value, 'cards');
    }
    removeCardsObject(value:Card) {
        this._removeObjectForKey(value, 'cards');
    }
    // Relationship: mobilePhone
    set mobilePhone(value:PhoneNumber) {
        this.setValueForKey(value, 'mobilePhone');
    }
    get mobilePhone():PhoneNumber {
        return this.valueForKey('mobilePhone') as PhoneNumber;
    }
    // Relationship: phone
    set phone(value:PhoneNumber) {
        this.setValueForKey(value, 'phone');
    }
    get phone():PhoneNumber {
        return this.valueForKey('phone') as PhoneNumber;
    }
}
