

// Generated class User_ManagedObject

class User_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: email
    set email(value:string) {
        this.setValueForKey(value, 'email');
    }
    get email():string {
        return this.valueForKey('email');
    }
    set emailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'email');
    }
    get emailPrimitiveValue():string {
        return this.primitiveValueForKey('email');
    }

    // Property: isAdmin
    set isAdmin(value:boolean) {
        this.setValueForKey(value, 'isAdmin');
    }
    get isAdmin():boolean {
        return this.valueForKey('isAdmin');
    }
    set isAdminPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAdmin');
    }
    get isAdminPrimitiveValue():boolean {
        return this.primitiveValueForKey('isAdmin');
    }

    // Property: isInvited
    set isInvited(value:boolean) {
        this.setValueForKey(value, 'isInvited');
    }
    get isInvited():boolean {
        return this.valueForKey('isInvited');
    }
    set isInvitedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isInvited');
    }
    get isInvitedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isInvited');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: businessPermission
    set businessPermission(value:UserBusinessPermission) {
        this.setValueForKey(value, 'businessPermission');
    }
    get businessPermission():UserBusinessPermission {
        return this.valueForKey('businessPermission') as UserBusinessPermission;
    }
}
