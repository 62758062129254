
function DMRegisterModelClasses()
{
	 MIOCoreRegisterClassByName('AccountBalance_ManagedObject', AccountBalance_ManagedObject);
	 MIOCoreRegisterClassByName('AccountBalance', AccountBalance);
	 MIOCoreRegisterClassByName('ClientAccountBalance_ManagedObject', ClientAccountBalance_ManagedObject);
	 MIOCoreRegisterClassByName('ClientAccountBalance', ClientAccountBalance);
	 MIOCoreRegisterClassByName('ClientLoyaltyBalance_ManagedObject', ClientLoyaltyBalance_ManagedObject);
	 MIOCoreRegisterClassByName('ClientLoyaltyBalance', ClientLoyaltyBalance);
	 MIOCoreRegisterClassByName('ClientDebtBalance_ManagedObject', ClientDebtBalance_ManagedObject);
	 MIOCoreRegisterClassByName('ClientDebtBalance', ClientDebtBalance);
	 MIOCoreRegisterClassByName('CashdeskSessionBalance_ManagedObject', CashdeskSessionBalance_ManagedObject);
	 MIOCoreRegisterClassByName('CashdeskSessionBalance', CashdeskSessionBalance);
	 MIOCoreRegisterClassByName('CashDesk_ManagedObject', CashDesk_ManagedObject);
	 MIOCoreRegisterClassByName('CashDesk', CashDesk);
	 MIOCoreRegisterClassByName('CashDeskEntity_ManagedObject', CashDeskEntity_ManagedObject);
	 MIOCoreRegisterClassByName('CashDeskEntity', CashDeskEntity);
	 MIOCoreRegisterClassByName('CashDeskSummaryItem_ManagedObject', CashDeskSummaryItem_ManagedObject);
	 MIOCoreRegisterClassByName('CashDeskSummaryItem', CashDeskSummaryItem);
	 MIOCoreRegisterClassByName('CashDeskSession_ManagedObject', CashDeskSession_ManagedObject);
	 MIOCoreRegisterClassByName('CashDeskSession', CashDeskSession);
	 MIOCoreRegisterClassByName('CheckPoint_ManagedObject', CheckPoint_ManagedObject);
	 MIOCoreRegisterClassByName('CheckPoint', CheckPoint);
	 MIOCoreRegisterClassByName('CashDeskLine_ManagedObject', CashDeskLine_ManagedObject);
	 MIOCoreRegisterClassByName('CashDeskLine', CashDeskLine);
	 MIOCoreRegisterClassByName('CashDeskOperation_ManagedObject', CashDeskOperation_ManagedObject);
	 MIOCoreRegisterClassByName('CashDeskOperation', CashDeskOperation);
	 MIOCoreRegisterClassByName('StockAnnotationView_ManagedObject', StockAnnotationView_ManagedObject);
	 MIOCoreRegisterClassByName('StockAnnotationView', StockAnnotationView);
	 MIOCoreRegisterClassByName('Product_ManagedObject', Product_ManagedObject);
	 MIOCoreRegisterClassByName('Product', Product);
	 MIOCoreRegisterClassByName('EllaborationCenter_ManagedObject', EllaborationCenter_ManagedObject);
	 MIOCoreRegisterClassByName('EllaborationCenter', EllaborationCenter);
	 MIOCoreRegisterClassByName('ProductWarehouse_ManagedObject', ProductWarehouse_ManagedObject);
	 MIOCoreRegisterClassByName('ProductWarehouse', ProductWarehouse);
	 MIOCoreRegisterClassByName('Warehouse_ManagedObject', Warehouse_ManagedObject);
	 MIOCoreRegisterClassByName('Warehouse', Warehouse);
	 MIOCoreRegisterClassByName('Tag_ManagedObject', Tag_ManagedObject);
	 MIOCoreRegisterClassByName('Tag', Tag);
	 MIOCoreRegisterClassByName('ProductModifierCategory_ManagedObject', ProductModifierCategory_ManagedObject);
	 MIOCoreRegisterClassByName('ProductModifierCategory', ProductModifierCategory);
	 MIOCoreRegisterClassByName('ProductAttributeType_ManagedObject', ProductAttributeType_ManagedObject);
	 MIOCoreRegisterClassByName('ProductAttributeType', ProductAttributeType);
	 MIOCoreRegisterClassByName('ProductAttribute_ManagedObject', ProductAttribute_ManagedObject);
	 MIOCoreRegisterClassByName('ProductAttribute', ProductAttribute);
	 MIOCoreRegisterClassByName('Supplier_ManagedObject', Supplier_ManagedObject);
	 MIOCoreRegisterClassByName('Supplier', Supplier);
	 MIOCoreRegisterClassByName('PayMethod_ManagedObject', PayMethod_ManagedObject);
	 MIOCoreRegisterClassByName('PayMethod', PayMethod);
	 MIOCoreRegisterClassByName('DBImage_ManagedObject', DBImage_ManagedObject);
	 MIOCoreRegisterClassByName('DBImage', DBImage);
	 MIOCoreRegisterClassByName('Category_ManagedObject', Category_ManagedObject);
	 MIOCoreRegisterClassByName('Category', Category);
	 MIOCoreRegisterClassByName('StockCategory_ManagedObject', StockCategory_ManagedObject);
	 MIOCoreRegisterClassByName('StockCategory', StockCategory);
	 MIOCoreRegisterClassByName('ProductListItem_ManagedObject', ProductListItem_ManagedObject);
	 MIOCoreRegisterClassByName('ProductListItem', ProductListItem);
	 MIOCoreRegisterClassByName('ProductFormat_ManagedObject', ProductFormat_ManagedObject);
	 MIOCoreRegisterClassByName('ProductFormat', ProductFormat);
	 MIOCoreRegisterClassByName('ProductFormatRate_ManagedObject', ProductFormatRate_ManagedObject);
	 MIOCoreRegisterClassByName('ProductFormatRate', ProductFormatRate);
	 MIOCoreRegisterClassByName('ProductModifier_ManagedObject', ProductModifier_ManagedObject);
	 MIOCoreRegisterClassByName('ProductModifier', ProductModifier);
	 MIOCoreRegisterClassByName('ProductRate_ManagedObject', ProductRate_ManagedObject);
	 MIOCoreRegisterClassByName('ProductRate', ProductRate);
	 MIOCoreRegisterClassByName('StockInputFormat_ManagedObject', StockInputFormat_ManagedObject);
	 MIOCoreRegisterClassByName('StockInputFormat', StockInputFormat);
	 MIOCoreRegisterClassByName('CategoryTag_ManagedObject', CategoryTag_ManagedObject);
	 MIOCoreRegisterClassByName('CategoryTag', CategoryTag);
	 MIOCoreRegisterClassByName('Tax_ManagedObject', Tax_ManagedObject);
	 MIOCoreRegisterClassByName('Tax', Tax);
	 MIOCoreRegisterClassByName('SubTax_ManagedObject', SubTax_ManagedObject);
	 MIOCoreRegisterClassByName('SubTax', SubTax);
	 MIOCoreRegisterClassByName('Rate_ManagedObject', Rate_ManagedObject);
	 MIOCoreRegisterClassByName('Rate', Rate);
	 MIOCoreRegisterClassByName('Format_ManagedObject', Format_ManagedObject);
	 MIOCoreRegisterClassByName('Format', Format);
	 MIOCoreRegisterClassByName('ProductSource_ManagedObject', ProductSource_ManagedObject);
	 MIOCoreRegisterClassByName('ProductSource', ProductSource);
	 MIOCoreRegisterClassByName('Component_ManagedObject', Component_ManagedObject);
	 MIOCoreRegisterClassByName('Component', Component);
	 MIOCoreRegisterClassByName('ProductComponentItem_ManagedObject', ProductComponentItem_ManagedObject);
	 MIOCoreRegisterClassByName('ProductComponentItem', ProductComponentItem);
	 MIOCoreRegisterClassByName('Booking_ManagedObject', Booking_ManagedObject);
	 MIOCoreRegisterClassByName('Booking', Booking);
	 MIOCoreRegisterClassByName('BookingGroup_ManagedObject', BookingGroup_ManagedObject);
	 MIOCoreRegisterClassByName('BookingGroup', BookingGroup);
	 MIOCoreRegisterClassByName('BookingPrescriptor_ManagedObject', BookingPrescriptor_ManagedObject);
	 MIOCoreRegisterClassByName('BookingPrescriptor', BookingPrescriptor);
	 MIOCoreRegisterClassByName('BookingRecommendation_ManagedObject', BookingRecommendation_ManagedObject);
	 MIOCoreRegisterClassByName('BookingRecommendation', BookingRecommendation);
	 MIOCoreRegisterClassByName('BookingSource_ManagedObject', BookingSource_ManagedObject);
	 MIOCoreRegisterClassByName('BookingSource', BookingSource);
	 MIOCoreRegisterClassByName('BookingChannel_ManagedObject', BookingChannel_ManagedObject);
	 MIOCoreRegisterClassByName('BookingChannel', BookingChannel);
	 MIOCoreRegisterClassByName('BookingZone_ManagedObject', BookingZone_ManagedObject);
	 MIOCoreRegisterClassByName('BookingZone', BookingZone);
	 MIOCoreRegisterClassByName('RelationBookingZone_ManagedObject', RelationBookingZone_ManagedObject);
	 MIOCoreRegisterClassByName('RelationBookingZone', RelationBookingZone);
	 MIOCoreRegisterClassByName('BookingDayTimeRange_ManagedObject', BookingDayTimeRange_ManagedObject);
	 MIOCoreRegisterClassByName('BookingDayTimeRange', BookingDayTimeRange);
	 MIOCoreRegisterClassByName('TimeRange_ManagedObject', TimeRange_ManagedObject);
	 MIOCoreRegisterClassByName('TimeRange', TimeRange);
	 MIOCoreRegisterClassByName('TimeRangeGroup_ManagedObject', TimeRangeGroup_ManagedObject);
	 MIOCoreRegisterClassByName('TimeRangeGroup', TimeRangeGroup);
	 MIOCoreRegisterClassByName('BookingDay_ManagedObject', BookingDay_ManagedObject);
	 MIOCoreRegisterClassByName('BookingDay', BookingDay);
	 MIOCoreRegisterClassByName('RelationBookingDay_ManagedObject', RelationBookingDay_ManagedObject);
	 MIOCoreRegisterClassByName('RelationBookingDay', RelationBookingDay);
	 MIOCoreRegisterClassByName('BookingLocationType_ManagedObject', BookingLocationType_ManagedObject);
	 MIOCoreRegisterClassByName('BookingLocationType', BookingLocationType);
	 MIOCoreRegisterClassByName('BookingZoneOcupationRule_ManagedObject', BookingZoneOcupationRule_ManagedObject);
	 MIOCoreRegisterClassByName('BookingZoneOcupationRule', BookingZoneOcupationRule);
	 MIOCoreRegisterClassByName('BookingPriceRule_ManagedObject', BookingPriceRule_ManagedObject);
	 MIOCoreRegisterClassByName('BookingPriceRule', BookingPriceRule);
	 MIOCoreRegisterClassByName('BookingModifierCategory_BookingZone_ManagedObject', BookingModifierCategory_BookingZone_ManagedObject);
	 MIOCoreRegisterClassByName('BookingModifierCategory_BookingZone', BookingModifierCategory_BookingZone);
	 MIOCoreRegisterClassByName('BookingModifierCategory_ManagedObject', BookingModifierCategory_ManagedObject);
	 MIOCoreRegisterClassByName('BookingModifierCategory', BookingModifierCategory);
	 MIOCoreRegisterClassByName('BookingModifier_ManagedObject', BookingModifier_ManagedObject);
	 MIOCoreRegisterClassByName('BookingModifier', BookingModifier);
	 MIOCoreRegisterClassByName('BookingGuestList_ManagedObject', BookingGuestList_ManagedObject);
	 MIOCoreRegisterClassByName('BookingGuestList', BookingGuestList);
	 MIOCoreRegisterClassByName('BookingGuest_ManagedObject', BookingGuest_ManagedObject);
	 MIOCoreRegisterClassByName('BookingGuest', BookingGuest);
	 MIOCoreRegisterClassByName('BookingEvent_ManagedObject', BookingEvent_ManagedObject);
	 MIOCoreRegisterClassByName('BookingEvent', BookingEvent);
	 MIOCoreRegisterClassByName('BookingLog_ManagedObject', BookingLog_ManagedObject);
	 MIOCoreRegisterClassByName('BookingLog', BookingLog);
	 MIOCoreRegisterClassByName('Language_ManagedObject', Language_ManagedObject);
	 MIOCoreRegisterClassByName('Language', Language);
	 MIOCoreRegisterClassByName('Client_ManagedObject', Client_ManagedObject);
	 MIOCoreRegisterClassByName('Client', Client);
	 MIOCoreRegisterClassByName('Department_ManagedObject', Department_ManagedObject);
	 MIOCoreRegisterClassByName('Department', Department);
	 MIOCoreRegisterClassByName('WorkerRole_ManagedObject', WorkerRole_ManagedObject);
	 MIOCoreRegisterClassByName('WorkerRole', WorkerRole);
	 MIOCoreRegisterClassByName('Country_ManagedObject', Country_ManagedObject);
	 MIOCoreRegisterClassByName('Country', Country);
	 MIOCoreRegisterClassByName('Address_ManagedObject', Address_ManagedObject);
	 MIOCoreRegisterClassByName('Address', Address);
	 MIOCoreRegisterClassByName('PhoneNumber_ManagedObject', PhoneNumber_ManagedObject);
	 MIOCoreRegisterClassByName('PhoneNumber', PhoneNumber);
	 MIOCoreRegisterClassByName('EconomicEntity_ManagedObject', EconomicEntity_ManagedObject);
	 MIOCoreRegisterClassByName('EconomicEntity', EconomicEntity);
	 MIOCoreRegisterClassByName('LegalEntity_ManagedObject', LegalEntity_ManagedObject);
	 MIOCoreRegisterClassByName('LegalEntity', LegalEntity);
	 MIOCoreRegisterClassByName('Employee_ManagedObject', Employee_ManagedObject);
	 MIOCoreRegisterClassByName('Employee', Employee);
	 MIOCoreRegisterClassByName('OfferRule_ManagedObject', OfferRule_ManagedObject);
	 MIOCoreRegisterClassByName('OfferRule', OfferRule);
	 MIOCoreRegisterClassByName('AccountLine_ManagedObject', AccountLine_ManagedObject);
	 MIOCoreRegisterClassByName('AccountLine', AccountLine);
	 MIOCoreRegisterClassByName('EconomicAccountLine_ManagedObject', EconomicAccountLine_ManagedObject);
	 MIOCoreRegisterClassByName('EconomicAccountLine', EconomicAccountLine);
	 MIOCoreRegisterClassByName('LoyaltyAccountLine_ManagedObject', LoyaltyAccountLine_ManagedObject);
	 MIOCoreRegisterClassByName('LoyaltyAccountLine', LoyaltyAccountLine);
	 MIOCoreRegisterClassByName('DebtLine_ManagedObject', DebtLine_ManagedObject);
	 MIOCoreRegisterClassByName('DebtLine', DebtLine);
	 MIOCoreRegisterClassByName('Tip_ManagedObject', Tip_ManagedObject);
	 MIOCoreRegisterClassByName('Tip', Tip);
	 MIOCoreRegisterClassByName('ProductionProduct_ManagedObject', ProductionProduct_ManagedObject);
	 MIOCoreRegisterClassByName('ProductionProduct', ProductionProduct);
	 MIOCoreRegisterClassByName('LapsopayLocation_ManagedObject', LapsopayLocation_ManagedObject);
	 MIOCoreRegisterClassByName('LapsopayLocation', LapsopayLocation);
	 MIOCoreRegisterClassByName('BkTicket_ManagedObject', BkTicket_ManagedObject);
	 MIOCoreRegisterClassByName('BkTicket', BkTicket);
	 MIOCoreRegisterClassByName('Budget_ManagedObject', Budget_ManagedObject);
	 MIOCoreRegisterClassByName('Budget', Budget);
	 MIOCoreRegisterClassByName('BudgetLine_ManagedObject', BudgetLine_ManagedObject);
	 MIOCoreRegisterClassByName('BudgetLine', BudgetLine);
	 MIOCoreRegisterClassByName('Menu_ManagedObject', Menu_ManagedObject);
	 MIOCoreRegisterClassByName('Menu', Menu);
	 MIOCoreRegisterClassByName('MenuCategory_ManagedObject', MenuCategory_ManagedObject);
	 MIOCoreRegisterClassByName('MenuCategory', MenuCategory);
	 MIOCoreRegisterClassByName('ProductMenu_ManagedObject', ProductMenu_ManagedObject);
	 MIOCoreRegisterClassByName('ProductMenu', ProductMenu);
	 MIOCoreRegisterClassByName('StockCustomConcept_ManagedObject', StockCustomConcept_ManagedObject);
	 MIOCoreRegisterClassByName('StockCustomConcept', StockCustomConcept);
	 MIOCoreRegisterClassByName('StockNote_ManagedObject', StockNote_ManagedObject);
	 MIOCoreRegisterClassByName('StockNote', StockNote);
	 MIOCoreRegisterClassByName('StockNoteLine_ManagedObject', StockNoteLine_ManagedObject);
	 MIOCoreRegisterClassByName('StockNoteLine', StockNoteLine);
	 MIOCoreRegisterClassByName('SupplierProduct_ManagedObject', SupplierProduct_ManagedObject);
	 MIOCoreRegisterClassByName('SupplierProduct', SupplierProduct);
	 MIOCoreRegisterClassByName('StockProduct_ManagedObject', StockProduct_ManagedObject);
	 MIOCoreRegisterClassByName('StockProduct', StockProduct);
	 MIOCoreRegisterClassByName('InventoryTemplate_ManagedObject', InventoryTemplate_ManagedObject);
	 MIOCoreRegisterClassByName('InventoryTemplate', InventoryTemplate);
	 MIOCoreRegisterClassByName('InventoryTemplateItem_ManagedObject', InventoryTemplateItem_ManagedObject);
	 MIOCoreRegisterClassByName('InventoryTemplateItem', InventoryTemplateItem);
	 MIOCoreRegisterClassByName('InvitationResponsible_ManagedObject', InvitationResponsible_ManagedObject);
	 MIOCoreRegisterClassByName('InvitationResponsible', InvitationResponsible);
	 MIOCoreRegisterClassByName('Location_ManagedObject', Location_ManagedObject);
	 MIOCoreRegisterClassByName('Location', Location);
	 MIOCoreRegisterClassByName('LocationCategory_ManagedObject', LocationCategory_ManagedObject);
	 MIOCoreRegisterClassByName('LocationCategory', LocationCategory);
	 MIOCoreRegisterClassByName('BusinessLicense_ManagedObject', BusinessLicense_ManagedObject);
	 MIOCoreRegisterClassByName('BusinessLicense', BusinessLicense);
	 MIOCoreRegisterClassByName('License_ManagedObject', License_ManagedObject);
	 MIOCoreRegisterClassByName('License', License);
	 MIOCoreRegisterClassByName('Application_ManagedObject', Application_ManagedObject);
	 MIOCoreRegisterClassByName('Application', Application);
	 MIOCoreRegisterClassByName('CashDeskSessionSummaryItem_ManagedObject', CashDeskSessionSummaryItem_ManagedObject);
	 MIOCoreRegisterClassByName('CashDeskSessionSummaryItem', CashDeskSessionSummaryItem);
	 MIOCoreRegisterClassByName('Offer_ManagedObject', Offer_ManagedObject);
	 MIOCoreRegisterClassByName('Offer', Offer);
	 MIOCoreRegisterClassByName('OfferProduct_ManagedObject', OfferProduct_ManagedObject);
	 MIOCoreRegisterClassByName('OfferProduct', OfferProduct);
	 MIOCoreRegisterClassByName('PromotionalCode_ManagedObject', PromotionalCode_ManagedObject);
	 MIOCoreRegisterClassByName('PromotionalCode', PromotionalCode);
	 MIOCoreRegisterClassByName('VIPCard_ManagedObject', VIPCard_ManagedObject);
	 MIOCoreRegisterClassByName('VIPCard', VIPCard);
	 MIOCoreRegisterClassByName('Card_ManagedObject', Card_ManagedObject);
	 MIOCoreRegisterClassByName('Card', Card);
	 MIOCoreRegisterClassByName('DBScript_ManagedObject', DBScript_ManagedObject);
	 MIOCoreRegisterClassByName('DBScript', DBScript);
	 MIOCoreRegisterClassByName('DBFilter_ManagedObject', DBFilter_ManagedObject);
	 MIOCoreRegisterClassByName('DBFilter', DBFilter);
	 MIOCoreRegisterClassByName('ArchivedDocument_ManagedObject', ArchivedDocument_ManagedObject);
	 MIOCoreRegisterClassByName('ArchivedDocument', ArchivedDocument);
	 MIOCoreRegisterClassByName('ArchivedTicket_ManagedObject', ArchivedTicket_ManagedObject);
	 MIOCoreRegisterClassByName('ArchivedTicket', ArchivedTicket);
	 MIOCoreRegisterClassByName('ArchivedTicketLinesGroup_ManagedObject', ArchivedTicketLinesGroup_ManagedObject);
	 MIOCoreRegisterClassByName('ArchivedTicketLinesGroup', ArchivedTicketLinesGroup);
	 MIOCoreRegisterClassByName('ArchivedTicketLine_ManagedObject', ArchivedTicketLine_ManagedObject);
	 MIOCoreRegisterClassByName('ArchivedTicketLine', ArchivedTicketLine);
	 MIOCoreRegisterClassByName('ArchivedTicketLineModifier_ManagedObject', ArchivedTicketLineModifier_ManagedObject);
	 MIOCoreRegisterClassByName('ArchivedTicketLineModifier', ArchivedTicketLineModifier);
	 MIOCoreRegisterClassByName('IssuedInvoice_ManagedObject', IssuedInvoice_ManagedObject);
	 MIOCoreRegisterClassByName('IssuedInvoice', IssuedInvoice);
	 MIOCoreRegisterClassByName('IssuedInvoiceGroupLine_ManagedObject', IssuedInvoiceGroupLine_ManagedObject);
	 MIOCoreRegisterClassByName('IssuedInvoiceGroupLine', IssuedInvoiceGroupLine);
	 MIOCoreRegisterClassByName('IssuedInvoiceLine_ManagedObject', IssuedInvoiceLine_ManagedObject);
	 MIOCoreRegisterClassByName('IssuedInvoiceLine', IssuedInvoiceLine);
	 MIOCoreRegisterClassByName('Party_ManagedObject', Party_ManagedObject);
	 MIOCoreRegisterClassByName('Party', Party);
	 MIOCoreRegisterClassByName('PartyEvent_ManagedObject', PartyEvent_ManagedObject);
	 MIOCoreRegisterClassByName('PartyEvent', PartyEvent);
	 MIOCoreRegisterClassByName('PartyImage_ManagedObject', PartyImage_ManagedObject);
	 MIOCoreRegisterClassByName('PartyImage', PartyImage);
	 MIOCoreRegisterClassByName('IntegratorsConfig_ManagedObject', IntegratorsConfig_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorsConfig', IntegratorsConfig);
	 MIOCoreRegisterClassByName('IntegratorsMappings_ManagedObject', IntegratorsMappings_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorsMappings', IntegratorsMappings);
	 MIOCoreRegisterClassByName('IntegratorsSync_ManagedObject', IntegratorsSync_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorsSync', IntegratorsSync);
	 MIOCoreRegisterClassByName('ProductPlace_ManagedObject', ProductPlace_ManagedObject);
	 MIOCoreRegisterClassByName('ProductPlace', ProductPlace);
	 MIOCoreRegisterClassByName('IntegratorSync_ManagedObject', IntegratorSync_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorSync', IntegratorSync);
	 MIOCoreRegisterClassByName('DBSyncEntity_ManagedObject', DBSyncEntity_ManagedObject);
	 MIOCoreRegisterClassByName('DBSyncEntity', DBSyncEntity);
	 MIOCoreRegisterClassByName('IntegratorSAPStockNoteLine_ManagedObject', IntegratorSAPStockNoteLine_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorSAPStockNoteLine', IntegratorSAPStockNoteLine);
	 MIOCoreRegisterClassByName('DBSyncEntityQueue_ManagedObject', DBSyncEntityQueue_ManagedObject);
	 MIOCoreRegisterClassByName('DBSyncEntityQueue', DBSyncEntityQueue);
	 MIOCoreRegisterClassByName('IntegratorSAPStockNoteLineQueue_ManagedObject', IntegratorSAPStockNoteLineQueue_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorSAPStockNoteLineQueue', IntegratorSAPStockNoteLineQueue);
	 MIOCoreRegisterClassByName('DBSyncTransaction_ManagedObject', DBSyncTransaction_ManagedObject);
	 MIOCoreRegisterClassByName('DBSyncTransaction', DBSyncTransaction);
	 MIOCoreRegisterClassByName('DBMappingEntity_ManagedObject', DBMappingEntity_ManagedObject);
	 MIOCoreRegisterClassByName('DBMappingEntity', DBMappingEntity);
	 MIOCoreRegisterClassByName('DBMappingEntityProduct_ManagedObject', DBMappingEntityProduct_ManagedObject);
	 MIOCoreRegisterClassByName('DBMappingEntityProduct', DBMappingEntityProduct);
	 MIOCoreRegisterClassByName('DBMappingEntityClient_ManagedObject', DBMappingEntityClient_ManagedObject);
	 MIOCoreRegisterClassByName('DBMappingEntityClient', DBMappingEntityClient);
	 MIOCoreRegisterClassByName('DBMappingEntityWorker_ManagedObject', DBMappingEntityWorker_ManagedObject);
	 MIOCoreRegisterClassByName('DBMappingEntityWorker', DBMappingEntityWorker);
	 MIOCoreRegisterClassByName('DBMappingEntityPlace_ManagedObject', DBMappingEntityPlace_ManagedObject);
	 MIOCoreRegisterClassByName('DBMappingEntityPlace', DBMappingEntityPlace);
	 MIOCoreRegisterClassByName('DBMappingEntityLocationCategory_ManagedObject', DBMappingEntityLocationCategory_ManagedObject);
	 MIOCoreRegisterClassByName('DBMappingEntityLocationCategory', DBMappingEntityLocationCategory);
	 MIOCoreRegisterClassByName('DBMappingEntityWarehouse_ManagedObject', DBMappingEntityWarehouse_ManagedObject);
	 MIOCoreRegisterClassByName('DBMappingEntityWarehouse', DBMappingEntityWarehouse);
	 MIOCoreRegisterClassByName('DBMappingEntityCashDesk_ManagedObject', DBMappingEntityCashDesk_ManagedObject);
	 MIOCoreRegisterClassByName('DBMappingEntityCashDesk', DBMappingEntityCashDesk);
	 MIOCoreRegisterClassByName('DBMappingEntityCeco_ManagedObject', DBMappingEntityCeco_ManagedObject);
	 MIOCoreRegisterClassByName('DBMappingEntityCeco', DBMappingEntityCeco);
	 MIOCoreRegisterClassByName('User_ManagedObject', User_ManagedObject);
	 MIOCoreRegisterClassByName('User', User);
	 MIOCoreRegisterClassByName('UserSection_ManagedObject', UserSection_ManagedObject);
	 MIOCoreRegisterClassByName('UserSection', UserSection);
	 MIOCoreRegisterClassByName('UserPermission_ManagedObject', UserPermission_ManagedObject);
	 MIOCoreRegisterClassByName('UserPermission', UserPermission);
	 MIOCoreRegisterClassByName('UserBusinessPermission_ManagedObject', UserBusinessPermission_ManagedObject);
	 MIOCoreRegisterClassByName('UserBusinessPermission', UserBusinessPermission);
	 MIOCoreRegisterClassByName('BankAccount_ManagedObject', BankAccount_ManagedObject);
	 MIOCoreRegisterClassByName('BankAccount', BankAccount);
	 MIOCoreRegisterClassByName('BankAccountMovement_ManagedObject', BankAccountMovement_ManagedObject);
	 MIOCoreRegisterClassByName('BankAccountMovement', BankAccountMovement);
	 MIOCoreRegisterClassByName('PaymentEntity_ManagedObject', PaymentEntity_ManagedObject);
	 MIOCoreRegisterClassByName('PaymentEntity', PaymentEntity);
	 MIOCoreRegisterClassByName('Configuration_ManagedObject', Configuration_ManagedObject);
	 MIOCoreRegisterClassByName('Configuration', Configuration);
	 MIOCoreRegisterClassByName('Preset_ManagedObject', Preset_ManagedObject);
	 MIOCoreRegisterClassByName('Preset', Preset);
	 MIOCoreRegisterClassByName('EmailTemplate_ManagedObject', EmailTemplate_ManagedObject);
	 MIOCoreRegisterClassByName('EmailTemplate', EmailTemplate);
	 MIOCoreRegisterClassByName('WorkSession_ManagedObject', WorkSession_ManagedObject);
	 MIOCoreRegisterClassByName('WorkSession', WorkSession);
	 MIOCoreRegisterClassByName('LogOperation_ManagedObject', LogOperation_ManagedObject);
	 MIOCoreRegisterClassByName('LogOperation', LogOperation);
	 MIOCoreRegisterClassByName('Business_ManagedObject', Business_ManagedObject);
	 MIOCoreRegisterClassByName('Business', Business);
	 MIOCoreRegisterClassByName('UserBusiness_ManagedObject', UserBusiness_ManagedObject);
	 MIOCoreRegisterClassByName('UserBusiness', UserBusiness);
	 MIOCoreRegisterClassByName('Module_ManagedObject', Module_ManagedObject);
	 MIOCoreRegisterClassByName('Module', Module);
	 MIOCoreRegisterClassByName('Section_ManagedObject', Section_ManagedObject);
	 MIOCoreRegisterClassByName('Section', Section);
	 MIOCoreRegisterClassByName('FilterTicket_ManagedObject', FilterTicket_ManagedObject);
	 MIOCoreRegisterClassByName('FilterTicket', FilterTicket);
	 MIOCoreRegisterClassByName('FilterTicketGroupLine_ManagedObject', FilterTicketGroupLine_ManagedObject);
	 MIOCoreRegisterClassByName('FilterTicketGroupLine', FilterTicketGroupLine);
	 MIOCoreRegisterClassByName('FilterTicketLine_ManagedObject', FilterTicketLine_ManagedObject);
	 MIOCoreRegisterClassByName('FilterTicketLine', FilterTicketLine);
	 MIOCoreRegisterClassByName('SupplierInvoice_ManagedObject', SupplierInvoice_ManagedObject);
	 MIOCoreRegisterClassByName('SupplierInvoice', SupplierInvoice);
	 MIOCoreRegisterClassByName('SupplierInvoiceGroupLine_ManagedObject', SupplierInvoiceGroupLine_ManagedObject);
	 MIOCoreRegisterClassByName('SupplierInvoiceGroupLine', SupplierInvoiceGroupLine);
	 MIOCoreRegisterClassByName('SupplierInvoiceLine_ManagedObject', SupplierInvoiceLine_ManagedObject);
	 MIOCoreRegisterClassByName('SupplierInvoiceLine', SupplierInvoiceLine);
	 MIOCoreRegisterClassByName('ReportScriptItem_ManagedObject', ReportScriptItem_ManagedObject);
	 MIOCoreRegisterClassByName('ReportScriptItem', ReportScriptItem);
	 MIOCoreRegisterClassByName('ReportScriptFilter_ManagedObject', ReportScriptFilter_ManagedObject);
	 MIOCoreRegisterClassByName('ReportScriptFilter', ReportScriptFilter);
	 MIOCoreRegisterClassByName('ReportItem_ManagedObject', ReportItem_ManagedObject);
	 MIOCoreRegisterClassByName('ReportItem', ReportItem);
	 MIOCoreRegisterClassByName('ReportListItem_ManagedObject', ReportListItem_ManagedObject);
	 MIOCoreRegisterClassByName('ReportListItem', ReportListItem);
	 MIOCoreRegisterClassByName('ReportChartItem_ManagedObject', ReportChartItem_ManagedObject);
	 MIOCoreRegisterClassByName('ReportChartItem', ReportChartItem);
	 MIOCoreRegisterClassByName('Report_ManagedObject', Report_ManagedObject);
	 MIOCoreRegisterClassByName('Report', Report);
	 MIOCoreRegisterClassByName('ReportFilter_ManagedObject', ReportFilter_ManagedObject);
	 MIOCoreRegisterClassByName('ReportFilter', ReportFilter);
	 MIOCoreRegisterClassByName('SupportTicket_ManagedObject', SupportTicket_ManagedObject);
	 MIOCoreRegisterClassByName('SupportTicket', SupportTicket);
}
