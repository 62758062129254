
class DBMatchCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private codeLabel:MUILabel = null;
    private unlinkButton:MUIButton = null;
    private linkButton:MUIButton = null;    

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.codeLabel = MUIOutlet(this, "code-lbl", "MUILabel");

        this.unlinkButton = MUIOutlet(this, "unlink-btn", "MUIButton");
        this.unlinkButton.setAction(this, function(){
            this.unlinkEntity(true);
        })

        this.linkButton = MUIOutlet(this, "link-btn", "MUIButton");
        this.linkButton.setAction(this, function(){
            this.linkEntity();
        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private item:DBMappingEntity = null;
    private selectedItem:DBMappingEntity = null;
    setItems(item:DBMappingEntity, selectedItem:DBMappingEntity) {
        this.item = item;
        this.selectedItem = selectedItem;
        this.nameLabel.text = item.foreignName;
        this.codeLabel.text = item.foreignID;
        this.linkButton.hidden = true;
        this.unlinkButton.hidden = true;

        if (selectedItem != null && selectedItem.foreignID == null) {
            this.linkButton.hidden = false;
        }            
    }

    private linkEntity(){
        //this.unlinkEntity(false);
        // this.selectedItem.foreignID = this.item.foreignID;
        // this.selectedItem.foreignName = this.item.foreignName;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let im = MIOEntityDescription.insertNewObjectForEntityForName("IntegratorsMappings", ad.managedObjectContext) as IntegratorsMappings;
        im.enviroment = this.item.enviroment;
        im.integrator = this.item.integrator;
        im.enviroment = this.item.enviroment;
        
        im.foreignEntityName = this.item.foreignEntityName;
        im.foreignName = this.item.foreignName;
        im.foreignID = this.item.foreignID;
        im.ownEntityName = this.selectedItem.ownEntityName;
        im.ownName = this.selectedItem.ownName;
        im.ownID = this.selectedItem.identifier;

        DBHelper.saveMainContextWithCompletion(this, function(){
            // HACK: Please remove as soon as you can. The rigth way is let the fetch results controller reload de tableview
            MIONotificationCenter.defaultCenter().postNotification("DBMappingItemDidChange", this);
        });
    }

    private unlinkEntity(save:boolean){
        this.selectedItem.foreignID = null;
        this.selectedItem.foreignName = null;

        if (save == true) {
            DBHelper.saveMainContext();
            MIONotificationCenter.defaultCenter().postNotification("DBMappingItemDidChange", this);
        }
    }
}