

class CashDeskCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private totalLabel:MUILabel = null;    

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');
        this.totalLabel = MUIOutlet(this, 'total-lbl', 'MUILabel');  
        
        this.separatorStyle = MUITableViewCellSeparatorStyle.None;
    }

    set item(i){
        this.nameLabel.text = i.name;
        this.totalLabel.text = this.cf.stringFromNumber(i.total);
    }
}
