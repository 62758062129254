//
// Generated class Rate
//

/// <reference path="Rate_ManagedObject.ts" />

class Rate extends Rate_ManagedObject
{
    
}
