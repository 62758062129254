/// <reference path="../notes/NoteDetailViewController.ts" />
/// <reference path="../AppHelper.ts" />


class InventoryNoteDetailViewController extends NoteDetailViewController 
{
    private categoryDropDown:MUIButton = null;

    private editHeaderView:InventoryNoteDetailHeader = null;
    private noEditHeaderView:InventoryNoteDetailHeader = null;  
    
    private filterController:ColumnFilterController = null; 
    private column: MUIReportTableViewColumn = null;

    private conceptTextField: ColumnFilterTextField = null;
    private quantityTextField: ColumnFilterTextField = null;

    private filterItems = [];

    
    viewDidLoad(){
        super.viewDidLoad();

        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);

        this.categoryDropDown = MUIOutlet(this, "category-dd", "MUIButton");
        this.categoryDropDown.setAction(this, this.categoryDropdownAction);

        this.editHeaderView = MUIOutlet(this, "edit-header-view", "InventoryNoteDetailHeader");            
        this.noEditHeaderView = MUIOutlet(this, "no-edit-header-view", "InventoryNoteDetailHeader");

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.conceptTextField = MUIOutlet(this, "concept-tf", "ColumnFilterTextField"); 
        this.conceptTextField.filterController = this.filterController;
        this.conceptTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "productName", null, null);

        this.quantityTextField = MUIOutlet(this, "quantity-tf", "ColumnFilterTextField"); 
        this.quantityTextField.filterController = this.filterController;
        this.quantityTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "quantity", null, null);

        this.column = new MUIReportTableViewColumn();
    }

    protected updateUI(){        
        this.editHeaderView.note = this.stockNote;
        this.noEditHeaderView.note = this.stockNote;
        this.editHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;
        this.noEditHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? false : true;

        this.invalidateData();
        this.tableView.reloadData();
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', this.column.ascending)];

        let predicateFormat = "note.identifier == " + this.stockNote.identifier;
        if (this.searchString != null) {
            predicateFormat += " AND productName CONTAINS '" + this.searchString + "'";
        }

        //  Column filter
		let filterFormat = this.filterController.filterPredicateFormat();
		if (filterFormat != null) {
			predicateFormat += " AND (" + filterFormat + ")";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockNoteLine", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["inputFormat"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeObject(controller: MIOFetchedResultsController, object: any, indexPath: MIOIndexPath, changeType: MIOFetchedResultsChangeType, newIndexPath: MIOIndexPath) {

        let tv = this.tableView as UITableView;

        switch(changeType){
            case MIOFetchedResultsChangeType.Insert:
            tv.insertRowsAtIndexPaths([newIndexPath], UITableViewRowAnimation.Automatic);
            break;

            case MIOFetchedResultsChangeType.Delete:
            tv.deleteRowsAtIndexPaths([indexPath], UITableViewRowAnimation.Automatic);
            break;
        }

    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        // if (this.addNewLine == true) {
        //     //TODO: Implement in MWSTableview the scrroll view extension
        //     this.tableView.layer.scrollTop = this.tableView.layer.scrollHeight;
        //     this.addNewLine = false;
        // }
    }

    sortDescriptorsDidChange() {

        if (this.column.serverName == null) return;

        this.column.ascending = !this.column.ascending;
        
        this.filterItems = _MIOSortDescriptorSortObjects(this.filterItems, [MIOSortDescriptor.sortDescriptorWithKey(this.column.serverName, this.column.ascending)]);

        this.tableView.reloadData();
    }
        
    private categoryDropdownAction(){
        AppHelper.sharedInstance().showSelectStockCategoryViewControllerFromView(this.categoryDropDown, null, this,this.categoryDidSelected, null, null);
    }

    private categoryFilter:StockCategory = null;
    private categoryDidSelected(category:StockCategory){
        this.categoryFilter = category;
        this.invalidateData();
    }

    private showProcessView(){
        let alert = new MUIAlertViewController();
        alert.initWithTitle(MIOLocalizeString('PROCESS','Process'), 'Do you want to process the movement?', MUIAlertViewStyle.Default);
        alert.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('YES', 'Yes'), MUIAlertActionStyle.Default, this, function(){
            
            this._movementNote.isProcessed = true;
            this.saveMovementNote();
        }));
        alert.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString('NO', 'No'), MUIAlertActionStyle.Default, null, null));
       
        this.presentViewController(alert, true);
    }    

    protected showCustomActions(avc:MUIAlertViewController){

        avc.addAction(MUIAlertAction.alertActionWithTitle("Add all products from warehouse", MUIAlertActionStyle.Default, this, function(){
            this.addAllProduct();
        }));
    }

    private addAllProduct(){

        let whID = this.stockNote.destinationWarehouse ? this.stockNote.destinationWarehouse.identifier : null;
        //let predicate = MIOPredicate.predicateWithFormat("defaultWarehouse.identifier == " + whID + " AND stockElaborationType != " + StockElaborationType.AtSale);            
        let predicate = MIOPredicate.predicateWithFormat("stockElaborationType != " + StockElaborationType.AtSale);            
        DBHelper.queryObjectsWithCompletion("Product", [MIOSortDescriptor.sortDescriptorWithKey("name", true)], predicate, ["taxBuy", "category"], this, function(objects){
            for (let index = 0; index < objects.length; index++){
                let product = objects[index] as Product;
                DBHelper.createInventoryNoteLine(product, null, product.measureType, 0, 0, 0, this.stockNote);
                //line.estimatedQuantity = sa.productQuantity;
                //this.stockNote.addLinesObject(line);    
            }

            this.invalidateData();
        });
    }

    filterPredicateDidChange(controller:ColumnFilterController){        
        this.invalidateData();
        this.tableView.reloadData();        
    }

}
