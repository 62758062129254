

class CashDeskSessionCountCell extends MUITableViewCell
{
    private iconView:MUIImageView = null;
    private titleLabel:MUILabel = null;
    private estimatedLabel:MUILabel = null;
    private tipsLabel:MUILabel = null;
    private countedTextField:MUITextField = null;
    private tipsCountedTextField:MUITextField = null;

    private cf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML(){
        //this.iconView = MUIOutlet(this, "icon-view", "MUIImageView");
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.estimatedLabel = MUIOutlet(this,"estimated-lbl", "MUILabel");
        this.tipsLabel = MUIOutlet(this,"tips-lbl", "MUILabel");
        
        this.countedTextField = MUIOutlet(this, "counted-tf", "MUITextField");        
        this.countedTextField.formatter = this.cf;
        this.countedTextField.setOnChangeText(this, function(control, value){
            let newValue = this.cf.numberFromString(value);            
            this.item["count"] = newValue;
        });

        this.tipsCountedTextField = MUIOutlet(this, "counted-tips-tf", "MUITextField");
        this.tipsCountedTextField.formatter = this.cf;
        this.tipsCountedTextField.setOnChangeText(this, function(control, value){
            let newValue = this.cf.numberFromString(value);
            this.item["counttips"] = newValue;
        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }
    
    private item = null;
    setItem(item){
        this.item = item;

        //this.iconView.setImage(item["paymethodimageurl"]);
        this.titleLabel.text = item["paymethodname"];
        this.estimatedLabel.text = this.cf.stringFromNumber(item["import"]);
        this.tipsLabel.text = this.cf.stringFromNumber(item["tip"]);
        this.countedTextField.text = this.cf.stringFromNumber(item["count"]);
        this.tipsCountedTextField.text = this.cf.stringFromNumber(item["counttips"]);
    }

}