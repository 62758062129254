/// <reference path="FilterCell.ts" />
/// <reference path="FilterController.ts" />


enum FilterFieldType
{
    String,
    Boolean,
    Number,
    Currency,
    Date,
    Time,
    DateTime,
    ComboBox,
    DropDown,
    DateRange,
    TimeRange,
    DateTimeRange,
    NumberRange
}

class FilterOption extends Filter
{
    title:string = null;
    fieldType = FilterFieldType.String;
    defaultValue:string = null;
    placeHolderText:string = null;
    request:MIOFetchRequest = null; 
    titleKey:string = null;
    valueKey:string = null;
    valueString:string = null;
    defaultTimeValue:string = null;
    enumValues = null;
    predicateFormat:string = null;
    allowMultipleSelection = false;

    static filterWithTitle(identifier:string, title:string, key:string, fieldType:FilterFieldType, defaultValue?:string, defaultOperation?:FilterControllerComparatorType, placeHolderText?:string, defaultTimeValue?:string, enumValues?):FilterOption {
        
        let type = FilterControllerValueType.String;

        switch(fieldType){
            case FilterFieldType.String:
                type = FilterControllerValueType.String;
                break;

            case FilterFieldType.Number:
            case FilterFieldType.Currency:
            case FilterFieldType.NumberRange:
                type = FilterControllerValueType.Number;
                break;

            case FilterFieldType.Date:
            case FilterFieldType.Time:
            case FilterFieldType.DateTime:
            case FilterFieldType.DateRange:
            case FilterFieldType.DateTimeRange:
                type = FilterControllerValueType.Date;
                break;

            case FilterFieldType.ComboBox:
            case FilterFieldType.DropDown:
                type = FilterControllerValueType.Enum;
                break;
        }

        let fo = new FilterOption();
        fo.initWithIdentifier(identifier, key, type);

        fo.title = title;
        fo.key = key;        
        fo.type = type;
        fo.fieldType = fieldType;
        fo.defaultComparator = defaultOperation;
        fo.defaultValue = defaultValue;
        fo.placeHolderText = placeHolderText;
        fo.defaultTimeValue = defaultTimeValue;
        fo.enumValues = enumValues != null ? enumValues.split(","): null;

        return fo;
    }

    static filterWithFetchRequest(identifier:string, title:string, key:string, fieldType:FilterFieldType, entityName:string, titleKey:string, valueKey:string, placeHolderText?:string):FilterOption {
        let fo = FilterOption.filterWithTitle(identifier, title, key, fieldType, null, null, placeHolderText);
        fo.request = MIOFetchRequest.fetchRequestWithEntityName(entityName); 
        fo.titleKey = titleKey;  
        fo.valueKey = valueKey; 

        return fo;
    }
}

class FilterViewController extends MUIViewController
{
    embeddedController = false;

    private navBar:MUIView = null;
    private closeButton:MUIButton = null;
    private clearButton:MUIButton = null;
    private tableView:UITableView = null;      
            
    get preferredContentSize(){
		let h = (this._filters.length * 65) + 60;
        return new MIOSize(320, h + 44);
    }

    viewDidLoad(){
        super.viewDidLoad();        

        this.navBar = MUIOutlet(this, "top-bar", "MUIView");

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        });        

        this.clearButton = MUIOutlet(this, "clear-btn", "MUIButton");
        this.clearButton.setAction(this, this.clearFiltersAction);        

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;    
        this.tableView.delegate = this;    
    }

    viewWillAppear(animated?:boolean){
        super.viewWillAppear(animated);
        if (this.embeddedController == true) this.navBar.hidden = true;
        this.updateUI();
    }

    private _filters = null;  
    private filterController:FilterController = null;
    get filters() {return this._filters}
    set filters(value){
        this._filters = value;      
        this.filterController = FilterController.newControllerWithFilters(value);

        this.updateUI();
    }

    private target = null;
    private completion = null;
    setCompletionBlock(target, completion){
        this.target = target;
        this.completion = completion;
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._filters == null) return;

        this.tableView.reloadData();
    }

    private isEnableApplyButton = true;
    setApplyButtonEnable(value:boolean){
        this.isEnableApplyButton = value;
    }

    numberOfSections(tableview:UITableView){
        return 1;
    }

    numberOfRowsInSection(tableview:UITableView, section){        
        return this._filters.length + 1;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){

        if (indexPath.row == this._filters.length) {
            let applyCell = tableview.dequeueReusableCellWithIdentifier("ApplyCell");
            applyCell.selectionStyle = UITableViewCellSelectionStyle.None;
            return applyCell;
        }

        let filter = this._filters[indexPath.row] as FilterOption;

        let cell:FilterCell = null;

        if (filter.fieldType == FilterFieldType.String || filter.fieldType == FilterFieldType.Number ||  filter.fieldType == FilterFieldType.Currency) {
            cell = tableview.dequeueReusableCellWithIdentifier("StringCell") as FilterCell;
        }
        else if (filter.fieldType == FilterFieldType.Date || filter.fieldType == FilterFieldType.Time || filter.fieldType == FilterFieldType.DateTime) {
            cell = tableview.dequeueReusableCellWithIdentifier("DateCell") as FilterCell;
        }
        else if (filter.fieldType == FilterFieldType.ComboBox){
            cell = tableview.dequeueReusableCellWithIdentifier("ComboBoxCell") as FilterCell;
        }
        else if (filter.fieldType == FilterFieldType.DropDown){
            cell = tableview.dequeueReusableCellWithIdentifier("DropDownCell") as FilterCell;
        }
        else if (filter.fieldType == FilterFieldType.DateRange || filter.fieldType == FilterFieldType.DateTimeRange){
            cell = tableview.dequeueReusableCellWithIdentifier("DateRangeCell") as FilterCell;
        }
        else if (filter.fieldType == FilterFieldType.NumberRange || filter.fieldType == FilterFieldType.TimeRange){
            cell = tableview.dequeueReusableCellWithIdentifier("NumberRangeCell") as FilterCell;
        }

        cell.filter = filter;
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        if (indexPath.row < this._filters.length) return;

        if (this.isEnableApplyButton == false) return;

        let predicateFormat = this.filterController.predicateFormat();

        if (this.target != null && this.completion != null) {
            this.completion.call(this.target, predicateFormat);
        }

        if (this.embeddedController == false) this.dismissViewController(true);        
    }

    private clearFiltersAction(){
        for (let index = 0; index < this._filters.length; index++){
            let f = this._filters[index];
            f.value = null;
            f.valueString = null;
        }

        this.tableView.reloadData();
    }


}