

// Generated class Configuration_ManagedObject

class Configuration_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: section
    set section(value:string) {
        this.setValueForKey(value, 'section');
    }
    get section():string {
        return this.valueForKey('section');
    }
    set sectionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'section');
    }
    get sectionPrimitiveValue():string {
        return this.primitiveValueForKey('section');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: value
    set value(value:string) {
        this.setValueForKey(value, 'value');
    }
    get value():string {
        return this.valueForKey('value');
    }
    set valuePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'value');
    }
    get valuePrimitiveValue():string {
        return this.primitiveValueForKey('value');
    }

    // Property: entityName
    set entityName(value:string) {
        this.setValueForKey(value, 'entityName');
    }
    get entityName():string {
        return this.valueForKey('entityName');
    }
    set entityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityName');
    }
    get entityNamePrimitiveValue():string {
        return this.primitiveValueForKey('entityName');
    }

    // Property: entityTitleKey
    set entityTitleKey(value:string) {
        this.setValueForKey(value, 'entityTitleKey');
    }
    get entityTitleKey():string {
        return this.valueForKey('entityTitleKey');
    }
    set entityTitleKeyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityTitleKey');
    }
    get entityTitleKeyPrimitiveValue():string {
        return this.primitiveValueForKey('entityTitleKey');
    }

    // Property: entityValueKey
    set entityValueKey(value:string) {
        this.setValueForKey(value, 'entityValueKey');
    }
    get entityValueKey():string {
        return this.valueForKey('entityValueKey');
    }
    set entityValueKeyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityValueKey');
    }
    get entityValueKeyPrimitiveValue():string {
        return this.primitiveValueForKey('entityValueKey');
    }

    // Property: entityValueTitle
    set entityValueTitle(value:string) {
        this.setValueForKey(value, 'entityValueTitle');
    }
    get entityValueTitle():string {
        return this.valueForKey('entityValueTitle');
    }
    set entityValueTitlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityValueTitle');
    }
    get entityValueTitlePrimitiveValue():string {
        return this.primitiveValueForKey('entityValueTitle');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
