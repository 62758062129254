/**
 * Created by miguel on 22/2/17.
 */




class IssuedInvoiceCell extends UITableViewCell
{
    private documentIDLabel:MUILabel = null;    
    private nameLabel:MUILabel = null;
    private priceLabel:MUILabel = null;    
    private nextDocumentLabel:MUILabel = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML(){
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");        
        this.nameLabel = MUIOutlet(this, "client-name-lbl", "MUILabel");
        this.priceLabel = MUIOutlet(this, "amount-lbl", "MUILabel");               
        this.nextDocumentLabel = MUIOutlet(this, "next-doc-lbl", "MUILabel");
        
        this.separatorStyle = UITableViewCellSeparatorStyle.None;
    }

    set item(i:ArchivedDocument){
        this.nameLabel.text = i.ticketLegalEntityName;
        
        this.documentIDLabel.text = i.documentID;
        this.priceLabel.text = this.cf.stringFromNumber(i.totalPrice);

        this.nextDocumentLabel.text = i.nextDocument != null ? i.nextDocument.legalDocumentID : null;
    }

}