/**
 * Created by godshadow on 04/08/16.
 */

class TaxListViewController extends MUIViewController
{
    private addButton:MUIButton = null;
    private tableView:UITableView = null;                    

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function(){
           this.addTax();
        });

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;       
        this.tableView.reloadData();
    }
    
    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('TaxCell') as TaxCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Tax;
        cell.item = item;
            
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Tax;

        let vc = new TaxDetailViewController("tax-detail-view");
        vc.initWithResource("layout/taxes/TaxDetailView.html");
        vc.item = item;

        this.navigationController.pushViewController(vc, true);
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Tax;    

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Tax');
    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private addTax(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('TAX','Tax'), MIOLocalizeString('CHOOSE A NAME','Choose a name '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Name");
        });
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Quantity");
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            textField.formatter = ad.numberFormatter;
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            let quantityTF = avc.textFields[1];
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let taxquantity = ad.numberFormatter.numberFromString(quantityTF.text.trim());

            if(name.length > 0 && taxquantity != null){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                let tax = MIOEntityDescription.insertNewObjectForEntityForName("Tax", moc) as Tax;
                tax.identifier = MIOUUID.UUID().UUIDString;
                tax.name = name;
                tax.taxQuantity = taxquantity == 0 ? taxquantity : taxquantity / 100;
                moc.save()
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('CHECK NAME AND TAX QUANTITY','Check name and tax quantity.'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
        
        this.presentViewController(avc, true);
    }
}
