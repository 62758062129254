
class DBCacheHelper extends MIOObject 
{
    private static _sharedInstance: DBCacheHelper = null;
    static sharedInstance(): DBCacheHelper {

        if (this._sharedInstance == null) {
            this._sharedInstance = new DBCacheHelper();
            this._sharedInstance.init();
        }

        return this._sharedInstance;
    }

    constructor() {
        super();
        if (DBCacheHelper._sharedInstance) {
            throw new Error("DBCacheHelper: Instantiation failed: Use sharedInstance() instead of new.");
        }
    }

    private cachedEntities = {};
    cacheEntityName(entityName:string, relationships?, predicateFormat?:string){
        if (this.cachedEntities[entityName] != null) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let request = DBHelper.listFetchRequestWithEntityName(entityName, null, predicateFormat, false);
        if (relationships != null) request.relationshipKeyPathsForPrefetching = relationships;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(request, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this.cachedEntities[entityName] = fetchedResultsController;
    }

    cacheFetchRequest(fetchRequest:MIOFetchRequest){
        if (this.cachedEntities[fetchRequest.entityName] != null) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        this.cachedEntities[fetchRequest.entityName] = fetchedResultsController;
    }


    objectsForEntityName(entityName:string, sortDescriptors:[MIOSortDescriptor], predicate:MIOPredicate){        
        let frc = this.cachedEntities[entityName] as MIOFetchedResultsController;
        if (frc == null) return [];

        let objects = frc.fetchedObjects;
        if (predicate != null) objects = _MIOPredicateFilterObjects(objects, predicate);
        if (sortDescriptors != null) objects = _MIOSortDescriptorSortObjects(objects, sortDescriptors);

        return objects;
    }

    objectForEntityNameWithPredicate(entityName:string, predicate:MIOPredicate){        
        let objects = this.objectsForEntityName(entityName, null, predicate);                
        return objects.length > 0 ? objects[0] : null;
    }

    controllerDidChangeContent(controller: MIOFetchedResultsController) {
        //let entityName = controller.fetchRequest.entityName;
        //TODO: Notify if there's any changes.
    }
    
}