
class ReportResultsView extends MUIView 
{
    initWithLayer(layer, owner, options){
        super.initWithLayer(layer, owner, options);

        // Check if we have prototypes
        if (this.layer.childNodes.length > 0) {
            for (let index = 0; index < this.layer.childNodes.length; index++) {
                var subLayer = this.layer.childNodes[index];

                if (subLayer.tagName != "DIV")
                    continue;
                
                if (subLayer.getAttribute("data-section-header") != null) {
                    this.addSectionHeader(subLayer);
                }
                else if (subLayer.getAttribute("data-column-header") != null) {
                    //this.addSectionHeader(subLayer);
                }
                else if (subLayer.getAttribute("data-column-footer") != null) {
                    //this.addSectionHeader(subLayer);
                }
                else if (subLayer.getAttribute("data-section-footer") != null) {
                    this.addSectionFooter(subLayer);
                }
                else if (subLayer.getAttribute("data-cell-identifier") != null) {
                    this.addCellPrototype(subLayer);                    
                }
            }
        }   
    }

    private addSectionHeader(layer){

    }

    private addSectionFooter(layer){

    }

    private addCellPrototype(layer){

    }
}