

class ReportDateFilterCell extends MUITableViewCell
{
    delegate = null;
    private _filter = null;

    private titleLabel = null;
    private dateButton = null;
    dateLabel = null;

    awakeFromHTML()
    {
        this.titleLabel = MUIOutlet(this, "rfv_c1_lbl", "MUILabel");

        this.dateLabel = MUIOutlet(this, "rfv_c1_date_lbl", "MUILabel");
        this.dateLabel.text = MIOLocalizeString("SELECT DATE", "Select date");
        
        this.dateButton = MUIOutlet(this, "rfv_c1_calendar_btn", "MUIButton");
        this.dateButton.setAction(this, function(){

            if (this.delegate != null) {
                this.delegate.selectDateFromDateFilterCell.call(this.delegate, this, this.dateButton, this._filter);
            }
        });
    }

    set filter(filter){

        this._filter = filter;
        this.titleLabel.text = filter["title"] + ":";
    }
}