

// Generated class IntegratorsMappings_ManagedObject

class IntegratorsMappings_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: ownName
    set ownName(value:string) {
        this.setValueForKey(value, 'ownName');
    }
    get ownName():string {
        return this.valueForKey('ownName');
    }
    set ownNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ownName');
    }
    get ownNamePrimitiveValue():string {
        return this.primitiveValueForKey('ownName');
    }

    // Property: ownID
    set ownID(value:string) {
        this.setValueForKey(value, 'ownID');
    }
    get ownID():string {
        return this.valueForKey('ownID');
    }
    set ownIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ownID');
    }
    get ownIDPrimitiveValue():string {
        return this.primitiveValueForKey('ownID');
    }

    // Property: ownEntityName
    set ownEntityName(value:string) {
        this.setValueForKey(value, 'ownEntityName');
    }
    get ownEntityName():string {
        return this.valueForKey('ownEntityName');
    }
    set ownEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ownEntityName');
    }
    get ownEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('ownEntityName');
    }

    // Property: foreignName
    set foreignName(value:string) {
        this.setValueForKey(value, 'foreignName');
    }
    get foreignName():string {
        return this.valueForKey('foreignName');
    }
    set foreignNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'foreignName');
    }
    get foreignNamePrimitiveValue():string {
        return this.primitiveValueForKey('foreignName');
    }

    // Property: foreignID
    set foreignID(value:string) {
        this.setValueForKey(value, 'foreignID');
    }
    get foreignID():string {
        return this.valueForKey('foreignID');
    }
    set foreignIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'foreignID');
    }
    get foreignIDPrimitiveValue():string {
        return this.primitiveValueForKey('foreignID');
    }

    // Property: foreignEntityName
    set foreignEntityName(value:string) {
        this.setValueForKey(value, 'foreignEntityName');
    }
    get foreignEntityName():string {
        return this.valueForKey('foreignEntityName');
    }
    set foreignEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'foreignEntityName');
    }
    get foreignEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('foreignEntityName');
    }

    // Property: enviroment
    set enviroment(value:string) {
        this.setValueForKey(value, 'enviroment');
    }
    get enviroment():string {
        return this.valueForKey('enviroment');
    }
    set enviromentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'enviroment');
    }
    get enviromentPrimitiveValue():string {
        return this.primitiveValueForKey('enviroment');
    }

    // Property: integrator
    set integrator(value:string) {
        this.setValueForKey(value, 'integrator');
    }
    get integrator():string {
        return this.valueForKey('integrator');
    }
    set integratorPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integrator');
    }
    get integratorPrimitiveValue():string {
        return this.primitiveValueForKey('integrator');
    }

    // Property: extra
    set extra(value:string) {
        this.setValueForKey(value, 'extra');
    }
    get extra():string {
        return this.valueForKey('extra');
    }
    set extraPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'extra');
    }
    get extraPrimitiveValue():string {
        return this.primitiveValueForKey('extra');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
