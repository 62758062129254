

// Generated class BookingZone_ManagedObject

class BookingZone_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: maxPax
    set maxPax(value:number) {
        this.setValueForKey(value, 'maxPax');
    }
    get maxPax():number {
        return this.valueForKey('maxPax');
    }
    set maxPaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maxPax');
    }
    get maxPaxPrimitiveValue():number {
        return this.primitiveValueForKey('maxPax');
    }

    // Property: templatePrefix
    set templatePrefix(value:number) {
        this.setValueForKey(value, 'templatePrefix');
    }
    get templatePrefix():number {
        return this.valueForKey('templatePrefix');
    }
    set templatePrefixPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'templatePrefix');
    }
    get templatePrefixPrimitiveValue():number {
        return this.primitiveValueForKey('templatePrefix');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: parent
    set parent(value:BookingZone) {
        this.setValueForKey(value, 'parent');
    }
    get parent():BookingZone {
        return this.valueForKey('parent') as BookingZone;
    }

    // Relationship: subZones
    protected _subZones:MIOManagedObjectSet = null;
    get subZones():MIOManagedObjectSet {
        return this.valueForKey('subZones');
    }
    addSubZonesObject(value:BookingZone) {
        this._addObjectForKey(value, 'subZones');
    }
    removeSubZonesObject(value:BookingZone) {
        this._removeObjectForKey(value, 'subZones');
    }

    // Relationship: ocupationRules
    protected _ocupationRules:MIOManagedObjectSet = null;
    get ocupationRules():MIOManagedObjectSet {
        return this.valueForKey('ocupationRules');
    }
    addOcupationRulesObject(value:BookingZoneOcupationRule) {
        this._addObjectForKey(value, 'ocupationRules');
    }
    removeOcupationRulesObject(value:BookingZoneOcupationRule) {
        this._removeObjectForKey(value, 'ocupationRules');
    }

    // Relationship: priceRules
    protected _priceRules:MIOManagedObjectSet = null;
    get priceRules():MIOManagedObjectSet {
        return this.valueForKey('priceRules');
    }
    addPriceRulesObject(value:BookingPriceRule) {
        this._addObjectForKey(value, 'priceRules');
    }
    removePriceRulesObject(value:BookingPriceRule) {
        this._removeObjectForKey(value, 'priceRules');
    }
}
