

// Generated class PromotionalCode_ManagedObject

class PromotionalCode_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: code
    set code(value:string) {
        this.setValueForKey(value, 'code');
    }
    get code():string {
        return this.valueForKey('code');
    }
    set codePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'code');
    }
    get codePrimitiveValue():string {
        return this.primitiveValueForKey('code');
    }

    // Property: beginDate
    set beginDate(value:Date) {
        this.setValueForKey(value, 'beginDate');
    }
    get beginDate():Date {
        return this.valueForKey('beginDate');
    }
    set beginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'beginDate');
    }
    get beginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('beginDate');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }

    // Property: usedDate
    set usedDate(value:Date) {
        this.setValueForKey(value, 'usedDate');
    }
    get usedDate():Date {
        return this.valueForKey('usedDate');
    }
    set usedDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'usedDate');
    }
    get usedDatePrimitiveValue():Date {
        return this.primitiveValueForKey('usedDate');
    }

    // Property: offerID
    set offerID(value:string) {
        this.setValueForKey(value, 'offerID');
    }
    get offerID():string {
        return this.valueForKey('offerID');
    }
    set offerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'offerID');
    }
    get offerIDPrimitiveValue():string {
        return this.primitiveValueForKey('offerID');
    }

    // Property: formatID
    set formatID(value:string) {
        this.setValueForKey(value, 'formatID');
    }
    get formatID():string {
        return this.valueForKey('formatID');
    }
    set formatIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatID');
    }
    get formatIDPrimitiveValue():string {
        return this.primitiveValueForKey('formatID');
    }

    // Property: productID
    set productID(value:string) {
        this.setValueForKey(value, 'productID');
    }
    get productID():string {
        return this.valueForKey('productID');
    }
    set productIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productID');
    }
    get productIDPrimitiveValue():string {
        return this.primitiveValueForKey('productID');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
