

// Generated class SupplierProduct_ManagedObject

class SupplierProduct_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: placeID
    set placeID(value:string) {
        this.setValueForKey(value, 'placeID');
    }
    get placeID():string {
        return this.valueForKey('placeID');
    }
    set placeIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'placeID');
    }
    get placeIDPrimitiveValue():string {
        return this.primitiveValueForKey('placeID');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: averagePrice
    set averagePrice(value:number) {
        this.setValueForKey(value, 'averagePrice');
    }
    get averagePrice():number {
        return this.valueForKey('averagePrice');
    }
    set averagePricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'averagePrice');
    }
    get averagePricePrimitiveValue():number {
        return this.primitiveValueForKey('averagePrice');
    }

    // Property: lastPrice
    set lastPrice(value:number) {
        this.setValueForKey(value, 'lastPrice');
    }
    get lastPrice():number {
        return this.valueForKey('lastPrice');
    }
    set lastPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lastPrice');
    }
    get lastPricePrimitiveValue():number {
        return this.primitiveValueForKey('lastPrice');
    }

    // Property: productPrice
    set productPrice(value:number) {
        this.setValueForKey(value, 'productPrice');
    }
    get productPrice():number {
        return this.valueForKey('productPrice');
    }
    set productPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productPrice');
    }
    get productPricePrimitiveValue():number {
        return this.primitiveValueForKey('productPrice');
    }

    // Property: productAveragePrice
    set productAveragePrice(value:number) {
        this.setValueForKey(value, 'productAveragePrice');
    }
    get productAveragePrice():number {
        return this.valueForKey('productAveragePrice');
    }
    set productAveragePricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productAveragePrice');
    }
    get productAveragePricePrimitiveValue():number {
        return this.primitiveValueForKey('productAveragePrice');
    }

    // Property: productLastPrice
    set productLastPrice(value:number) {
        this.setValueForKey(value, 'productLastPrice');
    }
    get productLastPrice():number {
        return this.valueForKey('productLastPrice');
    }
    set productLastPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productLastPrice');
    }
    get productLastPricePrimitiveValue():number {
        return this.primitiveValueForKey('productLastPrice');
    }

    // Property: discountString
    set discountString(value:string) {
        this.setValueForKey(value, 'discountString');
    }
    get discountString():string {
        return this.valueForKey('discountString');
    }
    set discountStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'discountString');
    }
    get discountStringPrimitiveValue():string {
        return this.primitiveValueForKey('discountString');
    }

    // Property: isDefault
    set isDefault(value:boolean) {
        this.setValueForKey(value, 'isDefault');
    }
    get isDefault():boolean {
        return this.valueForKey('isDefault');
    }
    set isDefaultPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefault');
    }
    get isDefaultPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefault');
    }

    // Property: supplierReference
    set supplierReference(value:string) {
        this.setValueForKey(value, 'supplierReference');
    }
    get supplierReference():string {
        return this.valueForKey('supplierReference');
    }
    set supplierReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'supplierReference');
    }
    get supplierReferencePrimitiveValue():string {
        return this.primitiveValueForKey('supplierReference');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: supplier
    set supplier(value:Supplier) {
        this.setValueForKey(value, 'supplier');
    }
    get supplier():Supplier {
        return this.valueForKey('supplier') as Supplier;
    }
}
