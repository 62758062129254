//
// Generated class SupplierInvoiceGroupLine
//

/// <reference path="SupplierInvoiceGroupLine_ManagedObject.ts" />

class SupplierInvoiceGroupLine extends SupplierInvoiceGroupLine_ManagedObject
{
    identifier     = null;
    isArchived     = null;
    id = null;
    invoice        = null;
    id_product     = null;
    noteid         = null;
    productname    = null;
   // id_tax       = null;
    tax_name       = null;
    tax_quantity   = null;
    quantity       = null;
    unitprice      = null;
    orderindex     = null;
    discount       = null;
    totalpricebase = null;
    totaltaxes     = null;
    totalprice     = null;
    totaldiscount  = null;
    totalinvited   = null;
    totaldeleted   = null;

    category_name  = null;
    format_name    = null;
    menu_name      = null;
    product_name   = null;
    product_price  = null;
    rate           = null;
    tags           = null;
    parent         = null;

    groupid        = null;

    //Used only for manager purpose
    
    lines:MIOSet = null;

    setObject(object, order, moc)
    {
        this.invoice        = object['invoice'];
        this.isArchived     = this.invoice['isArchived'];
        this.identifier     = object['id'];
        this.id             = object['id']; 
        this.tax_name       = object['taxname'];
        this.tax_quantity   = object['tax'];
        this.category_name  = object['categoryname'];
        this.format_name    = object['formatname'];
        this.menu_name      = object['menuname'];
        this.product_name   = object['productname'];
        this.product_price  = object['productprice'];
        this.id_product     = object['productid'];
        this.noteid         = object['noteid'];
        this.rate           = object['rate'];
        this.tags           = object['tags'];
        this.discount       = object['discount'];
        this.groupid        = object['groupid'];
        this.totalpricebase = object['totalpricebase'] || 0;
        this.totaltaxes     = object['totaltaxes'] || 0;
        this.totalprice     = object['totalprice'] || 0;
        this.totaldiscount  = object['totaldiscount'] || 0; 
        this.totalinvited   = object['totalinvited'] || 0;
        this.totaldeleted   = object['totaldeleted'] || 0;
        this.parent         = object['parent'];
        
        
        this.orderindex  = object['orderindex'] || order;

        let key = this.isArchived ? 'archivedTicketLines' : 'documentLines';
        let lines = object[key] || [];
        this.quantity    = object['quantity'] ? object['quantity'] : lines.length;
        
        this.lines = MIOSet.set();

        if(lines){
            var instance = this;
            
            lines.forEach(function (line, index) {
                var p = MIOEntityDescription.insertNewObjectForEntityForName("SupplierInvoiceLine", moc) as SupplierInvoiceLine;
                line['group'] = instance;
                p.setObject(line,index, moc);
                instance.lines.addObject(p);
            }, instance);
        }
    }
    
    get id_invoice()
    {
        return this.invoice.identifier;
    }

    get price()
    {
        return parseFloat(this.valueForKey('quantity')) * parseFloat(this.valueForKey('product_price')) - parseFloat(this.valueForKey('discount') || 0);
    }
    
    get priceWithTax()
    {
        return (1 + parseFloat(this.valueForKey('tax_quantity'))) * this.price;
    }
    
    getObject()
    {
        var object = {};
        
        if(this.identifier != null)
            object['id'] = this.identifier;

        object['categoryname']   = this.valueForKey('category_name');
        object['formatname']     = this.valueForKey('format_name');
        object['menuname']       = this.valueForKey('menu_name'); 
        object['productname']    = this.valueForKey('product_name');
        object['productprice']   = this.valueForKey('product_price');
        object['productid']      = this.valueForKey('id_product');
        object['noteid']         = this.valueForKey('noteid');
        object['taxname']        = this.valueForKey('tax_name');
        object['tax']            = this.valueForKey('tax_quantity');
        object['rate']           = this.valueForKey('rate');
        object['tags']           = this.valueForKey('tags');
        object['discount']       = this.valueForKey('discount');
        object['totalpricebase'] = this.valueForKey('totalpricebase');
        object['totaltaxes']     = this.valueForKey('totaltaxes');
        object['totalprice']     = this.valueForKey('totalprice');
        object['totaldiscount']  = this.valueForKey('totaldiscount');
        object['totalinvited']   = this.valueForKey('totalinvited');
        object['totaldeleted']   = this.valueForKey('totaldeleted');
        object['parent']         = this.valueForKey('parent');

        let lines = [];
                
        for(let i = 0; i < this.lines.length; i++)
        {
            lines.push(this.lines.objectAtIndex(i)); 
        }
        
        
        let key = this.isArchived ? 'archivedTicketLines' : 'documentLines';

        object[key] = lines;

        return object;
   }
}
