
class CalendarNavBar extends MUIView 
{
    delegate = null;

    private prevButton:MUIButton = null;
    private nextButton:MUIButton = null;
    private monthComboBox:MUIComboBox = null;
    private yearComboBox:MUIComboBox = null;

    awakeFromHTML(){
        let today = MIODateToday();

        this.prevButton = MUIOutlet(this, "calendar-back-btn", "MUIButton");
        this.prevButton.setAction(this, function(){
            this.addDateOffset(-1);
        });
        
        this.monthComboBox = MUIOutlet(this, "calendar-month-cb", "MUIComboBox");
        if (this.monthComboBox) {
            this.monthComboBox.removeAllItems();        
            for (let index = 0; index < 12; index++) {
                this.monthComboBox.addItem(MIODateGetStringForMonth(index), index);
            }
            this.monthComboBox.setOnChangeAction(this, function(control, value){
                let m = parseInt(value);
                let y = parseInt(this.yearComboBox.getSelectedItem());
                this.delegate.monthAndDateDidChange(m, y);
            });
            this.monthComboBox.selectItem(MIODateGetMonthFromDate(today));
        }
        
        this.yearComboBox = MUIOutlet(this, "calendar-year-cb", "MUIComboBox");        
        this.yearComboBox.removeAllItems();        
        // Get 10 years, 5 years before, 5 year after the selected year
        let year = MIODateGetYearFromDate(MIODateToday());
        let minYear = year - 5;
        let maxYear = year + 5;
        for (let index = minYear; index < maxYear; index++) {
            this.yearComboBox.addItem(index);
        }
        this.yearComboBox.setOnChangeAction(this, function(control, value){
            let m = this.monthComboBox != null ? parseInt(this.monthComboBox.getSelectedItem()) : 0;
            let y = parseInt(value);            
            this.delegate.monthAndDateDidChange(m, y);
        });
        this.yearComboBox.selectItem(MIODateGetYearFromDate(today));
        
        this.nextButton = MUIOutlet(this, "calendar-next-btn", "MUIButton");
        this.nextButton.setAction(this, function(){
            this.addDateOffset(1);
        });
    }

    private addDateOffset(offset){
        if (this.monthComboBox != null) {
        
            let m = parseInt(this.monthComboBox.getSelectedItem());
            let y = parseInt(this.yearComboBox.getSelectedItem());
    
            m += offset;
            if (m < 0) {
                m = 11;
                y--;
            }
            else if (m > 11) {
                m = 0;
                y++;
            }
            this.monthComboBox.selectItem(m);
            this.yearComboBox.selectItem(y);
            this.delegate.monthAndDateDidChange(m, y);
        }
        else {
            let y = parseInt(this.yearComboBox.getSelectedItem()) + offset;
            this.yearComboBox.selectItem(y);
            this.delegate.monthAndDateDidChange(0, y);
        }            
    }

}