
/// <reference path="../notes/NoteListViewController.ts" />
/// <reference path="../AppHelper.ts" />


class MovementNoteListViewController extends NoteListViewController 
{
    protected noteStatusForSegmentedIndex(index){
        if (index == 1) return StockNoteStatus.Processed;        
        return StockNoteStatus.None;
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('MovementNoteCell') as MovementNoteCell;    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockNote;
    
        cell.item = item;
        cell.selected = this.selectedNote == item ? true : false;

        return cell;
    }
        
    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("documentID", false)];
        if (this.selectedStatus == StockNoteStatus.Processed) {
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("creationDate", false)];
        }   
                
        let predicateFormat = "type = " + StockNoteType.MovementNote + " AND status == " + this.selectedStatus;
        if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
        if (this.searchString != null) {
            predicateFormat += " AND (documentID CONTAINS '" + this.searchString + "' OR internalOrder.documentID CONTAINS '" + this.searchString + "')";
        }
        if (this.filterPredicateFormat != null) {
            predicateFormat += " AND " + this.filterPredicateFormat;
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockNote", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 50;
        fetchRequest.relationshipKeyPathsForPrefetching = ['internalOrder'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private _filters = null;
    get filters(){
        if (this._filters != null) return this._filters;

        this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "validationDate", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
                FilterOption.filterWithTitle("ToDate", "To day", "validationDate", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),                
                FilterOption.filterWithFetchRequest("Origin", "From warehouse", "originWarehouse.identifier", FilterFieldType.DropDown, "Warehouse", "name", "identifier", "Select origin warehouse"),
                FilterOption.filterWithFetchRequest("Destination", "To warehouse", "destinationWarehouse.identifier", FilterFieldType.DropDown, "Warehouse", "name", "identifier", "Select destination warehouse")];

        return this._filters;
    }
    
    private movementDetailViewController:MovementNoteDetailViewController = null;
    protected showSelectedItem(){
        if(this.movementDetailViewController == null) {
            let vc = new MovementNoteDetailViewController('movement-note-detail-view');
            vc.initWithResource('layout/movementnote/MovementNoteDetailView.html');   
            this.movementDetailViewController = vc;
        }
        
        this.movementDetailViewController.note = this.selectedNote;
        this.splitViewController.showDetailViewController(this.movementDetailViewController);
    }

    protected showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("MovementNote", NoItemSelectedViewControllerType.MovementNote);
        this.splitViewController.showDetailViewController(vc);
    }

    protected showAddNoteViewController(){
        
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD MOVEMENT NOTE','Add Movement Note'), MIOLocalizeString('CHOOSE A ORIGIN AND DESTINATION WAREHOUSE','Choose a origin and destination warehouse.'), MUIAlertViewStyle.Default);
        
        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
        
            DBHelper.sharedInstance().addObserverForEntity(comboBox, 'Warehouse', null, null, null, function (objects) {
                comboBox.removeAllItems();
                comboBox.addItem(NoWarehouse, -1);
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].name, i);
            });
        });
        
        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
             
            DBHelper.sharedInstance().addObserverForEntity(comboBox, 'Warehouse', null, null, null, function (objects) {
                comboBox.removeAllItems();
                comboBox.addItem(NoWarehouse, -1);
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].name, i);
            });
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function(){
            let moc: MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

            let originWarehouseComboBox:MUIComboBox = avc.comboBoxes[0];
            let originWarehouse =  originWarehouseComboBox.getSelectedItem();

            let destinationWarehouseComboBox:MUIComboBox = avc.comboBoxes[1];        
            let destinationWarehouse =  destinationWarehouseComboBox.getSelectedItem();
            
            let errorString = '';

            if(destinationWarehouse == originWarehouse) {
                AppHelper.showErrorMessage(this, "Error", "Origin and destination warehouse can't be the same.");
                return;
            }

            originWarehouse = originWarehouse == '-1' ? null : DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc.comboBoxes[0], originWarehouse, 'Warehouse');
            destinationWarehouse = destinationWarehouse == '-1' ? null : DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc.comboBoxes[1], destinationWarehouse, 'Warehouse');
            this.addMovementNote(originWarehouse, destinationWarehouse);
            
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        avc.addCompletionHandler(this, function () {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc.comboBoxes[0], 'Warehouse');
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc.comboBoxes[1], 'Warehouse');
        });
        
        this.presentViewController(avc, true);
    }

   private addMovementNote(originWarehouse, destinationWarehouse){       
        this.selectedNote = DBHelper.createMovementNote(originWarehouse, destinationWarehouse);         
        DBHelper.saveMainContext();
        this.showSelectedItem();
   }
}
