//
// Generated class ReportItem
//

/// <reference path="ReportItem_ManagedObject.ts" />

enum ReportItemType {
    Chart,
    List,
    Folder,
    Script
}

enum ReportItemValueType {
    String,
    Integer,
    Float,
    Currency,    
    Date
}

class ReportItem extends ReportItem_ManagedObject
{    
    title:string = null;
    section:string = null;
    type = null;
    queryID:string = null;
    scriptID:string = null;
    queryFilterKeys = null;    
    nextReportID:string = null;
    nextReportFilterKeys = null;
    orderIndex = null;
    sectionKeyPaths = null;

    parseJSON(item){        
        this.identifier = item["id"];
        this.type = item["type"];

        this.title = item["name"];
        this.section = item["section"];
        this.queryID = item["queryid"];
        this.scriptID = item["scriptid"];
        this.queryFilterKeys = item["querykeys"];
        this.nextReportID = item["nextlistid"];
        this.nextReportFilterKeys = item["nextquerykeys"];
        this.orderIndex = item["orderindex"];        
        this.sectionKeyPaths = item["sectionkeypaths"];
    }

    private isDownloading = false;
    executeQuery(dateTimeFrom, dateTimeTo, filterValues, identifier, identifierType, target?, completion?) {

        if (this.isDownloading == true) return;
        this.isDownloading = true;

        let ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws: WebService = ad.webService;        

        let from = ad.serverDateTimeFormatter.stringFromDate(dateTimeFrom);
        let to = ad.serverDateTimeFormatter.stringFromDate(dateTimeTo);
        
        let body = this.getBody(from, to, filterValues);
        
        ws.executeQuery(body, this, function (code, json) {
            let items = null;
            if (code == 200) {
                items = json["data"];
            }
            else {
                MIOLog("ReportItem Error: ");
                MIOLog("ReportItem Error -> Body: " + JSON.stringify(body));
                MIOLog("ReportItem Error -> Code: " + code + ". Something happend in the server!");
            }

            if (completion != null && target != null) {
                completion.call(target, code, items);
            }

            this.isDownloading = false;
        });
    }

    private getBody(from:string, to:string, filterValues){
        
        var body = {};
        body["id"] = this.queryID != null ? this.queryID : this.scriptID;
        var filters = {};
        body["filters"] = filters;
        filters["session_begindate"] = from;
        filters["session_enddate"] = to;
                
        if (filterValues == null) return body;

        for (let key in filterValues){
            if (key == "session_begindate" || key == "session_enddate") continue;
            let value = filterValues[key];
            filters[key] = value;
        }        

        return body;
    }
    
    static parseValue(type:ReportItemValueType, value){

        var v = value;

        switch (type){

            case ReportItemValueType.Integer:
                v = parseInt(value);
                break;

            case ReportItemValueType.Float:
            case ReportItemValueType.Currency:
                v = parseFloat(value);
                break;

            case ReportItemValueType.Date:
                v = MIODateFromString(value);
                break;
        }

        return v;
    }
}
