
class CardRuleCell extends MUITableViewCell
{
    private conditionTypeComboBox:MUIComboBox = null;   
    private conditionValueTextField:MUITextField = null;
    private discountTextField:MUITextField = null;
    private currentDiscountLimitLabel:MUILabel = null;
    private discountLimitTextField:MUITextField = null;
    private currentInvitationLimitLabel:MUILabel = null;    
    private invitationLimitTextField:MUITextField = null;

    awakeFromHTML(){
        this.conditionTypeComboBox = MUIOutlet(this, "condition-type-cb", "MUIComboBox");
        this.conditionTypeComboBox.setOnChangeAction(this, function(control, value){
            this.rule.conditionType = value;
        });
        
        this.conditionValueTextField = MUIOutlet(this, "condition-value-tf", "MUITextField");
        this.conditionValueTextField.setOnChangeText(this, function(control, value){
            this.rule.conditionValue = value;            
        });

        this.discountTextField = MUIOutlet(this, "discount-tf", "MUITextField");
        this.discountTextField.setOnChangeText(this, function(control, value){
            this.rule.discount = value;
        });

        this.currentDiscountLimitLabel = MUIOutlet(this, "current-discount-lbl", "MUILabel");

        this.discountLimitTextField = MUIOutlet(this, "discount-limit-tf", "MUITextField");
        this.discountLimitTextField.setOnChangeText(this, function(control, value){
            this.rule.discountLimitInSession = value;
        });

        this.currentInvitationLimitLabel = MUIOutlet(this, "current-invitation-lbl", "MUILabel");

        this.invitationLimitTextField = MUIOutlet(this, "invitation-limit-tf", "MUITextField");
        this.invitationLimitTextField.setOnChangeText(this, function(control, value){
            this.rule.invitationLimitInSession = value;
        });
        
        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private rule:OfferRule = null;
    set item(item:OfferRule){
        this.rule = item;

        this.conditionTypeComboBox.selectItem(item.conditionType);
        this.conditionValueTextField.text = item.conditionValue;
        this.discountTextField.text = item.discount;
        this.currentDiscountLimitLabel.text = item.currentDiscounts;
        this.discountLimitTextField.text = item.discountLimitInSession;
        this.currentInvitationLimitLabel.text = item.currentInvitations;
        this.invitationLimitTextField.text = item.invitationLimitInSession;
    }
}