class BookingDetailLogsViewController extends MUIViewController
{
    private tableView:MUITableView = null;
    private detailTableView:MUITableView = null;

    private noSelectedView:MUIView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.detailTableView = MUIOutlet(this, "detail-table-view", "MUITableView");
        this.detailTableView.dataSource = this;    
        
        this.noSelectedView = MUIOutlet(this, "no-selected-view", "MUIView");
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.noSelectedView.setHidden(false);
        this.tableView.reloadData();
        this.detailTableView.reloadData();
    }

    private _booking:Booking = null;
    set booking(value:Booking){
        this._booking = value;
        this.fetchedResultsController = null;        
        this.selectedInfoKeys = [];
        this.selectedLogInfo = {};        
    }

    numberOfSections(tableview){
        if (tableview == this.tableView) {
        return this.fetchedResultsController.sections.length;        
        }
        else {
            return 1;
        }
    }

    numberOfRowsInSection(tableview, section) {
        if (tableview == this.tableView){
            let sec = this.fetchedResultsController.sections[section];
            return sec.numberOfObjects();
        }
        else {            
            return this.selectedInfoKeys.length;
        }
    }

    cellAtIndexPath(tableview: MUITableView, indexPath: MIOIndexPath){

        if (tableview == this.tableView) {
            let cell: BookingLogCell = tableview.dequeueReusableCellWithIdentifier("BookingLogCell") as BookingLogCell;
            let item:BookingLog = this.fetchedResultsController.objectAtIndexPath(indexPath);
            cell.item = item;                    
            return cell;    
        }
        else {
            let cell: BookingLogDataCell = tableview.dequeueReusableCellWithIdentifier("BookingLogDataCell") as BookingLogDataCell;
            let key = this.selectedInfoKeys[indexPath.row];
            let value = this.selectedLogInfo[key];
            cell.keyLabel.text = key;
            cell.valueLabel.text = value;
            return cell;
        }
    }

    private selectedInfoKeys = [];
    private selectedLogInfo = {};
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath) {
        let item:BookingLog = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.selectedLogInfo = item.data;
        this.selectedInfoKeys = Object.keys(this.selectedLogInfo);
        this.detailTableView.reloadData();
        this.noSelectedView.setHidden(true);
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("date", true)];
                
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("BookingLog");
        fetchRequest.sortDescriptors = sortDescriptors;         
        fetchRequest.predicate = MIOPredicate.predicateWithFormat("bookingID == " + this._booking.identifier);

        let fetchedResultsController = new MIOFetchedResultsController();        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }
}