

// Generated class ArchivedTicket_ManagedObject

class ArchivedTicket_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: modifiedByUser
    set modifiedByUser(value:boolean) {
        this.setValueForKey(value, 'modifiedByUser');
    }
    get modifiedByUser():boolean {
        return this.valueForKey('modifiedByUser');
    }
    set modifiedByUserPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'modifiedByUser');
    }
    get modifiedByUserPrimitiveValue():boolean {
        return this.primitiveValueForKey('modifiedByUser');
    }

    // Property: modifiedType
    set modifiedType(value:number) {
        this.setValueForKey(value, 'modifiedType');
    }
    get modifiedType():number {
        return this.valueForKey('modifiedType');
    }
    set modifiedTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'modifiedType');
    }
    get modifiedTypePrimitiveValue():number {
        return this.primitiveValueForKey('modifiedType');
    }

    // Property: documentID
    set documentID(value:string) {
        this.setValueForKey(value, 'documentID');
    }
    get documentID():string {
        return this.valueForKey('documentID');
    }
    set documentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentID');
    }
    get documentIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentID');
    }

    // Property: legalDocumentID
    set legalDocumentID(value:string) {
        this.setValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentID():string {
        return this.valueForKey('legalDocumentID');
    }
    set legalDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('legalDocumentID');
    }

    // Property: numberOfPeople
    set numberOfPeople(value:number) {
        this.setValueForKey(value, 'numberOfPeople');
    }
    get numberOfPeople():number {
        return this.valueForKey('numberOfPeople');
    }
    set numberOfPeoplePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'numberOfPeople');
    }
    get numberOfPeoplePrimitiveValue():number {
        return this.primitiveValueForKey('numberOfPeople');
    }

    // Property: sessionBeginDate
    set sessionBeginDate(value:Date) {
        this.setValueForKey(value, 'sessionBeginDate');
    }
    get sessionBeginDate():Date {
        return this.valueForKey('sessionBeginDate');
    }
    set sessionBeginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'sessionBeginDate');
    }
    get sessionBeginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('sessionBeginDate');
    }

    // Property: openDate
    set openDate(value:Date) {
        this.setValueForKey(value, 'openDate');
    }
    get openDate():Date {
        return this.valueForKey('openDate');
    }
    set openDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'openDate');
    }
    get openDatePrimitiveValue():Date {
        return this.primitiveValueForKey('openDate');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: orderType
    set orderType(value:number) {
        this.setValueForKey(value, 'orderType');
    }
    get orderType():number {
        return this.valueForKey('orderType');
    }
    set orderTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderType');
    }
    get orderTypePrimitiveValue():number {
        return this.primitiveValueForKey('orderType');
    }

    // Property: closed
    set closed(value:boolean) {
        this.setValueForKey(value, 'closed');
    }
    get closed():boolean {
        return this.valueForKey('closed');
    }
    set closedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'closed');
    }
    get closedPrimitiveValue():boolean {
        return this.primitiveValueForKey('closed');
    }

    // Property: clientID
    set clientID(value:string) {
        this.setValueForKey(value, 'clientID');
    }
    get clientID():string {
        return this.valueForKey('clientID');
    }
    set clientIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientID');
    }
    get clientIDPrimitiveValue():string {
        return this.primitiveValueForKey('clientID');
    }

    // Property: clientName
    set clientName(value:string) {
        this.setValueForKey(value, 'clientName');
    }
    get clientName():string {
        return this.valueForKey('clientName');
    }
    set clientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientName');
    }
    get clientNamePrimitiveValue():string {
        return this.primitiveValueForKey('clientName');
    }

    // Property: clientDocumentID
    set clientDocumentID(value:string) {
        this.setValueForKey(value, 'clientDocumentID');
    }
    get clientDocumentID():string {
        return this.valueForKey('clientDocumentID');
    }
    set clientDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientDocumentID');
    }
    get clientDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('clientDocumentID');
    }

    // Property: clientPhone
    set clientPhone(value:string) {
        this.setValueForKey(value, 'clientPhone');
    }
    get clientPhone():string {
        return this.valueForKey('clientPhone');
    }
    set clientPhonePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientPhone');
    }
    get clientPhonePrimitiveValue():string {
        return this.primitiveValueForKey('clientPhone');
    }

    // Property: clientAddress
    set clientAddress(value:string) {
        this.setValueForKey(value, 'clientAddress');
    }
    get clientAddress():string {
        return this.valueForKey('clientAddress');
    }
    set clientAddressPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientAddress');
    }
    get clientAddressPrimitiveValue():string {
        return this.primitiveValueForKey('clientAddress');
    }

    // Property: clientAddress2
    set clientAddress2(value:string) {
        this.setValueForKey(value, 'clientAddress2');
    }
    get clientAddress2():string {
        return this.valueForKey('clientAddress2');
    }
    set clientAddress2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientAddress2');
    }
    get clientAddress2PrimitiveValue():string {
        return this.primitiveValueForKey('clientAddress2');
    }

    // Property: locationCategoryName
    set locationCategoryName(value:string) {
        this.setValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryName():string {
        return this.valueForKey('locationCategoryName');
    }
    set locationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryName');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }

    // Property: totalPriceBase
    set totalPriceBase(value:number) {
        this.setValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBase():number {
        return this.valueForKey('totalPriceBase');
    }
    set totalPriceBasePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBasePrimitiveValue():number {
        return this.primitiveValueForKey('totalPriceBase');
    }

    // Property: totalTaxes
    set totalTaxes(value:number) {
        this.setValueForKey(value, 'totalTaxes');
    }
    get totalTaxes():number {
        return this.valueForKey('totalTaxes');
    }
    set totalTaxesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTaxes');
    }
    get totalTaxesPrimitiveValue():number {
        return this.primitiveValueForKey('totalTaxes');
    }

    // Property: totalPrice
    set totalPrice(value:number) {
        this.setValueForKey(value, 'totalPrice');
    }
    get totalPrice():number {
        return this.valueForKey('totalPrice');
    }
    set totalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPrice');
    }
    get totalPricePrimitiveValue():number {
        return this.primitiveValueForKey('totalPrice');
    }

    // Property: totalDiscount
    set totalDiscount(value:number) {
        this.setValueForKey(value, 'totalDiscount');
    }
    get totalDiscount():number {
        return this.valueForKey('totalDiscount');
    }
    set totalDiscountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDiscount');
    }
    get totalDiscountPrimitiveValue():number {
        return this.primitiveValueForKey('totalDiscount');
    }

    // Property: totalInvited
    set totalInvited(value:number) {
        this.setValueForKey(value, 'totalInvited');
    }
    get totalInvited():number {
        return this.valueForKey('totalInvited');
    }
    set totalInvitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalInvited');
    }
    get totalInvitedPrimitiveValue():number {
        return this.primitiveValueForKey('totalInvited');
    }

    // Property: totalDeleted
    set totalDeleted(value:number) {
        this.setValueForKey(value, 'totalDeleted');
    }
    get totalDeleted():number {
        return this.valueForKey('totalDeleted');
    }
    set totalDeletedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDeleted');
    }
    get totalDeletedPrimitiveValue():number {
        return this.primitiveValueForKey('totalDeleted');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }

    // Relationship: linesGroup
    protected _linesGroup:MIOManagedObjectSet = null;
    get linesGroup():MIOManagedObjectSet {
        return this.valueForKey('linesGroup');
    }
    addLinesGroupObject(value:ArchivedTicketLinesGroup) {
        this._addObjectForKey(value, 'linesGroup');
    }
    removeLinesGroupObject(value:ArchivedTicketLinesGroup) {
        this._removeObjectForKey(value, 'linesGroup');
    }
    // Relationship: nextDocument
    set nextDocument(value:ArchivedDocument) {
        this.setValueForKey(value, 'nextDocument');
    }
    get nextDocument():ArchivedDocument {
        return this.valueForKey('nextDocument') as ArchivedDocument;
    }
}
