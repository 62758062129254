class InventoryNoteCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;
    private dateLabel:MUILabel   = null;
    private nameLabel:MUILabel  = null;
    
    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.dateLabel   = MUIOutlet(this, "date-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, "destination-lbl", "MUILabel");
    } 
    
    set item(item:StockNote){        
        let df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
        
        this.titleLabel.text = item.externalReference;        
        this.dateLabel.text = df.stringFromDate(item.stockDate);
        this.nameLabel.text = item.destinationName ? item.destinationName : MIOLocalizeString("DEFAULT WAREHOUSE", "DEFAULT WAREHOUSE");
    }
}
