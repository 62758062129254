//
// Generated class SupplierInvoice
//

/// <reference path="SupplierInvoice_ManagedObject.ts" />

class SupplierInvoice extends SupplierInvoice_ManagedObject
{
    identifier      = null;
    isArchived      = null;
    num             = null;
    id_receipt      = null;
    closed          = null;
    receiptid       = null;
    receiptname     = null;
    receiptphone    = null;
    receiptaddress  = null;
    receiptaddress2 = null;
    receiptdocument = null;
    modifiedbyuser  = null;
    paymethodid     = null;
    paymethodname   = null;
    expirationdate  = null;
    tags            = null;
    date            = null;
    totalpricebase  = null;
    totaltaxes      = null;
    totalprice      = null;
    totaldiscount   = null;
    totalinvited    = null;
    totaldeleted    = null;
    nextdocument    = null;
    isincominginvoice = null;

    groups:MIOSet = null;
 
    //deliveryNoteLines = [];

    private df = MUIWebApplication.sharedInstance().delegate.serverDateFormatter;
  // validationDate = null;
    
     // Property: invoice  date    
    private _invoiceDateString:string = null;
    private _invoiceDate:Date = null;
    set invoiceDate(value:Date) {
        this.setValueForKey(value, '_invoiceDate');
        this._invoiceDateString = this.df.stringFromDate(value); 
    }
    get invoiceDate():Date {
        return this.valueForKey('_invoiceDate');
    } 

    get invoiceDateString():Date {
        return this.valueForKey('_invoiceDateString');
    } 
         // Property: invoice  date    
    private _invoiceExpirationDateString:string = null;
    private _invoiceExpirationDate:Date = null;
    set invoiceExpirationDate(value:Date) {
        this.setValueForKey(value, '_invoiceExpirationDate');
        this._invoiceExpirationDateString = this.df.stringFromDate(value); 
    }
    get invoiceExpirationDate():Date {
        return this.valueForKey('_invoiceExpirationDate');
    } 

    setObject(object, moc)
    {
        this.identifier      = object['id'];
        this.isArchived      = object['isarchived'];
        this.num             = object['documentid'];
        this.id_receipt      = object['idreceipt'];
        this.receiptid       = object['invoicelegalentityid'];
        this.receiptname     = object['invoicelegalentityname'];
        this.receiptphone    = object['invoicelegalentityphone'];
        this.receiptaddress  = object["invoicelegalentityaddress"];
        this.receiptaddress2 = object["invoicelegalentityaddress2"];
        this.receiptdocument = object["invoicelegalentitydocument"];
        this.modifiedbyuser  = object["modifiedbyuser"];
        this.closed          = object['closed'];
        this.tags            = object['tags'];
        this.invoiceDate     = this.df.dateFromString(object["date"]);
        this.invoiceExpirationDate  = this.df.dateFromString(object["expirationdate"]);
        this.paymethodid     = object['paymethodid'];
        this.paymethodname   = object['paymethodname'];
        this.totalpricebase  = object['totalpricebase'] || 0;
        this.totaltaxes      = object['totaltaxes'] || 0;
        this.totalprice      = object['totalprice'] || 0;
        this.totaldiscount   = object['totaldiscount'] || 0;
        this.totalinvited    = object['totalinvited'] || 0;
        this.totaldeleted    = object['totaldeleted'] || 0;
        this.isincominginvoice  = true;
        this.nextdocument    = object['nextdocument'];
        
        let key = this.isArchived ? 'archivedTicketLinesGroup' : 'documentGroup';
        
        this.groups = MIOSet.set();
        
        if(object[key]){
            var instance = this;
            var groups = object[key] || [];
            groups.forEach(function (group, index) {
                var p = MIOEntityDescription.insertNewObjectForEntityForName("SupplierInvoiceGroupLine", moc) as SupplierInvoiceGroupLine;
                group['invoice'] = instance;
                p.setObject(group, index, moc);
                instance.groups.addObject(p);
            }, instance);
        }
    }
    
    get total()
    {
        let total = 0;
        for(let i = 0; i < this.groups.length; i++){
            let group:SupplierInvoiceGroupLine = this.groups.objectAtIndex(i);
            total += group.price;
        }
    
        return total;
    }
    get status()
    {
        return this.closed == true ? 'Closed' : 'Pending';
    }
    get archived()
    {
        return this.isArchived == true ? 'Archived' : 'Open';
    }
    get totalWithTax()
    {
        let total = 0;
        for(let i = 0; i < this.groups.length; i++){
            let group:SupplierInvoiceGroupLine = this.groups.objectAtIndex(i);
            total += group.priceWithTax;
        }
    
        return total;
    }
    getObject()
    {
        var object = {};
        
        if(this.valueForKey('isincominginvoice') == true)
            object['documentid']  = this.valueForKey('num');

        object['receiptId']       = this.valueForKey('receiptid');
        object['receiptName']     = this.valueForKey('receiptname');
        object['receiptPhone']    = this.valueForKey('receiptphone');
        object["receiptAddress"]  = this.valueForKey('receiptaddress');
        object["receiptAddress2"] = this.valueForKey('receiptaddress2');
        object["receiptDocument"] = this.valueForKey('receiptdocument;') 
        object["modifiedByUser"]  = this.valueForKey('modifiedbyuser');
        object['closed']          = this.valueForKey('closed');
        object['tags']            = this.valueForKey('tags');
        object['totalpricebase']  = this.valueForKey('totalpricebase');
        object['totaltaxes']      = this.valueForKey('totaltaxes');
        object['totalprice']      = this.valueForKey('totalprice');
        object['totaldiscount']   = this.valueForKey('totaldiscount');
        object['totalinvited']    = this.valueForKey('totalinvited');
        object['totaldeleted']    = this.valueForKey('totaldeleted');
        object['date']            = this._invoiceDateString;
        object['paymethodid']     = this.valueForKey('paymethodid');
        object['paymethodname']   = this.valueForKey('paymethodname');
        object['expirationdate']  = this._invoiceExpirationDateString;
        object['nextdocument']    = this.valueForKey('nextdocument');
        object['isincominginvoice']    = true;

        let key = this.isArchived ? 'archivedTicketLinesGroup' : 'documentGroup';

        object[key] = [];

        for(let i = 0; i < this.groups.length; i++){
            var group = this.groups.objectAtIndex(i);
             object[key].push(group);
        }
        

        return object;
   }
}
