//
// Generated class CashdeskSessionBalance
//

/// <reference path="CashdeskSessionBalance_ManagedObject.ts" />

class CashdeskSessionBalance extends CashdeskSessionBalance_ManagedObject
{

}
