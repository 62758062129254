

// Generated class IntegratorSync_ManagedObject

class IntegratorSync_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: placeID
    set placeID(value:string) {
        this.setValueForKey(value, 'placeID');
    }
    get placeID():string {
        return this.valueForKey('placeID');
    }
    set placeIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'placeID');
    }
    get placeIDPrimitiveValue():string {
        return this.primitiveValueForKey('placeID');
    }

    // Property: integrator
    set integrator(value:string) {
        this.setValueForKey(value, 'integrator');
    }
    get integrator():string {
        return this.valueForKey('integrator');
    }
    set integratorPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integrator');
    }
    get integratorPrimitiveValue():string {
        return this.primitiveValueForKey('integrator');
    }

    // Property: enviroment
    set enviroment(value:string) {
        this.setValueForKey(value, 'enviroment');
    }
    get enviroment():string {
        return this.valueForKey('enviroment');
    }
    set enviromentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'enviroment');
    }
    get enviromentPrimitiveValue():string {
        return this.primitiveValueForKey('enviroment');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: error
    set error(value:string) {
        this.setValueForKey(value, 'error');
    }
    get error():string {
        return this.valueForKey('error');
    }
    set errorPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'error');
    }
    get errorPrimitiveValue():string {
        return this.primitiveValueForKey('error');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: referenceEntityID
    set referenceEntityID(value:string) {
        this.setValueForKey(value, 'referenceEntityID');
    }
    get referenceEntityID():string {
        return this.valueForKey('referenceEntityID');
    }
    set referenceEntityIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'referenceEntityID');
    }
    get referenceEntityIDPrimitiveValue():string {
        return this.primitiveValueForKey('referenceEntityID');
    }

    // Property: referenceEntityName
    set referenceEntityName(value:string) {
        this.setValueForKey(value, 'referenceEntityName');
    }
    get referenceEntityName():string {
        return this.valueForKey('referenceEntityName');
    }
    set referenceEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'referenceEntityName');
    }
    get referenceEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('referenceEntityName');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }
}
