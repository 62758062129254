//
// Generated class StockNote
//

/// <reference path="StockNote_ManagedObject.ts" />

enum StockNoteType
{
    InventoryNote,
    CustomInputNote,
    CustomOutputNote,
    InternalInputNote,
    InternalOutputNote,
    InternalOrder,
    InternalOrderResponse,
    MovementNote,
    DeliveryNote,
    SupplierNote,
    SupplierOrder,
    LowStockOrder,
    ProductionNote,
    DeliveryOrder,
    InternalReturnOrder,
    SupplierReturnNote
}

enum StockNoteStatus
{
    None,
    Pending,
    Processed
}

class StockNote extends StockNote_ManagedObject
{
    get statusString():string{
        switch(this.status){
            case StockNoteStatus.None:
            return MIOLocalizeString("NONE", "None");

            case StockNoteStatus.Pending:
            return MIOLocalizeString("PENDING", "Pending");

            case StockNoteStatus.Processed:
            return MIOLocalizeString("PROCESSED", "Processed");
        }

        return null;
    }
}
