//
// Generated class IntegratorsMappings
//

/// <reference path="IntegratorsMappings_ManagedObject.ts" />

class IntegratorsMappings extends IntegratorsMappings_ManagedObject
{

}
