

// Generated class DBScript_ManagedObject

class DBScript_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: type
    set type(value:string) {
        this.setValueForKey(value, 'type');
    }
    get type():string {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():string {
        return this.primitiveValueForKey('type');
    }

    // Property: builtinFunction
    set builtinFunction(value:string) {
        this.setValueForKey(value, 'builtinFunction');
    }
    get builtinFunction():string {
        return this.valueForKey('builtinFunction');
    }
    set builtinFunctionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'builtinFunction');
    }
    get builtinFunctionPrimitiveValue():string {
        return this.primitiveValueForKey('builtinFunction');
    }

    // Property: query
    set query(value:string) {
        this.setValueForKey(value, 'query');
    }
    get query():string {
        return this.valueForKey('query');
    }
    set queryPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'query');
    }
    get queryPrimitiveValue():string {
        return this.primitiveValueForKey('query');
    }

    // Property: code
    set code(value:string) {
        this.setValueForKey(value, 'code');
    }
    get code():string {
        return this.valueForKey('code');
    }
    set codePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'code');
    }
    get codePrimitiveValue():string {
        return this.primitiveValueForKey('code');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }

    // Relationship: filters
    protected _filters:MIOManagedObjectSet = null;
    get filters():MIOManagedObjectSet {
        return this.valueForKey('filters');
    }
    addFiltersObject(value:DBFilter) {
        this._addObjectForKey(value, 'filters');
    }
    removeFiltersObject(value:DBFilter) {
        this._removeObjectForKey(value, 'filters');
    }
}
