

/// <reference path="DBSyncEntity.ts" />

// Generated class IntegratorSAPStockNoteLine_ManagedObject

class IntegratorSAPStockNoteLine_ManagedObject extends DBSyncEntity
{

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
}
