

// Generated class EconomicEntity_ManagedObject

class EconomicEntity_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: type
    set type(value:string) {
        this.setValueForKey(value, 'type');
    }
    get type():string {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():string {
        return this.primitiveValueForKey('type');
    }

    // Property: alias
    set alias(value:string) {
        this.setValueForKey(value, 'alias');
    }
    get alias():string {
        return this.valueForKey('alias');
    }
    set aliasPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'alias');
    }
    get aliasPrimitiveValue():string {
        return this.primitiveValueForKey('alias');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: document
    set document(value:string) {
        this.setValueForKey(value, 'document');
    }
    get document():string {
        return this.valueForKey('document');
    }
    set documentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'document');
    }
    get documentPrimitiveValue():string {
        return this.primitiveValueForKey('document');
    }

    // Property: email
    set email(value:string) {
        this.setValueForKey(value, 'email');
    }
    get email():string {
        return this.valueForKey('email');
    }
    set emailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'email');
    }
    get emailPrimitiveValue():string {
        return this.primitiveValueForKey('email');
    }

    // Property: image
    set image(value:string) {
        this.setValueForKey(value, 'image');
    }
    get image():string {
        return this.valueForKey('image');
    }
    set imagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'image');
    }
    get imagePrimitiveValue():string {
        return this.primitiveValueForKey('image');
    }

    // Property: automaticDebts
    set automaticDebts(value:string) {
        this.setValueForKey(value, 'automaticDebts');
    }
    get automaticDebts():string {
        return this.valueForKey('automaticDebts');
    }
    set automaticDebtsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'automaticDebts');
    }
    get automaticDebtsPrimitiveValue():string {
        return this.primitiveValueForKey('automaticDebts');
    }

    // Property: automaticDebtsMode
    set automaticDebtsMode(value:string) {
        this.setValueForKey(value, 'automaticDebtsMode');
    }
    get automaticDebtsMode():string {
        return this.valueForKey('automaticDebtsMode');
    }
    set automaticDebtsModePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'automaticDebtsMode');
    }
    get automaticDebtsModePrimitiveValue():string {
        return this.primitiveValueForKey('automaticDebtsMode');
    }

    // Property: debtsLimit
    set debtsLimit(value:string) {
        this.setValueForKey(value, 'debtsLimit');
    }
    get debtsLimit():string {
        return this.valueForKey('debtsLimit');
    }
    set debtsLimitPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'debtsLimit');
    }
    get debtsLimitPrimitiveValue():string {
        return this.primitiveValueForKey('debtsLimit');
    }

    // Property: discount
    set discount(value:string) {
        this.setValueForKey(value, 'discount');
    }
    get discount():string {
        return this.valueForKey('discount');
    }
    set discountPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'discount');
    }
    get discountPrimitiveValue():string {
        return this.primitiveValueForKey('discount');
    }

    // Property: rateID
    set rateID(value:string) {
        this.setValueForKey(value, 'rateID');
    }
    get rateID():string {
        return this.valueForKey('rateID');
    }
    set rateIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'rateID');
    }
    get rateIDPrimitiveValue():string {
        return this.primitiveValueForKey('rateID');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: barcode
    set barcode(value:string) {
        this.setValueForKey(value, 'barcode');
    }
    get barcode():string {
        return this.valueForKey('barcode');
    }
    set barcodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'barcode');
    }
    get barcodePrimitiveValue():string {
        return this.primitiveValueForKey('barcode');
    }

    // Property: barcode2
    set barcode2(value:string) {
        this.setValueForKey(value, 'barcode2');
    }
    get barcode2():string {
        return this.valueForKey('barcode2');
    }
    set barcode2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'barcode2');
    }
    get barcode2PrimitiveValue():string {
        return this.primitiveValueForKey('barcode2');
    }

    // Property: invitationConceptID
    set invitationConceptID(value:string) {
        this.setValueForKey(value, 'invitationConceptID');
    }
    get invitationConceptID():string {
        return this.valueForKey('invitationConceptID');
    }
    set invitationConceptIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invitationConceptID');
    }
    get invitationConceptIDPrimitiveValue():string {
        return this.primitiveValueForKey('invitationConceptID');
    }

    // Property: invitationResponsibleID
    set invitationResponsibleID(value:string) {
        this.setValueForKey(value, 'invitationResponsibleID');
    }
    get invitationResponsibleID():string {
        return this.valueForKey('invitationResponsibleID');
    }
    set invitationResponsibleIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invitationResponsibleID');
    }
    get invitationResponsibleIDPrimitiveValue():string {
        return this.primitiveValueForKey('invitationResponsibleID');
    }

    // Property: currentInvitations
    set currentInvitations(value:string) {
        this.setValueForKey(value, 'currentInvitations');
    }
    get currentInvitations():string {
        return this.valueForKey('currentInvitations');
    }
    set currentInvitationsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'currentInvitations');
    }
    get currentInvitationsPrimitiveValue():string {
        return this.primitiveValueForKey('currentInvitations');
    }

    // Property: invitationLimitInSession
    set invitationLimitInSession(value:string) {
        this.setValueForKey(value, 'invitationLimitInSession');
    }
    get invitationLimitInSession():string {
        return this.valueForKey('invitationLimitInSession');
    }
    set invitationLimitInSessionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invitationLimitInSession');
    }
    get invitationLimitInSessionPrimitiveValue():string {
        return this.primitiveValueForKey('invitationLimitInSession');
    }

    // Property: cardType
    set cardType(value:string) {
        this.setValueForKey(value, 'cardType');
    }
    get cardType():string {
        return this.valueForKey('cardType');
    }
    set cardTypePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'cardType');
    }
    get cardTypePrimitiveValue():string {
        return this.primitiveValueForKey('cardType');
    }

    // Property: userID
    set userID(value:string) {
        this.setValueForKey(value, 'userID');
    }
    get userID():string {
        return this.valueForKey('userID');
    }
    set userIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'userID');
    }
    get userIDPrimitiveValue():string {
        return this.primitiveValueForKey('userID');
    }

    // Property: externalPlaceID
    set externalPlaceID(value:string) {
        this.setValueForKey(value, 'externalPlaceID');
    }
    get externalPlaceID():string {
        return this.valueForKey('externalPlaceID');
    }
    set externalPlaceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalPlaceID');
    }
    get externalPlaceIDPrimitiveValue():string {
        return this.primitiveValueForKey('externalPlaceID');
    }

    // Property: externalCompanyID
    set externalCompanyID(value:string) {
        this.setValueForKey(value, 'externalCompanyID');
    }
    get externalCompanyID():string {
        return this.valueForKey('externalCompanyID');
    }
    set externalCompanyIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalCompanyID');
    }
    get externalCompanyIDPrimitiveValue():string {
        return this.primitiveValueForKey('externalCompanyID');
    }

    // Property: externalGroupID
    set externalGroupID(value:string) {
        this.setValueForKey(value, 'externalGroupID');
    }
    get externalGroupID():string {
        return this.valueForKey('externalGroupID');
    }
    set externalGroupIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalGroupID');
    }
    get externalGroupIDPrimitiveValue():string {
        return this.primitiveValueForKey('externalGroupID');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }

    // Relationship: offerRules
    protected _offerRules:MIOManagedObjectSet = null;
    get offerRules():MIOManagedObjectSet {
        return this.valueForKey('offerRules');
    }
    addOfferRulesObject(value:OfferRule) {
        this._addObjectForKey(value, 'offerRules');
    }
    removeOfferRulesObject(value:OfferRule) {
        this._removeObjectForKey(value, 'offerRules');
    }

    // Relationship: economicAccountLines
    protected _economicAccountLines:MIOManagedObjectSet = null;
    get economicAccountLines():MIOManagedObjectSet {
        return this.valueForKey('economicAccountLines');
    }
    addEconomicAccountLinesObject(value:EconomicAccountLine) {
        this._addObjectForKey(value, 'economicAccountLines');
    }
    removeEconomicAccountLinesObject(value:EconomicAccountLine) {
        this._removeObjectForKey(value, 'economicAccountLines');
    }

    // Relationship: loyaltyAccountLines
    protected _loyaltyAccountLines:MIOManagedObjectSet = null;
    get loyaltyAccountLines():MIOManagedObjectSet {
        return this.valueForKey('loyaltyAccountLines');
    }
    addLoyaltyAccountLinesObject(value:LoyaltyAccountLine) {
        this._addObjectForKey(value, 'loyaltyAccountLines');
    }
    removeLoyaltyAccountLinesObject(value:LoyaltyAccountLine) {
        this._removeObjectForKey(value, 'loyaltyAccountLines');
    }
}
