

class CashDeskSummaryViewController extends MUIViewController
{
    private chartView:CashDeskSummaryChartView = null;
    private tableView:MUITableView = null;        
    private activityIndicator:MUIActivityIndicatorView = null;
    
    private dateTimeFrom = null;
    private dateTimeTo = null;
    private mode = null; 
    
    private sdf = MUIWebApplication.sharedInstance().delegate.serverDateFormatter;
    private sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;
    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;

    viewDidLoad(){
        super.viewDidLoad();                

        this.chartView = MUIOutlet(this, "chart-view", "CashDeskSummaryChartView");        

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;   
        
        this.activityIndicator = MUIOutlet(this, 'activity-indicator', "MUIActivityIndicatorView");
        this.activityIndicator.setHidden(true);        
        
        this.updateUI();
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private cashDeskEntity:CashDeskEntity = null;
    public setItem(cashDeskEntity, dateTimeFrom, dateTimeTo, mode) {
        this.cashDeskEntity = cashDeskEntity;
        this.dateTimeFrom = dateTimeFrom;
        this.dateTimeTo = dateTimeTo;
        this.mode = mode;
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this.cashDeskEntity == null) return;

        this.chartView.setDataSet(this.cashDeskEntity, this.dateTimeFrom, this.dateTimeTo, this.mode);
        this.getCashDeskSummary();                
    }

    private getCashDeskSummary() {
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws:WebService = ad.webService;
        this.activityIndicator.setHidden(false);
        let dateFrom = this.sdtf.stringFromDate(this.dateTimeFrom);
        let dateTo = this.sdtf.stringFromDate(this.dateTimeTo);
        ws.getCashDeskSummary(ad.selectedPlace, this.cashDeskEntity, dateFrom, dateTo, this, function(code, json){
            this.fetchedResultsController = null;
            this.tableView.reloadData();
            this.activityIndicator.setHidden(true); 
        });
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.count;
    }
    
    numberOfRowsInSection(tableview, section){
        let si = this.fetchedResultsController.sections[section];
        return si.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
                
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as CashDeskSummaryItem;        

        if (item.isHeader == true){
            let header = null;
            if (item.headerType == 1){
                header = tableview.dequeueReusableCellWithIdentifier("HeaderDoubleCell") as CashDeskSummaryHeaderCell;
            }
            else if (item.headerType == 2){
                header = tableview.dequeueReusableCellWithIdentifier("HeaderTripleCell") as CashDeskSummaryHeaderCell;                         
            }            
            else if (item.headerType == 3){
                header = tableview.dequeueReusableCellWithIdentifier("HeaderFourthCell") as CashDeskSummaryHeaderCell;                         
            }
            else if (item.headerType == 4){
                header = tableview.dequeueReusableCellWithIdentifier("HeaderFifthCell") as CashDeskSummaryHeaderCell;                         
            }            
            else if (item.headerType == 5){
                header = tableview.dequeueReusableCellWithIdentifier("HeaderSixthCell") as CashDeskSummaryHeaderCell;                         
            }    
            else if (item.headerType == 6){
                header = tableview.dequeueReusableCellWithIdentifier("HeaderSeventhCell") as CashDeskSummaryHeaderCell;                         
            }  
            else if (item.headerType == 7){
                header = tableview.dequeueReusableCellWithIdentifier("HeaderEighthCell") as CashDeskSummaryHeaderCell;                         
            }                   
            else {
                header = tableview.dequeueReusableCellWithIdentifier("HeaderSingleCell") as CashDeskSummaryHeaderCell;
            }
            header.item = item;                
            return header;
        }
        
        let cell = null;
        if (item.cellType == 1){
            cell = tableview.dequeueReusableCellWithIdentifier("DoubleCell") as CashDeskSummaryCell;
        }
        else if (item.cellType == 2){
            cell = tableview.dequeueReusableCellWithIdentifier("TripleCell") as CashDeskSummaryCell;
        }
        else if (item.cellType == 3){
            cell = tableview.dequeueReusableCellWithIdentifier("FourthCell") as CashDeskSummaryCell;
        }
        else if (item.cellType == 4){
            cell = tableview.dequeueReusableCellWithIdentifier("FifthCell") as CashDeskSummaryCell;
        }
        else if (item.cellType == 5){
            cell = tableview.dequeueReusableCellWithIdentifier("SixthCell") as CashDeskSummaryCell;
        }
        else if (item.cellType == 6){
            cell = tableview.dequeueReusableCellWithIdentifier("SeventhCell") as CashDeskSummaryCell;
        }
        else if (item.cellType == 7){
            cell = tableview.dequeueReusableCellWithIdentifier("EighthCell") as CashDeskSummaryCell;
        }
        else{
            cell = tableview.dequeueReusableCellWithIdentifier("SingleCell") as CashDeskSummaryCell;
        }
        cell.item = item;

        return cell;
    }
            
    private _fetchedResultsController:MIOFetchedResultsController = null;    
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('CashDeskSummaryItem');
            
        fetchRequest.predicate = MIOPredicate.predicateWithFormat('cashDeskSession == null AND section > "0" AND section < "6"');
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderindex', true)];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }
}