
/// <reference path = "../model/ProductListItem.ts" />

class DBHelper extends MIOObject {

    private static _sharedInstance: DBHelper = null;
    static sharedInstance(): DBHelper {

        if (this._sharedInstance == null) {
            this._sharedInstance = new DBHelper();
            this._sharedInstance.init();
        }

        return this._sharedInstance;
    }

    constructor() {
        super();
        if (DBHelper._sharedInstance) {
            throw new Error("DBHelper: Instantiation failed: Use sharedInstance() instead of new.");
        }
    }

    static get mainManagedObjectContext():MIOManagedObjectContext{
        return MUIWebApplication.sharedInstance().delegate.managedObjectContext;
    }

    static saveMainContext(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.managedObjectContext.save();
    }

    static saveMainContextWithCompletion(target, completion){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webPersistentStore.performBlockWithCompletion(target, function(){
            ad.managedObjectContext.save();
        }, completion);
    }

    static deleteObjectFromMainContext(object:MIOManagedObject, save:boolean){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.managedObjectContext.deleteObject(object);
        if (save == true) ad.managedObjectContext.save();
    }

    static listFetchRequestWithEntityName(entityName, sortsDescriptors, predicateFormat, fetchDeleted?: boolean, limit?:number) {

        let request = MIOFetchRequest.fetchRequestWithEntityName(entityName);
        request.sortDescriptors = sortsDescriptors;
        if(limit != null) request.fetchLimit = limit;

        if (fetchDeleted == true) {
            request.predicate = (predicateFormat != null && predicateFormat.length > 0) ? MIOPredicate.predicateWithFormat(predicateFormat) : null;
        }
        else {
            request.predicate = (predicateFormat == null) ? 
                MIOPredicate.predicateWithFormat("deletedAt == null"):
                MIOPredicate.predicateWithFormat("deletedAt == null AND (" + predicateFormat + ")");
        }

        return request;
    }

    static queryObjects(context: MIOManagedObjectContext, entityName: string, predicate?: MIOPredicate, includeEntities?) {

        let request = MIOFetchRequest.fetchRequestWithEntityName(entityName);

        if (predicate != null)
            request.predicate = predicate;

        if (includeEntities != null)
            request.relationshipKeyPathsForPrefetching = includeEntities;

        let moc:MIOManagedObjectContext = context != null ? context : MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        let objs = moc.executeFetch(request);
        return objs;
    }

    static queryObject(context: MIOManagedObjectContext, entityName: string, predicate?: MIOPredicate, includeEntities?) {

        let objs = DBHelper.queryObjects(context, entityName, predicate, includeEntities);
        return objs.length > 0 ? objs[0] : null;
    }

    static queryObjectsFromMainContext(entityName: string, sortDescriptors?:any, predicate?: MIOPredicate, includeEntities?:any) {
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        return DBHelper.queryObjects(ad.managedObjectContext, entityName, predicate, includeEntities);
    }

    static queryObjectFromMainContext(entityName: string, predicate?: MIOPredicate, includeEntities?) {

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        return DBHelper.queryObject(ad.managedObjectContext, entityName, predicate, includeEntities);
    }

    static queryObjectsWithCompletion(entityName: string, sortDescriptors:any, predicate: MIOPredicate, includeEntities, target, completion) {
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let wps:MWSPersistentStore = ad.webPersistentStore;
        let moc:MIOManagedObjectContext = ad.managedObjectContext;
        
        let request = MIOFetchRequest.fetchRequestWithEntityName(entityName);
        request.sortDescriptors = sortDescriptors;
        request.predicate = predicate;
        request.relationshipKeyPathsForPrefetching = includeEntities;

        wps.fetchObjects(request, moc, target, completion);
    }

    static queryObjectWithCompletion(entityName: string, sortDescriptors:any, predicate: MIOPredicate, includeEntities, target, completion) {
        DBHelper.queryObjectsWithCompletion(entityName, sortDescriptors, predicate, includeEntities, this, function(objects){
            if (objects.length > 0) completion.call(target, objects[0])
            else completion.call(target, null)
        });        
    }

    static objectFromObjectID(objectID:MIOManagedObjectID, target, completion) {        
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let wps:MWSPersistentStore = ad.webPersistentStore;
        let moc:MIOManagedObjectContext = ad.managedObjectContext;

        let obj = moc.existingObjectWithID(objectID);
        if (obj == null) {
            // Fetch
        }
        else {
            completion.call(target, obj);
        }        
    }
    
    private managedObjectContext = null;
    private fetchesByEntity = {};
    private observersByEntity = {};

    init() {
        super.init();
        let ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
        this.managedObjectContext = ad.managedObjectContext;
    }

    isObservingForChangesInEntity(observer, entityName: string):boolean{
        let array = this.observersByEntity[entityName];

        for (let index = 0; array != null && index < array.length; index++) {
            let i = array[index];
            let obs = i["OBS"];

            if (obs === observer) {
                return true;
            }
        }
        return false;
    }
    
    addObserverForEntity(observer, entityName: string, predicate: MIOPredicate, sortDescriptors, relationShips, completion) {

        let item = {};
        item["OBS"] = observer;
        item["Block"] = completion;
        item["Predicate"] = predicate;
        item["Sorts"] = sortDescriptors;

        let array = this.observersByEntity[entityName];
        if (array == null) {
            array = [];
            this.observersByEntity[entityName] = array;
            this.addFetchedWithEntity(entityName, relationShips);
        }

        // Check not add the same observer for the same entity
        for (let index = 0; index < array.length; index++) {
            let i = array[index];
            let obs = i["OBS"];

            if (obs === observer) {
                throw new Error("AppHelper: Trying to add the same observer for the same entity!");
            }
        }

        array.push(item);

        // Check for relationships
        let resetFecth = false;
        if (relationShips != null) {
            let rels = this.relationShipsByEntity[entityName];
            for(let index = 0; index < relationShips.length; index++){
                let r = relationShips[index];
                if (rels.containsObject(r) == false) {
                    rels.addObject(r);
                    resetFecth = true;
                }
            }

            if (resetFecth) {
                this.executeFetchedWithEntity(entityName);
            }
        }

        this.notifyObserverItem(item, entityName);
    }

    removeObserverForChangesInEntity(observer, entityName: string) {

        let array = this.observersByEntity[entityName];
        if (array == null) return;

        let indexFound = -1;
        for (let index = 0; index < array.length; index++) {
            let i = array[index];
            let obs = i["OBS"];

            if (obs === observer) {
                indexFound = index;
                break;
            }
        }

        if (indexFound > -1) {
            array.splice(indexFound, 1);
        }
    }

    objectsForEntity(observer, entityName: string) {
        
        let item = this.itemForObserverAndEntity(observer, entityName);
        let objs = item["Objects"];
        if (objs == null) {
            let fecth = this.fetchesByEntity[entityName];        
            objs = fecth.resultObjects;
    
            let predicate = item["Predicate"];
            let sorts = item["Sorts"];
        
            objs = _MIOPredicateFilterObjects(objs, predicate);
            objs = _MIOSortDescriptorSortObjects(objs, sorts);    

            item["Objects"] = objs;
        }
        
        return objs;
    }

    objectAtIndexForEntity(index, entityName:string){

        let fecth = this.fetchesByEntity[entityName];
        if (fetch == null) return null;
        
        let objs = fecth.resultObjects;

        if (objs == null) return null;
        if (objs.length == 0) return null;

        return objs[index];
    }

    objectAtIndexForEntityObserver(observer, index, entityName){
        
        let objs = this.objectsForEntity(observer, entityName);

        if (objs == null) return null;
        if (objs.length == 0) return null;

        return objs[index];
    }
    
    indexOfObjectForEntity(observer, entityName, object){
        let objects = this.objectsForEntity(observer, entityName);
        let index = objects.indexOf(object);
        
        if(index < 0) throw new Error('Object not found');
        return index;
    }

    setObserverPredicateForEntity(observer, entityName: string, predicate: MIOPredicate) {

        let item = this.itemForObserverAndEntity(observer, entityName);
        item["Predicate"] = predicate;
        delete item["Objects"]; // To reset the predicate
        
        this.notifyObserverItem(item, entityName);
    }

    private itemForObserverAndEntity(observer, entityName: string) {

        let array = this.observersByEntity[entityName];
        if (array == null) return null;

        for (let index = 0; index < array.length; index++) {
            let i = array[index];
            let obs = i["OBS"];

            if (obs === observer) {
                return i;
            }
        }

        return null;
    }

    private fetchRequestByEntity(entityName: string){
        let request = DBHelper.listFetchRequestWithEntityName(entityName, null, null, false);        
        request.relationshipKeyPathsForPrefetching = this.relationShipsByEntity[entityName];   
        return request;
    }

    private relationShipsByEntity = {};
    private addFetchedWithEntity(entityName: string, relationShips) {
        
        if (relationShips == null) this.relationShipsByEntity[entityName] = [];
        else this.relationShipsByEntity[entityName] = relationShips;                    

        let request = this.fetchRequestByEntity(entityName);

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(request, this.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        this.fetchesByEntity[entityName] = fetchedResultsController;

        this.executeFetchedWithEntity(entityName);
        
    }

    private executeFetchedWithEntity(entityName:string){

        let request = this.fetchRequestByEntity(entityName);

        let fetchedResultsController = this.fetchesByEntity[entityName] as MIOFetchedResultsController;
        fetchedResultsController.fetchRequest = request;
        
        fetchedResultsController.performFetch();
    }

    private notifyObserverItem(item, entityName:string){
        let observer = item["OBS"];
        let completion = item["Block"];
        let objects = this.objectsForEntity(observer, entityName);
        completion.call(observer, objects);
    }

    controllerDidChangeContent(controller: MIOFetchedResultsController) {

        let entityName = controller.fetchRequest.entityName;

        let observers = this.observersByEntity[entityName];
        for (let index = 0; index < observers.length; index++) {
            let item = observers[index];
            delete item["Objects"]; // To reset the predicate

            this.notifyObserverItem(item, entityName);
        }
    }
    
    static queryBusiness(target, completion){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.loginUserInfo(this, function(code, json){
            let business = json["data"];
            let userID = json["id"];
            if (target != null && completion != null)
                completion.call(target, business, userID);
        });                
    }

    static createPaymentEntity(name:string, type:PaymentEntityType, save?:boolean):PaymentEntity {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext as MIOManagedObjectContext;
        let object = MIOEntityDescription.insertNewObjectForEntityForName("PaymentEntity", moc) as PaymentEntity;

        object.name = name;
        object.paymentType = type;

        if (save == true) moc.save();

        return object;
    }

    static createCategory(category) {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let categoryListItem: ProductListItem = MIOEntityDescription.insertNewObjectForEntityForName("ProductListItem", moc) as ProductListItem;
        categoryListItem.identifier = MIOUUID.UUID().UUIDString;
        categoryListItem.type = ProductListItemType.Category;
        categoryListItem.orderIndex = 1;
        categoryListItem.itemID = category.identifier;
        categoryListItem.name = category.name;
        categoryListItem.parentCategoryID = category.parentCategory;
    }

    static createStockCategory(name:string, parentCategory:StockCategory) {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        let category = MIOEntityDescription.insertNewObjectForEntityForName("StockCategory", moc) as StockCategory;
        category.identifier = MIOUUID.UUID().UUIDString;
        category.name = name;
        category.parentCategory = parentCategory;
        
        let categoryListItem: ProductListItem = MIOEntityDescription.insertNewObjectForEntityForName("ProductListItem", moc) as ProductListItem;
        categoryListItem.identifier = MIOUUID.UUID().UUIDString;
        categoryListItem.type = ProductListItemType.StockCategory;
        categoryListItem.orderIndex = 1;
        categoryListItem.itemID = category.identifier;
        categoryListItem.name = category.name;        
        categoryListItem.parentStockCategoryID = category.parentCategory ? category.parentCategory.identifier : null;
    }

    //Create EconomicAccountLine used for Client Acount page
    createEconomicLine(value, paymethod: PayMethod, client:Client, type?:AccountLineType, bookingID?) {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        let economicLine = MIOEntityDescription.insertNewObjectForEntityForName("EconomicAccountLine", moc) as EconomicAccountLine;
        economicLine.identifier = MIOUUID.UUID().UUIDString;
        economicLine.economicEntity = client; // Economic entity
        economicLine.concept = (value < 0 ? 'Remove ' : 'Add ') + ' funds to ' + client.name;
        economicLine.value = value;
        economicLine.payMethod = paymethod;
        if (paymethod != null) economicLine.payMethodName = paymethod.name;
        economicLine.date = new Date();        
        if (type != null) economicLine.type = type;
        if (bookingID != null) economicLine.bookingID = bookingID;
        client.addEconomicAccountLinesObject(economicLine);
        //client.totalAccount += value;
    
        moc.save();
        return economicLine;
    }

    //Create LoyaltyAccount Line used for Loyalty page (need to revise)
    createLoyaltyLine(value, client:Client, type?:AccountLineType, bookingID?) {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let paymethod = null;

        let loyaltyLine = MIOEntityDescription.insertNewObjectForEntityForName("LoyaltyAccountLine", moc) as LoyaltyAccountLine;
        loyaltyLine.identifier = MIOUUID.UUID().UUIDString;
        loyaltyLine.economicEntity = client; //Economic entity
        loyaltyLine.concept = (value < 0 ? 'Remove ' : 'Add ') + ' funds to ' + client.name;
        loyaltyLine.value = value;
        // loyaltyLine.payMethod = paymethod;
        // if (paymethod != null) loyaltyLine.payMethodName = paymethod.name;
        loyaltyLine.date = new Date();        
        // if (type != null) loyaltyLine.type = type;
        // if (bookingID != null) loyaltyLine.bookingID = bookingID;
        client.addLoyaltyAccountLinesObject(loyaltyLine);
        //client.totalLoyalty += value;
    
        moc.save();
        return loyaltyLine;
    }

    static checkClient(name:string, target, completion){
        DBHelper.queryObjectsWithCompletion("Client", null, MIOPredicate.predicateWithFormat("name == '" + name + "'"), [], this, function(objects){
            if (objects.length > 0) completion.call(target, false);
            else completion.call(target, true);
        });
    }

    static createClient(name?:string){
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let client = MIOEntityDescription.insertNewObjectForEntityForName("Client", moc) as Client;        
        client.address = MIOEntityDescription.insertNewObjectForEntityForName("Address", moc) as Address;
        client.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber;
        client.mobilePhone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber;
        client.type = 'C';

        if (name != null) client.name = name;


        return client;
    }

    static createSupplier(name:string):Supplier {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let supplier = MIOEntityDescription.insertNewObjectForEntityForName("Supplier", moc) as Supplier;        
        supplier.identifier = MIOUUID.UUID().UUIDString;
        supplier.type = 'S';

        supplier.name = name;

        return supplier;
    }

    static createEmployee(name:string):Employee {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let employee = MIOEntityDescription.insertNewObjectForEntityForName("Employee", moc) as Employee;        
        employee.identifier = MIOUUID.UUID().UUIDString;
        employee.type = 'W';

        employee.name = name;

        return employee;
    }


    addTipFromCashDeskLine(line:CashDeskLine, tip){
        this.createCashDeskLineTipFromLine(line, tip);
    }

    rectifyTipFromCashDeskLine(line:CashDeskLine, tip){
        let newTip = tip - line.money;
        this.createCashDeskLineTipFromLine(line, newTip);
    }

    createCashDeskLineTipFromLine(line:CashDeskLine, tip){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let newLine = MIOEntityDescription.insertNewObjectForEntityForName("CashDeskLine", ad.managedObjectContext) as CashDeskLine;
                
        newLine.idApp = line.idApp;
        newLine.legalDocumentID = line.legalDocumentID;

        newLine.bookingID = line.bookingID;
        newLine.byUser = 1;
        newLine.operationID = line.operationID;
        newLine.checkPoint = line.checkPoint;
        newLine.comments = null;
        newLine.concept = MIOLocalizeString("TIP", "tip");
        newLine.currency = null;
        newLine.customConcept = null;
        newLine.date = line.date;
        newLine.debtLine = null;
        newLine.documentID = line.documentID;
        newLine.eventID = line.eventID;
        newLine.eventName = line.eventName;
        //newLine.externalTransactionID   
        newLine.info = null;
        newLine.invitationResponsible = null;
        newLine.invitationResponsibleName = null;
        newLine.invitationResponsibleType = 0;
        newLine.legalDocumentID = null;
        newLine.locationCategoryName = line.locationCategoryName;
        newLine.locationName = line.locationName;
        newLine.money = tip;
        newLine.moneyOtherCurrency = null;
        newLine.payMethod = line.payMethod;
        newLine.payMethodName = line.payMethodName;
        newLine.payMethodSubtype = line.payMethodSubtype;
        newLine.paymentType = 1;
        //subCashDesk = nil;
        //tags = nil;
        //tax = nil;
        //taxID = nil;
        //taxName = nil;
        newLine.type = 10;
        newLine.worker = line.worker;
        newLine.workerName = line.workerName;

        DBHelper.saveMainContext();
    }

    //temporary method for TicketView, should be implemented in API
    static createCashDeskLinePAXFromLine(line:CashDeskLine, pax){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let newLine:CashDeskLine = MIOEntityDescription.insertNewObjectForEntityForName("CashDeskLine", ad.managedObjectContext) as CashDeskLine;
                
        newLine.identifier = MIOUUID.UUID().UUIDString;
        newLine.idApp = line.idApp;
        newLine.legalDocumentID = line.legalDocumentID;

        newLine.money = pax;
        newLine.type = 19;
        newLine.concept = MIOLocalizeString("PAX", "PAX");

        newLine.idPlace = line.idPlace;
        newLine.bookingID = line.bookingID;
        newLine.byUser = 0;
        newLine.operationID = line.operationID;
        newLine.checkPoint = line.checkPoint;
        newLine.comments = null;
        newLine.currency = null;
        newLine.customConcept = null;
        newLine.date = line.date;
        newLine.debtLine = null;
        newLine.documentID = line.documentID;
        newLine.eventID = line.eventID;
        newLine.eventName = line.eventName;
        //newLine.externalTransactionID   
        newLine.info = null;
        newLine.invitationResponsible = null;
        newLine.invitationResponsibleName = null;
        newLine.invitationResponsibleType = 0;
        newLine.legalDocumentID = null;
        newLine.locationCategoryName = null;
        newLine.locationName = null;
        newLine.moneyOtherCurrency = null;
        newLine.payMethod = null;
        newLine.payMethodName = null;
        newLine.payMethodSubtype = null;
        newLine.paymentType = 0;
        //subCashDesk = nil;
        //tags = nil;
        //tax = nil;
        //taxID = nil;
        //taxName = nil;
        newLine.worker = null;
        newLine.workerName = null;

        ad.managedObjectContext.save();
        DBHelper.saveMainContext();
        return newLine;
    }

    static createBookingFromBooking(booking:Booking):Booking { 
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let b = MIOEntityDescription.insertNewObjectForEntityForName("Booking", ad.managedObjectContext) as Booking;
        
        b.client = booking.client;
        b.clientName = booking.clientName;
        b.clientPhone = booking.clientPhone;
        b.pax = booking.pax;
        b.clientEmail = booking.clientEmail;
        b.bookingSource = booking.bookingSource;
        b.channelID = booking.channelID;
        b.recommendationID = booking.recommendationID;

        b.status = BookingStatus.BookingRequest;
        b.clientComments = booking.clientComments;
        
        return b;
    }

    static createBookingSource(name:string, phone:string, email:string):BookingSource {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let source = MIOEntityDescription.insertNewObjectForEntityForName("BookingSource", ad.managedObjectContext) as BookingSource;

        source.name = name;
        source.phone = phone;
        source.email = email;

        return source;
    }
    
    //
    // Calculations for products
    //

    static calculateTotalConsumption(product:Product, quantity:number, quantityMeasure:MeasureUnitType, loss:number, lossMeasure:MeasureUnitType, totalMeasure:MeasureUnitType){
        // Calculate total from the measure unit of total selection        
        let q = quantity;
        let l = loss;

        if (totalMeasure == MeasureUnitType.Container){
            if (quantityMeasure != MeasureUnitType.Container) {
                let factor = DBHelper.meassureFactor(quantityMeasure, product.measureUnitType);
                q = (quantity * factor) / product.quantity;            
            }

            if (lossMeasure == MeasureUnitType.Percentage) {
                l = (loss * q) / 100.0;
            }
            else if (lossMeasure != MeasureUnitType.Container) {
                let factor = DBHelper.meassureFactor(lossMeasure, product.measureUnitType);
                l = (loss * factor) / product.quantity;
            }
        }
        else {
            if (quantityMeasure == MeasureUnitType.Container) {
                let factor = DBHelper.meassureFactor(product.measureUnitType, totalMeasure);
                q = (quantity * factor) * product.quantity;
            }
            else {
                let factor = DBHelper.meassureFactor(quantityMeasure, totalMeasure);
                q = quantity * factor;
            }

            if (lossMeasure == MeasureUnitType.Percentage) {
                l = (loss * q) / 100.0;
            }
            else if (lossMeasure == MeasureUnitType.Container) {
                let factor = DBHelper.meassureFactor(product.measureUnitType, totalMeasure);
                l = (loss * factor) * product.quantity;
            }
            else {
                let factor = DBHelper.meassureFactor(lossMeasure, totalMeasure);
                l = loss * factor;
            }

        }    
        
        return q + l;
    }

    static calculateQuantityConsumption(product:Product, total:number, totalMeasure:MeasureUnitType, loss:number, lossMeasure:MeasureUnitType, quantityMeasure:MeasureUnitType){
        // Calculate quantity from total
        let t = total;
        let l = loss;

        if (quantityMeasure == MeasureUnitType.Container){
            if (totalMeasure != MeasureUnitType.Container) {
                let factor = DBHelper.meassureFactor(totalMeasure, product.measureUnitType);
                t = (total * factor) / product.quantity;
            }

            if (lossMeasure != MeasureUnitType.Container) {
                let factor = DBHelper.meassureFactor(lossMeasure, product.measureUnitType);
                l = (loss * factor) / product.quantity;
            }
        }
        else {
            if (totalMeasure == MeasureUnitType.Container) {
                let factor = DBHelper.meassureFactor(product.measureUnitType, quantityMeasure);
                t = (total * factor) * product.quantity;
            }
            else {
                let factor = DBHelper.meassureFactor(totalMeasure, quantityMeasure);
                t = total * factor;
            }

            if (lossMeasure == MeasureUnitType.Container) {
                let factor = DBHelper.meassureFactor(product.measureUnitType, quantityMeasure);
                l = (loss * factor) * product.quantity;
            }
            else {
                let factor = DBHelper.meassureFactor(lossMeasure, quantityMeasure);
                l = loss * factor;
            }

        }    
        
        return t - l;
    }

    static additionalProductCostsString(product:Product){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        if (product.isContainer == false) return ad.currencyFormatter.stringFromNumber(product.additionalCosts);

        let cp = product.additionalCosts * product.quantity;
        return ad.currencyFormatter.stringFromNumber(cp);
    }

    static calculateMinumCostFromProduct(product:Product){
        let cost = 0;
        if (product.costType == 0) cost = (product.costLastPrice || 0);
        else if (product.costType == 1) cost = (product.costAveragePrice || 0);
        else if (product.costType == 2) cost = (product.costPrice || 0);        
        
        //cost = product.isContainer == false ? product.costPrice : product.costPrice * product.quantity;
        //let addonCost = product.isContainer == false ? product.additionalCosts : product.additionalCosts * product.quantity;
        cost = cost + (product.additionalCosts || 0);

        return cost;
    }    

    static calculateCostFromProduct(product:Product, recalculateComponents?:boolean){
        let cost = 0;
        if (product.costType == 0) cost = (product.costProductLastPrice || 0);
        else if (product.costType == 1) cost = (product.costProductAveragePrice || 0);
        else if (product.costType == 2) cost = (product.costProductPrice || 0);
        else if (product.costType == 3 && recalculateComponents == true) cost = DBHelper.calculateProductCostFromComponents(product);
        else if (product.costType == 3) cost = (product.costProductComponentsPrice || 0);
        
        //cost = product.isContainer == false ? product.costPrice : product.costPrice * product.quantity;
        //let addonCost = product.isContainer == false ? product.additionalCosts : product.additionalCosts * product.quantity;
        cost = cost + (product.additionalProductCosts || 0);

        return cost;
    }    

    static calculateCostFromSupplierProduct(product:Product, supplierProduct:SupplierProduct){
        let cost = 0;

        if (supplierProduct.productPrice > 0) cost = supplierProduct.productPrice;
        else {
            if (product.costType == 0) cost = (supplierProduct.productLastPrice || 0);
            else if (product.costType == 1) cost = (supplierProduct.productAveragePrice || 0);    
        }        
        
        cost = cost + (product.additionalProductCosts || 0);

        return cost;
    }    

    static costFromProductAndSupplier(product:Product, supplier:Supplier){                        
        
        if (product == null) return [null, null];

        let sp:SupplierProduct = null;

        for (let index = 0; index < product.supplierProducts.length; index++){
            let item = product.supplierProducts.objectAtIndex(index) as SupplierProduct;
            if (item.supplier == supplier) {
                sp = item;
                break;
            }
        }

        let cost = 0;
        let discount = null;
        if (sp != null) {
            cost = DBHelper.calculateCostFromSupplierProduct(product, sp);        
            discount = sp.discountString;
        }
        if (cost == 0) cost = DBHelper.calculateCostFromProduct(product);
        
        return [cost, discount];
    }

    static costFromProduct(product:Product, supplier?:Supplier){                        
        if (supplier != null) return DBHelper.costFromProductAndSupplier(product, supplier);
        else return DBHelper.calculateCostFromProduct(product);
    }    

    static calculateCostProductString(product:Product){
        let cost = DBHelper.calculateCostFromProduct(product);

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(cost);
    }

    static calculateMinumCostFromProductPrice(productPrice:number, product:Product){        
        let price = productPrice;
    
        if (product.isContainer == true) price = productPrice / product.quantity;

        switch(product.measureUnitType){                        
            case MeasureUnitType.VolumeLitre:
            price = price / DBHelper.meassureFactor(product.measureUnitType, MeasureUnitType.VolumeCentilitre);
            break;

            case MeasureUnitType.MassKilogram:
            price = price / DBHelper.meassureFactor(product.measureUnitType, MeasureUnitType.MassGram);
            break;                   
        }
        
        return price;                    
    }

    static calculateCostFromComponent(product:Product, quantity:number, measure:MeasureUnitType){                        
        let cost = DBHelper.calculateCostFromProduct(product);        

        if (product.measureType == measure) {
            return quantity * cost;
        }
        else if (product.measureType == MeasureUnitType.Container){
            let c = cost / product.containerQuantity;
            if (measure == product.containerMeasureType){
                return c * quantity;
            }
            else {
                let factor = DBHelper.meassureFactor(product.measureUnitType, measure);       
                let q = quantity / factor;
                return c * q;
            }
        } 
        else {
            let factor = DBHelper.meassureFactor(product.measureUnitType, measure);       
            let q = quantity / factor;
            return cost * q;
        }        
    }

    static calculateProductCostFromComponents(product:Product){
        let total = 0;
        for (let index = 0; index < product.components.count; index++){
            let item = product.components.objectAtIndex(index) as Component;
            let cost = DBHelper.calculateCostFromComponent(item.product, item.totalQuantity, item.totalMeasureType);
            total += cost;
        }
                
        return total;
    }

    static createProductItemFromProduct(product:Product):ProductListItem{
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let productItem = MIOEntityDescription.insertNewObjectForEntityForName("ProductListItem", ad.managedObjectContext) as ProductListItem;
        productItem.type = ProductListItemType.Product;
        productItem.name = product.name;
        productItem.itemID = product.identifier;
        //productItem.imageURL = product.imageURL;
        productItem.category = product.category;
        productItem.product = product;
        productItem.price = product.price;

        return productItem;
    }

    static checkProductName(name:string, target, completion){
        DBHelper.queryObjectsWithCompletion("Product", null, MIOPredicate.predicateWithFormat("name == '" + name + "'"), [], this, function(objects){
            if (objects.length > 0) completion.call(target, false);
            else completion.call(target, true);
        });
    }

    static createProduct(name:string, price:number, category:Category, source:ProductSource, measureType:MeasureUnitType, containerMeasureType:MeasureUnitType, containerQuantity:number):Product{
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let product = MIOEntityDescription.insertNewObjectForEntityForName("Product", ad.managedObjectContext) as Product;
        product.identifier = MIOUUID.UUID().UUIDString;
        product.name = name;
        product.price = price;
        product.category = category;
        product.source = source;

        if (measureType == MeasureUnitType.Container) {
            product.measureUnitType = containerMeasureType;
            product.quantity = containerQuantity;
        }
        else {
            product.measureUnitType = measureType;
            product.quantity = null;
        }

        return product;
    }

    static createInputFormat(name:string, quantity:string, product:Product, productID?:string){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let q = ad.numberFormatter.numberFromString(quantity);

        let inputFormat = MIOEntityDescription.insertNewObjectForEntityForName("StockInputFormat", ad.managedObjectContext) as StockInputFormat;
        inputFormat.name = name;
        inputFormat.quantity = q;

        if (product == null) {
            DBHelper.queryObjectsWithCompletion("Product", null, MIOPredicate.predicateWithFormat("identifier == " + productID), [], this, function(products){
                if (products.length == 0) return;
                let p = products[0] as Product; 
                inputFormat.product = p;
                p.addStockInputFormatsObject(inputFormat);
                DBHelper.saveMainContext();
            });
        }
        else {
            inputFormat.product = product;
            product.addStockInputFormatsObject(inputFormat);
        }
        
        return inputFormat;
    }    

    static createProductFormat(product:Product, format:Format):ProductFormat {
        if (product == null) return null;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let pf = MIOEntityDescription.insertNewObjectForEntityForName("ProductFormat", ad.managedObjectContext) as ProductFormat;

        pf.format = format;
        pf.product = product;
        let mu = product.isContainer ? product.containerMeasureType : product.measureType;
        pf.consumptionMeasureType = mu;
        pf.lossMeasureType = mu;
        pf.totalMeasureType = mu;
        product.addProductFormatsObject(pf);

        return pf;
    }

    static removeProductFormat(productFormat:ProductFormat){
        if (productFormat == null) return;        
        DBHelper.deleteObjectFromMainContext(productFormat, true);
    }

    static priceFromProductAndSupplier(product:Product, supplier:Supplier):[number, string]{                
        if (supplier == null) return [0, null];

        let array = product.supplierProducts.filterWithPredicate(MIOPredicate.predicateWithFormat("supplier.identifier == " + supplier.identifier));
        if (array.length > 0) {
            let sp = array[0] as SupplierProduct;
            return [sp.price, sp.discountString];
        }

        return [0, null];
    }

    static stockTaxFromProduct(product:Product):Tax {
        if (product.taxBuy != null) return product.taxBuy;
        if (product.tax != null) return product.tax;
        if (product.category != null && product.category.tax != null) return product.category.tax;
        return null;
    }

    static meassureFactor(meassureUnit:MeasureUnitType, inputMeassureUnit:MeasureUnitType){
        if (inputMeassureUnit == null) return 1;
        if (inputMeassureUnit == 0) return 1;
        if (meassureUnit == inputMeassureUnit) return 1;
        if (meassureUnit == MeasureUnitType.MassKilogram && inputMeassureUnit == MeasureUnitType.MassGram) return 1000;
        if (meassureUnit == MeasureUnitType.MassGram && inputMeassureUnit == MeasureUnitType.MassKilogram) return 0.001;
        if (meassureUnit == MeasureUnitType.VolumeLitre && inputMeassureUnit == MeasureUnitType.VolumeCentilitre) return 100;
        if (meassureUnit == MeasureUnitType.VolumeCentilitre && inputMeassureUnit == MeasureUnitType.VolumeLitre) return 0.01;
        
        return 1;
        MIOLog('**** Unkown meassure conversion!! ****');
    }

    // Calculate from product quantity and product price
    static calculateTotalFromStockLine(quantity, price, discountString:string){

        let measurePrice = 0;
        let discount = 0;        
        let total = quantity * price;

        // Calculate discount
        if (discountString) {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate
            let discountNumber = ad.percentNumberFormatter.numberFromString(discountString);

            if (discountString.hasSuffix("%") == true) {                
                discount = (discountNumber * total) / 100;
            }
            else if (discountString.hasSuffix("%") == false){
                discount = discountNumber;            
            }
        } else {
            discountString = null;
        }

        if (discount != 0) total -= discount;

        return [total, discount];
    }  

    static calculateQuantityFromStockLine(quantity, inputFormat:StockInputFormat, inputType:MeasureUnitType, productMeasureType:MeasureUnitType, productContainerMeasureType:MeasureUnitType, productContainerQuantity){
        
        let productQuantity = 0;
        let measureQuantity = 0;   
        let factor = 1;     

        // Calculate product quantity
        if (inputFormat != null) {
            productQuantity = quantity * inputFormat.quantity;            
        }
        else if (productMeasureType != MeasureUnitType.Container && inputType != productMeasureType) {            
            factor = DBHelper.meassureFactor(inputType, productMeasureType);
            productQuantity = quantity * factor;            
        }
        else if (productMeasureType == MeasureUnitType.Container && inputType != MeasureUnitType.Container){
            if (inputType == productContainerMeasureType) {
                productQuantity = quantity / productContainerQuantity; 
                factor = 1 / productContainerQuantity; 
            }
            else {
                factor = DBHelper.meassureFactor(inputType, productContainerMeasureType);
                productQuantity = (quantity * factor) / productContainerQuantity;                
            }
        }
        else {
            productQuantity = quantity;            
        }

        // Calculate meassure
        if (productMeasureType == MeasureUnitType.Container) {
            measureQuantity = productQuantity * productContainerQuantity;
        }
        else {
            measureQuantity = productQuantity;
        }

        return [productQuantity, measureQuantity, factor];
    }  

    static removeNote(note:StockNote){        
        if (note == null) return;

        for (let index = 0; index < note.lines.count; index++){
            let l = note.lines.objectAtIndex(index);
            DBHelper.deleteObjectFromMainContext(l, false);
        }

        DBHelper.deleteObjectFromMainContext(note, true);
        MIONotificationCenter.defaultCenter().postNotification("StockNoteDidDelete", note, null);        
    }

    static createNoteLine(type:StockNoteLineType, product:Product, tax:Tax, inputFormat:StockInputFormat, measureType:MeasureUnitType, quantity, productQuantity, measureQuantity, price, productPrice, total, warehouseID:string, warehouseName:string, note:StockNote):StockNoteLine {

        if (product == null || quantity == null || note == null) return null;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let line = MIOEntityDescription.insertNewObjectForEntityForName("StockNoteLine", ad.managedObjectContext) as StockNoteLine;        
                
        if (ad.selectedIdentifierType == "place") line.placeID = ad.selectedIdentifier;

        line.type = type;        
        line.productName = product.stockName ? product.stockName : product.name;
        line.product = product;
        line.productMeasureType = product.isContainer ?  MeasureUnitType.Container : product.measureUnitType;
        line.productContainerMeasureType = product.isContainer ? product.measureUnitType : null;
        line.productContainerQuantity = product.isContainer ? product.quantity : null;

        let stockTax = tax;
        if (stockTax == null) stockTax = line.stockTax();        
        line.tax = stockTax;
        line.taxName = stockTax != null ? stockTax.name : null;
        line.taxQuantity = stockTax != null ? stockTax.taxQuantity : null;

        if (inputFormat != null) {
            line.inputFormatQuantity = inputFormat.quantity;
            line.inputFormat = inputFormat;
            line.inputFormatName = inputFormat.name;
        }

        line.measureType = measureType ? measureType : 0;
        line.quantity = quantity;
        line.productQuantity = productQuantity;
        line.measureQuantity = measureQuantity;
        
        //TODO: Add product price and total
        line.price = price;
        line.productPrice = productPrice;
        line.total = total;
        line.note = note;   
                
        line.warehouseID = warehouseID;
        line.warehouseName = warehouseName;        

        line.orderIndex = Date.now();

        note.addLinesObject(line);
        return line;
    }

    // 
    // StockNote
    //
    
    static createStockNote(type:StockNoteType):StockNote {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let note = MIOEntityDescription.insertNewObjectForEntityForName("StockNote", ad.managedObjectContext) as StockNote;        

        if (ad.selectedIdentifierType == "place") note.placeID = ad.selectedIdentifier;
        note.appID = "MANAGER";

        let now = MIODateNow();
        note.creationDate = now;
        note.documentDate = now;
        note.stockDate = now;
        note.status = StockNoteStatus.None;
        note.type = type;

        return note;
    }

    //
    // Inventory notes
    //
    static createInventoryNote(destination:Warehouse):StockNote {
        let note = DBHelper.createStockNote(StockNoteType.InventoryNote);
        
        note.destinationWarehouse = destination;
        note.destinationName = destination != null ? destination.name : MIOLocalizeString("DEFFAULT WAREHOUSE", "Default Warehouse");

        return note;
    }

    static createInventoryNoteLine(product:Product, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity, productQuantity, measureQuantity, note:StockNote):StockNoteLine {
        return this.createNoteLine(StockNoteLineType.InventoryNoteLine, product, null, inputFormat, measureUnit, quantity, productQuantity, measureQuantity, null, null, null, null, null, note);
    }

    //
    // Movements notes
    //

    static createMovementNote(origin:Warehouse, destination:Warehouse):StockNote {
        let note = DBHelper.createStockNote(StockNoteType.MovementNote);
        
        note.originWarehouse = origin;
        note.originName = origin != null ? origin.name : MIOLocalizeString("DEFFAULT WAREHOUSE", "Default Warehouse");
        note.destinationWarehouse = destination;
        note.destinationName = destination != null ? destination.name : MIOLocalizeString("DEFFAULT WAREHOUSE", "Default Warehouse");

        return note;
    }

    static createMovementNoteLine(product:Product, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity, productQuantity,measureQuantity, note:StockNote):StockNoteLine {
        return this.createNoteLine(StockNoteLineType.NoteLine, product, null, inputFormat, measureUnit, quantity, productQuantity, measureQuantity, null, null, null, null, null, note);
    }

    //
    // Output notes
    //

    static createOutputNote(origin:Warehouse, destination:StockCustomConcept){
        let note = DBHelper.createStockNote(StockNoteType.CustomOutputNote);
        
        note.originWarehouse = origin;
        note.originName = origin != null ? origin.name : MIOLocalizeString("DEFFAULT WAREHOUSE", "Default Warehouse");
        note.destinationName = destination.name;
        note.destinationConcept = destination;
    
        return note;
    }

    static createOutputNoteLine(product:Product, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity, productQuantity, measureQuantity, note:StockNote):StockNoteLine {
        return this.createNoteLine(StockNoteLineType.OutputLine, product, null, inputFormat, measureUnit, quantity, productQuantity, measureQuantity, null, null, null, null, null, note);
    }

    //
    // Supplier order
    //

    static createSupplierOrder(supplier:Supplier) {
        let note = DBHelper.createStockNote(StockNoteType.SupplierOrder);
        
        note.destinationEntity = supplier;
        note.destinationName = supplier.name;

        return note;
    }

    static createSupplierOrderLine(product:Product, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity, productQuantity, measureQuantity, price, productPrice, total, note:StockNote):StockNoteLine {
        return this.createNoteLine(StockNoteLineType.SupplierNoteLine, product, null, inputFormat, measureUnit, quantity, productQuantity, measureQuantity, price, productPrice, total, null, null, note);
    }

    //
    // Supplier note
    //

    static createSupplierNote(supplier:Supplier) {
        let note = DBHelper.createStockNote(StockNoteType.SupplierNote);

        note.originEntity = supplier;
        note.originName = supplier.name;

        return note;
    }

    static createSupplierNoteLine(product:Product, tax:Tax, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity, productQuantity, measureQuantity, price, productPrice, total, warehouseID:string, warehouseName:string, note:StockNote):StockNoteLine {
        return this.createNoteLine(StockNoteLineType.SupplierNoteLine, product, tax, inputFormat, measureUnit, quantity, productQuantity, measureQuantity, price, productPrice, total, warehouseID, warehouseName,  note);
    }

    //
    // Delivery note
    //

    static createDeliveryNote(legalEntity){
        let note = DBHelper.createStockNote(StockNoteType.DeliveryNote);

        note.destinationEntity = legalEntity;
        note.destinationName = legalEntity.name;

        return note;
    }

    static createDeliveryNoteLine(product:Product, tax:Tax, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity, productQuantity, measureQuantity, price, productPrice, total, warehouseID:string, warehouseName:string, note:StockNote):StockNoteLine {
        return this.createNoteLine(StockNoteLineType.DeliveryNoteLine, product, tax, inputFormat, measureUnit, quantity, productQuantity, measureQuantity, price, productPrice, total, warehouseID, warehouseName,  note);
    }

    //
    // Low stock order note line
    //
    
    static createLowStockNoteLine(product:Product, inputFormat:StockInputFormat, measureUnit:MeasureUnitType, quantity, productQuantity, measureQuantity, supplier:Supplier, note:StockNote):StockNoteLine {
        let line = this.createNoteLine(StockNoteLineType.LowStockOrderLine, product, null, inputFormat, measureUnit, quantity, productQuantity, measureQuantity, null, null, null, null, null,  note);
        line.legalEntity = supplier;
        line.legalEntityName = supplier ? supplier.name : null;
        return line;
    }


    // 
    // Others
    //

    static createSupplierProduct(supplier:Supplier, product:Product, price:number, productPrice:number, reference:string, discount:string):SupplierProduct {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let sp = MIOEntityDescription.insertNewObjectForEntityForName("SupplierProduct", ad.managedObjectContext) as SupplierProduct;        
        
        if (ad.selectedIdentifierType == "place") sp.placeID = ad.selectedIdentifier;
        sp.supplier = supplier;
        sp.product = product;        
        sp.supplierReference = reference;
        sp.price = price;
        sp.productPrice = productPrice;
        sp.discountString = discount;

        return sp;
    }


    static removeSupplierProduct(item:SupplierProduct, save?){
        let moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        moc.deleteObject(item);
        if (save == true) moc.save();
    }


    //
    // Worker
    //

    static createWorkSession(beginDate:Date, endDate:Date, worker:Employee):WorkSession {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            
        let ws = MIOEntityDescription.insertNewObjectForEntityForName("WorkSession", ad.managedObjectContext) as WorkSession;
        ws.beginDate = beginDate;
        ws.endDate = endDate;
        ws.worker = worker;

        if (ad.selectedIdentifierType == "place") ws.placeID = ad.selectedIdentifier;

        return ws;
    }


}