
class BankAccountDetailViewController extends MUIViewController
{
    private actionButton:MUIButton = null;
    private totalLabel:MUILabel = null;

    private tableView:MUITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(){
            this.showActions();
        });

        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }    

    viewWillAppear(){
        super.viewWillAppear();
        this.updateUI();
    }

    private type = null
    set bankType(type){
        this.type = type;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this.type == null) return;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        let sections = this.fetchedResultsController.sections.length;
        return sections;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();        
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){                                
        let cell = tableview.dequeueReusableCellWithIdentifier('BankMovementCell') as BankMovementCell;        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as BankAccountMovement;
        
        cell.item = item;

        return cell;            
    }

    private detailViewController:BankAccountDetailViewController = null;
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){        
        if (this.detailViewController == null) {
            this.detailViewController = new BankAccountDetailViewController("bank-account-detail-view");
            this.detailViewController.initWithResource("layout/bankaccount/BankAccountDetailView.html");
        }

        this.navigationController.splitViewController.showDetailViewController(this.detailViewController);
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('createdAt', true)];
        let predicateFormat = null;//'bankAccount.type == ' + this.type;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BankAccountMovement", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["bankAccount"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){    
        this.updateTotal(controller.fetchedObjects);
        this.tableView.reloadData();
    }   


    private updateTotal(lines){
        let total = 0;

        for (let index = 0; index < lines.length; index++){
            let l = lines[index] as BankAccountMovement;
            total += l.amount;
        }

        this.totalLabel.text = total;
    }

    private showActions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Actions", "Choose an action", MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle("Send payment Link", MUIAlertActionStyle.Default, this, function(){
            this.askForEmail();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Make transfer", MUIAlertActionStyle.Default, this, function(){
            this.askForBankAccount();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Move to another account", MUIAlertActionStyle.Default, this, function(){
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }
    
    private askForEmail(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Info", "Fill the required info", MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Email");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Name");
        });
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Amount");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Concept");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle("Send", MUIAlertActionStyle.Default, this, function(){
            let email = avc.textFields[0].text;
            let name = avc.textFields[1].text;
            let amount = avc.textFields[2].text;
            let concept = avc.textFields[3].text;

            this.sendPaymentLink(email, name, parseFloat(amount), concept);            
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private askForBankAccount(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Info", "Fill the required info", MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("IBAN");
        });
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Amount");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Concept");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle("Send", MUIAlertActionStyle.Default, this, function(){
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }    

    private sendPaymentLink(email:string, name:string, amount, concept:string){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.sendPaymentLink(email, name, amount, concept, null, this, function (code, data){
            if (code == 200) {
                AppHelper.showInfoMessage(this, "Info", "Payment link sent");
            }
            else {
                AppHelper.showErrorMessage(this, "Error", "Error sending email. please try again later");
            }
        });        
    }
}