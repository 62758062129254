
class SupplierOrderCell extends UITableViewCell
{
    private documentIDLabel:MUILabel = null;
    private originLabel:MUILabel = null;
    private dateLabel:MUILabel = null;    

    awakeFromHTML(){
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.originLabel = MUIOutlet(this, "origin-lbl", "MUILabel");
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
    }

    set item (item:StockNote){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        this.documentIDLabel.text = item.documentID;
        this.originLabel.text = item.destinationName;        
        this.dateLabel.text = ad.dateFormatter.stringFromDate(item.stockDate);
    }
}