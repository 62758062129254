

// Generated class Report_ManagedObject

class Report_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: section
    set section(value:string) {
        this.setValueForKey(value, 'section');
    }
    get section():string {
        return this.valueForKey('section');
    }
    set sectionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'section');
    }
    get sectionPrimitiveValue():string {
        return this.primitiveValueForKey('section');
    }

    // Property: type
    set type(value:string) {
        this.setValueForKey(value, 'type');
    }
    get type():string {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():string {
        return this.primitiveValueForKey('type');
    }

    // Property: script
    set script(value:string) {
        this.setValueForKey(value, 'script');
    }
    get script():string {
        return this.valueForKey('script');
    }
    set scriptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'script');
    }
    get scriptPrimitiveValue():string {
        return this.primitiveValueForKey('script');
    }

    // Property: en
    set en(value:string) {
        this.setValueForKey(value, 'en');
    }
    get en():string {
        return this.valueForKey('en');
    }
    set enPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'en');
    }
    get enPrimitiveValue():string {
        return this.primitiveValueForKey('en');
    }

    // Property: es
    set es(value:string) {
        this.setValueForKey(value, 'es');
    }
    get es():string {
        return this.valueForKey('es');
    }
    set esPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'es');
    }
    get esPrimitiveValue():string {
        return this.primitiveValueForKey('es');
    }

    // Property: nl
    set nl(value:string) {
        this.setValueForKey(value, 'nl');
    }
    get nl():string {
        return this.valueForKey('nl');
    }
    set nlPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nl');
    }
    get nlPrimitiveValue():string {
        return this.primitiveValueForKey('nl');
    }

    // Property: ar
    set ar(value:string) {
        this.setValueForKey(value, 'ar');
    }
    get ar():string {
        return this.valueForKey('ar');
    }
    set arPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ar');
    }
    get arPrimitiveValue():string {
        return this.primitiveValueForKey('ar');
    }
}
