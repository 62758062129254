


/// <reference path="../ModifierPrice/ModifierPriceViewController.ts" />

class ModifierViewController extends MUIViewController
{
    private _fetchedResultsController:MIOFetchedResultsController = null;    

    private backButton:MUIButton = null;
    private addButton:MUIButton = null;
    private modifierCategoryLabel:MUILabel = null;
    private categorynameLabel:MUILabel = null;
    private incrementalCheckbox:MUISwitchButton = null;
    private multipleselectionCheckbox:MUISwitchButton = null;
    private setPriceButton:MUIButton = null;
    private setCategoryButton:MUIButton = null;

    private tableView:MUITableView = null;

    private mpvc:ModifierPriceViewController = null; //Modifier Price ViewController    

    get preferredContentSize(){
        return new MIOSize(500, 500);
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, 'mv_nb_back_btn', 'MUIButton');
        this.backButton.setAction(this,function () {
             this.navigationController.popViewController(true); 
         });       

        this.addButton = MUIOutlet(this, 'mv_nb_add_btn', 'MUIButton');
        this.addButton.setAction(this, function()
        {
           this.addModifier();
        });

        this.modifierCategoryLabel = MUIOutlet(this, 'mv_tb_category_text', 'MUILabel');
        this.categorynameLabel = MUIOutlet(this, 'mv_cb_category_sync', 'MUILabel');

        this.incrementalCheckbox = MUIOutlet(this, 'mv_b2_incrementable_switch_btn', 'MUISwitchButton');
        this.incrementalCheckbox.setOnChangeValue(this, function(checkbox, on){
            
            this._modifiercategory.incremental = on;
        });

        this.multipleselectionCheckbox = MUIOutlet(this, 'mv_b1_multiple_selection_switch_btn', 'MUISwitchButton');
        this.multipleselectionCheckbox.setOnChangeValue(this, function(checkbox, on){
            
            this._modifiercategory.multipleSelection = on;
        });

        this.setPriceButton = MUIOutlet(this, 'mv_b3_establish_btn', 'MUIButton');
        this.setPriceButton.setAction(this, function () {

           /* if(this.mpvc == null) {

                this.mpvc = new ModifierPriceViewController('modifier_price_view');
                this.mpvc.initWithResource('layout/modals/modifierprice/ModifierPriceView.html');
            }

            this.presentViewController(this.mpvc, true);
            */
            if(!this.mpvc) {
               this.mpvc = new ModifierPriceViewController('modifier_price_view');
               this.mpvc.delegate = this;
               this.mpvc.initWithResource('layout/modals/modifierprice/ModifierPriceView.html');
               this.mpvc.modalPresentationStyle = MUIModalPresentationStyle.Popover;
            
               var mpvc = this.mpvc.popoverPresentationController;
               mpvc.sourceRect = this.setPriceButton.frame;
               mpvc.sourceView = this.setPriceButton;
            }
            if(!this.mpvc.isPresented)
               this.presentViewController(this.mpvc, true);
            
        });

        this.setCategoryButton = MUIOutlet(this, 'mv_c1_category_box', 'MUIButton');
        this.setCategoryButton.setAction(this, function () {

            // if(this.cvc == null) {
            //     this.cvc = new CategoryViewController("category_view");
            //     this.cvc.initWithResource("layout/modals/category/CategoryView.html");
            // }
            // this.navigationController.pushViewController(this.cvc, true);
        });

        this.tableView = MUIOutlet(this,"mv_pb_tableview","MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        
        this.tableView.reloadData();
    }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);        
        this._updateUI();
    }

    private _modifierCategory:ProductModifierCategory = null;
    set modifierCategory(value:ProductModifierCategory){
        this._modifierCategory = value;
        this._updateUI();
    }

    private _updateUI(){
        if (this.viewIsLoaded == false || this._modifierCategory == null) return;

        this.modifierCategoryLabel.text = this._modifierCategory.name;
        this.categorynameLabel.text = this._modifierCategory.category.name;
        this.multipleselectionCheckbox.setOn(this._modifierCategory.multipleSelection);
        this.incrementalCheckbox.setOn(this._modifierCategory.incremental);
        
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    private addModifier()
    {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('MODIFIER','Modifier'), MIOLocalizeString('CHOOSE A NAME','Choose a name '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){

            textField.setPlaceholderText("Name");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function()
        {
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0)
            {
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                var modifier:ProductModifier = MIOEntityDescription.insertNewObjectForEntityForName("ProductModifier", moc) as ProductModifier;
                modifier.identifier = MIOUUID.UUID().UUIDString;
                modifier.name = name;
                modifier.isDefault = false;
                modifier.lossInProduction = 0;
                modifier.isVisible = true;
                modifier.price = 0;
                modifier.productModifierCategory = this._modifiercategory;
                
                modifier.orderIndex = 1;
                moc.save();
                //this.attribute = at;
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('CHECK NAME','Check name.'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
        
        this.presentViewController(avc, true);
    }

    private removeModifier(modifier)
    {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        moc.deleteObject(modifier);
        moc.save();
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section)
    {
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        let cell:ModifierCell = tableview.dequeueReusableCellWithIdentifier('ModifierCell');

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        cell.item = item;

        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        cell.editing = true;

        return cell;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        
        var modifier = this.fetchedResultsController.objectAtIndexPath(indexPath);    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeModifier(modifier);
        }
    }

    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('orderindex', true),
                                            MIOSortDescriptor.sortDescriptorWithKey('name', true)];

        let predicateFormat = 'productModifierCategory.identifier == "' + this._modifierCategory.identifier + '"';

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductModifier", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ['productModifierCategory'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }

}