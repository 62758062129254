

class ReportDateTimeFilterCell extends MUITableViewCell
{
    delegate = null;
    
    private _filter = null;
    private _date = null;

    private titleLabel = null;
    
    private dateLabel = null;
    private dateButton = null;

    private hourTextField = null;
    private minutesTextField = null;

    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private tf = MUIWebApplication.sharedInstance().delegate.timeFormatter;

    awakeFromHTML()
    {
        this.titleLabel = MUIOutlet(this, "rfv_c6_lbl", "MUILabel");

        this.dateLabel = MUIOutlet(this, "rfv_c6_date_lbl", "MUILabel");
        this.dateLabel.text = MIOLocalizeString("SELECT DATE", "Select date");
        
        this.dateButton = MUIOutlet(this, "rfv_c6_calendar_btn", "MUIButton");
        this.dateButton.setAction(this, function(){

            if (this.delegate != null) {
                this.delegate.selectDateFromDateFilterCell.call(this.delegate, this, this.dateButton, this._filter);
            }
        });

        this.hourTextField = MUIOutlet(this, 'rfv_c6_hour', 'MUITextField');
        this.hourTextField.formatter = this.tf;
        this.hourTextField.text = "00:00";
        this.hourTextField.setOnChangeText(this, function(control, text){
            let time = this.tf.dateFromString(text);
            if (this._date != null){

                this._date.setHours(time ? time.getHours() : 0);
                this._date.setMinutes(time ? time.getMinutes() : 0);
                this.didChangeDateTimeValue();
            }
        });

       /* this.minutesTextField = MUIOutlet(this, 'rfv_c6_minute', 'MUITextField');
        this.minutesTextField.text = "00";
        this.minutesTextField.setOnChangeText(this, function(control, text){

            if (this._date != null){

                this._date.setMinutes(parseInt(text));
                this.didChangeDateTimeValue();
            }
        });
        */
    }

    set filter(filter){

        this._filter = filter;
        this.titleLabel.text = filter["title"] + ":";
    }

    set date(date){

        this._date = date;
        let time = this.tf.dateFromString(this.hourTextField.text);
        if(this._date) {
            this._date.setHours(time ? time.getHours() : 0);
            this._date.setMinutes(time ? time.getMinutes() : 0);
            this._date.setSeconds(0);
        }

        this.dateLabel.text = this.df.stringFromDate(date);

        this.didChangeDateTimeValue();
    }

    private didChangeDateTimeValue(){
    
        if (this.delegate != null)
            this.delegate.didChangeDateTimeValue.call(this.delegate, this, this._date, this._filter);
                
    }
}