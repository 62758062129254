
class SelectTicketViewController extends MUIViewController
{    
    private closeButton:MUIButton = null;   
    private createButton:MUIButton = null;
    private clientNameDropdown:MUIButton = null;
    private editClientButton:MUIButton = null;
    private dateFilterTextField:ColumnFilterTextField = null;    
    private documentIDFilterTextField:ColumnFilterTextField = null;    
    private clientNameFilterTextField:ColumnFilterTextField = null;    
    private totalFilterTextField:ColumnFilterTextField = null;    
    private tableView:MUITableView = null; 

    private ticketTableView:MUITableView = null;
    private totalLabel:MUILabel = null;

    private columnFilterController:ColumnFilterController = null;    
    private selectedTicketDataSource:SelectedTicketDataSource = null;    

    get preferredContentSize(){
        return new MIOSize(750, 700);
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.columnFilterController = new ColumnFilterController();
        this.columnFilterController.initWithDelegate(this);

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        });      
        
        this.createButton = MUIOutlet(this, "create-btn", "MUIButton");
        this.createButton.setAction(this, this.createInvoiceAction);

        this.clientNameDropdown = MUIOutlet(this, "client-dd", "MUIButton");
        this.clientNameDropdown.setAction(this, this.selectClientAction);        

        this.editClientButton = MUIOutlet(this, "edit-client-btn", "MUIButton");
        this.editClientButton.enabled = false;
        this.editClientButton.setAction(this, this.editClientAction);

        this.dateFilterTextField = MUIOutlet(this, "date-filter-tf", "ColumnFilterTextField");
        this.dateFilterTextField.filterController = this.columnFilterController;
        this.dateFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "date", null, null);

        this.documentIDFilterTextField = MUIOutlet(this, "document-filter-tf", "ColumnFilterTextField");
        this.documentIDFilterTextField.filterController = this.columnFilterController;
        this.documentIDFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "legalDocumentID", null, null);

        this.clientNameFilterTextField = MUIOutlet(this, "client-filter-tf", "ColumnFilterTextField");
        this.clientNameFilterTextField.filterController = this.columnFilterController;
        this.clientNameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "ticketLegalEntityName", null, null);

        this.totalFilterTextField = MUIOutlet(this, "total-filter-tf", "ColumnFilterTextField");
        this.totalFilterTextField.filterController = this.columnFilterController;
        this.totalFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "totalPrice", null, null);

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;                

        this.ticketTableView = MUIOutlet(this, "tickets-table-view", "MUITableView");
        this.selectedTicketDataSource = new SelectedTicketDataSource();
        this.selectedTicketDataSource.init();
        this.ticketTableView.dataSource = this.selectedTicketDataSource;
        this.ticketTableView.delegate = this.selectedTicketDataSource;
        this.selectedTicketDataSource.tickets = this.selectedTickets;
        this.selectedTicketDataSource.delegate = this;

        this.totalLabel = MUIOutlet(this, "selected-total-lbl", "MUILabel");
        this.totalLabel.text = this.selectedTicketTotalString();
    }
    
    viewWillAppear(animated?:boolean){
        super.viewWillAppear(animated);
        this.tableView.reloadData();
    }

    viewWillDisappear(animated?:boolean){
        super.viewWillDisappear(animated);
        this.fetchedResultsController = null;
    }

    //
    // Tableview delegate
    //

    numberOfSections(tableView:MUITableView){
        return this.fetchedResultsController.sections.length + 1;
    }
    
    numberOfRowsInSection(tableView:MUITableView, section:number){
        if (this.fetchedResultsController.sections.length == section) return 1;

        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {
        if (this.fetchedResultsController.sections.length == indexPath.section) return tableView.dequeueReusableCellWithIdentifier("MoreCell");

        let cell = tableView.dequeueReusableCellWithIdentifier("TicketCell") as SelectTicketCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ArchivedDocument;
        
        cell.item = item;
        cell.delegate = this;
        cell.selectedTicket = this.selectedTickets.containsObject(item);

        return cell;
    }   
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        if (indexPath.section < this.fetchedResultsController.sections.length) return;
        
        this.fetchedResultsController.performNextFetch();        
    }

    private selectedTickets = [];
    private selectedTicketsTotal = 0;        
    addTicketFromCell(cell:SelectTicketCell, item:ArchivedDocument){
        if (this.selectedTickets.containsObject(item) == true) {
            this.selectedTickets.removeObject(item);
            this.selectedTicketsTotal -= item.totalPrice;
            cell.selectedTicket = false;
        }
        else {
            this.selectedTickets.addObject(item);
            this.selectedTicketsTotal += item.totalPrice;
            cell.selectedTicket = true;
        }

        this.ticketTableView.reloadData();                
        this.totalLabel.text = this.selectedTicketTotalString();
    }

    removeSelectedTicket(item:ArchivedDocument){
        this.selectedTickets.removeObject(item);
        this.selectedTicketsTotal -= item.totalPrice;
        this.ticketTableView.reloadData();
        this.totalLabel.text = this.selectedTicketTotalString();

        let ip = this.fetchedResultsController.indexPathForObject(item);
        if (ip != null) {
            let cell = this.tableView.cellAtIndexPath(ip) as SelectTicketCell
            cell.selectedTicket = false;
        }
    }

    private _fetchedResultsController:MWSFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("ArchivedDocument");
        fetchRequest.fetchLimit = 50;  
        
        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("date", false), 
        MIOSortDescriptor.sortDescriptorWithKey("legalDocumentID", false)];
    
        let predicateFormat = "legalDocumentID != null AND type == 'T' AND nextDocument == null AND modifiedType != 1 AND totalPrice != 0";
        // if (this.searchString != null) {
        //     predicateString += " AND (legalDocumentID CONTAINS '" + this.searchString + "' OR documentID CONTAINS '" + this.searchString + "')";
        // }
        // if (this.dateTimeFrom != null && this.dateTimeTo != null) {
        //     predicateString += " AND (sessionBeginDate >= '" + this.dateTimeFrom + "' AND sessionBeginDate <= '" + this.dateTimeTo + "')";
        // }

        let filterFormat = this.columnFilterController.filterPredicateFormat();
        if (filterFormat != null) {
            predicateFormat += " AND " + filterFormat;
        }

        fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
                
        let fetchedResultsController = new MWSFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.webPersistentStore = ad.webPersistentStore;
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.tableView.reloadData();
    }

    filterPredicateDidChange(controller:ColumnFilterController){        
        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }
    
    private selectedClient:Client = null;
    private selectClientAction(){
        AppHelper.sharedInstance().showSelectClientViewControllerFromView(this.clientNameDropdown, null, true, this, function(controller, client:Client){
            this.clientNameDropdown.title = client.name;
            this.selectedClient = client;
            this.editClientButton.enabled = true;
        }, this, function(){
            let vc = new AddNewClientViewController("add-new-client-view");
            vc.initWithResource("layout/clients/AddNewClientView.html");
            vc.delegate = this;
            this.presentViewController(vc, true);
        });
    }

    clientDidInserted(client:Client){
        this.clientNameDropdown.title = client.name;
        this.selectedClient = client;
        this.editClientButton.enabled = true;
    }

    private editClientAction(){
        let vc = new AddNewClientViewController('add-new-client-view');        
        vc.initWithResource('layout/clients/AddNewClientView.html');
        vc.client = this.selectedClient;
        this.presentViewController(vc, true);
    }

    private selectedTicketTotalString(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.selectedTicketsTotal);
    }

    private createInvoiceAction(){
        if (this.selectedClient == null) {
            AppHelper.showErrorMessage(this, "Error", "Please select a client.");
            return;
        }

        if (this.selectedTickets.length == 0){
            AppHelper.showErrorMessage(this, "Error", "Please select a ticket or tickets");
            return;
        }

        if (this.selectedTicketsTotal == 0) {
            AppHelper.showErrorMessage(this, "Error", "Sorry. It's not possible to make an invoce of 0");
            return;
        }

        this.createButton.enabled = false;
        this.closeButton.enabled = false;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.createInvoiceFromMultipleTickets(this.selectedTickets, this.selectedClient, this, function(code, data){            
            this.createButton.enabled = true;
            this.closeButton.enabled = true;                
            if (code == 200) {

                for (let index = 0; index < data.length; index++){
                    let item = data[index];
                    if (item["type"] != 'I') continue;
                    
                    let identifier = item["id"];    
                    MIONotificationCenter.defaultCenter().postNotification("SelectInvoiceNotification", identifier, null);
                    break;
                }                                

                this.dismissViewController(true);
            }  
        });        
    }


}
