
class SingleEditCell extends UITableViewCell
{
    private titleTextField:MUITextField = null;

    awakeFromHTML(){
        this.titleTextField = MUIOutlet(this, "title-tf", "MUITextField");
        this.setupTextField(this.titleTextField);        
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private item = null;
    private key = null; 
    setItem(item:MIOManagedObject, key:string){
        this.key = key;
        this.item = item;

        this.titleTextField.text = item.valueForKey(key);
    }

    private setupTextField(textField:MUITextField) {
        if (textField == null) return;

        textField.setOnChangeText(this, function (control:MUITextField, value:string){
            this.item.setValueForKey(value, this.key);
        });        
    }



}