/**
 * Created by miguel on 23/2/17.
 */


class OfferGeneralDetailViewController extends MUIViewController
{ 
    private _selectProductVC = null;

    private nameTextField:MUITextField = null;
    private typeComboBox:MUIComboBox = null;
    private typeValueTextField:MUITextField = null;

    private tableView:MUITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.nameTextField = MUIOutlet(this, 'name-tf','MUITextField');
        this.nameTextField.setOnChangeText(this, function(textfield, value){            
            this._offer.name = value;
        });        

        this.typeComboBox = MUIOutlet(this, "type-cb", "MUIComboBox");
        this.typeComboBox.addItem("Money", OfferType.Money);
        this.typeComboBox.addItem("Discount", OfferType.Discount);
        this.typeComboBox.addItem("Invite Product High", OfferType.InviteProductHigh);
        this.typeComboBox.addItem("Invite Product Low", OfferType.InviteProductLow);
        this.typeComboBox.addItem("Free Specific Product", OfferType.FreeSpecificProduct);
        this.typeComboBox.addItem("Free Products", OfferType.FreeProducts);
        this.typeComboBox.setOnChangeAction(this, function(control, value){
            this._offer.type = value;            
        });

        this.typeValueTextField = MUIOutlet(this, "value-tf", "MUITextField");
        this.typeValueTextField.setOnChangeText(this, function(control, value){
            this._offer.value = value;
        });

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    viewDidAppear(animate?){
        super.viewDidAppear(animate);
        this.updateUI();
    }
    
    private _offer:Offer = null;
    set offer(value){
        this._offer = value;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._offer == null) return null;

        this.nameTextField.text = this._offer.name;
        this.typeComboBox.selectItem(this._offer.type);
        this.typeValueTextField.text = this._offer.value;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;        
    }

    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell:OfferDetailCell = tableview.dequeueReusableCellWithIdentifier('OfferDetailCell');
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;        

        let item:Product = this.fetchedResultsController.objectAtIndexPath(indexPath);        
        cell.item = item;

        return cell;
    }

    // didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
    //     let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);
    // }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let offerProduct:OfferProduct = this.fetchedResultsController.objectAtIndexPath(indexPath);    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            //this.removeOfferProduct(offerProduct);
        }
    }

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Product');        
    
        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        fetchRequest.predicate = MIOPredicate.predicateWithFormat('offerID == ' + this._offer.identifier);
 
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

}