//
// Generated class BookingSource
//

/// <reference path="BookingSource_ManagedObject.ts" />

enum BookingSourceType {
    Booking,
    VIP
}

class BookingSource extends BookingSource_ManagedObject
{
    static insertNewSource(name:string, type:number, context?:MIOManagedObjectContext){
        
        let moc = context != null ? context : MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        let src = MIOEntityDescription.insertNewObjectForEntityForName("BookingSource", moc) as BookingSource;        
        src.name = name;
        src.type = type;

        return src;
    }
}
