/**
 * Created by miguel on 15/3/17.
 */

class CashDeskListViewController extends BaseTableViewController
{   
    private calendarButton:MUIButton = null; 
    private dateLabel:MUILabel = null;
    private totalLabel:MUILabel = null;
    
    private sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;

    viewDidLoad() { 
        super.viewDidLoad();

        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel")
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
        
        this.calendarButton = MUIOutlet(this, 'calendar-btn','MUIButton');
        this.calendarButton.setAction(this, function(){
            let ah:AppHelper = AppHelper.sharedInstance();
            ah.presentDateSelectionViewController(this);
        });

        this.tableView = MUIOutlet(this, 'table-view', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this; 

        this.updateDate();
        this.invalidateFetch();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        //let ip = MIOIndexPath.indexForRowInSection(indexPath.row + 1, indexPath.section);
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as CashDeskEntity;
        
        let cell = null;
        if(item.type == 0)
            cell = tableview.dequeueReusableCellWithIdentifier('CashDeskLevel1Cell');
        else if(item.type == 1)
            cell = tableview.dequeueReusableCellWithIdentifier('CashDeskLevel2Cell');
        else
            cell = tableview.dequeueReusableCellWithIdentifier('CashDeskLevel3Cell');        
        
        cell.item = item;        
        
        //console.log(item.indexPathString);

        return cell;
    }
        
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        //let ip = MIOIndexPath.indexForRowInSection(indexPath.row + 1, indexPath.section);
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as CashDeskEntity;
        this.showItem();
    }
        
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('CashDeskEntity');
        
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('indexPathString', true)];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }
        
    protected invalidateData(){        
        this.updateDate();        
        super.invalidateData();
    }

    protected invalidateFetch(){
        super.invalidateFetch();
        this.downloadCashDesklines();
    }

    protected reloadData(){
        super.reloadData();
        this.updateTotal();
    }

    datesDidSelect(dateTimeFrom, dateTimeTo) {
        let rh = ReportHelper.sharedInstance();
        rh.selectedDateTimeFrom = dateTimeFrom;
        rh.selectedDateTimeTo = dateTimeTo;  
        this.updateDate();
        this.downloadCashDesklines();
    }

    private downloadCashDesklines(){
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws:WebService = ad.webService;
        if(ad.selectedIdentifier == null) return;
        
        let rh = ReportHelper.sharedInstance();
        let dateTimeFrom = this.sdtf.stringFromDate(rh.selectedDateTimeFrom);
        let dateTimeTo = this.sdtf.stringFromDate(rh.selectedDateTimeTo);

        //this.activityIndicator.setHidden(false);

        ws.getCashDeskEntitiesByDate(ad.selectedIdentifier, ad.selectedIdentifierType, dateTimeFrom, dateTimeTo, this, function(code, json){
            if(code == 200) {
              
               this.fetchedResultsController = null;
               this.tableView.reloadData();
               this.showNoItemSelectedView();
               this.updateTotal();
            }
            else {
                let avc = new MUIAlertViewController();
                avc.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('AN ERROR OCURRED WHILE DOWNLOADING CASHDESKLINES','An error occurred while downloading CashDeskLines.'), MUIAlertViewStyle.Default);
                avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, null, null));
                
                this.presentViewController(avc, true);
            }
           // this.updateUI();
            //this.activityIndicator.setHidden(true);
        }, ad.managedObjectContext);
    }
        
    private showItem(){                        
        if (MIOCoreIsPhone()) return;

        let vc = new CashDeskDetailViewController("cash-desk-detail-view");
        vc.initWithResource("layout/cashdesk/CashDeskDetailView.html");    
        vc.cashDeskEntity = this.selectedItem;

        let nc = new MUINavigationController();
        nc.initWithRootViewController(vc);

        this.splitViewController.showDetailViewController(nc);                    
    }

    private showNoItemSelectedView(){        
        if (MIOCoreIsPhone() == false){
            let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("CashDesk", NoItemSelectedViewControllerType.CashDesk);
            this.splitViewController.showDetailViewController(vc);
        }
    }

    private updateDate(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;    
        
        let rh = ReportHelper.sharedInstance();
        let dayFrom = MIODateGetDateString(rh.selectedDateTimeFrom);
        let dayTo = MIODateGetDateString(rh.selectedDateTimeTo);

        if (dayFrom == dayTo) {
            this.dateLabel.text = ad.longDateFormatter.stringFromDate(rh.selectedDateTimeFrom);
        }
        else {
            this.dateLabel.text = ad.dateFormatter.stringFromDate(rh.selectedDateTimeFrom) + ' - ' + ad.dateFormatter.stringFromDate(rh.selectedDateTimeTo);
        }
    }

    private updateTotal(){    
        this.totalLabel.text = "0";

        if (this.fetchedResultsController.fetchedObjects.length == 0) return;

        let ip = MIOIndexPath.indexForRowInSection(0, 0);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as CashDeskEntity;        

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.totalLabel.text = ad.currencyFormatter.stringFromNumber(item.total);
    }
}