

class UserAccessCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private emailLabel:MUILabel = null;
    private workerLabel:MUILabel = null;
    private workerButton:MUIButton = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.emailLabel = MUIOutlet(this, "email-lbl", "MUILabel");
        this.workerLabel = MUIOutlet(this, "worker-lbl", "MUILabel");

        this.separatorStyle = MUITableViewCellSeparatorStyle.None;
    }

    set item (item:User){
        this.nameLabel.text = item.name;
        this.emailLabel.text = item.email;
        this.workerLabel.text = null;

        if (item.businessPermission.worker != null) {
            this.workerLabel.text = item.businessPermission.worker.name;
        }
    }
}