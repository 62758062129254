//
// Generated class ArchivedTicketLine
//

/// <reference path="ArchivedTicketLine_ManagedObject.ts" />

class ArchivedTicketLine extends ArchivedTicketLine_ManagedObject
{

}
