

class ClientInvoiceViewController extends MUIViewController
{    
    private tableView:MUITableView = null;    
    
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private ticketDetailVC:TicketDetailViewController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, 'cinvoicedv_tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    
        this.updateUI();
    }

    viewDidAppear(animate?){
        super.viewDidAppear(animate);
        this.updateUI();
    }

    private _client:Client = null;
    set client(value){
        this._client = value;
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this._client == null) return;
        
        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    numberOfSections(tableview:MUITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:MUITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell:ClientInvoiceCell = tableview.dequeueReusableCellWithIdentifier('ClientInvoiceCell') as ClientInvoiceCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
    
        cell.item = item;
        cell.delegate = this;
      
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let archivedDocument:ArchivedDocument = this.fetchedResultsController.objectAtIndexPath(indexPath);

        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;

        // ws.getTicketByDocumentId(ad.selectedPlace, archivedDocument.documentID, this, function(code, json, ticket:Ticket){
        //    if(code == 200) 
        //    {
        //       this.ticketDetailViewController.ticket = ticket;
        //       this.navigationController.pushViewController(this.ticketDetailViewController, true);
        //    }
        //    else{
        //        AppHelper.showErrorMessage(this, 'Error', 'Cant find ticket!');
        //    }
        // }, ad.managedObjectContext);
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('date', true)];
        let predicateFormat = 'type = "I" AND invoiceLegalEntity.identifier == ' + this._client.identifier;

        let fetchRequest = DBHelper.listFetchRequestWithEntityName('ArchivedDocument', sortDescriptors, predicateFormat);
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    get ticketDetailViewController(){
        if(!this.ticketDetailVC) 
        {
            this.ticketDetailVC  = new TicketDetailViewController('ticket-detail-view');
            this.ticketDetailVC.initWithResource('layout/tickets/TicketDetailView.html');   
        }

        return this.ticketDetailVC;
    }
}