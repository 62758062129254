

// Generated class CashDeskSession_ManagedObject

class CashDeskSession_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: appID
    set appID(value:string) {
        this.setValueForKey(value, 'appID');
    }
    get appID():string {
        return this.valueForKey('appID');
    }
    set appIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appID');
    }
    get appIDPrimitiveValue():string {
        return this.primitiveValueForKey('appID');
    }

    // Property: placeID
    set placeID(value:string) {
        this.setValueForKey(value, 'placeID');
    }
    get placeID():string {
        return this.valueForKey('placeID');
    }
    set placeIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'placeID');
    }
    get placeIDPrimitiveValue():string {
        return this.primitiveValueForKey('placeID');
    }

    // Property: beginDate
    set beginDate(value:Date) {
        this.setValueForKey(value, 'beginDate');
    }
    get beginDate():Date {
        return this.valueForKey('beginDate');
    }
    set beginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'beginDate');
    }
    get beginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('beginDate');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }

    // Property: serverSessionBeginDate
    set serverSessionBeginDate(value:Date) {
        this.setValueForKey(value, 'serverSessionBeginDate');
    }
    get serverSessionBeginDate():Date {
        return this.valueForKey('serverSessionBeginDate');
    }
    set serverSessionBeginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'serverSessionBeginDate');
    }
    get serverSessionBeginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('serverSessionBeginDate');
    }

    // Property: serverSession
    set serverSession(value:string) {
        this.setValueForKey(value, 'serverSession');
    }
    get serverSession():string {
        return this.valueForKey('serverSession');
    }
    set serverSessionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'serverSession');
    }
    get serverSessionPrimitiveValue():string {
        return this.primitiveValueForKey('serverSession');
    }

    // Property: serverSessionName
    set serverSessionName(value:string) {
        this.setValueForKey(value, 'serverSessionName');
    }
    get serverSessionName():string {
        return this.valueForKey('serverSessionName');
    }
    set serverSessionNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'serverSessionName');
    }
    get serverSessionNamePrimitiveValue():string {
        return this.primitiveValueForKey('serverSessionName');
    }

    // Property: serverSessionInterval
    set serverSessionInterval(value:string) {
        this.setValueForKey(value, 'serverSessionInterval');
    }
    get serverSessionInterval():string {
        return this.valueForKey('serverSessionInterval');
    }
    set serverSessionIntervalPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'serverSessionInterval');
    }
    get serverSessionIntervalPrimitiveValue():string {
        return this.primitiveValueForKey('serverSessionInterval');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: inBalance
    set inBalance(value:boolean) {
        this.setValueForKey(value, 'inBalance');
    }
    get inBalance():boolean {
        return this.valueForKey('inBalance');
    }
    set inBalancePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'inBalance');
    }
    get inBalancePrimitiveValue():boolean {
        return this.primitiveValueForKey('inBalance');
    }

    // Property: isBlind
    set isBlind(value:boolean) {
        this.setValueForKey(value, 'isBlind');
    }
    get isBlind():boolean {
        return this.valueForKey('isBlind');
    }
    set isBlindPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isBlind');
    }
    get isBlindPrimitiveValue():boolean {
        return this.primitiveValueForKey('isBlind');
    }

    // Property: blindClosed
    set blindClosed(value:boolean) {
        this.setValueForKey(value, 'blindClosed');
    }
    get blindClosed():boolean {
        return this.valueForKey('blindClosed');
    }
    set blindClosedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'blindClosed');
    }
    get blindClosedPrimitiveValue():boolean {
        return this.primitiveValueForKey('blindClosed');
    }

    // Property: isOpen
    set isOpen(value:boolean) {
        this.setValueForKey(value, 'isOpen');
    }
    get isOpen():boolean {
        return this.valueForKey('isOpen');
    }
    set isOpenPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isOpen');
    }
    get isOpenPrimitiveValue():boolean {
        return this.primitiveValueForKey('isOpen');
    }

    // Property: isBackup
    set isBackup(value:boolean) {
        this.setValueForKey(value, 'isBackup');
    }
    get isBackup():boolean {
        return this.valueForKey('isBackup');
    }
    set isBackupPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isBackup');
    }
    get isBackupPrimitiveValue():boolean {
        return this.primitiveValueForKey('isBackup');
    }

    // Property: isCashCounted
    set isCashCounted(value:boolean) {
        this.setValueForKey(value, 'isCashCounted');
    }
    get isCashCounted():boolean {
        return this.valueForKey('isCashCounted');
    }
    set isCashCountedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isCashCounted');
    }
    get isCashCountedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isCashCounted');
    }

    // Property: maxDocumentID
    set maxDocumentID(value:string) {
        this.setValueForKey(value, 'maxDocumentID');
    }
    get maxDocumentID():string {
        return this.valueForKey('maxDocumentID');
    }
    set maxDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'maxDocumentID');
    }
    get maxDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('maxDocumentID');
    }

    // Property: minDocumentID
    set minDocumentID(value:string) {
        this.setValueForKey(value, 'minDocumentID');
    }
    get minDocumentID():string {
        return this.valueForKey('minDocumentID');
    }
    set minDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'minDocumentID');
    }
    get minDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('minDocumentID');
    }

    // Property: numDocuments
    set numDocuments(value:string) {
        this.setValueForKey(value, 'numDocuments');
    }
    get numDocuments():string {
        return this.valueForKey('numDocuments');
    }
    set numDocumentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'numDocuments');
    }
    get numDocumentsPrimitiveValue():string {
        return this.primitiveValueForKey('numDocuments');
    }

    // Property: pax
    set pax(value:number) {
        this.setValueForKey(value, 'pax');
    }
    get pax():number {
        return this.valueForKey('pax');
    }
    set paxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'pax');
    }
    get paxPrimitiveValue():number {
        return this.primitiveValueForKey('pax');
    }

    // Property: totalSales
    set totalSales(value:number) {
        this.setValueForKey(value, 'totalSales');
    }
    get totalSales():number {
        return this.valueForKey('totalSales');
    }
    set totalSalesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalSales');
    }
    get totalSalesPrimitiveValue():number {
        return this.primitiveValueForKey('totalSales');
    }

    // Property: beginWorkerName
    set beginWorkerName(value:string) {
        this.setValueForKey(value, 'beginWorkerName');
    }
    get beginWorkerName():string {
        return this.valueForKey('beginWorkerName');
    }
    set beginWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'beginWorkerName');
    }
    get beginWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('beginWorkerName');
    }

    // Property: endWorkerName
    set endWorkerName(value:string) {
        this.setValueForKey(value, 'endWorkerName');
    }
    get endWorkerName():string {
        return this.valueForKey('endWorkerName');
    }
    set endWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'endWorkerName');
    }
    get endWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('endWorkerName');
    }

    // Property: appInfo
    set appInfo(value:string) {
        this.setValueForKey(value, 'appInfo');
    }
    get appInfo():string {
        return this.valueForKey('appInfo');
    }
    set appInfoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appInfo');
    }
    get appInfoPrimitiveValue():string {
        return this.primitiveValueForKey('appInfo');
    }

    // Property: cashdeskInfo
    set cashdeskInfo(value:string) {
        this.setValueForKey(value, 'cashdeskInfo');
    }
    get cashdeskInfo():string {
        return this.valueForKey('cashdeskInfo');
    }
    set cashdeskInfoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'cashdeskInfo');
    }
    get cashdeskInfoPrimitiveValue():string {
        return this.primitiveValueForKey('cashdeskInfo');
    }

    // Property: isManager
    set isManager(value:boolean) {
        this.setValueForKey(value, 'isManager');
    }
    get isManager():boolean {
        return this.valueForKey('isManager');
    }
    set isManagerPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isManager');
    }
    get isManagerPrimitiveValue():boolean {
        return this.primitiveValueForKey('isManager');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: beginWorker
    set beginWorker(value:Employee) {
        this.setValueForKey(value, 'beginWorker');
    }
    get beginWorker():Employee {
        return this.valueForKey('beginWorker') as Employee;
    }
    // Relationship: endWorker
    set endWorker(value:Employee) {
        this.setValueForKey(value, 'endWorker');
    }
    get endWorker():Employee {
        return this.valueForKey('endWorker') as Employee;
    }
    // Relationship: cashDesk
    set cashDesk(value:CashDesk) {
        this.setValueForKey(value, 'cashDesk');
    }
    get cashDesk():CashDesk {
        return this.valueForKey('cashDesk') as CashDesk;
    }
    // Relationship: sessionBalance
    set sessionBalance(value:CashdeskSessionBalance) {
        this.setValueForKey(value, 'sessionBalance');
    }
    get sessionBalance():CashdeskSessionBalance {
        return this.valueForKey('sessionBalance') as CashdeskSessionBalance;
    }
}
