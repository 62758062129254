//
// Generated class Category
//

/// <reference path="Category_ManagedObject.ts" />

class Category extends Category_ManagedObject
{
    
    // get imageUrl()
    // {
    //     let url = '';
    //     if(this.image) {
    //         let image = this.image;
    //         url = image['url'];
    //     } 
    //     return url;
    // }

    // get imageId()
    // {
    //     let id = null;
    //     if(this.image) {
    //         let image = this.image;
    //         id = image['id'];
    //     }
    //     return id;
    // }
}
