

class TimeRangeCell extends UITableViewCell
{
    //private groupTextField:MUITextField = null;
    private startTimeLabel:MUILabel = null;
    public maxPaxTextField:MUITextField = null;
    private maxOrdersTextField:MUITextField = null;

    private nf = MUIWebApplication.sharedInstance().delegate.integerFormatter;

    awakeFromHTML(){
        // this.groupTextField = MUIOutlet(this, "group-tf", "MUITextField");
        // this.groupTextField.setOnChangeText(this, function(control, value){
        //     this.item.group = value;
        // });

        this.startTimeLabel = MUIOutlet(this, "hour-lbl", "MUILabel");

        this.maxPaxTextField = MUIOutlet(this, "pax-tf", "MUITextField");
        this.maxPaxTextField.formatter = this.nf;
        this.maxPaxTextField.setOnChangeText(this, function(control, value){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            this._item.maxPax = ad.numberFormatter.numberFromString(value);
        });

        this.maxOrdersTextField = MUIOutlet(this, "orders-tf", "MUITextField");
        this.maxOrdersTextField.formatter = this.nf;
        this.maxOrdersTextField.setOnChangeText(this, function(control, value){
            this._item.maxOrder = value;
        });

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:TimeRange = null;
    set item(item:TimeRange){
        this._item = item;
        this.startTimeLabel.text = item.hour;
        this.maxPaxTextField.text = item.maxPax;
        this.maxOrdersTextField.text = item.maxOrder;
    }

    // get item () {return this._item;}
}