

// Generated class DebtLine_ManagedObject

class DebtLine_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: creationDate
    set creationDate(value:Date) {
        this.setValueForKey(value, 'creationDate');
    }
    get creationDate():Date {
        return this.valueForKey('creationDate');
    }
    set creationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'creationDate');
    }
    get creationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('creationDate');
    }

    // Property: documentID
    set documentID(value:string) {
        this.setValueForKey(value, 'documentID');
    }
    get documentID():string {
        return this.valueForKey('documentID');
    }
    set documentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentID');
    }
    get documentIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentID');
    }

    // Property: legalDocumentID
    set legalDocumentID(value:string) {
        this.setValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentID():string {
        return this.valueForKey('legalDocumentID');
    }
    set legalDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('legalDocumentID');
    }

    // Property: debtValue
    set debtValue(value:number) {
        this.setValueForKey(value, 'debtValue');
    }
    get debtValue():number {
        return this.valueForKey('debtValue');
    }
    set debtValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'debtValue');
    }
    get debtValuePrimitiveValue():number {
        return this.primitiveValueForKey('debtValue');
    }

    // Property: payedValue
    set payedValue(value:number) {
        this.setValueForKey(value, 'payedValue');
    }
    get payedValue():number {
        return this.valueForKey('payedValue');
    }
    set payedValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'payedValue');
    }
    get payedValuePrimitiveValue():number {
        return this.primitiveValueForKey('payedValue');
    }

    // Property: payedDate
    set payedDate(value:Date) {
        this.setValueForKey(value, 'payedDate');
    }
    get payedDate():Date {
        return this.valueForKey('payedDate');
    }
    set payedDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'payedDate');
    }
    get payedDatePrimitiveValue():Date {
        return this.primitiveValueForKey('payedDate');
    }

    // Property: isDiscarded
    set isDiscarded(value:boolean) {
        this.setValueForKey(value, 'isDiscarded');
    }
    get isDiscarded():boolean {
        return this.valueForKey('isDiscarded');
    }
    set isDiscardedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDiscarded');
    }
    get isDiscardedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDiscarded');
    }

    // Property: tipModifierString
    set tipModifierString(value:string) {
        this.setValueForKey(value, 'tipModifierString');
    }
    get tipModifierString():string {
        return this.valueForKey('tipModifierString');
    }
    set tipModifierStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tipModifierString');
    }
    get tipModifierStringPrimitiveValue():string {
        return this.primitiveValueForKey('tipModifierString');
    }
    // Relationship: tip
    set tip(value:Tip) {
        this.setValueForKey(value, 'tip');
    }
    get tip():Tip {
        return this.valueForKey('tip') as Tip;
    }
    // Relationship: economicEntity
    set economicEntity(value:EconomicEntity) {
        this.setValueForKey(value, 'economicEntity');
    }
    get economicEntity():EconomicEntity {
        return this.valueForKey('economicEntity') as EconomicEntity;
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
