/**
 * Created by miguel on 10/2/17.
 */



/// <reference path="AddNewProductModifierViewController.ts" />
/// <reference path="AddNewProductSourceViewController.ts" />
/// <reference path="AddNewProductUnitViewController.ts" />
/// <reference path="../../model/Product.ts" />


class AddNewProductViewController extends MUIViewController
{
    private closeButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private addAnotherButton:MUIButton = null;
    private categoryNameLabel:MUILabel = null;
    private nameTextField:MUITextField = null;
    private priceTextField:MUITextField = null;
    private barcodeTextField:MUITextField = null;

    private segmentedControl:MUISegmentedControl = null;
    private pageController:MUIPageController = null;

    private _product:Product = null;

    private modifiersvc:AddNewProductModifierViewController = null;
    private destvc:AddNewProductSourceViewController = null;
    private unitsvc:AddNewProductUnitViewController = null;

    private _category:Category = null;
    private spinning:MUIActivityIndicatorView = null;

    private nf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    private moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

    get preferredContentSize()
    {
        return new MIOSize(500, 500);
    }

    viewDidLoad()
    {
        super.viewDidLoad();

        this.spinning = MUIOutlet(this,'anpv_spinning','MUIActivityIndicatorView');
        this.categoryNameLabel = MUIOutlet(this, 'anpv_cb_category_sync','MUILabel');
        this.nameTextField = MUIOutlet(this, 'anpv_pb_product_text', 'MUITextField');
        this.barcodeTextField = MUIOutlet(this, 'anpv_pb_barcode_text', 'MUITextField');
        this.priceTextField = MUIOutlet(this, 'anpv_pb_price_text', 'MUITextField');
        this.priceTextField.formatter = this.nf;

        this.closeButton = MUIOutlet(this, 'anpv_nb_cancel_btn', 'MUIButton');
        this.closeButton.setAction(this, function ()
        {
            if(this._product != null) {
                this.moc.deleteObject(this._product);
                this.moc.save();
            }
            this.dismissViewController(true);
        });

        this.addAnotherButton = MUIOutlet(this, 'anpv_nb_add_another_btn', 'MUIButton');
        this.addAnotherButton.setAction(this, function ()
        {
            this._product.name = this.nameTextField.text;
            this._product.price = this.nf.numberFromString(this.priceTextField.text);
            this._product.barcode = this.barcodeTextField.text;

            this.createProduct(true);
        });

        this.saveButton = MUIOutlet(this, 'anpv_nb_save_btn', 'MUIButton');
        this.saveButton.setAction(this, function ()
        {
            this._product.name = this.nameTextField.text;
            this._product.price =  this.nf.numberFromString(this.priceTextField.text);
            this._product.barcode = this.barcodeTextField.text;
            
            this.createProduct();
        });

        this.segmentedControl = MUIOutlet(this, "anpv_segmented_control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function (control, index)
        {
            this.pageController.showPageAtIndex(index);
        });

        //SEGMENTED BUTTON
        this.pageController = MUIOutlet(this, "anppage_controller", "MUIPageController");

        this.modifiersvc = new AddNewProductModifierViewController("add-new-product-modifier-view");
        this.modifiersvc.initWithResource("layout/products/AddNewProductModifierView.html");
        this.pageController.addPageViewController(this.modifiersvc);

        this.destvc = new AddNewProductSourceViewController("add-new-product-source-view");
        this.destvc.initWithResource("layout/products/AddNewProductSourceView.html");
        this.pageController.addPageViewController(this.destvc);

        this.unitsvc = new AddNewProductUnitViewController("add-new-product-unit-view");
        this.unitsvc.initWithResource("layout/products/AddNewProductUnitView.html");
        this.pageController.addPageViewController(this.unitsvc);
        // END SEGMENTED BUTTON
    }

    viewDidAppear(animate?)
    {
        super.viewDidAppear(animate);
        this.clearFields();
    }
 
    private clearFields()
    {
        let moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        this._product = MIOEntityDescription.insertNewObjectForEntityForName("Product", moc) as Product;
        this._product.identifier = MIOUUID.UUID().UUIDString;
        this._product.category = this._category;
        this._product.name = null;
        this._product.price = null;
        this._product.barcode = null;
        
        this._product.source = null;
        this._product.measureUnitType = MeasureUnitType.Unit;
        this._product.quantity = null;

        this.categoryNameLabel.text = this._category.name;

        this.nameTextField.text = null;
        this.barcodeTextField.text = null;
        this.priceTextField.text = null;

        this.modifiersvc.product = this._product;
        this.destvc.product = this._product;
        this.unitsvc.product = this._product;
        
        this.segmentedControl.selectSegmentedAtIndex(0);
        this.pageController.showPageAtIndex(0);
    }

    
    private copyProduct()
    {
         let moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
         let product = MIOEntityDescription.insertNewObjectForEntityForName("Product", moc) as Product;
         product.identifier = MIOUUID.UUID().UUIDString;

         this.nameTextField.text = null;
         this.barcodeTextField.text = null;
         this.priceTextField.text = null;

         product.name = null;
         product.price = null;
         product.barcode = null;
         product.category = this._category;

         for(var i = 0 ; i < this._product.productModifiersCategories.length; i++)
            product.addProductModifiersCategoriesObject( this._product.productModifiersCategories.objectAtIndex(i));

         product.source = this._product.source;
         product.measureUnitType = this._product.measureUnitType;
         
         //this.unitsvc.clearQuantity();
         product.quantity = null;

         this.segmentedControl.selectSegmentedAtIndex(0);
         this.pageController.showPageAtIndex(0);
         
         this._product = product;
         this.modifiersvc.product = this._product;
         this.unitsvc.product = this._product;
         this.destvc.product = this._product;
    }

    set category(value)
    {
        this._category = value;
    }

    private createProduct(addAnother = false)
    {
        let name = this._product.name;
        let errorString = null;

        if(!name || name.length <= 0 ) {
            errorString = 'Please, check product name.';
        }
        else if(this._product.price == null || this._product.price < 0)
        {
            errorString = 'Please, check product price.';
        }
        if(errorString != null)
        {
            AppHelper.showErrorMessage(this, 'Error', errorString);
            return;
        }
         
        this.save(addAnother);
       
    }

    private save(addAnother = false)
    {
        this.moc.save();
        
        if(!addAnother){
            MIONotificationCenter.defaultCenter().postNotification('ItemDidChange', null);
            this.dismissViewController(true);
        }
        else   {
            this.copyProduct();
        }
    }
}