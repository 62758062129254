

// Generated class Booking_ManagedObject

class Booking_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: owner
    set owner(value:string) {
        this.setValueForKey(value, 'owner');
    }
    get owner():string {
        return this.valueForKey('owner');
    }
    set ownerPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'owner');
    }
    get ownerPrimitiveValue():string {
        return this.primitiveValueForKey('owner');
    }

    // Property: day
    set day(value:string) {
        this.setValueForKey(value, 'day');
    }
    get day():string {
        return this.valueForKey('day');
    }
    set dayPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'day');
    }
    get dayPrimitiveValue():string {
        return this.primitiveValueForKey('day');
    }

    // Property: hour
    set hour(value:string) {
        this.setValueForKey(value, 'hour');
    }
    get hour():string {
        return this.valueForKey('hour');
    }
    set hourPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'hour');
    }
    get hourPrimitiveValue():string {
        return this.primitiveValueForKey('hour');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: dateShow
    set dateShow(value:Date) {
        this.setValueForKey(value, 'dateShow');
    }
    get dateShow():Date {
        return this.valueForKey('dateShow');
    }
    set dateShowPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'dateShow');
    }
    get dateShowPrimitiveValue():Date {
        return this.primitiveValueForKey('dateShow');
    }

    // Property: externalReference
    set externalReference(value:string) {
        this.setValueForKey(value, 'externalReference');
    }
    get externalReference():string {
        return this.valueForKey('externalReference');
    }
    set externalReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalReference');
    }
    get externalReferencePrimitiveValue():string {
        return this.primitiveValueForKey('externalReference');
    }

    // Property: orderId
    set orderId(value:string) {
        this.setValueForKey(value, 'orderId');
    }
    get orderId():string {
        return this.valueForKey('orderId');
    }
    set orderIdPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderId');
    }
    get orderIdPrimitiveValue():string {
        return this.primitiveValueForKey('orderId');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: isWalkin
    set isWalkin(value:boolean) {
        this.setValueForKey(value, 'isWalkin');
    }
    get isWalkin():boolean {
        return this.valueForKey('isWalkin');
    }
    set isWalkinPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isWalkin');
    }
    get isWalkinPrimitiveValue():boolean {
        return this.primitiveValueForKey('isWalkin');
    }

    // Property: showTaxi
    set showTaxi(value:boolean) {
        this.setValueForKey(value, 'showTaxi');
    }
    get showTaxi():boolean {
        return this.valueForKey('showTaxi');
    }
    set showTaxiPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showTaxi');
    }
    get showTaxiPrimitiveValue():boolean {
        return this.primitiveValueForKey('showTaxi');
    }

    // Property: duration
    set duration(value:number) {
        this.setValueForKey(value, 'duration');
    }
    get duration():number {
        return this.valueForKey('duration');
    }
    set durationPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'duration');
    }
    get durationPrimitiveValue():number {
        return this.primitiveValueForKey('duration');
    }

    // Property: createDate
    set createDate(value:Date) {
        this.setValueForKey(value, 'createDate');
    }
    get createDate():Date {
        return this.valueForKey('createDate');
    }
    set createDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createDate');
    }
    get createDatePrimitiveValue():Date {
        return this.primitiveValueForKey('createDate');
    }

    // Property: cancelledDate
    set cancelledDate(value:Date) {
        this.setValueForKey(value, 'cancelledDate');
    }
    get cancelledDate():Date {
        return this.valueForKey('cancelledDate');
    }
    set cancelledDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'cancelledDate');
    }
    get cancelledDatePrimitiveValue():Date {
        return this.primitiveValueForKey('cancelledDate');
    }

    // Property: lastUpdateDate
    set lastUpdateDate(value:Date) {
        this.setValueForKey(value, 'lastUpdateDate');
    }
    get lastUpdateDate():Date {
        return this.valueForKey('lastUpdateDate');
    }
    set lastUpdateDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'lastUpdateDate');
    }
    get lastUpdateDatePrimitiveValue():Date {
        return this.primitiveValueForKey('lastUpdateDate');
    }

    // Property: confirmationEmail
    set confirmationEmail(value:number) {
        this.setValueForKey(value, 'confirmationEmail');
    }
    get confirmationEmail():number {
        return this.valueForKey('confirmationEmail');
    }
    set confirmationEmailPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'confirmationEmail');
    }
    get confirmationEmailPrimitiveValue():number {
        return this.primitiveValueForKey('confirmationEmail');
    }

    // Property: preferedDays
    set preferedDays(value:string) {
        this.setValueForKey(value, 'preferedDays');
    }
    get preferedDays():string {
        return this.valueForKey('preferedDays');
    }
    set preferedDaysPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'preferedDays');
    }
    get preferedDaysPrimitiveValue():string {
        return this.primitiveValueForKey('preferedDays');
    }

    // Property: clientID
    set clientID(value:string) {
        this.setValueForKey(value, 'clientID');
    }
    get clientID():string {
        return this.valueForKey('clientID');
    }
    set clientIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientID');
    }
    get clientIDPrimitiveValue():string {
        return this.primitiveValueForKey('clientID');
    }

    // Property: clientName
    set clientName(value:string) {
        this.setValueForKey(value, 'clientName');
    }
    get clientName():string {
        return this.valueForKey('clientName');
    }
    set clientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientName');
    }
    get clientNamePrimitiveValue():string {
        return this.primitiveValueForKey('clientName');
    }

    // Property: clientEmail
    set clientEmail(value:string) {
        this.setValueForKey(value, 'clientEmail');
    }
    get clientEmail():string {
        return this.valueForKey('clientEmail');
    }
    set clientEmailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientEmail');
    }
    get clientEmailPrimitiveValue():string {
        return this.primitiveValueForKey('clientEmail');
    }

    // Property: clientCity
    set clientCity(value:string) {
        this.setValueForKey(value, 'clientCity');
    }
    get clientCity():string {
        return this.valueForKey('clientCity');
    }
    set clientCityPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientCity');
    }
    get clientCityPrimitiveValue():string {
        return this.primitiveValueForKey('clientCity');
    }

    // Property: clientCountry
    set clientCountry(value:number) {
        this.setValueForKey(value, 'clientCountry');
    }
    get clientCountry():number {
        return this.valueForKey('clientCountry');
    }
    set clientCountryPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'clientCountry');
    }
    get clientCountryPrimitiveValue():number {
        return this.primitiveValueForKey('clientCountry');
    }

    // Property: clientPhone
    set clientPhone(value:string) {
        this.setValueForKey(value, 'clientPhone');
    }
    get clientPhone():string {
        return this.valueForKey('clientPhone');
    }
    set clientPhonePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientPhone');
    }
    get clientPhonePrimitiveValue():string {
        return this.primitiveValueForKey('clientPhone');
    }

    // Property: clientPhone2
    set clientPhone2(value:string) {
        this.setValueForKey(value, 'clientPhone2');
    }
    get clientPhone2():string {
        return this.valueForKey('clientPhone2');
    }
    set clientPhone2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientPhone2');
    }
    get clientPhone2PrimitiveValue():string {
        return this.primitiveValueForKey('clientPhone2');
    }

    // Property: clientPostalCode
    set clientPostalCode(value:string) {
        this.setValueForKey(value, 'clientPostalCode');
    }
    get clientPostalCode():string {
        return this.valueForKey('clientPostalCode');
    }
    set clientPostalCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientPostalCode');
    }
    get clientPostalCodePrimitiveValue():string {
        return this.primitiveValueForKey('clientPostalCode');
    }

    // Property: pax
    set pax(value:number) {
        this.setValueForKey(value, 'pax');
    }
    get pax():number {
        return this.valueForKey('pax');
    }
    set paxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'pax');
    }
    get paxPrimitiveValue():number {
        return this.primitiveValueForKey('pax');
    }

    // Property: paxAttend
    set paxAttend(value:number) {
        this.setValueForKey(value, 'paxAttend');
    }
    get paxAttend():number {
        return this.valueForKey('paxAttend');
    }
    set paxAttendPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'paxAttend');
    }
    get paxAttendPrimitiveValue():number {
        return this.primitiveValueForKey('paxAttend');
    }

    // Property: paxInvited
    set paxInvited(value:number) {
        this.setValueForKey(value, 'paxInvited');
    }
    get paxInvited():number {
        return this.valueForKey('paxInvited');
    }
    set paxInvitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'paxInvited');
    }
    get paxInvitedPrimitiveValue():number {
        return this.primitiveValueForKey('paxInvited');
    }

    // Property: information
    set information(value:string) {
        this.setValueForKey(value, 'information');
    }
    get information():string {
        return this.valueForKey('information');
    }
    set informationPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'information');
    }
    get informationPrimitiveValue():string {
        return this.primitiveValueForKey('information');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: clientComments
    set clientComments(value:string) {
        this.setValueForKey(value, 'clientComments');
    }
    get clientComments():string {
        return this.valueForKey('clientComments');
    }
    set clientCommentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientComments');
    }
    get clientCommentsPrimitiveValue():string {
        return this.primitiveValueForKey('clientComments');
    }

    // Property: privateComments
    set privateComments(value:string) {
        this.setValueForKey(value, 'privateComments');
    }
    get privateComments():string {
        return this.valueForKey('privateComments');
    }
    set privateCommentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'privateComments');
    }
    get privateCommentsPrimitiveValue():string {
        return this.primitiveValueForKey('privateComments');
    }

    // Property: allergens
    set allergens(value:string) {
        this.setValueForKey(value, 'allergens');
    }
    get allergens():string {
        return this.valueForKey('allergens');
    }
    set allergensPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'allergens');
    }
    get allergensPrimitiveValue():string {
        return this.primitiveValueForKey('allergens');
    }

    // Property: allergies
    set allergies(value:boolean) {
        this.setValueForKey(value, 'allergies');
    }
    get allergies():boolean {
        return this.valueForKey('allergies');
    }
    set allergiesPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'allergies');
    }
    get allergiesPrimitiveValue():boolean {
        return this.primitiveValueForKey('allergies');
    }

    // Property: language
    set language(value:number) {
        this.setValueForKey(value, 'language');
    }
    get language():number {
        return this.valueForKey('language');
    }
    set languagePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'language');
    }
    get languagePrimitiveValue():number {
        return this.primitiveValueForKey('language');
    }

    // Property: spokenLanguages
    set spokenLanguages(value:string) {
        this.setValueForKey(value, 'spokenLanguages');
    }
    get spokenLanguages():string {
        return this.valueForKey('spokenLanguages');
    }
    set spokenLanguagesPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'spokenLanguages');
    }
    get spokenLanguagesPrimitiveValue():string {
        return this.primitiveValueForKey('spokenLanguages');
    }

    // Property: payorderId
    set payorderId(value:string) {
        this.setValueForKey(value, 'payorderId');
    }
    get payorderId():string {
        return this.valueForKey('payorderId');
    }
    set payorderIdPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payorderId');
    }
    get payorderIdPrimitiveValue():string {
        return this.primitiveValueForKey('payorderId');
    }

    // Property: calculatedPrice
    set calculatedPrice(value:number) {
        this.setValueForKey(value, 'calculatedPrice');
    }
    get calculatedPrice():number {
        return this.valueForKey('calculatedPrice');
    }
    set calculatedPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'calculatedPrice');
    }
    get calculatedPricePrimitiveValue():number {
        return this.primitiveValueForKey('calculatedPrice');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: calculatedMinSpend
    set calculatedMinSpend(value:number) {
        this.setValueForKey(value, 'calculatedMinSpend');
    }
    get calculatedMinSpend():number {
        return this.valueForKey('calculatedMinSpend');
    }
    set calculatedMinSpendPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'calculatedMinSpend');
    }
    get calculatedMinSpendPrimitiveValue():number {
        return this.primitiveValueForKey('calculatedMinSpend');
    }

    // Property: minSpend
    set minSpend(value:number) {
        this.setValueForKey(value, 'minSpend');
    }
    get minSpend():number {
        return this.valueForKey('minSpend');
    }
    set minSpendPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minSpend');
    }
    get minSpendPrimitiveValue():number {
        return this.primitiveValueForKey('minSpend');
    }

    // Property: ammountToPay
    set ammountToPay(value:string) {
        this.setValueForKey(value, 'ammountToPay');
    }
    get ammountToPay():string {
        return this.valueForKey('ammountToPay');
    }
    set ammountToPayPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ammountToPay');
    }
    get ammountToPayPrimitiveValue():string {
        return this.primitiveValueForKey('ammountToPay');
    }

    // Property: totalAmmountToPay
    set totalAmmountToPay(value:number) {
        this.setValueForKey(value, 'totalAmmountToPay');
    }
    get totalAmmountToPay():number {
        return this.valueForKey('totalAmmountToPay');
    }
    set totalAmmountToPayPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalAmmountToPay');
    }
    get totalAmmountToPayPrimitiveValue():number {
        return this.primitiveValueForKey('totalAmmountToPay');
    }

    // Property: deposit
    set deposit(value:number) {
        this.setValueForKey(value, 'deposit');
    }
    get deposit():number {
        return this.valueForKey('deposit');
    }
    set depositPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'deposit');
    }
    get depositPrimitiveValue():number {
        return this.primitiveValueForKey('deposit');
    }

    // Property: penalizedImport
    set penalizedImport(value:number) {
        this.setValueForKey(value, 'penalizedImport');
    }
    get penalizedImport():number {
        return this.valueForKey('penalizedImport');
    }
    set penalizedImportPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'penalizedImport');
    }
    get penalizedImportPrimitiveValue():number {
        return this.primitiveValueForKey('penalizedImport');
    }

    // Property: isPayed
    set isPayed(value:boolean) {
        this.setValueForKey(value, 'isPayed');
    }
    get isPayed():boolean {
        return this.valueForKey('isPayed');
    }
    set isPayedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isPayed');
    }
    get isPayedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isPayed');
    }

    // Property: hasPaymentToken
    set hasPaymentToken(value:boolean) {
        this.setValueForKey(value, 'hasPaymentToken');
    }
    get hasPaymentToken():boolean {
        return this.valueForKey('hasPaymentToken');
    }
    set hasPaymentTokenPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'hasPaymentToken');
    }
    get hasPaymentTokenPrimitiveValue():boolean {
        return this.primitiveValueForKey('hasPaymentToken');
    }

    // Property: sourceID
    set sourceID(value:string) {
        this.setValueForKey(value, 'sourceID');
    }
    get sourceID():string {
        return this.valueForKey('sourceID');
    }
    set sourceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceID');
    }
    get sourceIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourceID');
    }

    // Property: sourceName
    set sourceName(value:string) {
        this.setValueForKey(value, 'sourceName');
    }
    get sourceName():string {
        return this.valueForKey('sourceName');
    }
    set sourceNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceName');
    }
    get sourceNamePrimitiveValue():string {
        return this.primitiveValueForKey('sourceName');
    }

    // Property: sourceEmail
    set sourceEmail(value:string) {
        this.setValueForKey(value, 'sourceEmail');
    }
    get sourceEmail():string {
        return this.valueForKey('sourceEmail');
    }
    set sourceEmailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceEmail');
    }
    get sourceEmailPrimitiveValue():string {
        return this.primitiveValueForKey('sourceEmail');
    }

    // Property: sourcePhone
    set sourcePhone(value:string) {
        this.setValueForKey(value, 'sourcePhone');
    }
    get sourcePhone():string {
        return this.valueForKey('sourcePhone');
    }
    set sourcePhonePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourcePhone');
    }
    get sourcePhonePrimitiveValue():string {
        return this.primitiveValueForKey('sourcePhone');
    }

    // Property: channelID
    set channelID(value:string) {
        this.setValueForKey(value, 'channelID');
    }
    get channelID():string {
        return this.valueForKey('channelID');
    }
    set channelIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'channelID');
    }
    get channelIDPrimitiveValue():string {
        return this.primitiveValueForKey('channelID');
    }

    // Property: channelName
    set channelName(value:string) {
        this.setValueForKey(value, 'channelName');
    }
    get channelName():string {
        return this.valueForKey('channelName');
    }
    set channelNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'channelName');
    }
    get channelNamePrimitiveValue():string {
        return this.primitiveValueForKey('channelName');
    }

    // Property: prescriptorID
    set prescriptorID(value:string) {
        this.setValueForKey(value, 'prescriptorID');
    }
    get prescriptorID():string {
        return this.valueForKey('prescriptorID');
    }
    set prescriptorIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'prescriptorID');
    }
    get prescriptorIDPrimitiveValue():string {
        return this.primitiveValueForKey('prescriptorID');
    }

    // Property: prescriptorName
    set prescriptorName(value:string) {
        this.setValueForKey(value, 'prescriptorName');
    }
    get prescriptorName():string {
        return this.valueForKey('prescriptorName');
    }
    set prescriptorNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'prescriptorName');
    }
    get prescriptorNamePrimitiveValue():string {
        return this.primitiveValueForKey('prescriptorName');
    }

    // Property: recommendationID
    set recommendationID(value:string) {
        this.setValueForKey(value, 'recommendationID');
    }
    get recommendationID():string {
        return this.valueForKey('recommendationID');
    }
    set recommendationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'recommendationID');
    }
    get recommendationIDPrimitiveValue():string {
        return this.primitiveValueForKey('recommendationID');
    }

    // Property: zoneID
    set zoneID(value:string) {
        this.setValueForKey(value, 'zoneID');
    }
    get zoneID():string {
        return this.valueForKey('zoneID');
    }
    set zoneIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'zoneID');
    }
    get zoneIDPrimitiveValue():string {
        return this.primitiveValueForKey('zoneID');
    }

    // Property: zoneName
    set zoneName(value:string) {
        this.setValueForKey(value, 'zoneName');
    }
    get zoneName():string {
        return this.valueForKey('zoneName');
    }
    set zoneNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'zoneName');
    }
    get zoneNamePrimitiveValue():string {
        return this.primitiveValueForKey('zoneName');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }

    // Relationship: locations
    protected _locations:MIOManagedObjectSet = null;
    get locations():MIOManagedObjectSet {
        return this.valueForKey('locations');
    }
    addLocationsObject(value:Location) {
        this._addObjectForKey(value, 'locations');
    }
    removeLocationsObject(value:Location) {
        this._removeObjectForKey(value, 'locations');
    }
    // Relationship: locationType
    set locationType(value:BookingLocationType) {
        this.setValueForKey(value, 'locationType');
    }
    get locationType():BookingLocationType {
        return this.valueForKey('locationType') as BookingLocationType;
    }
    // Relationship: group
    set group(value:BookingGroup) {
        this.setValueForKey(value, 'group');
    }
    get group():BookingGroup {
        return this.valueForKey('group') as BookingGroup;
    }
    // Relationship: client
    set client(value:Client) {
        this.setValueForKey(value, 'client');
    }
    get client():Client {
        return this.valueForKey('client') as Client;
    }
    // Relationship: bookingSource
    set bookingSource(value:BookingSource) {
        this.setValueForKey(value, 'bookingSource');
    }
    get bookingSource():BookingSource {
        return this.valueForKey('bookingSource') as BookingSource;
    }
    // Relationship: bookingChannel
    set bookingChannel(value:BookingChannel) {
        this.setValueForKey(value, 'bookingChannel');
    }
    get bookingChannel():BookingChannel {
        return this.valueForKey('bookingChannel') as BookingChannel;
    }
    // Relationship: bookingPrescriptor
    set bookingPrescriptor(value:BookingPrescriptor) {
        this.setValueForKey(value, 'bookingPrescriptor');
    }
    get bookingPrescriptor():BookingPrescriptor {
        return this.valueForKey('bookingPrescriptor') as BookingPrescriptor;
    }
    // Relationship: bookingRecommendation
    set bookingRecommendation(value:BookingRecommendation) {
        this.setValueForKey(value, 'bookingRecommendation');
    }
    get bookingRecommendation():BookingRecommendation {
        return this.valueForKey('bookingRecommendation') as BookingRecommendation;
    }
    // Relationship: bookingZone
    set bookingZone(value:BookingZone) {
        this.setValueForKey(value, 'bookingZone');
    }
    get bookingZone():BookingZone {
        return this.valueForKey('bookingZone') as BookingZone;
    }
}
