/**
 * Created by godshadow on 04/08/16.
 */

/// <reference path="../../AppHelper.ts" />

class SourceListViewController extends BaseTableViewController
{
    private titleLabel:MUILabel = null;
    private addButton:MUIButton = null;    

    private sdvc = null;
    private savc = null;
    private _source:BookingSource = null;
    private noDetailViewController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.titleLabel = MUIOutlet(this,"title-lbl", "MUILabel");
        this.titleLabel.text = MIOLocalizeString("SOURCES", "Sources");

        this.addButton = MUIOutlet(this,"slv_add_button", "MUIButton");
        this.addButton.setAction(this, function(){
            this.addBookingSource();
        });

        this.searchTextField = MUIOutlet(this,"slv_searchfield", "MUITextField", "SearchType");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"slv_tableview", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("SourceCell") as BookingSourceCell;
        
        let s = this.fetchedResultsController.objectAtIndexPath(indexPath) as BookingSource;
        cell.item = s;

        if(this._source && this._source.identifier == s.identifier){
            cell.setSelected(true);
        }
        else {
            cell.setSelected(false);
        }

        return cell;
    }

    heightForRowAtIndexPath(tableview, index, section){
        return 40;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        this._source = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showCurrentItem();
    }

    private showCurrentItem(){
        if(!this.sdvc) {
            this.sdvc = new SourceDetailViewController('source-detai-view');
            this.sdvc.initWithResource('layout/bookings/source/SourceDetailView.html');
        }

        this.sdvc.source = this._source;
        this.splitViewController.showDetailViewController(this.sdvc);        
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("BookingSource", NoItemSelectedViewControllerType.BookingSource)        
        this.splitViewController.showDetailViewController(vc);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        // Setup data
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];

        let predicateFormat = "type == " + BookingSourceType.Booking;
        if (this.searchString != null)
            predicateFormat += ' AND name CONTAINS "' + this.searchString + '"';

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingSource", sortDescriptors, predicateFormat);    
        this._fetchedResultsController = new MIOFetchedResultsController();
        this._fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        this._fetchedResultsController.delegate = this;

        this._fetchedResultsController.performFetch();
        return this._fetchedResultsController;
    }

    private addBookingSource(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('BOOKING SOURCE','Booking Source'), MIOLocalizeString('CHOOSE A NAME','Choose a name '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Name");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();
            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

                this._source = MIOEntityDescription.insertNewObjectForEntityForName("BookingSource", moc) as BookingSource;
                
                this._source.identifier = MIOUUID.UUID().UUIDString;
                this._source.name = name;
                
                moc.save();
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('MUST WRITE A NAME FOR SOURCE','Must write a name for source '), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
        
        this.presentViewController(avc, true);
    }
}
