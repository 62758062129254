

// Generated class Application_ManagedObject

class Application_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: deviceID
    set deviceID(value:string) {
        this.setValueForKey(value, 'deviceID');
    }
    get deviceID():string {
        return this.valueForKey('deviceID');
    }
    set deviceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deviceID');
    }
    get deviceIDPrimitiveValue():string {
        return this.primitiveValueForKey('deviceID');
    }

    // Property: placeID
    set placeID(value:string) {
        this.setValueForKey(value, 'placeID');
    }
    get placeID():string {
        return this.valueForKey('placeID');
    }
    set placeIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'placeID');
    }
    get placeIDPrimitiveValue():string {
        return this.primitiveValueForKey('placeID');
    }

    // Property: module
    set module(value:number) {
        this.setValueForKey(value, 'module');
    }
    get module():number {
        return this.valueForKey('module');
    }
    set modulePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'module');
    }
    get modulePrimitiveValue():number {
        return this.primitiveValueForKey('module');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: os
    set os(value:number) {
        this.setValueForKey(value, 'os');
    }
    get os():number {
        return this.valueForKey('os');
    }
    set osPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'os');
    }
    get osPrimitiveValue():number {
        return this.primitiveValueForKey('os');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
