/**
 * Created by miguel on 22/2/17.
 */



class TicketCell extends MUITableViewCell
{
    private numLabel:MUILabel = null;
    private nextDocumentLabel:MUILabel = null;
    private nameLabel:MUILabel = null;
    private priceLabel:MUILabel = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML(){
        this.numLabel = MUIOutlet(this, "tlv_tv_ticket_num_lbl", "MUILabel");       
        this.nextDocumentLabel = MUIOutlet(this, "next-doc-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, "tlv_tv_client_name_lbl", "MUILabel");
        this.priceLabel = MUIOutlet(this, "tlv_tv_price_lbl", "MUILabel");                
        
        this.separatorStyle = MUITableViewCellSeparatorStyle.None;
    }

    private _item:ArchivedDocument = null;
    set item(i:ArchivedDocument){
        this._item = i;
        this.nameLabel.text = i.ticketLegalEntityName ? i.ticketLegalEntityName : 'NO CLIENT';
        this.numLabel.text = i.legalDocumentID ? i.legalDocumentID : i.documentID;        
        this.nextDocumentLabel.text = i.nextDocument != null ? i.nextDocument.legalDocumentID : null;
        if (i.totalPrice == 0) {
            this.priceLabel.text = (this.cf.stringFromNumber(i.totalInvited) || '0');
        }
        else {
            this.priceLabel.text = (this.cf.stringFromNumber(i.totalPrice) || '0' );
        }
        
    }

}