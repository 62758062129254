

// Generated class BookingEvent_ManagedObject

class BookingEvent_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: description
    set description(value:string) {
        this.setValueForKey(value, 'description');
    }
    get description():string {
        return this.valueForKey('description');
    }
    set descriptionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'description');
    }
    get descriptionPrimitiveValue():string {
        return this.primitiveValueForKey('description');
    }

    // Property: imageURL
    set imageURL(value:string) {
        this.setValueForKey(value, 'imageURL');
    }
    get imageURL():string {
        return this.valueForKey('imageURL');
    }
    set imageURLPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'imageURL');
    }
    get imageURLPrimitiveValue():string {
        return this.primitiveValueForKey('imageURL');
    }

    // Property: pax
    set pax(value:number) {
        this.setValueForKey(value, 'pax');
    }
    get pax():number {
        return this.valueForKey('pax');
    }
    set paxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'pax');
    }
    get paxPrimitiveValue():number {
        return this.primitiveValueForKey('pax');
    }

    // Property: rateID
    set rateID(value:string) {
        this.setValueForKey(value, 'rateID');
    }
    get rateID():string {
        return this.valueForKey('rateID');
    }
    set rateIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'rateID');
    }
    get rateIDPrimitiveValue():string {
        return this.primitiveValueForKey('rateID');
    }

    // Property: presetGroupID
    set presetGroupID(value:string) {
        this.setValueForKey(value, 'presetGroupID');
    }
    get presetGroupID():string {
        return this.valueForKey('presetGroupID');
    }
    set presetGroupIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'presetGroupID');
    }
    get presetGroupIDPrimitiveValue():string {
        return this.primitiveValueForKey('presetGroupID');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
