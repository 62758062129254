//
// Generated class Module
//

/// <reference path="Module_ManagedObject.ts" />

class Module extends Module_ManagedObject
{
    identifier = null;
    business   = null;
    id_module  = null;
    name       = null;

    sections = [];

    setObject(object, moc)
    {
        let business    = object['business'];
        this.id_module  = object["idModule"];
        this.identifier = business.identifier + '-' + object["idModule"];
        this.business   = business.identifier;
        this.name       = object["name"];
        
        var instance = this;
        
        if(object['sections']){
            let sections = object['sections'];
            sections.forEach(function (section, index) {
                var p = MIOEntityDescription.insertNewObjectForEntityForName("Section", moc) as Section;
                section['module'] = instance;
                p.setObject(section, moc);
                instance.sections.push(p);
            }, instance);

        }
    }
}
