

// Generated class IssuedInvoice_ManagedObject

class IssuedInvoice_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: isArchived
    set isArchived(value:boolean) {
        this.setValueForKey(value, 'isArchived');
    }
    get isArchived():boolean {
        return this.valueForKey('isArchived');
    }
    set isArchivedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isArchived');
    }
    get isArchivedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isArchived');
    }

    // Property: documentID
    set documentID(value:string) {
        this.setValueForKey(value, 'documentID');
    }
    get documentID():string {
        return this.valueForKey('documentID');
    }
    set documentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentID');
    }
    get documentIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentID');
    }

    // Property: legalDocumentID
    set legalDocumentID(value:string) {
        this.setValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentID():string {
        return this.valueForKey('legalDocumentID');
    }
    set legalDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('legalDocumentID');
    }

    // Property: sessionBeginDate
    set sessionBeginDate(value:Date) {
        this.setValueForKey(value, 'sessionBeginDate');
    }
    get sessionBeginDate():Date {
        return this.valueForKey('sessionBeginDate');
    }
    set sessionBeginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'sessionBeginDate');
    }
    get sessionBeginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('sessionBeginDate');
    }

    // Property: openDate
    set openDate(value:Date) {
        this.setValueForKey(value, 'openDate');
    }
    get openDate():Date {
        return this.valueForKey('openDate');
    }
    set openDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'openDate');
    }
    get openDatePrimitiveValue():Date {
        return this.primitiveValueForKey('openDate');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: orderType
    set orderType(value:number) {
        this.setValueForKey(value, 'orderType');
    }
    get orderType():number {
        return this.valueForKey('orderType');
    }
    set orderTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderType');
    }
    get orderTypePrimitiveValue():number {
        return this.primitiveValueForKey('orderType');
    }

    // Property: closed
    set closed(value:boolean) {
        this.setValueForKey(value, 'closed');
    }
    get closed():boolean {
        return this.valueForKey('closed');
    }
    set closedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'closed');
    }
    get closedPrimitiveValue():boolean {
        return this.primitiveValueForKey('closed');
    }

    // Property: receiptID
    set receiptID(value:string) {
        this.setValueForKey(value, 'receiptID');
    }
    get receiptID():string {
        return this.valueForKey('receiptID');
    }
    set receiptIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'receiptID');
    }
    get receiptIDPrimitiveValue():string {
        return this.primitiveValueForKey('receiptID');
    }

    // Property: receiptName
    set receiptName(value:string) {
        this.setValueForKey(value, 'receiptName');
    }
    get receiptName():string {
        return this.valueForKey('receiptName');
    }
    set receiptNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'receiptName');
    }
    get receiptNamePrimitiveValue():string {
        return this.primitiveValueForKey('receiptName');
    }

    // Property: receiptDocumentID
    set receiptDocumentID(value:string) {
        this.setValueForKey(value, 'receiptDocumentID');
    }
    get receiptDocumentID():string {
        return this.valueForKey('receiptDocumentID');
    }
    set receiptDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'receiptDocumentID');
    }
    get receiptDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('receiptDocumentID');
    }

    // Property: receiptAddress
    set receiptAddress(value:string) {
        this.setValueForKey(value, 'receiptAddress');
    }
    get receiptAddress():string {
        return this.valueForKey('receiptAddress');
    }
    set receiptAddressPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'receiptAddress');
    }
    get receiptAddressPrimitiveValue():string {
        return this.primitiveValueForKey('receiptAddress');
    }

    // Property: receiptAddress2
    set receiptAddress2(value:string) {
        this.setValueForKey(value, 'receiptAddress2');
    }
    get receiptAddress2():string {
        return this.valueForKey('receiptAddress2');
    }
    set receiptAddress2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'receiptAddress2');
    }
    get receiptAddress2PrimitiveValue():string {
        return this.primitiveValueForKey('receiptAddress2');
    }

    // Property: totalBase
    set totalBase(value:number) {
        this.setValueForKey(value, 'totalBase');
    }
    get totalBase():number {
        return this.valueForKey('totalBase');
    }
    set totalBasePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalBase');
    }
    get totalBasePrimitiveValue():number {
        return this.primitiveValueForKey('totalBase');
    }

    // Property: totalTaxes
    set totalTaxes(value:number) {
        this.setValueForKey(value, 'totalTaxes');
    }
    get totalTaxes():number {
        return this.valueForKey('totalTaxes');
    }
    set totalTaxesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTaxes');
    }
    get totalTaxesPrimitiveValue():number {
        return this.primitiveValueForKey('totalTaxes');
    }

    // Property: totalPrice
    set totalPrice(value:number) {
        this.setValueForKey(value, 'totalPrice');
    }
    get totalPrice():number {
        return this.valueForKey('totalPrice');
    }
    set totalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPrice');
    }
    get totalPricePrimitiveValue():number {
        return this.primitiveValueForKey('totalPrice');
    }

    // Property: totalDiscount
    set totalDiscount(value:number) {
        this.setValueForKey(value, 'totalDiscount');
    }
    get totalDiscount():number {
        return this.valueForKey('totalDiscount');
    }
    set totalDiscountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDiscount');
    }
    get totalDiscountPrimitiveValue():number {
        return this.primitiveValueForKey('totalDiscount');
    }

    // Property: totalInvited
    set totalInvited(value:number) {
        this.setValueForKey(value, 'totalInvited');
    }
    get totalInvited():number {
        return this.valueForKey('totalInvited');
    }
    set totalInvitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalInvited');
    }
    get totalInvitedPrimitiveValue():number {
        return this.primitiveValueForKey('totalInvited');
    }

    // Property: totalDeleted
    set totalDeleted(value:number) {
        this.setValueForKey(value, 'totalDeleted');
    }
    get totalDeleted():number {
        return this.valueForKey('totalDeleted');
    }
    set totalDeletedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDeleted');
    }
    get totalDeletedPrimitiveValue():number {
        return this.primitiveValueForKey('totalDeleted');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: linesGroup
    set linesGroup(value:IssuedInvoiceGroupLine) {
        this.setValueForKey(value, 'linesGroup');
    }
    get linesGroup():IssuedInvoiceGroupLine {
        return this.valueForKey('linesGroup') as IssuedInvoiceGroupLine;
    }
}
