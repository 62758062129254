//
// Generated class BankAccountMovement
//

/// <reference path="BankAccountMovement_ManagedObject.ts" />

class BankAccountMovement extends BankAccountMovement_ManagedObject
{

}
