
class ProductRatesDataSource extends MIOObject
{    
    private tableView:MUITableView = null;
    initWithTableView(tableview:MUITableView){
        this.tableView = tableview;
    }

    private _product:Product = null;
    set product(product:Product){
        this._product = product;
        this.fetchedResultsController = null;        
        this.mapRates(this.ratesFetchedResultsController.fetchedObjects);
        this.tableView.reloadData();        
    }

    numberOfSections(tableview:MUITableView){
        //return this.fetchedResultsController.sections.length;
        return 1;
    }

    numberOfRowsInSection(tableview:MUITableView, section){
        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();
        return this.productRatesInfo.count;
    }

    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){        
        let cell = tableview.dequeueReusableCellWithIdentifier("ProductRateCell") as ProductRateCell;

        //let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        let item = this.productRatesInfo[indexPath.row];
        cell.item = item;            
        
        return cell;
    }
    
    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let predicateFormat = "product.identifier = " + this._product.identifier;
        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductRate", sds, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ['rate'];        
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;            
        
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private _ratesFetchedResultsController:MIOFetchedResultsController = null;
    set ratesFetchedResultsController(value){
        if (value == null && this._ratesFetchedResultsController != null)
            this._ratesFetchedResultsController.delegate = null;
    
        this._ratesFetchedResultsController = value;
    }
    
    get ratesFetchedResultsController(){
        if (this._ratesFetchedResultsController != null)
            return this._ratesFetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Rate", sds, null);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;            
        
        fetchedResultsController.performFetch();
    
        this._ratesFetchedResultsController = fetchedResultsController;    
        return this._ratesFetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        if (controller == this.ratesFetchedResultsController) {
            this.mapRates(controller.fetchedObjects);            
        }     
        else {
            this.mapProductRates(controller.fetchedObjects);
        }   
    }

    private productRatesInfo = [];
    private mapRates(objects){
        this.productRatesInfo = [];
        for (let index = 0; index < objects.count; index++){
            let r = objects[index] as Rate;

            let item = {};
            item["ID"] = r.identifier;
            item["Product"] = this._product;
            item["Rate"] = r;

            this.productRatesInfo.addObject(item);
        }

        if (objects.count > 0) this.mapProductRates(this.fetchedResultsController.fetchedObjects);
    }
    
    private mapProductRates(objects){
        for (let index = 0; index < objects.count; index++){
            let pr = objects[index] as ProductRate;
            let rateID = pr.rate.identifier;
            let item = null;
            
            for (let rateIndex = 0; rateIndex < this.productRatesInfo.length; rateIndex++){
                item = this.productRatesInfo[rateIndex];
                if (item["ID"] == pr.rate.identifier) break;
            }
            
            if (item == null) continue;

            item["ProductRate"] = pr;
        }
        
        this.tableView.reloadData();
    }
}