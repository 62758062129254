

/// <reference path="../AppHelper.ts" />

class SupplierProductViewController extends MUIViewController
{    
    private searchTextField:MUITextField = null;
    private onlySupplierProducts:MUISwitchButton = null;
    private tableView:MUITableView = null;    
    
    private searchTimer = null;
    private searchString = null;    

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    
    viewDidLoad(){
        super.viewDidLoad();
        
        this.onlySupplierProducts = MUIOutlet(this, 'supplier-only-products-sw','MUISwitchButton');
        this.onlySupplierProducts.setOnChangeValue(this, function(control, value){
            this._supplier.onlySupplierProducts = value;
        });

        this.searchTextField = MUIOutlet(this, 'search-bar','MUITextField');
        this.searchTextField.setOnChangeText(this, function(textfield, value){
            this.searchString = value.length > 0 ? value : null;

            if (this.searchTimer != null) this.searchTimer.invalidate();
            this.searchTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(tinmer:MIOTimer){
                this.fetchedResultsController = null;
                this.tableView.reloadData();
            });
        });     
        
        this.tableView = MUIOutlet(this, 'table-view', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private _supplier:Supplier = null;
    set supplier(value){
        this._supplier = value;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._supplier == null) return;

        this.onlySupplierProducts.setOn(this._supplier.onlySupplierProducts);

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length + 1;
    }
    
    numberOfRowsInSection(tableview, section){
        if (section == this.fetchedResultsController.sections.count) return 1;

        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){

        let cell:SupplierProductCell = null;
        if (indexPath.section == this.fetchedResultsController.sections.count) {
            cell = tableview.dequeueReusableCellWithIdentifier('AddCell') as SupplierProductCell;            
        }
        else {
            cell = tableview.dequeueReusableCellWithIdentifier('EditCell') as SupplierProductCell;
    
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as SupplierProduct;    
            cell.item = item;    
        }

        cell.supplier = this._supplier;
        return cell;
    }        

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        if (indexPath.section == this.fetchedResultsController.sections.count) return MUITableViewCellEditingStyle.Insert;
        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        
        if (editingStyle == MUITableViewCellEditingStyle.Delete){
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as SupplierProduct;
            DBHelper.removeSupplierProduct(item, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;    
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('product.created_at', true)];
    
        let predicateFormat = 'supplier.identifier == ' + this._supplier.identifier; 
        if(this.searchString != null)
            predicateFormat += " AND product.name CONTAINS '" + this.searchString + "'";

        let fetchRequest = DBHelper.listFetchRequestWithEntityName('SupplierProduct', sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ['product'];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }
}
