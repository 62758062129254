class BookingDetailCell extends UITableViewCell
{
    delegate = null;

    private dateTextField:MUITextField = null;
    private dateButton:MUIButton = null;
    private hourComboBox:MUIComboBox = null;
    private paxTextField:MUITextField = null;
    private durationComboBox:MUIComboBox = null;
    private statusComboBox:MUIComboBox = null;
    private zonesDropdown:MUIButton = null;
    private calculatePriceLabel:MUILabel = null;
    private priceTextField:MUITextField = null;
    private calculateMinSpendLabel:MUILabel = null;
    private minSpendTextField:MUITextField = null;
    private amountToPayTextField:MUITextField = null;
    private totalAmountToPayLabel:MUILabel = null;
    private addDepositButton:MUIButton = null;
    private depositLabel:MUILabel = null;
    private customerCommentsArea:MUITextArea = null;
    private hallCommentsArea:MUITextArea = null;
    private internalCommentsArea:MUITextArea = null;
    private allergensCommentsArea:MUITextArea = null;

    private addBookingButton:MUIButton = null;

    private modifiersTableView:UITableView = null;
    
    private dateFormatter = MUIWebApplication.sharedInstance().delegate.dateFormatter as MIODateFormatter;
    private integerFormatter = MUIWebApplication.sharedInstance().delegate.integerFormatter as MIONumberFormatter;
    private currencyFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;

    awakeFromHTML(){
        this.dateTextField = MUIOutlet(this, "date-tf", "MUITextField");
        this.dateButton = MUIOutlet(this, "date-btn", "MUIButton");
        this.setupDateButton(this.dateButton);

        this.hourComboBox = MUIOutlet(this, "hour-cb", "MUIComboBox");
        this.setupHourComboBox(this.hourComboBox);
        this.hourComboBox.setOnChangeAction(this, function(control, value){
            this.booking.hour = value;
        });
        
        this.durationComboBox = MUIOutlet(this, "duration-cb", "MUIComboBox");
        this.paxTextField = MUIOutlet(this, "pax-tf", "MUITextField");
        this.paxTextField.setOnChangeText(this, function(control, value){
            this.booking.pax = this.integerFormatter.numberFromString(value);
            this.updatePrices();
        });

        this.statusComboBox = MUIOutlet(this, "status-cb", "MUIComboBox");
        AppHelper.sharedInstance().setupBookingStatusComboBox(this.statusComboBox);
        this.statusComboBox.setOnChangeAction(this, function(control, value){
            this.booking.status = this.integerFormatter.numberFromString(value);
        });

        this.zonesDropdown = MUIOutlet(this, "zones-dd", "MUIButton");
        this.setupZonesDropDown(this.zonesDropdown);
        this.addDepositButton = MUIOutlet(this, "add-deposit-btn", "MUIButton");
        this.addDepositButton.setAction(this, function(){
            this.showAddDepositAlert();
        });

        this.calculatePriceLabel = MUIOutlet(this, "calculated-price-lbl", "MUILabel");
        this.priceTextField = MUIOutlet(this, "price-tf", "MUITextField");
        this.priceTextField.setOnChangeText(this, function(control, value:string){
            if (value.length > 0) {
                this.booking.price = this.currencyFormatter.numberFromString(value);
            }
            else this.booking.price = null;
            this.updateTotals();
        });

        this.calculateMinSpendLabel = MUIOutlet(this, "calculated-min-spend-lbl", "MUILabel");
        this.minSpendTextField = MUIOutlet(this, "min-spend-tf", "MUITextField");
        this.minSpendTextField.setOnChangeText(this, function(control, value:string){
            if (value.length > 0) {
                this.booking.minSpend = this.currencyFormatter.numberFromString(value);
            }
            else this.booking.minSpend = null;
            this.updateTotals();
        });

        this.amountToPayTextField = MUIOutlet(this, "amount-to-pay-tf", "MUITextField");
        this.amountToPayTextField.setOnChangeText(this, function(control, value:string){
            if(value.length > 0) {
                this.booking.ammountToPay = value;
            }
            else this.booking.amountToPay = null;
            this.updateTotals();
        });
        
        this.totalAmountToPayLabel = MUIOutlet(this, "total-lbl", "MUILabel");
        this.depositLabel = MUIOutlet(this, "deposit-lbl", "MUILabel");
        
        this.customerCommentsArea = MUIOutlet(this, "customer-comments-ta", "MUITextArea");
        this.customerCommentsArea.setOnChangeText(this, function(control, value){
            this.booking.clientComments = value;
        });

        this.hallCommentsArea = MUIOutlet(this, "hall-comments-ta", "MUITextArea");
        this.hallCommentsArea.setOnChangeText(this, function(control, value){
            this.booking.comments = value;
        });

        this.internalCommentsArea = MUIOutlet(this, "internal-comments-ta", "MUITextArea");
        this.internalCommentsArea.setOnChangeText(this, function(control, value){
            this.booking.privateComments = value;
        });
        
        this.allergensCommentsArea = MUIOutlet(this, "allergens-comments-ta", "MUITextArea");
        this.allergensCommentsArea.setOnChangeText(this, function(control, value){
            this.booking.allergens = value;
        });

        this.modifiersTableView = MUIOutlet(this, "modifiers-table-view", "UITableView");
        this.modifiersTableView.dataSource = this;   
        
        this.addBookingButton = MUIOutlet(this, "add-booking-btn", "MUIButton");
        this.addBookingButton.setAction(this, function(){
            this.addBookingAction();                        
		});
		
		this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private booking:Booking = null;
    set item(item:Booking){
        this.booking = item;        

        this.dateTextField.text = this.dateFormatter.stringFromDate(item.date);        

        if(item.hour != null){
            let h = item.hour.substr(0,5);
            this.hourComboBox.selectItem(h);
        }
        else     
            this.hourComboBox.selectItem(-1);

        this.durationComboBox.selectItem(item.duration);
        this.paxTextField.text = item.pax;
        this.statusComboBox.selectItem(item.status);

        // if (item.relationZones.count > 0) {
        //     let rz = item.relationZones.objectAtIndex(0) as RelationBookingZone;
        //     this.zonesDropdown.title = rz.name;
        // }

        this.zonesDropdown.title = item.zoneName ? item.zoneName : MIOLocalizeString("Select zone", "Select zone");
        this.reloadModifiersTableView();
                
        this.calculatePriceLabel.text = this.calculatedPriceString();
        this.priceTextField.text = this.priceString();
        this.calculateMinSpendLabel.text = this.calculatedMinimunSpendString();        
        this.minSpendTextField.text = this.minimunSpendString();        
        this.amountToPayTextField.text = this.amountToPayString();
        this.totalAmountToPayLabel.text = this.totalAmountToPayString();
        this.depositLabel.text = this.depositString();        
        
        this.customerCommentsArea.text = item.clientComments;
        this.hallCommentsArea.text = item.comments;
        this.internalCommentsArea.text = item.privateComments;
        this.allergensCommentsArea.text = item.allergens;
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){                
        let cell:BookingModifierCell = null;

        let modifier = this.fetchedResultsController.objectAtIndexPath(indexPath) as BookingModifier;
        if (modifier.category.incremental == false){
            cell = tableview.dequeueReusableCellWithIdentifier("ModifierCell") as BookingModifierCell;
        }
        else {
            cell = tableview.dequeueReusableCellWithIdentifier("IncrementalModifierCell") as BookingModifierCell;
        }                
        cell.item = modifier;

        return cell;
    }

    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("category.name", true), MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let predicateFormat = "ANY category.relationCategoryZones.zoneID == " + this.booking.zoneID;
            
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingModifier", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["category.relationCategoryZones"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "category.name");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.modifiersTableView.reloadData();
    }

    private reloadModifiersTableView(){
        if (this.booking.zoneID == null) return;

        this.fetchedResultsController = null;
        this.modifiersTableView.reloadData();
    }

    private setupDateButton(button:MUIButton){
        if (button == null) return;
        button.setAction(this, function(){
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;
    
            let pc = dp.popoverPresentationController;
            pc.sourceRect = button.frame;
            pc.sourceView = button;
    
            AppHelper.sharedInstance().presentViewController(dp, true);    
        });
    }

    didSelectDate(datePickerController:MUIDatePickerController, date:Date){                
        this.dateTextField.text = this.dateFormatter.stringFromDate(date);            
        this.booking.date = date;  
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.booking.day = ad.serverDateFormatter.stringFromDate(date);
    }

    private setupHourComboBox(comboBox:MUIComboBox){
        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('SELECT HOUR', 'Select Hour...'), -1); 
        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('nextDay', true), MIOSortDescriptor.sortDescriptorWithKey('time', true)];                                
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingDayTimeRange", sortDescriptors, "showRange == true");
        let objects = ad.managedObjectContext.executeFetch(fetchRequest);

        for (let index = 0; index < objects.length; index++){
            let tr:BookingDayTimeRange = objects[index];
            comboBox.addItem(tr.name, tr.time);
        }
    }

    private setupZonesDropDown(dropdown:MUIButton){
        if (dropdown == null) return;

        dropdown.setAction(this, function(){
            let vc = AppHelper.sharedInstance().selectViewController("Zone", "name", false, false, null, this, true);
            AppHelper.sharedInstance().presentViewController(vc, true);    
        });
    }

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{
        if (controller.identifier == "Zone"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];            
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingZone", sd, null);
            let parentZone = controller.context ? controller.context["ParentZone"] : null;
            if (parentZone == null) fetchRequest.predicate = MIOPredicate.predicateWithFormat("parent == null");
            else fetchRequest.predicate = MIOPredicate.predicateWithFormat("parent.identifier == " + parentZone.identifier);
            fetchRequest.relationshipKeyPathsForPrefetching = ["priceRules"];

            return fetchRequest;
        }
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){
        let dismiss = true;

        if (controller.identifier == "Zone"){
            let zone = item as BookingZone;
            if (zone.subZones.count > 0) {                
                let vc = AppHelper.sharedInstance().selectViewController("Zone", "name", false, false, {"ParentZone": zone}, this, true);
                controller.pushNextViewController(vc);
                dismiss = false;
            }
            else {
                this.zonesDropdown.title = zone.name;
                this.addZone(zone);
            }
        }

        return dismiss;
    }

    private addZone(zone:BookingZone){        

        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext as MIOManagedObjectContext;
        // let zoneRelation:RelationBookingZone = MIOEntityDescription.insertNewObjectForEntityForName("RelationBookingZone", moc) as RelationBookingZone;
        
        // zoneRelation.identifier = MIOUUID.UUID().UUIDString;
        // zoneRelation.booking = this.booking;
        // zoneRelation.zoneID = zone.identifier;
        // zoneRelation.name = zone.name;
        // zoneRelation.pax = this.booking.totalPax;
        // zoneRelation.price = zone.price || 0;                        

        // for (let index = 0; index < this.booking.relationZones.count; index++){
        //     let rz = this.booking.relationZones.objectAtIndex(index);
        //     moc.deleteObject(rz);
        // }
        //this.booking.addRelationZonesObject(zoneRelation);
        
        this.booking.zoneName = zone.name;
        this.booking.zoneID = zone.identifier;
        this.updatePrices();

        moc.save();    
        
        this.reloadModifiersTableView();
    }

    private showAddDepositAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle('Add Funds', 'Add or remove funds', MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("0");
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            DBHelper.queryObjectsWithCompletion("PayMethod", null, MIOPredicate.predicateWithFormat("deletedAt == null"), [], this, function(objects){
                AppHelper.fillPayMethodComboBox(comboBox, objects, null);
            });
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, this, function(){
            let quantity = avc.textFields[0].text;
            let cb = avc.comboBoxes[0] as MUIComboBox;
            let pmid = cb.getSelectedItem();
            let pm = DBHelper.queryObjectFromMainContext("PayMethod", MIOPredicate.predicateWithFormat("identifier == " + pmid), []) as PayMethod;            
            this.addDeposit(pm, quantity);            
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Cancel, null,null));
        
        AppHelper.sharedInstance().presentViewController(avc, true);
    }

    private addDeposit(payMethod:PayMethod, quantity:string){
        
        if (payMethod == null) {
            AppHelper.showErrorMessage(null, "No pay method", "You must select a Pay Method");
            return;
        }

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let money = ad.currencyFormatter.numberFromString(quantity);

        if (money == 0) {
            AppHelper.showErrorMessage(null, "Quanity is zero", "The quantity can not be zero.");
            return;
        }
        
        let dh = DBHelper.sharedInstance();
        
        if (money > 0 ) {
            dh.createEconomicLine(money, payMethod, this.booking.client, AccountLineType.AddFunds, this.booking.identifier);
        }
        else if (money < 0){
            dh.createEconomicLine(money, payMethod, this.booking.client, AccountLineType.RemoveFunds, this.booking.identifier);
        }                

        this.booking.deposit += money;
        this.depositLabel.text = this.depositString();
        DBHelper.saveMainContext();                
    }

    private priceAmountToPay = null;
    private minAmountToPay = null;
    private amountToPay = null;
    private updatePrices(){        
                
        this.booking.calculatedPrice = 0;
        this.booking.calculatedMinSpend = 0;

        if (this.booking.zoneID != null) {
            let zone = DBCacheHelper.sharedInstance().objectForEntityNameWithPredicate("BookingZone", MIOPredicate.predicateWithFormat("identifier == " + this.booking.zoneID));
            for (let index = 0; index < zone.priceRules.count; index++){
                let pr = zone.priceRules.objectAtIndex(index) as BookingPriceRule;

                if (pr.minPax != null && this.booking.pax < pr.minPax) continue;
                if (pr.maxPax != null && this.booking.pax > pr.maxPax) continue;
                                
                if (pr.priceType == BookingPriceRuleType.ByPax) this.booking.calculatedPrice = (pr.price || 0) * this.booking.pax;                
                else this.booking.calculatedPrice = (pr.price || 0);
                this.priceAmountToPay = pr.priceAmountToPay;
                
                if (pr.minSpendType == null) this.booking.calculatedMinSpend = (pr.minSpend || 0) * this.booking.pax;
                else if (pr.minSpendType == BookingPriceRuleType.ByPax) this.booking.calculatedMinSpend = (pr.minSpend || 0) * this.booking.pax;                
                else if (pr.minSpendType == BookingPriceRuleType.ByTable) this.booking.calculatedMinSpend = (pr.minSpend || 0);
                this.minAmountToPay = pr.minSpendAmountToPay;

                //if (this.booking.ammountToPay != null) this.amountToPay = this.booking.ammountToPay;
                //this.amountToPay = null;

                break;
            }
        }

        this.calculatePriceLabel.text = this.calculatedPriceString();
        this.calculateMinSpendLabel.text = this.calculatedMinimunSpendString();        
        this.amountToPayTextField.text = this.booking.ammountToPay;
        this.updateTotals();        
    }

    private calculatedPriceString(){
        if (this.booking.calculatedPrice == null) return null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.booking.calculatedPrice);        
    }

    private priceString(){
        if (this.booking.price == null) return null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.booking.price);        
    }

    private calculatedMinimunSpendString(){
        if (this.booking.calculatedMinSpend == null) return null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.booking.calculatedMinSpend);        
    }

    private minimunSpendString(){
        if (this.booking.minSpend == null) return null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.booking.minSpend);        
    }

    private amountToPayString(){
        if (this.booking.ammountToPay == null) return null;
        
        return this.booking.ammountToPay;
        // let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        // return ad.currencyFormatter.stringFromNumber(this.booking.ammountToPay);        
    }

    private totalAmountToPayString(){
        if (this.booking.totalAmmountToPay == null) return null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.booking.totalAmmountToPay);        
    }

    private depositString(){
        if (this.booking.deposit == null) return null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.booking.deposit);        
    }

    private updateTotals(){
        let price = 0;
        if (this.booking.price == null) price = this.booking.calculatedPrice;
        else price = this.booking.price;

        let total = price;
        
        let minSpend = 0;
        if (this.booking.minSpend == null) minSpend = this.booking.calculatedMinSpend;
        else minSpend = this.booking.minSpend;

        total += minSpend;

        if (this.booking.ammountToPay != null) this.booking.totalAmmountToPay = this.calutateAmountFromAmountToPay(total, this.booking.ammountToPay);
        else {
            let p = this.calutateAmountFromAmountToPay(price, this.priceAmountToPay);
            let ms = this.calutateAmountFromAmountToPay(minSpend, this.minAmountToPay);
            this.booking.totalAmmountToPay = p + ms;
        }

        this.totalAmountToPayLabel.text = this.totalAmountToPayString();
    }
    
    private calutateAmountFromAmountToPay(amount:number, amountToPay:string):number{
        if (amountToPay == null) return amount;

        if (amountToPay.indexOf("%") == -1) {
            // Just the ammount
            return parseFloat(this.booking.ammountToPay);
        }
        else {
            // Calculate the percentage
            let value = amountToPay.replace("%", "");
            let factor = parseFloat(value);

            return (amount * factor) / 100;
        }
    }

    private addBookingAction(){
        let b = DBHelper.createBookingFromBooking(this.booking);
        b.date = this.booking.date;
        b.day = this.booking.day;

        if (this.delegate != null) this.delegate.addNewBooking(b);
    }

}