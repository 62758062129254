class InventoryTemplateCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
    }

    set item (item:InventoryTemplate){
        this.titleLabel.text = item.name;
    }
}
