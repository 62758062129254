
enum NoItemSelectedViewControllerType {    
    None,
    InventoryNote,
    MovementNote,
    OutputNote,
    SupplierOrder,
    SupplierNote,
    StockOrder,  
    DeliveryNote,
    Supplier, 
    CashDesk,        
    Party,
    Report,
    BookingSource,
    Setup,
    Product,
    ProductModifiers,
    Menus,
    Client,
    Ticket,
    Invoice,
    Worker,
    Category,
    Offer,
    LowStockProducts,
    BankAccount,
    License,
    GuestList,
    Events,
    ReportBuilder,
    InternalReturnOrder,
    SupportTickets
}
class NoItemSelectedViewController extends MUIViewController
{
    inventoryNoteView:MUIView = null;
    movementNoteView:MUIView = null;
    outputNoteView:MUIView = null;
    supplierOrderView:MUIView = null;
    supplierNoteView:MUIView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.inventoryNoteView = MUIOutlet(this, "inventory-note-no-select");
        this.movementNoteView = MUIOutlet(this, "movement-note-no-select");
        this.outputNoteView = MUIOutlet(this, "output-note-no-select");
        this.supplierOrderView = MUIOutlet (this, "supplier-order-no-select");
        this.supplierNoteView = MUIOutlet(this, "supplier-note-no-select");
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _type:NoItemSelectedViewControllerType = null;
    set type(type:NoItemSelectedViewControllerType){
        this._type = type;        
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        switch(this._type) {            
            case NoItemSelectedViewControllerType.InventoryNote:
            MUICoreLayerRemoveStyle(this.inventoryNoteView.layer, "hidden");
            break;

            case NoItemSelectedViewControllerType.MovementNote:
            MUICoreLayerRemoveStyle(this.movementNoteView.layer, "hidden");
            break;

            case NoItemSelectedViewControllerType.OutputNote:
            MUICoreLayerRemoveStyle(this.outputNoteView.layer, "hidden");
            break;
            
            case NoItemSelectedViewControllerType.SupplierOrder:
            MUICoreLayerRemoveStyle(this.supplierOrderView.layer, "hidden");
            break;

            case NoItemSelectedViewControllerType.SupplierNote:
            MUICoreLayerRemoveStyle(this.supplierNoteView.layer, "hidden");
            break;

        }        
    }
}