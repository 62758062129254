/**
 * Created by miguel on 10/2/17.
 */



// /// <reference path="AddNewCategoryTaxViewController.ts" />
// /// <reference path="AddNewCategoryOrderViewController.ts" />
// /// <reference path="AddNewCategoryGroupViewController.ts" />
// /// <reference path="AddNewCategorySectionViewController.ts" />
///<reference path="../../model/Category.ts" />

enum CategoryMode {
    AddCategory,
    EditCategory
}

class AddNewCategoryViewController extends MUIViewController
{
    private _category:Category = null;
    private _categoryID:string = null;

    private _parentCategory:Category = null;

    private titleLabel:MUILabel = null;
    private categoryImage:MUIImageView = null;
    private closeButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private settingsButton:MUIButton = null;
    private okButton:MUIButton = null;
    //private deleteButton:MUIButton = null;

    private nameTextField:MUITextField = null;
    private forSaleCheckButton:MUISwitchButton = null;

    private segmentedControl:MUISegmentedControl = null;
    private pageController:MUIPageController = null;

    private anctvc:AddNewCategoryTaxViewController = null;
    private ancovc:AddNewCategoryOrderViewController = null;
    private ancavc:AddNewCategoryGroupViewController = null;
    private ancsvc:AddNewCategorySectionViewController = null;
    private anctagsvc:AddNewCategoryTagsViewController = null;

    private _mode = CategoryMode.AddCategory;
    private _fetchedResultsController:MIOFetchedResultsController = null;

    get preferredContentSize()
    {
        return new MIOSize(500, 500);
    }

    viewDidLoad()
    {
        super.viewDidLoad();

        this.titleLabel = MUIOutlet(this, 'title','MUILabel');

        this.categoryImage = MUIOutlet(this, 'category_image','MUIImageView');

        this.nameTextField = MUIOutlet(this, 'ancv_pb_category_text', 'MUITextField');
        this.nameTextField.setOnChangeText(this, function(textfield, value)
        {
            this._category.name = value.trim();
        });

        this.forSaleCheckButton = MUIOutlet(this, 'ancv_pb_switch_btn', 'MUISwitchButton');
        this.forSaleCheckButton.setOnChangeValue(this, function ()
        {
            this._categoryProductListViewController = !this.isAvailable;
            DBHelper.saveMainContext();
        });

        this.closeButton = MUIOutlet(this, 'ancv_nb_cancel_btn', 'MUIButton');
        this.closeButton.setAction(this, function ()
        {
            this.removeCategory();
            this.dismissViewController(true);
        });

        // this.deleteButton = MUIOutlet(this, 'delete_button', 'MUIButton');
        // this.deleteButton.setAction(this, function ()
        // {
        //     this.removeCategory();
        // });

         this.saveButton = MUIOutlet(this, 'save_button', 'MUIButton');
         this.saveButton.setAction(this, function () {
             this.saveCategory();
         });

         this.settingsButton = MUIOutlet(this, 'settings_button', 'MUIButton');
         this.settingsButton.setAction(this, function() {
             this.massiveOperationAction();
         });

        this.okButton = MUIOutlet(this, 'ancv_nb_ok_btn', 'MUIButton');
        this.okButton.setAction(this, function ()
        {
            if(this.saveCategory())
            {
                this.dismissViewController(true);
            }

        });

        this.segmentedControl = MUIOutlet(this, "ancv_segmented_control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function (control, index) {

            this.pageController.showPageAtIndex(index);
        });

        //SEGMENTED BUTTON
        this.pageController = MUIOutlet(this, "ancpage_controller", "MUIPageController");

        this.anctvc = new AddNewCategoryTaxViewController("add-new-category-tax-view");
        this.anctvc.initWithResource("layout/products/AddNewCategoryTaxView.html");
        this.pageController.addPageViewController(this.anctvc);

        this.ancavc = new AddNewCategoryGroupViewController("add-new-category-group-view");
        this.ancavc.initWithResource("layout/products/AddNewCategoryGroupView.html");
        this.pageController.addPageViewController(this.ancavc);

        this.ancovc = new AddNewCategoryOrderViewController("add-new-category-order-view");
        this.ancovc.initWithResource("layout/products/AddNewCategoryOrderView.html");
        this.pageController.addPageViewController(this.ancovc);

        this.ancsvc = new AddNewCategorySectionViewController("add-new-category-section-view");
        this.ancsvc.initWithResource("layout/products/AddNewCategorySectionView.html");
        this.pageController.addPageViewController(this.ancsvc);

        this.anctagsvc = new AddNewCategoryTagsViewController("add-new-category-tags-view");
        this.anctagsvc.initWithResource("layout/products/AddNewCategoryTagsView.html");
        this.pageController.addPageViewController(this.anctagsvc);

    }

    private removeCategory() {
        var moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        moc.deleteObject(this._category);
        this._category = null;
        moc.save();
    }

    private saveCategory() {
        var moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let name = this._category.name;
        let errorString = null;
        if(!name || name.length <= 0) {
            errorString = 'Error, check category name.';
        }
        else if(this._category.tax == null)
            errorString = 'Error, must select a default tax.';

        if(errorString != null) 
        {
            AppHelper.showErrorMessage(this, 'Error', errorString);
            return false;
        }
        this._category.isAvailable = this.forSaleCheckButton.on;

        moc.save();

        MIONotificationCenter.defaultCenter().postNotification('ItemDidChange', null);
        return true;
    }

    private massiveOperationAction() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('MASSIVE OPERATION', "MASSIVE OPERATION"), MIOLocalizeString('SELECT THE OPERATION',"SELECT THE OPERATION"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DELETE CATEGORY", "DELETE CATEGORY"), MUIAlertActionStyle.Default, this, function(){
            this.removeCategory();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);

        if(this._mode == CategoryMode.AddCategory)
        {
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            this._category = MIOEntityDescription.insertNewObjectForEntityForName("Category", moc) as Category;
            this._category.identifier = MIOUUID.UUID().UUIDString;
            this._category.grouping = 0;
            this._category.isAvailable = true;
            this._category.showInBar = true;
            this._category.showInTables = true;
            this._category.showInOnline = true;
            
            this._category.parentCategory = this._parentCategory ? this._parentCategory.identifier : null;
        
        }
        
        this.updateUI();

        this.segmentedControl.selectSegmentedAtIndex(0);
        this.pageController.showPageAtIndex(0);
    }

    private updateUI() {
        if(this.viewIsLoaded == false || this._category == null) return;
        
        if(this._mode == CategoryMode.AddCategory)
        {
            this.titleLabel.text = MIOLocalizeString("NEW CATEGORY", "New category");
            
            this.okButton.setHidden(false);
            this.closeButton.setHidden(false);
            //this.deleteButton.setHidden(true);
            //this.saveButton.setHidden(true);
        }
        else {
            this.titleLabel.text = MIOLocalizeString("EDIT CATEGORY", "Edit category");
            //this.categoryImage.setImage(this._category.imageURL);
            
            this.okButton.setHidden(true);
            this.closeButton.setHidden(true);
            this.categoryImage.setImage(this._category.image ? this._category.image.urlByType(DBImageType.Category) : null);    

            //Hide the delete category button for now, until we have delete rules
            //this.deleteButton.setHidden(false);
            //this.saveButton.setHidden(false);
        }
        this.forSaleCheckButton.setOn(this._category.isAvailable);
        this.nameTextField.text = this._category.name;

        this.anctvc.category    = this._category;
        this.ancsvc.category    = this._category;
        this.ancavc.category    = this._category;
        this.ancovc.category    = this._category;
        this.anctagsvc.category = this._category;

    }

    set parentCategory(value) { 
        this._parentCategory = value;
    }

    set categoryID(value:string) {
        if(value == null) {
            this._categoryID = null;
            this._category = null;
            this._fetchedResultsController = null;
        }
        if (value == this._categoryID) return;
        this._categoryID = value;

        this._fetchedResultsController = null;

        var ad = MUIWebApplication.sharedInstance().delegate;
    
        var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Category');
        fetchRequest.relationshipKeyPathsForPrefetching = ['tax','tag'];
        fetchRequest.predicate = MIOPredicate.predicateWithFormat('identifier = ' + this._categoryID );
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        this._fetchedResultsController = fetchedResultsController;
    
        let results = fetchedResultsController.performFetch();
        this._mode = CategoryMode.EditCategory;
        if (results.length > 0) this.updateCategory(results[0]);

    }

    controllerDidChangeContent(controller) {
        let results = this._fetchedResultsController.resultObjects;
        if (results.length > 0) this.updateCategory(results[0]);
    }

    private updateCategory(category:Category) {
        if (this._categoryID != category.identifier) return;
        if (this._category == category) return;
        this._category = category;
        this.updateUI();
    }
}