

// Generated class Business_ManagedObject

class Business_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }
    // Relationship: parent
    set parent(value:Business) {
        this.setValueForKey(value, 'parent');
    }
    get parent():Business {
        return this.valueForKey('parent') as Business;
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: legalName
    set legalName(value:string) {
        this.setValueForKey(value, 'legalName');
    }
    get legalName():string {
        return this.valueForKey('legalName');
    }
    set legalNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalName');
    }
    get legalNamePrimitiveValue():string {
        return this.primitiveValueForKey('legalName');
    }

    // Property: businessID
    set businessID(value:string) {
        this.setValueForKey(value, 'businessID');
    }
    get businessID():string {
        return this.valueForKey('businessID');
    }
    set businessIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'businessID');
    }
    get businessIDPrimitiveValue():string {
        return this.primitiveValueForKey('businessID');
    }

    // Property: phone
    set phone(value:string) {
        this.setValueForKey(value, 'phone');
    }
    get phone():string {
        return this.valueForKey('phone');
    }
    set phonePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'phone');
    }
    get phonePrimitiveValue():string {
        return this.primitiveValueForKey('phone');
    }

    // Property: email
    set email(value:string) {
        this.setValueForKey(value, 'email');
    }
    get email():string {
        return this.valueForKey('email');
    }
    set emailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'email');
    }
    get emailPrimitiveValue():string {
        return this.primitiveValueForKey('email');
    }

    // Property: address
    set address(value:string) {
        this.setValueForKey(value, 'address');
    }
    get address():string {
        return this.valueForKey('address');
    }
    set addressPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'address');
    }
    get addressPrimitiveValue():string {
        return this.primitiveValueForKey('address');
    }

    // Property: state
    set state(value:string) {
        this.setValueForKey(value, 'state');
    }
    get state():string {
        return this.valueForKey('state');
    }
    set statePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'state');
    }
    get statePrimitiveValue():string {
        return this.primitiveValueForKey('state');
    }

    // Property: city
    set city(value:string) {
        this.setValueForKey(value, 'city');
    }
    get city():string {
        return this.valueForKey('city');
    }
    set cityPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'city');
    }
    get cityPrimitiveValue():string {
        return this.primitiveValueForKey('city');
    }

    // Property: postalCode
    set postalCode(value:string) {
        this.setValueForKey(value, 'postalCode');
    }
    get postalCode():string {
        return this.valueForKey('postalCode');
    }
    set postalCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'postalCode');
    }
    get postalCodePrimitiveValue():string {
        return this.primitiveValueForKey('postalCode');
    }

    // Property: countryISO2
    set countryISO2(value:string) {
        this.setValueForKey(value, 'countryISO2');
    }
    get countryISO2():string {
        return this.valueForKey('countryISO2');
    }
    set countryISO2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'countryISO2');
    }
    get countryISO2PrimitiveValue():string {
        return this.primitiveValueForKey('countryISO2');
    }

    // Property: countryName
    set countryName(value:string) {
        this.setValueForKey(value, 'countryName');
    }
    get countryName():string {
        return this.valueForKey('countryName');
    }
    set countryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'countryName');
    }
    get countryNamePrimitiveValue():string {
        return this.primitiveValueForKey('countryName');
    }
}
