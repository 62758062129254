

// Generated class Party_ManagedObject

class Party_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }

    // Property: description
    set description(value:string) {
        this.setValueForKey(value, 'description');
    }
    get description():string {
        return this.valueForKey('description');
    }
    set descriptionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'description');
    }
    get descriptionPrimitiveValue():string {
        return this.primitiveValueForKey('description');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: offer
    set offer(value:Offer) {
        this.setValueForKey(value, 'offer');
    }
    get offer():Offer {
        return this.valueForKey('offer') as Offer;
    }

    // Relationship: dates
    protected _dates:MIOManagedObjectSet = null;
    get dates():MIOManagedObjectSet {
        return this.valueForKey('dates');
    }
    addDatesObject(value:PartyEvent) {
        this._addObjectForKey(value, 'dates');
    }
    removeDatesObject(value:PartyEvent) {
        this._removeObjectForKey(value, 'dates');
    }

    // Relationship: images
    protected _images:MIOManagedObjectSet = null;
    get images():MIOManagedObjectSet {
        return this.valueForKey('images');
    }
    addImagesObject(value:PartyImage) {
        this._addObjectForKey(value, 'images');
    }
    removeImagesObject(value:PartyImage) {
        this._removeObjectForKey(value, 'images');
    }
}
