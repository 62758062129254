/**
 * Created by miguel on 1/2/17.
 */




class AddNewSupplierViewController extends MUIViewController
{
    private cancelButton:MUIButton = null;
    private saveButton :MUIButton= null;
    private imageButton:MUIButton = null;
    private nameTextField:MUITextField = null;    
    private documentTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private mobilePhoneTextField:MUITextField = null;
    private addressTextField:MUITextField = null;
    private cityTextField:MUITextField = null;    
    private postalCodeTextField:MUITextField = null;
    private stateTextField:MUITextField = null;
    private countryDropDown:MUIButton = null;
    
    delegate = null;

    get preferredContentSize(){
        return new MIOSize(500, 635);
    }

    viewDidLoad(){    
        super.viewDidLoad();

        this.cancelButton = MUIOutlet(this, 'ansv_cancel_btn', 'MUIButton');             
        this.cancelButton.setAction(this, function (){
            this.dismissViewController(true);
        });

        this.imageButton = MUIOutlet(this, 'ansv_supplier_image', 'MUIButton');
        this.imageButton.setAction(this, function (){
            console.log('open image file');
        });

        this.nameTextField = MUIOutlet(this, "ansv_supplier_tf", "MUITextField");
        this.documentTextField = MUIOutlet(this, "ansv_document_tf", "MUITextField");
        this.emailTextField = MUIOutlet(this, "ansv_email_tf", "MUITextField");
        this.phoneTextField = MUIOutlet(this, "ansv_phone_number_tf", "MUITextField");
        this.mobilePhoneTextField = MUIOutlet(this, "ansv_mobile_number_tf", "MUITextField");
        this.addressTextField = MUIOutlet(this, "ansv_address_tf", "MUITextField");
        this.cityTextField = MUIOutlet(this, "ansv_city_tf", "MUITextField");
        this.postalCodeTextField = MUIOutlet(this, "ansv_postal_code_tf", "MUITextField");
        this.stateTextField = MUIOutlet(this, "state-tf", "MUITextField");
        this.countryDropDown = MUIOutlet(this, "country-dd", "MUIButton");  
        this.countryDropDown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropDown, null, this, function(controller:SelectEntityViewController, country:Country){
                this.countryDropDown.title = country.localizeName;
                this.country = country;
            });
        });    

        this.saveButton = MUIOutlet(this, 'ansv_done_btn', 'MUIButton'); 
        this.saveButton.setAction(this, function (){
            let name = this.nameTextField.text.trim();
            
            this._supplier = DBHelper.createSupplier(name);

            this._supplier.onlySupplierProducts = true;            
            this._supplier.email = this.emailTextField.text.trim();
            this._supplier.document = this.documentTextField.text.trim();

            this.address.address1 = this.addressTextField.text.trim();
            this.address.city = this.cityTextField.text.trim();            
            this.address.postalCode = this.postalCodeTextField.text.trim();
            this.address.state = this.stateTextField.text.trim();
            // this.address.countryName = this.country.name;
            this.address.countryISOA2 = this.country.iso2;
            this.address.countryISOA3 = this.country.iso3;
            this.address.countryISON = this.country.ison;    

            this.phone.number = this.phoneTextField.text.trim();
            this.mobilePhone.number = this.mobilePhoneTextField.text.trim();
            
            this.saveButton.enabled = false;
            if(this.delegate != null) this.delegate.supplierWillInsert(this._supplier);
            
            DBHelper.saveMainContextWithCompletion(this, function() {
                if(this.delegate != null) this.delegate.supplierDidInsert(this._supplier);

                if(this.navigationController != null) {
                    this.navigationController.popViewController();
                }
                else
                    this.dismissViewController(true);    
            })

        });

        this.updateUI();
    }
    
    private _supplier:Supplier = null;
    private country:Country = null;

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private updateUI(){
        this.nameTextField.text = null;
        this.emailTextField.text = null;
        this.documentTextField.text = null;
        this.addressTextField.text = null;
        this.cityTextField.text = null;        
        this.postalCodeTextField.text = null;
        this.phoneTextField.text = null;
        this.mobilePhoneTextField.text = null;        
        this.countryDropDown.title = AppHelper.sharedInstance().defaultCountry.localizeName;        
        this.country = AppHelper.sharedInstance().defaultCountry;

        this.saveButton.enabled = true;
    }

    get address(){
        if(this._supplier.address == null){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this._supplier.address = MIOEntityDescription.insertNewObjectForEntityForName("Address", moc) as Address;
            this._supplier.address.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._supplier.address;
    }

    get phone(){
        if(this._supplier.phone == null){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this._supplier.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber;
            this._supplier.phone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._supplier.phone;
    }

    get mobilePhone(){
        if(this._supplier.mobilePhone == null){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this._supplier.mobilePhone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber;
            this._supplier.mobilePhone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._supplier.mobilePhone;
    } 
}