

// Generated class BookingGuestList_ManagedObject

class BookingGuestList_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: activeFrom
    set activeFrom(value:Date) {
        this.setValueForKey(value, 'activeFrom');
    }
    get activeFrom():Date {
        return this.valueForKey('activeFrom');
    }
    set activeFromPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'activeFrom');
    }
    get activeFromPrimitiveValue():Date {
        return this.primitiveValueForKey('activeFrom');
    }

    // Property: activeTo
    set activeTo(value:Date) {
        this.setValueForKey(value, 'activeTo');
    }
    get activeTo():Date {
        return this.valueForKey('activeTo');
    }
    set activeToPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'activeTo');
    }
    get activeToPrimitiveValue():Date {
        return this.primitiveValueForKey('activeTo');
    }

    // Property: hourFrom
    set hourFrom(value:string) {
        this.setValueForKey(value, 'hourFrom');
    }
    get hourFrom():string {
        return this.valueForKey('hourFrom');
    }
    set hourFromPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'hourFrom');
    }
    get hourFromPrimitiveValue():string {
        return this.primitiveValueForKey('hourFrom');
    }

    // Property: hourTo
    set hourTo(value:string) {
        this.setValueForKey(value, 'hourTo');
    }
    get hourTo():string {
        return this.valueForKey('hourTo');
    }
    set hourToPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'hourTo');
    }
    get hourToPrimitiveValue():string {
        return this.primitiveValueForKey('hourTo');
    }

    // Property: isPrivate
    set isPrivate(value:boolean) {
        this.setValueForKey(value, 'isPrivate');
    }
    get isPrivate():boolean {
        return this.valueForKey('isPrivate');
    }
    set isPrivatePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isPrivate');
    }
    get isPrivatePrimitiveValue():boolean {
        return this.primitiveValueForKey('isPrivate');
    }

    // Property: weekDays
    set weekDays(value:number) {
        this.setValueForKey(value, 'weekDays');
    }
    get weekDays():number {
        return this.valueForKey('weekDays');
    }
    set weekDaysPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'weekDays');
    }
    get weekDaysPrimitiveValue():number {
        return this.primitiveValueForKey('weekDays');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
