


class ClientTicketCell extends MUITableViewCell
{
    private dateLabel:MUILabel = null;
    private payedDateLabel:MUILabel = null;
    private pendingLabel:MUILabel = null;
    private ticketLabel:MUILabel = null;
    private clientNameLabel:MUILabel = null;
    private priceLabel:MUILabel = null;
    private payButton:MUIButton = null;
    private printButton:MUIButton = null;
    delegate = null;
        
    awakeFromHTML(){
        let ad = MUIWebApplication.sharedInstance().delegate;
        this.dateLabel = MUIOutlet(this, 'cticketdv_date_lbl','MUILabel');
        //this.dateLabel.formatter = ad.dateFormatter;
        this.payedDateLabel = MUIOutlet(this, 'cticketdv_payed_date_lbl','MUILabel');
        //this.dateLabel.formatter = ad.dateFormatter;
        this.pendingLabel = MUIOutlet(this, 'cticketdv_pending_lbl','MUILabel');
        this.ticketLabel = MUIOutlet(this, "cticketdv_ticket_lbl", "MUILabel");
        this.priceLabel = MUIOutlet(this, 'cticketdv_value_lbl','MUILabel');

        this.printButton = MUIOutlet(this, 'cticketdv_print_btn','MUIButton');
        this.printButton.setAction(this, function(){
            AppHelper.sharedInstance().printTicket(this._item);
        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private _item:ArchivedTicket = null;
    set item(i:ArchivedTicket){
        this._item = i;
        //this.delegate = i.delegate;

        let nf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
        let df = MUIWebApplication.sharedInstance().delegate.dateFormatter;

        this.ticketLabel.text = i.legalDocumentID;
        this.dateLabel.text = df.stringFromDate(i.date);   
        this.priceLabel.text = nf.stringFromNumber(i.totalPrice);
    }
}