/// <reference path="InventoryTemplateLineCell.ts" />


class InventoryTemplateDetailViewController extends BaseTableViewController
{
    private saveButton:MUIButton = null;
    private titleTextField:MUITextField = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.titleTextField = MUIOutlet(this, "title-tf", "MUITextField");
        this.titleTextField.setOnChangeText(this, function(textField:MUITextField, value:string){
            let v = value.length > 0 ? value : null;
            this.template.name = v;
        });       

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;   
        this.tableView.delegate = this;     
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private template:InventoryTemplate = null;
    set item(item:InventoryTemplate){
        this.template = item;
        this.updateUI();
    }   

    private updateUI(){
        if (this.viewIsLoaded == false || this.template == null) return;

        this.titleTextField.text = this.template.name;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        let sections = this.fetchedResultsController.sections.length + 1;
        return sections;
    }
    
    numberOfRowsInSection(tableview, section){
        if (section == this.fetchedResultsController.sections.length) return 1;

        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell:InventoryTemplateLineCell = null;
                
        if (indexPath.section == this.fetchedResultsController.sections.length) {
            cell = tableview.dequeueReusableCellWithIdentifier('AddLineCell') as InventoryTemplateLineCell;            
            cell.delegate = this;
            cell.template = this.template;
            return cell;
        }

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as InventoryTemplateItem;
        cell = tableview.dequeueReusableCellWithIdentifier('EditLineCell') as InventoryTemplateLineCell;
        cell.item = item;
        cell.delegate = this;  
        cell.template = this.template;      
                        
        return cell;
    }
    
    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {
        if (indexPath.section == this.fetchedResultsController.sections.length) {
            return MUITableViewCellEditingStyle.Insert;
        }
        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            this.removeProductLine(item);
        }
    }  
        
    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.reloadData();
        if (this.addedNewLine == true) {
            //this.tableView.scrollToBottom(false);
            this.addedNewLine = false;
        }
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];    
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("InventoryTemplateItem", sortDescriptors, "template.identifier == " + this.template.identifier);
        fetchRequest.relationshipKeyPathsForPrefetching = ["product.taxBuy", "product.tax", "product.stockInputFormats", "product.category", "product.supplierProducts"];        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    protected addedNewLine = false;
    lineDidInsert(cell:InventoryTemplateLineCell, line:InventoryTemplateItem){
        this.addedNewLine = true;
        DBHelper.saveMainContext();        
    }

    private removeProductLine(line:InventoryTemplateItem){
        DBHelper.deleteObjectFromMainContext(line, true);
    }
}