//
// Generated class Tag
//

/// <reference path="Tag_ManagedObject.ts" />

enum TagType {
    None,
    Ticket,
    Client,
    Product,    
    PayMethod,
    LocationCategory,
    CashDesk,
    Worker,    
    Supplier,
    Menu
}

class Tag extends Tag_ManagedObject
{

}
