/**
 * Created by miguel on 22/2/17.
 */




class EditorViewController extends MUIViewController
{
    private rightPanel:MUIView = null;
    private centerPanel:MUIView = null;
    private leftPanel:MUIView = null;

    private pagesViewController:MUIViewController = null;
    private propertiesViewController = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.leftPanel = MUIOutlet(this, "editor_panel_left");

        this.pagesViewController = new MUIViewController("pages_view");
        this.pagesViewController.initWithResource("layout/editor/PagesView.html");
        this.leftPanel.addSubview(this.pagesViewController.view);
        this.addChildViewController(this.pagesViewController);

        this.rightPanel = MUIOutlet(this, "editor_panel_right");        

        this.propertiesViewController = new MUIViewController("properties_view");
        this.propertiesViewController.initWithResource("layout/editor/PropertiesView.html");
        this.rightPanel.addSubview(this.propertiesViewController.view);
        this.addChildViewController(this.propertiesViewController);                
    }
}