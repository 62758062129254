
class ReportResultsCell extends MUIReportTableViewCell
{
    private titleLabel:MUILabel = null;
    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
    }

    set title(title:string){
        this.titleLabel.text = MIOLocalizeString(title, title);
    }

    set titleAligment(alignment){
        this.titleLabel.setTextAlignment(alignment);
    }

    set bold(value:boolean){
        if (value == true) MUICoreLayerAddStyle(this.titleLabel.layer, "strong");
        else MUICoreLayerRemoveStyle(this.titleLabel.layer, "strong");
    }
    
}