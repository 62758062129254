
class CashDeskSessionTicketTransactionsDataSource extends MIOObject
{
    private tableView:MUITableView|UITableView = null;
    initWithTableView(tableView:MUITableView|UITableView){
        super.init();
        this.tableView = tableView;   
    }

    session:CashDeskSession = null;

    private archivedDocument:ArchivedDocument = null;
    set item(item:ArchivedDocument){
        this.archivedDocument = item;
        this.fetchedResultsController = null;
        this.checkPointsfetchedResultsController = null;
        let objs = this.checkPointsfetchedResultsController.resultObjects;
        if (objs.length > 0){
            this.mapCheckPoints(objs);
            this.tableView.reloadData();
        }
    }

    numberOfSections(tableview:MUITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:MUITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("TransactionCell") as CashDeskSessionTicketTransactionCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as CashDeskLine;

        cell.item = item;

        return cell;
    }

    private _checkPointsfetchedResultsController:MIOFetchedResultsController = null;
    set checkPointsfetchedResultsController(value){
        if (value == null && this._checkPointsfetchedResultsController != null)
            this._checkPointsfetchedResultsController.delegate = null;
    
        this._checkPointsfetchedResultsController = value;
    }
    
    get checkPointsfetchedResultsController(){
        if (this._checkPointsfetchedResultsController != null)
            return this._checkPointsfetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('CheckPoint');
    
        //fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('session', true)];                                                
        //fetchRequest.fetchLimit = 100;
        
        let predicateFormat = "session == " + this.session.identifier;
        fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
    
        let fetchedResultsController = new MIOFetchedResultsController();        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._checkPointsfetchedResultsController = fetchedResultsController;
    
        return this._checkPointsfetchedResultsController;
    }    
        
    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('CashDeskLine');
        fetchRequest.relationshipKeyPathsForPrefetching = ["payMethod.image"];
    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('date', false)];                                                
        
        if (this.checkPointsIDs != null) {                 
            this.checkPointsIDs += " AND type != 16"; //CashDeskOperationTax
            this.checkPointsIDs += " AND type != 17"; //CashDeskOperationTaxBase
            this.checkPointsIDs += " AND type != 18"; //CashDeskOperationDiscount
            this.checkPointsIDs += " AND type != 19"; //CashDeskOperationPeople
    
            let predicateFormat = this.checkPointsIDs + " AND documentID == '" + this.archivedDocument.documentID + "'";
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);    
        }

        //let predicateFormat = "checkpoint == algo AND identifier == algo";        
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }    

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        if (controller == this.checkPointsfetchedResultsController){
            this.mapCheckPoints(controller.fetchedObjects);
            this.fetchedResultsController = null;
            this.tableView.reloadData();
        }
        else {
            this.tableView.reloadData();
        }
        
    }

    private checkPointsIDs = null;

    private mapCheckPoints(checkPoints){
        if (checkPoints.length > 0) {
            this.checkPointsIDs = "";
            let checkPoint:CheckPoint = checkPoints[0];
            this.checkPointsIDs += "checkPointID == " + checkPoint.identifier;
            for (let index = 1; index < checkPoints.length; index++){
                checkPoint = checkPoints[index];
                this.checkPointsIDs += "AND checkPointID == " + checkPoint.identifier;                                    
            }    
        }
    }

}