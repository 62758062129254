

class CashDeskSessionLinesViewController extends MUIViewController
{        
    private tableView:MUITableView = null;

    private typeFilterTextField:ColumnFilterTextField = null;    
	private conceptFilterTextField:ColumnFilterTextField = null; 
	private numberFilterTextField:ColumnFilterTextField = null;
    private locationFilterTextField:ColumnFilterTextField = null;   
	private paymethodFilterTextField:ColumnFilterTextField = null;
	private infoFilterTextField:ColumnFilterTextField = null;
    private amountFilterTextField:ColumnFilterTextField = null;

    private columnFilterController:ColumnFilterController = null;

    viewDidLoad(){
        super.viewDidLoad();    
        
        this.columnFilterController = new ColumnFilterController();
        this.columnFilterController.initWithDelegate(this);

        this.typeFilterTextField = MUIOutlet(this, "type-filter-tf", "ColumnFilterTextField");
        this.typeFilterTextField.filterController = this.columnFilterController;
        this.typeFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "type", null, null);

        this.conceptFilterTextField = MUIOutlet(this, "concept-filter-tf", "ColumnFilterTextField");
        this.conceptFilterTextField.filterController = this.columnFilterController;
		this.conceptFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "concept", null, null);
		
		this.numberFilterTextField = MUIOutlet(this, "number-filter-tf", "ColumnFilterTextField");
		this.numberFilterTextField.filterController = this.columnFilterController;
		this.numberFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "legalDocumentID", null, null);

        this.locationFilterTextField = MUIOutlet(this, "location-filter-tf", "ColumnFilterTextField");
        this.locationFilterTextField.filterController = this.columnFilterController;
        this.locationFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "locationCategoryName", null, null);

        this.paymethodFilterTextField = MUIOutlet(this, "paytmethod-filter-tf", "ColumnFilterTextField");
        this.paymethodFilterTextField.filterController = this.columnFilterController;
		this.paymethodFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "payMethodName", null, null);
		
		this.infoFilterTextField = MUIOutlet(this, "info-filter-tf", "ColumnFilterTextField");
        this.infoFilterTextField.filterController = this.columnFilterController;
        this.infoFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "payMethodSubtype", null, null);
        
        this.amountFilterTextField = MUIOutlet(this, "amount-filter-tf", "ColumnFilterTextField");
        this.amountFilterTextField.filterController = this.columnFilterController;
        this.amountFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "money", null, null);

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    viewWillAppear(animted?){
        super.viewWillAppear(animted);
        this.updateUI();
    }

    private _session:CashDeskSession = null;
    set session(i:CashDeskSession){
        this._session = i;
        this.updateUI();
    }

    private updateUI(){
        if (this._session != null && this.viewIsLoaded){
            this.fetchedResultsController = null;
            this.checkPointsfetchedResultsController = null;
            let objs = this.checkPointsfetchedResultsController.resultObjects;
            if (objs.length > 0){
                this.mapCheckPoints(objs);
                this.tableView.reloadData();
            }
        }
    }

    numberOfSections(tableview:MUITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:MUITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("LineCell") as CashDeskSessionLineCell;
        let item:CashDeskLine = this.fetchedResultsController.objectAtIndexPath(indexPath);

        cell.item = item;

        return cell;
    }

    private _checkPointsfetchedResultsController:MIOFetchedResultsController = null;
    set checkPointsfetchedResultsController(value){
        if (value == null && this._checkPointsfetchedResultsController != null)
            this._checkPointsfetchedResultsController.delegate = null;
    
        this._checkPointsfetchedResultsController = value;
    }
    
    get checkPointsfetchedResultsController(){
        if (this._checkPointsfetchedResultsController != null)
            return this._checkPointsfetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('CheckPoint');
    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('session', true)];                                                
        //fetchRequest.fetchLimit = 100;
        
        let predicateFormat = "session == " + this._session.identifier;
        fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
    
        let fetchedResultsController = new MIOFetchedResultsController();        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._checkPointsfetchedResultsController = fetchedResultsController;
    
        return this._checkPointsfetchedResultsController;
    }    
        
    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('CashDeskLine');
        fetchRequest.relationshipKeyPathsForPrefetching = ["payMethod.image"];
    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('date', false)];                                                
        
        if (this.checkPointsIDs != null) {                 
            this.checkPointsIDs += " AND type != 16"; //CashDeskOperationTax
            this.checkPointsIDs += " AND type != 17"; //CashDeskOperationTaxBase
            this.checkPointsIDs += " AND type != 18"; //CashDeskOperationDiscount
            this.checkPointsIDs += " AND type != 19"; //CashDeskOperationPeople
    
            let filterFormat = this.columnFilterController.filterPredicateFormat();
            if (filterFormat != null) {
                this.checkPointsIDs += " AND (" + filterFormat + ")";
            }

            fetchRequest.predicate = MIOPredicate.predicateWithFormat(this.checkPointsIDs);    
        }        
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }    

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        if (controller == this.checkPointsfetchedResultsController){
            this.mapCheckPoints(controller.fetchedObjects);
            this.fetchedResultsController = null;
            this.tableView.reloadData();
        }
        else {
            this.tableView.reloadData();
        }
        
    }

    private checkPointsIDs = null;

    private mapCheckPoints(checkPoints){
        if (checkPoints.length > 0) {
            this.checkPointsIDs = "";
            let checkPoint:CheckPoint = checkPoints[0];
            this.checkPointsIDs += "checkPoint.identifier == '" + checkPoint.identifier + "'";
            for (let index = 1; index < checkPoints.length; index++){
                checkPoint = checkPoints[index];
                this.checkPointsIDs += "OR checkPoint.identifier == '" + checkPoint.identifier + "'";                                    
            }    
        }
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.mapCheckPoints(this.checkPointsfetchedResultsController.fetchedObjects);
        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

}