//
// Generated class IssuedInvoice
//

/// <reference path="IssuedInvoice_ManagedObject.ts" />

class IssuedInvoice extends IssuedInvoice_ManagedObject
{
/*
    setObject(object, moc)
    {
        this.identifier      = object['id'];
        this.isArchived      = object['isarchived'];
        this.num             = object['documentid'];
        this.id_receipt      = object['idreceipt'];
        this.receiptid       = object['invoicelegalentityid'];
        this.receiptname     = object['invoicelegalentityname'];
        this.receiptphone    = object['invoicelegalentityphone'];
        this.receiptaddress  = object["invoicelegalentityaddress"];
        this.receiptaddress2 = object["invoicelegalentityaddress2"];
        this.receiptdocument = object["invoicelegalentitydocument"];
        this.modifiedbyuser  = object["modifiedbyuser"];
        this.closed          = object['closed'];
        this.tags            = object['tags'];
        this.invoiceDate     = this.df.dateFromString(object["date"]);
        this.invoiceExpirationDate  = this.df.dateFromString(object["expirationdate"]);
        this.paymethodid     = object['paymethodid'];
        this.paymethodname   = object['paymethodname'];
        this.totalpricebase  = object['totalpricebase'] || 0;
        this.totaltaxes      = object['totaltaxes'] || 0;
        this.totalprice      = object['totalprice'] || 0;
        this.totaldiscount   = object['totaldiscount'] || 0;
        this.totalinvited    = object['totalinvited'] || 0;
        this.totaldeleted    = object['totaldeleted'] || 0;
        this.isincominginvoice = false;
        this.nextdocument    = object['nextdocument'];
        
        this.groups = MIOSet.set();
        
        let key = this.isArchived ? 'archivedTicketLinesGroup' : 'documentGroup';

        if(object[key]){
            var instance = this;
            var groups = object[key] || [];
            groups.forEach(function (group, index) {
                var p:IssuedInvoiceGroupLine = MIOEntityDescription.insertNewObjectForEntityForName("IssuedInvoiceGroupLine", moc) as IssuedInvoiceGroupLine;
                group['invoice'] = instance;
                p.setObject(group, index, moc);
                instance.groups.addObject(p);
            }, instance);
        }
    }*/
    
    // get total()
    // {
    //     let total = 0;

    //     for(let i = 0; i < this.groups.length; i++){
    //         let group:IssuedInvoiceGroupLine = this.groups.objectAtIndex(i);
    //         total += group.price;
    //     }
    //     return total;
    // }
    // get status()
    // {
    //     return this.closed == true ? 'Closed' : 'Pending';
    // }
    // get archived()
    // {
    //     return this.isArchived == true ? 'Archived' : 'Open';
    // }
    // get dateString()
    // {
    //     let date:Date = this.df.dateFromString(this.date);
    //     return this.df.stringFromDate(date);
    // }

    // get totalWithTax()
    // {
    //     let total = 0;
    //     for(let i = 0; i < this.groups.length; i++){
    //         let group:IssuedInvoiceGroupLine = this.groups.objectAtIndex(i);
    //         total += group.priceWithTax;
    //     }
    
    //     return total;
    // }

}
