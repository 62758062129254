

enum SectionViewControllerType
{    
    None,
    Product = "99000000-0000-1111-1111-000000000001",    
    Category = "99000000-0000-1111-1111-000000000002",
    Menus = "99000000-0000-1111-1111-000000000003",
    ProductModifiers = "99000000-0000-1111-1111-000000000004",
    IssuedInvoices = "99000000-0000-1111-1111-000000000005",
    Tickets = "99000000-0000-1111-1111-000000000006",
    Logs = "99000000-0000-1111-1111-000000000007",
    CashDesk = "99000000-0000-1111-1111-000000000008",
    InventoryTemplates = "99000000-0000-1111-1111-000000000009",
    Client = "99000000-0000-1111-1111-000000000010",
    Worker = "99000000-0000-1111-1111-000000000011",
    Supplier = "99000000-0000-1111-1111-000000000012",
    Bookings = "99000000-0000-1111-1111-000000000013",
    NoShow = "99000000-0000-1111-1111-000000000014",
    SalesSummary = "99000000-0000-1111-1111-000000000015",
    ReportBuilder = "99000000-0000-1111-1111-000000000016",
    Stock = "99000000-0000-1111-1111-000000000017",
    StockOrderInNote = "99000000-0000-1111-1111-000000000018",
    Reports = "99000000-0000-1111-1111-000000000019",
    //Lapsopay = "99000000-0000-1111-1111-000000000020",
    //Feeding = "99000000-0000-1111-1111-000000000021",
    Offers = "99000000-0000-1111-1111-000000000022",
    Guests = "99000000-0000-1111-1111-000000000023",
    SupplierReturnNote = "99000000-0000-1111-1111-000000000024",
    BookingSources = "99000000-0000-1111-1111-000000000025",
    Events = "99000000-0000-1111-1111-000000000026",
    SupplierNote = "99000000-0000-1111-1111-000000000027",
    InventoryNote = "99000000-0000-1111-1111-000000000028",
    MovementNote = "99000000-0000-1111-1111-000000000029",
    InternalReturnOrder = "99000000-0000-1111-1111-000000000030",
    GuestLists = "99000000-0000-1111-1111-000000000031",
    //SUPPLIER INVOICES = "99000000-0000-1111-1111-000000000032",
    Settings = "99000000-0000-1111-1111-000000000033",
    //EDITOR ="99000000-0000-1111-1111-000000000034",
    OutputNote = "99000000-0000-1111-1111-000000000035",
    SupportTickets = "99000000-0000-1111-1111-000000000036",
    //BUDGET = "99000000-0000-1111-1111-000000000037",
    DeliveryNote = "99000000-0000-1111-1111-000000000038",
    Licenses = "99000000-0000-1111-1111-000000000039",
    DBBackup = "99000000-0000-1111-1111-000000000040",
    DBTool = "99000000-0000-1111-1111-000000000041",
    OperationSummary = "99000000-0000-1111-1111-000000000042",
    WorkerAdmin = "99000000-0000-1111-1111-000000000043",
    //DOCUMENT TRACKING = "99000000-0000-1111-1111-000000000044",
    //VIP CARDS = "99000000-0000-1111-1111-000000000045",
    //VIP RESPONSIBLES = "99000000-0000-1111-1111-000000000046",
    Parties = "99000000-0000-1111-1111-000000000047",
    StockOrderOutNote = "99000000-0000-1111-1111-000000000048",
    Cards = "99000000-0000-1111-1111-000000000049",
    DBMapping = "99000000-0000-1111-1111-000000000050",
    DBSync = "99000000-0000-1111-1111-000000000051",
    DBSyncLog = "99000000-0000-1111-1111-000000000052",
    ProductAdmin = "99000000-0000-1111-1111-000000000053",
    UserAccess = "99000000-0000-1111-1111-000000000054",
    LowStockOrder = "99000000-0000-1111-1111-000000000055",
    SupplierOrder = "99000000-0000-1111-1111-000000000056",
    DeliveryOrder = "99000000-0000-1111-1111-000000000057",
    //Bank Account = "99000000-0000-1111-1111-000000000058",
    DashBoard = "99000000-0000-1111-1111-000000000059",
    StockFlowsSummary = "99000000-0000-1111-1111-000000000060",
    OrdersSummary = "99000000-0000-1111-1111-000000000061",
    ProductMixSummary = "99000000-0000-1111-1111-000000000062",
    ClientHistorySummary = "99000000-0000-1111-1111-000000000063",
	//ReportsAccess = "99000000-0000-1111-1111-000000000064",
    WorkerSessionTool = "99000000-0000-1111-1111-000000000064",
    RoomSchedule = "99000000-0000-1111-1111-000000000065",
    RoomBooking = "99000000-0000-1111-1111-000000000066"
}

class SectionHelper extends MIOObject
{
    private static _sharedInstance: SectionHelper = null;
    static sharedInstance(): SectionHelper {

        if (this._sharedInstance == null) {
            this._sharedInstance = new SectionHelper();
            this._sharedInstance.init();
        }

        return this._sharedInstance;
    }

    init(){
        super.init();
        MIONotificationCenter.defaultCenter().addObserver(this, "WorkspaceDidChange", function(notification:MIONotification){            
            this.reset();
        });
    }

    private sectionListViewController:SectionListViewController = null;
    sectionsViewController(){
        if (this.sectionListViewController != null) return this.sectionListViewController;

        this.sectionListViewController = new SectionListViewController("section-list-view");
        this.sectionListViewController.initWithResource("layout/sections/SectionListView.html");
        this.sectionListViewController.modalPresentationStyle = MUIModalPresentationStyle.Custom;

        return this.sectionListViewController;
    }

    sectionViewControllerForType(type):MUIViewController {

        switch (type) {
            case SectionViewControllerType.DashBoard:
            return this.dashBoardViewController();

            case SectionViewControllerType.OperationSummary:
            return this.operationSummaryViewController();

            case SectionViewControllerType.SalesSummary:
            return this.salesSummaryViewController();

            case SectionViewControllerType.StockFlowsSummary:
            return this.stockFlowsSummaryViewController();

            case SectionViewControllerType.OrdersSummary:
            return this.ordersSummaryViewController();

            case SectionViewControllerType.ProductMixSummary:
            return this.productMixSummaryViewController();    

            case SectionViewControllerType.ClientHistorySummary:
            return this.clientHistorySummaryViewController();  

            case SectionViewControllerType.Reports:
            return this.reportsViewController();

            case SectionViewControllerType.Logs:
            return this.logsViewController();

            // ACOUNTING
            case SectionViewControllerType.CashDesk:
            return this.cashdeskViewController();

            case SectionViewControllerType.Tickets:
            return this.ticketsViewController();

            case SectionViewControllerType.IssuedInvoices:
            return this.issuedInvoicesViewController();
            
            // USERS
            case SectionViewControllerType.Client:
            return this.clientsViewController();
            
            case SectionViewControllerType.Worker:
            return this.workersViewController();

            case SectionViewControllerType.WorkerAdmin:
            return this.workersAdminViewController();

            case SectionViewControllerType.UserAccess:
            return this.userAccessViewController();

            /*case SectionViewControllerType.ReportsAccess:
            return this.reportsAccessViewController();*/

            case SectionViewControllerType.Cards:
			return this.cardsViewController();
			
			case SectionViewControllerType.WorkerSessionTool:
			return this.workerSessionToolViewController();

            // PRODUCTS            
            case SectionViewControllerType.Product:
            return this.productsViewController();

            case SectionViewControllerType.Category:
            return this.categoriesViewController();
            
            case SectionViewControllerType.ProductModifiers:
            return this.productModifiersViewController();

            case SectionViewControllerType.Menus:
            return this.menusViewController();

            case SectionViewControllerType.ProductAdmin:
            return this.productComponentsViewController();

            case SectionViewControllerType.Offers:
            return this.offersViewController();

            // BOOKINGS
            case SectionViewControllerType.Bookings:
            return this.bookingsViewController();

            case SectionViewControllerType.NoShow:
            return this.noShowViewController();

            case SectionViewControllerType.Guests:
            return this.guestsViewController();

            case SectionViewControllerType.GuestLists:
            return this.guestListViewController();

            case SectionViewControllerType.BookingSources:
            return this.bookingSourcesViewController();

            case SectionViewControllerType.Events:
            return this.eventsViewController();

            case SectionViewControllerType.Parties:
            return this.partiesViewController();

            // STOCK
            case SectionViewControllerType.Supplier:
            return this.suppliersViewController();

            // case SectionViewControllerType.StockProducts:
            // return this.stockProductsViewController();

            case SectionViewControllerType.Stock:
            return this.stockViewController();

            case SectionViewControllerType.InventoryNote:
            return this.inventoryNotesViewController();

            case SectionViewControllerType.InventoryTemplates:
            return this.inventoryTemplateViewController();
        
            case SectionViewControllerType.MovementNote:
            return this.movementNotesViewController();

            case SectionViewControllerType.OutputNote:
            return this.outputNotesViewController();

            case SectionViewControllerType.LowStockOrder:
            return this.lowStockProductViewController();

            case SectionViewControllerType.SupplierOrder:
            return this.supplierOrdersViewController();

            case SectionViewControllerType.SupplierNote:
            return this.supplierNotesViewController();

            case SectionViewControllerType.SupplierReturnNote:
            return this.supplierReturnNotesViewController();

            case SectionViewControllerType.DeliveryNote:
            return this.deliveryNotesViewController();

            case SectionViewControllerType.StockOrderInNote:
            return this.stockOrdersInViewController();

            case SectionViewControllerType.StockOrderOutNote:
            return this.stockOrdersOutViewController();

            case SectionViewControllerType.InternalReturnOrder:
            return this.internalReturnOrderViewController();
           

            // CONFIGURATION
            case SectionViewControllerType.Settings:
            return this.settingsViewController();

            case SectionViewControllerType.DBBackup:
            return this.dbBackupToolViewController();

            case SectionViewControllerType.DBTool:
            return this.dbToolViewController();

            case SectionViewControllerType.DBMapping:
            return this.dbMappingViewController();

            case SectionViewControllerType.DBSync:
            return this.dbSyncViewController();

            case SectionViewControllerType.DBSyncLog:
            return this.dbSyncLogViewController();

            case SectionViewControllerType.ReportBuilder:
            return this.reportBuilderViewController();

            // DUAL LINK
            case SectionViewControllerType.Licenses:
            return this.licensesViewController();

            case SectionViewControllerType.SupportTickets:
            return this.supportTicketsViewController();

            case SectionViewControllerType.RoomSchedule:
            return this.roomScheduleController();

            case SectionViewControllerType.RoomBooking:
            return this.roomBookingController();
        }

        let vc = new BaseViewController("section-none-view");        
        vc.initWithResource("layout/base/SectionNoneView.html");

        return vc;
    }

    private reset(){
        this._dashboardViewController = null;
        this._operationSummaryViewController = null;        
        this._reportsViewController = null;
        this._logViewController = null;
        this._cashdeskViewController = null;
        this._ticketsViewController = null;
        this._issuedInvoiceViewController = null;
        this._clientViewController = null;
        this._workersViewController = null;
		this._workersAdminViewController = null;
		this._workerSessionToolViewController = null;
        this._userAccessViewController = null;
        //this._reportsAccessViewController = null;
        this._cardListViewController = null;
        this._productViewController = null;
        this._productModifiersViewController = null;
        this._menusViewController = null;
        this._categoriesViewController = null;
        this._productComponentViewController = null;
        this._offerViewController = null;
        this._bookingViewController = null;
        this._noShowViewController = null;
        this._guestViewController = null;
        this._guestListViewController = null;
        this._bookingSourceViewController = null;
        this._eventsViewController = null;
        this._partyViewController = null;
        this._supplierViewController = null;
        this._stockViewController = null;
        this._inventoryNoteViewController = null;
        this._movementNoteViewController = null;
        this._outputNoteViewController = null;
        this._lowStockProductsViewController = null;
        this._supplierOrderViewController = null;
        this._supplierNoteViewController = null;
        this._deliveryNoteViewController = null;
        this._stockOrderOutViewController = null;
        this._stockOrderInViewController = null;
        this._settingsViewController = null;
        this._dbBackupToolViewController = null;
        this._dbToolViewController = null;
        this._dbSyncViewController = null;
        this._dbMappingViewController = null;
        this._dbSyncLogViewController = null;
        this._reportBuilderViewController = null;
        this._licensesViewController = null;

    }

    get canSeeDashBoard():boolean{
        let permissions = SettingsHelper.sharedInstance().permisisons;
        let value = permissions[53]; // Dashboard
        return (value == "1");
    }

    private _dashboardViewController = null;
    private dashBoardViewController() {
        if (this._dashboardViewController != null) return this._dashboardViewController;

        let vc = new DashboardViewController('dashboard-view');
        vc.initWithResource('layout/dashboard/DashboardView.html');

        // let vc = new ReportViewController("report-detail-view");
        // vc.initWithResource("layout/reports/ReportDetailView.html");
        // vc.title = MIOLocalizeString("DASHBOARD", "DASHBOARD");
        // //this._operationSummaryViewController.reportID = "C1E32789-9434-41C2-8364-2D6D1CF951DD";
        // vc.reportScriptPath = "python/dashboard.py";
        // vc.showSectionButton = true;

        let nc = new MUINavigationController('dashboard_nav_controller');
        nc.initWithRootViewController(vc);

        this._dashboardViewController = nc;
        return nc;
    }


    private _operationSummaryViewController:MUINavigationController = null;
    private operationSummaryViewController(){
        if (this._operationSummaryViewController != null) return this._operationSummaryViewController;

        let vc = new ReportViewController("report-detail-view");
        vc.initWithResource("layout/reports/ReportDetailView.html");
        vc.title = MIOLocalizeString("OPERATION SUMMARY", "OPERATION SUMMARY");
        //this._operationSummaryViewController.reportID = "C1E32789-9434-41C2-8364-2D6D1CF951DD";
        vc.reportScriptPath = "python/operation_summary.py";
        vc.showSectionButton = true;
        
        this._operationSummaryViewController = new MUINavigationController();
        this._operationSummaryViewController.initWithRootViewController(vc);

        return this._operationSummaryViewController;
    }

    private _salesSummaryViewController:ReportViewController = null;
    private salesSummaryViewController(){
        if (this._salesSummaryViewController != null) return this._salesSummaryViewController;

        this._salesSummaryViewController = new ReportViewController("report-detail-view");
        this._salesSummaryViewController.initWithResource("layout/reports/ReportDetailView.html");
        this._salesSummaryViewController.title = MIOLocalizeString("SALES SUMMARY", "SALES SUMMARY");
        this._salesSummaryViewController.reportScriptPath = "python/sales_summary.py";
        this._salesSummaryViewController.showSectionButton = true;
        
        return this._salesSummaryViewController;
    }

    private _stockflowsSummaryViewController:ReportViewController = null;
    private stockFlowsSummaryViewController(){
        if (this._stockflowsSummaryViewController != null) return this._stockflowsSummaryViewController;

        this._stockflowsSummaryViewController = new ReportViewController("report-detail-view");
        this._stockflowsSummaryViewController.initWithResource("layout/reports/ReportDetailView.html");
        this._stockflowsSummaryViewController.title = MIOLocalizeString("STOCKFLOWS SUMMARY", "STOCKFLOWS SUMMARY");
        this._stockflowsSummaryViewController.reportScriptPath = "python/stock_flow_summary.py";
        this._stockflowsSummaryViewController.showSectionButton = true;
        
        return this._stockflowsSummaryViewController;
    }

    private _ordersSummaryViewController:ReportViewController = null;
    private ordersSummaryViewController(){
        if (this._ordersSummaryViewController != null) return this._ordersSummaryViewController;

        this._ordersSummaryViewController = new ReportViewController("report-detail-view");
        this._ordersSummaryViewController.initWithResource("layout/reports/ReportDetailView.html");
        this._ordersSummaryViewController.title = MIOLocalizeString("ORDERS SUMMARY", "ORDERS SUMMARY");
        this._ordersSummaryViewController.reportScriptPath = "python/order_summary.py";
        this._ordersSummaryViewController.showSectionButton = true;
        
        return this._ordersSummaryViewController;
    }

    private _productMixSummaryViewController:ReportViewController = null;
    private productMixSummaryViewController(){
        if (this._productMixSummaryViewController != null) return this._productMixSummaryViewController;

        this._productMixSummaryViewController = new ReportViewController("report-detail-view");
        this._productMixSummaryViewController.initWithResource("layout/reports/ReportDetailView.html");
        this._productMixSummaryViewController.title = MIOLocalizeString("PRODUCT MIX SUMMARY", "PRODUCT MIX SUMMARY");
        this._productMixSummaryViewController.reportScriptPath = "python/product_summary.py";
        this._productMixSummaryViewController.showSectionButton = true;
        
        return this._productMixSummaryViewController;
    }

    private _clientHistorySummaryViewController:MUINavigationController = null;
    private clientHistorySummaryViewController(){
        if (this._clientHistorySummaryViewController != null) return this._clientHistorySummaryViewController;

        let vc = new ReportViewController("report-detail-view");
        vc.initWithResource("layout/reports/ReportDetailView.html");
        vc.title = MIOLocalizeString("CLIENT HISTORY SUMMARY", "CLIENT HISTORY SUMMARY");
        vc.reportScriptPath = "python/client_summary.py";
        vc.showSectionButton = true;
        
        this._clientHistorySummaryViewController = new MUINavigationController();
        this._clientHistorySummaryViewController.initWithRootViewController(vc);

        return this._clientHistorySummaryViewController;
	}
	
	private _workerSessionToolViewController:MUIViewController = null;
	private workerSessionToolViewController() {
		if (this._workerSessionToolViewController != null) return this._workerSessionToolViewController;

		this._workerSessionToolViewController = new ReportViewController("report-summary-view");
        this._workerSessionToolViewController.initWithResource("layout/workers/WorkerSessionToolView.html");
                
        return this._workersViewController;
	}

    private _reportsViewController = null;
    private reportsViewController() {
        if (this._reportsViewController != null) return this._reportsViewController;
        
        let mvc = new ReportListViewController("reports-list-view");
        mvc.initWithResource("layout/reports/ReportsListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Report", NoItemSelectedViewControllerType.Report);        

        this._reportsViewController = new MUISplitViewController();
        this._reportsViewController.init();    
        this._reportsViewController.setMasterViewController(mvc);
        this._reportsViewController.setDetailViewController(dvc);    

        return this._reportsViewController;
    }

    private _logViewController:LogViewController = null;
    private logsViewController():LogViewController{
        if (this._logViewController != null) return this._logViewController;

        if (MIOCoreIsPhone() == true) {
            this._logViewController = new LogViewController("log-view_phone");
            this._logViewController.initWithResource("layout/log/LogView_phone.html");    
        }
        else {
            this._logViewController = new LogViewController("log-view");
            this._logViewController.initWithResource("layout/log/LogView.html");    
        }

        return this._logViewController;
    }

    //
    // ACOUNTING
    // 

    private _cashdeskViewController = null;    
    private cashdeskViewController(){        
        if (this._cashdeskViewController != null) return this._cashdeskViewController;

        let mvc = new CashDeskListViewController("cash-desk-list-view");
        mvc.initWithResource("layout/cashdesk/CashDeskListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("CashDesk", NoItemSelectedViewControllerType.CashDesk);

        this._cashdeskViewController = new MUISplitViewController();
        this._cashdeskViewController.init();     
        this._cashdeskViewController.setMasterViewController(mvc);
        this._cashdeskViewController.setDetailViewController(dvc);            
        
        return this._cashdeskViewController;
    }

    private _ticketsViewController = null;    
    private ticketsViewController(){
        if (this._ticketsViewController != null) return this._ticketsViewController;
        
        let mvc = new TicketListViewController("ticket-list-view");
        mvc.initWithResource("layout/tickets/TicketListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Ticket", NoItemSelectedViewControllerType.Ticket);

        this._ticketsViewController = new MUISplitViewController();
        this._ticketsViewController.init();
        this._ticketsViewController.setMasterViewController(mvc);
        this._ticketsViewController.setDetailViewController(dvc);

        return this._ticketsViewController;
    }

    private _issuedInvoiceViewController:MUISplitViewController = null;    
    private issuedInvoicesViewController(){    
        if (this._issuedInvoiceViewController != null) return this._issuedInvoiceViewController;
        
        let mvc = new IssuedInvoiceListViewController("issued-invoice-list-view");        
        mvc.initWithResource("layout/issuedinvoice/IssuedInvoiceListView.html");
        mvc.mode = InvoiceType.OutcomingInvoice;

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Invoice", NoItemSelectedViewControllerType.Invoice);

        this._issuedInvoiceViewController = new MUISplitViewController();
        this._issuedInvoiceViewController.init();
        this._issuedInvoiceViewController.setMasterViewController(mvc);
        this._issuedInvoiceViewController.setDetailViewController(dvc);        

        return this._issuedInvoiceViewController;
    }

    //
    // USERS
    //

    private _clientViewController:MUISplitViewController = null;    
    private clientsViewController(){
        if (this._clientViewController != null) return this._clientViewController;

        let mvc = new ClientsListViewController("clients-list-view");
        mvc.initWithResource("layout/clients/ClientsListView.html");
        
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Client", NoItemSelectedViewControllerType.Client);

        this._clientViewController = new MUISplitViewController();
        this._clientViewController.init();
        this._clientViewController.setMasterViewController(mvc);
        this._clientViewController.setDetailViewController(dvc);

        return this._clientViewController;
    }

    private _workersViewController:MUISplitViewController = null;    
    private workersViewController(){
        if (this._workersViewController != null) return this._workersViewController;
        
        let mvc = new WorkerListViewController("workers-list-view");
        mvc.initWithResource("layout/workers/WorkersListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Workers", NoItemSelectedViewControllerType.Worker);

        this._workersViewController = new MUISplitViewController();
        this._workersViewController.init();
        this._workersViewController.setMasterViewController(mvc);
        this._workersViewController.setDetailViewController(dvc);
                
        return this._workersViewController;
    }

    private _workersAdminViewController:WorkerAdminViewController = null;    
    private workersAdminViewController() {
        if (this._workersAdminViewController != null) return this._workersAdminViewController;

        this._workersAdminViewController = new WorkerAdminViewController('worker-admin-view');
        this._workersAdminViewController.initWithResource("layout/workers/WorkerAdminView.html");

        return this._workersAdminViewController;
    }

    private _userAccessViewController:UserAccessViewController = null;    
    private userAccessViewController(){
        if (this._userAccessViewController != null) return this._userAccessViewController;

        this._userAccessViewController = new UserAccessViewController('users-view');
        this._userAccessViewController.initWithResource("layout/users/UsersView.html");        

        return this._userAccessViewController;
    }

    /*private _reportsAccessViewController:ReportsAccessViewController = null;    
    private reportsAccessViewController(){
        if (this._reportsAccessViewController != null) return this._reportsAccessViewController;

        this._reportsAccessViewController = new ReportsAccessViewController('users-view');
        this._reportsAccessViewController.initWithResource("layout/users/UsersView.html");        

        return this._reportsAccessViewController;
    }*/

    private _cardListViewController:CardListViewController = null;    
    private cardsViewController() {
        if (this._cardListViewController != null) return this._cardListViewController;

        this._cardListViewController = new CardListViewController('cards-list-view');
        this._cardListViewController.initWithResource("layout/cards/CardsListView.html");

        return this._cardListViewController;
    }

    //
    // PRODUCTS
    //

    private _productViewController:MUISplitViewController = null;    
    private productsViewController(){
        if (this._productViewController != null) return this._productViewController;

        let mvc = new ProductListViewController("products-list-view");
        mvc.initWithResource("layout/products/ProductsListView.html");

        let nc = new MUINavigationController();
        nc.initWithRootViewController(mvc);

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Product", NoItemSelectedViewControllerType.Product);

        this._productViewController = new MUISplitViewController();
        this._productViewController.init();
        this._productViewController.setMasterViewController(nc);
        this._productViewController.setDetailViewController(dvc);

        return this._productViewController;        
    }

    private _categoriesViewController:MUISplitViewController = null;    
    private categoriesViewController() {        
        if (this._categoriesViewController != null) return this._categoriesViewController;

        let mvc = new ProductCategoryListViewController("product-category-list-view");
        mvc.initWithResource("layout/productcategory/ProductCategoryListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Category", NoItemSelectedViewControllerType.Category);

        this._categoriesViewController = new MUISplitViewController();
        this._categoriesViewController.init();
        this._categoriesViewController.setMasterViewController(mvc);
        this._categoriesViewController.setDetailViewController(dvc);

        return this._categoriesViewController;
    }

    private _productModifiersViewController:MUISplitViewController = null;    
    private productModifiersViewController() {        
        if (this._productModifiersViewController != null) return this._productModifiersViewController;

        let mvc = new ProductModifiersListViewController("product-modifiers-list-view");
        mvc.initWithResource("layout/productmodifiers/ProductModifiersListView.html");

        let nc = new MUINavigationController();
        nc.initWithRootViewController(mvc);

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Product modifiers", NoItemSelectedViewControllerType.ProductModifiers);

        this._productModifiersViewController = new MUISplitViewController();
        this._productModifiersViewController.init();
        this._productModifiersViewController.setMasterViewController(nc);
        this._productModifiersViewController.setDetailViewController(dvc);

        return this._productModifiersViewController;

    }

    private _menusViewController:MUISplitViewController = null;    
    private menusViewController() {        
        if (this._menusViewController != null) return this._menusViewController;

        let mvc = new MenusListViewController("menus-list-view");
        mvc.initWithResource("layout/menus/MenusListView.html");

        let nc = new MUINavigationController();
        nc.initWithRootViewController(mvc);

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Menus", NoItemSelectedViewControllerType.Menus);

        this._menusViewController = new MUISplitViewController();
        this._menusViewController.init();
        this._menusViewController.setMasterViewController(nc);
        this._menusViewController.setDetailViewController(dvc);

        return this._menusViewController;

    }

    private _productComponentViewController:ProductAdminListViewController = null;    
    private productComponentsViewController(){
        if (this._productComponentViewController != null) return this._productComponentViewController;

        this._productComponentViewController = new ProductAdminListViewController('product-admin-view');
        this._productComponentViewController.initWithResource("layout/productadmin/ProductAdminView.html");

        return this._productComponentViewController;
    }

    private _offerViewController:MUISplitViewController = null;    
    private offersViewController(){
        if (this._offerViewController != null) return this._offerViewController;
        
        let mvc = new OffersListViewController("offer-list-view");
        mvc.initWithResource("layout/offers/OffersListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Offer", NoItemSelectedViewControllerType.Offer);

        this._offerViewController = new MUISplitViewController();
        this._offerViewController.init();
        this._offerViewController.setMasterViewController(mvc);
        this._offerViewController.setDetailViewController(dvc);

        return this._offerViewController;
    }

    //
    // BOOKINGS
    //

    private _bookingViewController:BookingsViewController = null;
    private bookingsViewController() {
        if (this._bookingViewController != null) return this._bookingViewController;

        this._bookingViewController = new BookingsViewController();
        this._bookingViewController.init()
        
        return this._bookingViewController;
    }

    private _noShowViewController:NoShowViewController = null;
    private noShowViewController() {
        if (this._noShowViewController != null) return this._noShowViewController;

        this._noShowViewController = new NoShowViewController("noshow-view");
        this._noShowViewController.initWithResource("layout/bookings/noshow/NoShowView.html");

        return this._noShowViewController;
    }

    private _guestViewController:GuestViewController = null;
    private guestsViewController() {
        if (this._guestViewController != null) return this._guestViewController;

        this._guestViewController = new GuestViewController("guest-view");
        this._guestViewController.initWithResource("layout/bookings/guest/GuestView.html");

        return this._guestViewController;
    }

    private _guestListViewController:MUISplitViewController = null;
    private guestListViewController() {
        if (this._guestListViewController != null) return this._guestListViewController;

        let mvc = new GuestlistListViewController("guestlist-list-view");
        mvc.initWithResource("layout/bookings/guestlist/GuestlistListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("GuestList", NoItemSelectedViewControllerType.GuestList);

        this._guestListViewController = new MUISplitViewController("guestlist-split-controller");
        this._guestListViewController.init();
        this._guestListViewController.setMasterViewController(mvc);
        this._guestListViewController.setDetailViewController(dvc);

        return this._guestListViewController;
    }
        
    private _bookingSourceViewController:MUISplitViewController = null;        
    private bookingSourcesViewController() {
        if (this._bookingSourceViewController != null) return this._bookingSourceViewController;

        let mvc = new SourceListViewController("sources-list-view");
        mvc.initWithResource("layout/bookings/source/SourceListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("BookingSource", NoItemSelectedViewControllerType.BookingSource)        

        this._bookingSourceViewController = new MUISplitViewController();
        this._bookingSourceViewController.init();

        this._bookingSourceViewController.setMasterViewController(mvc);
        this._bookingSourceViewController.setDetailViewController(dvc);

        return this._bookingSourceViewController;
    }

    private _eventsViewController:MUISplitViewController = null;
    private eventsViewController() {
        if (this._eventsViewController != null) return this._eventsViewController;

        let mvc = new EventListViewController("event-list-view");
        mvc.initWithResource("layout/event/EventListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Events", NoItemSelectedViewControllerType.Events);

        this._eventsViewController = new MUISplitViewController("event-split-controller");
        this._eventsViewController.init();
        this._eventsViewController.setMasterViewController(mvc);
        this._eventsViewController.setDetailViewController(dvc);

        return this._eventsViewController;
    }

    private _partyViewController:MUISplitViewController = null;    
    private partiesViewController() {
        if (this._partyViewController != null) return this._partyViewController;

        let mvc = new PartyListViewController('party-list-view');
        mvc.initWithResource("layout/party/PartyListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Parties", NoItemSelectedViewControllerType.Party);        

        this._partyViewController = new MUISplitViewController();
        this._partyViewController.init();
        this._partyViewController.setMasterViewController(mvc);
        this._partyViewController.setDetailViewController(dvc);

        return this._partyViewController;
    }

    //
    // STOCK
    //

    private _supplierViewController:MUISplitViewController = null;    
    private suppliersViewController() {        
        if (this._supplierViewController != null) return this._supplierViewController;

        let mvc = new SupplierListViewController("supplier-list-view");
        mvc.initWithResource("layout/supplier/SupplierListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Supplier", NoItemSelectedViewControllerType.Supplier);

        this._supplierViewController = new MUISplitViewController();
        this._supplierViewController.init();
        this._supplierViewController.setMasterViewController(mvc);
        this._supplierViewController.setDetailViewController(dvc);

        return this._supplierViewController;
    }
    
    private _stockProductsViewController:MUISplitViewController = null;    
    private stockProductsViewController(){
        if (this._stockProductsViewController != null) return this._stockProductsViewController;

        let mvc = new ProductListViewController("products-list-view");
        mvc.initWithResource("layout/products/ProductsListView.html");
        mvc.productType = ProductType.Stock;

        let nc = new MUINavigationController();
        nc.initWithRootViewController(mvc);

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Product", NoItemSelectedViewControllerType.Product);

        this._stockProductsViewController = new MUISplitViewController();
        this._stockProductsViewController.init();
        this._stockProductsViewController.setMasterViewController(nc);
        this._stockProductsViewController.setDetailViewController(dvc);

        return this._stockProductsViewController;        
    }

    private _stockViewController = null;
    private stockViewController() {      
        if (this._stockViewController != null) return this._stockViewController;
        
        let vc = new StockViewController("stock-view");
        vc.initWithResource("layout/stock/StockView.html");

        this._stockViewController = new MUINavigationController('stock_nav_controller');
        this._stockViewController.initWithRootViewController(vc);

        return this._stockViewController;
    }

    private _inventoryNoteViewController:MUISplitViewController = null;    
    private inventoryNotesViewController() {        
        if (this._inventoryNoteViewController != null) return this._inventoryNoteViewController;

        let mvc = new InventoryNoteListViewController("inventory-note-list-view");
        mvc.initWithResource("layout/inventorynote/InventoryNoteListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Inventory", NoItemSelectedViewControllerType.InventoryNote);

        this._inventoryNoteViewController = new MUISplitViewController();
        this._inventoryNoteViewController.init();
        this._inventoryNoteViewController.setMasterViewController(mvc);
        this._inventoryNoteViewController.setDetailViewController(dvc);

        return this._inventoryNoteViewController;
    }

    private _inventoryTemplateViewController:MUISplitViewController = null;    
    private inventoryTemplateViewController() {        
        if (this._inventoryTemplateViewController != null) return this._inventoryTemplateViewController;

        let mvc = new InventoryTemplateListViewController("inventory-template-list-view");
        mvc.initWithResource("layout/inventorytemplate/InventoryTemplateListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Inventory", NoItemSelectedViewControllerType.InventoryNote);

        this._inventoryTemplateViewController = new MUISplitViewController();
        this._inventoryTemplateViewController.init();
        this._inventoryTemplateViewController.setMasterViewController(mvc);
        this._inventoryTemplateViewController.setDetailViewController(dvc);

        return this._inventoryTemplateViewController;
    }

    private _movementNoteViewController:MUISplitViewController = null;    
    private movementNotesViewController() {        
        if (this._movementNoteViewController != null) return this._movementNoteViewController;

        let mvc = new MovementNoteListViewController("movement-note-list-view");
        mvc.initWithResource("layout/movementnote/MovementNoteListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("MovementNote", NoItemSelectedViewControllerType.MovementNote);

        this._movementNoteViewController = new MUISplitViewController();        
        this._movementNoteViewController.init();
        this._movementNoteViewController.setMasterViewController(mvc);
        this._movementNoteViewController.setDetailViewController(dvc);

        return this._movementNoteViewController;
    }

    private _outputNoteViewController:MUISplitViewController = null;    
    private outputNotesViewController() {        
        if (this._outputNoteViewController != null) return this._outputNoteViewController;

        let mvc = new OutputNoteListViewController("output-note-list-view");
        mvc.initWithResource("layout/outputnote/OutputNoteListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("OutputNote", NoItemSelectedViewControllerType.OutputNote);        

        this._outputNoteViewController = new MUISplitViewController();
        this._outputNoteViewController.init();
        this._outputNoteViewController.setMasterViewController(mvc);
        this._outputNoteViewController.setDetailViewController(dvc);

        return this._outputNoteViewController;
    }

    private _lowStockProductsViewController:MUISplitViewController = null;    
    private lowStockProductViewController(){
        if (this._lowStockProductsViewController != null) return this._lowStockProductsViewController;

        let mvc = new LowStockListViewController("low-stock-products-list-view");
        mvc.initWithResource("layout/lowstockproducts/LowStockProductsListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("LowStockProducts", NoItemSelectedViewControllerType.LowStockProducts);

        this._lowStockProductsViewController = new MUISplitViewController();
        this._lowStockProductsViewController.init();
        this._lowStockProductsViewController.setMasterViewController(mvc);
        this._lowStockProductsViewController.setDetailViewController(dvc);

        return this._lowStockProductsViewController;
    }

    private _supplierOrderViewController = null;    
    private supplierOrdersViewController(){
        if (this._supplierOrderViewController != null) return this._supplierOrderViewController;

        let mvc = new SupplierOrderListViewController("supplier-order-list-view");
        mvc.initWithResource("layout/supplierorder/SupplierOrderListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("SupplierOrder", NoItemSelectedViewControllerType.SupplierOrder);        

        this._supplierOrderViewController = new MUISplitViewController();
        this._supplierOrderViewController.init();
        this._supplierOrderViewController.setMasterViewController(mvc);
        this._supplierOrderViewController.setDetailViewController(dvc);

        return this._supplierOrderViewController;
    }

    private _supplierNoteViewController:MUISplitViewController = null;    
    private supplierNotesViewController() {
        if (this._supplierNoteViewController != null) return this._supplierNoteViewController;

        let mvc = new SupplierNoteListViewController('supplier-note-list-view');
        mvc.initWithResource('layout/suppliernote/SupplierNoteListView.html');

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("SupplierNote", NoItemSelectedViewControllerType.SupplierNote);        

        this._supplierNoteViewController = new MUISplitViewController();
        this._supplierNoteViewController.init();        
        this._supplierNoteViewController.setMasterViewController(mvc);
        this._supplierNoteViewController.setDetailViewController(dvc);
        
        return this._supplierNoteViewController;
    }

    private _supplierReturnNoteViewController:MUISplitViewController = null;    
    private supplierReturnNotesViewController() {
        if (this._supplierReturnNoteViewController != null) return this._supplierReturnNoteViewController;

        let mvc = new SupplierReturnNoteListViewController('supplier-return-note-list-view');
        mvc.initWithResource('layout/supplierreturnnote/SupplierReturnNoteListView.html');

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("SupplierNote", NoItemSelectedViewControllerType.SupplierNote);        

        this._supplierReturnNoteViewController = new MUISplitViewController();
        this._supplierReturnNoteViewController.init();        
        this._supplierReturnNoteViewController.setMasterViewController(mvc);
        this._supplierReturnNoteViewController.setDetailViewController(dvc);
        
        return this._supplierReturnNoteViewController;
    }

    private _deliveryNoteViewController:MUISplitViewController = null;    
    private deliveryNotesViewController() {     
        if (this._deliveryNoteViewController != null) return this._deliveryNoteViewController;

        let mvc = new DeliveryNoteListViewController("delivery-note-list-view");
        mvc.initWithResource("layout/deliverynote/DeliveryNoteListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("DeliveryNote", NoItemSelectedViewControllerType.DeliveryNote)

        this._deliveryNoteViewController = new MUISplitViewController();
        this._deliveryNoteViewController.init();
        this._deliveryNoteViewController.setMasterViewController(mvc);
        this._deliveryNoteViewController.setDetailViewController(dvc);

        return this._deliveryNoteViewController;
    }

    private _stockOrderOutViewController:MUISplitViewController = null;    
    private stockOrdersOutViewController() {        
        if (this._stockOrderOutViewController != null) return this._stockOrderOutViewController;
        
        let mvc = new StockOrderListViewController('stock-order-list-view');
        mvc.initWithResource("layout/ordernote/StockOrderListView.html");
        mvc.type = StockNoteType.InternalOrderResponse;

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("StockOrderOutNoSelectedItem", NoItemSelectedViewControllerType.StockOrder);        

        this._stockOrderOutViewController = new MUISplitViewController();
        this._stockOrderOutViewController.init();
        this._stockOrderOutViewController.setMasterViewController(mvc);
        this._stockOrderOutViewController.setDetailViewController(dvc);

        return this._stockOrderOutViewController;
    }

    private _stockOrderInViewController:MUISplitViewController = null;    
    private stockOrdersInViewController() {        
        if (this._stockOrderInViewController != null) return this._stockOrderInViewController;

        let mvc = new StockOrderListViewController('stock-order-list-view');
        mvc.initWithResource("layout/ordernote/StockOrderListView.html");
        mvc.type = StockNoteType.InternalOrder;

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("StockOrderInNoSelectedItem", NoItemSelectedViewControllerType.StockOrder);

        this._stockOrderInViewController = new MUISplitViewController();
        this._stockOrderInViewController.init();
        this._stockOrderInViewController.setMasterViewController(mvc);
        this._stockOrderInViewController.setDetailViewController(dvc);

        return this._stockOrderInViewController;
    }

    private _internalReturnOrderViewController:MUISplitViewController = null;    
    private internalReturnOrderViewController() {        
        if (this._internalReturnOrderViewController != null) return this._internalReturnOrderViewController;
        
        let irvc = new InternalReturnOrderListViewController('internal-return-order-list-view');
        irvc.initWithResource("layout/returnorder/InternalReturnOrderListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("InternalReturnOrderNoSelectedItem", NoItemSelectedViewControllerType.InternalReturnOrder);        

        this._internalReturnOrderViewController = new MUISplitViewController();
        this._internalReturnOrderViewController.init();
        this._internalReturnOrderViewController.setMasterViewController(irvc);
        this._internalReturnOrderViewController.setDetailViewController(dvc);

        return this._internalReturnOrderViewController;
    }

    // 
    // CONFIGURATION
    //

    private _settingsViewController:MUISplitViewController = null;    
    private settingsViewController() {
        if (this._settingsViewController != null) return this._settingsViewController;

        let mvc = new SetupViewController("setup-view");
        mvc.initWithResource("layout/setup/SetupView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Setup", NoItemSelectedViewControllerType.Setup);        

        this._settingsViewController = new MUISplitViewController();
        this._settingsViewController.init();
        this._settingsViewController.setMasterViewController(mvc);
        this._settingsViewController.setDetailViewController(dvc);

        return this._settingsViewController;
    }

    private _dbBackupToolViewController:DBBackupToolViewController = null;
    private dbBackupToolViewController() {
        if (this._dbBackupToolViewController != null) return this._dbBackupToolViewController;

        this._dbBackupToolViewController = new DBBackupToolViewController('db-backup-tool-view');
        this._dbBackupToolViewController.initWithResource("layout/dbtool/DBBackupToolView.html");

        return this._dbBackupToolViewController;
    }    

    private _dbToolViewController = null;
    private dbToolViewController() {
        if (this._dbToolViewController != null) return this._dbToolViewController;

        let vc = new DBToolViewController('db-tool-view');
        vc.initWithResource("layout/dbtool/DBToolView.html");

        this._dbToolViewController = new MUINavigationController();
        this._dbToolViewController.initWithRootViewController(vc);

        return this._dbToolViewController;
    }

    private _dbSyncViewController:DBSyncViewController = null;    
    private dbSyncViewController(){
        if (this._dbSyncViewController != null) return this._dbSyncViewController;

        this._dbSyncViewController = new DBSyncViewController('db-sync-view');
        this._dbSyncViewController.initWithResource("layout/dbtool/DBSyncView.html");

        return this._dbSyncViewController;
    }

    private _dbMappingViewController:DBMappingViewController = null;    
    private dbMappingViewController(){
        if (this._dbMappingViewController != null) return this._dbMappingViewController;
        
        this._dbMappingViewController = new DBMappingViewController('db-mappings-view');
        this._dbMappingViewController.initWithResource("layout/dbtool/DBMappingsView.html");

        return this._dbMappingViewController;
    }

    private _dbSyncLogViewController:MUIViewController = null;    
    private dbSyncLogViewController(){
        if (this._dbSyncLogViewController != null) return this._dbSyncLogViewController;

        this._dbSyncLogViewController = new MUIViewController('db-sync-log-view');
        this._dbSyncLogViewController.initWithResource("layout/dbtool/DBSyncLogView.html");

        return this._dbSyncLogViewController;
    }

    private _reportBuilderViewController:MUISplitViewController = null;
    private reportBuilderViewController(){
        if (this._reportBuilderViewController != null) return this._reportBuilderViewController;

        let mvc = new ReportScriptListViewController("report-files-list-view");
        mvc.initWithResource("layout/reports/ReportFilesListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("ReportBuilder", NoItemSelectedViewControllerType.ReportBuilder);

        this._reportBuilderViewController = new MUISplitViewController();
        this._reportBuilderViewController.init();
        this._reportBuilderViewController.setMasterViewController(mvc);
        this._reportBuilderViewController.setDetailViewController(dvc);

        return this._reportBuilderViewController;
    }

    //
    // DUAL LINK
    // 

    private _licensesViewController:MUISplitViewController = null;
    private licensesViewController():MUISplitViewController{
        if (this._licensesViewController != null) return this._licensesViewController;

        let mvc = new LicensesListViewController("licenses-list-view");
        mvc.initWithResource("layout/licenses/LicensesListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Licenses", NoItemSelectedViewControllerType.License);        

        this._licensesViewController = new MUISplitViewController("licenses_split_controller");
        this._licensesViewController.init();        
        this._licensesViewController.setMasterViewController(mvc);
        this._licensesViewController.setDetailViewController(dvc);

        return this._licensesViewController;
    }

    private _supportTicketViewController:MUISplitViewController = null;
    private supportTicketsViewController():MUISplitViewController{
        if (this._supportTicketViewController != null) return this._supportTicketViewController;

        let mvc = new SupportTicketListViewController("support-ticket-list-view");
        mvc.initWithResource("layout/supportticket/SupportTicketListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("SupportTickets", NoItemSelectedViewControllerType.SupportTickets);        

        this._supportTicketViewController = new MUISplitViewController();
        this._supportTicketViewController.init();        
        this._supportTicketViewController.setMasterViewController(mvc);
        this._supportTicketViewController.setDetailViewController(dvc);        

        return this._supportTicketViewController;
    }

    private _roomScheduleController:RoomScheduleViewController = null;
    private roomScheduleController():RoomScheduleViewController{
        if (this._roomScheduleController != null) return this._roomScheduleController;

        this._roomScheduleController = new RoomScheduleViewController("room-schedule-view");
        this._roomScheduleController.initWithResource("layout/rooms/RoomScheduleView.html");

        return this._roomScheduleController;
    }

    private _roomBookingController:RoomBookingListViewController = null;
    private roomBookingController():RoomBookingListViewController{
        if (this._roomBookingController != null) return this._roomBookingController;

        this._roomBookingController = new RoomBookingListViewController("rooms-booking-list-view");
        this._roomBookingController.initWithResource("layout/rooms/RoomsBookingListView.html");

        return this._roomBookingController;
    }

}
