/**
 * Created by miguel on 31/1/17.
 */
/*function roundToTwo(num) {    
    return +(Math.round(num + "e+2")  + "e-2");
}*/

function isInt(value) {
  return !isNaN(value) && 
         Number(value) == value && 
         !isNaN(parseInt(value, 10));
}

enum MeasureType 
{
    MeasureTypeUnit,
    MeasureTypeMass,
    MeasureTypeVolume
}

enum MeasureUnitType 
{
    Percentage = -100,
    Container = -1,
    None = 0,
    Unit = 1,
    MassKilogram = 2,
    MassGram = 3,
    VolumeLitre = 4,
    VolumeCentilitre = 5
}

class MeasureUnits {

    public static isMeasureUnit(measureUnit)
    {
        if(!isInt(measureUnit)) return false;

        switch(parseInt(measureUnit)){
            case MeasureUnitType.Unit:
            case MeasureUnitType.MassKilogram:
            case MeasureUnitType.MassGram:
            case MeasureUnitType.VolumeLitre:
            case MeasureUnitType.VolumeCentilitre:
            case MeasureUnitType.Container:
                return true;
            default:
                return false;    
        }
    }

    public static measureType(measureUnitType)
    {
        switch(measureUnitType){
            case MeasureUnitType.Unit:
                return MeasureType.MeasureTypeUnit;

            case MeasureUnitType.MassGram:
            case MeasureUnitType.MassKilogram:
                return MeasureType.MeasureTypeMass;

            case MeasureUnitType.VolumeCentilitre:
            case MeasureUnitType.VolumeLitre:
                return MeasureType.MeasureTypeVolume;            
        }

        // Default case
        return MeasureType.MeasureTypeUnit;
    }

    public static measureUnitsFromType(measureType){

        switch (measureType){
            case MeasureType.MeasureTypeUnit:
                return [MeasureUnitType.Unit];

            case MeasureType.MeasureTypeMass:
                return [MeasureUnitType.MassKilogram, MeasureUnitType.MassGram];

            case MeasureType.MeasureTypeVolume:
                return [MeasureUnitType.VolumeLitre, MeasureUnitType.VolumeCentilitre];
        }

        // Default case
        return [MeasureUnitType.Unit];
    }

    static measureUnitsFromUnitType(unitType:MeasureUnitType){

        // HACK: Sometimes we get and string.
        if (typeof unitType === "string") unitType = parseInt(unitType);

        let array = [];

        switch (unitType){
            case MeasureUnitType.Unit:
                array.addObject(MeasureUnitType.Unit);
                break;

            case MeasureUnitType.MassGram:
            case MeasureUnitType.MassKilogram:
                array.addObject(MeasureUnitType.MassKilogram);
                array.addObject(MeasureUnitType.MassGram);
                break;

            case MeasureUnitType.VolumeCentilitre:
            case MeasureUnitType.VolumeLitre:
                array.addObject(MeasureUnitType.VolumeLitre);
                array.addObject(MeasureUnitType.VolumeCentilitre);
                break;

            default:
                array.addObject(MeasureUnitType.Unit);
                break;
        }        
        
        return  array;
    }

    public static toString(id){
        
        switch (parseInt(id)) {
            case MeasureUnitType.Percentage:
                return '%';     //MeasureUnitTypePercentage
            case MeasureUnitType.Container:
                return MIOLocalizeString("CONT", "Cont");  //MeasureUnitTypeContainer
            case MeasureUnitType.None:
                return '';      //kMeasureUnitTypeNone
            case MeasureUnitType.Unit:
                return 'U';     //MeasureUnitTypeUni
            case MeasureUnitType.MassKilogram:
                return 'Kg';    //MeasureUnitTypeMassKilogram
            case MeasureUnitType.MassGram:
                return 'gr';    //MeasureUnitTypeMassGram
            case MeasureUnitType.VolumeLitre:
                return 'L';     //MeasureUnitTypeVolumeLitre
            case MeasureUnitType.VolumeCentilitre:
                return 'cl';    //MeasureUnitTypeVolumeCentilitre
            default:
                return '';
        }
    }

    public static description(id){
        switch (parseInt(id)){
            case MeasureUnitType.Percentage:
                return 'Porcentaje';     //MeasureUnitTypePercentage
            case MeasureUnitType.Container:
                return 'Contenedores';  //MeasureUnitTypeContainer
            case MeasureUnitType.None:
                return '';      //kMeasureUnitTypeNone
            case MeasureUnitType.Unit:
                return 'Unidades';     //MeasureUnitTypeUni
            case MeasureUnitType.MassKilogram:
                return 'Kilogramos';    //MeasureUnitTypeMassKilogram
            case MeasureUnitType.MassGram:
                return 'Gramos';    //MeasureUnitTypeMassGram
            case MeasureUnitType.VolumeLitre:
                return 'Litros';     //MeasureUnitTypeVolumeLitre
            case MeasureUnitType.VolumeCentilitre:
                return 'Centílitros';    //MeasureUnitTypeVolumeCentilitre
            default:
                return '';
        }
    }
}

