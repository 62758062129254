
class EmailCell extends UITableViewCell
{
    private keyTextField:MUITextField = null;
    private languageComboBox:MUIComboBox = null;
    private subjectTextField:MUITextField = null;
    private pathTextField:MUITextField = null;
    private examineButton:MUIButton = null;

    awakeFromHTML(){
        this.keyTextField = MUIOutlet(this, "key-tf", "MUITextField");
        this.languageComboBox = MUIOutlet(this, "languages-cb", "MUIComboBox");
        this.subjectTextField = MUIOutlet(this, "subject-tf", "MUITextField");
        this.pathTextField = MUIOutlet(this, "path-tf", "MUITextField");
        this.examineButton = MUIOutlet(this, "folder-btn", "MUIButton");

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private emailTemplate:EmailTemplate = null;
    set item(item:EmailTemplate){
        this.emailTemplate = item;

        this.keyTextField.text = item.key;
        this.subjectTextField.text = item.subject;
        this.pathTextField.text = item.path;
    }
}