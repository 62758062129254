

// Generated class UserSection_ManagedObject

class UserSection_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: moduleIndex
    set moduleIndex(value:number) {
        this.setValueForKey(value, 'moduleIndex');
    }
    get moduleIndex():number {
        return this.valueForKey('moduleIndex');
    }
    set moduleIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'moduleIndex');
    }
    get moduleIndexPrimitiveValue():number {
        return this.primitiveValueForKey('moduleIndex');
    }

    // Property: module
    set module(value:string) {
        this.setValueForKey(value, 'module');
    }
    get module():string {
        return this.valueForKey('module');
    }
    set modulePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'module');
    }
    get modulePrimitiveValue():string {
        return this.primitiveValueForKey('module');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: bitMask
    set bitMask(value:number) {
        this.setValueForKey(value, 'bitMask');
    }
    get bitMask():number {
        return this.valueForKey('bitMask');
    }
    set bitMaskPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bitMask');
    }
    get bitMaskPrimitiveValue():number {
        return this.primitiveValueForKey('bitMask');
    }

    // Property: isActive
    set isActive(value:boolean) {
        this.setValueForKey(value, 'isActive');
    }
    get isActive():boolean {
        return this.valueForKey('isActive');
    }
    set isActivePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isActive');
    }
    get isActivePrimitiveValue():boolean {
        return this.primitiveValueForKey('isActive');
    }

    // Property: isPhone
    set isPhone(value:boolean) {
        this.setValueForKey(value, 'isPhone');
    }
    get isPhone():boolean {
        return this.valueForKey('isPhone');
    }
    set isPhonePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isPhone');
    }
    get isPhonePrimitiveValue():boolean {
        return this.primitiveValueForKey('isPhone');
    }

    // Property: isPad
    set isPad(value:boolean) {
        this.setValueForKey(value, 'isPad');
    }
    get isPad():boolean {
        return this.valueForKey('isPad');
    }
    set isPadPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isPad');
    }
    get isPadPrimitiveValue():boolean {
        return this.primitiveValueForKey('isPad');
    }

    // Property: isDesktop
    set isDesktop(value:boolean) {
        this.setValueForKey(value, 'isDesktop');
    }
    get isDesktop():boolean {
        return this.valueForKey('isDesktop');
    }
    set isDesktopPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDesktop');
    }
    get isDesktopPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDesktop');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
