//
// Generated class License
//

/// <reference path="License_ManagedObject.ts" />

var LicenseType = {
    1:'Inicial',
    2:'Prorroga',
    3:'Renovacion',
    4:'Demo',
    5:'PromoAlimentaria'
}

var LicenseAppType = {
    1:'TPV',
    2:'Waiter',
    3:'Slave',
    4:'Chef'
}

class License extends License_ManagedObject
{

    static get getTypes(){
        return LicenseType;
    };

    get typeString(){
        return LicenseType[this.type];
    };

    static get getAppTypes(){
        return LicenseAppType;
    };

    get appTypeString(){
        return LicenseAppType[this.apptype];
    };
}
