//
// Generated class User
//

/// <reference path="User_ManagedObject.ts" />

enum UserAccessType
{
    DashBoard,
    Reports,
    Logs,
    Licenses,
    CashDesk,
    Tickets,
    IssuedInvoices,
    Client,
    Worker,
    WorkerAdmin,
    UserAccess,
    Cards,
    Product,
    Category,
    ProductAdmin,
    Bookings,
    NoShow,
    Guests,
    GuestLists,
    BookingSources,
    Events,
    Parties,
    Supplier,
    Stock,
    LowStockOrder,
    InventoryNote,
    MovementNote,
    OutputNote,
    SupplierOrder,
    SupplierNote,
    DeliveryNote,
    StockOrderOutNote,
    StockOrderInNote,
    Settings,
    DBBackup,
    DBTool,
    DBMapping,
    DBSync,
    DBSyncLog
}     


class User extends User_ManagedObject
{
    productDBs = [];    

    setObject(object)
    {
        this.identifier = object["id"];
        this.name = object["name"];
        this.email = object["email"];
    }
}
