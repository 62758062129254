


class ClientDebtCell extends MUITableViewCell
{
    delegate = null;

    private dateLabel:MUILabel = null;
    private payedDateLabel:MUILabel = null;
    private pendingLabel:MUILabel = null;
    private ticketLabel:MUILabel = null;    
    private priceLabel:MUILabel = null;
    private payButton:MUIButton = null;
    private actionButton:MUIButton = null;
    private printButton:MUIButton = null;        

    awakeFromHTML(){        
        this.dateLabel = MUIOutlet(this, 'date-lbl','MUILabel');        
        this.payedDateLabel = MUIOutlet(this, 'payed-date-lbl','MUILabel');        
        this.pendingLabel = MUIOutlet(this, 'pending-lbl','MUILabel');        
        this.priceLabel = MUIOutlet(this, 'value-lbl','MUILabel');
        this.ticketLabel = MUIOutlet(this, "ticket-lbl", "MUILabel");

        this.printButton = MUIOutlet(this, 'print-btn','MUIButton');
        this.printButton.setAction(this, function(){
            AppHelper.sharedInstance().printDebtTicket(this.debtLine);
        });

        this.payButton = MUIOutlet(this, 'pay-btn','MUIButton');
        this.payButton.setAction(this, function(){
           this.payDebt();
        });

        this.actionButton = MUIOutlet(this, "cell-action-btn", "MUIButton");
        this.actionButton.setAction(this, this.showActionsAlert);

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private debtLine = null;
    set item(item:DebtLine){
        this.debtLine = item;

        let nf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
        let df = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter as MIODateFormatter;
        
        this.dateLabel.text = df.stringFromDate(item.creationDate);
        this.payedDateLabel.text = df.stringFromDate(item.payedDate);     
        this.pendingLabel.text = nf.stringFromNumber(item.pendingValue);
        this.priceLabel.text = nf.stringFromNumber(item.debtValue);
        this.ticketLabel.text = item.legalDocumentID ? item.legalDocumentID : item.documentID;                        
        
        this.payButton.setHidden(!(item.pendingValue > 0 && item.isDiscarded == false));

        if (item.isDiscarded == true){
            MUICoreLayerAddStyle(this.ticketLabel.layer, "alert");
            MUICoreLayerAddStyle(this.dateLabel.layer, "alert");
            MUICoreLayerAddStyle(this.payedDateLabel.layer, "alert");
            MUICoreLayerAddStyle(this.pendingLabel.layer, "alert");
            MUICoreLayerAddStyle(this.priceLabel.layer, "alert");
        }
        else {
            MUICoreLayerRemoveStyle(this.ticketLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.dateLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.payedDateLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.pendingLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.priceLabel.layer, "alert");
        }

    }

    private showActionsAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Actions", "Choose an option", MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle("Nullify", MUIAlertActionStyle.Destructive, this, function(){
            //TODO: Juanjo has to create the service to remove the debt line
            AppHelper.showInfoMessage(null, "Info", "This action it's not availabe in your place");
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Discard", MUIAlertActionStyle.Destructive, this, function(){
            this.discardDebt();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        AppHelper.sharedInstance().presentViewController(avc, true);
    }

    private payDebt(){        
        if(this.delegate != null && (typeof this.delegate.showPayDebtOptions === "function"))
            this.delegate.showPayDebtOptions(this.debtLine);
    }

    private discardDebt(){
        if(this.delegate != null && (typeof this.delegate.discardDebtFromCell === "function"))
            this.delegate.discardDebtFromCell(this, this.debtLine);
    }

}