/**
 * Created by godshadow on 11/07/16.
 */

/// <reference path="../../base/BaseTableViewController.ts" />

class NoShowViewController extends BaseTableViewController
{
    private dateButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private exportButton:MUIButton = null;
    private progressView = null;
    
    
    private _selectedDayString = null;
    private _sources = null;

    private ldf = MUIWebApplication.sharedInstance().delegate.longDateFormatter as MIODateFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.dateButton = MUIOutlet(this, "date-btn", 'MUIButton');
        this.dateButton.setAction(this, function(){
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.dateButton.frame;
            pc.sourceView = this.dateButton;

            this.presentViewController(dp, true);
        });

        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");

        // this.progressView = MUIOutlet(this, "nsv_progress", 'MUIActivityIndicatorView');
        // this.progressView.setHidden(true);

//         this.exportButton = MUIOutlet(this, "nsv_export", 'MUIButton');
//         this.exportButton.setAction(this, function(){

// //            var url='http://www.dual-link.es/webapps/heartibiza/report/guests-all.php?day='+ this._selectedDayString;
// //            window.open(url, 'Download');

//         });

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;   
        
        let today = new Date();
        let todayString = MIODateGetDateString(today);
        // this.dateComboCombox.selectItem(todayString);
        this._selectedDayString = todayString;

        this.titleLabel.text = this.ldf.stringFromDate(today);        
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("NoShowCell") as NoShowCell;

        let b = this.fetchedResultsController.objectAtIndexPath(indexPath) as Booking;    
        cell.booking = b;

        return cell;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("Booking");

        fetchRequest.predicate = MIOPredicate.predicateWithFormat("day == '" + this._selectedDayString + "' AND isPayed == 0 AND (status == 6 OR status == 8)");

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    didSelectDate(datePickerController:MUIDatePickerController, date:Date){
        this._selectedDayString = MIODateGetDateString(date);
        this.titleLabel.text = this.ldf.stringFromDate(date);
        this.invalidateData();
    }

}
