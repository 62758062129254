

class SupplierDetailViewController extends MUIViewController
{
    private saveButton:MUIButton = null;
    private actionButton:MUIButton = null;    
    private segmentedControl:MUISegmentedControl = null;
    private sectionsPageController:MUIPageController = null;
    
    private detailGeneralViewController:SupplierDetailGeneralViewController = null;
    private productViewController:SupplierProductViewController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.actionButton = MUIOutlet(this, 'action-btn', 'MUIButton');
        this.actionButton.setAction(this, this.showActions);

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, function (){
            DBHelper.saveMainContext();
        });

        this.segmentedControl = MUIOutlet(this, 'segmented-control', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function (control, index) {
           this.sectionsPageController.showPageAtIndex(index);
        });

        this.sectionsPageController = MUIOutlet(this, 'page-controller', 'MUIPageController');
        
        this.detailGeneralViewController = new SupplierDetailGeneralViewController('supplier-detail-general-view');
        this.detailGeneralViewController.initWithResource('layout/supplier/SupplierDetailGeneralView.html');        
        this.sectionsPageController.addPageViewController(this.detailGeneralViewController);

        this.productViewController = new SupplierProductViewController('supplier-product-view');
        this.productViewController.initWithResource('layout/supplier/SupplierProductView.html');
        this.sectionsPageController.addPageViewController(this.productViewController);                        
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);        
        this.updateUI();
    }

    private _supplier:Supplier = null;    
    set supplier(value:Supplier){
        if (value == this._supplier) return;        
        this._supplier = value;

        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this._supplier == null) return;
        
        this.detailGeneralViewController.supplier = this._supplier;
        this.productViewController.supplier = this._supplier;        
    }   

    private showActions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ACTIONS", "ACTIONS"), MIOLocalizeString("SELECT ONE ACTION", "SELECT ONE ACTION"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DELETE", "DELETE"), MUIAlertActionStyle.Destructive, this, function(){
            let supplier = this._supplier;
            this._supplier = null;
            this.detailGeneralViewController.supplier = null;
            this.productViewController.supplier = null;            
            DBHelper.deleteObjectFromMainContext(supplier, true);      
            MIONotificationCenter.defaultCenter().postNotification("SupplierDidDeleted", supplier);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
    
        this.presentViewController(avc, true);
    }
    
}
