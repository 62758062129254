//
// Generated class DBImage
//

/// <reference path="DBImage_ManagedObject.ts" />

enum DBImageType 
{
    Raw,
    Product,
    Category
}

class DBImage extends DBImage_ManagedObject
{
    urlByType(type:DBImageType) {
        if (this.urlString == null) return null;

        let url = null;
        switch (type) {
            case DBImageType.Raw:
                url = this.urlString.substr(0, this.urlString.length - 9)  + "0%400.png";
                break;

            case DBImageType.Product:
                url = this.urlString.substr(0, this.urlString.length - 9)  + "1%401.png";
                break;

            case DBImageType.Category:
                url = this.urlString.substr(0, this.urlString.length - 9)  + "1%402.png";
                break;
        }

        return url;
    }
}
