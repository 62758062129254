

// Generated class Category_ManagedObject

class Category_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: grouping
    set grouping(value:number) {
        this.setValueForKey(value, 'grouping');
    }
    get grouping():number {
        return this.valueForKey('grouping');
    }
    set groupingPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'grouping');
    }
    get groupingPrimitiveValue():number {
        return this.primitiveValueForKey('grouping');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderindex
    set orderindex(value:number) {
        this.setValueForKey(value, 'orderindex');
    }
    get orderindex():number {
        return this.valueForKey('orderindex');
    }
    set orderindexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderindex');
    }
    get orderindexPrimitiveValue():number {
        return this.primitiveValueForKey('orderindex');
    }

    // Property: isAvailable
    set isAvailable(value:boolean) {
        this.setValueForKey(value, 'isAvailable');
    }
    get isAvailable():boolean {
        return this.valueForKey('isAvailable');
    }
    set isAvailablePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAvailable');
    }
    get isAvailablePrimitiveValue():boolean {
        return this.primitiveValueForKey('isAvailable');
    }

    // Property: showInOnline
    set showInOnline(value:boolean) {
        this.setValueForKey(value, 'showInOnline');
    }
    get showInOnline():boolean {
        return this.valueForKey('showInOnline');
    }
    set showInOnlinePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showInOnline');
    }
    get showInOnlinePrimitiveValue():boolean {
        return this.primitiveValueForKey('showInOnline');
    }

    // Property: showInBar
    set showInBar(value:boolean) {
        this.setValueForKey(value, 'showInBar');
    }
    get showInBar():boolean {
        return this.valueForKey('showInBar');
    }
    set showInBarPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showInBar');
    }
    get showInBarPrimitiveValue():boolean {
        return this.primitiveValueForKey('showInBar');
    }

    // Property: showInTables
    set showInTables(value:boolean) {
        this.setValueForKey(value, 'showInTables');
    }
    get showInTables():boolean {
        return this.valueForKey('showInTables');
    }
    set showInTablesPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showInTables');
    }
    get showInTablesPrimitiveValue():boolean {
        return this.primitiveValueForKey('showInTables');
    }

    // Property: invitationValue
    set invitationValue(value:number) {
        this.setValueForKey(value, 'invitationValue');
    }
    get invitationValue():number {
        return this.valueForKey('invitationValue');
    }
    set invitationValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'invitationValue');
    }
    get invitationValuePrimitiveValue():number {
        return this.primitiveValueForKey('invitationValue');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: parentCategory
    set parentCategory(value:string) {
        this.setValueForKey(value, 'parentCategory');
    }
    get parentCategory():string {
        return this.valueForKey('parentCategory');
    }
    set parentCategoryPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'parentCategory');
    }
    get parentCategoryPrimitiveValue():string {
        return this.primitiveValueForKey('parentCategory');
    }
    // Relationship: image
    set image(value:DBImage) {
        this.setValueForKey(value, 'image');
    }
    get image():DBImage {
        return this.valueForKey('image') as DBImage;
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: tag
    set tag(value:CategoryTag) {
        this.setValueForKey(value, 'tag');
    }
    get tag():CategoryTag {
        return this.valueForKey('tag') as CategoryTag;
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
}
