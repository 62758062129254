

// Generated class BookingModifierCategory_BookingZone_ManagedObject

class BookingModifierCategory_BookingZone_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: zoneID
    set zoneID(value:string) {
        this.setValueForKey(value, 'zoneID');
    }
    get zoneID():string {
        return this.valueForKey('zoneID');
    }
    set zoneIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'zoneID');
    }
    get zoneIDPrimitiveValue():string {
        return this.primitiveValueForKey('zoneID');
    }

    // Property: modifierID
    set modifierID(value:string) {
        this.setValueForKey(value, 'modifierID');
    }
    get modifierID():string {
        return this.valueForKey('modifierID');
    }
    set modifierIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'modifierID');
    }
    get modifierIDPrimitiveValue():string {
        return this.primitiveValueForKey('modifierID');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
