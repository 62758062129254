


class SupplierDetailGeneralViewController extends MUIViewController
{        
    private nameTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private documentTextField:MUITextField = null;
    private addressTextField:MUITextField = null;
    private stateTextField:MUITextField = null;
    private countryDropdown:MUIButton = null;
    private cityTextField:MUITextField = null;
    private postalCodeTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private mobilePhoneTextField:MUITextField = null;  
    private tagView:TagView = null;  
    
    viewDidLoad(){
        super.viewDidLoad();

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(textfield, value){
            this._supplier.name = (value != null ? value.trim() : null);
        });    

        this.documentTextField = MUIOutlet(this, "documentid-tf", "MUITextField");
        this.documentTextField.setOnChangeText(this, function(textfield, value){
            this._supplier.document= (value != null ? value.trim() : null);
        });

        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(textfield, value){
            this._supplier.email = (value != null ? value.trim() : null);
        });

        this.phoneTextField = MUIOutlet(this, "phone-tf", "MUITextField");
        this.phoneTextField.setOnChangeText(this, function(textfield, value){
            this.phone.number = (value != null ? value.trim() : null);
        });
        
        this.mobilePhoneTextField = MUIOutlet(this, "mobile-tf", "MUITextField");
        this.mobilePhoneTextField.setOnChangeText(this, function(textfield, value){
            this.mobilePhone.number = (value != null ? value.trim() : null);
        });

        this.postalCodeTextField = MUIOutlet(this, "postal-code-tf", "MUITextField");
        this.postalCodeTextField.setOnChangeText(this, function(textfield, value){
            this.address.postalCode = (value != null ? value.trim() : null);
        });

        this.addressTextField = MUIOutlet(this, "address-tf", "MUITextField");
        this.addressTextField.setOnChangeText(this, function(textfield, value){
            this.address.address1 = (value != null ? value.trim() : null);
        });
        
        this.stateTextField = MUIOutlet(this, "state-tf", "MUITextField");
        this.stateTextField.setOnChangeText(this, function(textfield, value){
            this.address.state = (value != null ? value.trim() : null);
        });

        this.cityTextField = MUIOutlet(this, "city-tf", "MUITextField");
        this.cityTextField.setOnChangeText(this, function(textfield, value){
            this.address.city = (value != null ? value.trim() : null);
        });

        this.countryDropdown = MUIOutlet(this, "country-dd", "MUIButton");
        this.countryDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropdown, null, this, function(controller:SelectEntityViewController, country:Country){
                // this.address.countryName = country.name;
                this.address.countryISON = country.ison;
                this.address.countryISOA2 = country.iso2;
                this.address.countryISOA3 = country.iso3;
                this.countryDropdown.title = country.name;
            });
        });

        this.tagView = MUIOutlet(this, "tag-view", "TagView");
        this.tagView.delegate = this;
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private supplierTags  = [];
    private _supplier:Supplier = null;
    set supplier(value:Supplier){
        this._supplier = value;
        if (value != null) this.supplierTags = value.tags == null ? [] : value.tags.split(',');
        this.updateUI();
    }
        
    private updateUI(){
        if (this.viewIsLoaded == false || this._supplier == null) return;

        this.nameTextField.text = this._supplier.name;
        this.emailTextField.text = this._supplier.email;
        this.documentTextField.text = this._supplier.document;

        this.setAddress(this.address)
        this.setPhone(this.phone);
        this.setMobilePhone(this.mobilePhone);

        let predicate = MIOPredicate.predicateWithFormat('type = ' + TagType.Supplier);
        DBHelper.queryObjectsWithCompletion("Tag", null, predicate, [], this, function(objects){
            this.reloadTags(objects);
        });                

    }
    
    private get address(){
        if(this._supplier.address == null){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this._supplier.address = MIOEntityDescription.insertNewObjectForEntityForName("Address", moc) as Address;   
            DBHelper.saveMainContext();
        }
        return this._supplier.address;
    }

    private setAddress(address){
        this.addressTextField.text = address != null ? address.address1 : '';
        this.stateTextField.text = address != null ? address.state : '';
        this.countryDropdown.title = address != null ? getCountryName( address.countryISOA2 ) : '';
        this.cityTextField.text = address != null ? address.city : '';
        this.postalCodeTextField.text = address != null ? address.postalCode : '';
    }

    private get phone(){
        if(this._supplier.phone == null){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this._supplier.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber; 
            DBHelper.saveMainContext();
        }
        return this._supplier.phone;
    }

    private setPhone(phone){
        this.phoneTextField.text = phone != null ? phone.number : '';
    }

    private get mobilePhone(){
        if(this._supplier.mobilePhone == null){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this._supplier.mobilePhone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber;   
            DBHelper.saveMainContext();                              
        }
        return this._supplier.mobilePhone;
    } 

    private setMobilePhone(phone){
        this.mobilePhoneTextField.text = phone != null ? phone.number : '';
    }

    private reloadTags(tags){
        // this.tagView.removeAll();
        // for (let index = 0; index < tags.length; index++){
        //     let tag = tags[index] as Tag;
        //     this.tagView.addTag(tag.name);
        //     if (this.supplierTags.containsObject(tag.name)) {
        //         this.tagView.selectTagAtIndex(index);
        //     }
        // }
    }    

    didSelectTagAtIndex(index, name:string){        
        let i = this.supplierTags.indexOfObject(name);
        if (i < 0){
            this.supplierTags.addObject(name);            
        }
        else {
            this.supplierTags.removeObject(name);
        }

        this._supplier.tags = this.supplierTags.join(',');
    }

}
