/**
 * Created by miguelsl on 30/12/16.
 */


class ProductDetailInformationViewController extends MUIViewController
{
    private shortdescriptionTextField:MUITextField = null;
    private detaildescriptionTextArea:MUITextArea = null;
    private componentsdescriptionTextArea:MUITextArea = null;
    private tagView:TagView = null;

    private extraimagesCollectionView:MUICollectionView = null;            

    viewDidLoad(){
        super.viewDidLoad();

        this.shortdescriptionTextField = MUIOutlet(this, 'pinv_b1_short_description_textfield', 'MUITextField');
        this.shortdescriptionTextField.setOnChangeText(this, function (textfield, value){
            this._product.info = value;
            MIONotificationCenter.defaultCenter().postNotification("ProductChangeValue", value);
        });

        this.detaildescriptionTextArea = MUIOutlet(this, 'pinv_b1_detail_description_textarea', 'MUITextArea');
        this.detaildescriptionTextArea.setOnChangeText(this, function (textarea, value){
            this._product.detailedInfo = value;
            MIONotificationCenter.defaultCenter().postNotification("ProductChangeValue", value);
        });

        this.componentsdescriptionTextArea = MUIOutlet(this, 'pinv_b1_components_description_textarea', 'MUITextArea');
        this.componentsdescriptionTextArea.setOnChangeText(this, function (textarea, value){
            this._product.componentsInfo = value;
            MIONotificationCenter.defaultCenter().postNotification("ProductChangeValue", value);
        })

       this.tagView = MUIOutlet(this, 'tag-view', 'TagView');
       this.tagView.delegate = this;
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private productTags = null;
    private _product:Product = null;
    set product(value){
        this._product = value;
        this.productTags = this._product.tags == null ? [] : this._product.tags.split(',');
        this.updateUI();
    }

    private updateUI(){
        if (this._product == null) return;
        if (this.viewIsLoaded == false) return;

        this.shortdescriptionTextField.text = this._product.info ? this._product.info :'';
        this.detaildescriptionTextArea.text = this._product.detailedInfo ? this._product.detailedInfo :'';
        this.componentsdescriptionTextArea.text = this._product.componentsInfo ? this._product.componentsInfo :'';

        let predicate = MIOPredicate.predicateWithFormat('type = ' + TagType.Product);
        DBHelper.queryObjectsWithCompletion("Tag", null, predicate, [], this, function(objects){
            this.reloadTags(objects);
        });                
    }

    private reloadTags(tags){
        this.tagView.removeAll();
        for (let index = 0; index < tags.length; index++){
            let tag = tags[index] as Tag;
            this.tagView.addTag(tag.name);
            if (this.productTags.containsObject(tag.name)) {
                this.tagView.selectTagAtIndex(index);
            }
        }
    }

    didSelectTagAtIndex(index, name:string){        
        let i = this.productTags.indexOfObject(name);
        if (i < 0){
            this.productTags.addObject(name);            
        }
        else {
            this.productTags.removeObject(name);
        }

        this._product.tags = this.productTags.join(',');
    }
}