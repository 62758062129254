
class ProductModifiersDetailViewController extends MUIViewController {

    private saveButton:MUIButton = null;
    private settingsButton:MUIButton = null;
    private addButton:MUIButton = null;
    private nameTextfield:MUITextField = null;
    private segmentedControl:MUISegmentedControl = null;
    private categoryButton:MUIButton = null; 
    private multiSelectSwitch:MUISwitchButton = null;
    private requiredSwitch:MUISwitchButton = null;
    private incrementalSwitch:MUISwitchButton = null;
    private allowNegSwitch:MUISwitchButton = null;
    private ignoreModifierSourceSwitch:MUISwitchButton = null;
    private isAbsorbentSwitch:MUISwitchButton = null;
    private limitQuantityTF:MUITextField = null;
    private tableView:MUITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, function (){            
            DBHelper.saveMainContextWithCompletion(this, function(){
                this.updateUI();
            });
        });

        this.settingsButton = MUIOutlet(this, 'settings-btn', 'MUIButton');
        this.settingsButton.setAction(this, function (){            
            this.showSettingsPopup();
        }); 

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function (control, index){
            if (this.productModifierCategory.productModifierCategoryType == ProductModifierCategoryType.Manual) {
                this.showAddManualProductModifier();
            } else if (this.productModifierCategory.productModifierCategoryType == ProductModifierCategoryType.Product) {
                this.showAddProductModifierFromProduct();
            }
        })

        this.nameTextfield = MUIOutlet(this, 'name-tf', 'MUITextField');
        this.nameTextfield.setOnChangeText(this, function(control, value:string){
            this.productModifierCategory.name = value;
        })

        this.segmentedControl = MUIOutlet(this, 'segmented-control', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function (control, index){
            
            if(this._fetchedResultsController.fetchedObjects[0] != null){ //if there are modifiers
                this.ShowChangeProductCategoryType(index);
            } else {
                this.productModifierCategory.productModifierCategoryType = index;
                this.updateUI();
            }
        });

        this.categoryButton = MUIOutlet(this, 'category-dd', 'MUIButton');
        this.categoryButton.setAction(this, function() {

            let avc = new MUIAlertViewController();
            avc.initWithTitle(MIOLocalizeString('NOTICE',"NOTICE"), MIOLocalizeString('CURRENT MODIFIERS WILL BE RESET IF YOU CHANGE CATEGORY',"CURRENT MODIFIERS WILL BE RESET IF YOU CHANGE CATEGORY"), MUIAlertViewStyle.Default);    
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK',"OK"), MUIAlertActionStyle.Default, this, function(){                        
                this.showCategorySelectEntityViewController(this.categoryButton, "Category");
            }));
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, null, null));
            this.presentViewController(avc, true); 

        });

        this.multiSelectSwitch = MUIOutlet(this, "multi-select-switch", "MUISwitchButton");
        this.multiSelectSwitch.setOnChangeValue(this, function(control, value:boolean){
            this.productModifierCategory.multipleSelection = value;
        });

        this.requiredSwitch = MUIOutlet(this, "required-switch", "MUISwitchButton");
        this.requiredSwitch.setOnChangeValue(this, function(control, value:boolean){
            this.productModifierCategory.isRequired = value;
        });

        this.incrementalSwitch = MUIOutlet(this, "incremental-switch", "MUISwitchButton");
        this.incrementalSwitch.setOnChangeValue(this, function(control, value:boolean){
            this.productModifierCategory.incremental = value;
        });

        this.allowNegSwitch = MUIOutlet(this, "allow-negative-switch", "MUISwitchButton");
        this.allowNegSwitch.setOnChangeValue(this, function(control, value:boolean){
            this.productModifierCategory.allowNegatives = value;
        });

        this.ignoreModifierSourceSwitch = MUIOutlet(this, 'ignore-destination-switch', 'MUISwitchButton');
        this.ignoreModifierSourceSwitch.setOnChangeValue(this, function(control, value:boolean){
            this.productModifierCategory.ignoreModifierSource = value;
        })
        
        this.isAbsorbentSwitch = MUIOutlet(this, 'absorbent-switch', 'MUISwitchButton');
        this.isAbsorbentSwitch.setOnChangeValue(this, function(control, value:boolean){
            this.productModifierCategory.isAbsorbent = value;
        })

        this.limitQuantityTF = MUIOutlet(this, 'limit-TF', 'MUITextField');
        this.limitQuantityTF.setOnChangeText(this, function(control, value:number){
            this.productModifierCategory.limitQuantity = value;
        })
        
        this.tableView = MUIOutlet(this,"table-view","UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;                
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);

        this.updateUI();
    }

    private productModifierCategory:ProductModifierCategory = null;
    set item(item:ProductModifierCategory){        

        this.productModifierCategory = item;
        this.updateUI();
    }   

    private updateUI(){
        if (this.productModifierCategory == null) return;
        if (this.viewIsLoaded == false) return;

        this.nameTextfield.text = this.productModifierCategory.name;
        this.multiSelectSwitch.on = this.productModifierCategory.multipleSelection;
        this.requiredSwitch.on = this.productModifierCategory.isRequired;
        this.incrementalSwitch.on = this.productModifierCategory.incremental;
        this.allowNegSwitch.on = this.productModifierCategory.allowNegatives;
        this.ignoreModifierSourceSwitch.on = this.productModifierCategory.ignoreModifierSource;
        this.isAbsorbentSwitch.on = this.productModifierCategory.isAbsorbent;
        this.limitQuantityTF.text = this.productModifierCategory.limitQuantity ? this.productModifierCategory.limitQuantity : null;
        this.segmentedControl.selectSegmentedAtIndex(this.productModifierCategory.productModifierCategoryType);
        
        this.categoryButton.enabled = (this.productModifierCategory.productModifierCategoryType == ProductModifierCategoryType.Category) ? true: false;
        this.addButton.enabled = (this.productModifierCategory.productModifierCategoryType == ProductModifierCategoryType.Category) ? false: true;
        if(this.productModifierCategory.productModifierCategoryType == ProductModifierCategoryType.Category && this.productModifierCategory.category != null) {
            this.categoryButton.title = this.productModifierCategory.category.name;
        }

        this._fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){

        const cell_name =
            { [ProductModifierCategoryType.Manual  ] : "ProductModifierManualCell"
            , [ProductModifierCategoryType.Category] : "ProductModifierCategoryCell"
            , [ProductModifierCategoryType.Product ] : "ProductModifierProductCell"
            } ;

        let cell:ProductModifierDetailCell = tableview.dequeueReusableCellWithIdentifier( cell_name[ this.productModifierCategory.productModifierCategoryType ] ) as ProductModifierDetailCell;

        let item:ProductModifier = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductModifier;
        cell.item = item; //ERROR: the related Product is not being sent
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        //this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);   
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {         
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if (editingStyle == UITableViewCellEditingStyle.Delete && this.productModifierCategory.productModifierCategoryType != ProductModifierCategoryType.Category) {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            this.removeProductModifier(item);
        }
    } 

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        let fetchRequest:MIOFetchRequest = null;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true), MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)]; 
        let filterString = "productModifierCategory.identifier == " + (this.productModifierCategory.identifier ? this.productModifierCategory.identifier : "null");
        fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductModifier", sortDescriptors, filterString);
        fetchRequest.relationshipKeyPathsForPrefetching = ['productModifierCategory','product', 'tax'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){        
        this.tableView.reloadData();
    }

    //show notice functions
    private showSettingsPopup() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ACTIONS', 'ACTIONS'), MIOLocalizeString('CHOOSE AN ACTION', 'CHOOSE AN ACTION'), MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DELETE MODIFIER CATEGORY', 'DELETE MODIFIER CATEGORY'), MUIAlertActionStyle.Destructive, this, function(){
            
            this.removeAllProductModifiers();

            if(this._fetchedResultsController.resultObjects.length == 0) {
                this.removeProductModifierCategory(this.productModifierCategory);
            }
            
        }));
                
        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'Cancel'), MUIAlertActionStyle.Cancel, null, null));
       
        this.presentViewController(avc, true);
    }

    private showCategorySelectEntityViewController(dropdownButton, entityName) {

        let predicateFormat = null;        
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(dropdownButton, entityName, "name", predicateFormat, this, function(controller, object:MIOManagedObject){

            //remove what is already there
            this.removeAllProductModifiers();

            //get the information selected
            let title = object.valueForKey("name");
            let value = object.valueForKey("identifier");
            dropdownButton.title = title;
            this.productModifierCategory.category = object;

            //create the new product modifiers
            if (object != null) this.createProductModifiersFromCategory(object, this.productModifierCategory);

            this.updateUI();
        });        
    }

    private showAddManualProductModifier(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD PRODUCT MODIFIER',"ADD PRODUCT MODIFIER"), MIOLocalizeString('CREATE A PRODUCT MODIFIER',"CREATE A PRODUCT MODIFIER"), MUIAlertViewStyle.Default);    

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK',"OK"), MUIAlertActionStyle.Default, this, function(){                        
            let name = avc.textFields[0].text;
            this.createProductModifier(name, this.productModifierCategory);

            this.updateUI();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        this.presentViewController(avc, true);    
    }

    private showAddProductModifierFromProduct() {
        let predicateFormat = null;        
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(this.addButton, "Product", "name", predicateFormat, this, function(controller, object:MIOManagedObject){

            let title = object.valueForKey("name");
            let value = object.valueForKey("identifier");
            this.createProductModifierFromProduct(object, this.productModifierCategory);

            DBHelper.saveMainContext();
            this.updateUI();
        });     
    }

    private ShowChangeProductCategoryType(newModifierType){
            let notice = new MUIAlertViewController();
            notice.initWithTitle(MIOLocalizeString('NOTICE', 'NOTICE'), MIOLocalizeString('CURRENT MODIFIERS WILL BE RESET IF YOU CHANGE MODIFIER CATEGORY TYPE','CURRENT MODIFIERS WILL BE RESET IF YOU CHANGE MODIFIER CATEGORY TYPE'), MUIAlertViewStyle.Default);
            notice.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK',"OK"), MUIAlertActionStyle.Default, this, function(){                        
                
                this.productModifierCategory.productModifierCategoryType = newModifierType;
                this.removeAllProductModifiers(); //saves main context
                this.updateUI();

            }));
            notice.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, this, function(){
                this.updateUI();
            }));
            this.presentViewController(notice, true);
    }
      
    //generation functions
    private createProductModifier(name:string, productModifierCategory:ProductModifierCategory){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let productModifier = MIOEntityDescription.insertNewObjectForEntityForName("ProductModifier", ad.managedObjectContext) as ProductModifier;
        productModifier.identifier = MIOUUID.UUID().UUIDString;
        productModifier.productModifierCategory = productModifierCategory;
        productModifier.name = name;

        return productModifier;
    }

    private createProductModifierFromProduct(product:Product, productModifierCategory:ProductModifierCategory){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let productModifier = MIOEntityDescription.insertNewObjectForEntityForName("ProductModifier", ad.managedObjectContext) as ProductModifier;
        productModifier.identifier = MIOUUID.UUID().UUIDString;
        productModifier.productModifierCategory = productModifierCategory;
        productModifier.product = product;
        productModifier.name = product.name;

        return productModifier;
    }

    private createProductModifiersFromCategory(category:Category, productModifierCategory:ProductModifierCategory) {
        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let predicate = MIOPredicate.predicateWithFormat("category == " + category.identifier);
        DBHelper.queryObjectsWithCompletion("Product", sds, predicate, [], this, function(objects){
            for(let index = 0; index < objects.length; index++){
                this.createProductModifierFromProduct(objects[index], productModifierCategory)
            }
            DBHelper.saveMainContext();
            this.updateUI();
        });  
    }


    //removal functions - saveMainContext after function
    private removeProductModifier(productModifier:ProductModifier){
        DBHelper.deleteObjectFromMainContext(productModifier, true);     
    }

    private removeAllProductModifiers(){
        let item:ProductModifier = null;
        for(let index = 0; index < this._fetchedResultsController.resultObjects.length; index++){
            item = this._fetchedResultsController.resultObjects[index] as ProductModifier;
            DBHelper.deleteObjectFromMainContext(item, false);
        }
        DBHelper.saveMainContext();
    }

    private removeProductModifierCategory(productModifierCategory:ProductModifierCategory){
        DBHelper.deleteObjectFromMainContext(productModifierCategory, true);     
    }

}
