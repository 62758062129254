

// Generated class ProductMenu_ManagedObject

class ProductMenu_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: automaticIncertion
    set automaticIncertion(value:boolean) {
        this.setValueForKey(value, 'automaticIncertion');
    }
    get automaticIncertion():boolean {
        return this.valueForKey('automaticIncertion');
    }
    set automaticIncertionPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'automaticIncertion');
    }
    get automaticIncertionPrimitiveValue():boolean {
        return this.primitiveValueForKey('automaticIncertion');
    }

    // Property: modifyMenuPrice
    set modifyMenuPrice(value:number) {
        this.setValueForKey(value, 'modifyMenuPrice');
    }
    get modifyMenuPrice():number {
        return this.valueForKey('modifyMenuPrice');
    }
    set modifyMenuPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'modifyMenuPrice');
    }
    get modifyMenuPricePrimitiveValue():number {
        return this.primitiveValueForKey('modifyMenuPrice');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: productMenuCount
    set productMenuCount(value:number) {
        this.setValueForKey(value, 'productMenuCount');
    }
    get productMenuCount():number {
        return this.valueForKey('productMenuCount');
    }
    set productMenuCountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMenuCount');
    }
    get productMenuCountPrimitiveValue():number {
        return this.primitiveValueForKey('productMenuCount');
    }

    // Property: serverID
    set serverID(value:number) {
        this.setValueForKey(value, 'serverID');
    }
    get serverID():number {
        return this.valueForKey('serverID');
    }
    set serverIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'serverID');
    }
    get serverIDPrimitiveValue():number {
        return this.primitiveValueForKey('serverID');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: menuCategory
    set menuCategory(value:MenuCategory) {
        this.setValueForKey(value, 'menuCategory');
    }
    get menuCategory():MenuCategory {
        return this.valueForKey('menuCategory') as MenuCategory;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: productFormat
    set productFormat(value:ProductFormat) {
        this.setValueForKey(value, 'productFormat');
    }
    get productFormat():ProductFormat {
        return this.valueForKey('productFormat') as ProductFormat;
    }
}
