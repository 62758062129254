/**
 * Created by miguel on 16/3/17.
 */




class InvoiceLineCell extends MUITableViewCell
{
   // _numLabel = null;
    _productView = null;
    _nameLabel = null;
    _taxLabel = null;
    _unitPriceLabel = null;
    _quantityLabel = null;
    _discountLabel = null;
    _priceLabel = null;

    private _separator = null;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    awakeFromHTML()
    {
       // this._numLabel = MUIOutlet(this, "hlv_tv_invoice_num_lbl", "MUILabel");
        this._productView = MUIOutlet(this, "iidv_ic_image", "MUIView");
        this._nameLabel = MUIOutlet(this, "iidv_ic_invoice_product_lbl", "MUILabel");
        this._taxLabel = MUIOutlet(this, 'iidv_ic_tax_lbl','MUILabel');
        this._unitPriceLabel = MUIOutlet(this, 'iidv_ic_unitary_price_lbl','MUILabel');
        this._priceLabel = MUIOutlet(this, "iidv_ic_price_lbl", "MUILabel");
        this._quantityLabel = MUIOutlet(this, 'iidv_ic_quantity_lbl','MUILabel');
        this._discountLabel = MUIOutlet(this, 'iidv_ic_discount_lbl','MUILabel');
        this._separator = MUIOutlet(this, "iidv_ic_separator", "MUIView");
    }

    /*setSelected(value)
    {
        super.setSelected(value);

        if (value == true)
            this._separator.setBackgroundRGBColor(0, 191, 191);
        else
            this._separator.setBackgroundRGBColor(237, 237, 242);
    }*/

    set item(i)
    { 
        this._nameLabel.text = i.valueForKey('product_name');
        
        this._taxLabel.text = (i.valueForKey('tax_quantity') ? parseFloat(i.valueForKey('tax_quantity')) * 100 : 10 ) + ' %';
        this._unitPriceLabel.text = this.cf.stringFromNumber(i.valueForKey('product_price'));
        this._quantityLabel.text = this.cf.stringFromNumber(i.valueForKey('quantity'));
        this._discountLabel.text = this.cf.stringFromNumber(i.valueForKey('totaldiscount'));
        this._priceLabel.text = this.cf.stringFromNumber(i.valueForKey('totalpricebase'));
    }

}