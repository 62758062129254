
class PaymentEntityCell extends UITableViewCell
{
    private nameTextField:MUITextField = null;

    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._item.name = value;
        });

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    } 
    
    protected _item:PaymentEntity = null;
    setItem(item:PaymentEntity){        
        this._item = item;
        this.nameTextField.text = item.name;
    }

    set item(item:PaymentEntity) {
        this.setItem(item);
    }

}