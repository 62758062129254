/**
 * Created by miguel on 10/2/17.
 */


class AddNewCategoryTagsViewController extends MUIViewController
{
    private tagView:TagView = null;

    viewDidLoad(){
        
        super.viewDidLoad();
        this.tagView = MUIOutlet(this, 'tag-view', 'TagView');
        this.tagView.delegate = this; 
    }


    private updateUI(){
        if (this.viewIsLoaded == false) return;

        let predicate = MIOPredicate.predicateWithFormat('type = ' + TagType.Product);
        DBHelper.queryObjectsWithCompletion("Tag", null, predicate, [], this, function(objects){
            this.reloadTags(objects);
        });                
    }


    
    private reloadTags(tags){
        this.tagView.removeAll();
        for (let index = 0; index < tags.length; index++){
            let tag = tags[index] as Tag;
            this.tagView.addTag(tag.name);
            if (this.productTags.containsObject(tag.name)) {
                this.tagView.selectTagAtIndex(index);
            }
        }
    }

    didSelectTagAtIndex(index, name:string){        
        let i = this.productTags.indexOfObject(name);
        if (i < 0){
            this.productTags.addObject(name);            
        }
        else {
            this.productTags.removeObject(name);
        }

        this._product.tags = this.productTags.join(',');
    }

}
