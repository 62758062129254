//
// Generated class IssuedInvoiceLine
//

/// <reference path="IssuedInvoiceLine_ManagedObject.ts" />

class IssuedInvoiceLine extends IssuedInvoiceLine_ManagedObject
{
    identifier  = null;
    id = null;
    id_group  = null;

    date           = null;
    isArchived     = null;
    status         = null;

    id_product     = null;
    productname    = null;
    tax            = null;
    taxname        = null;
    workername     = null;
    tags           = null;
    discount       = null;
    totalpricebase = null;
    totaltaxes     = null;
    totalprice     = null;
    totaldiscount  = null;
    totalinvited   = null;
    totaldeleted   = null;

    orderindex  = null;
    modifiers = [];

    setObject(object, order ,moc)
    {
        var group           = object['group'];
        this.isArchived     = group['isArchived'];
        this.identifier     = object['id'];
        this.id             = object['id'];
        this.id_group       = group.identifier;
        this.date           = object['date'];
        this.status         = object['status'];
        this.tags           = object['tags'];
        this.tax            = object['tax'];
        this.taxname        = object['taxname'];
        this.workername     = object['workername'];        
        this.orderindex     = object['orderindex'] || order;
        this.discount       = group ? group['discount'] || 0: 0;
        this.totalpricebase = object['totalpricebase'];
        this.totaltaxes     = object['totaltaxes'];
        this.totalprice     = object['totalprice'];
        this.totaldiscount  = object['totaldiscount'];
        this.totalinvited   = object['totalinvited'];
        this.totaldeleted   = object['totaldeleted'];

        this.modifiers      = object['modifiers'] || [];
        
    }
    
    getObject()
    {
        var object = {};
        
        if(this.identifier)
            object['id'] = this.identifier;

        object['date'] = this.date;
        object['status'] = this.status;
        object['tags'] = this.tags;
        object['tax'] = this.tax;
        object['taxname'] = this.taxname;
        object['workername'] = this.workername;
        object['orderindex'] = this.orderindex;
        object['totalpricebase'] = this.totalpricebase;
        object['totaltaxes'] = this.totaltaxes;
        object['totalprice'] = this.totalprice;
        object['totaldiscount'] = this.totaldiscount;
        object['totalinvited'] = this.totalinvited;
        object['totaldeleted'] = this.totaldeleted;

        object['modifiers'] = this.modifiers;
    
        return object;
   }
}
