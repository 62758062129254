
class ReportView extends MUIView
{    
    delegate = null;

    initWithLayer(layer, owner, options?){
        super.initWithLayer(layer, owner, options);

        // Check if we have prototypes
        if (this.layer.childNodes.length > 0) {
            for (let index = 0; index < this.layer.childNodes.length; index++) {
                let subLayer = this.layer.childNodes[index];

                if (subLayer.tagName != "DIV")
                    continue;

                if (subLayer.getAttribute("data-table-content") != null){
                    this.addTableContentWithLayer(subLayer);                    
                }    
                else if (subLayer.getAttribute("data-layout-column") != null) {
                    this.sectionColumnLayer = subLayer;
                    subLayer.style.display = "none";
                }    
                else if (subLayer.getAttribute("data-section-text") != null) {
                    this.sectionTextLayer = subLayer;                    
                }
                else if (subLayer.getAttribute("data-section-table") != null) {
                    this.sectionTableLayer = subLayer;
                    subLayer.style.display = "none";
                }            
                else if (subLayer.getAttribute("data-section-chart") != null) {
                    this.sectionChartLayer = subLayer;
                    subLayer.style.display = "none";
                }            
                else if (subLayer.getAttribute("data-section-header") != null) {
                    this.sectionHeaderLayer = subLayer;
                    subLayer.style.display = "none";
                }            
            }
        }
        
    }

    private sectionColumnLayer = null;
    private sectionRowLayer = null;    
    private sectionTextLayer = null;    
    private sectionTableLayer = null;
    private sectionChartLayer = null;
    private sectionHeaderLayer = null;

    private contentView:MUIView = null;
    private addTableContentWithLayer(layer){
        this.contentView = new MUIView();
        this.contentView.initWithLayer(layer, this);
    }

    clear(){
        for (let index = 0; index < this.contentView.subviews.length; index++){
            let v = this.contentView.subviews[index] as MUIView;
            try {
                v.removeFromSuperview();    
            } catch (error) {
                
            }
            
        }

        // while (this.contentView.layer.firstChild) {
        //     this.contentView.layer.removeChild(this.contentView.layer.firstChild);
        // }

        this.contentView.layer.innerHTML = "";
    }

    logText(text:string){
        if (this.sectionTextLayer == null) return;
        let tv = ReportScriptLogTitleView.titleViewWithLayer(this.sectionTextLayer);        
        this.contentView.addSubview(tv);

        tv.title = text;
    }    

    logQuery(objects){        
        if (objects.length == 0) {
            this.logText("No query results");
            return;
        }

        let tv = this.newTable(null);
        //this.contentView.addSubview(tv);

        // Create columns headers
        let line = objects[0];
        tv.newHeaderRow();        
        for (let key in line){            
            tv.addColumn(key);
        }        

        for (let index = 0; index < objects.length; index++){
            line = objects[index];
            tv.newRow();
            for (let key in line){
                let value = line[key];
                tv.addCell(value);
            }
        }
    }

    logTable(table){
        let title = table["Title"];
        let tv = this.newTable(title);
        let columns = table["Columns"];        
        tv.newHeaderRow();        
        for (let index = 0; index < columns.length; index++){
            let col = columns[index];
            tv.addColumnItem(col);
        }        

        let rows = table["Rows"];
        for (let index = 0; index < rows.length; index++){
            let r = rows[index];
            let subRow = r["__sub_row__"];
            let segueIdentifier = r["__segue_identifier__"];
            let segueValues = r["__segue_values__"];
            tv.newRow(subRow, segueIdentifier, segueValues);
            for (let colIndex = 0; colIndex < columns.length; colIndex++){
                let col = columns[colIndex];
                let key = col["Key"];
                let type = col["Type"];
                let align = col["Align"];
                let value = r[key];
                let subCell = false;
                if (r["__" + key + "__formatter__"] != null) {
                    type = r["__" + key + "__formatter__"];
                }
                tv.addCell(value, type, align, false);
            }
        }

        let subTotals = table["SubTotals"];
        if (Object.keys(subTotals).length === 0) return;
        tv.newFooterRow();
        for (let colIndex = 0; colIndex < columns.length; colIndex++){            
            let col = columns[colIndex];
            let key = col["Key"];            
            let type = col["Type"];
            let align = col["Align"];            
            if (colIndex == 0) {
                tv.addCell(MIOLocalizeString("TOTAL", "Total"), type, align, false);
            }
            else {
                let value = subTotals[key];                
                tv.addCell(value, type, align, false);
            }            
            
        }
    }

    private logChart(chart){
        let title = chart["Title"];
        let cv = this.newChart(title);
        cv.type = chart["ChartType"];
        if (cv.type == 100) cv.type = 0;

        let columns = chart["Columns"];        
        let rows = chart["Rows"];
        for (let index = 0; index < rows.length; index++){
            let r = rows[index];                                    
            cv.newRow();
            for (let colIndex = 0; colIndex < 2/*columns.length*/; colIndex++){
                let col = columns[colIndex];                
                let key = col["Key"];
                let type = col["Type"];
                let value = r[key];                
                if (r["__" + key + "__formatter__"] != null) {
                    type = r["__" + key + "__formatter__"];
                }
                cv.addCell(colIndex, value, type, true);
            }
        }

        cv.render();
    }

    private logHeader(header){
        let title = header["Title"];
        let hv =  ReporHeaderView.headerWithLayer(this.sectionHeaderLayer);
        if (this.currentColumnsView == null){
            this.contentView.addSubview(hv);   
        }
        else {
            this.currentColumnsView.addSubview(hv);
        }

        hv.title = title;        
        hv.value = ReportHelper.formattedValueForType(header["Value"], header["FieldType"], false);
    }

    parseResponse(lines){        
        if (lines == null) {
            this.logText("Results came with empty values");
            return;
        }
        for (let index = 0; index < lines.length; index++){
            let l = lines[index];
            let type = l["Type"];
            let value = l["Value"];
            switch(type){
                case "header":
                this.logHeader(l);
                break;

                case "chart":
                this.logChart(l);
                break;

                case "query":
                //this.logQuery(value);
                break;                

                case "table":
                this.logTable(l);
                break;

                case "begin-columns":
                this.beginColumns();
                break;

                case "end-columns":
                this.endColumns();
                break;

                default:
                //this.logText(value);
                break;
            }            
        }
    }
    
    private newTable(title:string){
        let tv = ReportTableView.tableViewWithLayer(this.sectionTableLayer);
        if (this.currentColumnsView == null){
            this.contentView.addSubview(tv);   
        }
        else {
            this.currentColumnsView.addSubview(tv);
        }

        tv.title = title;
        tv.delegate = this.delegate;
        return tv;
    }

    private currentColumnsView:ReporScriptLogColumn = null;
    private beginColumns(){
        if (MIOCoreIsPhone() == true) return;
        this.currentColumnsView = ReporScriptLogColumn.columnWithLayer(this.sectionColumnLayer);
        this.contentView.addSubview(this.currentColumnsView);
    }

    private endColumns(){        
        this.currentColumnsView = null;
    }    

    private newChart(title:string):ReportChartView{
        let cv = ReportChartView.chartViewWithLayer(this.sectionChartLayer);
        if (this.currentColumnsView == null){
            this.contentView.addSubview(cv);   
        }
        else {
            this.currentColumnsView.addSubview(cv);
        }

        cv.title = title;
        return cv;
    }
}

class ReportScriptLogTitleView extends MUIView
{
    static titleViewWithLayer(layer){
        let l = layer.cloneNode(true);
        l.style.display = "";            

        let tv = new ReportScriptLogTitleView();
        tv.initWithLayer(l, this);
        tv.awakeFromHTML();

        return tv;
    }

    private titleLabel:MUILabel = null;
    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
    }

    set title (title:string){
        this.titleLabel.text = title;
    }
}

class ReporScriptLogColumn extends MUIView 
{
    static columnWithLayer(layer):ReporScriptLogColumn{
        let l = layer.cloneNode(true);
        l.style.display = "";            

        let c = new ReporScriptLogColumn();
        c.initWithLayer(l, this);
        c.awakeFromHTML();

        return c;
    }
}

class ReportTableView extends MUIView
{        
    delegate = null;

    static tableViewWithLayer(layer):ReportTableView{
        let l = layer.cloneNode(true);        
        l.style.display = "";            

        let tv = new ReportTableView();
        tv.initWithLayer(l, this);
        tv.awakeFromHTML();
        l.innerHTML = "";

        return tv;
    }
   
    private sectionTableTitleLayer = null;
    private sectionTableHeaderRowLayer = null;
    private sectionTableHeaderColumnLayer = null;
    private sectionTableHeaderColumnFilterLayer = null;
    private sectionTableRowLayer = null;
    private sectionTableSubRowLayer = null;
    private sectionTableRowCellLayer = null;    
    private sectionTableFooterRowLayer = null;

    initWithLayer(layer, owner, options?){
        super.initWithLayer(layer, owner, options);

        // Check if we have prototypes
        if (this.layer.childNodes.length > 0) {
            for (let index = 0; index < this.layer.childNodes.length; index++) {
                let subLayer = this.layer.childNodes[index];

                if (subLayer.tagName != "DIV")
                    continue;

                if (subLayer.getAttribute("data-section-table-title") != null) {
                    this.sectionTableTitleLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-header-row") != null) {
                    this.sectionTableHeaderRowLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-header-column") != null) {
                    this.sectionTableHeaderColumnLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-header-column-filter") != null) {
                    this.sectionTableHeaderColumnFilterLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-row") != null) {
                    this.sectionTableRowLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-subrow") != null) {
                    this.sectionTableSubRowLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-row-cell") != null) {
                    this.sectionTableRowCellLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-footer-row") != null) {
                    this.sectionTableFooterRowLayer = subLayer;
                    subLayer.style.display = "none";
                }                
            }
        }        
    }

    private newLayer(layer){
        let newLayer = layer.cloneNode(true);
        newLayer.style.display = "";
        return newLayer;
    }    

    set title(title:string){
        if (title == null) return;
        if (this.sectionTableTitleLayer == null) return;

        let layer = this.newLayer(this.sectionTableTitleLayer);
        let titleView = new ReportTableTitleView();
        titleView.initWithLayer(layer, this);
        titleView.awakeFromHTML();
        
        titleView.title = title;
        this.addSubview(titleView);
    }

    private currentRowLayer = null;    
    newHeaderRow(){
        let layer = this.newLayer(this.sectionTableHeaderRowLayer);                  
        this.layer.appendChild(layer);
        this.currentRowLayer = layer;
    }

    newHeaderColumn(title:string, align?:string, filter?:boolean){                
        let column:ReportTableColumnView = null;            

        if (filter == true) {
            let layer = this.newLayer(this.sectionTableHeaderColumnFilterLayer);
            column = ReportTableColumnFilterView.columnFilterViewWithLayer(layer, title, align);    
        }
        else {
            let layer = this.newLayer(this.sectionTableHeaderColumnFilterLayer);
            column = ReportTableColumnView.columnViewWithLayer(layer, title, align);
        }
        
        this.currentRowLayer.appendChild(column.layer);        
    }

    private columns = [];
    addColumn(title:string, key?:string, type?:string, align?:string, filter?:boolean){
        let col = {"Title": title, "Key": key, "Type": type, "Align": align, "ColumnFilter": filter};
        this.columns.push(col);
        this.newHeaderColumn(title, align, filter);
    }

    addColumnItem(item){        
        this.columns.push(item);
        this.newHeaderColumn(item["Title"], item["Align"], item["ColumnFilter"]);
    }

    newRow(subCell?:boolean, segueIdentifier?:string, segueValues?){
        if (subCell == true){
            this.currentRowLayer = this.newLayer(this.sectionTableSubRowLayer);
        }
        else {
            this.currentRowLayer = this.newLayer(this.sectionTableRowLayer);
        }
        this.layer.appendChild(this.currentRowLayer);

        if (segueIdentifier == null) return;
        let instance = this;
        this.currentRowLayer.addEventListener("click", function(){
            instance.didRowClick.call(instance, segueIdentifier, segueValues);
        });

    }

    addCell(value, type?:string, align?:string, showNull?:boolean){
        if (this.currentRowLayer == null) return;
        
        let layer = this.newLayer(this.sectionTableRowCellLayer);
        if (align != null) MUICoreLayerAddStyle(layer, "align-" + align);
        if (value != null) layer.innerHTML = value;
        this.currentRowLayer.appendChild(layer);

        let v = ReportHelper.formattedValueForType(value, type, showNull == true ? true : false);
        layer.innerHTML = v;        

    }

    newFooterRow(){
        let layer = this.newLayer(this.sectionTableFooterRowLayer);
        this.layer.appendChild(layer);
        this.currentRowLayer = layer;
    }

    private didRowClick(segueIdentifier:string, segueValues){
        if (this.delegate != null){
            this.delegate.performSegueWithIdentfier(segueIdentifier, segueValues);
        }
    }

}

class ReportTableTitleView extends MUIView
{
    private titleLabel:MUILabel = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
    }

    set title(title:string){
        this.titleLabel.text = MIOLocalizeString(title, title);
    }
}

class ReportTableColumnView extends MUIView
{
    static columnViewWithLayer(layer, title:string, align?:string):ReportTableColumnView {        
        let column = new ReportTableColumnView();
        column.initWithLayer(layer, this);
        column.awakeFromHTML();     
        
        if (align != null) MUICoreLayerAddStyle(layer, "justify-" + align);
        column.title = title;

        return column;
    }

    set title(title:string){
        this.layer.innerHTML = MIOLocalizeString(title, title);
    }
}

class ReportTableColumnFilterView extends ReportTableColumnView
{
    static columnFilterViewWithLayer(layer, title:string, align?:string):ReportTableColumnFilterView{        
        let column = new ReportTableColumnFilterView();
        column.initWithLayer(layer, this);
        column.awakeFromHTML();     
        
        if (align != null) MUICoreLayerAddStyle(layer, "justify-" + align);
        column.title = title;

        return column;
    }

    private sortButton:MUIButton = null;
    private filterTextField:MUITextField = null;

    awakeFromHTML(){
        this.sortButton = MUIOutlet(this, "sort-btn", "MUIButton");
        this.sortButton.setAction(this, function() {

        });
        this.filterTextField = MUIOutlet(this, "filter-tf", "MUITextField");
    }

    set title(title:string){
        this.filterTextField.setPlaceholderText(MIOLocalizeString(title, title));        
    }
}


class ReportChartView extends MUIView
{        
    static chartViewWithLayer(layer):ReportChartView {
        let l = layer.cloneNode(true);        
        l.style.display = "";            

        let cv = new ReportChartView();
        cv.initWithLayer(l, this);
        cv.awakeFromHTML();
        //l.innerHTML = "";

        return cv;
    }

    private titleLabel:MUILabel = null;
    private chartView:MUIChartView = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "chart-title-lbl", "MUILabel");
        this.chartView = MUIOutlet(this, "chart-view", "MUIChartView");
    }

    set title(title:string){
        this.titleLabel.text = MIOLocalizeString(title, title);
    }   
    
    type = MUIChartViewType.Bar; 

    newRow() {

    }

    private labels = [];
    private values = [];

    addCell(colIndex, value, type?:string, showNull?:boolean){
        if (colIndex == 0) this.labels.push(value);
        else if (colIndex == 1)  this.values.push(value);
    }

    render(){
        this.chartView.labels = this.labels;
        this.chartView.values = this.values;
        this.chartView.renderWithType(this.type);
    }

}

class ReporHeaderView extends MUIView 
{
    private titleLabel:MUILabel = null;
    private valueLabel:MUILabel = null;

    static headerWithLayer(layer):ReporHeaderView {
        let l = layer.cloneNode(true);
        l.style.display = "";            

        let c = new ReporHeaderView();
        c.initWithLayer(l, this);
        c.awakeFromHTML();

        return c;
    }

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "header-title-lbl", "MUILabel");
        this.valueLabel = MUIOutlet(this, "header-value-lbl", "MUILabel");
    }

    set title(title:string){
        this.titleLabel.text = MIOLocalizeString(title, title);
    }

    set value(value:string){
        this.valueLabel.text = value;
    }

}
