

// Generated class TimeRangeGroup_ManagedObject

class TimeRangeGroup_ManagedObject extends MIOManagedObject
{

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }

    // Property: duration
    set duration(value:number) {
        this.setValueForKey(value, 'duration');
    }
    get duration():number {
        return this.valueForKey('duration');
    }
    set durationPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'duration');
    }
    get durationPrimitiveValue():number {
        return this.primitiveValueForKey('duration');
    }

    // Property: endHour
    set endHour(value:string) {
        this.setValueForKey(value, 'endHour');
    }
    get endHour():string {
        return this.valueForKey('endHour');
    }
    set endHourPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'endHour');
    }
    get endHourPrimitiveValue():string {
        return this.primitiveValueForKey('endHour');
    }

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: maxPax
    set maxPax(value:number) {
        this.setValueForKey(value, 'maxPax');
    }
    get maxPax():number {
        return this.valueForKey('maxPax');
    }
    set maxPaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maxPax');
    }
    get maxPaxPrimitiveValue():number {
        return this.primitiveValueForKey('maxPax');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: startHour
    set startHour(value:string) {
        this.setValueForKey(value, 'startHour');
    }
    get startHour():string {
        return this.valueForKey('startHour');
    }
    set startHourPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'startHour');
    }
    get startHourPrimitiveValue():string {
        return this.primitiveValueForKey('startHour');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Relationship: timeRanges
    protected _timeRanges:MIOManagedObjectSet = null;
    get timeRanges():MIOManagedObjectSet {
        return this.valueForKey('timeRanges');
    }
    addTimeRangesObject(value:TimeRange) {
        this._addObjectForKey(value, 'timeRanges');
    }
    removeTimeRangesObject(value:TimeRange) {
        this._removeObjectForKey(value, 'timeRanges');
    }
}
