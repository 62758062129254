/**
 * Created by godshadow on 04/08/16.
 */

enum SetupType {
    Settings,
    Tax,
    PayMethods,
    PayEntities,
    //TimeRanges,
    Departments,
    Warehouse,
    Online,
    StockCustomConcept,
    Format,
    ProductSource,
    AttributeType,
    // ModifierCategory,
    BookingsChannels,
    BookingsRecommendation,
    BookingsZones,
    BookingsZonesSetup,
    EmailTemplates
}
class SetupViewController extends BaseViewController
{
    private tableView:MUITableView = null;

    private _current = {};
    private noDetailViewController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this,"table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return 1;
    }

    numberOfRowsInSection(tableview:MUITableView, section){
        return this.items.length;
    }

    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("SetupCell") as SetupCell;

        let s = this.items[indexPath.row];
        cell.title = s.name;

        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        this._current = this.items[indexPath.row];
        this._showCurrentItem();
    }

    private _showCurrentItem(){
        let sdvc = null;

        switch(this._current['type'])
        {
            case SetupType.Settings:
                sdvc = new PlaceSettingsViewController('place-settings-view');
                sdvc.initWithResource('layout/mainsettings/PlaceSettingsView.html');
                break;

            case SetupType.Departments:
                sdvc = new DepartmentViewController("single-edit-view");
                sdvc.initWithResource("layout/base/SingleEditView.html");
                break;

            case SetupType.Tax:
                let tlvc = new TaxListViewController('tax-list-view');
                tlvc.initWithResource('layout/taxes/TaxListView.html');
                sdvc = new MUINavigationController();
                sdvc.initWithRootViewController(tlvc);
                break;

            case SetupType.PayMethods:
                sdvc = new PayMethodsViewController("single-edit-view");
                sdvc.initWithResource("layout/base/SingleEditView.html");
                break;

            case SetupType.PayEntities:
                sdvc = new PaymentEntityViewController("payment-entity-view");
                sdvc.initWithResource("layout/paymententity/PaymentEntityView.html");
                break;

            case SetupType.BookingsChannels:
                sdvc = new ChannelViewController('channel-view');
                sdvc.initWithResource('layout/bookings/channel/ChannelView.html');
                break;

            case SetupType.Warehouse:
                sdvc = new WarehouseSetupViewController('single-edit-view');
                sdvc.initWithResource('layout/base/SingleEditView.html');
                break;

            case SetupType.Online:
                var onvc = new OnlineSettingsViewController('online-settings-view');
                onvc.initWithResource('layout/onlineSettings/OnlineSettingsView.html');
                sdvc = new MUINavigationController('online_nav_controller');
                sdvc.initWithRootViewController(onvc);
                break;

            case SetupType.StockCustomConcept:
                sdvc = new StockCustomConceptViewController('single-edit-view');
                sdvc.initWithResource('layout/base/SingleEditView.html');
                break;

             case SetupType.BookingsZones:
                sdvc = new BookingsZonesSetupViewController('bookings-zones-setup-view');
                sdvc.initWithResource('layout/bookings/zones/BookingsZonesSetupView.html');
                break;

            case SetupType.ProductSource:
                sdvc = new ProductSourceSetupViewController('single-edit-view');
                sdvc.initWithResource('layout/base/SingleEditView.html');
                break;

            case SetupType.Format:
                sdvc = new SettingsFormatViewController("single-edit-view");
                sdvc.initWithResource("layout/base/SingleEditView.html");
                break;

            case SetupType.AttributeType:
                var atvc:AttributeTypeViewController = new AttributeTypeViewController('attribute-type-view');
                atvc.initWithResource('layout/modals/attributetype/AttributeTypeView.html');
                sdvc = new MUINavigationController('sat_nav_controller');
                sdvc.initWithRootViewController(atvc);
                break;

            // case SetupType.ModifierCategory:
            //     var mcvc:ModifierCategoryViewController = new ModifierCategoryViewController("modifier_category_view");
            //     mcvc.mode = ModifierCategoryMode.Single;
            //     mcvc.initWithResource("layout/modals/modifiercategory/ModifierCategoryView.html");
            //     sdvc = new MUINavigationController('modifier_nav_controller');
            //     sdvc.initWithRootViewController(mcvc);
            //     break;

            // case SetupType.BookingsZonesDays:
            //     sdvc = new BookingsZonesDaySetupViewController("bookings-zones-day-setup-view");
            //     sdvc.initWithResource('layout/bookings/zones/BookingsZonesDaySetupView.html');
            //     break;

            case SetupType.BookingsZonesSetup:
                sdvc = new BookingZoneSetupViewController("booking-zone-setup");
                sdvc.initWithResource("layout/bookingzones/BookingZoneSetupView.html");
                break;


            // case SetupType.TimeRanges:
            //     sdvc = new TimeRangeViewController("timerange-view");
            //     sdvc.initWithResource('layout/timerange/TimeRangeView.html');
            //     break;

            case SetupType.BookingsRecommendation:
                sdvc = new BookingRecommendationViewController("single-edit-view");
                sdvc.initWithResource("layout/base/SingleEditView.html");
                break;

            case SetupType.EmailTemplates:
                sdvc = new EmailTemplateViewController("email-settings-view");
                sdvc.initWithResource("layout/mainsettings/EmailSettingsView.html");
                break;
        }

        this.splitViewController.showDetailViewController(sdvc);
    }

    private _showNoItemSelected(){
        this._current = null;
        if(!this.noDetailViewController) {
            this.noDetailViewController = new MUIViewController("setup-no-select-view");
            this.noDetailViewController.initWithResource("layout/setup/SetupNoSelectView.html");
        }
        this.splitViewController.showDetailViewController(this.noDetailViewController);
    }

    private _items = null;
    private get items(){
        if (this._items != null) return this._items;

        this.addItem('SETTINGS', SetupType.Settings);
        this.addItem('TAXES', SetupType.Tax);
        this.addItem('PAY METHODS', SetupType.PayMethods);
        this.addItem('PAYMENT ENTITIES', SetupType.PayEntities);
        //this.addItem('TIME RANGES', SetupType.TimeRanges);
        this.addItem('DEPARTMENTS', SetupType.Departments);
        this.addItem('WAREHOUSES', SetupType.Warehouse);
        this.addItem('ONLINE', SetupType.Online);
        this.addItem('PRODUCT SOURCE', SetupType.ProductSource);
        this.addItem('FORMATS', SetupType.Format);
        this.addItem('ATTRIBUTES', SetupType.AttributeType);
        //this.addItem('MODIFIER CATEGORY', SetupType.ModifierCategory);
        this.addItem('STOCK CUSTOM CONCEPTS', SetupType.StockCustomConcept);
        //this.addItem('BOOKING ZONES', SetupType.BookingsZones);
        //this.addItem('BOOKING ZONES PER DAY', SetupType.BookingsZonesDays);
        this.addItem('BOOKING ZONES SETUP', SetupType.BookingsZonesSetup);
        this.addItem('BOOKING CHANNELS', SetupType.BookingsChannels);
        this.addItem('BOOKING RECOMENDATIONS', SetupType.BookingsRecommendation);
        this.addItem('EMAIL TEMPLATES', SetupType.EmailTemplates);

        return this._items;
    }

    private addItem(name:string, type, section?){
        if (this._items == null) this._items = [];

        let item = {};
        item["name"] = name;
        item["type"] = type;
        if (section != null) item["section"] = section;

        this._items.push(item);
    }

}
