class BankMovementCell extends MUITableViewCell
{
    private conceptLabel:MUILabel = null;
    private amountLabel:MUILabel = null;

    awakeFromHTML(){
        this.conceptLabel = MUIOutlet(this, "concept-lbl", "MUILabel");
        this.amountLabel = MUIOutlet(this, "amount-lbl", "MUILabel");
    }

    set item(item:BankAccountMovement){
        let cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;

        this.conceptLabel.text = item.concept;
        this.amountLabel.text = cf.stringFromNumber(item.amount);
    }
}