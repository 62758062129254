
class StockCustomConceptViewController extends SingleEditViewController
{    
    get title(){
        return "Stock Custom Concepts";
    }

    fetchEntity(){
        return "StockCustomConcept";
    }

    sortDescriptors(){
        return [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
    }

    configureCellAtIndexPath(cell:SingleEditCell, indexPath:MIOIndexPath, item:MIOManagedObject){
        let d = item as StockCustomConcept;
        cell.setItem(d, "name");
    }
    
    addButtonDidPress(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle('Add new custom concept', 'Set the conpcept name', MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
           textField.setPlaceholderText("Name");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, this, function(){
            let name = avc.textFields[0].text;
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let d = MIOEntityDescription.insertNewObjectForEntityForName(this.fetchEntity(), ad.managedObjectContext) as Department;                
            d.name = name;    
            
            ad.managedObjectContext.save();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Cancel, null, null));        

        this.presentViewController(avc, true);
    }

    removeItem(item:MIOManagedObject){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.managedObjectContext.deleteObject(item);
        ad.managedObjectContext.save();        
    }

    
}