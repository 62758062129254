
class NoteDetailViewController extends EntityListViewController 
{	
	showBackButton = false;

	protected backButton:MUIButton = null;

    protected saveButton:MUIButton = null;
    protected printButton:MUIButton = null;
    protected actionButton:MUIButton = null;

    protected commentsTextField:MUITextField = null;

    noteStatus = StockNoteStatus.None;  
    protected allowAddingLines = false;

    //protected removeButton:MUIButton = null;
    
    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, 'back-btn','MUIButton');
        if (this.backButton != null) this.backButton.setAction(this, function(){
            this.navigationController.popViewController(true);
        });

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, function() {
            DBHelper.saveMainContext();
        });

        this.printButton = MUIOutlet(this, "print-btn", "MUIButton");
        if (this.printButton != null) this.printButton.setAction(this, function(){
            this.printStockNote();
        });

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(){
            this.showActions();
        });

        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;          
        
        this.commentsTextField = MUIOutlet(this, "comments-tf", "MUITextField");
        if (this.commentsTextField) this.commentsTextField.setOnChangeText(this, function(control, value){
            this.stockNote.comments = value;
        });

        MIONotificationCenter.defaultCenter().addObserver(this, "StockNoteDidProcess", function(notification:MIONotification){
            let note = notification.object as StockNote;
            if (note.identifier == this.stockNote.identifier) this.note = this.stockNote;
        });

        //this.removeButton = MUIOutlet(this, "remove-btn", "MUIButton");
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        if (this.backButton){
            this.backButton.setHidden(!this.showBackButton);
        }
        this._updateUI();
    }

    protected stockNote:StockNote = null;
    set note(note:StockNote) {
        this.stockNote = note;
        this.noteStatus = note.status;
        this.allowAddingLines = this.noteStatus == StockNoteStatus.None;
        this._updateUI();
	}
	
	set filterValues(values:any){
        let identifier = values["id"];
        if (identifier == null) return;
        DBHelper.queryObjectsWithCompletion("StockNote", null, MIOPredicate.predicateWithFormat("identifier == '" + identifier + "'"), [], this, function(objects){
            if (objects.length > 0) {
                let note = objects[0];
                this.note = note;
            }
        });
    }

    protected canReloadData(){
        if (this.viewIsLoaded == false || this.stockNote == null) return false;
        return true;
    }

    private _updateUI(){
        if (this.canReloadData() == false) return;
        
        if (this.stockNote.status == StockNoteStatus.Processed) {
            this.saveButton.setHidden(true);            
            if (this.printButton)this.printButton.setHidden(false);
        }
        else {
            this.saveButton.setHidden(false);            
            if (this.printButton)this.printButton.setHidden(true);
        }        

        let actions = this.customActionsForStatus(this.stockNote.status);
        this.actionButton.hidden = actions.length > 0? false : true;

        if (this.commentsTextField) this.commentsTextField.text = this.stockNote.comments;
        
        //temporary fix until setAccessoryType(MUITableViewCellAccessoryType.None) works again
        // if (this.noteStatus == StockNoteStatus.Pending){
        //     MUICoreLayerAddStyle(this.removeButton.layer, "no-visible");
        // }

        this.updateUI();
    }

    protected updateUI(){}

    numberOfSections(tableview){
        let sections = this.fetchedResultsController.sections.length;
        if (this.allowAddingLines == true) sections++;
        return sections;
    }
    
    numberOfRowsInSection(tableview, section){
        if (section == this.fetchedResultsController.sections.length) return 1;

        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell:NoteLineCell = null;
                
        if (indexPath.section == this.fetchedResultsController.sections.length) {
            cell = tableview.dequeueReusableCellWithIdentifier('AddLineCell') as NoteLineCell;
            cell.note = this.stockNote;
            cell.delegate = this;
            return cell;
        }

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockNoteLine;
        if (this.noteStatus == StockNoteStatus.None || this.noteStatus == StockNoteStatus.Pending) {
            cell = tableview.dequeueReusableCellWithIdentifier('EditLineCell') as NoteLineCell;
            cell.delegate = this;
            // if (this.noteStatus == StockNoteStatus.Pending){
            //     //cell.setAccessoryType(MUITableViewCellAccessoryType.None);
            // }
        }
        else if (this.noteStatus == StockNoteStatus.Processed){
            cell = tableview.dequeueReusableCellWithIdentifier('LineCell') as NoteLineCell;
            cell.delegate = null;
        }                                                                    
        
        cell.note = this.stockNote;
        cell.line = item;
                
        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {   
        if (this.noteStatus == StockNoteStatus.Processed || this.noteStatus == StockNoteStatus.Pending)
            return MUITableViewCellEditingStyle.None;
        
        if (indexPath.section == this.fetchedResultsController.sections.length) {
            return MUITableViewCellEditingStyle.Insert;
        }
        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let noteline = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            this.removeNoteLine(noteline);
        }
    }  
    
    protected addedNewLine = false;
    lineDidInserted(cell:NoteLineCell, line:StockNoteLine){
        this.addedNewLine = true;
        DBHelper.saveMainContext();        
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.reloadData();
        if (this.addedNewLine == true) {
            //this.tableView.scrollToBottom(false);
            this.addedNewLine = false;
            // set cell focus 
            let ip = MIOIndexPath.indexForRowInSection(0, this.fetchedResultsController.sections.length);
            let cell = this.tableView.cellAtIndexPath(ip) as NoteLineCell;
            if (cell != null) cell.becomeFirstResponder();
        }
    }


    protected removeNoteLine(line:StockNoteLine){
        this.stockNote.removeLinesObject(line);
        DBHelper.deleteObjectFromMainContext(line, true);
    }

    protected showActions(){
        let actions = this.customActionsForStatus(this.stockNote.status);
        if (actions.length == 0) return;

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ACTIONS', 'Actions'), 'Choose an action', MUIAlertViewStyle.Default);
           
        for (let index = 0; index < actions.length; index++){
            let item = actions[index];
            let type = item["Type"];
            let action = item["Action"];

            if (type == "Action") {
                avc.addAction(action);
            }
        }
                
        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'Cancel'), MUIAlertActionStyle.Cancel, null, null));
       
        this.presentViewController(avc, true);
    }

    protected customActionsForStatus(status:StockNoteStatus){
        
        let actions = [];

        if (status == StockNoteStatus.None  || status == StockNoteStatus.Pending){            
            let deleteItem = {"Type": "Action", "Action": this.deleteAction};
            actions.addObject(deleteItem);
        }
        // else if (status == StockNoteStatus.Processed){
        //     let syncItem = {"Type": "Action", "Action" : this.syncAction};
        //     actions.addObject(syncItem);
        // }

        return actions;
    }

    protected get deleteAction(){
        return MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DELETE', 'Delete'), MUIAlertActionStyle.Destructive, this, function(){
            DBHelper.removeNote(this.stockNote);
        });   
    }

    protected get syncAction(){
        return MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD TO SYNC QUEUE', 'ADD TO SYNC QUEUE'), MUIAlertActionStyle.Default, this, function(){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            ad.webService.syncAddToQueue(this.stockNote.identifier, "StockNote", this, function(code, data){
                if (code == 200) {
                    AppHelper.showInfoMessage(null, "Added to Sync Queue", "The item was added succefully");
                    MIONotificationCenter.defaultCenter().postNotification("DBSyncEntityDidChange", null);
                }
                else AppHelper.showErrorMessage(null, "Error", "Something went wrong. Try again later");
            });
        });
    }

    private observeChanges(oldNote:StockNote, newNote:StockNote){

        if (oldNote != null) oldNote.removeObserver(this, "isFault");
        if (newNote != null) newNote.addObserver(this, "isFault");
    }

    observeValueForKeyPath(keyPath:string, type:string, object, ctx) {
        if (type != "did") return;

        if (this.stockNote.isFault == false) return;

        this._updateUI();
    }

    private printStockNote(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.exportStockNotePDF(this.stockNote.identifier, null, null);
    }
}