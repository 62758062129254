//
// Generated class Location
//

/// <reference path="Location_ManagedObject.ts" />

class Location extends Location_ManagedObject
{

}
