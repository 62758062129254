

// Generated class ProductListItem_ManagedObject

class ProductListItem_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: timestamp
    set timestamp(value:string) {
        this.setValueForKey(value, 'timestamp');
    }
    get timestamp():string {
        return this.valueForKey('timestamp');
    }
    set timestampPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'timestamp');
    }
    get timestampPrimitiveValue():string {
        return this.primitiveValueForKey('timestamp');
    }

    // Property: parentCategoryID
    set parentCategoryID(value:string) {
        this.setValueForKey(value, 'parentCategoryID');
    }
    get parentCategoryID():string {
        return this.valueForKey('parentCategoryID');
    }
    set parentCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'parentCategoryID');
    }
    get parentCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('parentCategoryID');
    }

    // Property: parentStockCategoryID
    set parentStockCategoryID(value:string) {
        this.setValueForKey(value, 'parentStockCategoryID');
    }
    get parentStockCategoryID():string {
        return this.valueForKey('parentStockCategoryID');
    }
    set parentStockCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'parentStockCategoryID');
    }
    get parentStockCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('parentStockCategoryID');
    }

    // Property: itemID
    set itemID(value:string) {
        this.setValueForKey(value, 'itemID');
    }
    get itemID():string {
        return this.valueForKey('itemID');
    }
    set itemIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'itemID');
    }
    get itemIDPrimitiveValue():string {
        return this.primitiveValueForKey('itemID');
    }

    // Property: isEnableForSales
    set isEnableForSales(value:boolean) {
        this.setValueForKey(value, 'isEnableForSales');
    }
    get isEnableForSales():boolean {
        return this.valueForKey('isEnableForSales');
    }
    set isEnableForSalesPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isEnableForSales');
    }
    get isEnableForSalesPrimitiveValue():boolean {
        return this.primitiveValueForKey('isEnableForSales');
    }

    // Property: isEnableForStock
    set isEnableForStock(value:boolean) {
        this.setValueForKey(value, 'isEnableForStock');
    }
    get isEnableForStock():boolean {
        return this.valueForKey('isEnableForStock');
    }
    set isEnableForStockPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isEnableForStock');
    }
    get isEnableForStockPrimitiveValue():boolean {
        return this.primitiveValueForKey('isEnableForStock');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: imageURL
    set imageURL(value:string) {
        this.setValueForKey(value, 'imageURL');
    }
    get imageURL():string {
        return this.valueForKey('imageURL');
    }
    set imageURLPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'imageURL');
    }
    get imageURLPrimitiveValue():string {
        return this.primitiveValueForKey('imageURL');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: stockName
    set stockName(value:string) {
        this.setValueForKey(value, 'stockName');
    }
    get stockName():string {
        return this.valueForKey('stockName');
    }
    set stockNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'stockName');
    }
    get stockNamePrimitiveValue():string {
        return this.primitiveValueForKey('stockName');
    }

    // Property: stockElaborationType
    set stockElaborationType(value:number) {
        this.setValueForKey(value, 'stockElaborationType');
    }
    get stockElaborationType():number {
        return this.valueForKey('stockElaborationType');
    }
    set stockElaborationTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'stockElaborationType');
    }
    get stockElaborationTypePrimitiveValue():number {
        return this.primitiveValueForKey('stockElaborationType');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: category
    set category(value:Category) {
        this.setValueForKey(value, 'category');
    }
    get category():Category {
        return this.valueForKey('category') as Category;
    }
    // Relationship: stockCategory
    set stockCategory(value:StockCategory) {
        this.setValueForKey(value, 'stockCategory');
    }
    get stockCategory():StockCategory {
        return this.valueForKey('stockCategory') as StockCategory;
    }
}
