

class CreateAccountViewController extends MUIViewController
{
    private cancelButton = null;
    private createButton = null;

    private nameTextField = null;
    private emailTextField = null;
    private passwordTextField = null;
    private confirmationTextField = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.nameTextField = MUIOutlet(this, 'cav_name_box', 'MUITextField');
        this.emailTextField = MUIOutlet(this, 'cav_email_box', 'MUITextField');
        this.passwordTextField = MUIOutlet(this, 'cav_pass_box', 'MUITextField');
        this.confirmationTextField = MUIOutlet(this, 'cav_verify_pass_box', 'MUITextField');

        this.cancelButton = MUIOutlet(this, 'cav_cancel_btn','MUIButton');
        this.cancelButton.setAction(this, function()
        {
            console.log('Cancel button');
            this.navigationController.popViewController(true);
        });

        this.createButton = MUIOutlet(this, 'cav_create_btn', 'MUIButton');
        this.createButton.setAction(this, function() 
        {
            console.log('Create account');
            var ws = MUIWebApplication.sharedInstance().delegate.webService;
            
            ws.signin(this.nameTextField.text, this.emailTextField.text, this.passwordTextField.text, this.confirmationTextField.text, this, function(error, result, data) {
                if(result)
                    this.navigationController.popViewController(true);
                else {
                    alert(MIOLocalizeString('THERE WAS AN ERROR CREATING ACCOUNT','There was an error creating the account'));
                }
            });
        });

    }
}