/// <reference path="PaymentEntityCell.ts" />


class RedsysPaymentEntityCell extends PaymentEntityCell
{    
    private merchantTextField:MUITextField = null;
    private terminalTextField:MUITextField = null;
    private secretTextField:MUITextField = null;
    private actionTextField:MUITextField = null;
    
    awakeFromHTML(){     
        super.awakeFromHTML();

        this.merchantTextField = MUIOutlet(this, "merchant-code-tf", "MUITextField");
        this.merchantTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._config["MERCHANTCODE"] = value;
            this._item.setRedsysConfig(this._config);
        });
        
        this.terminalTextField = MUIOutlet(this, "terminal-tf", "MUITextField");
        this.terminalTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._config["TERMINAL"] = value;
            this._item.setRedsysConfig(this._config);
        });
        
        this.secretTextField = MUIOutlet(this, "secret-tf", "MUITextField");
        this.secretTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._config["SECRET_KEY"] = value;
            this._item.setRedsysConfig(this._config);
        });

        this.actionTextField = MUIOutlet(this, "action-tf", "MUITextField");        
        this.actionTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._config["ACTION_URL"] = value;
            this._item.setRedsysConfig(this._config);
        });

    } 
    
    private _config = null;
    set item(item:PaymentEntity){        
        this.setItem(item); 
                
        this._config = item.redysConfig();
        this.merchantTextField.text = this._config["MERCHANTCODE"];
        this.terminalTextField.text = this._config["TERMINAL"];
        this.secretTextField.text = this._config["SECRET_KEY"];
        this.actionTextField.text = this._config["ACTION_URL"];        
    }

}