//
// Generated class UserBusiness
//

/// <reference path="UserBusiness_ManagedObject.ts" />

class UserBusiness extends UserBusiness_ManagedObject
{
    get indexPath():string {
        return this._followIndexPath(this.parent, this.name);
    }

    private _followIndexPath(parent:Business, name:string):string{
        if (parent == null) return name;
        return this._followIndexPath(parent.parent, parent.name) + "/" + name;
    }
}
