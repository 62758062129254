//
// Generated class IntegratorsSync
//

/// <reference path="IntegratorsSync_ManagedObject.ts" />

enum IntegratorsSyncStatus
{
    NoSync,
    Synced    
}


class IntegratorsSync extends IntegratorsSync_ManagedObject
{
    statusString(){
        switch (this.status){
            case 0:            
                return "NoSync";                

            case 1:                
                return "Synced";
        }

        return null;
    }
}
