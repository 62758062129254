//
// Generated class BKTicket
//

/// <reference path="BkTicket_ManagedObject.ts" />

class BkTicket extends BkTicket_ManagedObject
{
    identifier = null;
    event = null;
    id = null;
    begindate = null;
    enddate = null;
    used = null;
    total = null;
    
    sdt = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;
    ldt = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;
    nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    setObject(object)
    {
        this.event = object['event'];
        this.identifier = object["id"];
        this.id = object["id"];
        this.begindate = this.sdt.dateFromString(object["begindate"]);
        this.enddate = this.sdt.dateFromString(object["enddate"]);
        this.used = object['used'];
        this.total = object['total'];
        this.usedAt = object['usedAt'];
        this.code = object['code'];
        this.boughtAt = object['boughtAt'];
    } 
    get name()
    {
        let begindate = this.ldt.stringFromDate(this.begindate);
        let enddate = this.ldt.stringFromDate(this.enddate);
        
        return begindate + ' - ' + enddate;
    }
}
