

// Generated class BudgetLine_ManagedObject

class BudgetLine_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: annotation
    set annotation(value:string) {
        this.setValueForKey(value, 'annotation');
    }
    get annotation():string {
        return this.valueForKey('annotation');
    }
    set annotationPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'annotation');
    }
    get annotationPrimitiveValue():string {
        return this.primitiveValueForKey('annotation');
    }

    // Property: measureUnitId
    set measureUnitId(value:number) {
        this.setValueForKey(value, 'measureUnitId');
    }
    get measureUnitId():number {
        return this.valueForKey('measureUnitId');
    }
    set measureUnitIdPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'measureUnitId');
    }
    get measureUnitIdPrimitiveValue():number {
        return this.primitiveValueForKey('measureUnitId');
    }

    // Property: productName
    set productName(value:string) {
        this.setValueForKey(value, 'productName');
    }
    get productName():string {
        return this.valueForKey('productName');
    }
    set productNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productName');
    }
    get productNamePrimitiveValue():string {
        return this.primitiveValueForKey('productName');
    }

    // Property: productCostPrice
    set productCostPrice(value:number) {
        this.setValueForKey(value, 'productCostPrice');
    }
    get productCostPrice():number {
        return this.valueForKey('productCostPrice');
    }
    set productCostPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productCostPrice');
    }
    get productCostPricePrimitiveValue():number {
        return this.primitiveValueForKey('productCostPrice');
    }

    // Property: productPrice
    set productPrice(value:number) {
        this.setValueForKey(value, 'productPrice');
    }
    get productPrice():number {
        return this.valueForKey('productPrice');
    }
    set productPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productPrice');
    }
    get productPricePrimitiveValue():number {
        return this.primitiveValueForKey('productPrice');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }

    // Property: totalPriceBase
    set totalPriceBase(value:number) {
        this.setValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBase():number {
        return this.valueForKey('totalPriceBase');
    }
    set totalPriceBasePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBasePrimitiveValue():number {
        return this.primitiveValueForKey('totalPriceBase');
    }

    // Property: totalPrice
    set totalPrice(value:number) {
        this.setValueForKey(value, 'totalPrice');
    }
    get totalPrice():number {
        return this.valueForKey('totalPrice');
    }
    set totalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPrice');
    }
    get totalPricePrimitiveValue():number {
        return this.primitiveValueForKey('totalPrice');
    }

    // Property: totalTaxes
    set totalTaxes(value:number) {
        this.setValueForKey(value, 'totalTaxes');
    }
    get totalTaxes():number {
        return this.valueForKey('totalTaxes');
    }
    set totalTaxesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTaxes');
    }
    get totalTaxesPrimitiveValue():number {
        return this.primitiveValueForKey('totalTaxes');
    }

    // Property: totalDiscount
    set totalDiscount(value:number) {
        this.setValueForKey(value, 'totalDiscount');
    }
    get totalDiscount():number {
        return this.valueForKey('totalDiscount');
    }
    set totalDiscountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDiscount');
    }
    get totalDiscountPrimitiveValue():number {
        return this.primitiveValueForKey('totalDiscount');
    }

    // Property: discountString
    set discountString(value:string) {
        this.setValueForKey(value, 'discountString');
    }
    get discountString():string {
        return this.valueForKey('discountString');
    }
    set discountStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'discountString');
    }
    get discountStringPrimitiveValue():string {
        return this.primitiveValueForKey('discountString');
    }

    // Property: taxQuantity
    set taxQuantity(value:number) {
        this.setValueForKey(value, 'taxQuantity');
    }
    get taxQuantity():number {
        return this.valueForKey('taxQuantity');
    }
    set taxQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxQuantity');
    }
    get taxQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('taxQuantity');
    }

    // Property: taxName
    set taxName(value:string) {
        this.setValueForKey(value, 'taxName');
    }
    get taxName():string {
        return this.valueForKey('taxName');
    }
    set taxNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxName');
    }
    get taxNamePrimitiveValue():string {
        return this.primitiveValueForKey('taxName');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: budget
    set budget(value:Budget) {
        this.setValueForKey(value, 'budget');
    }
    get budget():Budget {
        return this.valueForKey('budget') as Budget;
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
}
