/**
 * Created by crisan on 7/12/16.
 */

class ClientAccountCell extends MUITableViewCell
{
    private dateLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private paymethodLabel:MUILabel = null;
    private quantityLabel:MUILabel = null;
    
    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.conceptLabel = MUIOutlet(this, 'concept-lbl','MUILabel');
        this.paymethodLabel = MUIOutlet(this, "paymethod-lbl", "MUILabel");
        this.quantityLabel = MUIOutlet(this, "balance-lbl", "MUILabel");
        this.separatorStyle = MUITableViewCellSeparatorStyle.None;
    }

    set item(i:EconomicAccountLine){
        this.dateLabel.text = this.df.stringFromDate(i.date);
        this.conceptLabel.text = i.concept;        
        this.quantityLabel.text = this.nf.stringFromNumber(i.value);
        this.paymethodLabel.text = i.payMethodName;
    }

}