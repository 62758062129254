

// Generated class License_ManagedObject

class License_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: app
    set app(value:string) {
        this.setValueForKey(value, 'app');
    }
    get app():string {
        return this.valueForKey('app');
    }
    set appPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'app');
    }
    get appPrimitiveValue():string {
        return this.primitiveValueForKey('app');
    }

    // Property: device
    set device(value:string) {
        this.setValueForKey(value, 'device');
    }
    get device():string {
        return this.valueForKey('device');
    }
    set devicePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'device');
    }
    get devicePrimitiveValue():string {
        return this.primitiveValueForKey('device');
    }

    // Property: place
    set place(value:string) {
        this.setValueForKey(value, 'place');
    }
    get place():string {
        return this.valueForKey('place');
    }
    set placePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'place');
    }
    get placePrimitiveValue():string {
        return this.primitiveValueForKey('place');
    }

    // Property: previousLicense
    set previousLicense(value:string) {
        this.setValueForKey(value, 'previousLicense');
    }
    get previousLicense():string {
        return this.valueForKey('previousLicense');
    }
    set previousLicensePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'previousLicense');
    }
    get previousLicensePrimitiveValue():string {
        return this.primitiveValueForKey('previousLicense');
    }

    // Property: apptype
    set apptype(value:number) {
        this.setValueForKey(value, 'apptype');
    }
    get apptype():number {
        return this.valueForKey('apptype');
    }
    set apptypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'apptype');
    }
    get apptypePrimitiveValue():number {
        return this.primitiveValueForKey('apptype');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: beginDate
    set beginDate(value:Date) {
        this.setValueForKey(value, 'beginDate');
    }
    get beginDate():Date {
        return this.valueForKey('beginDate');
    }
    set beginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'beginDate');
    }
    get beginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('beginDate');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }

    // Property: nextCheckDate
    set nextCheckDate(value:Date) {
        this.setValueForKey(value, 'nextCheckDate');
    }
    get nextCheckDate():Date {
        return this.valueForKey('nextCheckDate');
    }
    set nextCheckDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'nextCheckDate');
    }
    get nextCheckDatePrimitiveValue():Date {
        return this.primitiveValueForKey('nextCheckDate');
    }

    // Property: isActive
    set isActive(value:boolean) {
        this.setValueForKey(value, 'isActive');
    }
    get isActive():boolean {
        return this.valueForKey('isActive');
    }
    set isActivePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isActive');
    }
    get isActivePrimitiveValue():boolean {
        return this.primitiveValueForKey('isActive');
    }

    // Property: isCanceled
    set isCanceled(value:boolean) {
        this.setValueForKey(value, 'isCanceled');
    }
    get isCanceled():boolean {
        return this.valueForKey('isCanceled');
    }
    set isCanceledPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isCanceled');
    }
    get isCanceledPrimitiveValue():boolean {
        return this.primitiveValueForKey('isCanceled');
    }

    // Property: info
    set info(value:string) {
        this.setValueForKey(value, 'info');
    }
    get info():string {
        return this.valueForKey('info');
    }
    set infoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'info');
    }
    get infoPrimitiveValue():string {
        return this.primitiveValueForKey('info');
    }

    // Property: providerName
    set providerName(value:string) {
        this.setValueForKey(value, 'providerName');
    }
    get providerName():string {
        return this.valueForKey('providerName');
    }
    set providerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'providerName');
    }
    get providerNamePrimitiveValue():string {
        return this.primitiveValueForKey('providerName');
    }

    // Property: nextLicense
    set nextLicense(value:string) {
        this.setValueForKey(value, 'nextLicense');
    }
    get nextLicense():string {
        return this.valueForKey('nextLicense');
    }
    set nextLicensePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nextLicense');
    }
    get nextLicensePrimitiveValue():string {
        return this.primitiveValueForKey('nextLicense');
    }

    // Property: appname
    set appname(value:string) {
        this.setValueForKey(value, 'appname');
    }
    get appname():string {
        return this.valueForKey('appname');
    }
    set appnamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appname');
    }
    get appnamePrimitiveValue():string {
        return this.primitiveValueForKey('appname');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
