
class CardCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private typeComboBox:MUIComboBox = null;
    private codeTextField:MUITextField = null;
    private isWorkerLabel:MUILabel = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.typeComboBox = MUIOutlet(this, "type-cb", "MUIComboBox");
        this.codeTextField = MUIOutlet(this, "code-tf", "MUITextField");
        this.codeTextField.setOnChangeText(this, function(control, value){
            this.card.barcode = value;
        });
        this.isWorkerLabel = MUIOutlet(this, "worker-lbl", "MUILabel");

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private card:Card = null;
    set item(value:Card){
        this.card = value;
        this.nameLabel.text = value.name;
        if (value.name == null && value.legalEntity != null) this.nameLabel.text = value.legalEntity.name;
        this.typeComboBox.selectItem(value.cardType);
        this.codeTextField.text = value.barcode;
        if (value.legalEntity != null) this.isWorkerLabel.text = MIOLocalizeString("YES", "Yes");
        else this.isWorkerLabel.text = null;
    }
}