

// Generated class LocationCategory_ManagedObject

class LocationCategory_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: hidden
    set hidden(value:boolean) {
        this.setValueForKey(value, 'hidden');
    }
    get hidden():boolean {
        return this.valueForKey('hidden');
    }
    set hiddenPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'hidden');
    }
    get hiddenPrimitiveValue():boolean {
        return this.primitiveValueForKey('hidden');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: backgroundType
    set backgroundType(value:number) {
        this.setValueForKey(value, 'backgroundType');
    }
    get backgroundType():number {
        return this.valueForKey('backgroundType');
    }
    set backgroundTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'backgroundType');
    }
    get backgroundTypePrimitiveValue():number {
        return this.primitiveValueForKey('backgroundType');
    }

    // Property: consumptionProfile
    set consumptionProfile(value:string) {
        this.setValueForKey(value, 'consumptionProfile');
    }
    get consumptionProfile():string {
        return this.valueForKey('consumptionProfile');
    }
    set consumptionProfilePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'consumptionProfile');
    }
    get consumptionProfilePrimitiveValue():string {
        return this.primitiveValueForKey('consumptionProfile');
    }
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
