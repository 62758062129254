

/// <reference path="LegalEntity.ts" />

// Generated class Supplier_ManagedObject

class Supplier_ManagedObject extends LegalEntity
{

    // Property: onlySupplierProducts
    set onlySupplierProducts(value:boolean) {
        this.setValueForKey(value, 'onlySupplierProducts');
    }
    get onlySupplierProducts():boolean {
        return this.valueForKey('onlySupplierProducts');
    }
    set onlySupplierProductsPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'onlySupplierProducts');
    }
    get onlySupplierProductsPrimitiveValue():boolean {
        return this.primitiveValueForKey('onlySupplierProducts');
    }

    // Relationship: supplierProducts
    protected _supplierProducts:MIOManagedObjectSet = null;
    get supplierProducts():MIOManagedObjectSet {
        return this.valueForKey('supplierProducts');
    }
    addSupplierProductsObject(value:SupplierProduct) {
        this._addObjectForKey(value, 'supplierProducts');
    }
    removeSupplierProductsObject(value:SupplierProduct) {
        this._removeObjectForKey(value, 'supplierProducts');
    }
}
