class ProductWarehouseStockCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private minTextField:MUITextField = null;
    private maxTextField:MUITextField = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter as MIONumberFormatter;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.minTextField = MUIOutlet(this, "warehouse-min-tf", "MUITextField");
        this.minTextField.setOnChangeText(this, function(control, value){
            let q = this.nf.numberFromString(value);
            this.productWarehouse.minQuantity = q;
        });
        this.maxTextField = MUIOutlet(this, "warehouse-max-tf", "MUITextField");        
        this.maxTextField.setOnChangeText(this, function(control, value){            
            let q = this.nf.numberFromString(value);
            this.productWarehouse.maxQuantity = q;
        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private productWarehouse:ProductWarehouse = null;
    set item(productWarehouse:ProductWarehouse){
        this.productWarehouse = productWarehouse;        

        if (productWarehouse.warehouse == null) this.nameLabel.text = "DELETED!";
        else this.nameLabel.text = productWarehouse.warehouse.name;
        this.minTextField.text = productWarehouse.minQuantity;
        this.maxTextField.text = productWarehouse.maxQuantity;
    }    
}