

// Generated class StockNote_ManagedObject

class StockNote_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: placeID
    set placeID(value:string) {
        this.setValueForKey(value, 'placeID');
    }
    get placeID():string {
        return this.valueForKey('placeID');
    }
    set placeIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'placeID');
    }
    get placeIDPrimitiveValue():string {
        return this.primitiveValueForKey('placeID');
    }

    // Property: appID
    set appID(value:string) {
        this.setValueForKey(value, 'appID');
    }
    get appID():string {
        return this.valueForKey('appID');
    }
    set appIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appID');
    }
    get appIDPrimitiveValue():string {
        return this.primitiveValueForKey('appID');
    }

    // Property: documentID
    set documentID(value:string) {
        this.setValueForKey(value, 'documentID');
    }
    get documentID():string {
        return this.valueForKey('documentID');
    }
    set documentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentID');
    }
    get documentIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentID');
    }

    // Property: externalReference
    set externalReference(value:string) {
        this.setValueForKey(value, 'externalReference');
    }
    get externalReference():string {
        return this.valueForKey('externalReference');
    }
    set externalReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalReference');
    }
    get externalReferencePrimitiveValue():string {
        return this.primitiveValueForKey('externalReference');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: creationDate
    set creationDate(value:Date) {
        this.setValueForKey(value, 'creationDate');
    }
    get creationDate():Date {
        return this.valueForKey('creationDate');
    }
    set creationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'creationDate');
    }
    get creationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('creationDate');
    }

    // Property: documentDate
    set documentDate(value:Date) {
        this.setValueForKey(value, 'documentDate');
    }
    get documentDate():Date {
        return this.valueForKey('documentDate');
    }
    set documentDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'documentDate');
    }
    get documentDatePrimitiveValue():Date {
        return this.primitiveValueForKey('documentDate');
    }

    // Property: stockDate
    set stockDate(value:Date) {
        this.setValueForKey(value, 'stockDate');
    }
    get stockDate():Date {
        return this.valueForKey('stockDate');
    }
    set stockDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'stockDate');
    }
    get stockDatePrimitiveValue():Date {
        return this.primitiveValueForKey('stockDate');
    }

    // Property: validationDate
    set validationDate(value:Date) {
        this.setValueForKey(value, 'validationDate');
    }
    get validationDate():Date {
        return this.valueForKey('validationDate');
    }
    set validationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'validationDate');
    }
    get validationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('validationDate');
    }

    // Property: validationWorkerID
    set validationWorkerID(value:string) {
        this.setValueForKey(value, 'validationWorkerID');
    }
    get validationWorkerID():string {
        return this.valueForKey('validationWorkerID');
    }
    set validationWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'validationWorkerID');
    }
    get validationWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('validationWorkerID');
    }

    // Property: validationWorkerName
    set validationWorkerName(value:string) {
        this.setValueForKey(value, 'validationWorkerName');
    }
    get validationWorkerName():string {
        return this.valueForKey('validationWorkerName');
    }
    set validationWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'validationWorkerName');
    }
    get validationWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('validationWorkerName');
    }

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }

    // Property: originName
    set originName(value:string) {
        this.setValueForKey(value, 'originName');
    }
    get originName():string {
        return this.valueForKey('originName');
    }
    set originNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'originName');
    }
    get originNamePrimitiveValue():string {
        return this.primitiveValueForKey('originName');
    }
    // Relationship: originWarehouse
    set originWarehouse(value:Warehouse) {
        this.setValueForKey(value, 'originWarehouse');
    }
    get originWarehouse():Warehouse {
        return this.valueForKey('originWarehouse') as Warehouse;
    }
    // Relationship: originConcept
    set originConcept(value:StockCustomConcept) {
        this.setValueForKey(value, 'originConcept');
    }
    get originConcept():StockCustomConcept {
        return this.valueForKey('originConcept') as StockCustomConcept;
    }
    // Relationship: originEntity
    set originEntity(value:Supplier) {
        this.setValueForKey(value, 'originEntity');
    }
    get originEntity():Supplier {
        return this.valueForKey('originEntity') as Supplier;
    }

    // Property: destinationName
    set destinationName(value:string) {
        this.setValueForKey(value, 'destinationName');
    }
    get destinationName():string {
        return this.valueForKey('destinationName');
    }
    set destinationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'destinationName');
    }
    get destinationNamePrimitiveValue():string {
        return this.primitiveValueForKey('destinationName');
    }
    // Relationship: destinationWarehouse
    set destinationWarehouse(value:Warehouse) {
        this.setValueForKey(value, 'destinationWarehouse');
    }
    get destinationWarehouse():Warehouse {
        return this.valueForKey('destinationWarehouse') as Warehouse;
    }
    // Relationship: destinationConcept
    set destinationConcept(value:StockCustomConcept) {
        this.setValueForKey(value, 'destinationConcept');
    }
    get destinationConcept():StockCustomConcept {
        return this.valueForKey('destinationConcept') as StockCustomConcept;
    }
    // Relationship: destinationEntity
    set destinationEntity(value:Supplier) {
        this.setValueForKey(value, 'destinationEntity');
    }
    get destinationEntity():Supplier {
        return this.valueForKey('destinationEntity') as Supplier;
    }

    // Property: isPartial
    set isPartial(value:boolean) {
        this.setValueForKey(value, 'isPartial');
    }
    get isPartial():boolean {
        return this.valueForKey('isPartial');
    }
    set isPartialPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isPartial');
    }
    get isPartialPrimitiveValue():boolean {
        return this.primitiveValueForKey('isPartial');
    }

    // Relationship: lines
    protected _lines:MIOManagedObjectSet = null;
    get lines():MIOManagedObjectSet {
        return this.valueForKey('lines');
    }
    addLinesObject(value:StockNoteLine) {
        this._addObjectForKey(value, 'lines');
    }
    removeLinesObject(value:StockNoteLine) {
        this._removeObjectForKey(value, 'lines');
    }
    // Relationship: internalOrder
    set internalOrder(value:StockNote) {
        this.setValueForKey(value, 'internalOrder');
    }
    get internalOrder():StockNote {
        return this.valueForKey('internalOrder') as StockNote;
    }
}
