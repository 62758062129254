

class ProductListViewController extends BaseTableViewController
{
    parentCategoryID:string = null;
    parentCategoryName:string = null;
    categoryItem:ProductListItem = null;
    category:Category = null;
    stockCategory:StockCategory = null;
    productType = ProductType.Sales;    

    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private segmentedControl:MUISegmentedControl = null;
    private massiveButton:MUIButton = null;
    private addButton:MUIButton = null;    
    
    private pdvc:ProductDetailViewController = null;        
    private acmvc:AddNewCategoryViewController = null;    
    private plvc:ProductListViewController = null;    
    
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;

    viewDidLoad(){
        super.viewDidLoad();
        
        this.backButton = MUIOutlet(this, 'back-btn', 'MUIButton');
        this.backButton.setAction(this, function (){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');
        this.titleLabel.hidden = true;

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.hidden = true;
        this.segmentedControl.setAction(this, function(control, index){
            MIOUserDefaults.standardUserDefaults().removeValueForKey("LastProductDetailSalesPageIndex");
            this.productType = index + 1;
            this.invalidateData();
        });

        this.massiveButton = MUIOutlet(this, "massive-btn", "MUIButton");
        this.massiveButton.setAction(this, this.massiveOperationAction);

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function (){
            this.showOptionsAlertView();
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;          

        MIONotificationCenter.defaultCenter().addObserver(this, "WorkspaceDidChange", function (notification) {
            this.navigationController.popToRootViewController(false);
        });

        MIONotificationCenter.defaultCenter().addObserver(this, 'ProductDidDeleted', function (notification:MIONotification) {            
            let item = notification.object as ProductListItem;
            if (this.selectedItem == item.itemID) {                
                DBHelper.deleteObjectFromMainContext(item, true);
                this.selectedItem = null;
                this.selectedProductItem = null;                
                this.showNoItemSelected();
                this.invalidateData();
            }
        });
        
        MIONotificationCenter.defaultCenter().addObserver(this, 'ItemDidChange', function (notification) {            
            this.invalidateData();            
        });    
        
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        
        this.sectionsButton.setHidden(this.parentCategoryID ? true : false);
        this.backButton.setHidden(this.parentCategoryID ? false : true);
        this.segmentedControl.setHidden(this.parentCategoryID == null ? false : true);
        this.titleLabel.setHidden(this.parentCategoryID == null ? true : false);

        this.titleLabel.text = this.parentCategoryName;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("ProductCell") as ProductCell;

        cell.productType = this.productType;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);                
        cell.item = item;                   

        if (item.type == ProductListItemType.Category)
            cell.setAccessoryType(MUITableViewCellAccessoryType.DisclosureIndicator);
        else 
            cell.setAccessoryType(MUITableViewCellAccessoryType.None);        

        if (this.selectedItem == item.itemID){
            this.selectedProductItem = item;
            cell.selected = true;
        }
        else {
            cell.selected = false;
        }
        
        return cell;
    }
        
    private selectedProductItem:ProductListItem = null;
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        this.selectedProductItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductListItem;
        this.selectedItem = this.selectedProductItem.itemID;
        this.showItemDetail(this.selectedProductItem);

        if(this.selectedProductItem.type == ProductListItemType.Category) tableView.deselectRowAtIndexPath(indexPath, true);
    }

    private showItemDetail(item:ProductListItem){
        if (item == null) return;

        if(item.type == ProductListItemType.Product){
            let vc = new ProductDetailViewController("products-detail-view");
            vc.initWithResource("layout/products/ProductDetailView.html");
            vc.productType = this.productType;
           // pdvc.productID = item.itemID;
           // pdvc.productID = item.product.identifier;
            vc.item = item;            
            
            this.navigationController.splitViewController.showDetailViewController(vc);
        }
        else if(item.type == ProductListItemType.Category || item.type == ProductListItemType.StockCategory){
            let vc = new ProductListViewController("products-list-view");
            vc.initWithResource("layout/products/ProductsListView.html");                        
            vc.parentCategoryID = item.itemID;
            vc.parentCategoryName = item.name;
            vc.category = item.category;
            vc.stockCategory = item.stockCategory;
            vc.productType = this.productType;
                        
            this.navigationController.pushViewController(vc, true);
        }
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Product", NoItemSelectedViewControllerType.Product);
        this.navigationController.splitViewController.showDetailViewController(vc);
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("ProductListItem");
        fetchRequest.relationshipKeyPathsForPrefetching = ['category', 'stockCategory'];
        //fetchRequest.relationshipKeyPathsForPrefetching = ['category'];

        let predicate = [];        

        if (this.searchString == null){            
            if (this.productType == ProductType.Sales){
                predicate.push("parentCategoryID == " + (this.parentCategoryID ? this.parentCategoryID : "null"));
                //predicate.push("isEnableForSales == true");
                if (this.parentCategoryID == null) predicate.push("type == " + ProductListItemType.Category);
            }                
            else if (this.productType == ProductType.Stock){                
                predicate.push("parentStockCategoryID == " + (this.parentCategoryID ? this.parentCategoryID : "null"));
                //predicate.push("isEnableForStock == true");
                if (this.parentCategoryID == null) predicate.push("type == " + ProductListItemType.StockCategory);                
            }
            else {
                predicate.push("parentCategoryID == null");
                predicate.push("parentStockCategoryID == null");
                predicate.push("type == " + ProductListItemType.Product);
            }            
            fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true),
            MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)];
        }
        else {
            predicate.push("(name CONTAINS \"" + this.searchString + "\" OR stockName CONTAINS \"" + this.searchString + "\")");
            fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("type", true),
                                            MIOSortDescriptor.sortDescriptorWithKey("name", true),
                                            MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)];
        }
        
        if(predicate.length > 0)
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicate.join(' and '));

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }

    private showDetail = false;
    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        super.controllerDidChangeContent(controller);
        if (this.showDetail == true) this.showItemDetail(this.selectedProductItem);
        this.showDetail = false;
    }
    
    private showOptionsAlertView(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('CHOOSE THE TYPE',"Choose the type"), MIOLocalizeString('SELECT THE TYPE TO CREATE A CATEGORY OR PRODUCT',"Select the type to create a Category or Product "), MUIAlertViewStyle.Default);

		let title = this.productType == ProductType.Stock ? MIOLocalizeString('STOCK CATEGORY',"STOCK CATEGORY") : MIOLocalizeString('CATEGORY',"CATEGORY");
        avc.addAction(MUIAlertAction.alertActionWithTitle(title, MUIAlertActionStyle.Default, this, function(){                        
            this.showAddCategory();
        }));

        if(this.parentCategoryID != null) {            
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('PRODUCT',"Product"), MUIAlertActionStyle.Default, this, function(){
                this.showAddProductAlert();
            }));
        }

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"Cancel"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private showAddCategory(){

        if (this.productType == ProductType.Sales) {
            if(!this.acmvc) {
                this.acmvc = new AddNewCategoryViewController('add-new-category-view');
                this.acmvc.parentCategory = this.category;
                this.acmvc.initWithResource("layout/products/AddNewCategoryView.html")
            }
            if(!this.acmvc.isPresented)
                this.presentViewController(this.acmvc, true);      
        }

        else {

            let avc = new MUIAlertViewController();
            avc.initWithTitle(MIOLocalizeString('ADD CATEGORY NAME',"ADD CATEGORY NAME"), MIOLocalizeString('CREATE A STOCK CATEGORY',"CREATE A STOCK CATEGORY"), MUIAlertViewStyle.Default);    

            avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
                textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
            });

            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK',"OK"), MUIAlertActionStyle.Default, this, function(){                        
                let name = avc.textFields[0].text;
                DBHelper.createStockCategory(name, this.stockCategory);
                DBHelper.saveMainContext();
            }));

            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, null, null));
            this.presentViewController(avc, true);    
        }
    }

    private showAddProductAlert(){

        if (this.productType == ProductType.Sales) {
            AppHelper.sharedInstance().showAddSalesProductAlertViewController(null, this.category, this, function(product:Product){
                
                if (product == null) return;                

                DBHelper.saveMainContextWithCompletion(this, function(){
                    this.selectedProductItem = null;
                    this.selectedItem = product.identifier;
                    this.showDetail = true;
                    this.invalidateFetch();
                    //this.reloadData();        
                });
            });
        }
        else if (this.productType == ProductType.Stock){
            AppHelper.sharedInstance().showAddStockProductAlertViewController(null, this.stockCategory, this, function(product:Product){
                
                if (product == null) return;                
                
                DBHelper.saveMainContextWithCompletion(this, function(){
                    this.selectedProductItem = null;
                    this.selectedItem = product.identifier;
                    this.showDetail = true;
                    this.invalidateFetch();
                    //this.reloadData();
                });
            });
        }
    }

    // DEPRECATED
    // TODO: Move the check name product to product alert above. 

    private addProduct(name:string, price:string, sourceID:string){        
        if (name.length == 0) {
            AppHelper.showErrorMessage(this, "Product Error", "You need to at least fill up the name field");
            return;
        }

        let p = this.cf.numberFromString(price);        
        if (p == null) p = 0;

        let source = null;
        if (sourceID != "-1") {
            source = DBHelper.queryObjectFromMainContext("ProductSource", MIOPredicate.predicateWithFormat("identifier == " + sourceID), []);
        }

        DBHelper.checkProductName(name, this, function(result:boolean){
            if (result == true) {
                this.createProduct(name, price, source);
            }
            else {
                AppHelper.showErrorMessage(this, MIOLocalizeString("PRODUCT ERROR ALERT", "PRODUCT ERROR ALERT"), "Can not create the product. Please check doesn't exist yet");
            }
        });
        
    }

    private createProduct(name:string, price, source){
        let product = DBHelper.createProduct(name, price, this.category, source, MeasureUnitType.Unit, null, null) as Product;
        //let productItem = DBHelper.createProductItemFromProduct(product) as ProductListItem;
        //productItem.parentCategoryID = this.parentCategoryID;                
                
        DBHelper.saveMainContextWithCompletion(this, function(){
            this.selectedProductItem = null;
            this.selectedItem = product.identifier;
            this.showDetail = true;
            this.invalidateFetch();
        });                                        
    }

    private massiveOperationAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('MASSIVE OPERATION', "MASSIVE OPERATION"), MIOLocalizeString('SELECT THE OPERATION',"SELECT THE OPERATION"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("COST TYPE", "COST TYPE"), MUIAlertActionStyle.Default, this, function(){
            this.changeCostType();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }


    private changeCostType(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('MASSIVE OPERATION', "MASSIVE OPERATION"), MIOLocalizeString('SELECT COST TYPE TO APPLY', "SELECT COST TYPE TO APPLY"), MUIAlertViewStyle.Default);

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            comboBox.removeAllItems();
            comboBox.addItem(MIOLocalizeString("LAST PRICE", "LAST PRICE"), 0);
            comboBox.addItem(MIOLocalizeString("AVERAGE", "AVERAGE"), 1);
            comboBox.addItem(MIOLocalizeString("FIXED COST", "FIXED COST"), 2);
            comboBox.addItem(MIOLocalizeString("COMPONENTS", "COMPONENTS"), 3);
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(){
            let type = avc.comboBoxes[0].getSelectedItem();
            this.applyCostType(parseInt(type));
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private applyCostType(costType:number){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        ad.webService.changeCostPriceMassively(costType, null, this, function(code, data){
            if (code == 200) {
                AppHelper.showInfoMessage(this, "Operation Info", "Operation was succesfull");
                MIONotificationCenter.defaultCenter().postNotification("ProductReloadNotification", null);
            }
            else AppHelper.showErrorMessage(this, "Operation Error", "The operation couldn't execute. Try again later.");
        })
    }
}

