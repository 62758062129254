


class MenusListViewController extends EntityListViewController
{
    parentCategoryID = null;
    parentCategoryName = null;
    category:Category = null;    
    
    private addButton:MUIButton = null;
    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;      
        
    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, 'back-btn', 'MUIButton');
        this.backButton.setAction(this, function (){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');
        
        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function (){
            //add Menu
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"table-view","UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        

        MIONotificationCenter.defaultCenter().addObserver(this, "workspaceDidChange", function (notification) {
            this.navigationController.popToRootViewController(false);
        });                
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.backButton.setHidden(true);
        // this.sectionsButton.setHidden(this.parentCategoryID ? true : false);
        // this.titleLabel.setHidden(this.parentCategoryID == null ? true : false);
        // if(this.parentCategoryName) this.titleLabel.text = this.parentCategoryName;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        
        let cell:MenuListCell = tableview.dequeueReusableCellWithIdentifier("MenuListCell") as MenuListCell;
        let item:ProductModifierCategory = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductModifierCategory;
        cell.item = item;            

        //for cell selectiond
        if(this.selectedItem != null && this.selectedItem.identifier == item.identifier) {
            cell.setSelected(true);
        }
        
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showItemDetail(selectedItem);
    }

    private showItemDetail(item:Menu){     
        if (item == null) return;
        
        let vc = new MenusDetailViewController("menus-detail-view");
        vc.initWithResource("layout/menus/MenusDetailView.html");
        vc.item = item;    
            
        this.navigationController.splitViewController.showDetailViewController(vc);
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Product modifiers", NoItemSelectedViewControllerType.ProductModifiers);
        this.navigationController.splitViewController.showDetailViewController(vc);
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest:MIOFetchRequest = null;
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true), MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)]; 

        fetchRequest = DBHelper.listFetchRequestWithEntityName("Menu", sortDescriptors, null);
        fetchRequest.relationshipKeyPathsForPrefetching = ['tax'];
                
        // if (this.searchString != null) predicate.push('name CONTAINS "'+ this.searchString + '"');

        // if (this.searchString == null){            
        //     if (this.productType == ProductType.Sales){
        //         predicate.push("parentCategoryID == " + (this.parentCategoryID ? this.parentCategoryID : "null"));
        //         predicate.push("(type == " + ProductListItemType.Category + " OR type == " + ProductListItemType.Product + ")");
        //     }                
        //     else if (this.productType == ProductType.Stock){                
        //         predicate.push("parentStockCategoryID == " + (this.parentCategoryID ? this.parentCategoryID : "null"));                                
        //         if (this.parentCategoryID == null) {
        //             predicate.push("type == " + ProductListItemType.StockCategory);
        //         }
        //         else {                    
        //             predicate.push("type != " + ProductListItemType.Category);
        //         }                                                     
        //     }
        //     else {
        //         predicate.push("parentCategoryID == null");
        //         predicate.push("parentStockCategoryID == null");
        //         predicate.push("type == " + ProductListItemType.Product);
        //     }            
        // }
        
        //if(predicate.length > 0) filterString = predicate.join(' and ');

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }

    // private showAddProductModifierCategory(){

    //     let avc = new MUIAlertViewController();
    //     avc.initWithTitle(MIOLocalizeString('ADD PRODUCT MODIFIER CATEGORY',"ADD PRODUCT MODIFIER CATEGORY"), MIOLocalizeString('CREATE A PRODUCT MODIFIER CATAGORY',"CREATE A PRODUCT MODIFIER CATAGORY"), MUIAlertViewStyle.Default);    

    //     avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
    //         textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
    //     });

    //     avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK',"OK"), MUIAlertActionStyle.Default, this, function(){                        
    //         let name = avc.textFields[0].text;
    //         this.createProductModifierCategory(name);
    //         DBHelper.saveMainContext();
    //     }));

    //     avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, null, null));
    //     this.presentViewController(avc, true);    
    // }
      
    // private createProductModifierCategory(name:string):ProductModifierCategory{
    //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

    //     let productModifierCategory = MIOEntityDescription.insertNewObjectForEntityForName("ProductModifierCategory", ad.managedObjectContext) as ProductModifierCategory;
    //     productModifierCategory.identifier = MIOUUID.UUID().UUIDString;
    //     productModifierCategory.name = name;
    //     productModifierCategory.productModifierCategoryType = ProductModifierCategoryType.Manual;

    //     return productModifierCategory;
    // }
}

