//
// Generated class Budget
//

/// <reference path="Budget_ManagedObject.ts" />

class Budget extends Budget_ManagedObject
{

    get statusString()
    {
        return this.isAccepted ? 'Aceptado':'Pendiente';
    }
    get isAccepted()
    {
        return this.status == 1;
    }
}
