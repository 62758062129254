//
// Generated class UserBusinessPermission
//

/// <reference path="UserBusinessPermission_ManagedObject.ts" />

class UserBusinessPermission extends UserBusinessPermission_ManagedObject
{
    private  permissionsCount = 67

    hasManagerPermision(bitOffset){                
        let value = this.permissions[bitOffset];
        if (value == "1") {
            return true;
        }

        return false;
    }

    addManagerPermission(bitOffset){
        this.checkLength();
        this.permissions = this.setCharAt(this.permissions, bitOffset, "1");
    }

    removeManagerPermission(bitOffset){
        this.checkLength();
        this.permissions = this.setCharAt(this.permissions, bitOffset, "0");
    }

    setAllPermissions(){
        this.permissions = "";
        for (let index = 0; index < this.permissionsCount; index++){
            this.permissions += "1";
        }
    }

    unsetAllPermissions(){
        this.permissions = "";
        for (let index = 0; index < this.permissionsCount; index++){
            this.permissions += "0";
        }
    }
    
    private checkLength(){
        if (this.permissions.length == this.permissionsCount) return;

        for (let index = this.permissions.length; index < this.permissionsCount; index++){
            this.permissions += "0";
        }
    }

    private setCharAt(str, index, chr) {        
        return (index > 0 ? str.substr(0, index): "") + chr + (index < (str.length - 1) ? str.substr(index + 1) : "");
    }
}
