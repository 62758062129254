

// Generated class MenuCategory_ManagedObject

class MenuCategory_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: image
    set image(value:string) {
        this.setValueForKey(value, 'image');
    }
    get image():string {
        return this.valueForKey('image');
    }
    set imagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'image');
    }
    get imagePrimitiveValue():string {
        return this.primitiveValueForKey('image');
    }

    // Property: isLimited
    set isLimited(value:boolean) {
        this.setValueForKey(value, 'isLimited');
    }
    get isLimited():boolean {
        return this.valueForKey('isLimited');
    }
    set isLimitedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isLimited');
    }
    get isLimitedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isLimited');
    }

    // Property: limitQuantity
    set limitQuantity(value:number) {
        this.setValueForKey(value, 'limitQuantity');
    }
    get limitQuantity():number {
        return this.valueForKey('limitQuantity');
    }
    set limitQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'limitQuantity');
    }
    get limitQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('limitQuantity');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: PrintInTicket
    set PrintInTicket(value:boolean) {
        this.setValueForKey(value, 'PrintInTicket');
    }
    get PrintInTicket():boolean {
        return this.valueForKey('PrintInTicket');
    }
    set PrintInTicketPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'PrintInTicket');
    }
    get PrintInTicketPrimitiveValue():boolean {
        return this.primitiveValueForKey('PrintInTicket');
    }

    // Property: serverID
    set serverID(value:number) {
        this.setValueForKey(value, 'serverID');
    }
    get serverID():number {
        return this.valueForKey('serverID');
    }
    set serverIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'serverID');
    }
    get serverIDPrimitiveValue():number {
        return this.primitiveValueForKey('serverID');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: menu
    set menu(value:Menu) {
        this.setValueForKey(value, 'menu');
    }
    get menu():Menu {
        return this.valueForKey('menu') as Menu;
    }
    // Relationship: productMenu
    set productMenu(value:ProductMenu) {
        this.setValueForKey(value, 'productMenu');
    }
    get productMenu():ProductMenu {
        return this.valueForKey('productMenu') as ProductMenu;
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
}
