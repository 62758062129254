/**
 * Created by godshadow on 04/08/16.
 */



class ChannelViewController extends MUIViewController
{
    private tableView:MUITableView = null;
    private searchTextField:MUITextField = null;
    private addButton:MUIButton = null;
    private _fetchedResultsController:MIOFetchedResultsController = null;
    private searchString:string = null;        

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'cv_add_button','MUIButton');
        this.addButton.setAction(this, function(){
           this.addChannel();
        });

        this.tableView = MUIOutlet(this,"cv_tableview", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        
        this.tableView.reloadData();
    }
    
    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.tableView.reloadData();
    }
    
    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell:ChannelCell = tableview.dequeueReusableCellWithIdentifier('ChannelCell');
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;

        let item:BookingChannel = this.fetchedResultsController.objectAtIndexPath(indexPath);    
        cell.item = item;    
        
        cell.editing = true;
        
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView, indexPath){
        let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {

        let channel = this.fetchedResultsController.objectAtIndexPath(indexPath);    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeChannel(channel);
        }
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;
    
        var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('BookingChannel');
    
        fetchRequest.sortDescriptors = [ /*MIOSortDescriptor.sortDescriptorWithKey('categoryName', true),*/
                                            MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        if (this.searchString != null && this.searchString.length > 0)
            fetchRequest.predicate = MIOPredicate.predicateWithFormat('name CONTAINS "' + this.searchString + '"');
    
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private removeChannel(channel){
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws:WebService = ad.webService;    
        
        ad.managedObjectContext.deleteObject(channel);
        ad.managedObjectContext.save();                
    }

    private addChannel(){
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('BOOKING CHANNEL','Booking Channel'), MIOLocalizeString('CHOOSE A NAME','Choose a name'), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString('NAME',"Name"));
        });
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function()
        {
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();
            if(name.length > 0){
                let channel:BookingChannel = MIOEntityDescription.insertNewObjectForEntityForName("BookingChannel", moc) as BookingChannel;
                                
                channel.name =  name;
                channel.identifier = MIOUUID.UUID().UUIDString;
                this.channel = channel; 
                moc.save();
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('MUST WRITE A NAME CHANNEL','Must write a name for channel '), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
        
        this.presentViewController(avc, true);
    }
}
