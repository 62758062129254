

// Generated class PaymentEntity_ManagedObject

class PaymentEntity_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: paymentType
    set paymentType(value:number) {
        this.setValueForKey(value, 'paymentType');
    }
    get paymentType():number {
        return this.valueForKey('paymentType');
    }
    set paymentTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'paymentType');
    }
    get paymentTypePrimitiveValue():number {
        return this.primitiveValueForKey('paymentType');
    }

    // Property: config
    set config(value:string) {
        this.setValueForKey(value, 'config');
    }
    get config():string {
        return this.valueForKey('config');
    }
    set configPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'config');
    }
    get configPrimitiveValue():string {
        return this.primitiveValueForKey('config');
    }

    // Property: scope
    set scope(value:number) {
        this.setValueForKey(value, 'scope');
    }
    get scope():number {
        return this.valueForKey('scope');
    }
    set scopePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'scope');
    }
    get scopePrimitiveValue():number {
        return this.primitiveValueForKey('scope');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
