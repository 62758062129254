


/// <reference path="ReportDateFilterCell.ts" />
/// <reference path="ReportSelectFilterCell.ts" />

class ReportFilterViewController extends MUIViewController
{    
    report = null;

    private filters = null;
    private query = null;

    private filterButton = null;
    private tableView = null;

    private selectedFilter = null;
    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private sdf = MUIWebApplication.sharedInstance().delegate.serverDateFormatter;
    private sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;

    viewDidLoad(){

        super.viewDidLoad();

        this.filterButton = MUIOutlet(this, "rfv_nb_filter_btn", "MUIButton");        
        this.filterButton.setEnabled(false);
        this.filterButton.setAction(this, function(){

            var vc = new ReportResultsViewController("report_results_view");
            vc.initWithResource("layout/reports/ReportResultsView.html");                  
            this.navigationController.pushViewController(vc, true);
        });

        this.tableView = MUIOutlet(this, "rfv_tableview", "MUITableView");

        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animate?){

        super.viewWillAppear(animate);

        var filters = this.report ? this.report["allowedFilters"] : {};

        this.filters = [];
        for (let key in filters) {
            
            var item = filters[key];
            item["title"] = key;
            this.filters.push(item);
        }

        this.tableView.reloadData();        
    }

    numberOfSections(tableview)
    {
        return 1;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        return this.filters.length;
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var filter = this.filters[indexPath.row];
        var cell = null;

        if (filter["type"] == "date") {
            cell = tableview.dequeueReusableCellWithIdentifier('DateFilterCell');
            cell.delegate = this;
            cell.filter = filter;
        }
        else if (filter["type"] == "time") {
            cell = tableview.dequeueReusableCellWithIdentifier('TimeFilterCell');            
            cell.title = filter["title"] + ":";
            cell.delegate = this;
            cell.filter = filter;
        }
        else if (filter["type"] == "datetime") {
            cell = tableview.dequeueReusableCellWithIdentifier('DateTimeFilterCell');
            cell.title = filter["title"] + ":";
            cell.delegate = this;
            cell.filter = filter;
        }        
        else if (filter["type"] == "[string]") {                        
             cell = tableview.dequeueReusableCellWithIdentifier('SelectFilterCell');        
             cell.delegate = this;
             cell.filter = filter;
        }
        else if (filter["type"] == "string") {                        
             cell = tableview.dequeueReusableCellWithIdentifier('TextFilterCell');        
             cell.delegate = this;
             cell.filter = filter;
        }
        else if (filter["type"] == "boolean") {
             cell = tableview.dequeueReusableCellWithIdentifier('SwitchFilterCell');        
             cell.delegate = this;
             cell.filter = filter;
        }
        else { 
            cell = new MUITableViewCell();
            cell.initWithStyle(MUITableViewCellStyle.Default);
            cell.textLabel.text = MIOLocalizeString("UNSUPPORTED FILTER", "Unsupported filter");  
        }
    
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        cell.separatorStyle =  MUITableViewCellSeparatorStyle.None;

        return cell;
    }

    selectDateFromDateFilterCell(cell, control, filter){

        this.selectedFilter = filter;

        var dp = new MUIDatePickerController();
        dp.init();        
        dp.delegate = this;

        dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

        var pc = dp.popoverPresentationController;
        pc.sourceRect = control.frame;
        pc.sourceView = control;

        this.presentViewController(dp, true);
    }

    // Only happends with date filter cells
    didSelectDate(datePickerController, date)
    {
        if (this.selectedFilter == null) return;

        let index = this.filters.indexOf(this.selectedFilter);
        if (index < 0) return;

        var cell = this.tableView.cellAtIndexPath(MIOIndexPath.indexForRowInSection(index, 0));
        if (cell != null) {
            cell.date = date;
        }

        this.selectedFilter = null;
    }

    didChangeDateTimeValue(cell, date, filter) {
        
        this.sdtf.timeStyle = MIODateFormatterStyle.LongStyle;
        filter["value"] = this.sdtf.stringFromDate(date);

        this.checkQuery();        
    }

    didChangeTimeValue(cell, date, filter) {
        
        filter["value"] = MIODateGetTimeString(date)+':00';

        this.checkQuery();        
    }

    didSelectOptionFromSelectFilterCell(cell, value, filter){

        if (filter['type'] == "[string]")
            filter["value"] = [value];
        else 
            filter["value"] = value;
        this.checkQuery();
    }

    didChangeSwitchCellValue(cell, value, filter){

        filter["value"] = value;
        this.checkQuery();
    }

    private checkQuery(){

        var query = {}
        var filters = {};

        query["reportid"] = this.report["id"];
        query["filters"] = filters;

        for (var index = 0; index < this.filters.length; index++){

            var f = this.filters[index];
            
            let key = f["title"];
            let value = f["value"];
            if (f["required"] == true && value == null && f["type"] == "boolean") 
                value = false;
                        
            if (f["required"] == true && value == null) return;
            if (value == null) continue;        

            filters[key] = value;
        }

        this.query = query;
        this.filterButton.setEnabled(true);
    }

    // didSelectCellAtIndexPath(tableView, row, section)
    // {
        // var category = this.reports[section];
        // var reports = category["Reports"];

        // // var vc = new ReportResultsViewController("report_results_view");
        // // vc.initWithResource("layout/reports/ReportResultsView.html");      
        // // vc.report =  reports[row]; 
        // // this._report = reports[row];
        // // this.splitViewController.showDetailViewController(vc);

        // var vc = new ReportFilterViewController("report_filter_view");
        // vc.initWithResource("layout/reports/ReportFilterView.html");
        // vc.report =  reports[row]; 
        // this.splitViewController.showDetailViewController(vc);        
 //   }

}