class MenusDetailViewController extends BaseTableViewController {

    private nameTextField: MUITextField = null;
    private priceTextField: MUITextField = null;
    private imageView: MUIImageView = null;
    private taxDropDown = null;

    private cf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");

        this.priceTextField = MUIOutlet(this, "price-tf", "MUITextField");

        this.imageView = MUIOutlet(this, 'menu-img','MUIImageView');

        this.taxDropDown = MUIOutlet(this, 'tax-btn', 'MUIButton');
        this.taxDropDown.setAction(this, function() {
            this.showTaxSelectEntityViewController(this.taxDropDown, "Tax"); //view popup for tax
        });

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.updateUI();
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _menu:Menu;
    set item(item:Menu){        
        this._menu = item;
        this.updateUI();
    }   

    private updateUI(){
        if (this._menu == null) return;
        if (this.viewIsLoaded == false) return;    

        this.nameTextField.text = this._menu.name;
        this.taxDropDown.title = this._menu.tax.name;
        this.priceTextField.text = this._menu.price;

    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){  
        let cell:MenuDetailCell = tableview.dequeueReusableCellWithIdentifier("MenuDetailCell");
        let item:MenuCategory = this.fetchedResultsController.objectAtIndexPath(indexPath) as MenuCategory;
        cell.item = item;            
        
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest:MIOFetchRequest = null;
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true), MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)]; 

        fetchRequest = DBHelper.listFetchRequestWithEntityName("MenuCategory", sortDescriptors, null);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }


    private showTaxSelectEntityViewController(dropdownButton, entityName) {
        let predicateFormat = null;        
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(dropdownButton, entityName, "name", predicateFormat, this, function(controller, object:MIOManagedObject){
            let title = object.valueForKey("name");
            //let value = object.valueForKey("identifier");
            this.taxDropDown.title = title;
            this._menu.tax = object;
        });        
    }

}
