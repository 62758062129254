
class PartyDetailDatedViewController extends MUIViewController
{
    private tableView:MUITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.setupFileDialogHack();
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _party:Party = null;
    set party(party:Party){
        this._party = party;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._party == null) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }


    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("DateCell") as PartyDateCell;        

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as PartyEvent;
        cell.item = item;     
        cell.delegate = this;   

        return cell;
    }
 
    // private detailViewController:PartyDetailViewController = null;
    // didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
    //     let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Party;
    //     //this.showPartyDetailViewController(item);
    // }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as PartyEvent;

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("PartyEvent");            
        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("beginDate", true)];
        fetchRequest.predicate = MIOPredicate.predicateWithFormat("party.identifier == " + this._party.identifier);
        fetchRequest.relationshipKeyPathsForPrefetching = ['image'];
                
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }


    private selectedItem:PartyEvent = null; 
    imageDidClick(cell:PartyDateCell){
        this.selectedItem = cell.item;
        let avc = new MUIAlertViewController();
        avc.initWithTitle('Choose the action', 'Choose an action ', MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle('Change Image', MUIAlertActionStyle.Default, this, function(){            
            this.openFileDialog();
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);

    }

    private _inputLayer = null;
    private setupFileDialogHack(){

        //Web hack to open dialog
        let instance = this;

        this._inputLayer =  document.createElement("INPUT");
        this._inputLayer.setAttribute("type", "file");
        this._inputLayer.style.display = "none";        
        this._inputLayer.addEventListener('change', function(ev){
            let files = ev.target.files; // FileList object
            instance.filesDidSelect(files);
        }, false);
        
        MUICoreLayerAddSublayer(this.view.layer, this._inputLayer);
    }

    private openFileDialog(){
        this._inputLayer.click();
    }

    filesDidSelect(files){
        for (let index = 0; index < files.length; index++){
            let f = files[index];
            this.uploadFileToServer(f);
        }
    }

    uploadFileToServer(file){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let ws = ad.webService;

        ws.uploadImage(file, this, function(code, data){
            if (code == 200){
                let imageID = data["id"];
                let url = data["url"];
                MIOLog("Upload image OK!" + url);
                this.changeImage(imageID, url);
            }
            else {
                MIOLog("Upload image FAIL!");
            }
        });
    }

    private changeImage(imageID:string, urlString:string){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let img = MIOEntityDescription.insertNewObjectForEntityForName("DBImage", ad.managedObjectContext) as DBImage;
        img.identifier = imageID;
        img.urlString = urlString;
        this.selectedItem.image = img;
        
        ad.managedObjectContext.save();
    }
}