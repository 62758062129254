/// <reference path="../notes/NoteDetailViewController.ts" />
/// <reference path="../AppHelper.ts" />

class MovementNoteDetailViewController extends NoteDetailViewController 
{
    private editHeaderView:MovementDetailHeaderView = null;
    private noEditHeaderView:MovementDetailHeaderView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.editHeaderView = MUIOutlet(this, "edit-header-view", "MovementDetailHeaderView");            
        this.noEditHeaderView = MUIOutlet(this, "no-edit-header-view", "MovementDetailHeaderView");
    }

    protected updateUI(){        
        this.editHeaderView.note = this.stockNote;
        this.noEditHeaderView.note = this.stockNote;
        this.editHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;
        this.noEditHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? false : true;

        this.fetchedResultsController = null;
        this.tableView.reloadData();                                    
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];    
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockNoteLine", sortDescriptors, 'note.identifier == ' + this.stockNote.identifier);
        //fetchRequest.relationshipKeyPathsForPrefetching = ["product.taxBuy", "product.tax", "product.stockInputFormats", "product.category", "product.supplierProducts"];
        fetchRequest.relationshipKeyPathsForPrefetching = ["tax", "inputFormat"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
        
    private showProcessView(){
        let alert = new MUIAlertViewController();
        alert.initWithTitle(MIOLocalizeString('PROCESS','Process'), MIOLocalizeString("DO YOU WANT TO PROCCES?", "DO YOU WANT TO PROCCES?"), MUIAlertViewStyle.Default);
        alert.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('YES', 'YES'), MUIAlertActionStyle.Default, this, function(){            
            this._movementNote.isProcessed = true;
            this.saveMovementNote();
        }));
        alert.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString('NO', 'YES'), MUIAlertActionStyle.Default, null, null));
       
        this.presentViewController(alert, true);
    }

}
