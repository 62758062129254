

/// <reference path="../MobileViewController.ts" />
/// <reference path="../DesktopViewController.ts" />

class LoginViewController extends MUIViewController {
    
    private emailTextfield:MUITextField = null;
    private passTextfield:MUITextField = null;
    private loginButton:MUIButton = null;    
    private createAccountButton:MUIButton = null;
    private rememberPassButton:MUIButton = null;
    
    viewDidLoad() {        
        super.viewDidLoad();        

        this.emailTextfield = MUIOutlet(this, "email-tf", "MUITextField");        
        this.passTextfield = MUIOutlet(this, "pass-tf", "MUITextField");        

        this.createAccountButton = MUIOutlet(this, 'create-account-btn', 'MUIButton');
        this.createAccountButton.setAction(this, function () {            
            let vc = new CreateAccountViewController('create-account-view');
            vc.initWithResource("layout/createaccount/CreateAccountView.html");            
            this.navigationController.pushViewController(vc);
        });

        this.rememberPassButton = MUIOutlet(this, 'forgot-pass-btn', 'MUIButton');
        this.rememberPassButton.setAction(this, function () {            
            let vc = new RememberPassViewController('recover-password-view');
            vc.initWithResource("layout/recoverpassword/RecoverPasswordView.html");            
            this.navigationController.pushViewController(vc);
        });

        this.loginButton = MUIOutlet(this, "login-btn", "MUIButton");
        this.loginButton.setAction(this, function () {
            this.login();
        });
    }

    viewWillAppear(animated?) {        
        super.viewWillAppear(animated);
        
        let lastLoginUser = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginUser");
        this.emailTextfield.text = lastLoginUser ? lastLoginUser : "";
        this.registerForEnterEvent();
    }

    viewWillDisappear(animated?){        
        super.viewWillDisappear(animated);
        this.unregisterForEnterEvent();
    }

    registerForEnterEvent(){
        MIOCoreEventRegisterObserverForType(MIOCoreEventType.KeyUp, this, function (ev:MIOCoreKeyEvent) {
            if (ev.keyCode == MIOCoreEventKeyCode.Enter) {
                // Prevent propagation
                ev.cancel();
                this.login();
            }        
        });
    }

    unregisterForEnterEvent(){
        MIOCoreEventUnregisterObserverForType(MIOCoreEventType.KeyUp, this);
    }

    private login() {
        this.loginButton.setEnabled(false);
        let ws = MUIWebApplication.sharedInstance().delegate.webService;
        
        ws.userLogin(this.emailTextfield.text, this.passTextfield.text, this, function (error, result) {                        
            if (result == true) {                
                this.loginOK();
            }
            else {
                this.loginError();
            }
            
            this.loginButton.setEnabled(true);
        });
    }

    private loginOK(){        
        MIOUserDefaults.standardUserDefaults().setValueForKey("LastLoginUser", this.emailTextfield.text);

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.loginOK = true;        
        MIONotificationCenter.defaultCenter().postNotification("LoginOK", null);        

        if (SettingsHelper.sharedInstance().isNewServersLoaded) {
            this.showWorkspaceViewController();
        }
        else {
            let request = MIOFetchRequest.fetchRequestWithEntityName("Business");
            ad.webPersistentStore.fetchObjects(request, ad.managedObjectContext, this, function(objects){
                this.checkWorkspaceDB(objects);
            });
        }

    }

    private loginError(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.loginOK = false;

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('LOGIN ERROR',"Login error"), MIOLocalizeString('USER OR PASSWORD WRONG. PLEASE TRY AGAIN',"User or password wrong. Please try again."), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK',"OK"), MUIAlertActionStyle.Default, null, null));

        this.presentViewController(avc, true);
    }

    private checkWorkspaceDB(items){
        let item = null;
               
        let useremail = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginUser");
        let userDB = MIOUserDefaults.standardUserDefaults().valueForKey("UserDB");
        if (userDB != null) {
            let udb = JSON.parse(userDB);
            let lastDB = udb[useremail];
            if (lastDB != null) {
                let identifier = lastDB["lastDBIdentifier"];
                let identifierType = lastDB["lastDBIdentifierType"];
                
                let objs = _MIOPredicateFilterObjects(items, MIOPredicate.predicateWithFormat("identifier == " + identifier + " AND type == " + identifierType));
                if (objs.length > 0) item = objs[0];
            }
        }

        if (item != null) {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            ad.setBusiness(item, this, function(){
                this.dismissViewController(true);
            });    
        }
        else {
            this.showWorkspaceViewController();
        }        
    }

    private showWorkspaceViewController(){
        let vc:WorkspaceViewController = null;

        if (MIOCoreIsPhone()){
            vc = new WorkspaceViewController('workspace-list-view');
            vc.initWithResource('layout/workspace/WorkspaceListView.html');        
        }
        else {
            vc = new WorkspaceViewController('login-workspace-list-view');
            vc.initWithResource('layout/workspace/LoginWorkspaceListView.html');
            vc.isModal = false;
            vc.transitioningDelegate = this;    
        }

        this.navigationController.pushViewController(vc);
    }

    // Transitioning delegate
    private pushAnimationController = null;
    private popAnimationController = null;    
    animationControllerForPresentedController(presentedViewController, presentingViewController, sourceController){
        if (this.pushAnimationController == null) {
            this.pushAnimationController = new LoginPushAnimationController();
            this.pushAnimationController.init();
        }

        return this.pushAnimationController;
    }

    animationControllerForDismissedController(dismissedController)    {            
        if (this.popAnimationController == null) {
            this.popAnimationController = new LoginPopAnimationController();
            this.popAnimationController.init();
        } 
        
        return this.popAnimationController;
    }  

}

/*
    ANIMATIONS
 */

class LoginPushAnimationController extends MIOObject
{
    transitionDuration(transitionContext){
        return 0.75;
    }

    animateTransition(transitionContext){
        // make view configurations before transitions
        let lvc = transitionContext["presentingViewController"];
        lvc.unregisterForEnterEvent();
    }

    animationEnded(transitionCompleted){
        // make view configurations after transitions
    }

    // TODO: Not iOS like transitions. For now we use css animations
    animations(transitionContext){
        let animations = MUIClassListForAnimationType(MUIAnimationType.HorizontalInFlip);
        return animations;
    }

}

class LoginPopAnimationController extends MIOObject
{
    transitionDuration(transitionContext){
        return 0.75;
    }

    animateTransition(transitionContext){
        // make view configurations after transitions
        let lvc = transitionContext["presentedViewController"];
        lvc.registerForEnterEvent();
    }

    animationEnded(transitionCompleted){
        // make view configurations before transitions
    }

    // TODO: Not iOS like transitions. For now we use css animations
    animations(transitionContext){
        let animations = MUIClassListForAnimationType(MUIAnimationType.HorizontalOutFlip);
        return animations;
    }

}
