
class TicketSnapShotViewController extends MUIViewController
{
    private linesTableView:UITableView = null;        
    private logTableView:UITableView = null;            
    private previewTableView:UITableView = null;
    private previewLabel:MUILabel = null;

    private linesDataSource:TicketLinesDataSource = null;
    private logDataSource:TicketSnapshotLogDataSource = null;
    private previewDataSource:TicketPreviewDataSource = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.linesTableView = MUIOutlet(this, "lines-table-view", "UITableView");
        this.linesDataSource = new TicketLinesDataSource();
        this.linesDataSource.initWithTableView(this.linesTableView);
        this.linesTableView.dataSource = this.linesDataSource;

        this.logTableView = MUIOutlet(this, "log-table-view", "UITableView");
        this.logDataSource = new TicketSnapshotLogDataSource();
        this.logDataSource.initWithTableView(this.logTableView);
        this.logTableView.dataSource = this.logDataSource;
        this.logTableView.delegate = this;

        this.previewLabel = MUIOutlet(this, "preview-lbl", "MUILabel");        

        this.previewTableView = MUIOutlet(this, "preview-table-view", "UITableView");
        this.previewDataSource = new TicketPreviewDataSource();
        this.previewDataSource.initWithTableView(this.previewTableView);
        this.previewTableView.dataSource = this.previewDataSource;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _ticket:ArchivedDocument = null;
    private _filterValues = null;
    set filterValues(values){        
        this._filterValues = values;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._filterValues == null) return;

        let predicate = MIOPredicate.predicateWithFormat("documentID = '" + this._filterValues["documentID"] + "'");
        let relationships = [];
        DBHelper.queryObjectsWithCompletion("ArchivedDocument", null, predicate, relationships, this, function(objects){
            if (objects.length > 0) {                
                this._ticket = objects[0] as ArchivedDocument;

                predicate = MIOPredicate.predicateWithFormat("document.identifier = " + this._ticket.identifier);
                relationships = ["lines"];
                DBHelper.queryObjectsWithCompletion("ArchivedTicketLinesGroup", null, predicate, relationships, this, function(lines){        
                    this.mapLines(lines);
                    this.linesDataSource.setItems(this._ticket, this.linesGroup);

                    let ln = null;
                    if (this._ticket.locationName == null) {
                        let kk = this._ticket.orderEntityName;
                        if (parseInt(kk) > 0) ln = "TAB " + this._ticket.orderEntityName;
                    }
                    else {
                        ln = this._ticket.locationCategoryName + " " + this._ticket.locationName;
                    }
            
                    this.logDataSource.setValues(this._filterValues, this.lines, ln);
                });                                    
            }
        });  
                
        
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let item = this.logDataSource.fetchedResultsController.objectAtIndexPath(indexPath) as LogOperation;   
        this.showPreviewFromLog(item);
        this.previewLabel.text = "Printed #" + (indexPath.row + 1);
    }

    private showPreviewFromLog(item:LogOperation){
        let xml = item.infoXML["reportsDicts"];
        this.previewDataSource.xml = xml;
    }

    private linesGroup = [];
    private lines = [];
    private mapLines(lines){

        this.linesGroup = [];
        this.lines = [];
        for (let index = 0; index < lines.length; index++){
            let grp = lines[index];
            if (grp.menuName == null || (grp.menuName != null && grp.menuName == grp.productName)) {
                let line = this.createLineFromGroup(grp);
                if (line != null) this.linesGroup.addObject(line);    
            }
        }

        //this.lines = _MIOSortDescriptorSortObjects(this.lines, [MIOSortDescriptor.sortDescriptorWithKey("Title", true)]);
    }

    private createLineFromGroup(group:ArchivedTicketLinesGroup){
        let line = {};
        line["Title"] = group.productName;        
        line["UnitPrice"] = group.productPrice;        
        line["TaxName"] = group.taxName;
        
        let quantity = 0;
        let invited = 0;
        let discount = 0;
        let total = 0;
        let linesCount = 0;

        for (let index = 0; index < group.lines.count; index++){
            let l = group.lines.objectAtIndex(index) as ArchivedTicketLine;

            this.lines.push(l);

            if (this._ticket.modifiedType == 1 && l.modifiedType == 1 && l.nullifyBy != null) continue;
            
            linesCount++;
            quantity++;
            discount += l.totalDiscount;
            invited += l.totalInvited;
            total += l.totalPrice;
        }                

        if (group.bulkQuantity != null) {
            line["Quantity"] = group.bulkQuantity;        
        }
        else {
            line["Quantity"] = quantity;        
        }

        line["Total"] = total;
        
        return linesCount > 0 ? line : null;
    }
  
}