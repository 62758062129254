
class PaymentEntityViewController extends MUIViewController
{

    private addButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private tableView:MUITableView = null;

    viewDidLoad() {
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            this.showAddPaymentEntityViewController();
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });
        
        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);

        this.updateUI();
    }

    private updateUI()
    {
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath) 
    {   
        let cell = null;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as PaymentEntity;

        switch(item.paymentType) {

            case PaymentEntityType.Redsys:
                cell = tableview.dequeueReusableCellWithIdentifier('RedsysPaymentEntityCell') as RedsysPaymentEntityCell;
            break;

            case PaymentEntityType.Stripe:
                cell = tableview.dequeueReusableCellWithIdentifier('StripePaymentEntityCell') as PaymentEntityCell;
            break;

            case PaymentEntityType.CashOnDelivery:
                cell = tableview.dequeueReusableCellWithIdentifier('CashPaymentEntityCell') as PaymentEntityCell;
            break;
        }

        cell.item = item;
        return cell;
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("PaymentEntity", sortDescriptors, null);
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }

    private showAddPaymentEntityViewController() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("PAYMENT ENTITY", "PAYMENT ENTITY"), MIOLocalizeString("ADD NEW PAYMENT ENTITY", "ADD NEW PAYMENT ENTITY"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString("NAME", "NAME");
        })

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox) {
            let types = PaymentEntity.supportedTypesForCurrentRegion();

            for (let t of types) {
                comboBox.addItem(PaymentEntity.typeStringForType(t), t);
            }
            
            comboBox.selectItem(0);
        })

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function() {
            let name = avc.textFields[0].text;
            let type = avc.comboBoxes[0].getSelectedItem();

            if (name.length == 0) {
                AppHelper.showErrorMessage(this, MIOLocalizeString("UNEABLE TO CREATE", "UNEABLE TO CREATE"), MIOLocalizeString("YOU MUST SPECIFIED A NAME", "YOU MUST SPECIFIED A NAME"));
                return;
            }

            DBHelper.createPaymentEntity(name, parseInt(type), true);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

}