

// Generated class ProductWarehouse_ManagedObject

class ProductWarehouse_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: maxQuantity
    set maxQuantity(value:number) {
        this.setValueForKey(value, 'maxQuantity');
    }
    get maxQuantity():number {
        return this.valueForKey('maxQuantity');
    }
    set maxQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maxQuantity');
    }
    get maxQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('maxQuantity');
    }

    // Property: minQuantity
    set minQuantity(value:number) {
        this.setValueForKey(value, 'minQuantity');
    }
    get minQuantity():number {
        return this.valueForKey('minQuantity');
    }
    set minQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minQuantity');
    }
    get minQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minQuantity');
    }

    // Property: measureUnit
    set measureUnit(value:number) {
        this.setValueForKey(value, 'measureUnit');
    }
    get measureUnit():number {
        return this.valueForKey('measureUnit');
    }
    set measureUnitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'measureUnit');
    }
    get measureUnitPrimitiveValue():number {
        return this.primitiveValueForKey('measureUnit');
    }

    // Property: isDefault
    set isDefault(value:boolean) {
        this.setValueForKey(value, 'isDefault');
    }
    get isDefault():boolean {
        return this.valueForKey('isDefault');
    }
    set isDefaultPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefault');
    }
    get isDefaultPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefault');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: warehouse
    set warehouse(value:Warehouse) {
        this.setValueForKey(value, 'warehouse');
    }
    get warehouse():Warehouse {
        return this.valueForKey('warehouse') as Warehouse;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
}
