/**
 * Created by miguel on 22/2/17.
 */

class TicketListViewController extends BaseTableViewController
{            
    private activityIndicator:MUIActivityIndicatorView = null;
	private calendarButton:MUIButton = null; 
	private filterButton:MUIButton = null;       
    
    private tdvc:TicketDetailViewController = null;

    private selectedDayString = null;

    private lastDate = null;
    private delayTimer = null;

    private invoices = [];    

    private sdf = MUIWebApplication.sharedInstance().delegate.serverDateFormatter;
    private df = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.activityIndicator = MUIOutlet(this, 'tlv_activity-indicator', 'MUIActivityIndicatorView');
        this.activityIndicator.setHidden(true);
 
        this.calendarButton = MUIOutlet(this, 'calendar_btn', 'MUIButton');
        this.calendarButton.setAction(this, function () {
            let ah:AppHelper = AppHelper.sharedInstance();
            ah.presentDateSelectionViewController(this);
		});
		
        this.filterButton = MUIOutlet(this, "filter-btn", "MUIButton");
		this.filterButton.setAction(this, function() {
            AppHelper.sharedInstance().showFilterViewController(this, this.filters, this.filtersDidChange);
             
		});

        this.searchTextField = MUIOutlet(this, 'tlv_tv_search_bar', 'MUITextField');
        this.setupSearchTextField();
    
        this.tableView = MUIOutlet(this, 'tlv_tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.needsReloadData();
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);

        MIONotificationCenter.defaultCenter().addObserver(this, "UpdateTicketList", function(note:MIONotification){
            this.setFetchedResultsController(null);
            this.tableView.reloadData();
        });
    }

    viewWillDisappear(animated?){
        super.viewWillDisappear(animated);
        MIONotificationCenter.defaultCenter().removeObserver(this, "UpdateTicketList");
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("TicketCell") as TicketCell; 

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ArchivedDocument;
        cell.item = item;

        return cell;
    }
     
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);

        if(!this.tdvc) {
            this.tdvc = new TicketDetailViewController('ticket-detail-view');
            this.tdvc.initWithResource('layout/tickets/TicketDetailView.html');   
        }
        
        this.tdvc.ticket = obj;
        this.splitViewController.showDetailViewController(this.tdvc);
        this.selectedItem = obj;
    }

    private showNoItemSelectedView(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Ticket", NoItemSelectedViewControllerType.Ticket);
        this.splitViewController.showDetailViewController(vc);
	}
	
	protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("ArchivedDocument");
        fetchRequest.fetchLimit = 50;
        fetchRequest.relationshipKeyPathsForPrefetching = ["nextDocument"];
    
        let predicateString = "legalDocumentID != null AND type == 'T'";
        if (this.searchString != null) {
            predicateString += " AND (legalDocumentID CONTAINS '" + this.searchString + "' OR documentID CONTAINS '" + this.searchString + "')";
        }
        if (this.dateTimeFrom != null && this.dateTimeTo != null) {
            predicateString += " AND (sessionBeginDate >= '" + this.dateTimeFrom + "' AND sessionBeginDate <= '" + this.dateTimeTo + "')";
		}
		if (this.filterPredicateFormat != null) {
            predicateString += " AND " + this.filterPredicateFormat;
        }

        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("date", false), 
                                         MIOSortDescriptor.sortDescriptorWithKey("legalDocumentID", false)];
        if (predicateString != null)
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateString);
                
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }

    private dateTimeFrom:string = null;
    private dateTimeTo:string = null;    
    datesDidSelect(dateTimeFrom, dateTimeTo) {
        this.dateTimeFrom = MIODateGetDateString(dateTimeFrom) + " 00:00:00";
        this.dateTimeTo = MIODateGetDateString(dateTimeTo) + " 23:59:59";
        
        this.showNoItemSelectedView();
        this.invalidateData();
	}
	
	private _filters = null;
    get filters(){
        if (this._filters != null) {
            return this._filters;
        } else {
            this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "date", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
                    FilterOption.filterWithTitle("ToDate", "To day", "date", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),    
                    FilterOption.filterWithTitle("Amount", "Amount", "totalPrice", FilterFieldType.Number, null, null),            
                    FilterOption.filterWithFetchRequest("Client", "Client", "ticketLegalEntity.identifier", FilterFieldType.DropDown, "Client", "name", "identifier", "Select client")];

            return this._filters;
        }
    }

    protected filterPredicateFormat:string = null;
    protected filtersDidChange(filtersPredicateFormat){
        
        if(filtersPredicateFormat != null) {
            MUICoreLayerAddStyle(this.filterButton.layer, 'filter');
            MUICoreLayerRemoveStyle(this.filterButton.layer, 'filter-off');
        } else {
            MUICoreLayerRemoveStyle(this.filterButton.layer, 'filter');
            MUICoreLayerAddStyle(this.filterButton.layer, 'filter-off');
        }

        this.filterPredicateFormat = filtersPredicateFormat;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
	}
	
}