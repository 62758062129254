//
// Generated class Product
//

/// <reference path="Product_ManagedObject.ts" />

enum ProductType 
{
    None,    
    Sales,
    Stock,
    Both    
}

enum StockElaborationType
{
    None,
    BeforeSale,
    AtSale
}

enum StockBlockType
{
    None,
    Product,
    Components
}

class Product extends Product_ManagedObject
{
    static stockElabotarationStringByType(type:StockElaborationType){
        switch (type){
            case StockElaborationType.None:
            return MIOLocalizeString("NO ELABORATION", "No elaboration");

            case StockElaborationType.BeforeSale:
            return MIOLocalizeString("ELABORATION BEFORE SALE", "Elaboration before sale");

            case StockElaborationType.AtSale:
            return MIOLocalizeString("ELABORATION AT SALE", "Elaboration at sale");
        }

        return null;
    }

    static stockBlockStringByType(type:StockBlockType){
        switch(type){
            case StockBlockType.None:
            return MIOLocalizeString("PRODUCT NO BLOCK", "No block");

            case StockBlockType.Product:
            return MIOLocalizeString("PRODUCT BLOCK BY PRODUCT", "Block by product");

            case StockBlockType.Components:
            return MIOLocalizeString("PRODUCT BLOCK BY INGREDIENTS", "Block by components");
        }

        return null;
    }

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter as MIONumberFormatter;
        
    get currentStock():string{
        let stock = null;

        if (this.isContainer == true) {
            stock = this.nf.stringFromNumber(this.existences) + " " + (this.stockContainerName != null ? this.stockContainerName : MeasureUnits.toString(MeasureUnitType.Container));
        }
        else {
            stock = this.nf.stringFromNumber(this.existences) + ' ' + MeasureUnits.toString(this.measureUnitType);
        }
        return stock;
    }

    // get avgCost(){
    //     let quantity = this.quantity ? this.quantity  : 1;      
    //     return (this.currentExistences ? parseFloat(this.currentExistences['avgcost']) * quantity : 0);
    // }

    get imageUrl(){
        let url = '';
        if(this.imageURL) return this.imageURL;
        if(this.image) {
            let image = this.image;
            url = image['url'];
        } 
        return url;
    }

    public getAllergies(flags){
        if (flags == null) return;
        let allergies = [];
        for (let index = 0; index < 14; index++){
            let b = (flags >> index) & 0x1;
            let item;
            if (b == 1 && (item = this._addAllergies(index))) {

                 allergies.push(item);
            }
        }
        return allergies;
    }

    private _addAllergies(index){
        let item = null;
        switch (index)
        {
            case 0: // Gluten
                item = "Gluten";
                break;

            case 1: // Crustaceans
                item = "Crustaceans";
                break;

            case 2: // Eggs
                item = "Eggs";
                break;

            case 3: // Fish
                item = "Fish";
                break;

            case 4: // Peanuts
                item = "Peanuts";
                break;

            case 5: // Soya
                item = "Soya";
                break;

            case 6: // Milk
                item = "Milk";
                break;

            case 7: // Nuts
                item = "Nuts";
                break;

            case 8: // Celery
                item = "Celery";
                break;

            case 9: // Mustard
                item = "Mustard";
                break;

            case 10: // Sesame
                item = "Sesame";
                break;

            case 11: // Sulphates
                item = "Sulphates";
                break;

            case 12: // Mollusk
                item = "Mollusk";
                break;

            case 13: // Lupins
                item = "Lupins";
                break;
        }

        return item;
    }    

    get defaultTax():Tax{
        let tax:Tax = this.tax;
        if (tax != null) return tax;

        if (this.category == null) return tax;

        tax = this.category.tax;
        return tax;
    }

    taxString():string {
        let tax = this.defaultTax;
        if (tax != null) return tax.name;

        return null;
    }

    set fixedCostPriceForMinimumMeasure(price){
        let q:MIODecimalNumber = MIODecimalNumber.numberWithFloat(this.quantity == null ? 1 : this.quantity);
        let p:MIODecimalNumber = MIODecimalNumber.numberWithFloat(price);

        switch(this.measureUnitType)
        {
            case MeasureUnitType.Unit:
            case MeasureUnitType.VolumeCentilitre:
            case MeasureUnitType.MassGram:
                this.costPrice = p.decimalNumberByDividingBy(q).decimalValue;
                //this.costPrice = price / quantity;
                break;
            case MeasureUnitType.MassKilogram:
                this.costPrice = p.decimalNumberByDividingBy(q.decimalNumberByMultiplyingBy(MIODecimalNumber.numberWithInteger(1000))).decimalValue;
                break;
               
            case MeasureUnitType.VolumeLitre:
            this.costPrice = p.decimalNumberByDividingBy(q.decimalNumberByMultiplyingBy(MIODecimalNumber.numberWithInteger(100))).decimalValue;
                break;
            default:
                throw new Error('Unexpected measure unit');
        }
    }

    get fixedCostPriceFromMinimumMeasure(){
        let quantity = MIODecimalNumber.numberWithFloat(this.quantity == null ? 1 : this.quantity);
        let costPrice = MIODecimalNumber.numberWithFloat(this.costPrice);
        let price = null;

        switch(this.measureUnitType)
        {
            case MeasureUnitType.Unit:
            case MeasureUnitType.VolumeCentilitre:
            case MeasureUnitType.MassGram:
                price = costPrice.decimalNumberByMultiplyingBy(quantity).decimalValue;
                break;

            case MeasureUnitType.MassKilogram:
                price = costPrice.decimalNumberByMultiplyingBy(quantity).decimalNumberByMultiplyingBy(MIODecimalNumber.numberWithInteger(1000)).decimalValue;
                break;
               
            case MeasureUnitType.VolumeLitre:            
                price = costPrice.decimalNumberByMultiplyingBy(quantity).decimalNumberByMultiplyingBy(MIODecimalNumber.numberWithInteger(100)).decimalValue;
                break;
            
            default:
                throw new Error('Unexpected measure unit');
        }
        return price;
    }

    defaultInputFormatString():string{
        let type = MeasureUnits.toString(this.measureUnitType);
        if (this.quantity == null || this.quantity == 0) return type;

        return MeasureUnits.toString(MeasureUnitType.Container) + " (" + this.quantity + " " + type + ")";
    }

    get isContainer(){
        return this.quantity > 0 && this.measureUnitType != null;
    }

    // get measureType()
    // {
    //     let measureunittype = this.measureUnitType;
    //     if(measureunittype == MeasureUnitType.Unit)
    //         return MeasureType.MeasureTypeUnit;
    //     if(measureunittype == MeasureUnitType.MassGram || measureunittype == MeasureUnitType.MassKilogram)
    //         return MeasureType.MeasureTypeMass;
    //     if(measureunittype == MeasureUnitType.VolumeCentilitre || measureunittype == MeasureUnitType.VolumeLitre)
    //         return MeasureType.MeasureTypeVolume;
                
    // }

    get measureType(){
        if (this.isContainer == true) {
            return MeasureUnitType.Container;
        }
        else {
            return this.measureUnitType;
        }
    }

    get containerMeasureType(){
        if (this.isContainer == true) return this.measureUnitType;
        
        return null;
    }

    get containerQuantity(){
        if (this.isContainer == true) return this.quantity;

        return null;
    }

    get stockElaborationTypeString(){
        return Product.stockElabotarationStringByType(this.elaborationType);
    }

    get stockBlockTypeString(){
        return Product.stockBlockStringByType(this.stockBlockType);
    }

    get productType():ProductType{
        if (this.isEnableForSell== false && this.isEnableForStock == false) return ProductType.None;
        if (this.isEnableForSell == true && this.isEnableForStock == true) return ProductType.Both;
        if (this.isEnableForSell == true) return ProductType.Sales;
        return ProductType.Stock;
    }

    get productTypeString():string {
        switch (this.productType){
            case ProductType.None:
                return MIOLocalizeString("NONE", "NONE");
            case ProductType.Sales:
                return MIOLocalizeString("ONLY SALES", "ONLY SALES");
            case ProductType.Stock:
                return MIOLocalizeString("ONLY STOCK", "ONLY STOCK");
            case ProductType.Both:
                return MIOLocalizeString("SALES & STOCK", "SALES & STOCK");                        
        }

        return null;
    }

    // get product place
    private setProductPlaceValue(value, key:string) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        if (ad.selectedIdentifierType == "company") this.setValueForKey(value, key);

        let placeID = ad.selectedIdentifier;

        let items = this.productPlace.filterWithPredicate(MIOPredicate.predicateWithFormat("placeID == '" + placeID + "'"));
        if (items.length > 0) { 
            let item = items[0] as ProductPlace;
            item.setValueForKey(value, key);    
        }
        else {
            let item = MIOEntityDescription.insertNewObjectForEntityForName("ProductPlace", this.managedObjectContext) as ProductPlace;            
            item.placeID = placeID;
            item.product = this;
            this.addProductPlaceObject(item);
            item.setValueForKey(value, key);
        }    
    }

    // get product place
    private getProductPlaceValue(key:string) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        if (ad.selectedIdentifierType == "company") return this.valueForKey(key);
        
        let placeID = ad.selectedIdentifier;

        let items = this.productPlace.filterWithPredicate(MIOPredicate.predicateWithFormat("placeID == '" + placeID + "'"));
        if (items.length == 0) return this.valueForKey(key);
        
        let item = items[0] as ProductPlace;
        return item.valueForKey(key);
    }

    // Property: price
    set price(value:number) {
        this.setProductPlaceValue(value, 'price');
    }
    get price():number {
        return this.getProductPlaceValue('price');
    }

    // Property: isEnableForSell
    set isEnableForSell(value:boolean) {        
        this.setProductPlaceValue(value, 'isEnableForSell');
    }

    get isEnableForSell():boolean {
        return this.getProductPlaceValue('isEnableForSell');
    }    
}
