
class  ProductAdminCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private formatNameLabel:MUILabel = null;
    private priceLabel:MUILabel = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.formatNameLabel = MUIOutlet(this, "format-name-lbl", "MUILabel");
        this.priceLabel = MUIOutlet(this, "price-lbl", "MUILabel");

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    set item (item:ProductComponentItem){
        this.nameLabel.text = item.name;        
        if (this.formatNameLabel != null) this.formatNameLabel.text = item.formatName;        
        if (this.priceLabel != null) {
            let price = item.productFormatPrice != null ? item.productFormatPrice : item.price;
            this.priceLabel.text = this.cf.stringFromNumber(price);
        }
    }    
}