/**
 * Created by godshadow on 04/08/16.
 */



class SettingsFormatViewController extends SingleEditViewController
{
    get title(){
        return "Formats";
    }

    fetchEntity(){
        return "Format";
    }

    sortDescriptors(){
        return [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
    }

    configureCellAtIndexPath(cell:SingleEditCell, indexPath:MIOIndexPath, item:MIOManagedObject){
        let i = item as Format;        
        cell.setItem(i, "name");
    }

    addButtonDidPress(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('FORMAT','Format'), MIOLocalizeString('CHOOSE A NAME','Choose a name '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Name");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                let format:Format = MIOEntityDescription.insertNewObjectForEntityForName("Format", moc) as Format;
                format.identifier = MIOUUID.UUID().UUIDString;
                format.name = name;
                format.orderIndex = 1;
                moc.save();                
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('CHECK NAME','Check name.'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);
    }
}
