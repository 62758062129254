 /// <reference path="ReportFilterView.ts" />


class ReportViewController extends MUIViewController
{
    showSectionButton = false;
    showBackButton = false;
    showSplitBackButton = false;
    showNavigationBar = true;
    splitBackButtonTarget = null;
    splitBackButtonCompletion = null;

    private navigationBar:MUIView = null;
    private sectionsButton:MUIButton = null;
    private backButton:MUIButton = null;
    private splitBackButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private dateLabel:MUILabel = null;
    private filterButton:MUIButton = null;
    private exportButton:MUIButton = null;
    private filtersView:MUIView = null;
    private resultsView:ReportView = null;

    private loadingView:MUIView = null;

    private filterViewController:FilterViewController = null;
    
    viewDidLoad(){
        super.viewDidLoad();   

        this.navigationBar = MUIOutlet(this, "nav-bar", "MUIView");

        this.sectionsButton = MUIOutlet(this, "sections-btn", "MUIButton");
        if (this.sectionsButton != null) this.sectionsButton.hidden = true;
        if (this.sectionsButton != null) this.sectionsButton.setAction(this, function(){
            AppHelper.sharedInstance().presentSectionViewController(this);        
        });

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        if (this.backButton != null) {
            this.backButton.hidden = true;
            this.backButton.setAction(this, function(){
                this.navigationController.popViewController(true);
            });
        }
        
        this.splitBackButton = MUIOutlet(this, "sv-back-btn", "MUIButton");
        if (this.splitBackButton != null) {
            this.splitBackButton.hidden = true;
            this.splitBackButton.setAction(this, function(){
                if (this.splitBackButtonTarget != null && this.splitBackButtonCompletion != null) {
                    this.splitBackButtonCompletion.call(this.splitBackButtonTarget);
                }
            });
        }
        
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");

        this.filterButton = MUIOutlet(this, "filter-btn", "MUIButton");
        this.filterButton.setAction(this, function(){
            this.toggleFilterViewController();                
        });
        
        this.exportButton = MUIOutlet(this, "export-btn", "MUIButton");
        if (MIOCoreIsMobile() == true) this.exportButton.hidden = true;
        this.exportButton.setAction(this, function(){
            this.exportReport();
        });
        
        this.resultsView = MUIOutlet(this, "results-view", "ReportView");   
        this.resultsView.delegate = this;     

        this.loadingView = MUIOutlet(this, "loading-view", "MUIView");
        this.loadingView.hidden = true;

        this.filtersView = MUIOutlet(this, "right-view", "MUIView");
        if (this.filtersView == null) return;
        this.filtersView.hidden = true;        
        this.filterViewController = new FilterViewController("filter-view");
        this.filterViewController.initWithResource("layout/filter/FilterView.html");
        this.filterViewController.embeddedController = true;
        this.filtersView.addSubview(this.filterViewController.view);                
        this.addChildViewController(this.filterViewController);
        this.filterViewController.setCompletionBlock(this, function(){
            if (this.isDownloading == false && this.filterViewController != null) {
                this.isDownloaded = false;
                this.filterViewController.setApplyButtonEnable(false);
                this.updateUI();
            }                        
        });
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        //this.filtersView.setWidth(0);

        if (this._report != null) ReportHelper.sharedInstance().filtersFromReport(this._report, this, function(filters){
            if (this.filterViewController != null) this.filterViewController.filters = filters;
        });

        this.updateUI();
    }

    private _report:Report = null;
    set report(item:Report){
        this._report = item;
        this._title = item.name;        
        this.reportScriptPath = item.type + "/" + item.script;
    }

    set item(item:ReportListItem){
        this.reportID = item.scriptID;
        this._title = item.title;
    }

    private _reportID:string = null;
    set reportID(reportID:string){

        if (SettingsHelper.sharedInstance().isNewServersLoaded == true){            
            DBHelper.queryObjectWithCompletion("Report", null, MIOPredicate.predicateWithFormat("identifier == " + reportID), [], this, function(report:Report){
                this.report = report;
            });                                     
        }          
        else {
            this._reportID = reportID;
            this.updateUI();
        }                 
    }

    private _reportPath:string = null;
    set reportScriptPath(path:string){
        this._reportPath = path;
        this.updateUI();
    }

    private _title:string = null;
    set title(title:string){
        this._title = title;
    }

    private _filterValues = null;
    set filterValues(values){
        this._filterValues = values;
    }

    private dateIntervalString:string = null;
    private serverDateIntervalString:string = null;
    private isDownloaded = false;
    private isDownloading = false;
    private updateUI(){
        if (this.viewIsLoaded == false) return;
        if (this._reportID == null && this._reportPath == null) return;
        if (this.isDownloaded == true) return;
        if (this.isDownloading == true) return;
        if (this.showSectionButton == true && this.sectionsButton != null) this.sectionsButton.hidden = false;
        if (this.showBackButton == true && this.backButton != null) this.backButton.hidden = false;
        if (this.showSplitBackButton == true && this.splitBackButton != null) this.splitBackButton.hidden = false;
        this.navigationBar.hidden = !this.showNavigationBar;
        this.titleLabel.text = this._title;
        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let rh = ReportHelper.sharedInstance();
        let from = ad.dateFormatter.stringFromDate(rh.selectedDateTimeFrom);
        let to = ad.dateFormatter.stringFromDate(rh.selectedDateTimeTo);
        if (from == to) {
            this.dateIntervalString = from;
            this.serverDateIntervalString = ad.serverDateFormatter.stringFromDate(rh.selectedDateTimeFrom);
        }
        else {
            this.dateIntervalString = from + " - " + to;
            let serverFrom = ad.serverDateFormatter.stringFromDate(rh.selectedDateTimeFrom);
            let serverTo = ad.serverDateFormatter.stringFromDate(rh.selectedDateTimeTo);
            this.serverDateIntervalString = serverFrom + "_" + serverTo;
        }
        
        this.dateLabel.text = this.dateIntervalString;
        this.downloadReport();
    }

    private toggleFilterViewController(){   

        if (SettingsHelper.sharedInstance().isNewServersLoaded) {
            this.filtersView.hidden = !this.filtersView.hidden;
            // if (this.isDisplayFilterView == false) this.showFilterView();
            // else this.hideFilterView();    
        }
        else {            
            AppHelper.sharedInstance().presentDateSelectionViewController(this);            
        }

    }
    
    private isDisplayFilterView = false;
    private showFilterView(){
        if (this.isDisplayFilterView == true) return;
        
        MUIView.animateWithDuration(0.15, this, function(){
            this.isDisplayLogView = true;
            this.filtersView.setWidth(320);
        }, function(){
            this.filtersView.setNeedsDisplay();
        });
    }

    private hideFilterView(){
        if (this.isDisplayFilterView == false) return;

        MUIView.animateWithDuration(0.15, this, function(){
            this.isDisplayLogView = false;
            this.filtersView.setWidth(0);
        }, function(){
            this.filtersView.setNeedsDisplay();
        });
    }


    datesDidSelect(dateTimeFrom, dateTimeTo) {
        let rh = ReportHelper.sharedInstance();
        rh.selectedDateTimeFrom = dateTimeFrom;
        rh.selectedDateTimeTo = dateTimeTo;   
        this.isDownloaded = false;
        this.updateUI();
    }
    
    private reportData = null;
    private downloadReport(){
        this.resultsView.clear();

        if (this._reportID != null) this.downloadReportByID();
        else if (this._reportPath != null) this.downloadReportByPath();            
    }

    private downloadReportByID(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let ws = ad.webService as WebService;                
    
        // OLD Reprot API
        let body = {};
        body["id"] = this._reportID;
        let filters = {};
        body["filters"] = filters;
        filters["session_begindate"] = ReportHelper.sharedInstance().selectedServerDateTimeFromString;
        filters["session_enddate"] = ReportHelper.sharedInstance().selectedServerDateTimeToString;                          

        if (this._filterValues != null){
            for (let key in this._filterValues){
                filters[key] = this._filterValues[key];
            }
        }
        
        this.loadingView.hidden = false;
        ws.executeQuery(body, this, function(code, data){
            this.loadingView.hidden = true;
            this.isDownloaded = true;
            this.isDownloading =  false;
            this.filterViewController.setApplyButtonEnable(true);
            if (code != 200) {
                this.resultsView.logText("Error executing script (" + code + ")");
                return;
            }        
            this.resultsView.parseResponse(data["data"]);
            this.reportData = data["data"];    
        });
    }

    private downloadReportByPath(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let ws = ad.webService as WebService;                

        let filters = {};
        filters["session_date"] = ReportHelper.sharedInstance().selectedServerDateTimeFromString + "," + ReportHelper.sharedInstance().selectedServerDateTimeToString;

        // Add filter values from another report
        if (this._filterValues != null){
            for (let key in this._filterValues){
                filters[key] = this._filterValues[key];
            }
        }

        let filterOptions = this.filterViewController.filters != null ? this.filterViewController.filters : [];
        for (let index = 0; index < filterOptions.length; index++){
            let fo = filterOptions[index] as FilterOption;

            if (fo.value != null) {
                if (fo.type == FilterControllerValueType.Number) filters[fo.key] = fo.value;                
                else {
                    //filters[fo.key] = fo.value.replace(/"([^"]*)"/, "");
                    let value = fo.value;
                    if (value.hasPreffix("'") && value.hasSuffix("'")) value = fo.value.substring(1, fo.value.length - 1);
                    if (value.hasPreffix("\"") && value.hasSuffix("\"")) value = fo.value.substring(1, fo.value.length - 1);
                    filters[fo.key] = value;
                }                
            }
        }

        this.loadingView.hidden = false;
        ws.executeReportScriptAtPath(this._reportPath, filters, this, function(code, data){
            this.loadingView.hidden = true;
            this.isDownloaded = true;
            this.isDownloading =  false;
            this.filterViewController.setApplyButtonEnable(true);
            if (code != 200) {
                this.resultsView.logText("Error executing script (" + code + ")");
                return;
            }        
            this.resultsView.clear();
            this.resultsView.parseResponse(data);
            this.reportData = data;                
        });
    }

    performSegueWithIdentfier(segueIdentifier, values){        
        
        if (MIOCoreIsPhone() == true) return;
        
        let vc = null;
        switch(segueIdentifier){            
            case "ShowReportViewController":
                vc = this.reportViewController(values);
                break;

            case "ShowTicketShapshotViewController":
                vc = new TicketSnapShotViewController("printer-snapshot-view");
                vc.initWithResource("layout/printersnapshot/PrinterSnapshotView.html");
                vc.filterValues = values;
                break;

            case "ShowTicketDetailViewController":
                vc = new TicketDetailViewController('ticket-detail-view');
                vc.initWithResource('layout/tickets/TicketDetailView.html');   
                vc.filterValues = values;
                vc.showBackButton = true;
                break;

            case "ShowWorkerSessionViewController":
                vc = new WorkerSessionViewController("worker-sessions-view");
                vc.initWithResource("layout/workers/WorkerSessionsView.html");
                vc.filterValues = values;
                vc.showFromReport = true;
                vc.setOnSaveBlock(this, function(){
                    this.downloadReport();
                });
                break;
			
			case "ShowInventoryNoteDetailViewController":
                vc = new InventoryNoteDetailViewController('inventory-note-detail-view');
                vc.initWithResource('layout/inventorynote/InventoryNoteDetailView.html');   
                vc.filterValues = values;
                vc.showBackButton = true;
                break;

            case "ShowBookingDetailViewController":
                vc = new BookingDetailViewController("booking-detail-view");
                vc.initWithResource("layout/bookings/bookings/BookingsDetailView.html");
                vc.filterValues = values;                
                break;
            }
        
        if (vc == null || this.navigationController == null) return;
        this.navigationController.pushViewController(vc, true);
    }

    private reportViewController(values){
        let vc = new ReportViewController("report-detail-view");
        vc.initWithResource("layout/reports/ReportDetailView.html");            
        vc.showSectionButton = false;
        vc.showBackButton = true;            

        let rid = values["reportID"];
        let rp = values["ReportPath"];
        let filters = values["filters"];

        vc.filterValues = filters;
        if (rp == null) vc.reportID = rid;
        else vc.reportScriptPath = rp;        
        
        return vc;
    }

    exportReport(){
        let filename = this.serverDateIntervalString + (this._title ? "_" + this._title : "");
        ReportHelper.sharedInstance().showExportActions(filename, this._title, this.dateIntervalString, this.reportData);
    }
}