

// Generated class Component_ManagedObject

class Component_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: ignoreBulk
    set ignoreBulk(value:boolean) {
        this.setValueForKey(value, 'ignoreBulk');
    }
    get ignoreBulk():boolean {
        return this.valueForKey('ignoreBulk');
    }
    set ignoreBulkPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'ignoreBulk');
    }
    get ignoreBulkPrimitiveValue():boolean {
        return this.primitiveValueForKey('ignoreBulk');
    }

    // Property: consumptionQuantity
    set consumptionQuantity(value:number) {
        this.setValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantity():number {
        return this.valueForKey('consumptionQuantity');
    }
    set consumptionQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('consumptionQuantity');
    }

    // Property: consumptionMeasureType
    set consumptionMeasureType(value:number) {
        this.setValueForKey(value, 'consumptionMeasureType');
    }
    get consumptionMeasureType():number {
        return this.valueForKey('consumptionMeasureType');
    }
    set consumptionMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionMeasureType');
    }
    get consumptionMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('consumptionMeasureType');
    }

    // Property: lossQuantity
    set lossQuantity(value:number) {
        this.setValueForKey(value, 'lossQuantity');
    }
    get lossQuantity():number {
        return this.valueForKey('lossQuantity');
    }
    set lossQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossQuantity');
    }
    get lossQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('lossQuantity');
    }

    // Property: lossMeasureType
    set lossMeasureType(value:number) {
        this.setValueForKey(value, 'lossMeasureType');
    }
    get lossMeasureType():number {
        return this.valueForKey('lossMeasureType');
    }
    set lossMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossMeasureType');
    }
    get lossMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('lossMeasureType');
    }

    // Property: totalQuantity
    set totalQuantity(value:number) {
        this.setValueForKey(value, 'totalQuantity');
    }
    get totalQuantity():number {
        return this.valueForKey('totalQuantity');
    }
    set totalQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalQuantity');
    }
    get totalQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('totalQuantity');
    }

    // Property: totalMeasureType
    set totalMeasureType(value:number) {
        this.setValueForKey(value, 'totalMeasureType');
    }
    get totalMeasureType():number {
        return this.valueForKey('totalMeasureType');
    }
    set totalMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalMeasureType');
    }
    get totalMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('totalMeasureType');
    }

    // Property: cost
    set cost(value:number) {
        this.setValueForKey(value, 'cost');
    }
    get cost():number {
        return this.valueForKey('cost');
    }
    set costPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'cost');
    }
    get costPrimitiveValue():number {
        return this.primitiveValueForKey('cost');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: productFormatCustom
    set productFormatCustom(value:ProductFormat) {
        this.setValueForKey(value, 'productFormatCustom');
    }
    get productFormatCustom():ProductFormat {
        return this.valueForKey('productFormatCustom') as ProductFormat;
    }
    // Relationship: productFormatExtra
    set productFormatExtra(value:ProductFormat) {
        this.setValueForKey(value, 'productFormatExtra');
    }
    get productFormatExtra():ProductFormat {
        return this.valueForKey('productFormatExtra') as ProductFormat;
    }

    // Property: parentProductID
    set parentProductID(value:string) {
        this.setValueForKey(value, 'parentProductID');
    }
    get parentProductID():string {
        return this.valueForKey('parentProductID');
    }
    set parentProductIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'parentProductID');
    }
    get parentProductIDPrimitiveValue():string {
        return this.primitiveValueForKey('parentProductID');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
}
