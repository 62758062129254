/**
 * Created by miguel on 22/2/17.
 */


class IssuedInvoiceDetailViewController extends MUIViewController
{
    private actionButton:MUIButton = null;

    private statusBarView:MUIView = null;
    private noEditHeader:MUIView = null;
    private editHeader:MUIView = null;

    private documentIDLabel:MUILabel = null;
    private dateLabel:MUILabel = null;
    private clientLabel:MUILabel = null;
    private nextDocumentIDLabel:MUILabel = null;

    private tableView:MUITableView                = null;


    private addLabel:MUILabel                 = null;

    private _product:Product                  = null;

    
    private numTextField:MUITextField             = null;
    private dateTextField:MUITextField            = null;
    private receiptNameLabel:MUILabel         = null;
    private receiptPhoneLabel:MUILabel        = null;
    private receiptImage:MUIImageView             = null;
    private statusComboBox:MUIComboBox           = null;
    private payMethodComboBox:MUIComboBox        = null;
    private expirationDateTextField:MUITextField  = null;  
    
    private addDocumentButton:MUIButton        = null; 
    private exportButton:MUIButton             = null; 

    private newTaxQuantity = null;

    private totalBaseLabel:MUILabel           = null;
    private totalTaxLabel:MUILabel            = null;
    private totalLabel:MUILabel               = null;

    private containerView:MUIView = null;

    private activityIndicator:MUIActivityIndicatorView        = null;

    
    private saveButton:MUIButton               = null;
    private deleteButton:MUIButton             = null;
    private receptionDateButton:MUIButton      = null;
    private expirationDateButton:MUIButton      = null;

    private newLineView:MUIView              = null;

    private filterProductVC          = null;
    private taxCache = {};
    private taxNameCache = {};
    private snsv = null;

    private ad = MUIWebApplication.sharedInstance().delegate;

    private df:MIODateFormatter = this.ad.dateFormatter;
    private cf:MIONumberFormatter = this.ad.currencyFormatter;
    private nf:MIONumberFormatter = this.ad.numberFormatter;
    
    private _mode = null;

    private invoiceModel = null;
    private groupModel = null;
    private lineModel = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(){
            this.showActions();
        });

        this.exportButton = MUIOutlet(this, 'print-btn','MUIButton');
        this.exportButton.setAction(this, function(){
            AppHelper.sharedInstance().printInvoice(this._invoice);
        });

        //this.statusBarView = MUIOutlet(this, 'iidv_c1_status_bar','MUIView');

        // this.activityIndicator = MUIOutlet(this, 'iidv_activity-indicator','MUIActivityIndicatorView');
        // this.activityIndicator.setHidden(true);

        this.editHeader = MUIOutlet(this, "edit-header-view", "MUIView");
        this.editHeader.setHidden(true);

        this.noEditHeader = MUIOutlet(this, "no-edit-header-view", "MUIView");
        this.noEditHeader.setHidden(false);

        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.clientLabel = MUIOutlet(this, "client-lbl", "MUILabel");
        this.nextDocumentIDLabel = MUIOutlet(this, "next-document-id-lbl", "MUILabel");


        // this.receptionDateButton = MUIOutlet(this, 'iidv_mb1_reception_date_btn','MUIButton');
        // this.receptionDateButton.setAction(this, function(){
        //     let dp = new MUIDatePickerController();
        //     dp.init();        
        //     dp.delegate = this;
        //     dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

        //     let pc = dp.popoverPresentationController;
        //     pc.sourceRect = this.receptionDateButton.frame;
        //     pc.sourceView = this.receptionDateButton;

        //     this.presentViewController(dp, true);
        // });

        // this.expirationDateButton = MUIOutlet(this, 'iidv_mb2_expiration_date_btn','MUIButton');
        // this.expirationDateButton.setAction(this, function(){
        //     let dp = new MUIDatePickerController();
        //     dp.init();        
        //     dp.delegate = this;
        //     dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

        //     let pc = dp.popoverPresentationController;
        //     pc.sourceRect = this.expirationDateButton.frame;
        //     pc.sourceView = this.expirationDateButton;

        //     this.presentViewController(dp, true);
        // });

        // this.titleLabel = MUIOutlet(this, 'iidv_nb_invoice_lbl','MUILabel');
        // this.addLabel = MUIOutlet(this, 'iidv_tickets_add_title','MUILabel');
        // this.documentIDLabel = MUIOutlet(this, 'iidv_mb1_invoice_num_lbl', 'MUILabel');
        // this.numTextField = MUIOutlet(this, 'iidv_mb1_invoice_num_text', 'MUITextField');

        // if(this._mode == InvoiceType.OutcomingInvoice) {
        //     this.titleLabel.text = MIOLocalizeString("ISSUED INVOICE DETAIL", "Issued Invoice Detail");
        //     this.addLabel.text = 'Tickets';
        //     this.documentIDLabel.setHidden(false);
        //     this.numTextField.setHidden(true);
        //     this.invoiceModel = 'IssuedInvoice';
        //     this.groupModel   = 'IssuedInvoiceGroupLine';
        //     this.lineModel    = 'IssuedInvoiceLine';
        // }
        // else
        // {
        //    this.titleLabel.text = MIOLocalizeString("SUPPLIER INVOICE DETAIL", "Supplier invoice detail");
        //    this.addLabel.text = MIOLocalizeString("SUPPLIER NOTES", "Supplier notes");
        //    this.documentIDLabel.setHidden(true);
        //    this.numTextField.setHidden(false);
        //    this.invoiceModel = 'SupplierInvoice';
        //    this.groupModel   = 'SupplierInvoiceGroupLine';
        //    this.lineModel    = 'SupplierInvoiceLine';
        // }        

        // this.deleteButton = MUIOutlet(this, 'iidv_nb_delete_btn','MUIButton');
        // this.deleteButton.setAction(this, function() {
        //    this.deleteInvoice();
        // });

        // this.statusComboBox = MUIOutlet(this, 'iidv_nb_status_cb', 'MUIComboBox');
        // this.statusComboBox.addItem(MIOLocalizeString('PENDING','Pending'), false);
        // this.statusComboBox.addItem(MIOLocalizeString('CLOSED','Closed'), true);
        // this.statusComboBox.setOnChangeAction(this, function(combobox, value){
        //     this._invoice.setValueForKey(value, 'closed');
        // });

        // this.payMethodComboBox = MUIOutlet(this, 'iidv_mb2_pay_method_combobox','MUIComboBox');
        // this.payMethodComboBox.setOnChangeAction(this, function(combobox, value) {
        //     this._invoice.setValueForKey('paymethodname', this.payMethodComboBox.getSelectedItemText());
        //     this._invoice.setValueForKey('paymethodid', this.payMethodComboBox.getSelectedItem());   
        // });
        
        // this.dateTextField = MUIOutlet(this, 'iidv_mb1_reception_date_lbl','MUITextField');
        // this.dateTextField.formatter = this.df;
        // this.dateTextField.setOnChangeText(this, function(textfield, value){
        //     let date = this.df.dateFromString(this.dateTextField.text);
        //     this._invoice.invoiceDate = date;
        // });
        
        // this.expirationDateTextField = MUIOutlet(this, 'iidv_mb2_expiration_date_lbl','MUITextField');
        // this.expirationDateTextField.formatter = this.df;
        // this.expirationDateTextField.setOnChangeText(this, function(textfield, value){
        //     let date = this.df.dateFromString(this.expirationDateTextField.text);
        //     this._invoice.invoiceExpirationDate = date;
        // });
       
        // this.receiptNameLabel                = MUIOutlet(this, 'iidv_mb3_receipt_name_lbl','MUILabel');
        // this.receiptPhoneLabel               = MUIOutlet(this, 'iidv_mb3_phone_number_lbl','MUILabel');
        // this.receiptImage                    = MUIOutlet(this, 'iidv_mb3_receipt_image','MUIView');
        
        // this.addDocumentButton = MUIOutlet(this, 'iidv_tickets_add_button', 'MUIButton');
        // this.addDocumentButton.setAction(this, function() {
        //     if(this._invoice.valueForKey('isArchived') == true) return;
        //     let pc = null;
        //     if(this._mode == InvoiceType.OutcomingInvoice)
        //     {
        //         let vc = new SelectTicketViewController('select_view');
        //         vc.initWithResource('layout/base/SelectView.html');
        //         vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;
                
        //         pc = vc.popoverPresentationController;
        //         pc.sourceRect = this.addDocumentButton.frame;
        //         pc.sourceView = this.addDocumentButton;
                
        //         vc.delegate = this;            
        //         this.presentViewController(vc, true); 
        //     }
        //     else
        //     {    
        //         let vc = new SelectDeliveryNoteViewController('select_view');
        //         vc.supplier = this._invoice.receiptid;
        //         vc.initWithResource('layout/base/SelectView.html');
        //         vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;
                
        //         pc = vc.popoverPresentationController;
        //         pc.sourceRect = this.addDocumentButton.frame;
        //         pc.sourceView = this.addDocumentButton;
                
        //         vc.delegate = this;            
        //         this.presentViewController(vc, true); 
        //     }           
        // });

        // this.newConceptTextField = MUIOutlet(this, 'iidv_alc_concept_text','MUITextField');        
        // this.newConceptTextField.setOnChangeText(this, function(textfield, value) {
            
        //     clearTimeout(this.delayTimer);
        //     var instance = this;
            
        //     instance.delayTimer = setTimeout(function() {
               
        //        if(!instance._filterProductVC) {
        //            instance._filterProductVC = new SelectProductViewController('select_product_view');
        //            instance._filterProductVC.initWithResource('layout/modals/selectProduct/SelectProductView.html');
        //            instance._filterProductVC.modalPresentationStyle = MUIModalPresentationStyle.Popover;
               
        //            var filterProductVC = instance._filterProductVC.popoverPresentationController;
        //            filterProductVC.sourceRect = instance.newConceptTextField.frame;
        //            filterProductVC.sourceView = instance.newConceptTextField;
        //            instance._filterProductVC.mode = ProductSearchMode.Product;
        //        }
        //        instance.filterProductVC.searchString = value;
               
        //        instance.filterProductVC.delegate = instance;

        //        if(!instance.filterProductVC.isPresented)
        //            instance.presentViewController(instance.filterProductVC, true);
                  
        //     }, 500, instance);
        // });

        // this.newTaxComboBox                = MUIOutlet(this, 'iidv_alc_tax_combobox','MUIComboBox');

        
        // this.newTaxQuantity = null;
    
        // this.newUnitPriceTextField          = MUIOutlet(this, 'iidv_alc_unitary_price_text','MUITextField');
        // this.newUnitPriceTextField.formatter = this.cf;
        // this.newUnitPriceTextField.setOnEnterPress(this, function()
        // {
        //     this.addNewLine();
        // });
        // this.newQuantityTextField           = MUIOutlet(this, 'iidv_alc_quantity_text','MUITextField');
        // this.newQuantityTextField.formatter = this.nf;
        // this.newQuantityTextField.setOnEnterPress(this, function() {
        //     this.addNewLine();
        // });
        // this.newPriceTextField              = MUIOutlet(this, 'iidv_alc_price_text','MUITextField');
        // this.newPriceTextField.formatter = this.cf;
        // this.newPriceTextField.setHidden(true);

        // this.newDiscountTextField = MUIOutlet(this, 'iidv_alc_discount_text','MUITextField');
        // this.newDiscountTextField.formatter = this.cf;
        // this.newDiscountTextField.setOnEnterPress(this, function()
        // {
        //     this.addNewLine();
        // });

        // this.newLineView                    = MUIOutlet(this, 'iidv_c2_add_line_cell','MUIView');
        // this.newLineView.setHidden(true);
        
        this.tableView                      = MUIOutlet(this, 'table-view', 'MUITableView');
        this.tableView.dataSource           = this;
        this.tableView.delegate             = this;
        
        this.totalBaseLabel = MUIOutlet(this, 'total-base-lbl','MUILabel');
        this.totalTaxLabel = MUIOutlet(this, 'total-tax-lbl','MUILabel');
        this.totalLabel = MUIOutlet(this, 'total-lbl','MUILabel');

        // this.saveButton = MUIOutlet(this, 'iidv_nb_save_btn','MUIButton');
        // this.saveButton.setAction(this, function(){
        //     this.saveInvoice();
        // });

    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        
        // this._product = null;        
        // DBHelper.sharedInstance().addObserverForChangesInEntity(this, "Tax", null, null, null, function (objects) {
        //     AppHelper.fillTaxComboBox(this.newTaxComboBox, objects);
        // });
        // DBHelper.sharedInstance().addObserverForChangesInEntity(this, "PayMethod", null, null, null, function (objects) {
        //     AppHelper.fillPayMethodComboBox(this.payMethodComboBox, objects, this._invoice.paymethodid);
        // });
        
        this.updateUI();
    }

    viewWillDisappear(){
        super.viewWillDisappear();
        // DBHelper.sharedInstance().removeObserverForChangesInEntity(this, 'Tax');
        // DBHelper.sharedInstance().removeObserverForChangesInEntity(this, 'PayMethod');
    }
    
    set mode(value){
        this._mode = value;
    }

    private _invoice:ArchivedDocument = null;
    get invoice(){ return this._invoice;}
    set invoice(value){
        if (this._invoice == value) return;
        this._invoice = value;
        this._product = null;
        this.updateUI();
    }

    private ldtf = MUIWebApplication.sharedInstance().delegate.longDateTimeFormatter as MIODateFormatter;
    private updateUI(){
        if(!this.viewIsLoaded || this._invoice == null) return;

        // if (this._invoice.isArchived == true) {
        //     this.statusBarView.setBackgroundRGBColor(0, 191, 191);
        //     this.containerView.layer.style.top = '136px';
        // }
        // else {
        //     this.statusBarView.setBackgroundRGBColor(255, 84, 112);
        //     this.containerView.layer.style.top = '224px';
        // }

        //this.addDocumentButton.setHidden(this._invoice.isArchived);         

        this.documentIDLabel.text = this._invoice.documentID;
        this.dateLabel.text = this.ldtf.stringFromDate(this._invoice.date);
        this.clientLabel.text = this._invoice.ticketLegalEntityName;
        this.nextDocumentIDLabel.text = this._invoice.nextDocument != null ? this._invoice.nextDocument.documentID : null;
        
        // this.receiptNameLabel.text = this._invoice.receiptName;
        // //this.receiptPhoneLabel.text = this._invoice.receiptPhone;
        
        // this.dateTextField.text = this.df.stringFromDate(this._invoice.date);
        //this.expirationDateTextField.text =this.df.stringFromDate(this._invoice.invoiceExpirationDate);// this._invoice.shortDate('expirationdate');

        //this.statusComboBox.selectItem(this._invoice.valueForKey('closed'));        
        
        // this.newLineView.setHidden(this._invoice.isArchived);
        // this.saveButton.setHidden(this._invoice.isArchived);
        // this.deleteButton.setHidden(this._invoice.isArchived);
        // this.statusComboBox.setHidden(this._invoice.isArchived);  
        
        this.fetchedResultsController = null;
        this.tableView.reloadData();   
        
        this.updateTotals();
    }

    private reloadInvoices(identifier:string){

        //this is called to update all parts of the manager related to the changes made (updates listView)
        let predicate = MIOPredicate.predicateWithFormat('identifier = ' + identifier);
        let relationships = ["nextDocument"];
        DBHelper.queryObjectsWithCompletion("ArchivedDocument", null, predicate, relationships, this, function(objects){
            if (objects.length > 0) {
                let invoice = objects[0];
                this._invoice = invoice;
            }
        });
    }

    private updateTotals(){
        this.totalBaseLabel.text = this.cf.stringFromNumber(this._invoice.totalPriceBase);
        this.totalTaxLabel.text  = this.cf.stringFromNumber(this._invoice.totalTaxes);
        this.totalLabel.text     = this.cf.stringFromNumber(this._invoice.totalPrice);
    }
    
    // Select view controller delegate
    didSelectItemFromSelectViewController(controller, item){    
        if(this._mode == InvoiceType.IncomingInvoice)
            this.addLinesFromDeliveryNote(item);
        else
            this.addLinesFromTicket(item);
    }


    private addLinesFromTicket(ticket){
        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;
       /* this._activityIndicator.setHidden(false);
        ws.addTicketToDocument(ad.selectedPlace, this._invoice, ticket , this, function(code, json){
           if(code == 200) 
           {
              var data = json['data'];
              for(let i in data)
              {
                var p:IssuedInvoiceGroupLine | SupplierInvoiceGroupLine = MIOEntityDescription.insertNewObjectForEntityForName(this.groupModel, ad.managedObjectContext) as IssuedInvoiceGroupLine | SupplierInvoiceGroupLine;
                data[i]['invoice'] = this.invoice;
                p.setObject(data[i],this._invoice.groups.length, ad.managedObjectContext);
                this._invoice.add_group(p);   
                if(p.parent != null)
                {
                    let invoicePriceBase = this._invoice.totalpricebase;
                    this._invoice.totalpricebase =  invoicePriceBase + p.totalpricebase;
                    
                    let invoiceTax = this._invoice.totaltaxes;
                    this._invoice.totaltaxes =  invoiceTax + p.totaltaxes;
                    
                    let invoicePrice = this._invoice.totalprice;
                    this._invoice.totalprice  = invoicePrice + p.totalprice;
                    
                    let invoiceDiscount = this._invoice.totaldiscount;
                    this._invoice.totaldiscount = invoiceDiscount + p.totaldiscount;
                }
              }

              //Elimino el ticket de los filtrados para que no se vuelva a mostrar
              let filteredTickets = ad.managedObjectContext.queryObject('FilterTicket','identifier == "' + ticket.identifier + '"');
              for(let i in filteredTickets)
              {
                ad.managedObjectContext.deleteObject(filteredTickets[i]);
              }
              ad.managedObjectContext.save();
              this._calculateTotals();
              this._saveInvoice();
           }
           else
           {
                var avc = new MUIAlertViewController();
                avc.initWithTitle(MIOLocalizeString('ERROR', 'Error'), json['error'], MUIAlertViewStyle.Default);
                avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Default, this, function(){}));
                
                this.presentViewController(avc, true);
           }
           this._activityIndicator.setHidden(true);
        });*/
    }

    private addLinesFromDeliveryNote(item)
    {
        var ad = MUIWebApplication.sharedInstance().delegate;
        
       /* let totals = item.totals;
        let taxIds = Object.keys(totals);

        for(let i in taxIds)
        {
            if(taxIds[i] == 'total') continue;
            
            let taxId = taxIds[i];
            let taxLine = totals[taxId];
            let productName = (this._mode == InvoiceType.IncomingInvoice ? 'Albaran: ' + item.noteIdString : 'Ticket: ' + item.num);
            var group = {}; 
            group['invoice'] = this._invoice;
            group['noteid'] = item.identifier;
            group['productname'] = productName;
            group['productprice'] = taxLine['productPrice'];
            group['taxname'] = this._taxNameCache[taxId] || taxLine['name'];
            group['tax'] = this._taxCache[taxId] || taxLine['name'];
            group['quantity'] = 1;
            group['discount'] = 0;
            group['totalpricebase'] = taxLine['base'];
            group['totaltaxes']    = taxLine['tax'];
            group['totalprice']    = taxLine['total'];
            group['totaldiscount'] = taxLine['discount'] || 0;

            let invoicePriceBase = this._invoice.totalpricebase;
            this._invoice.totalpricebase =  invoicePriceBase + group['totalpricebase'];
        
            let invoiceTax = this._invoice.totaltaxes;
            this._invoice.totaltaxes = invoiceTax + group['totaltaxes'];
            
            let invoicePrice = this._invoice.totalprice;
            this._invoice.totalprice = invoicePrice + group['totalprice'];
            
            let invoiceDiscount = this._invoice.totaldiscount;
            this._invoice.totaldiscount = invoiceDiscount + group['totaldiscount'];

            var p:IssuedInvoiceGroupLine | SupplierInvoiceGroupLine  = MIOEntityDescription.insertNewObjectForEntityForName(this.groupModel, ad.managedObjectContext) as IssuedInvoiceGroupLine | SupplierInvoiceGroupLine ;
            p.setObject(group,this._invoice.groups.length, ad.managedObjectContext);
           
            let d = new Date();
            var orderindex =  d.getTime();
            p['id'] = orderindex;

            let line = {};
            
            line['group'] = p;
            line['date']           = MIODateGetDateString(d);
            line['status']         = '1';
            line['tax']            = group['tax'];
            line['taxname']        = group['taxname'];
            line['workername']     = group['workername'];        
            line['orderindex']     = orderindex++;
            
            line['totalpricebase'] = group['totalpricebase'];
            line['totaltaxes']     = group['totaltaxes'];
            line['totalprice']     = group['totalprice'];
            line['totaldiscount']  = group['totaldiscount'];
            line['totalinvited']   = 0;
            line['totaldeleted']   = 0;

            var l:IssuedInvoiceLine | SupplierInvoiceLine  = MIOEntityDescription.insertNewObjectForEntityForName(this.lineModel, ad.managedObjectContext) as IssuedInvoiceLine | SupplierInvoiceLine;
            l.setObject(line, i, ad.managedObjectContext);
            p.lines.addObject(l);
            this._invoice.group.addObject(p);        
        }

        let deliveryNote = ad.managedObjectContext.queryObject('SupplierNote','identifier == "' + item.identifier + '"');
        for(let i in deliveryNote)
        {
            deliveryNote[i].setValue('archivedInvoiceID', this._invoice.identifier);
        }
        ad.managedObjectContext.save();
        */
    }

    private addNewLine()
    {
        // let tax = this.newTaxComboBox.getSelectedItem();
        // if(!tax) {
            
        //     AppHelper.showErrorMessage(this, 'Error', 'Please, select tax');
            
        //     return;
        // }

        var ad = MUIWebApplication.sharedInstance().delegate;
        var p:IssuedInvoiceGroupLine | SupplierInvoiceGroupLine = MIOEntityDescription.insertNewObjectForEntityForName(this.groupModel, ad.managedObjectContext) as IssuedInvoiceGroupLine | SupplierInvoiceGroupLine;
        var group = {}; 
        group['invoice'] = this._invoice;
        // group['productname'] = this.newConceptTextField.text;
        // let unitprice =  this.cf.numberFromString(this.newUnitPriceTextField.text);
        // group['productprice'] = unitprice;
        // group['productid'] = this._product ? this._product.identifier : null;
        // group['categoryname'] = this._product ? this._product.category.name : null;
        // group['taxname'] = this.newTaxComboBox.getSelectedItemText(); 
        // group['tax'] = this.taxCache[tax];
        // group['rate'] = null;
        // group['tags'] = null;
        // let quantity = this.cf.numberFromString(this.newQuantityTextField.text);
        // let discount = this.cf.numberFromString(this.newDiscountTextField.text) || 0;
        // group['quantity'] = quantity;
        // group['discount'] = discount;
        // let price = quantity * unitprice;

        // group['totaldiscount'] = discount;
        // group['totalprice']    = price - discount;
        // group['totalpricebase'] = group['totalprice'] / (1+ group['tax']);
        // group['totaltaxes']    = group['totalprice'] - group['totalpricebase'];

        let invoicePriceBase = this._invoice.valueForKey('totalpricebase');
        this._invoice.setValueForKey( invoicePriceBase + group['totalpricebase'], 'totalpricebase' );
        
        let invoiceTax = this._invoice.valueForKey('totaltaxes');
        this._invoice.setValueForKey(invoiceTax + group['totaltaxes'],'totaltaxes' );
        
        let invoicePrice = this._invoice.valueForKey('totalprice');
        this._invoice.setValueForKey(invoicePrice + group['totalprice'],'totalprice');
        
        let invoiceDiscount = this._invoice.valueForKey('totaldiscount');
        this._invoice.setValueForKey( invoiceDiscount + group['totaldiscount'], 'totaldiscount');
        

        //p.setObject(group,this._invoice.groups.length, ad.managedObjectContext);
        let d = new Date();
        p['id'] = d.getTime();

        // for(let i = 0; i < quantity; i++)
        // { 
        //     let line = {};
            
        //     line['group'] = p;
        //     line['date']           = MIODateGetDateString(d);
        //     line['status']         = '1';
        //     line['tax']            = group['tax'];
        //     line['taxname']        = group['taxname'];
        //     line['workername']     = group['workername'];        
        //     line['orderindex']     = i;
        //    // line['discount']       = group['discount'] || 0;
        //     line['totaldiscount']  = discount / quantity;
        //     line['totalprice']     = unitprice - line['totaldiscount'];//line['totalpricebase'] * ( 1 + group['tax']);
        //     line['totalpricebase'] = line['totalprice']  / (1+ line['tax']);
        //     line['totaltaxes']     = line['totalprice'] - line['totalpricebase'];
        //     if(this._product) {
        //         line['tags'] = this._product.valueForKey('tags');
        //     }
            
        //     line['totalinvited']   = 0;
        //     line['totaldeleted']   = 0;

        //     var l:IssuedInvoiceLine | SupplierInvoiceLine = MIOEntityDescription.insertNewObjectForEntityForName(this.lineModel, ad.managedObjectContext) as  IssuedInvoiceLine | SupplierInvoiceLine;
        //     l.setObject(line, i, ad.managedObjectContext);
        //     //p.lines.addObject(l);
        //}

        // this._invoice.groups.addObject(p);        
        // ad.managedObjectContext.save();

        // this.newConceptTextField.text   = null;
        // this.newTaxComboBox.selectItem('');
        // this.newUnitPriceTextField.text = null;
        // this.newQuantityTextField.text  = null;
        // this.newPriceTextField.text     = null;
        // this.newDiscountTextField.text  = null
        // this.newConceptTextField.becomeFirstResponder();
    }

    private _calculateTotals()
    {
        var grouplines = this._invoice.valueForKey('groups');
        var totalTax = 0;
        var totalBase = 0;
        var totalDiscount = 0;
        var total = 0

        for(var i = 0; i < grouplines.length; i++) {
            if(grouplines[i].valueForKey('noteid') != null) continue;
            let base = grouplines[i].valueForKey('totalpricebase');
            let tax = grouplines[i].valueForKey('totaltaxes');
            let discount = grouplines[i].valueForKey('totaldiscount');
            totalBase += base;
            totalTax += tax;
            totalDiscount += discount;
        }

        total = totalBase + totalTax;
        // this._invoice.valueForKey('totalpricebase', totalBase);
        // this._invoice.valueForKey('totaltaxes', totalTax);
        // this._invoice.valueForKey('totaldiscount', totalDiscount);
        // this._invoice.valueForKey('totalprice', total);

        this.updateTotals();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    titleForHeaderInSection(tableView:MUITableView, section:number) {
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip); 
        if (item.wasCloned == true) return MIOLocalizeString("Previous documents".toUpperCase(), "Previous documents")
        else if(item.wasCloned == false) return MIOLocalizeString("Current documents".toUpperCase(), "Current documents")
        else return "";
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('LineCell') as InvoiceGroupCell;    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ArchivedTicketLinesGroup;
    
        cell.item = item;      
                    
        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {

        let invoiceline = this.fetchedResultsController.objectAtIndexPath(indexPath);    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeInvoiceLine(invoiceline);
        }
    }
    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('ArchivedTicketLinesGroup'); 
        fetchRequest.sortDescriptors = [    MIOSortDescriptor.sortDescriptorWithKey('wasCloned', false),
                                            MIOSortDescriptor.sortDescriptorWithKey('orderindex', true)];    
        fetchRequest.relationshipKeyPathsForPrefetching = ["lines", "document"];
        
        //add predicate for returns?? Negative only if the condition is met?
        // if (this._invoice.modifiedType == 1) 
            fetchRequest.predicate = MIOPredicate.predicateWithFormat('document.identifier == ' + this._invoice.identifier);
        // else
        //     fetchRequest.predicate = MIOPredicate.predicateWithFormat('document.identifier == ' + this._invoice.identifier);

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "wasCloned");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();        
    }

    productDidSelected(product)
    {
        this._product = product;
        // this.newConceptTextField.text = product.name;
        // this.newQuantityTextField.text = '';
        // this.newUnitPriceTextField.text = product.price;
        // if(product.tax) {
        //     this.newTaxComboBox.selectItem(product.tax.id);
        //     this.newTaxQuantity = product.tax.taxquantity;
        // }
    }

    private removeInvoiceLine(invoiceline)
    {
        /*let ad = MUIWebApplication.sharedInstance().delegate;
        let removeLines;
        var modelName;
        var fieldName;
        if(this._invoice.isincominginvoice == true)
        {
            modelName = 'SupplierNote';
            fieldName = 'archivedInvoiceID';
        }
        else
        {
            modelName = 'Ticket';
            fieldName = 'nextdocument';
        }

        if(invoiceline.valueForKey('noteid') == null || this._invoice.isincominginvoice == false)
            removeLines = ad.managedObjectContext.queryObject(this.groupModel, 'id == "' + invoiceline.valueForKey('id') +'" or parent == "' + invoiceline.valueForKey('id') +'"');
        else 
            removeLines = ad.managedObjectContext.queryObject(this.groupModel, 'noteid == ' + invoiceline.valueForKey('noteid'));
            
        for(let i = 0; i < removeLines.length; i++) {
            if(removeLines[i].valueForKey('noteid') != null)
            {
                var items = ad.managedObjectContext.queryObject(modelName,'identifier == "' + removeLines[i].valueForKey('noteid') + '"');

                if(items && items.length > 0) {
                    let item = items[0];
                    item.setValueForKey(null, fieldName);
                }
            }
            
            this._invoice.groups.removeObject(removeLines[i]);
            ad.managedObjectContext.deleteObject(removeLines[i]);
        }
        this._calculateTotals();
        ad.managedObjectContext.save();
        */
    }

    private saveInvoice()
    {
        var ad = MUIWebApplication.sharedInstance().delegate;
        var place = ad.selectedPlace;

        /*
        if(this._mode == InvoiceType.IncomingInvoice)
            this._invoice.setValue('num',this._numTextField.text);

        this._activityIndicator.setHidden(false);
        ad.webService.updateDocument(place, this._invoice, this, function (code, data) 
        {
            if(code == 200) {

                for(let group in this._invoice.groups)
                {
                    this._invoice.remove_group(this._invoice.groups[group]);
                    ad.managedObjectContext.deleteObject(this._invoice.groups[group]);
                }
                //ad.managedObjectContext.deleteObject(this._invoice);
                
                ad.managedObjectContext.save();

                //var p = MIOEntityDescription.insertNewObjectForEntityForName(this.invoiceModel, ad.managedObjectContext);
                this._invoice.setObject(data['data'], ad.managedObjectContext);
               
                //Si es una factura de entrada y todo ha ido bien, marco los albaranes
                //con la factura.

                if(this._mode == InvoiceType.IncomingInvoice)
                    this.setSupplierNotesWithInvoice();
                
                //this.invoice = p;
                ad.managedObjectContext.save();
            }
            else {
                var avc = new MUIAlertViewController();
                avc.initWithTitle(MIOLocalizeString('ERROR', 'Error'), data['error'], MUIAlertViewStyle.Default);
                avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Default, this, function(){}));
                this.presentViewController(avc, true);
            }
            this._activityIndicator.setHidden(true);
        }, ad.managedObjectContext);
        */
    }

    private setSupplierNotesWithInvoice()
    {
        /*let ad = MUIWebApplication.sharedInstance().delegate;
        
        let groups = this._invoice.getValue('groups');
        for(let i in groups)
        {
            if(groups[i].getValue('noteid') != null)
            {
                let supplierNote = ad.managedObjectContext.queryObject('SupplierNote','identifier == "' + groups[i].getValue('noteid') + '"');
                if(supplierNote && supplierNote.length > 0) {
                    let note = supplierNote[0];
                    note.setValue('archivedInvoiceID', this._invoice.identifier);
                }
            }
        }*/
    }

    private deleteInvoice()
    {/*
        if(!this._invoice) return;

        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;

        this._activityIndicator.setHidden(false);
        ws.deleteDocument(ad.selectedPlace, this._invoice, this, function(code, json){
           if(code == 200) 
           {
               for(let group in  this._invoice.groups) 
               {
                   for(let line in this._invoice.groups[group].lines)
                   {
                       ad.managedObjectContext.deleteObject(this._invoice.groups[group].lines[line]);
                   }
                   ad.managedObjectContext.deleteObject(this._invoice.groups[group]);
               }
              ad.managedObjectContext.deleteObject(this._invoice);
              this._invoice = null;
              ad.managedObjectContext.save();
           }
           else
           {
               var avc = new MUIAlertViewController();
               avc.initWithTitle(MIOLocalizeString('ERROR', 'Error'), 'Error deleting document', MUIAlertViewStyle.Default);
               avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Default, this, function()
               {
                
               }));
               this.presentViewController(avc, true);
           }
           this._activityIndicator.setHidden(true);
        });
        */
    }

    didSelectDate(datePickerController:MUIDatePickerController, date)
    {
        if(datePickerController.delegate == this.receptionDateButton) 
        {
            this.dateTextField.text = this.df.stringFromDate(date);
            //this._invoice.invoiceDate = date;
        }
        else 
        {
             //this._invoice.invoiceExpirationDate = date;
             this.expirationDateTextField.text =this.df.stringFromDate(date);
        }
    }

    private showActions(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle("Actions", "Choose action", MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle('Change Client', MUIAlertActionStyle.Default, this, function(){
            this.changeClient();
        }));

        if(this._invoice.modifiedType == 0 && this._invoice.nextDocument == null)
        avc.addAction(MUIAlertAction.alertActionWithTitle("Nullify", MUIAlertActionStyle.Destructive, this, function(){
            this.nullifyInvoice();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private nullifyInvoice(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.nullifyInvoice(this._invoice.identifier, this, function(code, status){
            if (code == 200){
                AppHelper.showAlert(this, "Information", "Nullify invoice was create");
                this.reloadInvoices(this._invoice.identifier);
            }
            else {
                AppHelper.showAlert(this, "Error", "Unable to create the nullify invoice");
            }
        });
    }

    private changeClient(){
        let vc = AppHelper.sharedInstance().selectViewController("Client", "name", false, false, null, this, null) as SelectEntityViewController;
        vc.allowSearch = true;
        this.presentViewController(vc, true);
    }

        //
    // SelectEntityViewControllerDelegate
    //

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "Client"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Client", sd, null);
            fetchRequest.fetchLimit = 50;
            fetchRequest.relationshipKeyPathsForPrefetching = ['address', 'mobilePhone', 'phone'];

            return fetchRequest;
        }

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){
        
        let dismiss = true;

        if (controller.identifier == "Client"){
            let client = item as Client;
            this._invoice.ticketLegalEntity = client;
            this._invoice.ticketLegalEntityDocumentID = client.document;
            this._invoice.ticketLegalEntityName = client.name;            
            this._invoice.ticketLegalEntityAddress = client.address.address1;

            let addr = [];
            // if (client.address.countryName) addr.push(client.address.countryName);
            if (client.address.countryISOA2) addr.push( getCountryName( client.address.countryISOA2 ) );
            if (client.address.state) addr.push("(" + client.address.state + ")");
            if (client.address.postalCode) addr.push(client.address.postalCode);
            if (client.address.city) addr.push(client.address.city);                                    

            this._invoice.ticketLegalEntityAddress2 = addr.join(" ");

            DBHelper.saveMainContext();
        }

        return dismiss;
    }
    
}