

// Generated class IntegratorsConfig_ManagedObject

class IntegratorsConfig_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }

    // Property: enviroment
    set enviroment(value:string) {
        this.setValueForKey(value, 'enviroment');
    }
    get enviroment():string {
        return this.valueForKey('enviroment');
    }
    set enviromentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'enviroment');
    }
    get enviromentPrimitiveValue():string {
        return this.primitiveValueForKey('enviroment');
    }

    // Property: integrator
    set integrator(value:string) {
        this.setValueForKey(value, 'integrator');
    }
    get integrator():string {
        return this.valueForKey('integrator');
    }
    set integratorPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integrator');
    }
    get integratorPrimitiveValue():string {
        return this.primitiveValueForKey('integrator');
    }

    // Property: mappingEntityName
    set mappingEntityName(value:string) {
        this.setValueForKey(value, 'mappingEntityName');
    }
    get mappingEntityName():string {
        return this.valueForKey('mappingEntityName');
    }
    set mappingEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'mappingEntityName');
    }
    get mappingEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('mappingEntityName');
    }

    // Property: ownEntityName
    set ownEntityName(value:string) {
        this.setValueForKey(value, 'ownEntityName');
    }
    get ownEntityName():string {
        return this.valueForKey('ownEntityName');
    }
    set ownEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ownEntityName');
    }
    get ownEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('ownEntityName');
    }

    // Property: endpoint
    set endpoint(value:string) {
        this.setValueForKey(value, 'endpoint');
    }
    get endpoint():string {
        return this.valueForKey('endpoint');
    }
    set endpointPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'endpoint');
    }
    get endpointPrimitiveValue():string {
        return this.primitiveValueForKey('endpoint');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: groupByReference
    set groupByReference(value:boolean) {
        this.setValueForKey(value, 'groupByReference');
    }
    get groupByReference():boolean {
        return this.valueForKey('groupByReference');
    }
    set groupByReferencePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'groupByReference');
    }
    get groupByReferencePrimitiveValue():boolean {
        return this.primitiveValueForKey('groupByReference');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
