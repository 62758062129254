
interface CashDeskSessionListCellDelegate {
    closeSession(cell:CashDeskSessionListCell, sessionID:string);
}

class CashDeskSessionListCell extends MUITableViewCell
{
    delegate = null;

    private nameLabel:MUILabel = null;
    private beginLabel:MUILabel = null;
    private endLabel:MUILabel = null;
    private beginWorkerLabel:MUILabel = null;
    private endWorkerLabel:MUILabel = null;
    private totalLabel:MUILabel = null;
    private exportButton:MUIButton = null;
    private statusView:MUIView = null;

    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;    

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
        this.beginLabel = MUIOutlet(this, 'open-at-lbl','MUILabel');
        this.endLabel = MUIOutlet(this, 'close-at-lbl','MUILabel');
        this.beginWorkerLabel = MUIOutlet(this, 'open-by-lbl','MUILabel');
        this.endWorkerLabel = MUIOutlet(this, 'close-by-lbl','MUILabel');
        this.totalLabel = MUIOutlet(this, 'total-lbl','MUILabel');

        this.exportButton = MUIOutlet(this, 'export-btn','MUIButton');
        this.exportButton.setAction(this, function(){
           let ad = MUIWebApplication.sharedInstance().delegate;
           let ws = ad.webService;
           ws.exportPDFCashDeskSession(this._item , null, null);
        });

        this.statusView = MUIOutlet(this, "status-view", "MUIView");        
    }

    private _item:CashDeskSession = null;
    set item(i:CashDeskSession){
        this._item = i;

        this.nameLabel.text = i.name;
        this.beginLabel.text = this.dtf.stringFromDate(i.beginDate);
        this.endLabel.text = this.dtf.stringFromDate(i.endDate);
        this.beginWorkerLabel.text = i.beginWorkerName;
        this.endWorkerLabel.text = i.endWorkerName;        
        this.totalLabel.text = i.sessionBalance != null ? this.cf.stringFromNumber(i.sessionBalance.amount) : this.cf.stringFromNumber(0);

        if (i.isOpen == true) {
            MUICoreLayerRemoveStyle(this.layer, "closed");
            MUICoreLayerAddStyle(this.layer, "open");            
            this.exportButton.hidden = true;
        }
        else if (i.isCashCounted == false) {
            MUICoreLayerRemoveStyle(this.layer, "open");
            MUICoreLayerAddStyle(this.layer, "closed");
            this.exportButton.hidden = false;
        }
        else {
            MUICoreLayerRemoveStyle(this.layer, "open");
            MUICoreLayerRemoveStyle(this.layer, "closed");
            this.exportButton.hidden = false;
        }
        
    }
}

