//
// Generated class StockProduct
//

/// <reference path="StockProduct_ManagedObject.ts" />

class StockProduct extends StockProduct_ManagedObject
{

    setObject(item)
    {
        
        this.product = item['product'];
        this.warehouse = item['warehouse'];
        this.name = item['name'];
        this.isAvailable = item['isavailable'];
        this.costPrice = item['costprice'];
        this.measureUnitType = item['measureunittype'];
        this.quantity = item['quantity'];
        this.price = item['price'];
        this.stock = item['stock'];
        this.min = item['min'];
        this.max = item['max'];

    }

    get isContainer()
    {
        return this.quantity > 0 && this.measureUnitType != null;
    }

    get measureType()
    {
        let measureunittype = this.measureUnitType;
        if(measureunittype == MeasureUnitType.Unit)
            return MeasureType.MeasureTypeUnit;
        if(measureunittype == MeasureUnitType.MassGram || measureunittype == MeasureUnitType.MassKilogram)
            return MeasureType.MeasureTypeMass;
        if(measureunittype == MeasureUnitType.VolumeCentilitre || measureunittype == MeasureUnitType.VolumeLitre)
            return MeasureType.MeasureTypeVolume;
                
    }
}
