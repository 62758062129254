
class SupplierNoteListViewController extends NoteListViewController
{
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("SupplierNoteCell") as SupplierNoteCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockNote;

        cell.item = item;     
        cell.selected = this.selectedNote == item ? true : false;   

        return cell;
    }

    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("documentID", false)];
        if (this.selectedStatus == StockNoteStatus.Processed) {
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("creationDate", false)];
        }

        let predicateFormat = "type = " + StockNoteType.SupplierNote + " AND status == " + this.selectedStatus;
        if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
        if(this.searchString != null) {
            predicateFormat += " AND (documentID CONTAINS '" + this.searchString + "' OR externalReference CONTAINS '" + this.searchString + "')";            
		}
		
		if (this.filterPredicateFormat != null) {
            predicateFormat += " AND " + this.filterPredicateFormat;
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName(this.entityName, sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 50;
        fetchRequest.relationshipKeyPathsForPrefetching = ["originEntity"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData(); 
	}
	
	private _filters = null;
    get filters(){
        if (this._filters != null) return this._filters;

		this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "stockDate", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
		FilterOption.filterWithTitle("ToDate", "To day", "stockDate", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),
		FilterOption.filterWithFetchRequest("Destination", "Supplier", "originEntity.identifier", FilterFieldType.DropDown, "Supplier", "name", "identifier", "Select Supplier")];

        return this._filters;
    }
    
    private detailViewController:SupplierNoteDetailViewController = null;
    protected showSelectedItem(){        
        
        if(this.detailViewController == null) {
            this.detailViewController = new SupplierNoteDetailViewController("supplier-note-detail-view");
            this.detailViewController.initWithResource("layout/suppliernote/SupplierNoteDetailView.html");
        }

        this.detailViewController.note = this.selectedNote;
        this.splitViewController.showDetailViewController(this.detailViewController);        
    }

    protected showNoItemSelected(){        
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("SupplierNote", NoItemSelectedViewControllerType.SupplierNote);
        this.splitViewController.showDetailViewController(vc);        
    }
    
    protected showAddNoteViewController(){
        let vc = AppHelper.sharedInstance().selectViewController("Supplier", "name", false, false, null, this, false) as SelectEntityViewController;
        vc.allowSearch = true;
        vc.allowAddEntity = true;
        vc.addTarget = this;
        vc.addBlock = this.addSupplier;        
        this.presentViewController(vc, true);
    }

    //
    // Select entity view controller
    //

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "Supplier"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Supplier", sd, null);
            fetchRequest.fetchLimit = 50;            

            return fetchRequest;
        }

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){        
        
        let dismiss = true;

        if (controller.identifier == "Supplier") {
            let supplier = item as Supplier;
            let note = DBHelper.createSupplierNote(supplier);
            this.selectedNote = note;
            DBHelper.saveMainContext();
            this.showSelectedItem();
        }

        return dismiss;
    }

    private addSupplier(supplier:Supplier){
        let vc = new AddNewSupplierViewController('add-new-supplier-view');
        vc.initWithResource('layout/supplier/AddNewSupplierView.html');            
        vc.delegate = this;
        this.presentViewController(vc, true);
    }

    supplierDidInserted(supplier:Supplier){
        let note = DBHelper.createSupplierNote(supplier);
        this.selectedNote = note;
        DBHelper.saveMainContext();
        this.showSelectedItem();
    }

}