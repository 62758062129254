
class CashDeskSessionDocumentCell extends MUITableViewCell
{
    private dateLabel:MUILabel = null;
    private innoviceIcon:MUIView = null;
    private documentIDLabel:MUILabel = null;
    private nameLabel:MUILabel = null;
    private locationCategoryLabel:MUILabel = null;
    private amountLabel:MUILabel = null;

    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        //this.innoviceIcon = MUIOutlet(this, "inovice-icon", "MUIView");
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel")
        this.locationCategoryLabel = MUIOutlet(this, "location-category-lbl", "MUILabel");
        this.amountLabel = MUIOutlet(this, "total-lbl", "MUILabel");
    }

    set item(item:ArchivedDocument){
        let tf = MUIWebApplication.sharedInstance().delegate.timeFormatter as MIODateFormatter;
        let cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
        
        this.dateLabel.text = tf.stringFromDate(item.date);
        this.documentIDLabel.text = item.legalDocumentID;
        //this.innoviceIcon.setHidden((item.type != "I"));
        if (item.ticketLegalEntityName != null && item.roomName != null) {
            this.nameLabel.text = item.ticketLegalEntityName + " (" + item.roomName + ")";
        }
        else if (item.ticketLegalEntityName != null && item.roomName == null){
            this.nameLabel.text = item.ticketLegalEntityName;
        }
        else if (item.ticketLegalEntityName == null && item.roomName != null){
            this.nameLabel.text = "Room " + item.roomName;
        }
        else {
            this.nameLabel.text = null;
        }
		
		if(item.locationCategoryName != null) {
			this.locationCategoryLabel.text = item.locationCategoryName + " " + item.locationName;
		} else {
			this.locationCategoryLabel.text = "";
		}
        
        this.amountLabel.text = cf.stringFromNumber(item.totalPrice);
    }
}