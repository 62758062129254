

// Generated class PayMethod_ManagedObject

class PayMethod_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: eliminable
    set eliminable(value:boolean) {
        this.setValueForKey(value, 'eliminable');
    }
    get eliminable():boolean {
        return this.valueForKey('eliminable');
    }
    set eliminablePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'eliminable');
    }
    get eliminablePrimitiveValue():boolean {
        return this.primitiveValueForKey('eliminable');
    }

    // Property: isDisabled
    set isDisabled(value:boolean) {
        this.setValueForKey(value, 'isDisabled');
    }
    get isDisabled():boolean {
        return this.valueForKey('isDisabled');
    }
    set isDisabledPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDisabled');
    }
    get isDisabledPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDisabled');
    }

    // Property: needsChange
    set needsChange(value:boolean) {
        this.setValueForKey(value, 'needsChange');
    }
    get needsChange():boolean {
        return this.valueForKey('needsChange');
    }
    set needsChangePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'needsChange');
    }
    get needsChangePrimitiveValue():boolean {
        return this.primitiveValueForKey('needsChange');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: printTicket
    set printTicket(value:boolean) {
        this.setValueForKey(value, 'printTicket');
    }
    get printTicket():boolean {
        return this.valueForKey('printTicket');
    }
    set printTicketPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'printTicket');
    }
    get printTicketPrimitiveValue():boolean {
        return this.primitiveValueForKey('printTicket');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: openCashDrawer
    set openCashDrawer(value:boolean) {
        this.setValueForKey(value, 'openCashDrawer');
    }
    get openCashDrawer():boolean {
        return this.valueForKey('openCashDrawer');
    }
    set openCashDrawerPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'openCashDrawer');
    }
    get openCashDrawerPrimitiveValue():boolean {
        return this.primitiveValueForKey('openCashDrawer');
    }

    // Property: transactionConcept
    set transactionConcept(value:string) {
        this.setValueForKey(value, 'transactionConcept');
    }
    get transactionConcept():string {
        return this.valueForKey('transactionConcept');
    }
    set transactionConceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'transactionConcept');
    }
    get transactionConceptPrimitiveValue():string {
        return this.primitiveValueForKey('transactionConcept');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: image
    set image(value:DBImage) {
        this.setValueForKey(value, 'image');
    }
    get image():DBImage {
        return this.valueForKey('image') as DBImage;
    }
}
