/// <reference path="../notes/NoteListViewController.ts" />
/// <reference path="../AppHelper.ts" />

enum InventoryMode {
    Single,
    Multiselection
}

class InventoryNoteListViewController extends NoteListViewController 
{
    protected noteStatusForSegmentedIndex(index){
        if (index == 1) return StockNoteStatus.Processed;        
        return StockNoteStatus.None;
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('InventoryNoteCell') as InventoryNoteCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockNote;    
        cell.item = item;
            
        cell.selected = this.selectedNote === item ? true : false;

        return cell;
    }
    
    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("documentID", false)];
        if (this.selectedStatus == StockNoteStatus.Processed) {
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("creationDate", false)];
        }      

        let predicateFormat = "type = " + StockNoteType.InventoryNote + " AND status == " + this.selectedStatus;
        if(this.searchString != null) {
            predicateFormat += " AND documentID CONTAINS '" + this.searchString + "'";
		}
		
		if (this.filterPredicateFormat != null) {
            predicateFormat += " AND " + this.filterPredicateFormat;
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName(this.entityName, sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 50;

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }
	
	private _filters = null;
    get filters(){
        if (this._filters != null) return this._filters;

		this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "stockDate", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
		FilterOption.filterWithTitle("ToDate", "To day", "stockDate", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),                
		FilterOption.filterWithFetchRequest("Destination", "Warehouse", "destinationWarehouse.identifier", FilterFieldType.DropDown, "Warehouse", "name", "identifier", "Select warehouse")];

        return this._filters;
    }

    private detailViewController:MUINavigationController = null;
    protected showSelectedItem(){
        if (this.detailViewController == null) {
            let vc = new InventoryNoteDetailViewController('inventory-note-detail-view');
            vc.initWithResource('layout/inventorynote/InventoryNoteDetailView.html');   
            this.detailViewController = new MUINavigationController();
            this.detailViewController.initWithRootViewController(vc);
        }
        
        this.detailViewController.rootViewController.note = this.selectedNote;
        this.splitViewController.showDetailViewController(this.detailViewController);
    }

    protected showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Inventory", NoItemSelectedViewControllerType.InventoryNote);
        this.splitViewController.showDetailViewController(vc);
    }

    protected showAddNoteViewController(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD INVENTORY NOTE','Add Inventory Note'), MIOLocalizeString('CHOOSE A WAREHOUSE','Choose a warehouse '), MUIAlertViewStyle.Default);
        
        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){                
            DBHelper.sharedInstance().addObserverForEntity(avc, 'Warehouse', null, null, null, function (objects) {
                comboBox.removeAllItems();
                comboBox.addItem(NoWarehouse, -1);
                let sortObjects = _MIOSortDescriptorSortObjects(objects, [MIOSortDescriptor.sortDescriptorWithKey("name", true)]);
                for (let i = 0; i < sortObjects.length; i++)
                    comboBox.addItem(sortObjects[i].name, i);
            });  
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){                
            DBHelper.sharedInstance().addObserverForEntity(avc, 'InventoryTemplate', null, null, null, function (objects) {
                comboBox.removeAllItems();
                comboBox.addItem(MIOLocalizeString("NO TEMPLATE", "NO TEMPLATE"), -1);
                let sortObjects = _MIOSortDescriptorSortObjects(objects, [MIOSortDescriptor.sortDescriptorWithKey("name", true)]);
                for (let i = 0; i < sortObjects.length; i++){
                    let id = sortObjects[i].identifier;
                    comboBox.addItem(sortObjects[i].name, id);
                }
            });  

            comboBox.setOnChangeAction(this, function(control, index){
                if (parseInt(index) == -1) avc.comboBoxes[2].enabled = true;
                else avc.comboBoxes[2].enabled = false;
            });

        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){                
            comboBox.removeAllItems();
            comboBox.addItem("Total", 0);
            comboBox.addItem("Partial", 1);            
        });
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function(){
            let moc: MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            let warehouseComboBox = avc.comboBoxes[0] as MUIComboBox;
            let defaultWarehouse = warehouseComboBox.getSelectedItem();
            let warehouse = defaultWarehouse == '-1' ? null : DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, defaultWarehouse, 'Warehouse');
            
            let template = avc.comboBoxes[1].getSelectedItem();
            let type = avc.comboBoxes[2].getSelectedItem();            
                      
            this.addInventoryNote(warehouse, type, template);            
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, null, null));
        avc.addCompletionHandler(this, function () {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'Warehouse');
        });
        this.presentViewController(avc, true);
    }
    
    private addInventoryNote(warehouse:Warehouse, type, templateID:string){
        if (templateID != null) {
            let predicate = MIOPredicate.predicateWithFormat("deletedAt == null AND template.identifier == " + templateID);
            //DBHelper.queryObjectsWithCompletion("InventoryTemplateItem", null, predicate, ["product", "product.taxBuy", "product.stockCategory"], this, function(objects){                
            DBHelper.queryObjectsWithCompletion("InventoryTemplateItem", null, predicate, ["product", "product.taxBuy", "product.category"], this, function(objects){                
                this.createInventoryNoteWithTemplate(warehouse, objects);
            });            
        }
        else if (type == "0") {
            
            let whID = warehouse != null ? warehouse.identifier : null;
            let predicate = MIOPredicate.predicateWithFormat("warehouse.identifier == " + whID + " AND product.stockElaborationType != " + StockElaborationType.AtSale);            

            DBHelper.queryObjectsWithCompletion("StockAnnotationView", null, predicate, ["product.taxBuy", "product.category"], this, function(objects){
                this.createInventoryNoteWithProducts(warehouse, objects);
            });            
        }
        else {
            this.selectedNote = DBHelper.createInventoryNote(warehouse);            
            DBHelper.saveMainContext();
            this.showSelectedItem();
        }
   }

   private createInventoryNoteWithProducts(warehouse:Warehouse, objects){
        this.selectedNote = DBHelper.createInventoryNote(warehouse);
        this.selectedNote.isPartial = false;

        for (let index = 0; index < objects.length; index++){
            let sa = objects[index] as StockAnnotationView;
            let product = sa.product;            
            let line = DBHelper.createInventoryNoteLine(product, null, sa.productMeasureType, 0, 0, 0, this.selectedNote);
            //line.estimatedQuantity = sa.productQuantity;
            this.selectedNote.addLinesObject(line);
        }

        DBHelper.saveMainContext();
        this.showSelectedItem();
   }

    private createInventoryNoteWithTemplate(warehouse:Warehouse, objects){
        this.selectedNote = DBHelper.createInventoryNote(warehouse);
        this.selectedNote.isPartial = true;

        DBHelper.saveMainContextWithCompletion(this, function(){
            this.addTemplateProductsToInventory(objects);
        });
    }

    private addTemplateProductsToInventory(objects){
        
        for (let index = 0; index < objects.length; index++){
            let item = objects[index] as InventoryTemplateItem;
            let product = item.product as Product;            
            let line = DBHelper.createInventoryNoteLine(product, null, product.measureType, 0, 0, 0, this.selectedNote);
            //line.estimatedQuantity = sa.productQuantity;
            this.selectedNote.addLinesObject(line);
        }
    
        DBHelper.saveMainContext();
        this.showSelectedItem();    
    }

}
