


class BookingsZoneDaySetupSubCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private priceTextField:MUITextField = null;
    private chargePriceTextField:MUITextField = null;
    private paxTextField:MUITextField = null;

    private nf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.numberFormatter ;
    private cf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');

        this.priceTextField = MUIOutlet(this, 'price-tf', 'MUITextField');
        this.priceTextField.formatter = this.cf;
        this.priceTextField.setOnChangeText(this, function(textfield, value){
            this.updateValue("price", value);
        });

        this.chargePriceTextField = MUIOutlet(this, "charge-price-tf", "MUITextField");
        this.chargePriceTextField.setOnChangeText(this, function(textfield, value){
            this.updateValue("chargePrice", value);            
        });

        this.paxTextField = MUIOutlet(this, 'max-pax-tf', 'MUITextField');
        this.paxTextField.formatter = this.nf;
        this.paxTextField.setOnChangeText(this, function(textfield, value){
            this.updateValue("maxPax", value);            
        });        
    }

    private zone:BookingZone = null;    
    private dayZone:RelationBookingDay = null;
    private dayID:string = null;
    setItems(zone:BookingZone, dayZone:RelationBookingDay, dayID:string){
        this.zone = zone;
        this.dayZone = dayZone;
        this.dayID = dayID;

        this.nameLabel.text = zone.name;
        
        if (dayZone == null) {
            this.priceTextField.text = this.nf.stringFromNumber(this.zone.price);            
            this.chargePriceTextField.text = this.zone.chargePrice;
            this.paxTextField.text = this.nf.stringFromNumber(this.zone.maxPax);
        }
        else {
            this.priceTextField.text = this.nf.stringFromNumber(dayZone.price);
            this.chargePriceTextField.text = dayZone.chargePrice; //this.nf.stringFromNumber(i.chargePrice);
            this.paxTextField.text = this.nf.stringFromNumber(dayZone.maxPax);    
        }
    }

    private updateValue(key, value){

        if (this.dayZone == null){
            let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
            this.dayZone = MIOEntityDescription.insertNewObjectForEntityForName("RelationBookingDay", ad.managedObjectContext) as RelationBookingDay;
            this.dayZone.zoneID = this.zone.identifier;
            this.dayZone.day = this.dayID;
            this.dayZone.price = this.zone.price;
            this.dayZone.chargePrice = this.zone.chargePrice;
            this.dayZone.maxPax = this.zone.maxPax;
        }

        let v = value;
        switch (key){
            case "price":
                v = this.nf.numberFromString(value);    
                break;

            case "maxPax":
                v = this.nf.numberFromString(value);    
                break;
        }

        this.dayZone.setValueForKey(v, key);
    }
}
