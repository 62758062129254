

// Generated class BankAccountMovement_ManagedObject

class BankAccountMovement_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: documentID
    set documentID(value:string) {
        this.setValueForKey(value, 'documentID');
    }
    get documentID():string {
        return this.valueForKey('documentID');
    }
    set documentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentID');
    }
    get documentIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentID');
    }

    // Property: amount
    set amount(value:number) {
        this.setValueForKey(value, 'amount');
    }
    get amount():number {
        return this.valueForKey('amount');
    }
    set amountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'amount');
    }
    get amountPrimitiveValue():number {
        return this.primitiveValueForKey('amount');
    }

    // Property: total
    set total(value:number) {
        this.setValueForKey(value, 'total');
    }
    get total():number {
        return this.valueForKey('total');
    }
    set totalPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'total');
    }
    get totalPrimitiveValue():number {
        return this.primitiveValueForKey('total');
    }

    // Property: legalEntityName
    set legalEntityName(value:string) {
        this.setValueForKey(value, 'legalEntityName');
    }
    get legalEntityName():string {
        return this.valueForKey('legalEntityName');
    }
    set legalEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalEntityName');
    }
    get legalEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('legalEntityName');
    }
    // Relationship: legalEntity
    set legalEntity(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():LegalEntity {
        return this.valueForKey('legalEntity') as LegalEntity;
    }
    // Relationship: bankAccount
    set bankAccount(value:BankAccount) {
        this.setValueForKey(value, 'bankAccount');
    }
    get bankAccount():BankAccount {
        return this.valueForKey('bankAccount') as BankAccount;
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
