/**
 * Created by miguel on 23/2/17.
 */

class OfferDetailViewController extends MUIViewController
{   
    private _activeButton = null;    
    
    private codesFilterComboBox:MUIComboBox = null;

    private saveButton:MUIButton= null;
    private segmentedControl:MUISegmentedControl = null;
    private addButton:MUIButton = null;
    private pageController:MUIPageController = null;        

    private generalViewController:OfferGeneralDetailViewController = null;
    private offerProductsViewController:OfferDetailProductsViewController = null;
    private codesViewController:OfferCodeDetailViewController = null;    

    viewDidLoad(){
        super.viewDidLoad();
        
        this.codesFilterComboBox = MUIOutlet(this, "filter-cb", "MUIComboBox");
        this.codesFilterComboBox.setHidden(true);
        this.codesFilterComboBox.setOnChangeAction(this, function(control, value){
            this.changeCodesFilter(parseInt(value));
        });

        this.saveButton = MUIOutlet(this, 'save-btn','MUIButton');
        //this.saveButton.setHidden(true);
        this.saveButton.setAction(this, function(){
            let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
            ad.managedObjectContext.save();            
        });        

        this.addButton = MUIOutlet(this, 'add-btn','MUIButton');
        this.addButton.setAction(this, function(){
            
            switch(this.pageController.selectedViewControllerIndex){

                case 0: 
                    this.addRelatedProduct();
                    break;

                case 1:
                    this.addOfferProducts();
                    break;

                case 2:
                    this.showCodeGeneratorViewController();
                    break;
            }
        });

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function (control, index) {
            this.pageController.showPageAtIndex(index);
            this.codesFilterComboBox.setHidden(index != 2 ? true : false);
        });

        this.pageController = MUIOutlet(this, "page-controller", "MUIPageController");
        
        this.generalViewController = new OfferGeneralDetailViewController("offer-general-detail-view");
        this.generalViewController.initWithResource("layout/offers/OfferGeneralDetailView.html");        
        this.pageController.addPageViewController(this.generalViewController);
       
        this.offerProductsViewController = new OfferDetailProductsViewController("offer-products-view");
        this.offerProductsViewController.initWithResource("layout/offers/OfferProductsView.html");
        this.pageController.addPageViewController(this.offerProductsViewController);

        this.codesViewController = new OfferCodeDetailViewController("offer-codes-view");
        this.codesViewController.initWithResource("layout/offers/OfferCodesView.html");        
        this.pageController.addPageViewController(this.codesViewController);                       
    }


    viewDidAppear(animate?){
        super.viewDidAppear(animate);
        this._updateUI();
    }

    private _offer:Offer = null;
    set offer(value:Offer){
        this._offer = value;
        this._updateUI();
    }

    public _updateUI(){
        if(this.viewIsLoaded  == false || this._offer == null) return;
        
        this.generalViewController.offer = this._offer;
        this.offerProductsViewController.offer = this._offer;
        this.codesViewController.offer = this._offer;
    }

    private showCodeGeneratorViewController(){        
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let avc = new MUIAlertViewController();
        avc.initWithTitle('Generate promotional codes', 'Choose quantity', MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Prefix");
        });
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Start number");
        });
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("End number");
        });
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Start date");
            //textField.formatter = ad.dateTimeFormatter;
            textField.text = ad.dateTimeFormatter.stringFromDate(MIODateToday());
        });
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("End date");
            //textField.formatter = ad.dateTimeFormatter;
            textField.text = ad.dateTimeFormatter.stringFromDate(MIODateTomorrow());
        });
        
        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            comboBox.removeAllItems();
            comboBox.addItem("Custom", PromotionalCodeType.Custom);
            comboBox.addItem("EAN 8", PromotionalCodeType.EAN8);
            comboBox.addItem("EAN 13", PromotionalCodeType.EAN13);

            // Set the last type code generator used.
            let value = MIOUserDefaults.standardUserDefaults().valueForKey("LastOfferTypeSelected");
            if (value == null) comboBox.selectItem(PromotionalCodeType.Custom);
            else comboBox.selectItem(value);
        });
        avc.addAction(MUIAlertAction.alertActionWithTitle('Generate', MUIAlertActionStyle.Default, this, function(){
            let prefix:string = avc.textFields[0].text;
            let startIndex = parseInt(avc.textFields[1].text);
            let endIndex = parseInt(avc.textFields[2].text);
            let startDate = ad.dateTimeFormatter.dateFromString(avc.textFields[3].text);
            let endDate = ad.dateTimeFormatter.dateFromString(avc.textFields[4].text);
            let type = avc.comboBoxes[0].getSelectedItem();

            MIOUserDefaults.standardUserDefaults().setValueForKey("LastOfferTypeSelected", type);

            let ah:AppHelper = AppHelper.sharedInstance();
            ah.generateCodesForOffer(this._offer, parseInt(type), prefix, startIndex, endIndex, startDate, endDate);
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);        
    }

    public productDidSelected(product:Product)
    {        
        product.offerID = this._offer.identifier;
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        ad.managedObjectContext.save();
    }

    private addOfferProducts(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle('Add Products', 'Choose type', MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle('Product', MUIAlertActionStyle.Default, this, function(){
            let vc = AppHelper.sharedInstance().selectViewController("AddOfferProduct", "name", false, true, null, this, true);            
            this.presentViewController(vc, true);        
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Category', MUIAlertActionStyle.Default, this, function(){
            let vc = AppHelper.sharedInstance().selectViewController("AddOfferProductsFromCategory", "name", false, true, null, this, true);
            this.presentViewController(vc, true);        
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle('Format', MUIAlertActionStyle.Default, this, function(){            
            let vc = AppHelper.sharedInstance().selectViewController("AddOfferProductsFromFormat", "name", true, false, null, this, true);
            this.presentViewController(vc, true);        
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);        
    }

    //
    // SelectEntityViewControllerDelegate
    //

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "AddOfferRelatedProduct"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Product", sd, null);
            fetchRequest.fetchLimit = 50;
            //fetchRequest.relationshipKeyPathsForPrefetching = ['productFormats.format'];

            return fetchRequest;
        }
        else if (controller.identifier == "AddOfferProduct"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Product", sd, null);
            fetchRequest.fetchLimit = 50;
            //fetchRequest.relationshipKeyPathsForPrefetching = ['productFormats.format'];

            return fetchRequest;
        }
        else if (controller.identifier == "AddProductFromProductFormats"){
            let product:Product =  controller.context["Product"];
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let predicateFormat = "product.identifier == " + product.identifier;
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductFormat", sd, predicateFormat);
            fetchRequest.relationshipKeyPathsForPrefetching = ["product", "format"];  
            
            return fetchRequest;
        }
        else if (controller.identifier == "AddOfferProductsFromCategory"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Category", sd, null);

            return fetchRequest;
        }
        else if (controller.identifier == "AddOfferProductFromCategoryAndFormats"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Format", sd, null);

            return fetchRequest;
        }
        else if (controller.identifier == "AddOfferProductFromCategoryAndFormatsAndProducts"){
            
            let categories = controller.context["Categories"];
            let formats = controller.context["Formats"];
            
            let predicateFormat = "";
            predicateFormat += "(category.identifier = " + categories[0].identifier; 
            for (let index = 1; index < categories.length; index++){
                predicateFormat += " OR category.identifier = " + categories[index].identifier; 
            }
            predicateFormat += ")";
            if (formats.length > 0){
                predicateFormat += ") AND (productFormat.format.identifier = " + formats[0].identifier;             
                for (let index = 1; index < formats.length; index++){
                    predicateFormat += " OR productFormat.format.identifier = " + formats[index].identifier;
                }
                predicateFormat += ")";
            }
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Product", sd, predicateFormat);

            return fetchRequest;
        }
        else if (controller.identifier == "AddOfferProductsFromFormat"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Format", sd, null);

            return fetchRequest;
        }        
        else if (controller.identifier == "AddOfferProductsFromFormatAndProducts"){
            let format = controller.context["Format"];
            let predicateFormat:string = null;
            let entityName:string = null;
            if (format == null){
                predicateFormat = "productFormat == null";
                entityName = "Product";                
            }
            else {
                predicateFormat = "format.identifier == " + format.identifier;
                entityName = "ProductFormat";
            }            
            controller.context["EntityName"] = entityName;
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName(entityName, sd, predicateFormat);
            if (format != null) fetchRequest.relationshipKeyPathsForPrefetching = ["product", "format"];

            return fetchRequest;
        }        

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){
        
        let dismiss = true;

        if (controller.identifier == "AddOfferRelatedProduct") {
            let product = item as Product;
            this.addOfferRelatedProduct(product);
        }
        else if (controller.identifier == "AddOfferProductsFromFormat") {            
            let format = item as Format;            

            this.addAllProductsForFormat(format);
            return true;

            let context = null;
            let titleKey:string = null;

            if (format == null){
                // Get products
                context = {};
                titleKey = "name";
            }
            else {
                // Get product formats
                context = {"Format": format};
                titleKey = "product.name";
            }
            let vc = AppHelper.sharedInstance().selectViewController("AddOfferProductsFromFormatAndProducts", titleKey, false, true, context, this);
            controller.pushNextViewController(vc);
            dismiss = false;
        }

        return dismiss;
    }

    didSelectObjectsFromSelectViewController(controller:SelectEntityViewController, items:MIOSet){
                
        let dismiss = true;

        if (controller.identifier == "AddOfferProduct") {
            this.addOfferProductsFromProducts(items);
        }
        else if (controller.identifier == "AddProductFromFormats"){            
            this.addOfferProductFromProductFormats(items);
        }
        else if (controller.identifier == "AddOfferProductsFromCategory") {            
            let context = {"Categories" : items};
            let vc = AppHelper.sharedInstance().selectViewController("AddOfferProductFromCategoryAndFormats", "name", true, true, context, this);
            controller.pushNextViewController(vc);
            dismiss = false;
        }
        else if (controller.identifier == "AddOfferProductFromCategoryAndFormats"){
            let context = controller.context;
            context["Formats"] = items;
            let vc = AppHelper.sharedInstance().selectViewController("AddOfferProductFromCategoryAndFormatsAndProducts", "name", false, true, context, this);
            controller.pushNextViewController(vc);
            dismiss = false;            
        }
        else if (controller.identifier == "AddOfferProductsFromFormatAndProducts") {
            let context = controller.context;
            let format = context["Format"];
            let entityName = context["EntityName"];
            if (entityName == "Product"){
                this.addOfferProductFromFormat(format, items);
            }
            else if (entityName == "ProductFormat"){
                this.addOfferProductFromProductFormats(items);
            }
        }
        else if (controller.identifier == "AddProductFromProductFormats"){
            this.addOfferProductFromProductFormats(items);
        }
        else if (controller.identifier == "AddOfferProductFromCategoryAndFormatsAndProducts"){
            let categories = controller.context["Categories"];
            let formats = controller.context["Formats"];
            this.addOfferProductFromCategoryAndFormatsAndProducts(items, categories, formats);
        }        

        return dismiss;
    }    

    private showAllProductsAlert(products, format:Format){
        
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Add products", "Are you sure to add all products", MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle("OK", MUIAlertActionStyle.Default, this, function(){
            this.addProductsFromProductFormats(products, format);
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private addRelatedProduct(){
        let vc = AppHelper.sharedInstance().selectViewController("AddOfferRelatedProduct", "name", false, false, null, this);
        this.presentViewController(vc, true);        
    }    

    private addOfferRelatedProduct(product:Product){        
        product.offerID = this._offer.identifier;
        DBHelper.saveMainContext();
    }

    private addOfferProductsFromProducts(products){
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        for (let index = 0; index < products.length; index++){
            let product = products[index] as Product;
            this.addOfferProduct(product, null, false);            
        }

        ad.managedObjectContext.save();
    }

    private addOfferProductFromFormat(format:Format, products){
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        for (let index = 0; index < products.length; index++){
            let product = products[index] as Product;
            this.addOfferProduct(product, format, false);
        }

        ad.managedObjectContext.save();
    }

    private addOfferProductFromProductFormats(productFormats){
        
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        for (let index = 0; index < productFormats.length; index++){
            let productFormat = productFormats[index] as ProductFormat;
            this.addOfferProduct(productFormat.product, productFormat.format, false);            
        }

        ad.managedObjectContext.save();
    }

    private addOfferProduct(product:Product, format:Format, save:boolean){
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let offerProduct = MIOEntityDescription.insertNewObjectForEntityForName("OfferProduct", ad.managedObjectContext) as OfferProduct; 
        offerProduct.offer = this._offer;
        offerProduct.product = product;
        if (format != null) offerProduct.format = format;

        this._offer.addOfferProductsObject(offerProduct);        

        if (save == true) ad.managedObjectContext.save();
    }

    private addOfferProductFromCategoryAndFormatsAndProducts(items, categories, formats){
        if (items.length > 0) return;

        let predicateFormat = "";
        predicateFormat += "(category.identifier = " + categories[0].identifier; 
        for (let index = 1; index < categories.length; index++){
            predicateFormat += " OR category.identifier = " + categories[index].identifier; 
        }
        predicateFormat += ")";
        if (formats.length > 0){
            predicateFormat += ") AND (productFormat.format.identifier = " + formats[0].identifier;             
            for (let index = 1; index < formats.length; index++){
                predicateFormat += " OR productFormat.format.identifier = " + formats[index].identifier;
            }
            predicateFormat += ")";
        }        
        DBHelper.queryObjectsWithCompletion("Product", null, MIOPredicate.predicateWithFormat(predicateFormat), [], this, function(objects){
            for (let index = 0; index < objects.count; index++){
                let product = objects[index];
                this.addOfferProduct(product, null, false);
            }
            DBHelper.saveMainContext();
        });        
    }

    addAllProductsForFormat(format:Format){
        let predicateFormat = "ANY productFormats.format.identifier = " + format.identifier;
    
        DBHelper.queryObjectsWithCompletion("Product", null, MIOPredicate.predicateWithFormat(predicateFormat), [], this, function(objects){
            for (let index = 0; index < objects.count; index++){
                let product = objects[index];
                this.addOfferProduct(product, format, false);
            }
            DBHelper.saveMainContext();
        });        
    }

    private changeCodesFilter(value){
        this.codesViewController.filter = value;
    }

}