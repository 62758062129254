

// Generated class ProductModifier_ManagedObject

class ProductModifier_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: consumptionMeasureUnit
    set consumptionMeasureUnit(value:number) {
        this.setValueForKey(value, 'consumptionMeasureUnit');
    }
    get consumptionMeasureUnit():number {
        return this.valueForKey('consumptionMeasureUnit');
    }
    set consumptionMeasureUnitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionMeasureUnit');
    }
    get consumptionMeasureUnitPrimitiveValue():number {
        return this.primitiveValueForKey('consumptionMeasureUnit');
    }

    // Property: consumptionQuantity
    set consumptionQuantity(value:number) {
        this.setValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantity():number {
        return this.valueForKey('consumptionQuantity');
    }
    set consumptionQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('consumptionQuantity');
    }

    // Property: isDefault
    set isDefault(value:boolean) {
        this.setValueForKey(value, 'isDefault');
    }
    get isDefault():boolean {
        return this.valueForKey('isDefault');
    }
    set isDefaultPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefault');
    }
    get isDefaultPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefault');
    }

    // Property: isVisible
    set isVisible(value:boolean) {
        this.setValueForKey(value, 'isVisible');
    }
    get isVisible():boolean {
        return this.valueForKey('isVisible');
    }
    set isVisiblePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isVisible');
    }
    get isVisiblePrimitiveValue():boolean {
        return this.primitiveValueForKey('isVisible');
    }

    // Property: lossInProduction
    set lossInProduction(value:number) {
        this.setValueForKey(value, 'lossInProduction');
    }
    get lossInProduction():number {
        return this.valueForKey('lossInProduction');
    }
    set lossInProductionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossInProduction');
    }
    get lossInProductionPrimitiveValue():number {
        return this.primitiveValueForKey('lossInProduction');
    }

    // Property: lossMeasureUnit
    set lossMeasureUnit(value:number) {
        this.setValueForKey(value, 'lossMeasureUnit');
    }
    get lossMeasureUnit():number {
        return this.valueForKey('lossMeasureUnit');
    }
    set lossMeasureUnitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossMeasureUnit');
    }
    get lossMeasureUnitPrimitiveValue():number {
        return this.primitiveValueForKey('lossMeasureUnit');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: productModifierCount
    set productModifierCount(value:number) {
        this.setValueForKey(value, 'productModifierCount');
    }
    get productModifierCount():number {
        return this.valueForKey('productModifierCount');
    }
    set productModifierCountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productModifierCount');
    }
    get productModifierCountPrimitiveValue():number {
        return this.primitiveValueForKey('productModifierCount');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: productModifierCategory
    set productModifierCategory(value:ProductModifierCategory) {
        this.setValueForKey(value, 'productModifierCategory');
    }
    get productModifierCategory():ProductModifierCategory {
        return this.valueForKey('productModifierCategory') as ProductModifierCategory;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: productFormat
    set productFormat(value:ProductFormat) {
        this.setValueForKey(value, 'productFormat');
    }
    get productFormat():ProductFormat {
        return this.valueForKey('productFormat') as ProductFormat;
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
}
