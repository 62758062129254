
class PartyListViewController extends BaseTableViewController
{
    private addButton:MUIButton = null;    

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            this.addParty();
        });

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("PartyCell") as PartyCell;        

        let item:Party = this.fetchedResultsController.objectAtIndexPath(indexPath);
        cell.item = item;

        cell.selected = item === this.selectedParty ? true : false;

        return cell;
    }
 
    private detailViewController:PartyDetailViewController = null;
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Party;
        this.showPartyDetailViewController(item);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("Party");            
        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("title", true)];
                
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    private addParty(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let party = MIOEntityDescription.insertNewObjectForEntityForName("Party", ad.managedObjectContext) as Party;
        party.title = "New party";
        party.description = "New party description";
        ad.managedObjectContext.save();        

        this.selectedParty = party;

        this.showPartyDetailViewController(party);
    }

    private selectedParty:Party = null;
    private showPartyDetailViewController(party:Party){
        
        if(this.detailViewController == null) {
            this.detailViewController = new PartyDetailViewController('party-detail-view');
            this.detailViewController.initWithResource('layout/party/PartyDetailView.html');   
        }

        this.selectedParty = party;
        
        this.detailViewController.party = party;
        this.splitViewController.showDetailViewController(this.detailViewController);   
    }
}