

class OfferDetailProductsViewController extends MUIViewController
{
    private tableView:MUITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, 'table-view', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _offer:Offer = null;
    set offer(value){
        this._offer = value;
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this._offer == null) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;        
    }

    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('OfferProductCell') as OfferProductCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as OfferProduct;
        cell.item = item;

        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let offerProduct:OfferProduct = this.fetchedResultsController.objectAtIndexPath(indexPath);    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeOfferProduct(offerProduct);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('OfferProduct');
        fetchRequest.relationshipKeyPathsForPrefetching = ['product', 'format'];

        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];        

       // if (this._searchString != null)
        fetchRequest.predicate = MIOPredicate.predicateWithFormat('offer.identifier == ' + this._offer.identifier);

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();        
    }

    private removeOfferProduct(offerProduct:OfferProduct){
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        this._offer.removeOfferProductsObject(offerProduct);
        moc.deleteObject(offerProduct);
        moc.save();    

    }
}