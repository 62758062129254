

class StockOrderListViewController extends NoteListViewController
{    
    type = StockNoteType.InternalOrder;

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('OrderCell') as StockOrderCell;    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        cell.item = item;
        cell.selected = this.selectedNote == item ? true : false;

        return cell;
    }
    
    private detailViewController:StockOrderDetailViewController = null;
    protected showSelectedItem(){
        if(!this.detailViewController) {
            if (this.type == StockNoteType.InternalOrder) {
                this.detailViewController = new StockOrderDetailViewController('stock-order-detail-view');
                this.detailViewController.initWithResource('layout/ordernote/StockOrderDetailView.html');   
            }
            else {
                this.detailViewController = new StockOrderDetailViewController('stock-order-response-detail-view');
                this.detailViewController.initWithResource('layout/ordernote/StockOrderResponseDetailView.html');
            }
        }
        
        this.detailViewController.note = this.selectedNote;
        this.splitViewController.showDetailViewController(this.detailViewController);
    }

    protected showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("StockOrder", NoItemSelectedViewControllerType.StockOrder);
        this.splitViewController.showDetailViewController(vc);
    }
            
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("documentID", false)];
        if (this.selectedStatus == StockNoteStatus.Processed) {
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("creationDate", false)];
        }   
                
        let predicateFormat = "type = " + this.type + " AND status == " + this.selectedStatus;
        if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
        if(this.searchString != null) {
            predicateFormat += " AND (documentID CONTAINS '" + this.searchString + "' OR internalOrder.documentID CONTAINS '" + this.searchString + "')";
        }
		if (this.filterPredicateFormat != null) {
            predicateFormat += " AND " + this.filterPredicateFormat;
        }
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockNote", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 50;
        fetchRequest.relationshipKeyPathsForPrefetching = ['internalOrder'];
            
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
	}    
	
	private _filters = null;
    get filters(){
        if (this._filters != null) return this._filters;

        this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "stockDate", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
                FilterOption.filterWithTitle("ToDate", "To day", "stockDate", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),                
                FilterOption.filterWithFetchRequest("Origin", "From warehouse", "originWarehouse.identifier", FilterFieldType.DropDown, "Warehouse", "name", "identifier", "Select origin warehouse"),
                FilterOption.filterWithFetchRequest("Destination", "To warehouse", "destinationWarehouse.identifier", FilterFieldType.DropDown, "Warehouse", "name", "identifier", "Select destination warehouse")];

        return this._filters;
    }
	
}