

// Generated class LapsopayLocation_ManagedObject

class LapsopayLocation_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: lapsopayId
    set lapsopayId(value:string) {
        this.setValueForKey(value, 'lapsopayId');
    }
    get lapsopayId():string {
        return this.valueForKey('lapsopayId');
    }
    set lapsopayIdPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'lapsopayId');
    }
    get lapsopayIdPrimitiveValue():string {
        return this.primitiveValueForKey('lapsopayId');
    }

    // Property: place
    set place(value:string) {
        this.setValueForKey(value, 'place');
    }
    get place():string {
        return this.valueForKey('place');
    }
    set placePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'place');
    }
    get placePrimitiveValue():string {
        return this.primitiveValueForKey('place');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }
}
