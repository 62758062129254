//
// Generated class StockNoteLine
//

/// <reference path="StockNoteLine_ManagedObject.ts" />

enum StockNoteLineType
{     
    NoteLine,
    InventoryNoteLine,
    InputLine,
    OutputLine,
    OutputOrderLine,
    DeliveryNoteLine,
    SupplierNoteLine,    
    LowStockOrderLine,
    DiscountLine,
    ExtraLine,    

}

class StockNoteLine extends StockNoteLine_ManagedObject
{
    static stockTaxFromProduct(product:Product):Tax {
        if (product.taxBuy != null) return product.taxBuy;
        if (product.tax != null) return product.tax;
        if (product.category != null && product.category.tax != null) return product.category.tax;
        return null;
    }

    static stockTaxStringFromProduct(product:Product):string{
        let tax = StockNoteLine.stockTaxFromProduct(product);
        return tax ? tax.name : null;
    }

    stockTax():Tax {
        if (this.tax != null) return this.tax;
        return StockNoteLine.stockTaxFromProduct(this.product);
    }

    stockTaxString():string{
        if (this.taxName != null) return this.taxName;
        return StockNoteLine.stockTaxStringFromProduct(this.product);
    } 

    productQuantityMultiplier(){
        if (this.productMeasureType == this.measureType) return 1;
        if (this.productMeasureType == MeasureUnitType.MassGram && this.measureType == MeasureUnitType.MassKilogram) return 1000;
        if (this.productMeasureType == MeasureUnitType.MassKilogram && this.measureType == MeasureUnitType.MassGram) return 0.001;
        if (this.productMeasureType == MeasureUnitType.VolumeCentilitre && this.measureType == MeasureUnitType.VolumeLitre) return 100;
        if (this.productMeasureType == MeasureUnitType.VolumeLitre && this.measureType == MeasureUnitType.VolumeCentilitre) return 0.01;
        
        return 1;
    }

}
