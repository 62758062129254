//
// Generated class StockCategory
//

/// <reference path="StockCategory_ManagedObject.ts" />

class StockCategory extends StockCategory_ManagedObject
{

}
