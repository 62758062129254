

// Generated class ArchivedTicketLine_ManagedObject

class ArchivedTicketLine_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: statusDate
    set statusDate(value:Date) {
        this.setValueForKey(value, 'statusDate');
    }
    get statusDate():Date {
        return this.valueForKey('statusDate');
    }
    set statusDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'statusDate');
    }
    get statusDatePrimitiveValue():Date {
        return this.primitiveValueForKey('statusDate');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: tax
    set tax(value:string) {
        this.setValueForKey(value, 'tax');
    }
    get tax():string {
        return this.valueForKey('tax');
    }
    set taxPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tax');
    }
    get taxPrimitiveValue():string {
        return this.primitiveValueForKey('tax');
    }

    // Property: taxName
    set taxName(value:string) {
        this.setValueForKey(value, 'taxName');
    }
    get taxName():string {
        return this.valueForKey('taxName');
    }
    set taxNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxName');
    }
    get taxNamePrimitiveValue():string {
        return this.primitiveValueForKey('taxName');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }

    // Property: discount
    set discount(value:number) {
        this.setValueForKey(value, 'discount');
    }
    get discount():number {
        return this.valueForKey('discount');
    }
    set discountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discount');
    }
    get discountPrimitiveValue():number {
        return this.primitiveValueForKey('discount');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }

    // Property: locationCategoryName
    set locationCategoryName(value:string) {
        this.setValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryName():string {
        return this.valueForKey('locationCategoryName');
    }
    set locationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryName');
    }

    // Property: totalPriceBase
    set totalPriceBase(value:number) {
        this.setValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBase():number {
        return this.valueForKey('totalPriceBase');
    }
    set totalPriceBasePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBasePrimitiveValue():number {
        return this.primitiveValueForKey('totalPriceBase');
    }

    // Property: totalTaxes
    set totalTaxes(value:number) {
        this.setValueForKey(value, 'totalTaxes');
    }
    get totalTaxes():number {
        return this.valueForKey('totalTaxes');
    }
    set totalTaxesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTaxes');
    }
    get totalTaxesPrimitiveValue():number {
        return this.primitiveValueForKey('totalTaxes');
    }

    // Property: totalInvited
    set totalInvited(value:number) {
        this.setValueForKey(value, 'totalInvited');
    }
    get totalInvited():number {
        return this.valueForKey('totalInvited');
    }
    set totalInvitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalInvited');
    }
    get totalInvitedPrimitiveValue():number {
        return this.primitiveValueForKey('totalInvited');
    }

    // Property: totalDiscount
    set totalDiscount(value:number) {
        this.setValueForKey(value, 'totalDiscount');
    }
    get totalDiscount():number {
        return this.valueForKey('totalDiscount');
    }
    set totalDiscountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDiscount');
    }
    get totalDiscountPrimitiveValue():number {
        return this.primitiveValueForKey('totalDiscount');
    }

    // Property: totalPrice
    set totalPrice(value:number) {
        this.setValueForKey(value, 'totalPrice');
    }
    get totalPrice():number {
        return this.valueForKey('totalPrice');
    }
    set totalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPrice');
    }
    get totalPricePrimitiveValue():number {
        return this.primitiveValueForKey('totalPrice');
    }

    // Property: totalDeleted
    set totalDeleted(value:number) {
        this.setValueForKey(value, 'totalDeleted');
    }
    get totalDeleted():number {
        return this.valueForKey('totalDeleted');
    }
    set totalDeletedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDeleted');
    }
    get totalDeletedPrimitiveValue():number {
        return this.primitiveValueForKey('totalDeleted');
    }

    // Property: inProcessSeconds
    set inProcessSeconds(value:number) {
        this.setValueForKey(value, 'inProcessSeconds');
    }
    get inProcessSeconds():number {
        return this.valueForKey('inProcessSeconds');
    }
    set inProcessSecondsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'inProcessSeconds');
    }
    get inProcessSecondsPrimitiveValue():number {
        return this.primitiveValueForKey('inProcessSeconds');
    }

    // Property: nullify
    set nullify(value:string) {
        this.setValueForKey(value, 'nullify');
    }
    get nullify():string {
        return this.valueForKey('nullify');
    }
    set nullifyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullify');
    }
    get nullifyPrimitiveValue():string {
        return this.primitiveValueForKey('nullify');
    }

    // Property: nullifyBy
    set nullifyBy(value:string) {
        this.setValueForKey(value, 'nullifyBy');
    }
    get nullifyBy():string {
        return this.valueForKey('nullifyBy');
    }
    set nullifyByPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifyBy');
    }
    get nullifyByPrimitiveValue():string {
        return this.primitiveValueForKey('nullifyBy');
    }

    // Property: sendToKitchenSeconds
    set sendToKitchenSeconds(value:number) {
        this.setValueForKey(value, 'sendToKitchenSeconds');
    }
    get sendToKitchenSeconds():number {
        return this.valueForKey('sendToKitchenSeconds');
    }
    set sendToKitchenSecondsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'sendToKitchenSeconds');
    }
    get sendToKitchenSecondsPrimitiveValue():number {
        return this.primitiveValueForKey('sendToKitchenSeconds');
    }

    // Property: modifiedType
    set modifiedType(value:number) {
        this.setValueForKey(value, 'modifiedType');
    }
    get modifiedType():number {
        return this.valueForKey('modifiedType');
    }
    set modifiedTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'modifiedType');
    }
    get modifiedTypePrimitiveValue():number {
        return this.primitiveValueForKey('modifiedType');
    }

    // Property: orderindex
    set orderindex(value:number) {
        this.setValueForKey(value, 'orderindex');
    }
    get orderindex():number {
        return this.valueForKey('orderindex');
    }
    set orderindexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderindex');
    }
    get orderindexPrimitiveValue():number {
        return this.primitiveValueForKey('orderindex');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: lineGroup
    set lineGroup(value:ArchivedTicket) {
        this.setValueForKey(value, 'lineGroup');
    }
    get lineGroup():ArchivedTicket {
        return this.valueForKey('lineGroup') as ArchivedTicket;
    }

    // Relationship: modifiers
    protected _modifiers:MIOManagedObjectSet = null;
    get modifiers():MIOManagedObjectSet {
        return this.valueForKey('modifiers');
    }
    addModifiersObject(value:ArchivedTicketLineModifier) {
        this._addObjectForKey(value, 'modifiers');
    }
    removeModifiersObject(value:ArchivedTicketLineModifier) {
        this._removeObjectForKey(value, 'modifiers');
    }
}
