/**
 * Created by miguel on 22/2/17.
 */

/// <reference path="../tickets/SelectTicketViewController.ts" />


enum InvoiceType 
{
    OutcomingInvoice,
    IncomingInvoice
}

class IssuedInvoiceListViewController extends BaseTableViewController
{
    private titleLabel:MUILabel = null;
	private calendarButton:MUIButton = null;
	private filterButton:MUIButton = null;
    private addButton:MUIButton = null;            

    private hidvc = null;

    private invoices = [];
    private _invoice = null;
    private noDetailViewController = null;

    private _mode = null;
    private invoiceModel = null;            
            
    private sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;
    private sdf = MUIWebApplication.sharedInstance().delegate.serverDateFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.titleLabel = MUIOutlet(this, 'title_lbl','MUILabel');
        if(this._mode == InvoiceType.IncomingInvoice) {
            this.titleLabel.text = MIOLocalizeString("SUPPLIER INVOICES", "Supplier invoices");
            this.invoiceModel = 'SupplierInvoice';
        }
        else {
            this.titleLabel.text = MIOLocalizeString("ISSUED INVOICES", "Issued invoices"); 
            this.invoiceModel = 'IssuedInvoice';
        }

        this.calendarButton = MUIOutlet(this, 'calendar_btn','MUIButton');
        this.calendarButton.setAction(this, function () {
            let ah:AppHelper = AppHelper.sharedInstance();
            ah.presentDateSelectionViewController(this);
		});
		
		this.filterButton = MUIOutlet(this, "filter-btn", "MUIButton");
		this.filterButton.setAction(this, function() {
			AppHelper.sharedInstance().showFilterViewController(this, this.filters, this.filtersDidChange);
		});

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            this.showMultipleTicketSelectionViewController();
        });

        this.searchTextField = MUIOutlet(this, 'search_bar','MUITextField');
        this.setupSearchTextField();

        //trying a change in tableview to UItableView
        this.tableView = MUIOutlet(this, 'tableview', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.needsReloadData();    
        
        MIONotificationCenter.defaultCenter().addObserver(this, "SelectInvoiceNotification", function(note:MIONotification){
            let invoiceID = note.object;
            this.selectInvoiceByID(invoiceID);
        });
    }

    set mode(value){
        this._mode = value;
    }

    titleForHeaderInSection(tableView, section){
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip);
        
        return item.dateString;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("IssuedInvoiceCell") as IssuedInvoiceCell;        

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ArchivedDocument;
        cell.item = item;

        if(this._invoice && this._invoice.identifier == item.identifier) {
            cell.setSelected(true);
            this.showSelectedInvoice();
        }

        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this._invoice = obj;

        this.showSelectedInvoice();
    }

    private showSelectedInvoice()
    {
        if(!this.hidvc) {
            this.hidvc = new IssuedInvoiceDetailViewController('issued-invoice-detail-view');
            this.hidvc.mode = this._mode;
            this.hidvc.initWithResource('layout/issuedinvoice/IssuedInvoiceDetailView.html');   
        }
        
        this.hidvc.invoice = this._invoice;
        this.splitViewController.showDetailViewController(this.hidvc);
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Invoice", NoItemSelectedViewControllerType.Invoice);
        this.splitViewController.showDetailViewController(this.noDetailViewController);
	}
	
	protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("ArchivedDocument");
        fetchRequest.fetchLimit = 50;
        fetchRequest.relationshipKeyPathsForPrefetching = ["nextDocument"];
    
        let predicateString = "legalDocumentID != null AND type == 'I'";
        if (this.searchString != null) {
            predicateString += " AND (legalDocumentID CONTAINS '" + this.searchString + "' OR documentID CONTAINS '" + this.searchString + "')";
        }
        if (this.dateTimeFrom != null && this.dateTimeTo != null) {
            predicateString += " AND (sessionBeginDate >= '" + this.dateTimeFrom + "' AND sessionBeginDate <= '" + this.dateTimeTo + "')";
		}
		if (this.filterPredicateFormat != null) {
            predicateString += " AND " + this.filterPredicateFormat;
        }

        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("date", false), 
                                         MIOSortDescriptor.sortDescriptorWithKey("legalDocumentID", false)];
        if (predicateString != null)
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateString);
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, 'dateString');
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

    public invoiceRecipientDidSelected(recipient){
        
        let address = recipient.address ? recipient.address.formatedAddress: null;
        let invoiceObj;
        if(this._mode == InvoiceType.OutcomingInvoice) invoiceObj = new IssuedInvoice();
        else invoiceObj = new SupplierInvoice();
        let invoice = {
            'documentid':            null,
            'invoicelegalentityid':       recipient.identifier,
            'invoicelegalentityname':     recipient.name,
            'invoicelegalentityphone':    recipient.phone ? recipient.phone.number: recipient.mobilePhone ? recipient.mobilePhone.number: null,
            'invoicelegalentitydocument': recipient.document,
            'invoicelegalentityaddress':  address ? address.address1:               null,
            'invoicelegalentityaddress2': address ? address.address2:               null,
            'modifiedbyuser':        false,
            'closed':                false,
            'date':                  this.sdtf.stringFromDate(MIODateNow()),
            'totalpricebase':        0,
            'totaltaxes':            0,
            'totalprice':            0,
            'totaldiscount':         0,
            'totalinvited':          0,
            'totaldeleted':          0,
            'isincominginvoice': this._mode == InvoiceType.IncomingInvoice
        };
        
        var ad = MUIWebApplication.sharedInstance().delegate;
        invoiceObj.setObject(invoice, ad.managedObjectContext);

        let ws = ad.webService;        
        ws.insertDocument(ad.selectedPlace, invoiceObj, this, function(code, json){
           if(code == 200) 
           {
               let newInvoice = json['data'];
               newInvoice['isarchived'] = false;
                var p:IssuedInvoice|SupplierInvoice = MIOEntityDescription.insertNewObjectForEntityForName(this.invoiceModel, ad.managedObjectContext) as IssuedInvoice|SupplierInvoice;
                //p.setObject(newInvoice, ad.managedObjectContext);
                this.newInvoice = p;
                ad.managedObjectContext.save();
                this._showSelectedInvoice();
           }
           this.activityIndicator.setHidden(true);
        });

    }

    private dateTimeFrom:string = null;
    private dateTimeTo:string = null;    
    datesDidSelect(dateTimeFrom, dateTimeTo) {
        this.dateTimeFrom = MIODateGetDateString(dateTimeFrom) + " 00:00:00";
        this.dateTimeTo = MIODateGetDateString(dateTimeTo) + " 23:59:59";
        
        //this.showNoItemSelectedView();
        this.setFetchedResultsController(null);
        this.tableView.reloadData();
	}
	
	private _filters = null;
    get filters(){
        if (this._filters != null) return this._filters;

        this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "date", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
				FilterOption.filterWithTitle("ToDate", "To day", "date", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),    
				FilterOption.filterWithTitle("Amount", "Amount", "totalPrice", FilterFieldType.Number, null, null),            
                FilterOption.filterWithFetchRequest("Client", "Client", "ticketLegalEntity.identifier", FilterFieldType.DropDown, "Client", "name", "identifier", "Select client")];
        return this._filters;
	}
	
	protected filterPredicateFormat:string = null;
    protected filtersDidChange(filtersPredicateFormat){
        if(filtersPredicateFormat != null) {
            MUICoreLayerAddStyle(this.filterButton.layer, 'filter');
            MUICoreLayerRemoveStyle(this.filterButton.layer, 'filter-off');
        } else {
            MUICoreLayerRemoveStyle(this.filterButton.layer, 'filter');
            MUICoreLayerAddStyle(this.filterButton.layer, 'filter-off');
        }

        this.filterPredicateFormat = filtersPredicateFormat;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
	}

    private showMultipleTicketSelectionViewController(){
        let vc = new SelectTicketViewController("select-invoice-tickets-view");
        vc.initWithResource("layout/issuedinvoice/SelectInvoiceTicketsView.html");

        this.presentViewController(vc, true);
    }

    private selectInvoiceByID(identifier:string){
        let obj = DBHelper.queryObjectFromMainContext("ArchivedDocument", MIOPredicate.predicateWithFormat("identifier == " + identifier));
        let ip = this.fetchedResultsController.indexPathForObject(obj);

        if (ip == null) return;
        this._invoice = obj;
        this.showSelectedInvoice();
    }

}