

// Generated class CashDeskLine_ManagedObject

class CashDeskLine_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: idApp
    set idApp(value:string) {
        this.setValueForKey(value, 'idApp');
    }
    get idApp():string {
        return this.valueForKey('idApp');
    }
    set idAppPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'idApp');
    }
    get idAppPrimitiveValue():string {
        return this.primitiveValueForKey('idApp');
    }

    // Property: idPlace
    set idPlace(value:string) {
        this.setValueForKey(value, 'idPlace');
    }
    get idPlace():string {
        return this.valueForKey('idPlace');
    }
    set idPlacePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'idPlace');
    }
    get idPlacePrimitiveValue():string {
        return this.primitiveValueForKey('idPlace');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: customConcept
    set customConcept(value:string) {
        this.setValueForKey(value, 'customConcept');
    }
    get customConcept():string {
        return this.valueForKey('customConcept');
    }
    set customConceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'customConcept');
    }
    get customConceptPrimitiveValue():string {
        return this.primitiveValueForKey('customConcept');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: debtLine
    set debtLine(value:string) {
        this.setValueForKey(value, 'debtLine');
    }
    get debtLine():string {
        return this.valueForKey('debtLine');
    }
    set debtLinePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'debtLine');
    }
    get debtLinePrimitiveValue():string {
        return this.primitiveValueForKey('debtLine');
    }

    // Property: documentID
    set documentID(value:string) {
        this.setValueForKey(value, 'documentID');
    }
    get documentID():string {
        return this.valueForKey('documentID');
    }
    set documentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentID');
    }
    get documentIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentID');
    }

    // Property: legalDocumentID
    set legalDocumentID(value:string) {
        this.setValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentID():string {
        return this.valueForKey('legalDocumentID');
    }
    set legalDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('legalDocumentID');
    }

    // Property: info
    set info(value:string) {
        this.setValueForKey(value, 'info');
    }
    get info():string {
        return this.valueForKey('info');
    }
    set infoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'info');
    }
    get infoPrimitiveValue():string {
        return this.primitiveValueForKey('info');
    }

    // Property: byUser
    set byUser(value:number) {
        this.setValueForKey(value, 'byUser');
    }
    get byUser():number {
        return this.valueForKey('byUser');
    }
    set byUserPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'byUser');
    }
    get byUserPrimitiveValue():number {
        return this.primitiveValueForKey('byUser');
    }

    // Property: invitationResponsible
    set invitationResponsible(value:string) {
        this.setValueForKey(value, 'invitationResponsible');
    }
    get invitationResponsible():string {
        return this.valueForKey('invitationResponsible');
    }
    set invitationResponsiblePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invitationResponsible');
    }
    get invitationResponsiblePrimitiveValue():string {
        return this.primitiveValueForKey('invitationResponsible');
    }

    // Property: invitationResponsibleName
    set invitationResponsibleName(value:string) {
        this.setValueForKey(value, 'invitationResponsibleName');
    }
    get invitationResponsibleName():string {
        return this.valueForKey('invitationResponsibleName');
    }
    set invitationResponsibleNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invitationResponsibleName');
    }
    get invitationResponsibleNamePrimitiveValue():string {
        return this.primitiveValueForKey('invitationResponsibleName');
    }

    // Property: invitationResponsibleType
    set invitationResponsibleType(value:number) {
        this.setValueForKey(value, 'invitationResponsibleType');
    }
    get invitationResponsibleType():number {
        return this.valueForKey('invitationResponsibleType');
    }
    set invitationResponsibleTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'invitationResponsibleType');
    }
    get invitationResponsibleTypePrimitiveValue():number {
        return this.primitiveValueForKey('invitationResponsibleType');
    }

    // Property: money
    set money(value:number) {
        this.setValueForKey(value, 'money');
    }
    get money():number {
        return this.valueForKey('money');
    }
    set moneyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'money');
    }
    get moneyPrimitiveValue():number {
        return this.primitiveValueForKey('money');
    }

    // Property: moneyOtherCurrency
    set moneyOtherCurrency(value:number) {
        this.setValueForKey(value, 'moneyOtherCurrency');
    }
    get moneyOtherCurrency():number {
        return this.valueForKey('moneyOtherCurrency');
    }
    set moneyOtherCurrencyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'moneyOtherCurrency');
    }
    get moneyOtherCurrencyPrimitiveValue():number {
        return this.primitiveValueForKey('moneyOtherCurrency');
    }

    // Property: paymentType
    set paymentType(value:number) {
        this.setValueForKey(value, 'paymentType');
    }
    get paymentType():number {
        return this.valueForKey('paymentType');
    }
    set paymentTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'paymentType');
    }
    get paymentTypePrimitiveValue():number {
        return this.primitiveValueForKey('paymentType');
    }

    // Property: payMethodName
    set payMethodName(value:string) {
        this.setValueForKey(value, 'payMethodName');
    }
    get payMethodName():string {
        return this.valueForKey('payMethodName');
    }
    set payMethodNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodName');
    }
    get payMethodNamePrimitiveValue():string {
        return this.primitiveValueForKey('payMethodName');
    }

    // Property: payMethodSubtype
    set payMethodSubtype(value:string) {
        this.setValueForKey(value, 'payMethodSubtype');
    }
    get payMethodSubtype():string {
        return this.valueForKey('payMethodSubtype');
    }
    set payMethodSubtypePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodSubtype');
    }
    get payMethodSubtypePrimitiveValue():string {
        return this.primitiveValueForKey('payMethodSubtype');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: taxQuantity
    set taxQuantity(value:number) {
        this.setValueForKey(value, 'taxQuantity');
    }
    get taxQuantity():number {
        return this.valueForKey('taxQuantity');
    }
    set taxQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxQuantity');
    }
    get taxQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('taxQuantity');
    }

    // Property: tax
    set tax(value:string) {
        this.setValueForKey(value, 'tax');
    }
    get tax():string {
        return this.valueForKey('tax');
    }
    set taxPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tax');
    }
    get taxPrimitiveValue():string {
        return this.primitiveValueForKey('tax');
    }

    // Property: taxName
    set taxName(value:string) {
        this.setValueForKey(value, 'taxName');
    }
    get taxName():string {
        return this.valueForKey('taxName');
    }
    set taxNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxName');
    }
    get taxNamePrimitiveValue():string {
        return this.primitiveValueForKey('taxName');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }

    // Property: currency
    set currency(value:string) {
        this.setValueForKey(value, 'currency');
    }
    get currency():string {
        return this.valueForKey('currency');
    }
    set currencyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'currency');
    }
    get currencyPrimitiveValue():string {
        return this.primitiveValueForKey('currency');
    }

    // Property: subCashDesk
    set subCashDesk(value:string) {
        this.setValueForKey(value, 'subCashDesk');
    }
    get subCashDesk():string {
        return this.valueForKey('subCashDesk');
    }
    set subCashDeskPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'subCashDesk');
    }
    get subCashDeskPrimitiveValue():string {
        return this.primitiveValueForKey('subCashDesk');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: operationID
    set operationID(value:string) {
        this.setValueForKey(value, 'operationID');
    }
    get operationID():string {
        return this.valueForKey('operationID');
    }
    set operationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'operationID');
    }
    get operationIDPrimitiveValue():string {
        return this.primitiveValueForKey('operationID');
    }

    // Property: bookingID
    set bookingID(value:string) {
        this.setValueForKey(value, 'bookingID');
    }
    get bookingID():string {
        return this.valueForKey('bookingID');
    }
    set bookingIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingID');
    }
    get bookingIDPrimitiveValue():string {
        return this.primitiveValueForKey('bookingID');
    }

    // Property: locationCategoryName
    set locationCategoryName(value:string) {
        this.setValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryName():string {
        return this.valueForKey('locationCategoryName');
    }
    set locationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryName');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }

    // Property: eventTags
    set eventTags(value:string) {
        this.setValueForKey(value, 'eventTags');
    }
    get eventTags():string {
        return this.valueForKey('eventTags');
    }
    set eventTagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'eventTags');
    }
    get eventTagsPrimitiveValue():string {
        return this.primitiveValueForKey('eventTags');
    }

    // Property: eventID
    set eventID(value:string) {
        this.setValueForKey(value, 'eventID');
    }
    get eventID():string {
        return this.valueForKey('eventID');
    }
    set eventIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'eventID');
    }
    get eventIDPrimitiveValue():string {
        return this.primitiveValueForKey('eventID');
    }

    // Property: eventName
    set eventName(value:string) {
        this.setValueForKey(value, 'eventName');
    }
    get eventName():string {
        return this.valueForKey('eventName');
    }
    set eventNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'eventName');
    }
    get eventNamePrimitiveValue():string {
        return this.primitiveValueForKey('eventName');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: worker
    set worker(value:Employee) {
        this.setValueForKey(value, 'worker');
    }
    get worker():Employee {
        return this.valueForKey('worker') as Employee;
    }
    // Relationship: payMethod
    set payMethod(value:PayMethod) {
        this.setValueForKey(value, 'payMethod');
    }
    get payMethod():PayMethod {
        return this.valueForKey('payMethod') as PayMethod;
    }
    // Relationship: checkPoint
    set checkPoint(value:CheckPoint) {
        this.setValueForKey(value, 'checkPoint');
    }
    get checkPoint():CheckPoint {
        return this.valueForKey('checkPoint') as CheckPoint;
    }
}
