

// Generated class OfferRule_ManagedObject

class OfferRule_ManagedObject extends MIOManagedObject
{

    // Property: placeID
    set placeID(value:string) {
        this.setValueForKey(value, 'placeID');
    }
    get placeID():string {
        return this.valueForKey('placeID');
    }
    set placeIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'placeID');
    }
    get placeIDPrimitiveValue():string {
        return this.primitiveValueForKey('placeID');
    }

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: conditionType
    set conditionType(value:number) {
        this.setValueForKey(value, 'conditionType');
    }
    get conditionType():number {
        return this.valueForKey('conditionType');
    }
    set conditionTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'conditionType');
    }
    get conditionTypePrimitiveValue():number {
        return this.primitiveValueForKey('conditionType');
    }

    // Property: conditionValue
    set conditionValue(value:string) {
        this.setValueForKey(value, 'conditionValue');
    }
    get conditionValue():string {
        return this.valueForKey('conditionValue');
    }
    set conditionValuePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'conditionValue');
    }
    get conditionValuePrimitiveValue():string {
        return this.primitiveValueForKey('conditionValue');
    }

    // Property: discount
    set discount(value:number) {
        this.setValueForKey(value, 'discount');
    }
    get discount():number {
        return this.valueForKey('discount');
    }
    set discountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discount');
    }
    get discountPrimitiveValue():number {
        return this.primitiveValueForKey('discount');
    }

    // Property: discountLimitInSession
    set discountLimitInSession(value:number) {
        this.setValueForKey(value, 'discountLimitInSession');
    }
    get discountLimitInSession():number {
        return this.valueForKey('discountLimitInSession');
    }
    set discountLimitInSessionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discountLimitInSession');
    }
    get discountLimitInSessionPrimitiveValue():number {
        return this.primitiveValueForKey('discountLimitInSession');
    }

    // Property: currentDiscounts
    set currentDiscounts(value:number) {
        this.setValueForKey(value, 'currentDiscounts');
    }
    get currentDiscounts():number {
        return this.valueForKey('currentDiscounts');
    }
    set currentDiscountsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'currentDiscounts');
    }
    get currentDiscountsPrimitiveValue():number {
        return this.primitiveValueForKey('currentDiscounts');
    }

    // Property: invitationLimitInSession
    set invitationLimitInSession(value:number) {
        this.setValueForKey(value, 'invitationLimitInSession');
    }
    get invitationLimitInSession():number {
        return this.valueForKey('invitationLimitInSession');
    }
    set invitationLimitInSessionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'invitationLimitInSession');
    }
    get invitationLimitInSessionPrimitiveValue():number {
        return this.primitiveValueForKey('invitationLimitInSession');
    }

    // Property: currentInvitations
    set currentInvitations(value:number) {
        this.setValueForKey(value, 'currentInvitations');
    }
    get currentInvitations():number {
        return this.valueForKey('currentInvitations');
    }
    set currentInvitationsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'currentInvitations');
    }
    get currentInvitationsPrimitiveValue():number {
        return this.primitiveValueForKey('currentInvitations');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: economicEntity
    set economicEntity(value:Card) {
        this.setValueForKey(value, 'economicEntity');
    }
    get economicEntity():Card {
        return this.valueForKey('economicEntity') as Card;
    }
}
