
class SupplierNoteDetailViewController extends NoteDetailViewController
{
    private allReceiptButton:MUIButton = null;

    private noEditHeaderView:SupplierNoteDetailHeader = null;
    private editHeaderView:SupplierNoteDetailHeader = null;

    private baseLabel:MUILabel = null;
    private taxLabel:MUILabel = null;
    private totalLabel:MUILabel = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.allReceiptButton = MUIOutlet(this, "all-received-btn", "MUIButton");
        this.allReceiptButton.setAction(this, function(){
            this.markAllAsReceivedAction();
        });

        this.noEditHeaderView = MUIOutlet(this, "no-edit-header-view", "SupplierNoteDetailHeader");
        this.editHeaderView = MUIOutlet(this, "edit-header-view", "SupplierNoteDetailHeader");

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.baseLabel = MUIOutlet(this, "base-lbl", "MUILabel");
        this.taxLabel = MUIOutlet(this, "tax-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
    }

    protected updateUI(){        
        this.allReceiptButton.hidden = this.stockNote.status == StockNoteStatus.Pending ? false : true;
        this.editHeaderView.note = this.stockNote;
        this.noEditHeaderView.note = this.stockNote;
        this.editHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;
        this.noEditHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? false : true;

        this.baseLabel.text = "-";
        this.taxLabel.text = "-";
        this.totalLabel.text = "-";

        this.fetchedResultsController = null;
        this.tableView.reloadData();

        this.updateTotal();
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];

        let predicateFormat = 'note.identifier == ' + this.stockNote.identifier;
        if (this.searchString != null) {
            predicateFormat += " AND productName CONTAINS '" + this.searchString + "'";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockNoteLine", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["tax", "inputFormat"];        
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        super.controllerDidChangeContent(controller);
        this.updateTotal();        
    }

    //
    // NoteLine Delegate
    //

    baseAmountDidChange(base:number){
        this.updateTotal();
    }

    taxDidChange(tax:Tax){
        this.updateTotal();
    }

    supplierFromNote(sender){
        return this.stockNote.originEntity;
    }

    private base = 0;
    private tax = 0;
    private total = 0;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
    private updateTotal(){
        this.base = 0;
        this.tax = 0;
        this.total = 0;

        let lines = this.fetchedResultsController.fetchedObjects;
        for (let index = 0; index < lines.length; index++){
            let l = lines[index] as StockNoteLine;
            let b = l.total;
            let tx = l.stockTax().taxQuantity;
            let tax = b * tx;
            this.total += b + tax;
            this.tax += tax;
            this.base += b;
        }

        this.baseLabel.text = this.cf.stringFromNumber(this.base);
        this.taxLabel.text = this.cf.stringFromNumber(this.tax);
        this.totalLabel.text = this.cf.stringFromNumber(this.total);
    }

    private markAllAsReceivedAction(){
        let lines = this.fetchedResultsController.fetchedObjects;
        for (let index = 0; index < lines.length; index++){
            let l = lines[index] as StockNoteLine;
            l.quantity = l.estimatedQuantity;
            l.price = l.estimatedPrice;
                        
            [l.productQuantity, l.measureQuantity] = DBHelper.calculateQuantityFromStockLine(l.quantity, l.inputFormat, l.measureType, l.productMeasureType, l.productContainerMeasureType, l.productContainerQuantity);        
            [l.total, l.discountValue] = DBHelper.calculateTotalFromStockLine(l.quantity, l.price, l.discountString);    
        }
        
        DBHelper.saveMainContext();        
    }

}