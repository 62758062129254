

class BookingsDayCell extends UITableViewCell 
{ 
    private hourLabel:MUILabel = null;    
    private hourInLabel:MUILabel = null;    
    private nameLabel:MUILabel = null;    
    private phoneLabel:MUILabel = null;
    private emailLabel:MUILabel = null;
	private paxLabel:MUILabel = null;
	private attendedLabel:MUILabel = null;
    private zonesLabel:MUILabel = null;
    private payedLabel:MUILabel = null;
    private showButton:MUIButton = null;

    awakeFromHTML() {
        this.hourLabel = MUIOutlet(this, "hour-lbl", "MUILabel");
        this.hourInLabel = MUIOutlet(this, "hour-in-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, 'name-lbl','MUILabel');
        this.phoneLabel = MUIOutlet(this, "phone-lbl", "MUILabel");
        this.emailLabel = MUIOutlet(this, "email-lbl", "MUILabel");
		this.paxLabel = MUIOutlet(this, 'pax-lbl','MUILabel');
		this.attendedLabel = MUIOutlet(this, 'attended-lbl', "MUILabel");
        this.zonesLabel = MUIOutlet(this, "zone-lbl", "MUILabel");
        this.payedLabel = MUIOutlet(this, "prepay-lbl", "MUILabel");
        this.showButton = MUIOutlet(this, "show-btn", "MUIButton");
        this.showButton.setAction(this, this.showAction);
    }

    private booking:Booking = null;
    set item(i:Booking) {
        this.booking = i;

        if (this.booking.status == BookingStatus.BookingConfirmed || this.booking.status == BookingStatus.BookingReserved) MUICoreLayerRemoveStyle(this.showButton.layer, "no-visible");
        else MUICoreLayerAddStyle(this.showButton.layer, "no-visible");

        let tf = MUIWebApplication.sharedInstance().delegate.timeFormatter as MIODateFormatter;

        this.hourLabel.text = i.hour;
        if (i.dateShow != null) {
            this.hourInLabel.text = tf.stringFromDate(i.dateShow).substr(0, 5);
        }
        else {
            this.hourInLabel.text = null;
        }

        if (i.externalReference != null && i.externalReference.length > 0){
            this.nameLabel.text = i.externalReference + " - " + i.clientName;
        }
        else{
            this.nameLabel.text = i.clientName;
        }
        
        let phone = i.client ? (i.client.mobilePhone ? i.client.mobilePhone.number : null) : null;
        let email = i.client ? i.client.email : null;

        this.phoneLabel.text = i.clientPhone ? i.clientPhone : phone;
        this.emailLabel.text = i.clientEmail ? i.clientEmail : email;

		this.paxLabel.text = i.pax;
		this.attendedLabel.text = i.paxAttend;
        
        this.zonesLabel.text = i.zoneName;
        
        let cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
        
        this.payedLabel.text = i.deposit > 0 ? cf.stringFromNumber(i.deposit) : null;
                
        // if (i.relationZones.length < 2) MUICoreLayerRemoveStyle(this.multipleZoneView.layer, "multiple-zone-icon");
        // else MUICoreLayerAddStyle(this.multipleZoneView.layer, "multiple-zone-icon");                        

        if (i.status == BookingStatus.BookingRequest){
            // REQUEST
            this.nameLabel.setTextRGBColor(255, 142, 9);
            this.paxLabel.setTextRGBColor(255, 142, 9);            
            // this.clientPhoneLabel.setTextRGBColor(255, 142, 9);
            // this.clientEmailLabel.setTextRGBColor(255, 142, 9);
        }
        else if (i.status == BookingStatus.BookingAssigned){
            // ASSIGNED
            this.nameLabel.setTextRGBColor(65, 93, 188);
            this.paxLabel.setTextRGBColor(65, 93, 188);            
            // this.clientPhoneLabel.setTextRGBColor(65, 93, 188);
            // this.clientEmailLabel.setTextRGBColor(65, 93, 188);
        }
        else if (i.status == BookingStatus.BookingConfirmed) {
            // CONFIRMED
            this.nameLabel.setTextRGBColor(0, 128, 64);
            this.paxLabel.setTextRGBColor(0, 128, 64);            
            // this.clientPhoneLabel.setTextRGBColor(0, 128, 64);
            // this.clientEmailLabel.setTextRGBColor(0, 128, 64);
        }        
        else if (i.status == BookingStatus.BookingFinished || i.status == BookingStatus.BookingShow){
            // FINISHED / SHOW
            this.nameLabel.setTextRGBColor(128, 237, 11);
            this.paxLabel.setTextRGBColor(128, 237, 11);            
            // this.clientPhoneLabel.setTextRGBColor(128, 237, 11);
            // this.clientEmailLabel.setTextRGBColor(128, 237, 11);
        }
        else if (i.status == BookingStatus.BookingWaiting){
            // WAITING LIST
            this.nameLabel.setTextRGBColor(211, 190, 15);
            this.paxLabel.setTextRGBColor(211, 190, 15);            
            // this.clientPhoneLabel.setTextRGBColor(211, 190, 15);
            // this.clientEmailLabel.setTextRGBColor(211, 190, 15);
        }
        else if (i.status == BookingStatus.BookingCancelled || i.status == BookingStatus.BookingDenied || i.status == BookingStatus.BookingDeleted){
            this.nameLabel.setTextRGBColor(200, 120, 120);
            this.paxLabel.setTextRGBColor(200, 120, 120);            
            // this.clientPhoneLabel.setTextRGBColor(200, 120, 120);
            // this.clientEmailLabel.setTextRGBColor(200, 120, 120);
        }
        else if (i.status == BookingStatus.BookingNoShow){
            // NO SHOW
            this.nameLabel.setTextRGBColor(167, 1, 203);
            this.paxLabel.setTextRGBColor(167, 1, 203);            
            // this.clientPhoneLabel.setTextRGBColor(167, 1, 203);
            // this.clientEmailLabel.setTextRGBColor(167, 1, 203);
        }
        else if (i.status == BookingStatus.BookingNoCC){
            // NO CC
            this.nameLabel.setTextRGBColor(237, 88, 141);
            this.paxLabel.setTextRGBColor(237, 88, 141);            
            // this.clientPhoneLabel.setTextRGBColor(237, 88, 141);
            // this.clientEmailLabel.setTextRGBColor(237, 88, 141);
        }
        else if (i.status == BookingStatus.BookingCCCanceled){
            this.nameLabel.setTextRGBColor(204, 102, 0);
            this.paxLabel.setTextRGBColor(204, 102, 0);            
            // this.clientPhoneLabel.setTextRGBColor(204, 102, 0);
            // this.clientEmailLabel.setTextRGBColor(204, 102, 0);            
        }
        else {
            this.nameLabel.setTextRGBColor(0, 0, 0);
            this.paxLabel.setTextRGBColor(0, 0, 0);            
            // this.clientPhoneLabel.setTextRGBColor(0, 0, 0);
            // this.clientEmailLabel.setTextRGBColor(0, 0, 0);
        }
    }

    private showAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Show", "Fill up the number of pax", MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.placeholderText = MIOLocalizeString("NUMBER OF PAX", "Number of PAX");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(){
            let tf = avc.textFields[0] as MUITextField;
            this.setShowStatus(tf.text);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "Cancel"), MUIAlertActionStyle.Cancel, null, null));

        AppHelper.sharedInstance().presentViewController(avc, true);
    }   

    private setShowStatus(value:string){
        let pax = parseInt(value);
        if (pax == null) {
            AppHelper.showErrorMessage(null, "Error", "The value is invalid");
            return;
        }

        this.booking.status = BookingStatus.BookingShow;
        this.booking.paxAttend = pax;

        DBHelper.saveMainContext();
    }
    

}
