//
// Generated class LapsopayLocation
//

/// <reference path="LapsopayLocation_ManagedObject.ts" />

class LapsopayLocation extends LapsopayLocation_ManagedObject
{

    public setObject(item)
    {
        this.identifier = item['id'];
        this.place = item['place'];
        this.name = item['name'];
        this.lapsopayId = item['lapsopayid'];
        this.locationName = item['locationname'];
    }
}
