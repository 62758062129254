

// Generated class ProductFormat_ManagedObject

class ProductFormat_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: additionalCostPrice
    set additionalCostPrice(value:number) {
        this.setValueForKey(value, 'additionalCostPrice');
    }
    get additionalCostPrice():number {
        return this.valueForKey('additionalCostPrice');
    }
    set additionalCostPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'additionalCostPrice');
    }
    get additionalCostPricePrimitiveValue():number {
        return this.primitiveValueForKey('additionalCostPrice');
    }

    // Property: costPrice
    set costPrice(value:number) {
        this.setValueForKey(value, 'costPrice');
    }
    get costPrice():number {
        return this.valueForKey('costPrice');
    }
    set costPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costPrice');
    }
    get costPricePrimitiveValue():number {
        return this.primitiveValueForKey('costPrice');
    }

    // Property: isDefaultFormat
    set isDefaultFormat(value:boolean) {
        this.setValueForKey(value, 'isDefaultFormat');
    }
    get isDefaultFormat():boolean {
        return this.valueForKey('isDefaultFormat');
    }
    set isDefaultFormatPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefaultFormat');
    }
    get isDefaultFormatPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefaultFormat');
    }

    // Property: consumptionQuantity
    set consumptionQuantity(value:number) {
        this.setValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantity():number {
        return this.valueForKey('consumptionQuantity');
    }
    set consumptionQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('consumptionQuantity');
    }

    // Property: consumptionMeasureType
    set consumptionMeasureType(value:number) {
        this.setValueForKey(value, 'consumptionMeasureType');
    }
    get consumptionMeasureType():number {
        return this.valueForKey('consumptionMeasureType');
    }
    set consumptionMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionMeasureType');
    }
    get consumptionMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('consumptionMeasureType');
    }

    // Property: lossQuantity
    set lossQuantity(value:number) {
        this.setValueForKey(value, 'lossQuantity');
    }
    get lossQuantity():number {
        return this.valueForKey('lossQuantity');
    }
    set lossQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossQuantity');
    }
    get lossQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('lossQuantity');
    }

    // Property: lossMeasureType
    set lossMeasureType(value:number) {
        this.setValueForKey(value, 'lossMeasureType');
    }
    get lossMeasureType():number {
        return this.valueForKey('lossMeasureType');
    }
    set lossMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossMeasureType');
    }
    get lossMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('lossMeasureType');
    }

    // Property: totalQuantity
    set totalQuantity(value:number) {
        this.setValueForKey(value, 'totalQuantity');
    }
    get totalQuantity():number {
        return this.valueForKey('totalQuantity');
    }
    set totalQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalQuantity');
    }
    get totalQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('totalQuantity');
    }

    // Property: totalMeasureType
    set totalMeasureType(value:number) {
        this.setValueForKey(value, 'totalMeasureType');
    }
    get totalMeasureType():number {
        return this.valueForKey('totalMeasureType');
    }
    set totalMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalMeasureType');
    }
    get totalMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('totalMeasureType');
    }

    // Property: modifiersRequired
    set modifiersRequired(value:boolean) {
        this.setValueForKey(value, 'modifiersRequired');
    }
    get modifiersRequired():boolean {
        return this.valueForKey('modifiersRequired');
    }
    set modifiersRequiredPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'modifiersRequired');
    }
    get modifiersRequiredPrimitiveValue():boolean {
        return this.primitiveValueForKey('modifiersRequired');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: format
    set format(value:Format) {
        this.setValueForKey(value, 'format');
    }
    get format():Format {
        return this.valueForKey('format') as Format;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }

    // Relationship: productModifierCategories
    protected _productModifierCategories:MIOManagedObjectSet = null;
    get productModifierCategories():MIOManagedObjectSet {
        return this.valueForKey('productModifierCategories');
    }
    addProductModifierCategoriesObject(value:ProductModifierCategory) {
        this._addObjectForKey(value, 'productModifierCategories');
    }
    removeProductModifierCategoriesObject(value:ProductModifierCategory) {
        this._removeObjectForKey(value, 'productModifierCategories');
    }

    // Relationship: productFormatRates
    protected _productFormatRates:MIOManagedObjectSet = null;
    get productFormatRates():MIOManagedObjectSet {
        return this.valueForKey('productFormatRates');
    }
    addProductFormatRatesObject(value:ProductFormatRate) {
        this._addObjectForKey(value, 'productFormatRates');
    }
    removeProductFormatRatesObject(value:ProductFormatRate) {
        this._removeObjectForKey(value, 'productFormatRates');
    }
}
