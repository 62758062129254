/**
 * Created by miguel on 23/2/17.
 */


class OffersListViewController extends BaseTableViewController
{
    private addButton:MUIButton = null;

    private offerDetailViewController:OfferDetailViewController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'add-btn','MUIButton');
        this.addButton.setAction(this, function(){
            this.addOffer();
        });

        this.tableView = MUIOutlet(this, 'table-view', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell:OfferCell = tableview.dequeueReusableCellWithIdentifier('OfferCell');
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
        cell.item = item;        
    
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let offer:Offer = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        if(this.offerDetailViewController == null) {
            this.offerDetailViewController = new OfferDetailViewController('offer-detail-view');
            this.offerDetailViewController.initWithResource('layout/offers/OfferDetailView.html');   
        }
        
        this.offerDetailViewController.offer = offer;
        this.splitViewController.showDetailViewController(this.offerDetailViewController);
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let zone = this.fetchedResultsController.objectAtIndexPath(indexPath);    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeOffer(zone);
        }
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Offer');
    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        // if (this._searchString &&  this._searchString.length > 0)
        //     fetchRequest.predicate = MIOPredicate.predicateWithFormat('name CONTAINS ' + this._searchString);
        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
        
    private addOffer(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle('New Offer', 'Type the name of the offer.', MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Name");
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            comboBox.removeAllItems();
            let types = Offer.typeStrings();
            for (let index = 0; index < types.length; index++){
                let offerType = types[index];
                comboBox.addItem(offerType, index);
            }
        });

        avc.addAction( MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, this, function(){
            let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
            let offer = MIOEntityDescription.insertNewObjectForEntityForName("Offer", ad.managedObjectContext) as Offer;
            offer.name = avc.textFields[0].text;
            offer.type = parseInt(avc.comboBoxes[0].getSelectedItem());
            ad.managedObjectContext.save();
        }));
        
        avc.addAction( MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);        
    }

    removeOffer(offer:Offer){
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        moc.deleteObject(offer);
        moc.save();    
    }
}