

// Generated class UserBusinessPermission_ManagedObject

class UserBusinessPermission_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: isAdmin
    set isAdmin(value:boolean) {
        this.setValueForKey(value, 'isAdmin');
    }
    get isAdmin():boolean {
        return this.valueForKey('isAdmin');
    }
    set isAdminPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAdmin');
    }
    get isAdminPrimitiveValue():boolean {
        return this.primitiveValueForKey('isAdmin');
    }

    // Property: isInvited
    set isInvited(value:boolean) {
        this.setValueForKey(value, 'isInvited');
    }
    get isInvited():boolean {
        return this.valueForKey('isInvited');
    }
    set isInvitedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isInvited');
    }
    get isInvitedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isInvited');
    }

    // Property: permissions
    set permissions(value:string) {
        this.setValueForKey(value, 'permissions');
    }
    get permissions():string {
        return this.valueForKey('permissions');
    }
    set permissionsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'permissions');
    }
    get permissionsPrimitiveValue():string {
        return this.primitiveValueForKey('permissions');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: user
    set user(value:User) {
        this.setValueForKey(value, 'user');
    }
    get user():User {
        return this.valueForKey('user') as User;
    }
    // Relationship: worker
    set worker(value:Employee) {
        this.setValueForKey(value, 'worker');
    }
    get worker():Employee {
        return this.valueForKey('worker') as Employee;
    }

    // Property: businessID
    set businessID(value:string) {
        this.setValueForKey(value, 'businessID');
    }
    get businessID():string {
        return this.valueForKey('businessID');
    }
    set businessIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'businessID');
    }
    get businessIDPrimitiveValue():string {
        return this.primitiveValueForKey('businessID');
    }
}
