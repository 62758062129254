/**
 * Created by erwinschneider on 30/12/16.
 */


class ProductDetailGeneralViewController extends MUIViewController
{        
    productType = ProductType.None;

    private productImageView:MUIImageView = null;
    private nameTextField:MUITextField = null;
    private stockNameTextField:MUITextField = null;
    private kitchenNameTextField:MUITextField = null;
    private ticketNameTextField:MUITextField = null;

    private categoryLabel:MUILabel = null;
    private categoryButton:MUIButton = null;
    private sourceButton:MUIButton = null;

    private segmentedControl:MUISegmentedControl = null;
    private pageController:MUIPageController = null;

    private elaborationTypeButton:MUIButton = null;
    private elaborationCenterButton:MUIButton = null;

    //Segmented
    private salesivc:ProductDetailSalesViewController = null;
    private stockivc:ProductDetailStockViewController = null;    
    private subproductsivc:ProductDetailSubproductsViewController = null;
    private allergiesViewController:ProductAllergiesViewController = null;
    private productSupplierVC:ProductSupplierViewController = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.productImageView = MUIOutlet(this, 'product_image','MUIImageView');
        let tapGesture = new MUIGestureRecognizer();
        tapGesture.initWithTarget(this, this.imageTapGesture);
        this.productImageView.addGestureRecognizer(tapGesture);

        this.nameTextField = MUIOutlet(this, "name_tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(textfield, value:string) {
            this._product.name = value.length > 0 ? value.trim() : null;
        });

        this.stockNameTextField = MUIOutlet(this, 'stock-name-tf', 'MUITextField');
        this.stockNameTextField.setOnChangeText(this, function(textfield, value:string) {                        
            this._product.stockName = value.length > 0 ? value.trim() : null;
        });

        this.kitchenNameTextField = MUIOutlet(this, "kitchen-name-tf", "MUITextField");
        this.kitchenNameTextField.setOnChangeText(this, function(textfield, value) {
            this._product.kitchenName = value.length > 0 ? value.trim() : null;
        });

        this.ticketNameTextField = MUIOutlet(this, "ticket-name-tf", "MUITextField");
        this.ticketNameTextField.setOnChangeText(this, function(textfield, value) {            
            this._product.ticketName = value.length > 0 ? value.trim() : null;
        });

        this.categoryLabel = MUIOutlet(this, "category-lbl", "MUILabel");

        //category button
        this.categoryButton = MUIOutlet(this, "category_btn", "MUIButton");
        this.categoryButton.setAction(this, function() {    
            if (this.productType == ProductType.Stock) {
                AppHelper.sharedInstance().showSelectStockCategoryViewControllerFromView(this.categoryButton, this._product.stockCategory, this, function(controller, category:StockCategory){
                    this._product.stockCategory = category;
                    this.categoryButton.title = category ? category.name : null;
                }, null, null);    
            }
            else {
                AppHelper.sharedInstance().showSelectCategoryViewControllerFromView(this.categoryButton, this._product.category, this, function(controller, category:Category){
                    this._product.category = category;
                    this.categoryButton.title = category ? category.name : null;
                }, null, null);
            }
        });        

        //source button
        this.sourceButton = MUIOutlet(this, "source_btn", "MUIButton");
        this.sourceButton.setAction(this, function() {    
            let vc = AppHelper.sharedInstance().selectViewController("ProductSource", "name", false, false, null, this, null);
            vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = vc.popoverPresentationController;
            pc.sourceRect = this.sourceButton.frame;
            pc.sourceView = this.sourceButton;
        
            this.presentViewController(vc, true);
        });

        this.elaborationTypeButton = MUIOutlet(this, "elaboration-type-dd", "MUIButton");
        this.elaborationTypeButton.setAction(this, function(){
            AppHelper.sharedInstance().showElaborationTypeViewController(this, this.elaborationTypeButton, this._product);
        });

        //elaboration button
        this.elaborationCenterButton = MUIOutlet(this, "elaboration_btn", "MUIButton");
        this.elaborationCenterButton.setAction(this, function() {    

            let vc = AppHelper.sharedInstance().selectViewController("EllaborationCenter", "name", false, false, null, this, null);
            vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = vc.popoverPresentationController;
            pc.sourceRect = this.elaborationCenterButton.frame;
            pc.sourceView = this.elaborationCenterButton;
            
            this.presentViewController(vc, true);
        });

        //segmented control
        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function (control, index) {
            this.setPageAtIndex(index);
        });

        //SEGMENTED BUTTON
        this.pageController = MUIOutlet(this, "pdpage_controller", "MUIPageController");

        this.salesivc = new ProductDetailSalesViewController("product-sale-info-view");
        this.salesivc.initWithResource("layout/products/ProductSaleInfoView.html");
        this.pageController.addPageViewController(this.salesivc);        

        this.stockivc = new ProductDetailStockViewController("product-stock-info-view");
        this.stockivc.initWithResource("layout/products/ProductStockInfoView.html");
        this.pageController.addPageViewController(this.stockivc);    
        
        this.subproductsivc = new ProductDetailSubproductsViewController("product-subproducts-info-view");
        this.subproductsivc.initWithResource("layout/products/ProductSubproductsInfoView.html");
        this.pageController.addPageViewController(this.subproductsivc); 

        this.allergiesViewController = new ProductAllergiesViewController("product-allergies-view");
        this.allergiesViewController.initWithResource("layout/products/ProductAllergiesView.html");
        this.pageController.addPageViewController(this.allergiesViewController);

        this.productSupplierVC = new ProductSupplierViewController("product-suppliers-price-view");
        this.productSupplierVC.initWithResource("layout/products/ProductSuppliersPriceView.html");
        this.pageController.addPageViewController(this.productSupplierVC);

        //END SEGMENTED

        //*****************************************************
        //NOTIFICATIONS
        MIONotificationCenter.defaultCenter().addObserver(this, "ProductMeasureUnitDidChange", function (notification) {
            if(this._product != null) {
                this.stockTextField.text = this._product.currentStock;
            }
        });

        MIONotificationCenter.defaultCenter().addObserver(this, "ProductPriceDidChange", function (notification) {
            if(this._product != null) {
                //let value = notification.object;
                this.updaterProfit();
            }
        });
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _product:Product = null;
    set product(value:Product){
        if (this._product != null) {
            this._product.removeObserver(this, "category");
            this._product.removeObserver(this, "stockCategory");
            this._product.removeObserver(this, "source");
            this._product.removeObserver(this, "ellaborationCenter");
            this._product.removeObserver(this, "measureUnitType");
            this._product.removeObserver(this, "tax");
            this._product.removeObserver(this, "price");
            this._product.removeObserver(this, "costPrice");
            this._product.removeObserver(this, "additionalCosts");
            this._product.removeObserver(this, "costType");
        }

        this._product = value;
        
        if (value != null) {
            this._product.addObserver(this, "category");
            this._product.addObserver(this, "stockCategory");
            this._product.addObserver(this, "source");
            this._product.addObserver(this, "ellaborationCenter");
            this._product.addObserver(this, "measureUnitType");
            this._product.addObserver(this, "tax");
            this._product.addObserver(this, "price");
            this._product.addObserver(this, "costPrice");
            this._product.addObserver(this, "additionalCosts");
            this._product.addObserver(this, "costType");
        }

        this.updateUI();
    }

    private updateUI(){
        if (this._product == null) return;
        if (this.viewIsLoaded == false) return;        

        this.productImageView.setImage(this._product.image ? this._product.image.urlByType(DBImageType.Product) : null);
        this.nameTextField.text = this._product.name;
        this.stockNameTextField.text = this._product.stockName;  
        this.kitchenNameTextField.text = this._product.kitchenName;
        this.ticketNameTextField.text = this._product.ticketName;

        if (this.productType != ProductType.Stock) {
            this.categoryLabel.text = MIOLocalizeString("CATEGORY", "CATEGORY");
            let category = this._product.category;
            this.categoryButton.setTitle(category ? category.name : '');
            this.segmentedControl.setTitleForSegmentedAtIndex(MIOLocalizeString("SALES", "SALES"), 0);
            this.segmentedControl.setTitleForSegmentedAtIndex(MIOLocalizeString("STOCK", "STOCK"), 1);            
        }
        else {
            this.categoryLabel.text = MIOLocalizeString("STOCK CATEGORY", "STOCK CATEGORY");
            let category = this._product.stockCategory;
            this.categoryButton.setTitle(category ? category.name : '');    
            this.segmentedControl.setTitleForSegmentedAtIndex(MIOLocalizeString("STOCK", "STOCK"), 0);
            this.segmentedControl.setTitleForSegmentedAtIndex(MIOLocalizeString("SALES", "SALES"), 1);
        }

        let source = this._product.source;
        this.sourceButton.setTitle(source ? source.name : '');   
        
        this.elaborationTypeButton.title = this._product.stockElaborationTypeString;
        let ec = this._product.ellaborationCenter;
        this.elaborationCenterButton.setTitle(ec ? ec.name : '');
        
        this.salesivc.product = this._product;
        this.stockivc.product = this._product;        
        this.subproductsivc.product = this._product;
        this.allergiesViewController.product = this._product;
        this.productSupplierVC.product = this._product;

        let index = MIOUserDefaults.standardUserDefaults().integerForKey("LastProductDetailSalesPageIndex");        
        this.segmentedControl.selectSegmentedAtIndex(index);
        this.setPageAtIndex(index);                
    }

    observeValueForKeyPath(keypath, type, object){

        if (type == "did") {

            switch (keypath) {

                case "category":
                    if (this.productType == ProductType.Stock) break;
                    this.categoryButton.setTitle(this._product.category ? this._product.category.name : null);
                    break;    

                case "stockCategory":
                    if (this.productType == ProductType.Sales) break;                
                    this.categoryButton.setTitle(this._product.stockCategory ? this._product.stockCategory.name : null);
                    break;        

                case "source":
                    let source = this._product.source;
                    this.sourceButton.setTitle(source ? source.name : null);
                    break;  

                case "ellaborationCenter":
                    let ellaborationCenter = this._product.ellaborationCenter;
                    this.elaborationCenterButton.setTitle(ellaborationCenter ? ellaborationCenter.name : null);
                    break;                  
            }                                    
        }
    }

    elaborationTypeDidUpdate(){
        this.elaborationTypeButton.title = this._product.stockElaborationTypeString;
    }

    private imageTapGesture(gesture:MUIGestureRecognizer){
        if (gesture.state != MUIGestureRecognizerState.Began) return;
        
        let panel = MUIOpenPanel.openPanel();
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        panel.beginSheetModalForWindow(ad.window, this, function(result:number){
            if (result == MIOFileHandlingPanel.OKButton) {
               let file = panel.files[0];               
               let ws = ad.webService;
       
               ws.uploadImage(file, this, function(code, data){
                   if (code == 200){
                       let imageID = data["id"];
                       let url = data["url"];
                       this.addProductImage(imageID, url);
                   }
                   else {
                       AppHelper.showErrorMessage(this, "Error", "Couldn't upload image. Please try again later.");
                   }
               });       
            }       
         });      
    }

    private addProductImage(imageID:string, imageURL:string){
        this._product.imageURL = imageURL;
        this._product.image = imageID;
        this.productImageView.setImage(imageURL);
    }

    private setPageAtIndex(index){
        let lastPageIndex = MIOUserDefaults.standardUserDefaults().setValueForKey("LastProductDetailSalesPageIndex", index);
        
        if (index == 0 && this.productType == ProductType.Sales) this.pageController.showPageAtIndex(0);
        else if (index == 0 && this.productType == ProductType.Stock) this.pageController.showPageAtIndex(1);
        else if (index == 1 && this.productType == ProductType.Sales) this.pageController.showPageAtIndex(1)
        else if (index == 1 && this.productType == ProductType.Stock) this.pageController.showPageAtIndex(0);
        else this.pageController.showPageAtIndex(index);        
    }

    //
    // Select Entity View Controller
    //

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "ProductSource"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductSource", sd, null);
            return fetchRequest;
        } else if (controller.identifier == "EllaborationCenter"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("EllaborationCenter", sd, null);
            return fetchRequest;
        }

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){
        let dismiss = true;

        if (controller.identifier == "ProductSource") {
            let source = item as ProductSource;
            this._product.source = source;            
        }
        else if (controller.identifier == "EllaborationCenter") {   
            let ec = item as EllaborationCenter;
            this._product.ellaborationCenter = ec;
        }
        
        return dismiss;
    }
    
}