class WorkerRoleCell extends UITableViewCell 
{
    nameLabel:MUILabel = null;
    private _separator = null;

    awakeFromHTML() {
        this.nameLabel = MUIOutlet(this, "wlv_tv_workers_name_lbl", "MUILabel");
        this._separator = MUIOutlet(this, "wlv_tv_separator", "MUIView");
    }

    set item(i) {
        this.nameLabel.text = i.name;
    }
}