//
// Generated class Warehouse
//

/// <reference path="Warehouse_ManagedObject.ts" />

class Warehouse extends Warehouse_ManagedObject
{
    setObject(object)
    {
        this.identifier = object["id"];
        
        this.name = object['name'];
        this.contactName = object['contactname'];
        this.email = object['email'];
        this.document = object['document'];
    }

    getObject()
    {
        var obj = {};

        if(this.identifier)
            obj['id'] = this.identifier;

        obj['name'] = this.name;
        obj['contactname'] = this.contactName;
        obj['email'] = this.email;
        obj['document'] = this.document;

        return obj;
    }

    mappingEntityByIntegrator(integrator:string, enviroment:string){
        let me = null;

        for (let index = 0; index < this.integrations.count; index++){
            let item = this.integrations.objectAtIndex(index) as DBMappingEntity;
            if (item.integrator == integrator && item.enviroment == enviroment) {
                me = item;
                break;
            }
        }

        return me;
    }

}
