class OnlineSettingsViewController extends MUIViewController
{        
    private saveButton:MUIButton = null;
    private tableView:UITableView = null;
    private onlineSettingsObjects:[] = null;
    private onlineSettingsObjectsDict:any = null;


    viewDidLoad(){
        super.viewDidLoad();

        this.getOnlineOrderSettings();

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        //sections Diseño, General, Carta Online, Take Away, Delivery
        return 5;
    }
    
    private sectionRows = [4, 4, 3, 4, 5]; //number of rows for each section
    numberOfRowsInSection(tableView:UITableView, section:number){
        return this.sectionRows[section];
    }


    private sectionTitles = ["DESIGN", "GENERAL", "ONLINE MENU", "TAKE AWAY", "DELIVERY"];
    titleForHeaderInSection(tableView:UITableView, section:number) {          
        return MIOLocalizeString(this.sectionTitles[section], this.sectionTitles[section]);
    }
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        

        let cell:any = null;
        
        switch (indexPath.section){
            case 0: //section Design
                switch (indexPath.row){
                    case 0:
                        cell = tableView.dequeueReusableCellWithIdentifier("OnlineSettingLogoCell") as OnlineSettingLogoCell;
                        cell.setName = MIOLocalizeString("COMPANY LOGO","COMPANY LOGO");
                        cell.setDesc ="Explanation, minimum size, etc.";
                        //cell.setItemURL = this.findObjectByKey(this.onlineSettingsObjects, "name", "onlineorder-companylogo-url")
                        //cell.setItemID = this.findObjectByKey(this.onlineSettingsObjects, "name", "onlineorder-companylogo-id")
                        cell.setItemURL = this.onlineSettingsObjectsDict["onlineorder-companylogo-url"];
                        cell.setItemID = this.onlineSettingsObjectsDict["onlineorder-companylogo-id"];
                    break;
        
                    case 1:
                        cell = tableView.dequeueReusableCellWithIdentifier("TextFieldColorSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("MAIN COLOR","MAIN COLOR");
                        cell.setDesc = "Explanation, where to use this color";
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-color-main"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 2:
                        cell = tableView.dequeueReusableCellWithIdentifier("TextFieldColorSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("SECONDARY COLOR","SECONDARY COLOR");
                        cell.setDesc = "Explanation, where to use this color";
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-color-secondary"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-color-secondary"));
                    break;
        
                    case 3:
                        cell = tableView.dequeueReusableCellWithIdentifier("TextFieldSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("FONT","FONT");
                        cell.setDesc = "Explanation, what fonts can be used, etc.";
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-font"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-font"));
                    break;

                }
            break;

            case 1: //section General
                switch (indexPath.row) {
                    case 0:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("WORKER","WORKER");
                        cell.setEntityName = "Employee"
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-worker"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-worker"));
                    break;

                    case 1:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("PRODUCT PRESET","PRODUCT PRESET");
                        cell.setEntityName = "Preset"
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-product-preset"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-product-preset"));
                    break;

                    case 2:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("SYNC APP","SYNC APP");
                        cell.setEntityName = "Application"
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-sync-app"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-sync-app"));
                    break;

                    case 3:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("DEFAULT PAYMENT ENTITY","DEFAULT PAYMENT ENTITY");
                        cell.setEntityName = "Paymententity"
                        cell.item = this.onlineSettingsObjectsDict["default-payment-entity"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == default-payment-entity"));
                    break;
                }
            break;

            case 2:  //section Online menu
                switch (indexPath.row){
                    case 0:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("PRESET","PRESET");
                        cell.setEntityName = "Preset"
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-menu-preset"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-menu-preset"));
                    break;
        
                    case 1:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("RATE","RATE");
                        cell.setEntityName = "Rate";
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-menu-rate"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 2:
                        cell = tableView.dequeueReusableCellWithIdentifier("SwitchSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("ACTIVATE DIGITAL MENU","ACTIVATE DIGITAL MENU");
                        cell.setDesc = "www.zestalicante.es/cartadigital?implace";
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-menu-enabled"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-menu-enabled"));
                    break;

                }
            break;

            case 3:  //section Take away
                switch (indexPath.row){
                    case 0:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("PRESET","PRESET");
                        cell.setEntityName = "Preset";
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-takeaway-preset"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 1:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("RATE","RATE");
                        cell.setEntityName = "Rate";
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-takeaway-rate"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 2:
                        cell = tableView.dequeueReusableCellWithIdentifier("PushSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("PICK UP SCHEDULE","PICK UP SCHEDULE");
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 3:
                        cell = tableView.dequeueReusableCellWithIdentifier("SwitchSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("ACTIVATE TAKEAWAY","ACTIVATE TAKEAWAY");
                        cell.setDesc = "www.zestalicante.es/cartadigital?implace";
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-takeaway-enabled"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;

                }
            break;

            case 4:  //section Delivery
                switch (indexPath.row){
                    case 0:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("PRESET","PRESET");
                        cell.setEntityName = "Preset";
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-delivery-preset"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 1:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("RATE","RATE");
                        cell.setEntityName = "Rate";
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-delivery-rate"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 2:
                        cell = tableView.dequeueReusableCellWithIdentifier("PushSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("DELIVERY SCHEDULE","DELIVERY SCHEDULE");
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 3:
                        cell = tableView.dequeueReusableCellWithIdentifier("PushSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("POSTAL CODES","POSTAL CODES");
                    break;

                    case 4:
                        cell = tableView.dequeueReusableCellWithIdentifier("SwitchSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("ACTIVATE DELIVERY","ACTIVATE DELIVERY");
                        cell.setDesc = "www.zestalicante.es/cartadigital?implace";
                        cell.item = this.onlineSettingsObjectsDict["onlineorder-delivery-enabled"];
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;

                }
            break;

        }

        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){      

        if (indexPath.section == 3 && indexPath.row == 2) {
            //take away
            let vc = new TimeRangeViewController("time-range-view");
            vc.initWithResource("layout/timerange/TimeRangeView.html");
            vc.timeRangeGroupType = TimeRangeGroupType.TakeAway;
            this.navigationController.pushViewController(vc, true);

        } else if (indexPath.section == 4 && indexPath.row == 2){
            //delivery
            let vc = new TimeRangeViewController("time-range-view");
            vc.initWithResource("layout/timerange/TimeRangeView.html");
            vc.timeRangeGroupType = TimeRangeGroupType.Delivery;
            this.navigationController.pushViewController(vc, true);
        } 
    }

    private getOnlineOrderSettings() {

        let predicate = MIOPredicate.predicateWithFormat("deletedAt == null");  //TO DO: filter by section == OnlineOrders
        DBHelper.queryObjectsWithCompletion("Configuration", null, predicate, [], this, function(objects){
            if (objects.length > 0){
                this.onlineSettingsObjects = objects; //used to find correct Configuration object using "findObjectByKey()"
                this.onlineSettingsObjectsDict = this.setupOnlineSettingsDict(objects); //used for referance as a dictionary
                this.tableView.reloadData();
            }
        });
    }

    private setupOnlineSettingsDict (array){
        let dict = {};
        for (var i = 0; i < array.length; i++) {
            dict[array[i].name] = array[i];
        }
        return dict;
    }

    private findObjectByKey(array, key, value) {
        for (var i = 0; i < array.length; i++) {
            if (array[i][key] === value) {
                return array[i];
            }
        }
        return null;
    }

    // private _fetchedResultsController = null;
    // set fetchedResultsController(value){
    //     if (value == null && this._fetchedResultsController != null)
    //         this._fetchedResultsController.delegate = null;
    
    //     this._fetchedResultsController = value;
    // }
    
    /*get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Configuration');    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('section', true)];
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "section");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }*/
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

}