
class BookingsClientSearchViewController extends MUIViewController
{    
    delegate = null;

    private closeButton:MUIButton = null;
    private addButton:MUIButton = null;
    private searchTextField:MUITextField = null;
    private clientTableView:MUITableView = null;
    private bookingTableView:MUITableView = null;

    private clientActivityIndicator:MUIActivityIndicatorView = null;
    private bookingActivityIndicator:MUIActivityIndicatorView = null; 

    private searchString:string = null;
    private delayTimer = null;    

    private _clientFetchedResultsController:MIOFetchedResultsController = null;
    private _bookingFetchedResultsController:MIOFetchedResultsController = null;

    get preferredContentSize(){
        return new MIOSize(640, 500);
    }

    viewDidLoad() {
        super.viewDidLoad();

        this.clientActivityIndicator = MUIOutlet(this, 'bcsv_client_activity-indicator','MUIActivityIndicatorView');
        this.clientActivityIndicator.setHidden(true);
        this.bookingActivityIndicator = MUIOutlet(this, 'bcsv_booking_activity-indicator','MUIActivityIndicatorView');
        this.bookingActivityIndicator.setHidden(true);

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function() {
            this.dismissViewController(true);
        });

        this.addButton = MUIOutlet(this, 'add-btn','MUIButton');
        this.addButton.setAction(this, function(){
            if(this.client == null){
                this.showAddNewClientViewController();
            }
            else {
                this.clientDidSelected();
            }
        });

        this.searchTextField = MUIOutlet(this, 'search-bar','MUITextField');
        this.searchTextField.setOnChangeText(this, function(textfield, value){
                        
            this.searchString = value.length > 0 ? value : null;
            if (this.delayTimer != null) this.delayTimer.invalidate();
            this.delayTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer){
                this.delayTimer = null;
                this.refreshTables();
            });            
        });

        this.clientTableView = MUIOutlet(this, 'client-table-view', 'MUITableView');
        this.clientTableView.dataSource = this;
        this.clientTableView.delegate = this;

        this.bookingTableView = MUIOutlet(this, 'booking-table-view', 'MUITableView');
        this.bookingTableView.dataSource = this;
        this.bookingTableView.delegate = this;        
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        //this.refreshTables();
        this.searchString = null;
        this.searchTextField.text = null;
    }

    private client:Client = null;
    public clientDidInserted(client:Client){
        this.client = client;
        this.clientDidSelected();
    }

    private refreshTables(){
        this.client = null;
        this.clientFetchedResultsController = null;
        this.clientActivityIndicator.setHidden(false);
        this.clientTableView.reloadData();
        if(this.client != null){
            this.bookingFetchedResultsController = null;
            this.bookingTableView.reloadData();
        }        
    }
   
    numberOfSections(tableview){
        if (tableview === this.clientTableView) {
            return this.clientFetchedResultsController.sections.length;
        }
        else if (tableview == this.bookingTableView) {
            return this.bookingFetchedResultsController.sections.length;
        }
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = null;
        if (tableview === this.clientTableView) {
            sec = this.clientFetchedResultsController.sections[section];
        }
        else if (tableview == this.bookingTableView) {
            sec = this.bookingFetchedResultsController.sections[section];
        }
        return sec ? sec.numberOfObjects() : 0;
    }
    
    titleForHeaderInSection(tableview:MUITableView, section){
        if(tableview == this.clientTableView)
            return 'Clients';
        else
            return 'Bookings';    
    }

    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell:SearchBookingClientCell|SearchBookingCell = null;
        let item = null;

        if (tableview === this.clientTableView) {
            cell = tableview.dequeueReusableCellWithIdentifier('SearchBookingClientCell') as SearchBookingClientCell;
            item = this.clientFetchedResultsController.objectAtIndexPath(indexPath);
        }
        else if (tableview == this.bookingTableView) {
            cell = tableview.dequeueReusableCellWithIdentifier('SearchBookingCell') as SearchBookingCell;
            cell.selectionStyle = MUITableViewCellSelectionStyle.None;
            item = this.bookingFetchedResultsController.objectAtIndexPath(indexPath);
        }
    
        cell.item = item;
    
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
    
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        if (tableView === this.clientTableView) {            
            this.client = this.clientFetchedResultsController.objectAtIndexPath(indexPath);
            ///this.getClientBookings();
            this.bookingFetchedResultsController = null;
            this.bookingActivityIndicator.setHidden(false);
            this.bookingTableView.reloadData();            
        }
        else if (tableView == this.bookingTableView) {          
            let booking:Booking = this.bookingFetchedResultsController.objectAtIndexPath(indexPath);
            if(this.delegate != null) this.delegate.bookingDidSelected(booking);
            this.dismissViewController(true);
        }
    }
    
    set clientFetchedResultsController(value){
        if (value == null && this._clientFetchedResultsController != null)
            this._clientFetchedResultsController.delegate = null;
    
        this._clientFetchedResultsController = value;
    }
    
    get clientFetchedResultsController(){
        if (this._clientFetchedResultsController != null)
            return this._clientFetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let queryString = null;
        
        if (this.searchString != null && this.searchString.length > 0) {                        
            queryString = 'name CONTAINS "' + this.searchString + '" OR phone.number CONTAINS "' + this.searchString + '" OR mobilePhone.number CONTAINS "' + this.searchString + '"';
        }            

        let fetchRequest = DBHelper.listFetchRequestWithEntityName('Client', sortDescriptors, queryString);
        fetchRequest.relationshipKeyPathsForPrefetching = ['address', 'phone', 'mobilePhone'];
        fetchRequest.fetchLimit = 15;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._clientFetchedResultsController = fetchedResultsController;
    
        return this._clientFetchedResultsController;
    }
    
    set bookingFetchedResultsController(value){
        if (value == null && this._bookingFetchedResultsController != null)
            this._bookingFetchedResultsController.delegate = null;
    
        this._bookingFetchedResultsController = value;
    }
    
    get bookingFetchedResultsController(){
        if (this.client == null) return null;

        if (this._bookingFetchedResultsController != null)
            return this._bookingFetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('date', false)];

        let queryString = 'clientID == "' + this.client.identifier + '"';
        
        let fetchRequest = DBHelper.listFetchRequestWithEntityName('Booking', sortDescriptors, queryString);
        fetchRequest.relationshipKeyPathsForPrefetching = ['client'];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._bookingFetchedResultsController = fetchedResultsController;
    
        return this._bookingFetchedResultsController;
    }

    controllerDidChangeContent(controller){
        if (controller === this.bookingFetchedResultsController){
            this.bookingActivityIndicator.setHidden(true);
            this.bookingTableView.reloadData();
        }
        else if (controller === this.clientFetchedResultsController){
            this.clientActivityIndicator.setHidden(true);
            this.clientTableView.reloadData();
        }
    }

    private showAddNewClientViewController(){
        let cvc = new AddNewClientViewController('add-new-client-view');        
        cvc.initWithResource('layout/clients/AddNewClientView.html');

        cvc.name = this.searchTextField.text;
        cvc.delegate = this;
        
        this.presentViewController(cvc, true);
    }

    private clientDidSelected(){
        if(this.delegate != null) this.delegate.clientDidSelected(this.client);
        this.dismissViewController(true);
    }

}