

// Generated class Card_ManagedObject

class Card_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: email
    set email(value:string) {
        this.setValueForKey(value, 'email');
    }
    get email():string {
        return this.valueForKey('email');
    }
    set emailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'email');
    }
    get emailPrimitiveValue():string {
        return this.primitiveValueForKey('email');
    }

    // Property: barcode
    set barcode(value:string) {
        this.setValueForKey(value, 'barcode');
    }
    get barcode():string {
        return this.valueForKey('barcode');
    }
    set barcodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'barcode');
    }
    get barcodePrimitiveValue():string {
        return this.primitiveValueForKey('barcode');
    }

    // Property: cardType
    set cardType(value:number) {
        this.setValueForKey(value, 'cardType');
    }
    get cardType():number {
        return this.valueForKey('cardType');
    }
    set cardTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'cardType');
    }
    get cardTypePrimitiveValue():number {
        return this.primitiveValueForKey('cardType');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: expirationDate
    set expirationDate(value:Date) {
        this.setValueForKey(value, 'expirationDate');
    }
    get expirationDate():Date {
        return this.valueForKey('expirationDate');
    }
    set expirationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'expirationDate');
    }
    get expirationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('expirationDate');
    }

    // Property: invitationConceptID
    set invitationConceptID(value:string) {
        this.setValueForKey(value, 'invitationConceptID');
    }
    get invitationConceptID():string {
        return this.valueForKey('invitationConceptID');
    }
    set invitationConceptIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invitationConceptID');
    }
    get invitationConceptIDPrimitiveValue():string {
        return this.primitiveValueForKey('invitationConceptID');
    }

    // Property: invitationResponsibleID
    set invitationResponsibleID(value:string) {
        this.setValueForKey(value, 'invitationResponsibleID');
    }
    get invitationResponsibleID():string {
        return this.valueForKey('invitationResponsibleID');
    }
    set invitationResponsibleIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invitationResponsibleID');
    }
    get invitationResponsibleIDPrimitiveValue():string {
        return this.primitiveValueForKey('invitationResponsibleID');
    }

    // Property: isSync
    set isSync(value:boolean) {
        this.setValueForKey(value, 'isSync');
    }
    get isSync():boolean {
        return this.valueForKey('isSync');
    }
    set isSyncPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isSync');
    }
    get isSyncPrimitiveValue():boolean {
        return this.primitiveValueForKey('isSync');
    }

    // Property: syncID
    set syncID(value:string) {
        this.setValueForKey(value, 'syncID');
    }
    get syncID():string {
        return this.valueForKey('syncID');
    }
    set syncIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'syncID');
    }
    get syncIDPrimitiveValue():string {
        return this.primitiveValueForKey('syncID');
    }

    // Property: externalPlaceID
    set externalPlaceID(value:string) {
        this.setValueForKey(value, 'externalPlaceID');
    }
    get externalPlaceID():string {
        return this.valueForKey('externalPlaceID');
    }
    set externalPlaceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalPlaceID');
    }
    get externalPlaceIDPrimitiveValue():string {
        return this.primitiveValueForKey('externalPlaceID');
    }

    // Property: externalCompanyID
    set externalCompanyID(value:string) {
        this.setValueForKey(value, 'externalCompanyID');
    }
    get externalCompanyID():string {
        return this.valueForKey('externalCompanyID');
    }
    set externalCompanyIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalCompanyID');
    }
    get externalCompanyIDPrimitiveValue():string {
        return this.primitiveValueForKey('externalCompanyID');
    }

    // Property: externalGroupID
    set externalGroupID(value:string) {
        this.setValueForKey(value, 'externalGroupID');
    }
    get externalGroupID():string {
        return this.valueForKey('externalGroupID');
    }
    set externalGroupIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalGroupID');
    }
    get externalGroupIDPrimitiveValue():string {
        return this.primitiveValueForKey('externalGroupID');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: legalEntity
    set legalEntity(value:EconomicEntity) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():EconomicEntity {
        return this.valueForKey('legalEntity') as EconomicEntity;
    }

    // Relationship: offerRules
    protected _offerRules:MIOManagedObjectSet = null;
    get offerRules():MIOManagedObjectSet {
        return this.valueForKey('offerRules');
    }
    addOfferRulesObject(value:OfferRule) {
        this._addObjectForKey(value, 'offerRules');
    }
    removeOfferRulesObject(value:OfferRule) {
        this._removeObjectForKey(value, 'offerRules');
    }
    // Relationship: department
    set department(value:Department) {
        this.setValueForKey(value, 'department');
    }
    get department():Department {
        return this.valueForKey('department') as Department;
    }
}
