//
// Generated class PaymentEntity
//

/// <reference path="PaymentEntity_ManagedObject.ts" />

enum PaymentEntityType
{
    Redsys = 0,
    Stripe = 1,
    CashOnDelivery = 2
}

class PaymentEntity extends PaymentEntity_ManagedObject
{
    static typeStringForType(type:PaymentEntityType):string {

        switch (type){
            case PaymentEntityType.Redsys:
                return "Redsys";

            case PaymentEntityType.Stripe:
                return "Stripe";
        }

        return null;
    }

    static supportedTypesForCurrentRegion(){
        let region = MIOLocale.currentLocale().countryCode;

        switch (region) {
            case "ES": 
                return [PaymentEntityType.Redsys];            
        }

        return [];
    }

    private _redsysConfig;
    redysConfig(){
        if (this._redsysConfig != null) return this._redsysConfig;

        if (this.config == null) return {};

        this._redsysConfig = JSON.parse(this.config);
        return this._redsysConfig;
    }

    setRedsysConfig(values) {
        this.config = JSON.stringify(values);
    }
}
