//
// Generated class BudgetLine
//

/// <reference path="BudgetLine_ManagedObject.ts" />

enum BudgetLineType {
    MainLine,
    DiscountLine,
    AdditionalCostLine,
    SubTotalLine,
    ServiceLine,
}

class BudgetLine extends BudgetLine_ManagedObject
{

}
