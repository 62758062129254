/**
 * Created by miguel on 15/3/17.
 */
 

/// <reference path="CashDeskReportViewController.ts" />


class CashDeskDetailViewController extends MUIViewController
{    
    private filterComboBox:MUIComboBox = null;
    private segmentedControl:MUISegmentedControl = null; 
    private exportButton:MUIButton = null;
    private sectionsPageController:MUIPageController = null;

    private summaryVC:CashDeskSummaryViewController = null;    
    private sessionsVC:CashDeskSessionListViewController = null;
   // private ticketsViewController:TicketsReportViewController = null;
    private newSummaryViewController:ReportViewController = null;
   
    viewDidLoad(){
        super.viewDidLoad();

        this.filterComboBox = MUIOutlet(this, "filter-type-cb", "MUIComboBox");
        this.filterComboBox.hidden = true;
        this.filterComboBox.setOnChangeAction(this, function(control, value){
            let index = parseInt(value);
            this.sessionsVC.filter = index;
        });                
        
        this.segmentedControl = MUIOutlet(this, 'segmented-control', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function (control, index){
            this.exportButton.hidden = (index != 0);
            this.filterComboBox.setHidden(index != 2);
            this.sectionsPageController.showPageAtIndex(index);
        });        

        this.exportButton = MUIOutlet(this, 'export-btn','MUIButton');
        this.exportButton.setHidden(false);
        this.exportButton.setAction(this, function(textfield, value){
            this.exportCashDeskSummary();
        });

        this.sectionsPageController = MUIOutlet(this, 'cddv_page_controller', 'MUIPageController');        

        this.newSummaryViewController = new ReportViewController("report-detail-view");
        this.newSummaryViewController.initWithResource("layout/reports/ReportDetailView.html");
        //this.newSummaryViewController.title = MIOLocalizeString("OPERATION SUMMARY", "OPERATION SUMMARY");
        //this._operationSummaryViewController.reportID = "C1E32789-9434-41C2-8364-2D6D1CF951DD";
        this.newSummaryViewController.reportScriptPath = "python/cashdesk_section_summary.py";   
        this.newSummaryViewController.title = MIOLocalizeString("CASHDESK SESSIONS SUMMARY", "CASHDESK SESSIONS SUMMARY");
        this.newSummaryViewController.showNavigationBar = false;  
        this.sectionsPageController.addPageViewController(this.newSummaryViewController);   

        // if (MIOCoreIsPhone() == true) {
        //     this.summaryVC = new CashDeskSummaryViewController("cashdesk-summary-view_phone");
        //     this.summaryVC.initWithResource("layout/cashdesk/CashDeskSummaryView_phone.html");    
        // }
        // else {
            this.summaryVC = new CashDeskSummaryViewController("cash-desk-summary-view");
            this.summaryVC.initWithResource("layout/cashdesk/CashDeskSummaryView.html");    
//        }
        this.sectionsPageController.addPageViewController(this.summaryVC);
        
        this.sessionsVC = new CashDeskSessionListViewController('cash-desk-sessions-list-view');
        this.sessionsVC.initWithResource('layout/cashdesk/CashDeskSessionsListView.html');
        this.sectionsPageController.addPageViewController(this.sessionsVC);

    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _cashDeskEntity:CashDeskEntity = null;
    private mode = CalendarMode.Day;
    set cashDeskEntity(cashDeskEntity:CashDeskEntity) {
        this._cashDeskEntity = cashDeskEntity;        
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this._cashDeskEntity == null) return;
        
        let rh = ReportHelper.sharedInstance();
        this.sessionsVC.setItems(this._cashDeskEntity, rh.selectedDateTimeFrom, rh.selectedDateTimeTo, this.mode);
        this.summaryVC.setItem(this._cashDeskEntity, rh.selectedDateTimeFrom, rh.selectedDateTimeTo, this.mode);        
    }

    private exportCashDeskSummary() {
        this.newSummaryViewController.exportReport();
    }
}