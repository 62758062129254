/**
 * Created by godshadow on 05/08/16.
 */



class SourceDetailViewController extends MUIViewController
{
    private nameTextField:MUITextField = null;
    private contactNameTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private activityIndicator:MUIActivityIndicatorView = null;

    private _source:BookingSource = null;
    private saveButton:MUIButton = null;
    private removeButton:MUIButton = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.activityIndicator = MUIOutlet(this, 'sdv_activity-indicator','MUIActivityIndicatorView');
        this.activityIndicator.setHidden(true);

        this.nameTextField = MUIOutlet(this, "sdv_c2_db1_name_text", "MUITextField");
    
        this.contactNameTextField = MUIOutlet(this, "sdv_c2_db2_contact_name_text", "MUITextField");

        this.phoneTextField = MUIOutlet(this, "sdv_c2_db3_phone_number_text", "MUITextField");

        this.emailTextField = MUIOutlet(this, "sdv_c2_db4_email_text", "MUITextField");

        this.saveButton = MUIOutlet(this, 'sdv_save_button', 'MUIButton');
        this.saveButton.setAction(this, function ()
        {
            this._source.email = this.emailTextField.text.trim();
            this._source.phone =  this.phoneTextField.text.trim();
            this._source.contactName =  this.contactNameTextField.text.trim();
            this._source.name =  this.nameTextField.text.trim();

            var moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            
            this.activityIndicator.setHidden(false);
            moc.save();
            this.activityIndicator.setHidden(true);
        });

        this.removeButton = MUIOutlet(this, 'sdv_delete_button', 'MUIButton');
        this.removeButton.setAction(this, function ()
        {
            var moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

            this.activityIndicator.setHidden(false);
            moc.deleteObject(this._source);
            this._source = null;
            this.activityIndicator.setHidden(true);
            moc.save();
        });

        this.updateUI();
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.updateUI();
    }

    set source(value)
    {
        this._source = value;
        this.updateUI();
    }

    private updateUI()
    {
        if(!this._source || !this.viewIsLoaded) return;

        this.nameTextField.text = this._source.name;
        this.contactNameTextField.text = this._source.contactName;
        this.phoneTextField.text = this._source.phone;
        this.emailTextField.text = this._source.email;
    }

}
