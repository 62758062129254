//
// Generated class Card
//

/// <reference path="Card_ManagedObject.ts" />

enum CardType {
    None,
    ByteFactory,
    External    
}

class Card extends Card_ManagedObject
{
    get nameString(){
        if (this.name != null) return this.name;
        if (this.legalEntity != null) return this.legalEntity.name;
        return null;
    }
}
