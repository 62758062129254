
/// <reference path="../base/BaseTableViewController.ts" />

/// <reference path="ReportFilterViewController.ts" />

class ReportListViewController extends BaseTableViewController
{    
    viewDidLoad(){
        super.viewDidLoad();

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");    
        this.tableView.dataSource = this;        
        this.tableView.delegate = this;        

        ReportHelper.sharedInstance().downloadReports(this, function(reports){
            this.needsReloadData();
        });
    }        
    
    titleForHeaderInSection(tableview, section){   
        let ip:MIOIndexPath = MIOIndexPath.indexForRowInSection(0, section); 
        let item = this.fetchedResultsController.objectAtIndexPath(ip);
        return MIOLocalizeString(item.section.toUpperCase(), item.section);
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('ReportListCell') as ReportListCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        if (SettingsHelper.sharedInstance().isNewServersLoaded) {
            cell.report = item;        
        }
        else {
            cell.item = item;
        }

        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        
        let report = this.fetchedResultsController.objectAtIndexPath(indexPath);
        if (report == null) return;

        if (SettingsHelper.sharedInstance().isNewServersLoaded) {
            let rvc = new ReportViewController("report-detail-view");
            rvc.initWithResource("layout/reports/ReportDetailView.html");            
            rvc.showSectionButton = false;
            rvc.showBackButton = false;
            
            if (MIOCoreIsPhone() == true) {
                rvc.showSplitBackButton = true;
                rvc.splitBackButtonTarget = this.splitViewController.displayModeButtonItem.target;
                rvc.splitBackButtonCompletion = this.splitViewController.displayModeButtonItem.action;            
            }

            rvc.report = report;

            let nc = new MUINavigationController();
            nc.initWithRootViewController(rvc);
            
            this.splitViewController.showDetailViewController(nc);    

        }
        else {

            if (report.scriptID == null) {

                let columns = report.columns;
                if (columns.length == 0) return null;
        
                let rvc = new ReportResultsViewController("report-results-view");
                rvc.initWithResource("layout/reports/ReportResultsView.html");
                rvc.backButtonHidden = true;
        
                // Activate the split back button for mobile
                if (MIOCoreIsPhone() == true) rvc.backSplitButtonHidden = false;
        
                rvc.report = report;
                
                for (let index = 0; index < columns.length; index++) {
                    let col = columns[index];
                    rvc.addColumn(col);
                }
        
                rvc.columnSubtotalsIndexes = report.subtotals;
    
                let nc = new MUINavigationController();
                nc.initWithRootViewController(rvc);
                
                this.splitViewController.showDetailViewController(nc);    
            }
            else {
                let rvc = new ReportViewController("report-detail-view");
                rvc.initWithResource("layout/reports/ReportDetailView.html");            
                rvc.showSectionButton = false;
                rvc.showBackButton = false;
                
                if (MIOCoreIsPhone() == true) {
                    rvc.showSplitBackButton = true;
                    rvc.splitBackButtonTarget = this.splitViewController.displayModeButtonItem.target;
                    rvc.splitBackButtonCompletion = this.splitViewController.displayModeButtonItem.action;            
                }
    
                rvc.item = report;
    
                let nc = new MUINavigationController();
                nc.initWithRootViewController(rvc);
                
                this.splitViewController.showDetailViewController(nc);    
            }

        }

    }

    get fetchRequest(){

        if (SettingsHelper.sharedInstance().isNewServersLoaded) {
            
            let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Report');
            fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('section', true),
                                            MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true),
                                            MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
            let predicateFormat = null;
            let searchLanguage = SettingsHelper.sharedInstance().currentSupportedLanguage;
            if (this.searchString != null) predicateFormat = searchLanguage + " CONTAINS '" + this.searchString + "'";
            if (predicateFormat != null) fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);    

            return fetchRequest;
        }
        else {
            let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('ReportListItem');
            fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('section', true),
                                            MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true),
                                            MIOSortDescriptor.sortDescriptorWithKey('title', true)];

            let predicateFormat = "isVisible == true";
            if (this.searchString != null) predicateFormat += " AND title CONTAINS '" + this.searchString + "'";
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);

            return fetchRequest;
        }
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(this.fetchRequest, ad.managedObjectContext, 'section');
        fetchedResultsController.delegate = this;        

        fetchedResultsController.performFetch(); 
        
        this._fetchedResultsController = fetchedResultsController;   
        return this._fetchedResultsController;
    }
  
    private reports = null;    
    private _loadReports(){
        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;
        ws.getReports(ad.selectedPlace, this, function(code, json){
           if(code == 200) 
           {
                let reports = json['data'];
                let categories = {};

                for (let index = 0; index < reports.length; index++) {
                    
                    let item = reports[index];
                    let type = item["type"];
                    let array = categories[type];
                    if (array == null){
                        array = [];
                        categories[type] = array;
                    }
                    array.push(item);
                }

                this.reports = [];
                for (let key in categories){

                    let array = categories[key];
                    this.reports.push({"Title": key, "Reports": array});                    
                }

              //category["Reports"] = 
              //this.reports.push(category);
              //this.reports = categories;
              this.tableView.reloadData();
           }
        });
    }

    public _rawData(){      
        return this['json'];
    }

    public _addTotals(){
        let data = this['json'];
        let totals = data['header']['totals'];
        data['body']['sections'][0]['rows'].push({
            "title": "TOTALS",
            "stotaltaxes": totals['stotaltaxes'],
            "stotalpricebase": totals['stotalpricebase'],
            "squantity": totals['squantity'],
            "btotaltaxes": totals['btotaltaxes'],
            "btotalpricebase": totals['btotalpricebase'],
            "bquantity": totals['bquantity']
        });
        return data;
    }
}
