/**
 * Created by miguel on 10/2/17.
 */




enum SectionType
{
    None ,
    SectionBar,
    SectionTable,
    SectionOnline
}

class AddNewCategorySectionViewController extends MUIViewController
{
    private options = [
        {type:SectionType.SectionBar,       name : 'Bar', selected : true},
        {type:SectionType.SectionTable,     name : 'Tables', selected : true},
        {type:SectionType.SectionOnline,    name : 'Online orders', selected : true},
    ];

    private tableView:MUITableView = null;
    private _category:Category = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, 'ancsv_c1_tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.reloadData();
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        if(this._category != null)
            this.tableView.reloadData();
    }

    public clear()
    {
        if(this.tableView != null && this._category != null) {
            this.tableView.reloadData();
        }
    }

    private isSectionEnabled(section:number):boolean
    {
        let enabled:boolean = false;

        switch (section) {
           
            case SectionType.SectionBar:
                enabled = this._category.showInBar;
                break;
            case SectionType.SectionTable:
                enabled = this._category.showInTables;
                break;
            case SectionType.SectionOnline:
                enabled = this._category.showInOnline;
                break;
        }

        return enabled;
    }

    private toggleSection(section:number)
    {
        switch (section) {
            
            case SectionType.SectionBar:
                this._category.showInBar = !this._category.showInBar;
                break;
            case SectionType.SectionTable:
                this._category.showInTables = !this._category.showInTables;
                break;
            case SectionType.SectionOnline:
                this._category.showInOnline = !this._category.showInOnline;
                break;
        }
    }

    set category(value)
    {
        this._category = value;
        this.clear();
    }

    numberOfSections(tableview)
    {
        return 1;
    }

    numberOfRowsInSection(tableview, section)
    {
        return this.options.length
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell:AddNewCategorySectionCell = tableview.dequeueReusableCellWithIdentifier("AddNewCategorySectionCell");

        var item = this.options[indexPath.row];

        cell.item = item;

        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;

        if(this.isSectionEnabled(item.type)) 
            cell.setAccessoryType(MUITableViewCellAccessoryType.Checkmark); 
        else
            cell.setAccessoryType(MUITableViewCellAccessoryType.None); 
        
        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        var item = this.options[indexPath.row];

        this.toggleSection(item.type);
        
        if(this.isSectionEnabled(item.type))
        {
            this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.Checkmark);    
        }
        else
        {
            this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.None);
        }
    }
}