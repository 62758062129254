

class AddGuestViewController extends MUIViewController
{

    private _backButton     = null;
    private _addButton       = null;
    
    private _newListComboBox   = null;
    private _newSourceComboBox = null;
    private _nameTextField  = null;
    private _paxTextField   = null;

    private _date          = null;
    private listCache = {};
    private _client = null;
    private _timer = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    private sdf = MUIWebApplication.sharedInstance().delegate.serverDateFormatter;
    private sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;
    
    delegate = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this._backButton = MUIOutlet(this, 'gav_back_btn','MUIButton');
        this._backButton.setAction(this, function()
        {
           this.navigationController.popViewController(true);
        });

        this._addButton = MUIOutlet(this, 'gav_ok_btn','MUIButton');
        this._addButton.setAction(this, function()
        {
            if(this.delegate != null) {
                let source = this._newSourceComboBox.getSelectedItem();
                let sourceName = this._newSourceComboBox.getSelectedItemText();
                let list = this._newListComboBox.getSelectedItem();
                let listData = this.listCache[list];
                let dateString = this.sdf.stringFromDate(this._date) + ' ' + (listData ? listData['hourfrom']: '');
                let listName = this._newListComboBox.getSelectedItemText();
                let clientName = this._nameTextField.text.trim();
                let pax = this.nf.numberFromString(this._paxTextField.text) | 0;

                let guest = this.delegate.createGuest(source, sourceName, list, listName, this._client, clientName, dateString, pax);
                if(guest != false) this._addNewLine(guest);
            } 
        });

        this._newListComboBox = MUIOutlet(this, 'gav_list_box','MUIComboBox');
        this._newSourceComboBox = MUIOutlet(this, 'gav_source_box','MUIComboBox');
        
        this._nameTextField = MUIOutlet(this, 'gav_name_box','MUITextField');
        this._nameTextField.setOnChangeText(this, function(textfield, value)
        {
            this._showSelectClientView(value);
        });

        this._nameTextField.setOnKeyPress(this, function(textfield, key) {
            
            //Catch tab key press
            if(key == 9 && this._client == null) {
                
                if (this._timer != null) this._timer.invalidate();
                if(this._filterClientVC != null && this._filterClientVC.isPresented) 
                    this._filterClientVC.dismissViewController(true);   
            } 
            if(key == 9 && this._client != null) {
                if(this._client.name.trim() != this._nameTextField.text.trim())
                    this.client = null;
                    this._filterClientVC.dismissViewController(true);
            }
        });

        this._paxTextField = MUIOutlet(this, 'gav_pax_box','MUITextField');
        this._paxTextField.formatter = this.nf;
        this._downloadBookingGuestList();
        this._initSourceComboBox();
    }

    viewWillAppear(animate ?)
    {
        super.viewWillAppear(animate);
        this._nameTextField.text = '';
        this._paxTextField.text = '';
        this._newSourceComboBox.selectItem("-2");
        this._newListComboBox.selectItem("-2");
       // this._downloadBookingGuestList();
       // 
    }

    set client(value)
    {
        this._client = value;
    }

    set date(value)
    {
        let sdt = this.sdtf.stringFromDate(this._date);
        let newdate = this.sdtf.stringFromDate(value);
        let reload = (sdt != newdate);
        this._date = value;
        if(this.viewIsLoaded && reload)
            this._downloadBookingGuestList();
    }

    private _downloadBookingGuestList()
    {
        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;
        let sdt = this.sdtf.stringFromDate(this._date);

        ws.getBookingGuestList(ad.selectedPlace, sdt, this, function(code, json){
           if(code == 200) 
           {  
              this._initListComboBox(json);  
           }
        });
    }

    private _initListComboBox(json)
    {
        this._newListComboBox.removeAllItems();
        this._newListComboBox.addItem('List...', -2);
        
        var items = json["data"];
        
        for(var i = 0; i < items.length; i++)
        {
            let item = items[i];
            let id = item['id'];
            this.listCache[id] = item;
            this._newListComboBox.addItem(item['name'], id);
        }
    }

    private _initSourceComboBox()
    {        
        this._newSourceComboBox.removeAllItems();
        this._newSourceComboBox.addItem(MIOLocalizeString('SOURCE','Source...'), -2);
        
        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let sources = ad.managedObjectContext.queryObject('BookingSource');
        for(let source in sources)
        {
            this._newSourceComboBox.addItem(sources[source]['name'], sources[source]['identifier']);
        }
    }

    private _showSelectClientView(value)
    {
        if(this._timer == null) {

            this._timer =  MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer) 
            {
                this._timer = null;
                if(!this._filterClientVC) {
                    this._filterClientVC = new SelectClientViewController('select_client_view');
                    this._filterClientVC.initWithResource('layout/modals/selectclient/SelectClientView.html');
                    this._filterClientVC.modalPresentationStyle = MUIModalPresentationStyle.Popover;
                
                    var filterClientVC = this._filterClientVC.popoverPresentationController;
                    filterClientVC.sourceRect = this._nameTextField.frame;
                    filterClientVC.sourceView = this._nameTextField;
                    this._filterClientVC.mode = ClientSearchMode.Client;
                    this._filterClientVC.delegate = this;                                    
                }                                
                
                this._filterClientVC.searchString = this._nameTextField.text;
                
                 if (!this._filterClientVC.isPresented)
                    this.presentViewController(this._filterClientVC, true);                                          
            });
        }
    }

    clientDidSelected(client)
    {
           // alert('Product: ' + product.name);
            this.client = client;
            this._nameTextField.text = client.name;
            this._nameTextField.becomeFirstResponder();
    }

    private _addNewLine(guest)
    {
        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;
      //  if(this._saveActivityIndicator) this._saveActivityIndicator.setHidden(false);
      /*  ws.insertBookingGuest(ad.selectedPlace, guest, this, function(code, json)
        {
           if(code == 200) 
           {
              var p = MIOEntityDescription.insertNewObjectForEntityForName("BookingGuest", ad.managedObjectContext) as BookingGuest;
              p.setObject(json['data']);
              ad.managedObjectContext.save();
              this._client = null;
              this._nameTextField.text = '';
              this._paxTextField.text = '';
              this._newListComboBox.selectItem(-2);
              this._newSourceComboBox.selectItem(-2);
              this.navigationController.popViewController(true);
           }
           else 
           {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), json['error'], MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Default, this, function()
                {
                }));
                        
                this.presentViewController(error, true);
           }
        //   if(this._saveActivityIndicator) this._saveActivityIndicator.setHidden(true);
        });*/
    }
}