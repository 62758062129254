class ReportListCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
    }

    set report (report:Report){                
        let lang = SettingsHelper.sharedInstance().currentSupportedLanguage;
        this.nameLabel.text = report.valueForKey(lang);
    }

    set item (item:ReportListItem){
        let name = item.title;
        this.nameLabel.text = MIOLocalizeString(name.toUpperCase(), name);
    }

}
