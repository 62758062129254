
class ReportsPermissionsDataSource extends MIOObject
{
    private tableView:MUITableView = null;
    initWithTableView(tableView:MUITableView){
        this.tableView = tableView;
    }

    private _user:User = null;
    get user(){return this._user;}
    set user(value:User) {
        this._user = value;
        this.tableView.reloadData();
    }

    numberOfSections(tableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    titleForHeaderInSection(tableView:MUITableView, section:number) {
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip); 
        if (item.section == null) return "";
        return MIOLocalizeString(item.section.toUpperCase(), item.section);
    }

    cellAtIndexPath(tableView:MUITableView, indexPath: MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("PermissionCell") as ReportsPermissionCell;
        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);                       
        //let name = MIOLocalizeString(item.title.toUpperCase(), item.title);

        cell.title = item.name;
        // this link dictates the use of the check mark
        //cell.accessoryType = this._user.businessPermission.hasManagerPermision(item.bitMask) ? MUITableViewCellAccessoryType.Checkmark : MUITableViewCellAccessoryType.None;
        return cell;
    }

    // canSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
    //     let item = this.fetchedResultsController.objectAtIndexPath(indexPath);

    //     let loginEmail = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginEmail");                        
    //     if (this._user.email == loginEmail && item.bitMask == 47) return false;

    //     return true;
    // }    

    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let cell = tableView.cellAtIndexPath(indexPath) as ReportsPermissionCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        // //if user currently has permission, remove them
        // if (this._user.businessPermission.hasManagerPermision(item.bitMask)) {
        //     // Remove
        //     this._user.businessPermission.removeManagerPermission(item.bitMask);
        //     cell.accessoryType = MUITableViewCellAccessoryType.None;
        // }
        // else {
        //     //add permission to the user.buinessPermission
        //     this._user.businessPermission.addManagerPermission(item.bitMask);
        //     cell.accessoryType = MUITableViewCellAccessoryType.Checkmark;
        // }        
    }

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    // get fetchedResultsController(){
    //     if (this._fetchedResultsController != null)
    //         return this._fetchedResultsController;
    
    //     let ad = MUIWebApplication.sharedInstance().delegate;
    
    //     let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('UserSection');
    //     fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('moduleIndex', true),
    //                                     MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
    
    //     let predicateValues = [];
    //     //predicateValues.push("bitMask & " + SettingsHelper.sharedInstance().permisisons + " > 0");
                
    //     if (predicateValues.length > 0) fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateValues.join(" AND "));

    //     let fetchedResultsController = new MIOFetchedResultsController();
    //     fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "moduleIndex");
    //     fetchedResultsController.delegate = this;
    
    //     fetchedResultsController.performFetch();
    
    //     this._fetchedResultsController = fetchedResultsController;    
    //     return this._fetchedResultsController;
    // }


    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Report');
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('section', true),
                                        MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true),
                                        MIOSortDescriptor.sortDescriptorWithKey('name', true)];

        //let predicateFormat = "isVisible == true";
        //if (this.searchString != null) predicateFormat += " AND title CONTAINS '" + this.searchString + "'";
        //fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, 'section');
        fetchedResultsController.delegate = this;        

        fetchedResultsController.performFetch(); 
        
        this._fetchedResultsController = fetchedResultsController;   
        return this._fetchedResultsController;
    }



    
    controllerDidChangeContent(controller:MIOFetchedResultsController){        
        this.tableView.reloadData();
    }
}