//
// Generated class ProductMenu
//

/// <reference path="ProductMenu_ManagedObject.ts" />

class ProductMenu extends ProductMenu_ManagedObject
{

}
