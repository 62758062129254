
class UserPermissionsDataSource extends MIOObject
{
    private tableView:MUITableView = null;
    initWithTableView(tableView:MUITableView){
        this.tableView = tableView;
    }

    private _user:User = null;
    get user(){return this._user;}
    set user(value:User) {
        this._user = value;
        this.tableView.reloadData();
    }

    numberOfSections(tableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    titleForHeaderInSection(tableView:MUITableView, section:number) {
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as UserSection;
        if (item.module == null) return "";
        return MIOLocalizeString(item.module, item.module);
    }

    cellAtIndexPath(tableView:MUITableView, indexPath: MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("PermissionCell") as UserPermissionCell;
        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as UserSection;                        
        let title = MIOLocalizeString(item.name, item.name);

        cell.title = title
        cell.accessoryType = this._user.businessPermission.hasManagerPermision(item.bitMask) ? MUITableViewCellAccessoryType.Checkmark : MUITableViewCellAccessoryType.None;

        return cell;
    }

    canSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as UserSection;

        let loginEmail = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginEmail");                        
        if (this._user.email == loginEmail && item.bitMask == 47) return false;

        return true;
    }    

    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let cell = tableView.cellAtIndexPath(indexPath) as UserPermissionCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as UserSection;
        
        if (this._user.businessPermission.hasManagerPermision(item.bitMask)) {
            // Remove
            this._user.businessPermission.removeManagerPermission(item.bitMask);
            cell.accessoryType = MUITableViewCellAccessoryType.None;
        }
        else {
            this._user.businessPermission.addManagerPermission(item.bitMask);
            cell.accessoryType = MUITableViewCellAccessoryType.Checkmark;
        }        
    }

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('UserSection');
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('moduleIndex', true),
                                        MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
    
        let predicateValues = [];
        //predicateValues.push("bitMask & " + SettingsHelper.sharedInstance().permisisons + " > 0");
                
        if (predicateValues.length > 0) fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateValues.join(" AND "));

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "moduleIndex");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){        
        this.tableView.reloadData();
    }
}