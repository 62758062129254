

// Generated class DBImage_ManagedObject

class DBImage_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: urlString
    set urlString(value:string) {
        this.setValueForKey(value, 'urlString');
    }
    get urlString():string {
        return this.valueForKey('urlString');
    }
    set urlStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'urlString');
    }
    get urlStringPrimitiveValue():string {
        return this.primitiveValueForKey('urlString');
    }

    // Property: resolution
    set resolution(value:string) {
        this.setValueForKey(value, 'resolution');
    }
    get resolution():string {
        return this.valueForKey('resolution');
    }
    set resolutionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'resolution');
    }
    get resolutionPrimitiveValue():string {
        return this.primitiveValueForKey('resolution');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
