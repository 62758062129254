

// Generated class PartyImage_ManagedObject

class PartyImage_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: urlString
    set urlString(value:string) {
        this.setValueForKey(value, 'urlString');
    }
    get urlString():string {
        return this.valueForKey('urlString');
    }
    set urlStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'urlString');
    }
    get urlStringPrimitiveValue():string {
        return this.primitiveValueForKey('urlString');
    }

    // Property: imageID
    set imageID(value:string) {
        this.setValueForKey(value, 'imageID');
    }
    get imageID():string {
        return this.valueForKey('imageID');
    }
    set imageIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'imageID');
    }
    get imageIDPrimitiveValue():string {
        return this.primitiveValueForKey('imageID');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: party
    set party(value:Party) {
        this.setValueForKey(value, 'party');
    }
    get party():Party {
        return this.valueForKey('party') as Party;
    }
}
