/**
 * Created by miguel on 15/2/17.
 */

class ProductSupportRequiredViewController extends MUIViewController
{
    private _closeButton:MUIButton = null;

    private _product:Product = null;

    private _stockIdInCheckButton:MUISwitchButton = null;
    private _stockIdOutCheckButton:MUISwitchButton = null;

    private _serialNumberInCheckButton:MUISwitchButton = null;
    private _serialNumberOutCheckButton:MUISwitchButton = null;

    private _expirationDateInCheckButton:MUISwitchButton = null;
    private _expirationDateOutCheckButton:MUISwitchButton = null;

    private _traceabilityIdInCheckButton:MUISwitchButton = null;
    private _traceabilityIdOutCheckButton:MUISwitchButton = null;

    private _customBarcodeInCheckButton:MUISwitchButton = null;
    private _customBarcodeOutCheckButton:MUISwitchButton = null;

    get preferredContentSize()
    {
        return new MIOSize(500, 500);
    }

    viewDidLoad()
    {
        super.viewDidLoad();

        this._closeButton = MUIOutlet(this, 'srv_nb_ok_btn', 'MUIButton');
        this._closeButton.setAction(this, function(){

            if(this._product.hasChanges)
                MIONotificationCenter.defaultCenter().postNotification("ProductChangeValue", null);

            this.dismissViewController(true);
        });

        this._stockIdInCheckButton = MUIOutlet(this, 'srv_tv_stock_set_id_switch1_btn', 'MUISwitchButton');
        this._stockIdInCheckButton.setOnChangeValue(this, function (value)
        {
            var field = this._product.stockSetIdRequiredOption;
            var active = field ^ 1;
            this._product.stockSetIdRequiredOption = active;
        });

        this._stockIdOutCheckButton = MUIOutlet(this, 'srv_tv_stock_set_id_switch2_btn', 'MUISwitchButton');
        this._stockIdOutCheckButton.setOnChangeValue(this, function (value)
        {
            var field = this._product.stockSetIdRequiredOption;
            var active = field ^ 2;

            this._product.stockSetIdRequiredOption = active;
        });

        this._serialNumberInCheckButton = MUIOutlet(this, 'srv_tv_serial_number_switch1_btn', 'MUISwitchButton');
        this._serialNumberInCheckButton.setOnChangeValue(this, function (value)
        {
            this._product.serialNumberRequiredOption = this._product.serialNumberRequiredOption ^ 1;
        });

        this._serialNumberOutCheckButton = MUIOutlet(this, 'srv_tv_serial_number_switch2_btn', 'MUISwitchButton');
        this._serialNumberOutCheckButton.setOnChangeValue(this, function (value)
        {
            this._product.serialNumberRequiredOption = this._product.serialNumberRequiredOption ^ 2;
        });

        this._expirationDateInCheckButton = MUIOutlet(this, 'srv_tv_expiration_date_switch1_btn', 'MUISwitchButton');
        this._expirationDateInCheckButton.setOnChangeValue(this, function (value)
        {
            this._product.expirationDateRequiredOption = this._product.expirationDateRequiredOption ^ 1;
        });

        this._expirationDateOutCheckButton = MUIOutlet(this, 'srv_tv_expiration_date_switch2_btn', 'MUISwitchButton');
        this._expirationDateOutCheckButton.setOnChangeValue(this, function (value)
        {
            this._product.expirationDateRequiredOption = this._product.expirationDateRequiredOption ^ 2;
        });

        this._traceabilityIdInCheckButton = MUIOutlet(this, 'srv_tv_traceability_id_switch1_btn', 'MUISwitchButton');
        this._traceabilityIdInCheckButton.setOnChangeValue(this, function (value)
        {
            this._product.traceabilityIdRequiredOption = this._product.traceabilityIdRequiredOption ^ 1;
        });

        this._traceabilityIdOutCheckButton = MUIOutlet(this, 'srv_tv_traceability_id_switch2_btn', 'MUISwitchButton');
        this._traceabilityIdOutCheckButton.setOnChangeValue(this, function (value)
        {
            this._product.traceabilityIdRequiredOption = this._product.traceabilityIdRequiredOption ^ 2;
        });

        this._customBarcodeInCheckButton = MUIOutlet(this, 'srv_tv_custom_barcode_switch1_btn', 'MUISwitchButton');
        this._customBarcodeInCheckButton.setOnChangeValue(this, function (value)
        {
            this._product.barcodeRequiredOption = this._product.barcodeRequiredOption ^ 1;
        });

        this._customBarcodeOutCheckButton = MUIOutlet(this, 'srv_tv_custom_barcode_switch2_btn', 'MUISwitchButton');
        this._customBarcodeOutCheckButton.setOnChangeValue(this, function (value)
        {
            this._product.barcodeRequiredOption = this._product.barcodeRequiredOption ^ 2;
        });

        this._updateUI();
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this._updateUI();
    }

    set product(value){
        this._product = value;
        this._updateUI();
    }


    private _updateUI()
    {
        if(!this._product || !this.viewIsLoaded) return;

        var stocksetidrequiredoption = this._product.stockSetIdRequiredOption;

        this._stockIdInCheckButton.setOn((stocksetidrequiredoption & 1) > 0);
        this._stockIdOutCheckButton.setOn((stocksetidrequiredoption & 2) > 0);

        var serialnumberrequiredoption = this._product.serialNumberRequiredOption;
        this._serialNumberInCheckButton.setOn((serialnumberrequiredoption & 1) > 0);
        this._serialNumberOutCheckButton.setOn((serialnumberrequiredoption & 2) > 0);

        var traceabilityidrequiredoption = this._product.traceabilityIdRequiredOption;

        this._traceabilityIdInCheckButton.setOn((traceabilityidrequiredoption & 1) > 0);
        this._traceabilityIdOutCheckButton.setOn((traceabilityidrequiredoption & 2) > 0);

        var expirationdaterequiredoption = this._product.expirationDateRequiredOption;

        this._expirationDateInCheckButton.setOn((expirationdaterequiredoption & 1) > 0);
        this._expirationDateOutCheckButton.setOn((expirationdaterequiredoption & 2) > 0);

        //var barcoderequiredoption = this._product.barcodeRequiredOption;

        // this._customBarcodeInCheckButton.setOn((barcoderequiredoption & 1) > 0);
        // this._customBarcodeOutCheckButton.setOn((barcoderequiredoption & 2) > 0);

    }
}