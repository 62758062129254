/**
 * Created by miguel on 16/3/17.
 */




class InvoiceGroupCell extends MUITableViewCell
{
    private nameLabel = null;
    private taxLabel = null;
    private unitPriceLabel = null;
    private quantityLabel = null;
    private discountLabel = null;
    private priceLabel = null;
   
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
   
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
            
        this.taxLabel = MUIOutlet(this, 'tax-lbl','MUILabel');
        this.unitPriceLabel = MUIOutlet(this, 'unit-price-lbl','MUILabel');
        this.quantityLabel = MUIOutlet(this, 'quantity-lbl','MUILabel');
        this.discountLabel = MUIOutlet(this, 'discount-lbl','MUILabel');
        this.priceLabel = MUIOutlet(this, "price-lbl", "MUILabel");            

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    set item(i:ArchivedTicketLinesGroup){ 
        this.nameLabel.text = i.productName;
        
        this.taxLabel.text =  i.taxName;
        this.unitPriceLabel.text = this.cf.stringFromNumber(i.productPrice);
        this.discountLabel.text = i.totalDiscount > 0 ? this.cf.stringFromNumber(i.totalDiscount) : null;        
        this.priceLabel.text = this.cf.stringFromNumber(i.totalPrice);

        if (i.bulkQuantity == null)
            this.quantityLabel.text = this.cf.stringFromNumber(i.lines.count);
        else {
            this.quantityLabel.text = this.cf.stringFromNumber(i.lines.count * i.bulkQuantity);
        }
    }

}