/**
 * Created by erwinschneider on 30/12/16.
 */

class ProductDetailSalesViewController extends MUIViewController
{   
    private enableSalesView:MUIView = null;
    private enableSalesButton:MUIButton = null;

    private isEnableForSalesButton:MUISwitchButton = null;

    private priceTextField:MUITextField = null;    
    private taxButton:MUIButton = null;

    private categoryButton:MUIButton = null;

    private variabletaxCheckButton:MUICheckButton = null;
    private onSaleCheckButton:MUICheckButton = null;
    private requiredmodifiersCheckButton:MUICheckButton = null;
    private isbulkCheckButton:MUICheckButton = null;    
    private isseasonproductCheckButton:MUICheckButton = null;
    private ontrackingCheckButton:MUICheckButton = null;

    private isFormatRequiredCheckButton:MUICheckButton = null;
    private defaultFormatButton:MUIButton = null;

    private addFormatButton:MUIButton = null;
    private formatsTableView:MUITableView = null;
    
    private manageModifiersButton:MUIButton = null;    

    private ratesTableView:MUITableView = null;    
    
    private formatDataSource:ProductFormatDataSource = null;
    private ratesDataSource:ProductRatesDataSource = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.enableSalesView = MUIOutlet(this, "page-cover", "MUIView");
        this.enableSalesButton = MUIOutlet(this, "enable-sales-btn", "MUIButton");
        this.enableSalesButton.setAction(this, function(control){
            this.showEnableSalesAlert();
        });

        this.isEnableForSalesButton = MUIOutlet(this, "enable-sale-sb", "MUISwitchButton");        
        this.isEnableForSalesButton.setOnChangeValue(this, function(control, value:boolean){
            this.showDisableSalesAlert();
        });

        this.priceTextField = MUIOutlet(this, "price_tf", "MUITextField");
        this.priceTextField.formatter = this.cf;
        this.priceTextField.setOnDidEndEditing(this, function(textfield){            
            this._product.price = this.cf.numberFromString(textfield.text);
            this.updatePriceNoTax();
            MIONotificationCenter.defaultCenter().postNotification("ProductChangeValue", null);        
        });

        this.taxButton = MUIOutlet(this, "tax-btn", "MUIButton");
        this.taxButton.setAction(this, function(control){
            let vc = AppHelper.sharedInstance().selectViewController("Tax", "name", false, false, null, this, null);
            vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = vc.popoverPresentationController;
            pc.sourceRect = this.taxButton.frame;
            pc.sourceView = this.taxButton;
        
            this.presentViewController(vc, true);
        });

        this.categoryButton = MUIOutlet(this, "category-dd", "MUIButton");
        this.categoryButton.setAction(this, function(){
            AppHelper.sharedInstance().showSelectCategoryViewControllerFromView(this.categoryButton, null, this, function(controller, category:Category){
                this._product.category = category;
                this.categoryButton.title = category ? category.name : null;
            }, null, null);
        });

        // this.onSaleCheckButton = MUIOutlet(this, 'available_sb', 'MUICheckButton');
        // this.onSaleCheckButton.setOnChangeValue(this,function (control, value){
        //     this._product.isAvailable = value;
        // });

        this.variabletaxCheckButton = MUIOutlet(this, 'tax_sb', 'MUICheckButton');
        this.variabletaxCheckButton.setOnChangeValue(this,function (control, value){
           this._product.isTaxVariable = value;            
        });

        this.requiredmodifiersCheckButton = MUIOutlet(this, 'modifier_sb', 'MUICheckButton');
        this.requiredmodifiersCheckButton.setOnChangeValue(this,function (control, value){
            this._product.modifiersRequired = value;            
        });

        this.isbulkCheckButton = MUIOutlet(this, 'bulk_sb', 'MUICheckButton');
        this.isbulkCheckButton.setOnChangeValue(this,function (control, value){
            this._product.isBulk = value;            
        });

        this.isseasonproductCheckButton = MUIOutlet(this, 'season_sb', 'MUICheckButton');
        this.isseasonproductCheckButton.setOnChangeValue(this,function (control, value){
            this._product.isSeasonProduct = value;            
        });

        this.ontrackingCheckButton = MUIOutlet(this, 'tracking_sb', 'MUICheckButton');
        this.ontrackingCheckButton.setOnChangeValue(this, function (control, value){
            this._product.isTrackingProduct = value;            
        });

        this.isFormatRequiredCheckButton = MUIOutlet(this, "format-required-check", "MUICheckButton");
        this.isFormatRequiredCheckButton.setOnChangeValue(this, function(control, value){
            this._product.formatRequired = value;
        });

        // Requiered format
        this.defaultFormatButton = MUIOutlet(this, "default-format-btn", "MUIButton");
        this.defaultFormatButton.setAction(this, function(control){
            let vc = AppHelper.sharedInstance().selectViewController("ProductFormat", "format.name", true, false, null, this, null) as  SelectEntityViewController;               
            vc.selectedObject = this._product.defaultProductFormat;
            vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = vc.popoverPresentationController;
            pc.sourceRect = this.defaultFormatButton.frame;
            pc.sourceView = this.defaultFormatButton;
        
            this.presentViewController(vc, true);
        });        

        this.addFormatButton = MUIOutlet(this, "add-format-btn", "MUIButton");
        this.addFormatButton.setAction(this, function () {
            this.showAddProductFormat();
        });
        
        this.formatsTableView = MUIOutlet(this, "format-table-view","MUITableView");
        this.formatDataSource = new ProductFormatDataSource();
        this.formatDataSource.initWithTableView(this.formatsTableView, this);
        this.formatsTableView.dataSource = this.formatDataSource;
        this.formatsTableView.delegate = this.formatDataSource;        

        this.manageModifiersButton = MUIOutlet(this, 'manage_btn', 'MUIButton');
        this.manageModifiersButton.setAction(this, function (){
            let vc = new ProductModifiersCategoryViewController('product-modifier-category-view');
            vc.initWithResource('layout/productmodifiercategory/ProductModifierCategoryView.html')
            vc.delegate = this;
            vc.modifiers = this._product.productModifiersCategories;

            this.presentViewController(vc, true);    
        });        

        this.ratesTableView = MUIOutlet(this, "rates-table-view", "MUITableView");
        this.ratesDataSource = new ProductRatesDataSource();
        this.ratesDataSource.initWithTableView(this.ratesTableView);
        this.ratesTableView.dataSource = this.ratesDataSource;
        this.ratesTableView.delegate = this.ratesDataSource;    
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();        
    }

    private _product:Product = null;
    set product(value){
        if (this._product != null){
            this._product.removeObserver(this, "category");
        }
        this._product = value;
        if (this._product != null){
            this._product.addObserver(this, "category");
        }

        this.updateUI();
    }

    observeValueForKeyPath(keypath, type, object){

        if (type == "did") {

            switch (keypath) {

                case "category":                    
                    this.categoryButton.setTitle(this._product.category ? this._product.category.name : null);
                    break;    
            }
        }
    }

    private updateUI(){
        if (this._product == null) return;
        if (this.viewIsLoaded == false) return;
        
        this.isEnableForSalesButton.on = this._product.isEnableForSell;

        this.priceTextField.text = this.cf.stringFromNumber(this._product.price) ;
        this.taxButton.title = this._product.taxString();

        let category = this._product.category;
        this.categoryButton.setTitle(category ? category.name : '');

        this.variabletaxCheckButton.on = this._product.isTaxVariable;
        this.requiredmodifiersCheckButton.on = this._product.modifiersRequired;
        this.isbulkCheckButton.on = this._product.isBulk;        
        this.isseasonproductCheckButton.on = this._product.isSeasonProduct;
        this.ontrackingCheckButton.on = this._product.isTrackingProduct;
        //this.onSaleCheckButton.on = this._product.isEnableForSale;
        this.enableSalesView.hidden = this._product.isEnableForSell;

        this.isFormatRequiredCheckButton.on = this._product.formatRequired;
        this.isFormatRequiredCheckButton.enabled = this._product.defaultProductFormat == null ? false : true;        
        if (this._product.defaultProductFormat != null) {
            if (this._product.defaultProductFormat.format == null)  this.defaultFormatButton.title = "Default Format DELETED!!";
            else 
                this.defaultFormatButton.title = this._product.defaultProductFormat.format.name;
        }
        else {
            this.defaultFormatButton.title = MIOLocalizeString("NONE", "NONE");
        }
        
        this.updatePriceNoTax();        
        this.formatDataSource.product = this._product;
        this.ratesDataSource.product = this._product;
    }

    public productTaxDidChange(controller:SelectEntityViewController, tax:Tax){
        this._product.tax = tax;
        this.taxButton.title = this._product.tax.name;
        this.updatePriceNoTax();
    }

    private updatePriceNoTax(){
        let tax:Tax = this._product.defaultTax;

        let price = this._product.price;
        if (isNaN(price)) price = 0;

        if(tax && tax.taxQuantity > 0)
            price = price / (1 + tax.taxQuantity);        
    }
    
    private showAddProductFormat(){
        let vc = AppHelper.sharedInstance().selectViewController("Format", "name", false, false, null, this);
        vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;

        let pc = vc.popoverPresentationController;
        pc.sourceRect = this.addFormatButton.frame;
        pc.sourceView = this.addFormatButton;

        this.presentViewController(vc, true);
    }

    modifierCategoryDidSelect(modifierCategory:ProductModifierCategory){
        this._product.addProductModifiersCategoriesObject(modifierCategory);
    }

    modifierCategoryDidDeselect(modifierCategory:ProductModifierCategory){
        this._product.removeProductModifiersCategoriesObject(modifierCategory);
    }

    //
    // Tableview delegate
    //

    productFormatDidSelect(productFormat:ProductFormat){        
        let vc = new ProductFormatDetailViewController('product-formats-detail-view');
        vc.initWithResource('layout/productformat/ProductFormatsDetailView.html')        
        vc.productFormat = productFormat;

        let nc = new MUINavigationController("product-format-nav");
        nc.initWithRootViewController(vc);

        this.presentViewController(nc, true);
    }

    //
    // Select Entity View Controller
    //

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "Tax"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Tax", sd, null);
            return fetchRequest;
        }
        else if (controller.identifier == "ProductFormat"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductFormat", sd, 'product.identifier = ' + this._product.identifier);
            fetchRequest.relationshipKeyPathsForPrefetching = ['format'];
            return fetchRequest;            
        }
        else if (controller.identifier == "Format"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Format", sd, null);
            return fetchRequest;            
        }

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){
        let dismiss = true;

        if (controller.identifier == "Tax") {
            let tax = item as Tax;
            this._product.tax = tax;            
            this.productTaxDidChange(controller, tax);
        }
        else if (controller.identifier == "ProductFormat") {
            let pf = item as ProductFormat; 
            this.updateDefaultProductFormat(pf);
        }
        else if (controller.identifier == "Format") {
            let format = item as Format;

            // Check if it's already added
            for (let index = 0; index < this._product.productFormats.count; index++){
                let obj = this._product.productFormats.objectAtIndex(index) as ProductFormat;
                if (obj.format.identifier == format.identifier) {
                    AppHelper.showAlert(this, "Warning", "You can't add a format that is already added.");
                    return dismiss;
                }
            }            
            let pf = DBHelper.createProductFormat(this._product, format);
            DBHelper.saveMainContext();
        }
        
        return dismiss;
    }

    private updateDefaultProductFormat(productFormat:ProductFormat){
        // reset product formats
        for (let index = 0; index < this._product.productFormats.count; index++){
            let pf = this._product.productFormats.objectAtIndex(index) as ProductFormat;
            pf.isDefaultFormat = false;
        }
                
        if (productFormat == null) {
            this._product.formatRequired = false;
            this.isFormatRequiredCheckButton.on = false;
        }
        this.isFormatRequiredCheckButton.enabled = productFormat == null ? false : true;
                
        this._product.defaultProductFormat = productFormat;
        if (productFormat != null) productFormat.isDefaultFormat = true;        
        this.defaultFormatButton.title = productFormat != null ? productFormat.format.name : MIOLocalizeString("NONE", "NONE");        
    }

    private showEnableSalesAlert(){
        if (this._product == null) return;

        let avc = new MUIAlertViewController();
        avc.initWithTitle("Warning", "Are you sure you want to enable sales support?", MUIAlertViewStyle.Default);
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('YES, ENBALE IT', 'YES, ENBALE IT'), MUIAlertActionStyle.Default, this, function(){
            this._product.isEnableForSell = true;
            this.enableSalesView.hidden = true;
            this.isEnableForSalesButton.on = true;
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'CANCEL'), MUIAlertActionStyle.Cancel, this, function(){
            this.isEnableForSalesButton.on = false;
        }));

        this.presentViewController(avc, true);
    }

    private showDisableSalesAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Warning", "Are you sure you want to disable sales support?", MUIAlertViewStyle.Default);
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('YES', 'YES'), MUIAlertActionStyle.Default, this, function(){
            this._product.isEnableForSell = false;
            this._product.category = null;
            this.enableSalesView.hidden = false;
            this.enableSalesButton.on = false;
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'CANCEL'), MUIAlertActionStyle.Cancel, this, function(){
            this.isEnableForSalesButton.on = true;
        }));

        this.presentViewController(avc, true);
    }
}