
class CashDeskSessionViewController extends MUIViewController
{ 
    private backButton:MUIButton = null;
    private segmentedControl:MUISegmentedControl = null;
    private adjustSessionButton:MUIButton = null;
	private exportButton:MUIButton = null;
	private commentTextField:MUITextField = null;

    private pageController:MUIPageController = null;

    private summaryViewController:CashDeskSessionSummaryViewController = null;
    private sessionLinesViewController:CashDeskSessionLinesViewController = null;
    private operationsViewController:CashDeskSessionOperationListViewController = null;
    private documentsViewController:CashDeskSessionDocumentListViewController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton= MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(){
            this.navigationController.popViewController();
        });

        this.segmentedControl = MUIOutlet(this, "segmented", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function (control, index) {
           this.pageController.showPageAtIndex(index);
        });

        this.adjustSessionButton = MUIOutlet(this, "close-session-btn", "MUIButton");        
        this.adjustSessionButton.setAction(this, function(){
            this.showSessionCountViewController();
        });

        this.exportButton = MUIOutlet(this, 'export-btn','MUIButton');
        this.exportButton.setAction(this, function(){
            this.exportSession(); 
        });

        this.pageController = MUIOutlet(this, 'page-controller', 'MUIPageController');

        this.summaryViewController = new CashDeskSessionSummaryViewController("cash-desk-session-summary-view");
        this.summaryViewController.initWithResource("layout/cashdesk/CashDeskSessionSummaryView.html");
        this.summaryViewController.session = this._session;
        this.pageController.addPageViewController(this.summaryViewController);

        this.sessionLinesViewController = new CashDeskSessionLinesViewController("cash-desk-session-lines-view");
        this.sessionLinesViewController.initWithResource("layout/cashdesk/CashDeskSessionLinesView.html");
        this.sessionLinesViewController.session = this._session;
        this.pageController.addPageViewController(this.sessionLinesViewController);

        this.operationsViewController = new CashDeskSessionOperationListViewController("cash-desk-operations");
        this.operationsViewController.initWithResource("layout/cashdesk/CashDeskOperationsView.html");
        this.operationsViewController.session = this._session;
        this.pageController.addPageViewController(this.operationsViewController);

        this.documentsViewController = new CashDeskSessionDocumentListViewController("cash-desk-session-tickets-view");
        this.documentsViewController.initWithResource("layout/cashdesk/CashDeskSessionTicketsView.html");
        this.documentsViewController.session = this._session;
		this.pageController.addPageViewController(this.documentsViewController);     
		
		this.commentTextField = MUIOutlet(this, "comment-session-tf", "MUITextField");
    }    

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _session:CashDeskSession = null; 
    set session(session:CashDeskSession){
        this._session = session;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._session == null) return;

        this.adjustSessionButton.setHidden(this._session.isOpen);
        this.summaryViewController.session = this._session;
        this.sessionLinesViewController.session = this._session;
        this.operationsViewController.session = this._session;
        this.documentsViewController.session = this._session; 
    }

    private showSessionCountViewController(){
        let vc:CashDeskSessionCountViewController = new CashDeskSessionCountViewController("cashdesk-session-count-view");
        vc.initWithResource("layout/cashdesk/CashDeskSessionCountView.html");
        vc.session = this._session;

        this.navigationController.pushViewController(vc, true);
    }

    private exportSession(){
        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;
        ws.exportPDFCashDeskSession(this._session , null, null);
    }
}