/// <reference path="../notes/NoteLineCell.ts" />

class InventoryNoteLineCell extends NoteLineCell
{
    private estimantedQuantityLabel:MUILabel = null;
    private inputTypeDropdown:MUIButton = null;
    private inputTypeLabel:MUILabel = null;
    private quantityTextField:MUITextField = null;
    private quantityLabel:MUILabel = null;
    private productQuantityLabel:MUILabel = null;    
    
    awakeFromHTML(){
        super.awakeFromHTML();
    
        this.estimantedQuantityLabel = MUIOutlet(this, "estimated-quantity-lbl", "MUILabel");

        this.inputTypeDropdown = MUIOutlet(this, "input-type-dd", "MUIButton");
        this.setupInputFormatDropdown(this.inputTypeDropdown);
        this.inputTypeLabel = MUIOutlet(this, "input-type-lbl", "MUILabel");
    
        this.quantityTextField = MUIOutlet(this, 'quantity-tf', 'MUITextField');   
        this.setupQuantityTextField(this.quantityTextField);        
        this.quantityLabel = MUIOutlet(this, "quantity-lbl", "MUILabel");
        
        this.productQuantityLabel = MUIOutlet(this, "product-quantity-lbl", "MUILabel");        

        this.reset();
        
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }
        
    setLine(item:StockNoteLine){
        super.setLine(item);
        
        this.productLabel.text = this.productNameString();

        if (item.estimatedQuantity != null) this.estimantedQuantityLabel.text = this.nf.stringFromNumber(item.estimatedQuantity);

        if (this.inputTypeDropdown != null) {
            this.inputTypeDropdown.title = this.inputFormatString();
            this.inputTypeDropdown.enabled = true;
        }

        if (this.inputTypeLabel != null) this.inputTypeLabel.text = this.inputFormatString();

        let q = this.nf.stringFromNumber(item.quantity);
        if (this.quantityLabel != null) this.quantityLabel.text = q;
        if (this.quantityTextField != null) {
            this.quantityTextField.text = q;
            this.quantityTextField.enabled = true;
        }
        
        this.productQuantityLabel.text = this.productQuantityString();        
    }

    productDidSelect(controller:SelectEntityViewController, product:Product){
        this.setProduct(product);
        
        this.productTextField.text = this.productNameString();                
        
        this.inputTypeDropdown.enabled = true;
        this.inputTypeDropdown.title = product.defaultInputFormatString();        

        this.quantityTextField.enabled = true;
        this.quantityTextField.becomeFirstResponder();
    }

    protected inputFormatDidSelect(controller:SelectInputFormatViewController, inputFormat:StockInputFormat, inputType:MeasureUnitType){
        super.inputFormatDidSelect(controller, inputFormat, inputType);
        this.inputTypeDropdown.title = this.inputFormatString();        
        this.productQuantityLabel.text = this.productQuantityString();
    }
    
    protected quantityDidChange(quantity){        
        super.quantityDidChange(quantity);        
        this.productQuantityLabel.text = this.productQuantityString();        
    }

    enterDidPress(){
        if (this.stockNoteLine != null) return;        
        if (this.quantity == null) return;
                
        let line = DBHelper.createInventoryNoteLine(this.product, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.measureQuantity, this.stockNote);
        if (line != null) {
            this.lineDidInserted(line);
            this.reset();
        }
    }

    reset(){
        this.product = null;        
        this.inputFormat = null;
        this.quantity = 0;
        this.productQuantity = 0;
        
        if (this.productTextField != null) this.productTextField.text = null;

        this.estimantedQuantityLabel.text = null;

        if (this.inputTypeDropdown != null) {
            this.inputTypeDropdown.title = null;
            this.inputTypeDropdown.enabled = false;            
        }
        
        if (this.quantityTextField != null) {
            this.quantityTextField.text = null;
            this.quantityTextField.enabled = false;
        }
        this.productQuantityLabel.text = null;
    }
}
