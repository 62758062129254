
class ReportsAccessViewController extends BaseTableViewController
{
    private saveButton:MUIButton = null;
    private addButton:MUIButton = null;
    private actionButton:MUIButton = null;        
    private reportsPermissionsTableView:MUITableView = null;

    private reportsPermissionsDataSource:ReportsPermissionsDataSource = null;

    viewDidLoad(){
        super.viewDidLoad();

        //same button disabled 
        // this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        // this.saveButton.setAction(this, function(){
        //     DBHelper.saveMainContext();
        // });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            this.inviteUserAction();
        });

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(){
            this.showActionsAlert();
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        

        this.reportsPermissionsTableView = MUIOutlet(this, "permissions-table-view", "MUITableView");
        this.reportsPermissionsDataSource = new ReportsPermissionsDataSource();
        this.reportsPermissionsDataSource.initWithTableView(this.reportsPermissionsTableView);
        this.reportsPermissionsTableView.dataSource = this.reportsPermissionsDataSource;
        this.reportsPermissionsTableView.delegate = this.reportsPermissionsDataSource;        
    }

    cellAtIndexPath(tableView, indexPath:MIOIndexPath){        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as User;

        let loginEmail = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginEmail");

        let cellIdentifier = "UserCell";
        if (item.isAdmin == true) {
            cellIdentifier = "AdminUserCell";
        }
        else if (item.businessPermission.isInvited == true) {
            cellIdentifier = "InvitedUserCell";
        }
        else if (item.email == loginEmail) {
            cellIdentifier = "CurrentUserCell";
        }

        let cell = tableView.dequeueReusableCellWithIdentifier(cellIdentifier) as ReportsAccessCell;
        cell.item = item;        

        return cell;
    }

    didSelectCellAtIndexPath(tableView,indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as User;
        this.reportsPermissionsDataSource.user = this.selectedItem;
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as User;    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeUser(item);
        }
    }


    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
        
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("User");
        fetchRequest.relationshipKeyPathsForPrefetching = ["businessPermission.worker"];
        fetchRequest.sortDescriptors = sortDescriptors;                
        if (this.searchString != null) {
            fetchRequest.predicate = MIOPredicate.predicateWithFormat("name CONTAINS '" + this.searchString + "'");
        }

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private inviteUserAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Users", "Send invitation", MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.placeholderText = "Email";
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle("OK", MUIAlertActionStyle.Default, this, function(){
            let email = avc.textFields[0].text;
            this.sendUserInvitation(email);
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private sendUserInvitation(email:string){
        if (email.length == 0) {
            AppHelper.showAlert(this, "Warning", "There's no valid email to send");
            return;
        }

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.sendManagerUserInvitationToEmail(email, this, function(code, data){            
            if (code == 200) {
                this.invalidateFetch();
            }            
            else if (code == 400) {
                AppHelper.showInfoMessage(this, "Warning", "User email not found in Dual Link DB. Please register before send the invitation.");
            }
            else {
                AppHelper.showErrorMessage(this, "Error", "The server return an unexpected error.Please try again later");
            }
        });
    }

    private removeUser(user:User){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Warning", "Are you user to remove the user?", MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle("OK", MUIAlertActionStyle.Default, this, function(){
            this.removeManagerUser(user);
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private removeManagerUser(user:User){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.removeManagerUser(user, this, function(code, data){            
            if (code == 200) {
                DBHelper.deleteObjectFromMainContext(user, true);
                this.invalidateFetch();
            }
            else {
                AppHelper.showErrorMessage(this, "Error", "The server return an unexpected error.Please try again later");
            }
        });

    }

    private showActionsAlert(){
        if (this.selectedItem == null) return;

        let avc = new MUIAlertViewController();
        avc.initWithTitle("Options", "Choose an action", MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle("Set all access", MUIAlertActionStyle.Default, this, function(){
            this.selectedItem.businessPermission.setAllPermissions();
            this.permissionsTableView.reloadData();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Remove all access", MUIAlertActionStyle.Default, this, function(){
            this.selectedItem.businessPermission.unsetAllPermissions();
            this.permissionsTableView.reloadData();
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);   
    }
}