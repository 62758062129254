

/// <reference path="LegalEntity.ts" />

// Generated class Employee_ManagedObject

class Employee_ManagedObject extends LegalEntity
{

    // Property: alias
    set alias(value:string) {
        this.setValueForKey(value, 'alias');
    }
    get alias():string {
        return this.valueForKey('alias');
    }
    set aliasPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'alias');
    }
    get aliasPrimitiveValue():string {
        return this.primitiveValueForKey('alias');
    }

    // Property: bookingPermissions
    set bookingPermissions(value:number) {
        this.setValueForKey(value, 'bookingPermissions');
    }
    get bookingPermissions():number {
        return this.valueForKey('bookingPermissions');
    }
    set bookingPermissionsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bookingPermissions');
    }
    get bookingPermissionsPrimitiveValue():number {
        return this.primitiveValueForKey('bookingPermissions');
    }

    // Property: isAvailable
    set isAvailable(value:boolean) {
        this.setValueForKey(value, 'isAvailable');
    }
    get isAvailable():boolean {
        return this.valueForKey('isAvailable');
    }
    set isAvailablePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAvailable');
    }
    get isAvailablePrimitiveValue():boolean {
        return this.primitiveValueForKey('isAvailable');
    }

    // Property: password
    set password(value:string) {
        this.setValueForKey(value, 'password');
    }
    get password():string {
        return this.valueForKey('password');
    }
    set passwordPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'password');
    }
    get passwordPrimitiveValue():string {
        return this.primitiveValueForKey('password');
    }

    // Property: permissions
    set permissions(value:number) {
        this.setValueForKey(value, 'permissions');
    }
    get permissions():number {
        return this.valueForKey('permissions');
    }
    set permissionsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'permissions');
    }
    get permissionsPrimitiveValue():number {
        return this.primitiveValueForKey('permissions');
    }

    // Property: pinCode
    set pinCode(value:string) {
        this.setValueForKey(value, 'pinCode');
    }
    get pinCode():string {
        return this.valueForKey('pinCode');
    }
    set pinCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'pinCode');
    }
    get pinCodePrimitiveValue():string {
        return this.primitiveValueForKey('pinCode');
    }
    // Relationship: department
    set department(value:Department) {
        this.setValueForKey(value, 'department');
    }
    get department():Department {
        return this.valueForKey('department') as Department;
    }
    // Relationship: role
    set role(value:WorkerRole) {
        this.setValueForKey(value, 'role');
    }
    get role():WorkerRole {
        return this.valueForKey('role') as WorkerRole;
    }
}
