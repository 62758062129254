
class ReportsPermissionCell extends MUITableViewCell
{
    private titleLabel:MUILabel = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    set title (title:string){
        this.titleLabel.text = title;
    }
}