

class FilterCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;
    private valueTextField:MUITextField = null;
    private calendarButton:MUIButton = null;
    private comboBox:MUIComboBox = null;
    private valueDropDown:MUIButton = null;

    private fromValueTextField:MUITextField = null;
    private toValueTextField:MUITextField = null;

    private fromValue:string = "";
    private toValue:string = "";

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.valueTextField = MUIOutlet(this, "value-tf", "MUITextField");
        this.setupValueTextField(this.valueTextField);
        this.calendarButton = MUIOutlet(this, "calendar-btn", "MUIButton");
        this.setupCalendarButton(this.calendarButton);
        this.comboBox = MUIOutlet(this, "combo-box", "MUIComboBox");
        this.setupComboBox(this.comboBox);
        this.valueDropDown = MUIOutlet(this, "value-dd", "MUIButton");
        this.setupValueDropdown(this.valueDropDown);
        this.fromValueTextField = MUIOutlet(this, "from-textfield", "MUITextField");        
        this.setupFromValueTextField(this.fromValueTextField);
        this.toValueTextField = MUIOutlet(this, "to-textfield", "MUITextField");
        this.setupToValueTextField(this.toValueTextField);

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private formatter:MIOFormatter = null;
    private serverFormatter:MIOFormatter = null;
    private _filter:FilterOption = null;    
    set filter (filter:FilterOption){
        this._filter = filter;
        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        switch (filter.fieldType){
            case FilterFieldType.Number:
				this.formatter = ad.numberFormatter;
				this.valueTextField.formatter = this.formatter;
                break;

            case FilterFieldType.Currency:
				this.formatter = ad.currencyFormatter;
				this.valueTextField.formatter = this.formatter;				
                break;

            case FilterFieldType.Date:
                this.formatter = ad.dateFormatter;
                this.serverFormatter = ad.serverDateFormatter;
                this.valueTextField.formatter = this.formatter;                
                break;

            case FilterFieldType.Time:
                this.formatter = ad.timeFormatter;
                this.serverFormatter = ad.serverTimeFormatter;
                this.valueTextField.formatter = this.formatter;
                break;

            case FilterFieldType.DateTime:
                this.formatter = ad.dateTimeFormatter;
                this.serverFormatter = ad.serverDateTimeFormatter;
                this.valueTextField.formatter = this.formatter;
                break;    
                
            case FilterFieldType.DateRange:
                this.formatter = ad.dateFormatter;
                this.serverFormatter = ad.serverDateFormatter;
                this.fromValueTextField.formatter = this.formatter;
                this.toValueTextField.formatter = this.formatter;
                break;

            case FilterFieldType.DateTimeRange:
                this.formatter = ad.dateTimeFormatter;
                this.serverFormatter = ad.serverDateTimeFormatter;
                this.fromValueTextField.formatter = this.formatter;
                this.toValueTextField.formatter = this.formatter;
                break;    

            case FilterFieldType.NumberRange:
                this.formatter = ad.numberFormatter;                
                this.fromValueTextField.formatter = this.formatter;
                this.toValueTextField.formatter = this.formatter;
                break;
    
        }
        
        if (this.titleLabel != null) this.titleLabel.text = filter.title;
        if (this.valueTextField != null) this.valueTextField.text = filter.valueString != null ? filter.valueString : filter.placeHolderText;
        if (this.valueDropDown != null) this.valueDropDown.title = filter.valueString != null ? filter.valueString : (filter.placeHolderText || "");

        if (this.comboBox != null) {
            let values = this._filter.enumValues;
            this.comboBox.addItem(MIOLocalizeString("NONE", "None"), -1);
            for (let index = 0; index < values.length; index++){
                let v = values[index];
                this.comboBox.addItem(MIOLocalizeString(v, v), index);
            }    
        }
    }

    private setupValueTextField(textField:MUITextField){
        if (textField == null) return;

        textField.setOnChangeText(this, function(control, value:string){
			let v = value.length > 0 ? value : null;			
            this._filter.valueString = v;
            this._filter.value = v;
            if (v == null) return;

            if (this._filter.fieldType == FilterFieldType.Date && this._filter.defaultTimeValue != null) {
                let date = this.formatter.getObjectValueForString(v);
                this._filter.value = "'" + this.serverFormatter.stringForObjectValue(date) + " " + this._filter.defaultTimeValue + "'";
			}            
			else if (this.formatter != null) this._filter.value = String(this.formatter.getObjectValueForString(v));
        });
    }

    private setupCalendarButton(button:MUIButton){
        if (button == null) return;

        button.setAction(this, function(){
            if (this._filter.fieldType == FilterFieldType.DateRange || this._filter.fieldType == FilterFieldType.DateTimeRange) {
                this.showCalendarViewController();
            }
            else {
                this.showDatePickerController();
            }
        });
    }

    private showCalendarViewController(){
        AppHelper.sharedInstance().presentDateSelectionViewController(null, this);
    }

    datesDidSelect(dateTimeFrom:Date, dateTimeTo:Date) {        
        this.fromValueTextField.text = this.formatter.stringForObjectValue(dateTimeFrom);
        this.toValueTextField.text = this.formatter.stringForObjectValue(dateTimeTo);
        this._filter.value = "'" + this.serverFormatter.stringForObjectValue(dateTimeFrom) + "," + this.serverFormatter.stringForObjectValue(dateTimeTo) + "'";
    }

    private showDatePickerController(){
        let dp = new MUIDatePickerController();
        dp.init();        
        dp.delegate = this;
        dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

        let pc = dp.popoverPresentationController;
        pc.sourceRect = this.calendarButton.frame;
        pc.sourceView = this.calendarButton;

        AppHelper.sharedInstance().presentViewController(dp, true);
    }

    didSelectDate(datePickerController, date:Date){
        let v = this.formatter.stringForObjectValue(date);
        this.valueTextField.text = v;        
        this._filter.valueString = v;       
        
        if (this._filter.fieldType == FilterFieldType.Date && this._filter.defaultTimeValue != null) {
            this._filter.value = "'" + this.serverFormatter.stringForObjectValue(date) + " " + this._filter.defaultTimeValue + "'";
        }
        else {
            this._filter.value = "'" + this.serverFormatter.stringForObjectValue(date) + "'";
        }                
    }

    private setupComboBox(comboBox:MUIComboBox){
        if (comboBox == null) return;

        comboBox.removeAllItems();
        comboBox.setOnChangeAction(this, function(control, value){                        
            this._filter.value = value != "-1" ? value : null;
        });
    }

    private setupValueDropdown(button:MUIButton){
        if (button == null) return;

        button.setAction(this, function(){

            let allowNoSelectionCell = true;
            let allowMultipleSelection = false;

            if (this._filter.allowMultipleSelection){
                allowNoSelectionCell = true;
                allowMultipleSelection = true;    
            }

			let vc = AppHelper.sharedInstance().selectViewController("Dropdown", this._filter.titleKey, allowNoSelectionCell, allowMultipleSelection, null, this) as SelectEntityViewController;
			vc.allowSearch = true;
            vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = vc.popoverPresentationController;
            pc.sourceRect = button.frame;
            pc.sourceView = button;

            AppHelper.sharedInstance().presentViewController(vc, true);
        });
    }

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "Dropdown"){       
            let sd = [MIOSortDescriptor.sortDescriptorWithKey(this._filter.titleKey, true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName(this._filter.request.entityName, sd, this._filter.predicateFormat);
            return fetchRequest;
        }

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){
        let dismiss = true;

        if (controller.identifier == "Dropdown") {
            let title = null;
            let value = null;
            
            if (item != null) {
                value = item.valueForKey(this._filter.valueKey);
                title = item.valueForKey(this._filter.titleKey);
            }
            
            this._filter.value = value;
            this._filter.valueString = title;
            this.valueDropDown.title = title;
        }

        return dismiss;
    }  

    didSelectObjectsFromSelectViewController(controller:SelectEntityViewController, items:[MIOManagedObject]){
        let dismiss = true;

        if (controller.identifier == "Dropdown") {
            
            if (items == null) {
                this.valueDropDown.title = null;
                this._filter.valueString = null;
                this._filter.value = null;                     
            }
            else if (items.length > 1) {
                let title = MIOLocalizeString("MULTIPLE VALUES SELECTED", "Multiple values selected");                
                this.valueDropDown.title = title;
                this._filter.valueString = title;

                let filterValues = [];
                for (let index = 0; index < items.length; index++) {                    
                    let value = items[index].valueForKey(this._filter.valueKey);
                    filterValues.addObject(value);
                }
                this._filter.value = filterValues.join(",");

            }
            else {
                let item = items[0];
                let value = item.valueForKey(this._filter.valueKey);
                let title = item.valueForKey(this._filter.titleKey);    
                
                this.valueDropDown.title = title;
                this._filter.valueString = title;
                this._filter.value = value;
            }                        
        }

        return dismiss;
    }  

    
    private setupFromValueTextField(textField:MUITextField){
        if (textField == null) return;

        textField.setOnChangeText(this, function(control, value:string){
            this.fromValue = value;

            if (this.formatter != null) this.fromValue = String(this.formatter.getObjectValueForString(value));
            
            this.updateRangeFilterValues();
        });
    }

    private setupToValueTextField(textField:MUITextField){
        if (textField == null) return;

        textField.setOnChangeText(this, function(control, value:string){            
            this.toValue = value;

            if (this.formatter != null) this.toValue = String(this.formatter.getObjectValueForString(value));
            
            this.updateRangeFilterValues();
        });
    }

    private updateRangeFilterValues(){
        if (this.fromValue.length == 0 && this.toValue.length == 0) {
            this._filter.value = null;
            return
        }

        this._filter.value = "";
        if (this.fromValue.length > 0) this._filter.value += this.fromValue;
        this._filter.value += ",";
        if (this.toValue.length > 0) this._filter.value += this.toValue;
    }


}