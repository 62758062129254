
class TaxCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private quantityLabel:MUILabel = null;

    private pnf = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter as MIONumberFormatter;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');        
        this.quantityLabel = MUIOutlet(this, 'value-lbl', 'MUILabel');        
    }
    
    set item(i:Tax){
        this.nameLabel.text = i.name;
        let tax = i.taxQuantity * 100;
        this.quantityLabel.text = this.pnf.stringFromNumber(tax);
    }
}
