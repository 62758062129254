//
// Generated class ArchivedTicket
//

/// <reference path="ArchivedTicket_ManagedObject.ts" />

class ArchivedTicket extends ArchivedTicket_ManagedObject
{

}
