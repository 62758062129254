/**
 * Created by miguel on 23/2/17.
 */

class OfferProductCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private formatLabel:MUILabel = null;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'product-lbl', 'MUILabel');
        this.formatLabel = MUIOutlet(this, "format-lbl", "MUILabel");

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    set item(offerProduct:OfferProduct){
        this.nameLabel.text = offerProduct.product ? offerProduct.product.name : "Product deleted";
        this.formatLabel.text = offerProduct.format != null ? offerProduct.format.name : null;
    }
}
