/**
 * Created by miguel on 7/2/16.
 */

/// <reference path="../external/MeasureUnits.ts" />

class IngredientCell extends UITableViewCell
{    
    private nameTextField:MUITextField = null;
    private nameLabel:MUILabel = null;
    private ignoreBulkCheckBox:MUISwitchButton = null;
    private lossTextField:MUITextField = null;
    private lossUnitComboBox:MUIComboBox = null;
    private consumptionTextField:MUITextField = null;
    private consumptionUnitComboBox:MUIComboBox = null;
    private totalTextField:MUITextField = null;
    private totalUnitComboBox:MUIComboBox = null;
    private costsLabel:MUILabel = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;    
    private cf = MUIWebApplication.sharedInstance().delegate.longCurrencyFormatter;    
  
    private lossQuantity = 0;
    private lossMeasure = null;
    private consumptionQuantity = 0;
    private consumptionMeasure = null;
    private totalQuantity = 0;
    private totalMeasure = null;
    private ignoreBulk = false;

    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.setupProductTextField(this.nameTextField);
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");

        this.ignoreBulkCheckBox = MUIOutlet(this, "bulk-sb", "MUISwitchButton");
        this.ignoreBulkCheckBox.setOnChangeValue(this, function (control, value) {
            this.ignoreBulkDidChange(value);            
        });

        this.consumptionTextField = MUIOutlet(this, "consumption-tf", "MUITextField");
        this.consumptionTextField.formatter = this.nf;
        this.consumptionTextField.setOnChangeText(this, function (textfield, value){
            let q = this.nf.numberFromString(value);
            this.consumptionQuantityDidChange(q);
        });
        this.consumptionTextField.setOnEnterPress(this, this.enterDidPress);

        this.consumptionUnitComboBox = MUIOutlet(this, "consumption-cb", "MUIComboBox");
        this.consumptionUnitComboBox.setOnChangeAction(this, function(combobox, value){
            this.comsuptionUnitDidChange(value);
        });

        this.lossTextField = MUIOutlet(this, "loss-tf", "MUITextField");
        this.lossTextField.formatter = this.nf;
        this.lossTextField.setOnChangeText(this, function (textfield, value){
            let q = this.nf.numberFromString(value);
            this.lossQuantityDidChange(q);
        });
        this.lossTextField.setOnEnterPress(this, this.enterDidPress);

        this.lossUnitComboBox = MUIOutlet(this, "loss-cb", "MUIComboBox");
        this.lossUnitComboBox.setOnChangeAction(this, function(combobox, value){
            this.lossUnitDidChange(value);            
        });
        
        this.totalTextField = MUIOutlet(this, "total-tf", "MUITextField");
        this.totalTextField.formatter = this.nf;
        this.totalTextField.setOnChangeText(this, function (textfield, value){
            let q = this.nf.numberFromString(value);
            this.totalQuantityDidChange(q);            
        });
        this.totalTextField.setOnEnterPress(this, this.enterDidPress);

        this.totalUnitComboBox = MUIOutlet(this, "total-cb", "MUIComboBox");
        this.totalUnitComboBox.setOnChangeAction(this, function(combobox, value){            
            this.totalUnitDidChange(value);
        });

        this.costsLabel = MUIOutlet(this, "costs-lbl", "MUILabel");

        this.reset();        

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _parentProduct:Product = null;
    set parentProduct(product:Product){
        this._parentProduct = product;
    }

    private component:Component = null;
    private product:Product = null;
    set item(item:Component){
        this.reset();
        this.component = item;     
        this.product = item.product;
        this.consumptionQuantity = item.consumptionQuantity;
        this.consumptionMeasure = item.consumptionMeasureType;
        this.lossQuantity = item.lossQuantity;
        this.lossMeasure = item.lossMeasureType;
        this.totalQuantity = item.totalQuantity;
        this.totalMeasure = item.totalMeasureType;
        
        this.nameLabel.text = item.product.name;
    
        this.ignoreBulkCheckBox.setOn(item.ignoreBulk);
        this.ignoreBulkCheckBox.enabled = true;

        this.consumptionTextField.text = this.consumptionQuantityString();
        this.consumptionTextField.enabled = true;
        this.consumptionUnitComboBox.enabled = true;
        AppHelper.fillProductUnitsComboBox(this.consumptionUnitComboBox, this.product, false, this.consumptionMeasure);

        this.lossTextField.text = this.nf.stringFromNumber(this.lossQuantity);
        this.lossTextField.enabled = true;
        this.lossUnitComboBox.enabled = true;
        AppHelper.fillProductUnitsComboBox(this.lossUnitComboBox, this.product, true, this.lossMeasure);

        this.totalTextField.text = this.totalQuantityString();
        this.totalTextField.enabled = true;
        this.totalUnitComboBox.enabled = true;
        AppHelper.fillProductUnitsComboBox(this.totalUnitComboBox, this.product, false, this.totalMeasure);
        
        let cost = DBHelper.calculateCostFromComponent(this.product, this.totalQuantity, this.totalMeasure);
        this.costsLabel.text = this.cf.stringFromNumber(cost);
    }

    private reset(){
        this.lossQuantity = 0;
        this.lossMeasure = null;
        this.consumptionQuantity = 0;
        this.consumptionMeasure = null;
        this.totalQuantity = 0;
        this.totalMeasure = null;

        if (this.nameLabel != null) this.nameLabel.text = null;
        if (this.nameTextField != null) this.nameTextField.text = null;

        this.ignoreBulkCheckBox.enabled = false;

        this.lossTextField.text = null;
        this.lossTextField.enabled = false;
        this.lossUnitComboBox.enabled = false;        

        this.consumptionTextField.text = null;
        this.consumptionTextField.enabled = false;
        this.consumptionUnitComboBox.enabled = false;

        this.totalTextField.text = null;
        this.totalTextField.enabled = false;
        this.totalUnitComboBox.enabled = false;

        this.costsLabel.text = null;
    }

    becameFirstResponder(){
        if (this.nameTextField != null) this.nameTextField.becomeFirstResponder();
    }

    private productDidSelect(controller:SelectEntityViewController, product:Product){
        if (product == this._parentProduct) {
            AppHelper.showErrorMessage(null, MIOLocalizeString("ERROR", "Error"), MIOLocalizeString("ERROR PRODUCT INGREDIENT LOOP", "It's not allow add the product like ingrediente to itself."));            
            return;            
        }

        this.product = product;
        this.consumptionMeasure = product.measureType != MeasureUnitType.Container ? product.measureType : product.containerMeasureType;
        this.lossQuantity = 0;
        this.lossMeasure = product.measureType != MeasureUnitType.Container ? product.measureType : product.containerMeasureType;        
        this.totalMeasure = product.measureType != MeasureUnitType.Container ? product.measureType : product.containerMeasureType;
        this.totalQuantity = 0;

        this.nameTextField.text = product.name;
        this.ignoreBulkCheckBox.enabled = true; 

        this.consumptionTextField.enabled = true;
        this.consumptionUnitComboBox.enabled = true;
        AppHelper.fillProductUnitsComboBox(this.consumptionUnitComboBox, this.product);

        this.lossTextField.enabled = true;        
        this.lossUnitComboBox.enabled = true;
        AppHelper.fillProductUnitsComboBox(this.lossUnitComboBox, this.product, true);
        
        this.totalTextField.enabled = true;
        this.totalUnitComboBox.enabled = true;
        AppHelper.fillProductUnitsComboBox(this.totalUnitComboBox, this.product);

        this.consumptionTextField.becomeFirstResponder();
    }

    private setupProductTextField(textField:MUITextField){
        if (textField == null) return;
        textField.setOnChangeText(this, function(control, value:string){
            //AppHelper.sharedInstance().showSelectProductViewControllerFromView(textField, value, {"SectionNameKeyPath":"productTypeString"}, this, this.productDidSelect, this, this.addProduct);
            AppHelper.sharedInstance().showSelectStockProductViewControllerFromView(textField, value, this, this.productDidSelect, this, this.addProduct);
        });                
    }


    private addProduct(value:string){
        AppHelper.sharedInstance().showAddStockProductAlertViewController(value, null);
    }

    private ignoreBulkDidChange(value:boolean) {
        this.ignoreBulk = value;
        if (this.component != null) this.component.ignoreBulk = value;
    }    

    private consumptionQuantityDidChange(quantity){
        this.consumptionQuantity = quantity;
        this.updateTotal();
    }
    
    private comsuptionUnitDidChange(value){
        let v = (typeof value === "string") ? parseInt(value) : value;
        this.consumptionMeasure = v;
        this.updateTotal();
    }

    private lossQuantityDidChange(quantity){
        this.lossQuantity = quantity;        
        this.updateTotal();
    }

    private lossUnitDidChange(value){
        let v = (typeof value === "string") ? parseInt(value) : value;
        this.lossMeasure = v;              
        this.updateTotal();
    }

    private totalQuantityDidChange(quantity){
        this.totalQuantity = quantity;
        this.updateQuantity();
    }

    private totalUnitDidChange(value){
        let v = (typeof value === "string") ? parseInt(value) : value;
        this.totalMeasure = v;
        this.updateTotal();
    }

    private enterDidPress(){
        if (this.component != null) return;
        
        // Create component
        let ad = MUIWebApplication.sharedInstance().delegate;
        let component:Component = MIOEntityDescription.insertNewObjectForEntityForName("Component", ad.managedObjectContext) as Component;
        component.parentProductID = this._parentProduct.identifier;
        component.product = this.product;                                
        component.consumptionQuantity = this.consumptionQuantity;
        component.consumptionMeasureType = this.consumptionMeasure;
        component.lossQuantity = this.lossQuantity;
        component.lossMeasureType = this.lossMeasure;        
        component.totalQuantity = this.totalQuantity;
        component.totalMeasureType = this.totalMeasure;
        component.ignoreBulk = this.ignoreBulk;

        ad.managedObjectContext.save();   
        this.reset();     
    }

    private updateTotal(){
        this.totalQuantity = DBHelper.calculateTotalConsumption(this.product, this.consumptionQuantity, this.consumptionMeasure, this.lossQuantity, this.lossMeasure, this.totalMeasure);
        this.totalTextField.text = this.totalQuantityString();        
        this.updateComponent();
    }

    private updateQuantity(){
        this.consumptionQuantity = DBHelper.calculateQuantityConsumption(this.product, this.totalQuantity, this.totalMeasure, this.lossQuantity, this.lossMeasure, this.consumptionMeasure);
        this.consumptionTextField.text = this.consumptionQuantityString();        
        this.updateComponent();
    }

    private updateComponent(){
        if (this.component == null) return;

        this.component.consumptionQuantity = this.consumptionQuantity;
        this.component.consumptionMeasureType = this.consumptionMeasure;
        this.component.lossMeasureType = this.lossMeasure;
        this.component.lossQuantity = this.lossQuantity;        
        this.component.totalMeasureType = this.totalMeasure;
        this.component.totalQuantity = this.totalQuantity;

        let cost = DBHelper.calculateCostFromComponent(this.product, this.totalQuantity, this.totalMeasure);
        this.costsLabel.text = this.cf.stringFromNumber(cost);
    }

    private consumptionQuantityString(){
        return this.nf.stringFromNumber(this.consumptionQuantity);
    }

    private totalQuantityString(){
        return this.nf.stringFromNumber(this.totalQuantity);
    }

}