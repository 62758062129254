

/// <reference path="ProductDetailGeneralViewController.ts" />
/// <reference path="../AppHelper.ts" />


class ProductSupplierViewController extends EntityListViewController
{        
    private cf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

    viewDidLoad(){
        super.viewDidLoad();        

        this.searchTextField = MUIOutlet(this, 'search-bar','MUITextField');
        this.setupSearchTextField();
        
        this.tableView = MUIOutlet(this, 'table-view', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();        
    }

    private _product:Product = null;
    set product(value){
        this._product = value;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._product == null) return;
        this.needsReloadData();
    }

    numberOfSections(tableview){
        let sections = this.fetchedResultsController.sections.length;        
        return sections + 1;
    }
    
    numberOfRowsInSection(tableview, section){
        if (section == this.fetchedResultsController.sections.length) return 1;

        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){        
        let cell:ProductSupplierCell = null;
        
        if (indexPath.section == this.fetchedResultsController.sections.length) {            
            cell = tableview.dequeueReusableCellWithIdentifier('AddCell') as ProductSupplierCell;            
            cell.product = this._product;
            return cell;
        }
        
        cell = tableview.dequeueReusableCellWithIdentifier('EditCell') as ProductSupplierCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as SupplierProduct;
    
        cell.product = this._product;
        cell.item = item;        

        return cell;
    }
        
    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {
        if (indexPath.section == this.fetchedResultsController.sections.length) {
            return MUITableViewCellEditingStyle.Insert;
        }
        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:MUITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {                
        
        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let supplierProduct = this.fetchedResultsController.objectAtIndexPath(indexPath);
            this.removeSupplierProduct(supplierProduct);
        }
    }
    
    get entityName(){
        return 'SupplierProduct';
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('supplier.name', true)];
    
        let filterString = 'product.identifier == ' + this._product.identifier; 
        if(this.searchString != null)
            filterString += " AND supplier.name CONTAINS '" + this.searchString +"'";

        let fetchRequest = DBHelper.listFetchRequestWithEntityName('SupplierProduct', sortDescriptors, filterString);
        fetchRequest.relationshipKeyPathsForPrefetching = ['supplier'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private removeSupplierProduct(supplierProduct:SupplierProduct){
        DBHelper.deleteObjectFromMainContext(supplierProduct, true);        
    }
    
}
