

// Generated class UserPermission_ManagedObject

class UserPermission_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: email
    set email(value:string) {
        this.setValueForKey(value, 'email');
    }
    get email():string {
        return this.valueForKey('email');
    }
    set emailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'email');
    }
    get emailPrimitiveValue():string {
        return this.primitiveValueForKey('email');
    }

    // Property: sections
    set sections(value:string) {
        this.setValueForKey(value, 'sections');
    }
    get sections():string {
        return this.valueForKey('sections');
    }
    set sectionsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sections');
    }
    get sectionsPrimitiveValue():string {
        return this.primitiveValueForKey('sections');
    }

    // Property: bookings
    set bookings(value:string) {
        this.setValueForKey(value, 'bookings');
    }
    get bookings():string {
        return this.valueForKey('bookings');
    }
    set bookingsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookings');
    }
    get bookingsPrimitiveValue():string {
        return this.primitiveValueForKey('bookings');
    }

    // Property: reports
    set reports(value:string) {
        this.setValueForKey(value, 'reports');
    }
    get reports():string {
        return this.valueForKey('reports');
    }
    set reportsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'reports');
    }
    get reportsPrimitiveValue():string {
        return this.primitiveValueForKey('reports');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
