
class SupportTicketListViewController extends BaseTableViewController
{
    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("SupportTicket", null, null);
            
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
}