


class ProductCategoryListViewController extends EntityListViewController
{
    parentCategoryID = null;
    parentCategoryName = null;
    category:Category = null;    
    
    private addButton:MUIButton = null;            
        
    viewDidLoad(){
        super.viewDidLoad();
        
        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function (){
            this.showAddCategory();
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"table-view","MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        

        MIONotificationCenter.defaultCenter().addObserver(this, "workspaceDidChange", function (notification) {
            this.navigationController.popToRootViewController(false);
        });                
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("ProductCategoryCell") as ProductCategoryCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Category;
        cell.item = item;            

        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;

        if(this.selectedItem != null && this.selectedItem.identifier == item.identifier) {
            cell.setSelected(true);
        }
        
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showItemDetail();
    }

    private showItemDetail(){        
        let vc = new AddNewCategoryViewController('add-new-category-view');
        vc.parentCategory = this.category;
        vc.initWithResource("layout/products/AddNewCategoryView.html")

        vc.categoryID = this.selectedItem.identifier;
    
        this.splitViewController.showDetailViewController(vc);
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Category", NoItemSelectedViewControllerType.Category);
        this.navigationController.splitViewController.showDetailViewController(vc);
    }

    get entityName(){
        return 'Category';
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sortDescriptors =  [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
    
        let predicate = [];
        let filterString = null;
        
        if (this.searchString != null) predicate.push('name CONTAINS "'+ this.searchString + '"');
        
        if(predicate.length > 0)
            filterString = predicate.join(' and ');

        let fetchRequest = DBHelper.listFetchRequestWithEntityName(this.entityName, sortDescriptors, filterString);
        fetchRequest.relationshipKeyPathsForPrefetching = ["image"];
        
        let fetchedResultsController = new MIOFetchedResultsController();        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }

    private showAddCategory(){
        let vc = new AddNewCategoryViewController('add-new-category-view');
        vc.initWithResource("layout/products/AddNewCategoryView.html")
        this.presentViewController(vc, true);     
    }
}

