/**
 * Created by miguel on 10/2/17.
 */



enum GroupType
{
    GroupAll = 1,
    DivideByProduct = 2,
    DivideAll = 3
}

class AddNewCategoryGroupViewController extends MUIViewController
{
    private tableView:MUITableView = null;
    private _category:Category = null;

    private options = [
        {type:GroupType.GroupAll, name : 'Group all'},
        {type:GroupType.DivideByProduct, name : 'Divide by product'},
        {type:GroupType.DivideAll, name : 'Divide all'},
    ];

    private selectedItem = 0;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, 'ancgv_c1_tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.reloadData();
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        if(this._category != null)
            this.tableView.reloadData();
        
    }

    public clear()
    {
        this.selectedItem = 0;
        if(this.tableView != null && this._category != null)
        {
            this.tableView.reloadData();
        }
    }

    set category(value)
    {
        this._category = value;
        this.clear();
    }

    numberOfSections(tableview)
    {
        return 1;
    }

    numberOfRowsInSection(tableview, section)
    {
        return this.options.length
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell:AddNewCategoryGroupCell = tableview.dequeueReusableCellWithIdentifier("AddNewCategoryGroupCell");

        var item = this.options[indexPath.row];

        cell.item = item;

        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;

        if(this._category.grouping == item.type )
        {
            this.selectedItem = indexPath.row; 
            cell.setAccessoryType(MUITableViewCellAccessoryType.Checkmark);    
        }
        else
            cell.setAccessoryType(MUITableViewCellAccessoryType.None);    
        
        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        var obj = this.options[indexPath.row];

        if(this.selectedItem != indexPath.row)
            this.tableView.cellAtIndexPath(MIOIndexPath.indexForRowInSection(this.selectedItem,indexPath.section)).setAccessoryType(MUITableViewCellAccessoryType.None);    
        
        this.selectedItem = indexPath.row;
        /*switch (obj.type) {
            case GroupType.GroupAll:
                this.selectedItem = GroupType.GroupAll;
               // MIONotificationCenter.defaultCenter().postNotification("AddNewCategoryGroupingDidChange", 1);
                break;
            case GroupType.DivideByProduct:
                this.selectedItem = GroupType.DivideByProduct;
              //  MIONotificationCenter.defaultCenter().postNotification("AddNewCategoryGroupingDidChange", 2);
                break;

            case GroupType.DivideAll:
                this.selectedItem = GroupType.DivideAll;
               // MIONotificationCenter.defaultCenter().postNotification("AddNewCategoryGroupingDidChange", 3);
                break;
        }*/
        
        this._category.grouping = obj.type;

        this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.Checkmark);    

     }
}