

// Generated class BookingModifierCategory_ManagedObject

class BookingModifierCategory_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: incremental
    set incremental(value:boolean) {
        this.setValueForKey(value, 'incremental');
    }
    get incremental():boolean {
        return this.valueForKey('incremental');
    }
    set incrementalPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'incremental');
    }
    get incrementalPrimitiveValue():boolean {
        return this.primitiveValueForKey('incremental');
    }

    // Property: multipleselection
    set multipleselection(value:boolean) {
        this.setValueForKey(value, 'multipleselection');
    }
    get multipleselection():boolean {
        return this.valueForKey('multipleselection');
    }
    set multipleselectionPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'multipleselection');
    }
    get multipleselectionPrimitiveValue():boolean {
        return this.primitiveValueForKey('multipleselection');
    }

    // Property: orderindex
    set orderindex(value:number) {
        this.setValueForKey(value, 'orderindex');
    }
    get orderindex():number {
        return this.valueForKey('orderindex');
    }
    set orderindexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderindex');
    }
    get orderindexPrimitiveValue():number {
        return this.primitiveValueForKey('orderindex');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }

    // Relationship: modifiers
    protected _modifiers:MIOManagedObjectSet = null;
    get modifiers():MIOManagedObjectSet {
        return this.valueForKey('modifiers');
    }
    addModifiersObject(value:BookingModifier) {
        this._addObjectForKey(value, 'modifiers');
    }
    removeModifiersObject(value:BookingModifier) {
        this._removeObjectForKey(value, 'modifiers');
    }

    // Relationship: relationCategoryZones
    protected _relationCategoryZones:MIOManagedObjectSet = null;
    get relationCategoryZones():MIOManagedObjectSet {
        return this.valueForKey('relationCategoryZones');
    }
    addRelationCategoryZonesObject(value:BookingModifierCategory_BookingZone) {
        this._addObjectForKey(value, 'relationCategoryZones');
    }
    removeRelationCategoryZonesObject(value:BookingModifierCategory_BookingZone) {
        this._removeObjectForKey(value, 'relationCategoryZones');
    }
}
