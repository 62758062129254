

// Generated class ArchivedDocument_ManagedObject

class ArchivedDocument_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: idApp
    set idApp(value:string) {
        this.setValueForKey(value, 'idApp');
    }
    get idApp():string {
        return this.valueForKey('idApp');
    }
    set idAppPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'idApp');
    }
    get idAppPrimitiveValue():string {
        return this.primitiveValueForKey('idApp');
    }

    // Property: idPlace
    set idPlace(value:string) {
        this.setValueForKey(value, 'idPlace');
    }
    get idPlace():string {
        return this.valueForKey('idPlace');
    }
    set idPlacePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'idPlace');
    }
    get idPlacePrimitiveValue():string {
        return this.primitiveValueForKey('idPlace');
    }

    // Property: type
    set type(value:string) {
        this.setValueForKey(value, 'type');
    }
    get type():string {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():string {
        return this.primitiveValueForKey('type');
    }

    // Property: documentID
    set documentID(value:string) {
        this.setValueForKey(value, 'documentID');
    }
    get documentID():string {
        return this.valueForKey('documentID');
    }
    set documentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentID');
    }
    get documentIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentID');
    }

    // Property: legalDocumentID
    set legalDocumentID(value:string) {
        this.setValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentID():string {
        return this.valueForKey('legalDocumentID');
    }
    set legalDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('legalDocumentID');
    }

    // Property: modifiedType
    set modifiedType(value:number) {
        this.setValueForKey(value, 'modifiedType');
    }
    get modifiedType():number {
        return this.valueForKey('modifiedType');
    }
    set modifiedTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'modifiedType');
    }
    get modifiedTypePrimitiveValue():number {
        return this.primitiveValueForKey('modifiedType');
    }

    // Property: sessionBeginDate
    set sessionBeginDate(value:Date) {
        this.setValueForKey(value, 'sessionBeginDate');
    }
    get sessionBeginDate():Date {
        return this.valueForKey('sessionBeginDate');
    }
    set sessionBeginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'sessionBeginDate');
    }
    get sessionBeginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('sessionBeginDate');
    }

    // Property: closed
    set closed(value:boolean) {
        this.setValueForKey(value, 'closed');
    }
    get closed():boolean {
        return this.valueForKey('closed');
    }
    set closedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'closed');
    }
    get closedPrimitiveValue():boolean {
        return this.primitiveValueForKey('closed');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: modifiedByUser
    set modifiedByUser(value:boolean) {
        this.setValueForKey(value, 'modifiedByUser');
    }
    get modifiedByUser():boolean {
        return this.valueForKey('modifiedByUser');
    }
    set modifiedByUserPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'modifiedByUser');
    }
    get modifiedByUserPrimitiveValue():boolean {
        return this.primitiveValueForKey('modifiedByUser');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: locationCategoryName
    set locationCategoryName(value:string) {
        this.setValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryName():string {
        return this.valueForKey('locationCategoryName');
    }
    set locationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryName');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }

    // Property: hotelReservationID
    set hotelReservationID(value:string) {
        this.setValueForKey(value, 'hotelReservationID');
    }
    get hotelReservationID():string {
        return this.valueForKey('hotelReservationID');
    }
    set hotelReservationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'hotelReservationID');
    }
    get hotelReservationIDPrimitiveValue():string {
        return this.primitiveValueForKey('hotelReservationID');
    }

    // Property: roomName
    set roomName(value:string) {
        this.setValueForKey(value, 'roomName');
    }
    get roomName():string {
        return this.valueForKey('roomName');
    }
    set roomNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'roomName');
    }
    get roomNamePrimitiveValue():string {
        return this.primitiveValueForKey('roomName');
    }

    // Property: numberOfPeople
    set numberOfPeople(value:number) {
        this.setValueForKey(value, 'numberOfPeople');
    }
    get numberOfPeople():number {
        return this.valueForKey('numberOfPeople');
    }
    set numberOfPeoplePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'numberOfPeople');
    }
    get numberOfPeoplePrimitiveValue():number {
        return this.primitiveValueForKey('numberOfPeople');
    }

    // Property: openDate
    set openDate(value:Date) {
        this.setValueForKey(value, 'openDate');
    }
    get openDate():Date {
        return this.valueForKey('openDate');
    }
    set openDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'openDate');
    }
    get openDatePrimitiveValue():Date {
        return this.primitiveValueForKey('openDate');
    }

    // Property: orderType
    set orderType(value:number) {
        this.setValueForKey(value, 'orderType');
    }
    get orderType():number {
        return this.valueForKey('orderType');
    }
    set orderTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderType');
    }
    get orderTypePrimitiveValue():number {
        return this.primitiveValueForKey('orderType');
    }

    // Property: orderid
    set orderid(value:string) {
        this.setValueForKey(value, 'orderid');
    }
    get orderid():string {
        return this.valueForKey('orderid');
    }
    set orderidPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderid');
    }
    get orderidPrimitiveValue():string {
        return this.primitiveValueForKey('orderid');
    }

    // Property: orderName
    set orderName(value:string) {
        this.setValueForKey(value, 'orderName');
    }
    get orderName():string {
        return this.valueForKey('orderName');
    }
    set orderNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderName');
    }
    get orderNamePrimitiveValue():string {
        return this.primitiveValueForKey('orderName');
    }

    // Property: totalPriceBase
    set totalPriceBase(value:number) {
        this.setValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBase():number {
        return this.valueForKey('totalPriceBase');
    }
    set totalPriceBasePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBasePrimitiveValue():number {
        return this.primitiveValueForKey('totalPriceBase');
    }

    // Property: totalTaxes
    set totalTaxes(value:number) {
        this.setValueForKey(value, 'totalTaxes');
    }
    get totalTaxes():number {
        return this.valueForKey('totalTaxes');
    }
    set totalTaxesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTaxes');
    }
    get totalTaxesPrimitiveValue():number {
        return this.primitiveValueForKey('totalTaxes');
    }

    // Property: totalPrice
    set totalPrice(value:number) {
        this.setValueForKey(value, 'totalPrice');
    }
    get totalPrice():number {
        return this.valueForKey('totalPrice');
    }
    set totalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPrice');
    }
    get totalPricePrimitiveValue():number {
        return this.primitiveValueForKey('totalPrice');
    }

    // Property: totalDiscount
    set totalDiscount(value:number) {
        this.setValueForKey(value, 'totalDiscount');
    }
    get totalDiscount():number {
        return this.valueForKey('totalDiscount');
    }
    set totalDiscountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDiscount');
    }
    get totalDiscountPrimitiveValue():number {
        return this.primitiveValueForKey('totalDiscount');
    }

    // Property: totalInvited
    set totalInvited(value:number) {
        this.setValueForKey(value, 'totalInvited');
    }
    get totalInvited():number {
        return this.valueForKey('totalInvited');
    }
    set totalInvitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalInvited');
    }
    get totalInvitedPrimitiveValue():number {
        return this.primitiveValueForKey('totalInvited');
    }

    // Property: totalDeleted
    set totalDeleted(value:number) {
        this.setValueForKey(value, 'totalDeleted');
    }
    get totalDeleted():number {
        return this.valueForKey('totalDeleted');
    }
    set totalDeletedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDeleted');
    }
    get totalDeletedPrimitiveValue():number {
        return this.primitiveValueForKey('totalDeleted');
    }

    // Property: bookingClientName
    set bookingClientName(value:string) {
        this.setValueForKey(value, 'bookingClientName');
    }
    get bookingClientName():string {
        return this.valueForKey('bookingClientName');
    }
    set bookingClientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingClientName');
    }
    get bookingClientNamePrimitiveValue():string {
        return this.primitiveValueForKey('bookingClientName');
    }

    // Property: bookingMinimumFee
    set bookingMinimumFee(value:number) {
        this.setValueForKey(value, 'bookingMinimumFee');
    }
    get bookingMinimumFee():number {
        return this.valueForKey('bookingMinimumFee');
    }
    set bookingMinimumFeePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bookingMinimumFee');
    }
    get bookingMinimumFeePrimitiveValue():number {
        return this.primitiveValueForKey('bookingMinimumFee');
    }

    // Property: tipModifierString
    set tipModifierString(value:string) {
        this.setValueForKey(value, 'tipModifierString');
    }
    get tipModifierString():string {
        return this.valueForKey('tipModifierString');
    }
    set tipModifierStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tipModifierString');
    }
    get tipModifierStringPrimitiveValue():string {
        return this.primitiveValueForKey('tipModifierString');
    }

    // Property: paymethodName
    set paymethodName(value:string) {
        this.setValueForKey(value, 'paymethodName');
    }
    get paymethodName():string {
        return this.valueForKey('paymethodName');
    }
    set paymethodNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'paymethodName');
    }
    get paymethodNamePrimitiveValue():string {
        return this.primitiveValueForKey('paymethodName');
    }

    // Property: expirationDate
    set expirationDate(value:Date) {
        this.setValueForKey(value, 'expirationDate');
    }
    get expirationDate():Date {
        return this.valueForKey('expirationDate');
    }
    set expirationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'expirationDate');
    }
    get expirationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('expirationDate');
    }

    // Property: isIncomingInvoice
    set isIncomingInvoice(value:boolean) {
        this.setValueForKey(value, 'isIncomingInvoice');
    }
    get isIncomingInvoice():boolean {
        return this.valueForKey('isIncomingInvoice');
    }
    set isIncomingInvoicePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isIncomingInvoice');
    }
    get isIncomingInvoicePrimitiveValue():boolean {
        return this.primitiveValueForKey('isIncomingInvoice');
    }

    // Property: salesourceId
    set salesourceId(value:string) {
        this.setValueForKey(value, 'salesourceId');
    }
    get salesourceId():string {
        return this.valueForKey('salesourceId');
    }
    set salesourceIdPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'salesourceId');
    }
    get salesourceIdPrimitiveValue():string {
        return this.primitiveValueForKey('salesourceId');
    }

    // Property: saleSourceName
    set saleSourceName(value:string) {
        this.setValueForKey(value, 'saleSourceName');
    }
    get saleSourceName():string {
        return this.valueForKey('saleSourceName');
    }
    set saleSourceNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'saleSourceName');
    }
    get saleSourceNamePrimitiveValue():string {
        return this.primitiveValueForKey('saleSourceName');
    }

    // Property: beginWorkerName
    set beginWorkerName(value:string) {
        this.setValueForKey(value, 'beginWorkerName');
    }
    get beginWorkerName():string {
        return this.valueForKey('beginWorkerName');
    }
    set beginWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'beginWorkerName');
    }
    get beginWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('beginWorkerName');
    }

    // Property: endWorkerName
    set endWorkerName(value:string) {
        this.setValueForKey(value, 'endWorkerName');
    }
    get endWorkerName():string {
        return this.valueForKey('endWorkerName');
    }
    set endWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'endWorkerName');
    }
    get endWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('endWorkerName');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: session
    set session(value:CashDeskSession) {
        this.setValueForKey(value, 'session');
    }
    get session():CashDeskSession {
        return this.valueForKey('session') as CashDeskSession;
    }
    // Relationship: locationCategory
    set locationCategory(value:LocationCategory) {
        this.setValueForKey(value, 'locationCategory');
    }
    get locationCategory():LocationCategory {
        return this.valueForKey('locationCategory') as LocationCategory;
    }
    // Relationship: location
    set location(value:Location) {
        this.setValueForKey(value, 'location');
    }
    get location():Location {
        return this.valueForKey('location') as Location;
    }
    // Relationship: bookingClient
    set bookingClient(value:Client) {
        this.setValueForKey(value, 'bookingClient');
    }
    get bookingClient():Client {
        return this.valueForKey('bookingClient') as Client;
    }
    // Relationship: booking
    set booking(value:Booking) {
        this.setValueForKey(value, 'booking');
    }
    get booking():Booking {
        return this.valueForKey('booking') as Booking;
    }
    // Relationship: tip
    set tip(value:Tip) {
        this.setValueForKey(value, 'tip');
    }
    get tip():Tip {
        return this.valueForKey('tip') as Tip;
    }
    // Relationship: ticketLegalEntity
    set ticketLegalEntity(value:Client) {
        this.setValueForKey(value, 'ticketLegalEntity');
    }
    get ticketLegalEntity():Client {
        return this.valueForKey('ticketLegalEntity') as Client;
    }

    // Property: ticketLegalEntityName
    set ticketLegalEntityName(value:string) {
        this.setValueForKey(value, 'ticketLegalEntityName');
    }
    get ticketLegalEntityName():string {
        return this.valueForKey('ticketLegalEntityName');
    }
    set ticketLegalEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketLegalEntityName');
    }
    get ticketLegalEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('ticketLegalEntityName');
    }

    // Property: ticketLegalEntityDocumentID
    set ticketLegalEntityDocumentID(value:string) {
        this.setValueForKey(value, 'ticketLegalEntityDocumentID');
    }
    get ticketLegalEntityDocumentID():string {
        return this.valueForKey('ticketLegalEntityDocumentID');
    }
    set ticketLegalEntityDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketLegalEntityDocumentID');
    }
    get ticketLegalEntityDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('ticketLegalEntityDocumentID');
    }

    // Property: ticketLegalEntityAddress
    set ticketLegalEntityAddress(value:string) {
        this.setValueForKey(value, 'ticketLegalEntityAddress');
    }
    get ticketLegalEntityAddress():string {
        return this.valueForKey('ticketLegalEntityAddress');
    }
    set ticketLegalEntityAddressPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketLegalEntityAddress');
    }
    get ticketLegalEntityAddressPrimitiveValue():string {
        return this.primitiveValueForKey('ticketLegalEntityAddress');
    }

    // Property: ticketLegalEntityAddress2
    set ticketLegalEntityAddress2(value:string) {
        this.setValueForKey(value, 'ticketLegalEntityAddress2');
    }
    get ticketLegalEntityAddress2():string {
        return this.valueForKey('ticketLegalEntityAddress2');
    }
    set ticketLegalEntityAddress2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketLegalEntityAddress2');
    }
    get ticketLegalEntityAddress2PrimitiveValue():string {
        return this.primitiveValueForKey('ticketLegalEntityAddress2');
    }
    // Relationship: invoiceLegalEntity
    set invoiceLegalEntity(value:Client) {
        this.setValueForKey(value, 'invoiceLegalEntity');
    }
    get invoiceLegalEntity():Client {
        return this.valueForKey('invoiceLegalEntity') as Client;
    }
    // Relationship: payMethod
    set payMethod(value:PayMethod) {
        this.setValueForKey(value, 'payMethod');
    }
    get payMethod():PayMethod {
        return this.valueForKey('payMethod') as PayMethod;
    }
    // Relationship: beginWorker
    set beginWorker(value:Employee) {
        this.setValueForKey(value, 'beginWorker');
    }
    get beginWorker():Employee {
        return this.valueForKey('beginWorker') as Employee;
    }
    // Relationship: endWorker
    set endWorker(value:Employee) {
        this.setValueForKey(value, 'endWorker');
    }
    get endWorker():Employee {
        return this.valueForKey('endWorker') as Employee;
    }
    // Relationship: nextDocument
    set nextDocument(value:ArchivedDocument) {
        this.setValueForKey(value, 'nextDocument');
    }
    get nextDocument():ArchivedDocument {
        return this.valueForKey('nextDocument') as ArchivedDocument;
    }

    // Relationship: linesGroup
    protected _linesGroup:MIOManagedObjectSet = null;
    get linesGroup():MIOManagedObjectSet {
        return this.valueForKey('linesGroup');
    }
    addLinesGroupObject(value:ArchivedTicketLinesGroup) {
        this._addObjectForKey(value, 'linesGroup');
    }
    removeLinesGroupObject(value:ArchivedTicketLinesGroup) {
        this._removeObjectForKey(value, 'linesGroup');
    }

    // Property: orderEntityName
    set orderEntityName(value:string) {
        this.setValueForKey(value, 'orderEntityName');
    }
    get orderEntityName():string {
        return this.valueForKey('orderEntityName');
    }
    set orderEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderEntityName');
    }
    get orderEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('orderEntityName');
    }
}
