
class BankType
{
    private static _banks = ["DLPay"];//, "BBVA"];
    static get banks(){
        return this._banks;
    }    
}

class BankListViewController extends MUIViewController
{
    private bankButton:MUIButton = null;
    private tableView:MUITableView = null;    

    viewDidLoad(){
        super.viewDidLoad();

        this.bankButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.bankButton.hidden = true;

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return 1;
    }
    
    numberOfRowsInSection(tableview, section){
        return BankType.banks.length;
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){                                
        let cell = tableview.dequeueReusableCellWithIdentifier('BankCell') as BankAccountCell;        
        
        cell.item = BankType.banks[indexPath.row];
        
        return cell;            
    }

    private accountListViewController:BankAccountListViewController = null;
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){        
        this.showAccountsFromBankType(indexPath.row);
        tableView.deselectCellAtIndexPath(indexPath);
    }

    private showAccountsFromBankType(type:BankType){
        if (this.accountListViewController == null) {
            this.accountListViewController = new BankAccountListViewController("bank-account-list-view");
            this.accountListViewController.initWithResource("layout/bankaccount/BankAccountListView.html");    
        }

        this.accountListViewController.type = type;
        this.navigationController.pushViewController(this.accountListViewController, true);
    }
}