
class BookingZoneCell extends MUITableViewCell
{
    private titleLabel:MUILabel = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel")
    }

    private _zone:BookingZone = null;
    set zone(value:BookingZone){
        this._zone = value;

        this.titleLabel.text = value.name;        
    }
}