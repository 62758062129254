/**
 * Created by miguel on 2/2/17.
 */

class ProductFormatGeneralViewController extends MUIViewController
{
    private priceTextField:MUITextField = null;
    private additionalCostPriceTextField:MUITextField = null;    
    private costPriceLabel:MUILabel = null;        
    
    private consumptionTextField:MUITextField = null;
    private consumptionUnitComboBox:MUIComboBox = null;
    private lossTextField:MUITextField = null;
    private lossUnitComboBox:MUIComboBox = null;
    private totalConsumptionTextField:MUITextField = null;
    private totalConsumptionComboBox:MUIComboBox = null;    

    private modifiersRequiredSwitchButton:MUISwitchButton = null;
    private manageModifiersButton:MUIButton = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;    

    viewDidLoad(){
        super.viewDidLoad();

        this.priceTextField = MUIOutlet(this, 'price-tf', 'MUITextField');
        this.priceTextField.formatter = this.nf;
        this.priceTextField.setOnChangeText(this, function(textfield, value){
            this._productFormat.price = this.nf.numberFromString(value);
        });

        this.costPriceLabel = MUIOutlet(this, 'cost-price-lbl', 'MUILabel');        

        this.additionalCostPriceTextField = MUIOutlet(this, 'aditional-cost-price-tf', 'MUITextField');
        this.additionalCostPriceTextField.formatter = this.nf;
        this.additionalCostPriceTextField.setOnChangeText(this, function(textfield, value){
            this._productFormat.additionalCostPrice = this.nf.numberFromString(value);
            this.updateCostPrice();
        });                

        this.consumptionTextField = MUIOutlet(this, 'consumption-tf','MUITextField');
        this.consumptionTextField.formatter = this.nf;
        this.consumptionTextField.setOnChangeText(this, function(textfield, value){ 
            let quantity = this.nf.numberFromString(value);
            this.consumptionQuantity = isNaN(quantity) ? 0 : quantity;
            this.updateTotal();
        });

        this.consumptionUnitComboBox = MUIOutlet(this, 'consumption-type-cb','MUIComboBox');
        this.consumptionUnitComboBox.setOnChangeAction(this, function(comboBox, value){
            this.consumptionMeasure = parseInt(value);
            this.updateTotal();
        });
        
        this.lossTextField = MUIOutlet(this, 'loss-tf', 'MUITextField');
        this.lossTextField.formatter = this.nf;
        this.lossTextField.setOnChangeText(this, function(textfield, value){
            let quantity = this.nf.numberFromString(value);
            this.lossQuantity = isNaN(quantity) ? 0 : quantity;
            this.updateTotal();
        });

        this.lossUnitComboBox = MUIOutlet(this, 'loss-type-cb','MUIComboBox');
        this.lossUnitComboBox.setOnChangeAction(this, function(comboBox, value){
            this.lossMeasure = parseInt(value);   
            this.updateTotal();       
        });

        this.totalConsumptionTextField = MUIOutlet(this, "total-consumption-tf", "MUITextField");
        this.totalConsumptionTextField.formatter = this.nf;
        this.totalConsumptionTextField.setOnChangeText(this, function(textfield, value){
            let quantity = this.nf.numberFromString(value);
            this.totalQuantity = isNaN(quantity) ? 0 : quantity;
            this.updateQuantity();
        });

        this.totalConsumptionComboBox = MUIOutlet(this, "total-consumption-type-cb", "MUIComboBox");     
        this.totalConsumptionComboBox.setOnChangeAction(this, function(comboBox, value){
            this.totalMeasure = parseInt(value);   
            this.updateTotal();
        });   

        this.modifiersRequiredSwitchButton = MUIOutlet(this, 'required-modifiers-sw','MUISwitchButton');
        this.modifiersRequiredSwitchButton.setOnChangeValue(this, function(switchButton, value){
            this._productFormat.modifiersRequired = value;
        });  
        
        this.manageModifiersButton = MUIOutlet(this, "manage-modifiers-btn", "MUIButton");
        this.manageModifiersButton.setAction(this, function(){
            this.showManageModifiers();
        });
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }
    
    private lossQuantity = 0;
    private lossMeasure = null;
    private consumptionQuantity = 0;
    private consumptionMeasure = null;
    private totalQuantity = 0;
    private totalMeasure = null;
    private product:Product = null;
    private _productFormat:ProductFormat = null;
    set productFormat(value:ProductFormat){
        this._productFormat = value;
        
        this.product = value.product;
        this.consumptionQuantity = value.consumptionQuantity;
        this.consumptionMeasure = value.consumptionMeasureType;
        this.lossQuantity = value.lossQuantity;
        this.lossMeasure = value.lossMeasureType;
        this.totalQuantity = value.totalQuantity || 0;
        this.totalMeasure = value.totalMeasureType ? value.totalMeasureType : this.product.measureType;

        this.updateUI();
    }

    private updateUI(){
        if(!(this.viewIsLoaded && this._productFormat != null)) return;

        this.priceTextField.text = this.cf.stringFromNumber(this._productFormat.price);        
        this.additionalCostPriceTextField.text = this.nf.stringFromNumber(this._productFormat.additionalCostPrice);        
        this.modifiersRequiredSwitchButton.setOn(this._productFormat.modifiersRequired);
        this.consumptionTextField.text = this.consumptionQuantityString();
        AppHelper.fillProductUnitsComboBox(this.consumptionUnitComboBox, this._productFormat.product, false, this._productFormat.consumptionMeasureType);
        this.lossTextField.text = this.nf.stringFromNumber(this._productFormat.lossQuantity);
        AppHelper.fillProductUnitsComboBox(this.lossUnitComboBox, this._productFormat.product, true, this._productFormat.lossMeasureType);
        this.totalConsumptionTextField.text = this.totalQuantityString();
        AppHelper.fillProductUnitsComboBox(this.totalConsumptionComboBox, this._productFormat.product, false, this._productFormat.totalMeasureType);  
        
        this.updateCostPrice();
    }

    public showManageModifiers() {
        let vc = new ProductModifiersCategoryViewController('product-modifier-category-view');
        vc.initWithResource('layout/productmodifiercategory/ProductModifierCategoryView.html')
        vc.delegate = this;
        vc.modifiers = this._productFormat.productModifierCategories;            

        this.navigationController.pushViewController(vc, true);
    }

    modifierCategoryDidSelect(modifierCategory:ProductModifierCategory){
        this._productFormat.addProductModifierCategoriesObject(modifierCategory);
    }

    modifierCategoryDidDeselect(modifierCategory:ProductModifierCategory){
        this._productFormat.removeProductModifierCategoriesObject(modifierCategory);
    }
    
    private updateTotal(){
        this.totalQuantity = DBHelper.calculateTotalConsumption(this.product, this.consumptionQuantity, this.consumptionMeasure, this.lossQuantity, this.lossMeasure, this.totalMeasure);
        this.totalConsumptionTextField.text = this.totalQuantityString();        
        this.updateComponent();
    }

    private updateQuantity(){
        this.consumptionQuantity = DBHelper.calculateQuantityConsumption(this.product, this.totalQuantity, this.totalMeasure, this.lossQuantity, this.lossMeasure, this.consumptionMeasure);
        this.consumptionTextField.text = this.consumptionQuantityString();
        this.updateComponent();
    }

    private updateComponent(){
        if (this._productFormat == null) return;

        this._productFormat.consumptionQuantity = this.consumptionQuantity;
        this._productFormat.consumptionMeasureType = this.consumptionMeasure;
        this._productFormat.lossMeasureType = this.lossMeasure;
        this._productFormat.lossQuantity = this.lossQuantity;        
        this._productFormat.totalMeasureType = this.totalMeasure;
        this._productFormat.totalQuantity = this.totalQuantity;

        this.updateCostPrice();
    }

    private consumptionQuantityString(){
        return this.nf.stringFromNumber(this.consumptionQuantity);
    }

    private totalQuantityString(){
        return this.nf.stringFromNumber(this.totalQuantity);
    }

    private updateCostPrice(){
        let total = DBHelper.calculateTotalConsumption(this.product, this.consumptionQuantity, this.consumptionMeasure, this.lossQuantity, this.lossMeasure, this.product.measureType);

        let productCostPrice = (this.product.costProductPrice || 0 ) + (this.product.additionalProductCosts || 0);
        let cost = (this._productFormat.additionalCostPrice || 0) + productCostPrice;
        cost = cost * total;
        this.costPriceLabel.text = this.cf.stringFromNumber(cost);
    }        
}