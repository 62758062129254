

class TimeRangeGroupCell extends UITableViewCell
{
    //private groupTextField:MUITextField = null;
    private nameLabel:MUILabel = null;
    private rangeLabel:MUILabel = null;
    private intervalLabel:MUILabel = null;

    private startTimeTextField:MUITextField = null;
    private endTimeTextField:MUITextField = null;

    awakeFromHTML(){
        // this.groupTextField = MUIOutlet(this, "group-tf", "MUITextField");
        // this.groupTextField.setOnChangeText(this, function(control, value){
        //     this.item.group = value;
        // });

        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        // this.nameTextField.setOnChangeText(this, function(control, value){
        //     this._item.name = value;
        // });

        this.rangeLabel = MUIOutlet(this, "range-lbl", "MUILabel");
        
        this.intervalLabel = MUIOutlet(this, "interval-lbl", "MUILabel");
           
        // this.startTimeTextField = MUIOutlet(this, "start-hour-tf", "MUITextField");
        // this.startTimeTextField.formatter = this.tf;
        // this.startTimeTextField.setOnChangeText(this, function(control, value){
        //     this._item.startTime = value;
        // });

        // this.endTimeTextField = MUIOutlet(this, "end-hour-tf", "MUITextField");
        // this.endTimeTextField.formatter = this.tf;
        // this.endTimeTextField.setOnChangeText(this, function(control, value){
        //     this._item.endTime = value;
        // });

        // this.durationTextField = MUIOutlet(this, "end-hour-tf", "MUITextField");
        // this.durationTextField.formatter = this.nf;
        // this.durationTextField.setOnChangeText(this, function(control, value){
        //     this._item.duration = value;
        // });

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:TimeRangeGroup = null;
    set item(item:TimeRangeGroup){
        this._item = item;
        this.nameLabel.text = item.name;
        this.rangeLabel.text = item.startHour + " - " + item.endHour;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.intervalLabel.text = ad.integerFormatter.stringFromNumber(item.duration) + " min";
    }

    //get item () {return this._item;}
}