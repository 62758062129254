


class CashDeskSummaryHeaderCell extends MUITableViewCell
{
    private titleLabel:MUILabel = null;
    
    private col1Label:MUILabel = null;
    private col2Label:MUILabel = null;
    private col3Label:MUILabel = null;
    private col4Label:MUILabel = null;
    private col5Label:MUILabel = null;
    private col6Label:MUILabel = null;
    private col7Label:MUILabel = null;
    private col8Label:MUILabel = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');

        this.col1Label = MUIOutlet(this, "col1-lbl", "MUILabel");
        this.col2Label = MUIOutlet(this, "col2-lbl", "MUILabel");
        this.col3Label = MUIOutlet(this, "col3-lbl", "MUILabel");
        this.col4Label = MUIOutlet(this, "col4-lbl", "MUILabel");
        this.col5Label = MUIOutlet(this, "col5-lbl", "MUILabel");
        this.col6Label = MUIOutlet(this, "col6-lbl", "MUILabel");
        this.col7Label = MUIOutlet(this, "col7-lbl", "MUILabel");
        this.col8Label = MUIOutlet(this, "col8-lbl", "MUILabel");
    }

    set item(item:CashDeskSummaryItem){
        this.titleLabel.text = MIOLocalizeString(item.title, item.title);

        if (this.col1Label != null) this.col1Label.text = MIOLocalizeString(item.col1, item.col1);
        if (this.col2Label != null) this.col2Label.text = MIOLocalizeString(item.col2, item.col2);
        if (this.col3Label != null) this.col3Label.text = MIOLocalizeString(item.col3, item.col3);        
        if (this.col4Label != null) this.col4Label.text = MIOLocalizeString(item.col4, item.col4);        
        if (this.col5Label != null) this.col5Label.text = MIOLocalizeString(item.col5, item.col5);
        if (this.col6Label != null) this.col6Label.text = MIOLocalizeString(item.col6, item.col6);
        if (this.col7Label != null) this.col7Label.text = MIOLocalizeString(item.col7, item.col7);
        if (this.col8Label != null) this.col8Label.text = MIOLocalizeString(item.col8, item.col8);
    }
}
