/**
 * Created by miguel on 01/02/16.
 */

/// <reference path="Address_ManagedObject.ts" />


class Address extends Address_ManagedObject
{
    get formatedAddress(){
        let address1 = [];
        let address2 = [];
        if(this.address1) address1.push(this.address1);
        if(this.address2) address1.push(this.address2);
        
        if(this.postalCode) address2.push('(' + this.postalCode + ')');
        if(this.city) address2.push(this.city);
        if(this.state) address2.push(this.state);
        if(this.countryISOA2) address2.push( getCountryName( this.countryISOA2 ) );
        // was: if(this.countryName) address2.push(this.countryName);
        
        return {address1 : address1.join("\n"), address2: address2.join(' ')};
    }
}