

// Generated class CashDesk_ManagedObject

class CashDesk_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: idApp
    set idApp(value:string) {
        this.setValueForKey(value, 'idApp');
    }
    get idApp():string {
        return this.valueForKey('idApp');
    }
    set idAppPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'idApp');
    }
    get idAppPrimitiveValue():string {
        return this.primitiveValueForKey('idApp');
    }

    // Property: idPlace
    set idPlace(value:string) {
        this.setValueForKey(value, 'idPlace');
    }
    get idPlace():string {
        return this.valueForKey('idPlace');
    }
    set idPlacePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'idPlace');
    }
    get idPlacePrimitiveValue():string {
        return this.primitiveValueForKey('idPlace');
    }

    // Property: invitationSerie
    set invitationSerie(value:string) {
        this.setValueForKey(value, 'invitationSerie');
    }
    get invitationSerie():string {
        return this.valueForKey('invitationSerie');
    }
    set invitationSeriePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invitationSerie');
    }
    get invitationSeriePrimitiveValue():string {
        return this.primitiveValueForKey('invitationSerie');
    }

    // Property: isOpen
    set isOpen(value:boolean) {
        this.setValueForKey(value, 'isOpen');
    }
    get isOpen():boolean {
        return this.valueForKey('isOpen');
    }
    set isOpenPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isOpen');
    }
    get isOpenPrimitiveValue():boolean {
        return this.primitiveValueForKey('isOpen');
    }

    // Property: lastOrderNumber
    set lastOrderNumber(value:number) {
        this.setValueForKey(value, 'lastOrderNumber');
    }
    get lastOrderNumber():number {
        return this.valueForKey('lastOrderNumber');
    }
    set lastOrderNumberPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lastOrderNumber');
    }
    get lastOrderNumberPrimitiveValue():number {
        return this.primitiveValueForKey('lastOrderNumber');
    }

    // Property: lastTicketNumber
    set lastTicketNumber(value:number) {
        this.setValueForKey(value, 'lastTicketNumber');
    }
    get lastTicketNumber():number {
        return this.valueForKey('lastTicketNumber');
    }
    set lastTicketNumberPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lastTicketNumber');
    }
    get lastTicketNumberPrimitiveValue():number {
        return this.primitiveValueForKey('lastTicketNumber');
    }

    // Property: lastWorkerTicketNumber
    set lastWorkerTicketNumber(value:number) {
        this.setValueForKey(value, 'lastWorkerTicketNumber');
    }
    get lastWorkerTicketNumber():number {
        return this.valueForKey('lastWorkerTicketNumber');
    }
    set lastWorkerTicketNumberPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lastWorkerTicketNumber');
    }
    get lastWorkerTicketNumberPrimitiveValue():number {
        return this.primitiveValueForKey('lastWorkerTicketNumber');
    }

    // Property: maximumCash
    set maximumCash(value:number) {
        this.setValueForKey(value, 'maximumCash');
    }
    get maximumCash():number {
        return this.valueForKey('maximumCash');
    }
    set maximumCashPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maximumCash');
    }
    get maximumCashPrimitiveValue():number {
        return this.primitiveValueForKey('maximumCash');
    }

    // Property: money
    set money(value:number) {
        this.setValueForKey(value, 'money');
    }
    get money():number {
        return this.valueForKey('money');
    }
    set moneyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'money');
    }
    get moneyPrimitiveValue():number {
        return this.primitiveValueForKey('money');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: nullifyInvitationSerie
    set nullifyInvitationSerie(value:string) {
        this.setValueForKey(value, 'nullifyInvitationSerie');
    }
    get nullifyInvitationSerie():string {
        return this.valueForKey('nullifyInvitationSerie');
    }
    set nullifyInvitationSeriePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifyInvitationSerie');
    }
    get nullifyInvitationSeriePrimitiveValue():string {
        return this.primitiveValueForKey('nullifyInvitationSerie');
    }

    // Property: nullifyInvoiceSerie
    set nullifyInvoiceSerie(value:string) {
        this.setValueForKey(value, 'nullifyInvoiceSerie');
    }
    get nullifyInvoiceSerie():string {
        return this.valueForKey('nullifyInvoiceSerie');
    }
    set nullifyInvoiceSeriePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifyInvoiceSerie');
    }
    get nullifyInvoiceSeriePrimitiveValue():string {
        return this.primitiveValueForKey('nullifyInvoiceSerie');
    }

    // Property: nullifyTicketSerie
    set nullifyTicketSerie(value:string) {
        this.setValueForKey(value, 'nullifyTicketSerie');
    }
    get nullifyTicketSerie():string {
        return this.valueForKey('nullifyTicketSerie');
    }
    set nullifyTicketSeriePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifyTicketSerie');
    }
    get nullifyTicketSeriePrimitiveValue():string {
        return this.primitiveValueForKey('nullifyTicketSerie');
    }

    // Property: prefix
    set prefix(value:string) {
        this.setValueForKey(value, 'prefix');
    }
    get prefix():string {
        return this.valueForKey('prefix');
    }
    set prefixPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'prefix');
    }
    get prefixPrimitiveValue():string {
        return this.primitiveValueForKey('prefix');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: ticketSerie
    set ticketSerie(value:string) {
        this.setValueForKey(value, 'ticketSerie');
    }
    get ticketSerie():string {
        return this.valueForKey('ticketSerie');
    }
    set ticketSeriePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketSerie');
    }
    get ticketSeriePrimitiveValue():string {
        return this.primitiveValueForKey('ticketSerie');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
