

// Generated class SubTax_ManagedObject

class SubTax_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: taxQuantity
    set taxQuantity(value:number) {
        this.setValueForKey(value, 'taxQuantity');
    }
    get taxQuantity():number {
        return this.valueForKey('taxQuantity');
    }
    set taxQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxQuantity');
    }
    get taxQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('taxQuantity');
    }

    // Property: showInTicket
    set showInTicket(value:boolean) {
        this.setValueForKey(value, 'showInTicket');
    }
    get showInTicket():boolean {
        return this.valueForKey('showInTicket');
    }
    set showInTicketPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showInTicket');
    }
    get showInTicketPrimitiveValue():boolean {
        return this.primitiveValueForKey('showInTicket');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }
}
