//
// Generated class Section
//

/// <reference path="Section_ManagedObject.ts" />

class Section extends Section_ManagedObject
{
    identifier  = null;
    
    id_section  = null;
    name        = null;
    permissions = [];

    setObject(object, moc)
    {
        let module      = object['module'];

        this.id_section = object['idSection'];
        this.identifier = module.identifier + '-' + object["idSection"];
        this.name       = object["name"];

        let instance = this;

        if(object['permissions']){
            let permissions = object['permissions'];
            permissions.forEach(function (permission, index) {
                var p = MIOEntityDescription.insertNewObjectForEntityForName("Permission", moc) as Permission;
                permission['section'] = instance;
                p.setObject(permission);
                instance.permissions.push(p);
            }, instance);

        }
    }
}
