

// Generated class IntegratorsSync_ManagedObject

class IntegratorsSync_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: enviroment
    set enviroment(value:string) {
        this.setValueForKey(value, 'enviroment');
    }
    get enviroment():string {
        return this.valueForKey('enviroment');
    }
    set enviromentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'enviroment');
    }
    get enviromentPrimitiveValue():string {
        return this.primitiveValueForKey('enviroment');
    }

    // Property: integrator
    set integrator(value:string) {
        this.setValueForKey(value, 'integrator');
    }
    get integrator():string {
        return this.valueForKey('integrator');
    }
    set integratorPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integrator');
    }
    get integratorPrimitiveValue():string {
        return this.primitiveValueForKey('integrator');
    }

    // Property: entityName
    set entityName(value:string) {
        this.setValueForKey(value, 'entityName');
    }
    get entityName():string {
        return this.valueForKey('entityName');
    }
    set entityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityName');
    }
    get entityNamePrimitiveValue():string {
        return this.primitiveValueForKey('entityName');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: referenceID
    set referenceID(value:string) {
        this.setValueForKey(value, 'referenceID');
    }
    get referenceID():string {
        return this.valueForKey('referenceID');
    }
    set referenceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'referenceID');
    }
    get referenceIDPrimitiveValue():string {
        return this.primitiveValueForKey('referenceID');
    }

    // Property: referenceConcept
    set referenceConcept(value:string) {
        this.setValueForKey(value, 'referenceConcept');
    }
    get referenceConcept():string {
        return this.valueForKey('referenceConcept');
    }
    set referenceConceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'referenceConcept');
    }
    get referenceConceptPrimitiveValue():string {
        return this.primitiveValueForKey('referenceConcept');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: error
    set error(value:string) {
        this.setValueForKey(value, 'error');
    }
    get error():string {
        return this.valueForKey('error');
    }
    set errorPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'error');
    }
    get errorPrimitiveValue():string {
        return this.primitiveValueForKey('error');
    }

    // Property: data
    set data(value:string) {
        this.setValueForKey(value, 'data');
    }
    get data():string {
        return this.valueForKey('data');
    }
    set dataPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'data');
    }
    get dataPrimitiveValue():string {
        return this.primitiveValueForKey('data');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
