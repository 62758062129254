
class GuestCell extends MUITableViewCell
{
    private nameLabel:MUILabel      = null;
    private hourLabel:MUILabel      = null;
    private paxLabel       = null;
    private paxAttendLabel = null;
    private listTypeLabel  = null; 
    private sourceLabel    = null;

    awakeFromHTML()
    {
        this.nameLabel      = MUIOutlet(this, "gv_tv_guest_list_text", 'MUILabel');
        this.hourLabel      = MUIOutlet(this, 'gv_tv_hour_lbl','MUILabel');
        this.paxLabel       = MUIOutlet(this, "gv_tv_pax_lbl", 'MUILabel');
        this.paxAttendLabel = MUIOutlet(this, "gv_tv_attend_pax_lbl", 'MUILabel');
        this.listTypeLabel  = MUIOutlet(this, "gv_tv_list_lbl", 'MUILabel');
        this.sourceLabel    = MUIOutlet(this, "gv_tv_source_lbl", 'MUILabel');
    }

    set item(i:BookingGuest)
    {
        this.nameLabel.setText(i.name);
        this.paxLabel.setText(i.pax);
        this.paxAttendLabel.text = i.paxAttend;
        this.hourLabel.text = i.hour;
        
        this.listTypeLabel.setText(i.guestList.name);
        this.sourceLabel.setText(i.source.name);

        if (i.status == 1)
        {
            // FINISHED / SHOW
            this.nameLabel.setTextRGBColor(7, 173, 30);
            this.paxLabel.setTextRGBColor(7, 173, 30);
            
            this.listTypeLabel.setTextRGBColor(7, 173, 30);
            this.sourceLabel.setTextRGBColor(7, 173, 30);
        }
        else if (i.status == 2)
        {
            // NO SHOW
            this.nameLabel.setTextRGBColor(167, 1, 203);
            this.paxLabel.setTextRGBColor(167, 1, 203);
           
            this.listTypeLabel.setTextRGBColor(167, 1, 203);
            this.sourceLabel.setTextRGBColor(167, 1, 203);
        }
        else
        {
            this.nameLabel.setTextRGBColor(0, 0, 0);
            this.paxLabel.setTextRGBColor(0, 0, 0);
           
            this.listTypeLabel.setTextRGBColor(0, 0, 0);
            this.sourceLabel.setTextRGBColor(0, 0, 0);
        }
    }
}