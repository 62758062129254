
class OutputNoteLineCell extends NoteLineCell
{
    private inputFormatDropdown:MUIButton = null;
    private inportFormatLabel:MUILabel = null;
    private quantityTextField:MUITextField = null;
    private quantityLabel:MUILabel = null;
    private productQuantityLabel:MUILabel = null;
        
    awakeFromHTML(){
        super.awakeFromHTML();
    
        this.inputFormatDropdown = MUIOutlet(this, "input-format-dd", "MUIButton");
        this.setupInputFormatDropdown(this.inputFormatDropdown);
        this.inportFormatLabel = MUIOutlet(this, "input-format-lbl", "MUILabel");
    
        this.quantityTextField = MUIOutlet(this, 'quantity-tf', 'MUITextField');   
        this.setupQuantityTextField(this.quantityTextField);        
        this.quantityLabel = MUIOutlet(this, "quantity-lbl", "MUILabel");
        
        this.productQuantityLabel = MUIOutlet(this, "product-quantity-lbl", "MUILabel");

        this.reset();

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }
    
    setLine(item:StockNoteLine){
        super.setLine(item);

        this.productLabel.text = this.productNameString();
        if (this.inputFormatDropdown != null) {
            this.inputFormatDropdown.title = this.inputFormatString();
            this.inputFormatDropdown.enabled = true;
        }

        if (this.inportFormatLabel != null) this.inportFormatLabel.text = this.inputFormatString();

        let q = this.nf.stringFromNumber(item.quantity);
        if (this.quantityLabel != null) this.quantityLabel.text =  q;
        if (this.quantityTextField != null) {
            this.quantityTextField.text = q
            this.quantityTextField.enabled = true;
        }
        
        this.productQuantityLabel.text = this.productQuantityString();
    }
    
    productDidSelect(controller:SelectEntityViewController, product:Product){
        this.setProduct(product);
        this.productTextField.text = this.productNameString();
                
        this.inputFormatDropdown.enabled = true;
        this.inputFormatDropdown.title = product.defaultInputFormatString();                

        this.quantityTextField.enabled = true;
        this.quantityTextField.becomeFirstResponder();
    }

    protected inputFormatDidSelect(controller:SelectInputFormatViewController, inputFormat:StockInputFormat, inputType:MeasureUnitType){
        super.inputFormatDidSelect(controller, inputFormat, inputType);
        this.inputFormatDropdown.title = this.inputFormatString();
        this.productQuantityLabel.text = this.productQuantityString();        
    }
    
    protected quantityDidChange(quantity){        
        super.quantityDidChange(quantity);        
        this.productQuantityLabel.text = this.productQuantityString();        
    }

    enterDidPress(){
        if (this.stockNoteLine != null) return;        
        if (this.quantity == 0 || this.quantity == null) return;
                
        let line = DBHelper.createOutputNoteLine(this.product, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.measureQuantity, this.stockNote);
        if (line != null) {
            this.lineDidInserted(line);
            this.reset();
        }
    }
    
    reset(){
        this.product = null;        
        this.inputFormat = null;
        this.quantity = 0;
        this.productQuantity = 0;
        this.measureQuantity = 0;
        
        if (this.productTextField != null) this.productTextField.text = null;

        if (this.inputFormatDropdown != null) {
            this.inputFormatDropdown.title = null;
            this.inputFormatDropdown.enabled = false;            
        }
        
        if (this.quantityTextField != null) {
            this.quantityTextField.text = null;
            this.quantityTextField.enabled = false;
        }
        this.productQuantityLabel.text = null;
    }
}