

// Generated class Format_ManagedObject

class Format_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: defaultConsumptionQuantity
    set defaultConsumptionQuantity(value:number) {
        this.setValueForKey(value, 'defaultConsumptionQuantity');
    }
    get defaultConsumptionQuantity():number {
        return this.valueForKey('defaultConsumptionQuantity');
    }
    set defaultConsumptionQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'defaultConsumptionQuantity');
    }
    get defaultConsumptionQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('defaultConsumptionQuantity');
    }

    // Property: defaultConsumptionMeasureType
    set defaultConsumptionMeasureType(value:number) {
        this.setValueForKey(value, 'defaultConsumptionMeasureType');
    }
    get defaultConsumptionMeasureType():number {
        return this.valueForKey('defaultConsumptionMeasureType');
    }
    set defaultConsumptionMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'defaultConsumptionMeasureType');
    }
    get defaultConsumptionMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('defaultConsumptionMeasureType');
    }

    // Property: defaultLossQuantity
    set defaultLossQuantity(value:number) {
        this.setValueForKey(value, 'defaultLossQuantity');
    }
    get defaultLossQuantity():number {
        return this.valueForKey('defaultLossQuantity');
    }
    set defaultLossQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'defaultLossQuantity');
    }
    get defaultLossQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('defaultLossQuantity');
    }

    // Property: defaultLossMeasureType
    set defaultLossMeasureType(value:number) {
        this.setValueForKey(value, 'defaultLossMeasureType');
    }
    get defaultLossMeasureType():number {
        return this.valueForKey('defaultLossMeasureType');
    }
    set defaultLossMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'defaultLossMeasureType');
    }
    get defaultLossMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('defaultLossMeasureType');
    }

    // Property: defaultTotalConsumptionQuantity
    set defaultTotalConsumptionQuantity(value:number) {
        this.setValueForKey(value, 'defaultTotalConsumptionQuantity');
    }
    get defaultTotalConsumptionQuantity():number {
        return this.valueForKey('defaultTotalConsumptionQuantity');
    }
    set defaultTotalConsumptionQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'defaultTotalConsumptionQuantity');
    }
    get defaultTotalConsumptionQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('defaultTotalConsumptionQuantity');
    }

    // Property: defaultTotalConsumptionMeasureType
    set defaultTotalConsumptionMeasureType(value:number) {
        this.setValueForKey(value, 'defaultTotalConsumptionMeasureType');
    }
    get defaultTotalConsumptionMeasureType():number {
        return this.valueForKey('defaultTotalConsumptionMeasureType');
    }
    set defaultTotalConsumptionMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'defaultTotalConsumptionMeasureType');
    }
    get defaultTotalConsumptionMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('defaultTotalConsumptionMeasureType');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
