

class MenuListCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private imageView:MUIImageView = null;
    private _separator = null;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");

        this.separatorStyle = UITableViewCellSeparatorStyle.None;
    }

    set item(i:any)
    {
        this.nameLabel.text = i.name;
    }

}