//
// Generated class Preset
//

/// <reference path="Preset_ManagedObject.ts" />

class Preset extends Preset_ManagedObject
{

}
