

class SelectInputFormatViewController extends MUIViewController
{    
    target = null;
    completion = null;

    private closeButton:MUIButton = null;
    private doneButton:MUIButton = null;
    private addButton:MUIButton = null;

    private searchBar:MUITextField = null;

    private tableView:UITableView = null;

    get preferredContentSize(){
        return new MIOSize(320, 500);
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setHidden(true);

        this.doneButton = MUIOutlet(this, "done-btn", "MUIButton");
        this.doneButton.setHidden(true);

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            AppHelper.sharedInstance().showAddInputFormatAlertViewController(this.product, this.product.identifier, this, this.inputFormatDidAdded);
        });        

        this.searchBar = MUIOutlet(this, "search-bar", "MUITextField");
        this.searchBar.setHidden(true);
        
        this.tableView = MUIOutlet(this, "table-view", "UITableView");        
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private units = [];
    private product:Product = null;
    setMeasureTypes(product:Product, measureType:MeasureUnitType, containerMeasureType:MeasureUnitType, containerQuantity){        
        this.product = product;

        this.units = [];
        let types = measureType == MeasureUnitType.Container ? MeasureUnits.measureUnitsFromUnitType(containerMeasureType) : MeasureUnits.measureUnitsFromUnitType(measureType);
        for (let index = 0; index < types.length; index++){
            let t = types[index];
            this.units.addObject(t);
        }

        if (measureType == MeasureUnitType.Container) this.units.addObject(MeasureUnitType.Container);

        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this.product == null) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview) {
        return 2;
    }
    
    numberOfRowsInSection(tableview, section) {
        if (section == 0) {            
            return this.units.length;
        }
        else {
            let sec = this.fetchedResultsController.sections[0];
            return sec.numberOfObjects();    
        }
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath) {        
        let cell = tableview.dequeueReusableCellWithIdentifier('SelectCell') as SelectCell;
        
        if (indexPath.section == 0) {
            let unit = this.units[indexPath.row];
            cell.title = MeasureUnits.toString(unit);
        }
        else {
            let ip = MIOIndexPath.indexForRowInSection(indexPath.row, 0);
            let item = this.fetchedResultsController.objectAtIndexPath(ip) as StockInputFormat;
            cell.title = item.name;
        }       
        
        cell.accessoryType = UITableViewCellAccessoryType.None;

        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {    

        if (this.target == null || this.completion == null) return;        

        if (indexPath.section == 0) {
            let unit = this.units[indexPath.row];
            this.completion.call(this.target, this, null, unit);
        }
        else {
            let ip = MIOIndexPath.indexForRowInSection(indexPath.row, 0);
            let item = this.fetchedResultsController.objectAtIndexPath(ip) as StockInputFormat;
            this.completion.call(this.target, this, item, null);
        }       
        
        this.dismissViewController(true);
    }
        
    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value) {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
            
    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockInputFormat", sd, "product.identifier == " + this.product.identifier);
        //fetchRequest.relationshipKeyPathsForPrefetching = ['product'];

        let frc = new MIOFetchedResultsController();
        frc.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        frc.delegate = this;            

        frc.performFetch();
                
        this._fetchedResultsController = frc;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller) {
        this.tableView.reloadData();
    }

    private inputFormatDidAdded(inputFormat:StockInputFormat){
        this.completion.call(this.target, this, inputFormat, null);
    }
   
}