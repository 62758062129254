
class WorkerManagerSectionCell extends MUITableViewCell
{
    private titleLabel:MUILabel = null; 

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }   

    setItems(section:Section, employee:Employee){
        this.titleLabel.text = section.name;
    }
}
