

// Generated class InventoryTemplateItem_ManagedObject

class InventoryTemplateItem_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: productName
    set productName(value:string) {
        this.setValueForKey(value, 'productName');
    }
    get productName():string {
        return this.valueForKey('productName');
    }
    set productNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productName');
    }
    get productNamePrimitiveValue():string {
        return this.primitiveValueForKey('productName');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: template
    set template(value:InventoryTemplate) {
        this.setValueForKey(value, 'template');
    }
    get template():InventoryTemplate {
        return this.valueForKey('template') as InventoryTemplate;
    }
}
