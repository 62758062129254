

/// <reference path="LegalEntity.ts" />

// Generated class Client_ManagedObject

class Client_ManagedObject extends LegalEntity
{

    // Property: agreeToSendAdvertising
    set agreeToSendAdvertising(value:boolean) {
        this.setValueForKey(value, 'agreeToSendAdvertising');
    }
    get agreeToSendAdvertising():boolean {
        return this.valueForKey('agreeToSendAdvertising');
    }
    set agreeToSendAdvertisingPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'agreeToSendAdvertising');
    }
    get agreeToSendAdvertisingPrimitiveValue():boolean {
        return this.primitiveValueForKey('agreeToSendAdvertising');
    }

    // Property: blacklistComment
    set blacklistComment(value:string) {
        this.setValueForKey(value, 'blacklistComment');
    }
    get blacklistComment():string {
        return this.valueForKey('blacklistComment');
    }
    set blacklistCommentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'blacklistComment');
    }
    get blacklistCommentPrimitiveValue():string {
        return this.primitiveValueForKey('blacklistComment');
    }

    // Property: clientType
    set clientType(value:number) {
        this.setValueForKey(value, 'clientType');
    }
    get clientType():number {
        return this.valueForKey('clientType');
    }
    set clientTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'clientType');
    }
    get clientTypePrimitiveValue():number {
        return this.primitiveValueForKey('clientType');
    }

    // Property: isInBlacklist
    set isInBlacklist(value:boolean) {
        this.setValueForKey(value, 'isInBlacklist');
    }
    get isInBlacklist():boolean {
        return this.valueForKey('isInBlacklist');
    }
    set isInBlacklistPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isInBlacklist');
    }
    get isInBlacklistPrimitiveValue():boolean {
        return this.primitiveValueForKey('isInBlacklist');
    }

    // Property: privacyPolicyAccepted
    set privacyPolicyAccepted(value:boolean) {
        this.setValueForKey(value, 'privacyPolicyAccepted');
    }
    get privacyPolicyAccepted():boolean {
        return this.valueForKey('privacyPolicyAccepted');
    }
    set privacyPolicyAcceptedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'privacyPolicyAccepted');
    }
    get privacyPolicyAcceptedPrimitiveValue():boolean {
        return this.primitiveValueForKey('privacyPolicyAccepted');
    }

    // Property: termsAndConditionsAccepted
    set termsAndConditionsAccepted(value:boolean) {
        this.setValueForKey(value, 'termsAndConditionsAccepted');
    }
    get termsAndConditionsAccepted():boolean {
        return this.valueForKey('termsAndConditionsAccepted');
    }
    set termsAndConditionsAcceptedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'termsAndConditionsAccepted');
    }
    get termsAndConditionsAcceptedPrimitiveValue():boolean {
        return this.primitiveValueForKey('termsAndConditionsAccepted');
    }
    // Relationship: accountBalance
    set accountBalance(value:ClientAccountBalance) {
        this.setValueForKey(value, 'accountBalance');
    }
    get accountBalance():ClientAccountBalance {
        return this.valueForKey('accountBalance') as ClientAccountBalance;
    }
    // Relationship: loyaltyBalance
    set loyaltyBalance(value:ClientLoyaltyBalance) {
        this.setValueForKey(value, 'loyaltyBalance');
    }
    get loyaltyBalance():ClientLoyaltyBalance {
        return this.valueForKey('loyaltyBalance') as ClientLoyaltyBalance;
    }
    // Relationship: debtBalance
    set debtBalance(value:ClientDebtBalance) {
        this.setValueForKey(value, 'debtBalance');
    }
    get debtBalance():ClientDebtBalance {
        return this.valueForKey('debtBalance') as ClientDebtBalance;
    }
}
