const MapItemsMock = {
    "status": "OK",
    "data": [{
        "id": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
        "hidden": false,
        "name": "TERRAZA",
        "orderindex": 7,
        "backgroundtype": 1,
        "idIn": 4,
        "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
        "consumptionprofile": "F4FEEC93-C1C0-4BBE-BD3D-7687DDCFBE38",
        "createdAt": "1516098829.835647",
        "updatedAt": "1517385799.991806",
        "locations": [{
                "id": "5CB4F8BF-FCB3-4332-8A5B-D26B2223C01C",
                "angle": 0.527834415436,
                "x": 25,
                "y": 65,
                "name": "423",
                "type": 36,
                "idIn": 132,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "F857C30C-D8BB-4F89-957F-55A85BB1485B",
                "x": 74,
                "y": 28,
                "name": "Barra H",
                "type": 2,
                "idIn": 95,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "6BE4519D-BA3A-4DFF-8970-12DEE54CA5A3",
                "angle": 0.524185955524,
                "x": 48,
                "y": 41,
                "name": "406",
                "type": 36,
                "idIn": 115,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "AE4AD6A7-D543-4C6C-8F1C-3594C917F81F",
                "x": 90,
                "y": 74,
                "name": "Luz",
                "type": 23,
                "idIn": 109,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "9DA581A7-0AB1-4512-AB01-DAC9E9509DBF",
                "x": 61,
                "y": 74,
                "name": "Luz",
                "type": 23,
                "idIn": 105,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "2314D5C1-6223-4200-8D4F-9677ACD3EAA9",
                "x": 76,
                "y": 74,
                "name": "Luz",
                "type": 23,
                "idIn": 107,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "DAC33F36-3AB1-4E36-957B-E5092CC5F471",
                "x": 83,
                "y": 74,
                "name": "Luz",
                "type": 23,
                "idIn": 108,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "1E682587-AECD-4445-8A5B-B2F96D864F8E",
                "angle": 0.532139480114,
                "x": 69,
                "y": 65,
                "name": "417",
                "type": 36,
                "idIn": 126,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "E84DDDFD-2B29-4B65-9C3C-A6DEF7114774",
                "x": 5,
                "y": 74,
                "name": "Luz",
                "type": 23,
                "idIn": 97,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "0C0D3E53-6894-4A5B-A2DB-61A0FCDEECA9",
                "angle": 0.5263864398,
                "x": 33,
                "y": 40,
                "name": "408",
                "type": 36,
                "idIn": 117,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "7DEE45AA-5AA1-4B5D-9B5B-892E26E552CA",
                "x": 12,
                "y": 74,
                "name": "Luz",
                "type": 23,
                "idIn": 98,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "3F24166C-59D1-4673-B0CD-38EB1A77A9F1",
                "x": 19,
                "y": 74,
                "name": "Luz",
                "type": 23,
                "idIn": 99,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "68C9E5CA-E2E2-41CF-8789-5595D91DA399",
                "angle": 0.520086765289,
                "x": 89,
                "y": 65,
                "name": "414",
                "type": 36,
                "idIn": 123,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "C0836219-AC39-4404-B14C-93EE77775796",
                "angle": 0.525992035866,
                "x": 18,
                "y": 65,
                "name": "424",
                "type": 36,
                "idIn": 133,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "C3D43B85-EB34-4D6D-A3D2-E6CCFCE130B5",
                "x": 40,
                "y": 74,
                "name": "Luz",
                "type": 23,
                "idIn": 102,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "78E3CF5E-6C4F-419C-986D-4AFE640EEBAE",
                "angle": 0.52470189333,
                "x": 4,
                "y": 40,
                "name": "412",
                "type": 36,
                "idIn": 121,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "58324C34-FB4A-4E4C-B122-397C0F7D016C",
                "angle": 0.527883708477,
                "x": 97,
                "y": 42,
                "name": "401",
                "type": 36,
                "idIn": 110,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "D60286BB-550C-4AE6-AF9E-586A9D1FB8D4",
                "angle": 0.522219896317,
                "x": 47,
                "y": 65,
                "name": "420",
                "type": 36,
                "idIn": 129,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "5FA08FAC-1B9C-45F7-BA3D-C49F69E4E235",
                "x": 47,
                "y": 74,
                "name": "Luz",
                "type": 23,
                "idIn": 103,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "D5511CE9-1354-4124-B88A-A4C91F75FDF2",
                "angle": 0.517141997814,
                "x": 11,
                "y": 41,
                "name": "410",
                "type": 36,
                "idIn": 119,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "7F3F48BC-A9EC-4DE9-9203-7326845778AB",
                "angle": 0.523776173592,
                "x": 73,
                "y": 41,
                "name": "404",
                "type": 36,
                "idIn": 113,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "B6E14958-925F-4122-A126-C17FEBB8ABF0",
                "angle": 0.524467766285,
                "x": 63,
                "y": 41,
                "name": "405",
                "type": 36,
                "idIn": 114,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "B5FE9794-01CD-4481-8D1C-DB0ADDCB559C",
                "angle": 0.528576672077,
                "x": 62,
                "y": 65,
                "name": "418",
                "type": 36,
                "idIn": 127,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "5490A645-253A-4520-929A-57AC6F4A09DD",
                "angle": 0.523579776287,
                "x": 18,
                "y": 41,
                "name": "411",
                "type": 36,
                "idIn": 120,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "87A39BEF-CEC3-48F4-8573-71BF7500A5E8",
                "angle": 0.513254463673,
                "x": 83,
                "y": 65,
                "name": "415",
                "type": 36,
                "idIn": 124,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "119413F1-7BEF-4C83-A20D-97AF25B8F900",
                "angle": 0.530762791634,
                "x": 96,
                "y": 65,
                "name": "413",
                "type": 36,
                "idIn": 122,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "DED7A1A0-DBAA-4146-8890-4134351957A6",
                "x": 26,
                "y": 74,
                "name": "Luz",
                "type": 23,
                "idIn": 100,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "D54DE1E1-B2C9-4A3E-9724-9E0B830E385C",
                "angle": 0.525798201561,
                "x": 54,
                "y": 65,
                "name": "419",
                "type": 36,
                "idIn": 128,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "E62BF6D8-FBA2-449D-9555-A27F3F6C14B2",
                "angle": 0.515461266041,
                "x": 25,
                "y": 40,
                "name": "409",
                "type": 36,
                "idIn": 118,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "CC1829D9-EF00-4CC2-A151-6694EC02521D",
                "x": 33,
                "y": 74,
                "name": "Luz",

                "type": 23,
                "idIn": 101,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "3340BA4A-865E-4903-8024-D7365EA8B6C3",
                "angle": 0.525073945522,
                "x": 11,
                "y": 65,
                "name": "425",
                "type": 36,
                "idIn": 134,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "07673A50-97BC-4351-BD50-84D753EF326B",
                "angle": 0.527248620987,
                "x": 40,
                "y": 66,
                "name": "421",
                "type": 36,
                "idIn": 130,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "877583E7-4E1D-4D71-8BF8-6B8C3081F1F1",
                "angle": 0.51748752594,
                "x": 89,
                "y": 42,
                "name": "402",
                "type": 36,
                "idIn": 111,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "812AB88C-0221-49A5-BA92-E55C72E4A93F",
                "x": 95,
                "y": 28,
                "name": "Barra H",
                "type": 2,
                "idIn": 96,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "143C02AF-2C83-41F5-BE7B-964B2642C223",
                "x": 54,
                "y": 74,
                "name": "Luz",
                "type": 23,
                "idIn": 104,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "3584F58B-72BF-458A-83B6-7FCA87F931CC",
                "x": 68,
                "y": 74,
                "name": "Luz",
                "type": 23,
                "idIn": 106,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "F373B985-0B1D-4DEA-8B37-CF087FFC6C79",
                "angle": 0.530041515827,
                "x": 76,
                "y": 65,
                "name": "416",
                "type": 36,
                "idIn": 125,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "6A61B9F6-B2CF-4295-ADB6-BCD83E9DFC3E",
                "x": 10,
                "y": 25,
                "name": "Barra H",
                "type": 2,
                "idIn": 93,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "D90B707D-D738-4A9A-9026-19E141A8F8A2",
                "angle": 0.528873026371,
                "x": 40,
                "y": 41,
                "name": "407",
                "type": 36,
                "idIn": 116,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "B00C217B-AD25-4A90-B299-C9384F8ECCC4",
                "angle": 0.524384617805,
                "x": 33,
                "y": 65,
                "name": "422",
                "type": 36,
                "idIn": 131,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "2DA25B42-F73F-46A3-96EE-FA4102388395",
                "angle": 0.529290139675,
                "x": 81,
                "y": 41,
                "name": "403",
                "type": 36,
                "idIn": 112,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "B82F0D13-7606-490F-AD2E-779E73912749",
                "angle": 0.526328504086,
                "x": 4,
                "y": 65,
                "name": "426",
                "type": 36,
                "idIn": 135,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            },
            {
                "id": "7648ECAC-7E8A-4954-B057-8ACF1A85BF14",
                "x": 32,
                "y": 25,
                "name": "Barra H",
                "type": 2,
                "idIn": 94,
                "idPlace": "76D0C186-958F-4547-ACAF-12219BCA5BA4",
                "category": "E79DED86-CEBB-461E-A710-4D8E0EB03A42",
                "createdAt": "1516098829.835647",
                "updatedAt": "1516098829.835647"
            }
        ]
    }],
    "lastupdate": "1517385799.991806"
};