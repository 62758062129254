class DBSyncViewController extends BaseTableViewController
{
    private entitiesComboBox:MUIComboBox = null;
    
    private segmentedControl:MUISegmentedControl = null;
    
    private calendarButton:MUIButton = null;
    private actionButton:MUIButton = null;
    
    private referenceColumnTextField:ColumnFilterTextField = null;
    private conceptColumnTextField:ColumnFilterTextField = null;
	private statusColumnTextField:ColumnFilterTextField = null;    
	private validationDateColumnTextField:ColumnFilterTextField = null;    

    private entityHeaderView:DBSynEntityHeaderView = null;

    private filterController:ColumnFilterController = null;

    private entitiesDataSource:DBSyncEntitiesDataSource = null;
    private queueDataSource:DBSyncEntitiesQueueDataSource = null;
    private transactionDataSource:DBSyncTransactionsDataSource = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.entitiesComboBox = MUIOutlet(this, "sync-entities-cb", "MUIComboBox");
        this.entitiesComboBox.setOnChangeAction(this, function(control, value){
            this.entityDidChange(parseInt(value));
        });

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, this.changeDataSourceAction);

        this.calendarButton = MUIOutlet(this, "calendar-btn", "MUIButton");
        this.calendarButton.setAction(this, function(){
            let ah:AppHelper = AppHelper.sharedInstance();
            ah.presentDateSelectionViewController(this);
        });

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(){
            this.showActions();
        });

        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);

        this.referenceColumnTextField = MUIOutlet(this, "reference-col-filter", "ColumnFilterTextField");
        this.referenceColumnTextField.filterController = this.filterController;
        this.referenceColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "referenceID", null, null);

        this.conceptColumnTextField = MUIOutlet(this, "concept-col-filter", "ColumnFilterTextField");
        this.conceptColumnTextField.filterController = this.filterController;
        this.conceptColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "concept", null, null);

        this.statusColumnTextField = MUIOutlet(this, "status-col-filter", "ColumnFilterTextField");
        this.statusColumnTextField.filterController = this.filterController;
        this.statusColumnTextField.setFilterEnumValues({"nosync":0, "synced":1});
		this.statusColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.Enum, "status", null, null);
		
		this.validationDateColumnTextField = MUIOutlet(this, "validation-date-col-filter", "ColumnFilterTextField");
        this.validationDateColumnTextField.filterController = this.filterController;
        this.validationDateColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "date", null, null);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.entityHeaderView = MUIOutlet(this, "entity-header-view", "DBSynEntityHeaderView");

        this.entitiesDataSource = new DBSyncEntitiesDataSource();
        this.entitiesDataSource.initWithTableView(this.tableView, this.entityHeaderView);

        this.queueDataSource = new DBSyncEntitiesQueueDataSource();
        this.queueDataSource.initWithTableView(this.tableView, this.entityHeaderView);        

        this.transactionDataSource = new DBSyncTransactionsDataSource();
        this.transactionDataSource.initWithTableView(this.tableView, this.entityHeaderView);

        this.tableView.dataSource = this.entitiesDataSource;
        this.tableView.delegate = this.entitiesDataSource;        
        
        this.updateEntitiesComboBox(this.configFetchedResultsController.fetchedObjects);

        // MIONotificationCenter.defaultCenter().addObserver(this, "IntegratorSyncedLine", function(note){
        //     this.invalidateData();
        // });

        MIONotificationCenter.defaultCenter().addObserver(this, "DBSyncEntityDidChange", function(note){
            this.invalidateData();
        });
    }

    protected invalidateData(){
        this.entitiesDataSource.invalidateData();
        this.transactionDataSource.invalidateData();
        this.queueDataSource.invalidateData();
        super.invalidateData();        
    }    

    /*
    viewForHeaderInSection(tableview, section){
            
        let h = new MUIView();
        h.init();
        h.layer.background = "";
        h.layer.style.position= "absolute";
        h.setHeight(23);

        let title = new MUILabel();
        title.init();
        title.layer.style.position = "absolute";
        title.setX(8);
        title.setWidth(500);
        h.addSubview(title);

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate

        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as DBSyncEntity;
        title.text = " " + ad.longDateTimeFormatter.stringFromDate(item.integratorDate) + " # " + item.integratorReferenceConcept;        

        return h;
    } */


    private changeDataSourceAction(sender){
        switch(this.segmentedControl.selectedSegmentedIndex){
            case 0:
                this.tableView.dataSource = this.entitiesDataSource;
                this.tableView.delegate = this.entitiesDataSource;        
                break;

            case 1:
                this.tableView.dataSource = this.queueDataSource;
                this.tableView.delegate = this.queueDataSource;  
                this.queueDataSource.invalidateData();      
                break;    

            case 2:
                this.tableView.dataSource = this.transactionDataSource;
                this.tableView.delegate = this.transactionDataSource;        
                this.transactionDataSource.invalidateData();
                break;                
        }

        this.tableView.reloadData();
    }

    private _configFetchedResultsController:MIOFetchedResultsController = null;
    get configFetchedResultsController(){
        if (this._configFetchedResultsController != null) return this._configFetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("title", true)];

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("IntegratorsConfig", sortDescriptors, null);
        fetchRequest.predicate = MIOPredicate.predicateWithFormat("type == 2");

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._configFetchedResultsController = fetchedResultsController;   
        return this._configFetchedResultsController;
    }
    
    controllerDidChangeContent(controller){        
        this.updateEntitiesComboBox(controller.fetchedObjects);
    }

    private updateEntitiesComboBox(objects){
        this.entitiesComboBox.removeAllItems();
        this.entitiesComboBox.addItem("None", -1);        
        
        for (let index = 0; index < objects.length; index++){
            let cfg = objects[index] as IntegratorsConfig;
            this.entitiesComboBox.addItem(cfg.title, index);
        }
    }

    private integrator:string = null;
    private entityName:string = null;
    private enviroment:string = null;
    private endpoint:string = null;
    private group = false;
        
    private entityDidChange(index){
        if (index == -1) return;        

        let ip = MIOIndexPath.indexForRowInSection(index, 0);
        let cfg = this._configFetchedResultsController.objectAtIndexPath(ip) as IntegratorsConfig;

        this.integrator = cfg.integrator;
        this.entityName = cfg.mappingEntityName == "stockmovementnoteline" ? "IntegratorSAPStockNoteLine" : cfg.mappingEntityName;
        this.enviroment = cfg.enviroment;
        this.endpoint = cfg.endpoint;
        this.group = cfg.groupByReference;

        this.entitiesDataSource.setFilterValues(this.entityName, this.integrator); this.entitiesDataSource.endpoint = this.endpoint;
        this.transactionDataSource.setFilterValues(this.entityName, this.integrator);

        //this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.invalidateData();
    }

    private dateTimeFrom:string = null;
    private dateTimeTo:string = null;
    datesDidSelect(dateTimeFrom, dateTimeTo) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.dateTimeFrom = ad.serverDateTimeFormatter.stringFromDate(dateTimeFrom);
        this.dateTimeTo = ad.serverDateTimeFormatter.stringFromDate(dateTimeTo);
        this.entitiesDataSource.dateFrom = this.dateTimeFrom;
        this.entitiesDataSource.dateTo = this.dateTimeTo;
        this.transactionDataSource.dateFrom = this.dateTimeFrom;
        this.transactionDataSource.dateTo = this.dateTimeTo;
        this.invalidateData();
    }

    private showActions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Actions", "Choose an action", MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ADD ITEMS TO TRANSACTION QUEUE", "ADD ITEMS TO TRANSACTION QUEUE"), MUIAlertActionStyle.Default, this, function(){
            //this.showSyncConfirmation();
            this.addAllEntitiesToTransaction();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Import entities for time selection", MUIAlertActionStyle.Default, this, function(){
            this.loadSyncs();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private addAllEntitiesToTransaction(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate
        let endpoint = (this.endpoint != null && this.endpoint.length > 0) ? this.endpoint : this.entityName;
        ad.webService.syncAddEntitiesToTransaction(this.entityName, this, function(code, data){
            if (code == 200) {
                AppHelper.showInfoMessage(null, "Transaction Queue", "The item was added succefully");
                MIONotificationCenter.defaultCenter().postNotification("DBSyncEntityDidChange", null);
            }
            else AppHelper.showErrorMessage(null, "Error", "Something went wrong. Try again later");
        });
    }

    private showSyncConfirmation(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Action", "Do you want to sync all visible lines?", MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle("OK", MUIAlertActionStyle.Default, this, function(){
            this.sync(this.fetchedResultsController.fetchedObjects);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private sync(objects){
/*        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate
        let endpoint = (this.endpoint != null && this.endpoint.length > 0) ? this.endpoint : this.entityName;
        ad.webService.integratorSync(this.integrator, endpoint, objects, this, function(code, data){
            if (code == 200) {
                AppHelper.showAlert(this, "Action", "The operation was successful");
                this.fetchedResultsController = null;
                this.tableView.reloadData();        
            }
            else {
                AppHelper.showErrorMessage(this, "Action", "Something went wrong.");
            }
        });    */
    }

    private loadSyncs(){
       /* if (this.dateTimeFrom == null || this.dateTimeTo == null) {
            AppHelper.showAlert(this, "Error", "You must select a date or time range to import");
            return;
        }

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate
        let endpoint = (this.endpoint != null && this.endpoint.length > 0) ? this.endpoint : this.entityName;
        ad.webService.loadSyncs(this.integrator, endpoint, this.dateTimeFrom, this.dateTimeTo, this, function(code, data){
            if (code == 200) {
                AppHelper.showAlert(this, "Action", "The operation was successful");
                this.fetchedResultsController = null;
                this.tableView.reloadData();        
            }
            else {
                AppHelper.showErrorMessage(this, "Action", "Something went wrong.");
            }
        });    
*/
    }

}