


class ClientInvoiceCell extends MUITableViewCell
{
    delegate = null;

    private dateLabel:MUILabel = null;
    private payedDateLabel:MUILabel = null;
    private pendingLabel:MUILabel = null;
    private ticketLabel:MUILabel = null;
    private clientNameLabel:MUILabel = null;
    private priceLabel:MUILabel = null;
    private printButton:MUIButton = null;            

    awakeFromHTML(){        
        this.dateLabel = MUIOutlet(this, 'cinvoicedv_date_lbl','MUILabel');
        //this.dateLabel.formatter = ad.dateFormatter;
        this.payedDateLabel = MUIOutlet(this, 'cinvoicedv_payed_date_lbl','MUILabel');
        //this.dateLabel.formatter = ad.dateFormatter;
        this.pendingLabel = MUIOutlet(this, 'cinvoicedv_pending_lbl','MUILabel');
        this.ticketLabel = MUIOutlet(this, "cinvoicedv_ticket_lbl", "MUILabel");
        this.priceLabel = MUIOutlet(this, 'cinvoicedv_value_lbl','MUILabel');
        
        this.printButton = MUIOutlet(this, 'cinvoicedv_print_btn','MUIButton');
        this.printButton.setAction(this, function(){
            AppHelper.sharedInstance().printTicket(this._item);
        });
    }

    private _item = null;
    set item(i:ArchivedDocument){
        this._item = i;
        
        let nf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
        let df = MUIWebApplication.sharedInstance().delegate.dateFormatter;

        this.ticketLabel.text = i.legalDocumentID;
        this.dateLabel.text = df.stringFromDate(i.date);
        this.priceLabel.text = nf.stringFromNumber(i.totalPrice);
    }

}