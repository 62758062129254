//
// Generated class BookingEvent
//

/// <reference path="BookingEvent_ManagedObject.ts" />

class BookingEvent extends BookingEvent_ManagedObject
{

}
