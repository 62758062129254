/**
 * Created by miguel on 22/2/17.
 */

class TicketDetailViewController extends MUIViewController
{
    showBackButton = false;
    
    private backButton:MUIButton = null;

    private actionButton:MUIButton = null;
    private exportButton:MUIButton = null;
    private printButton:MUIButton = null;

    private numLabel:MUILabel = null;
    private dateLabel:MUILabel = null;
    private clientNameLabel:MUILabel = null;    
    private paxLabel:MUILabel = null;
    private numItemsLabel:MUILabel = null;    
    private zoneLabel:MUILabel = null;
    private workerOpenLabel:MUILabel = null;
    private workerCloseLabel:MUILabel = null;
    private dateOpenLabel:MUILabel = null;
    private dateCloseLabel:MUILabel = null;

    private preTicketTitleLabel:MUILabel = null;
    private preTicketNumLabel:MUILabel = null;
    private nextDocumentLabel:MUILabel = null;    
    private nextDocumentButton:MUIButton = null;    

    private linesTableView:UITableView = null;
    private transactionsTableView:UITableView = null;    

    private totalDiscountLabel:MUILabel = null;
    private invitedLabel:MUILabel = null;
    private totalBaseLabel:MUILabel = null;
    private totalTaxLabel:MUILabel = null;
    private totalLabel:MUILabel = null;

    private transactionsDataSource:CashDeskSessionTicketTransactionsDataSource = null;

    private df = MUIWebApplication.sharedInstance().delegate.longDateTimeFormatter;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter as MIONumberFormatter;
    private ldt = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;

    private itemCount:number = 0;

    viewDidLoad(){
        super.viewDidLoad();
    
        this.backButton = MUIOutlet(this, 'tdv_nb_back_btn','MUIButton');
        this.backButton.setAction(this, function(){
            this.navigationController.popViewController(true);
        });

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(control, value){
            this.showActions();
        });

        this.exportButton = MUIOutlet(this, 'export-btn','MUIButton');
        this.exportButton.hidden = true;
        this.exportButton.setAction(this, function(){
            //AppHelper.sharedInstance().printTicket(this._ticket);
        });

        this.printButton = MUIOutlet(this, 'print-btn','MUIButton');
        this.printButton.setAction(this, function(){
            AppHelper.sharedInstance().printTicket(this._ticket);
        });

        this.numLabel = MUIOutlet(this, 'tdv_c1_ticket_num_lbl', 'MUILabel');
        
        this.dateLabel = MUIOutlet(this, 'tdv_c1_date_lbl','MUILabel');
        this.clientNameLabel = MUIOutlet(this, 'tdv_c1_client_name_lbl','MUILabel');        
        this.paxLabel = MUIOutlet(this, "pax-lbl", "MUILabel");
        this.numItemsLabel = MUIOutlet(this, 'num-items-lbl', 'MUILabel');
        this.zoneLabel = MUIOutlet(this, "zone-lbl", "MUILabel");
        this.workerOpenLabel = MUIOutlet(this, "worker-open-lbl", "MUILabel");
        this.workerCloseLabel = MUIOutlet(this, "worker-close-lbl", "MUILabel");
        this.dateOpenLabel = MUIOutlet(this, 'date-open-lbl', 'MUILabel');
        this.dateCloseLabel = MUIOutlet(this, 'date-close-lbl', 'MUILabel');

        this.preTicketTitleLabel = MUIOutlet(this, "pre-ticket-title-lbl", "MUILabel");
        this.preTicketNumLabel = MUIOutlet(this, "pre-ticket-value-lbl", "MUILabel");
        this.nextDocumentLabel = MUIOutlet(this, "next-doc-title-lbl", "MUILabel");
        this.nextDocumentButton = MUIOutlet(this, "next-doc-btn", "MUIButton");

        this.linesTableView = MUIOutlet(this, 'lines-table-view', 'UITableView');
        this.linesTableView.dataSource = this;
        this.linesTableView.delegate = this;

        // this.transactionsTableView = MUIOutlet("transactions-table-view", "UITableView");
        // this.transactionsDataSource = new CashDeskSessionTicketTransactionsDataSource();
        // this.transactionsDataSource.initWithTableView(this.transactionsTableView);
        // this.transactionsTableView.dataSource = this.transactionsDataSource;
        
        this.totalBaseLabel = MUIOutlet(this, 'tdv_rb_base_lbl','MUILabel');
        this.totalTaxLabel = MUIOutlet(this, 'tdv_rb_tax_lbl','MUILabel');
        this.totalLabel = MUIOutlet(this, 'tdv_rb_total_lbl','MUILabel');
        this.invitedLabel = MUIOutlet(this, 'tdv_rb_invited_lbl','MUILabel');
        this.totalDiscountLabel = MUIOutlet(this, 'tdv_rb_discount_lbl', 'MUILabel');
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.backButton.setHidden(!this.showBackButton);
        this.updateUI();
    }

    private _ticket:ArchivedDocument = null;
    set ticket(value:ArchivedDocument){
        this._ticket = value;
        this.updateUI();
    }

    set filterValues(values:any){
        let identifier = values["id"];
        if (identifier == null) return;
        this.reloadTicket(identifier);
    }

    private updateUI(){
        if(!this.viewIsLoaded || this._ticket == null) return;
        
        this.dateLabel.text = this.df.stringFromDate(this._ticket.date);        
        this.numLabel.text = this._ticket.legalDocumentID;
        this.clientNameLabel.text = this._ticket.ticketLegalEntityName ? this._ticket.ticketLegalEntityName : "";        
        this.paxLabel.text = this._ticket.numberOfPeople ? String(this._ticket.numberOfPeople) : null;    
        this.zoneLabel.text = this._ticket.zoneString;
        this.workerOpenLabel.text = this._ticket.beginWorkerName;
        this.workerCloseLabel.text = this._ticket.endWorkerName;
        this.dateOpenLabel.text = this.ldt.stringFromDate(this._ticket.openDate);
        this.dateCloseLabel.text = this.ldt.stringFromDate(this._ticket.date);
                
        if (this._ticket.documentID != null && this._ticket.documentID != this._ticket.legalDocumentID) {
            this.preTicketTitleLabel.setHidden(false);
            this.preTicketNumLabel.setHidden(false);
            this.preTicketNumLabel.text = this._ticket.documentID;
        }
        else {
            this.preTicketTitleLabel.setHidden(true);
            this.preTicketNumLabel.setHidden(true);
            this.preTicketNumLabel.text = null;            
        }

        if (this._ticket.nextDocument != null) {
            this.nextDocumentButton.setHidden(false);
            this.nextDocumentLabel.setHidden(false);
            this.nextDocumentButton.title = this._ticket.nextDocument.legalDocumentID;
        }
        else {
            this.nextDocumentButton.title = null;
            this.nextDocumentButton.setHidden(true);
            this.nextDocumentLabel.setHidden(true);
        }

        this.totalDiscountLabel.text = this.cf.stringFromNumber(this._ticket.totalDiscount || 0);
        this.invitedLabel.text = this.cf.stringFromNumber(this._ticket.totalInvited || 0);
        this.totalBaseLabel.text = this.cf.stringFromNumber(this._ticket.totalPriceBase || 0);
        this.totalTaxLabel.text = this.cf.stringFromNumber(this._ticket.totalTaxes || 0);
        this.totalLabel.text = this.cf.stringFromNumber(this._ticket.totalPrice || 0);

        this.fetchedResultsController = null;
        this.mapLines(this.fetchedResultsController.fetchedObjects);
        //this.numItemsLabel.text = this.countTotal(this.fetchedResultsController.fetchedObjects);
        this.numItemsLabel.text = String(this.itemCount);
        this.linesTableView.reloadData();

          //this.numItemsLabel.text = this.nf.stringFromNumber(   );
        //   let countTotal = 0;
        //   let testCount = this.fetchedResultsController.resultObjects.length;
        //   for (var index = 0; index < testCount; index++) {
        //       countTotal += this.fetchedResultsController.objectAtIndexPath(index).quantity;
        //   }
        //   this.numItemsLabel.text = this.nf.stringFromNumber(countTotal);
    }

    private reloadTicket(identifier:string){

        //this is called to update all parts of the manager related to the changes made (updates listView)
        let predicate = MIOPredicate.predicateWithFormat('identifier = ' + identifier);
        let relationships = ["nextDocument"];
        DBHelper.queryObjectsWithCompletion("ArchivedDocument", null, predicate, relationships, this, function(objects){
            if (objects.length > 0) {
                let ticket = objects[0];
                this.ticket = ticket;
            }
        });
    }

    numberOfSections(tableview){
        return 1;
        //return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();
        return this.lines.count;
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell:TicketDetailCell = tableview.dequeueReusableCellWithIdentifier('TicketDetailCell');    
        //let item:ArchivedTicketLinesGroup = this.fetchedResultsController.objectAtIndexPath(indexPath);    
        let item = this.lines[indexPath.row];
        cell.item = item;
        
        return cell;
    }
    

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
        
        if(this._ticket == null) return null;

        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('ArchivedTicketLinesGroup');    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderindex', true)];    
        fetchRequest.relationshipKeyPathsForPrefetching = ["lines"];
        
        fetchRequest.predicate = MIOPredicate.predicateWithFormat('document.identifier == ' + this._ticket.identifier);
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.mapLines(controller.fetchedObjects);
        this.linesTableView.reloadData();

        //this.numItemsLabel.text = this.countTotal(this.fetchedResultsController.fetchedObjects);
        this.numItemsLabel.text = String(this.itemCount);

    }

    private lines = [];
    private mapLines(linesGroup){

        this.itemCount = 0;

        this.lines = [];
        for (let index = 0; index < linesGroup.length; index++){
            let grp = linesGroup[index];
            if (grp.menuName == null || (grp.menuName != null && grp.menuName == grp.productName)) {
                let line = this.createLineFromGroup(grp);
                if (line != null) this.lines.addObject(line);    
            }
        }
    }

    private countTotal(linesGroup){
        let count = 0;
        for (let index = 0; index < linesGroup.length; index++){
            let grp = linesGroup[index];
            let testcount = grp.lines.count;
            count += testcount;
        }
        return count;
    }

    private createLineFromGroup(group:ArchivedTicketLinesGroup){
        let line = {};
        line["Title"] = group.productName;        
        line["UnitPrice"] = group.productPrice;        
        line["TaxName"] = group.taxName;
        
        let quantity = 0;
        let invited = 0;
        let discount = 0;
        let total = 0;
        let linesCount = 0;

        for (let index = 0; index < group.lines.count; index++){
            let l = group.lines.objectAtIndex(index) as ArchivedTicketLine;

            if (this._ticket.modifiedType == 1 && l.modifiedType == 1 && l.nullifyBy != null) continue;
            
            linesCount++;
            quantity++;
            discount += l.totalDiscount;
            invited += l.totalInvited;
            total += l.totalPrice;
        }        

        if (group.bulkQuantity != null) {
            line["BulkQuantity"] = group.bulkQuantity;
        }
        
        line["Quantity"] = quantity;        
        this.itemCount = this.itemCount + quantity;

		line["Total"] = total;
		line["TotalDiscount"] = discount;
		line["TotalInvited"] = invited;
        
        return linesCount > 0 ? line : null;
    }

    private showActions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle('Choose the action', null, MUIAlertViewStyle.Default);
        
        avc.addAction(MUIAlertAction.alertActionWithTitle('Change Client', MUIAlertActionStyle.Default, this, function(){
			this.changeClient();
        }));

        if (this._ticket.ticketLegalEntity != null) {
            avc.addAction(MUIAlertAction.alertActionWithTitle('Remove Client', MUIAlertActionStyle.Default, this, function(){
                this.unlinkClient();
            }));    
        }

        avc.addAction(MUIAlertAction.alertActionWithTitle('Change PAX', MUIAlertActionStyle.Default, this, function(){
            this.showChangePAXAlert();
        }));

        if (this._ticket.nextDocument == null) {
            avc.addAction(MUIAlertAction.alertActionWithTitle('Create Invoice from ticket', MUIAlertActionStyle.Default, this, function(){
                this.createInvoice();
            }));
        }

        avc.addAction(MUIAlertAction.alertActionWithTitle('Sync', MUIAlertActionStyle.Default, this, function(){
            this.getMappings();
        }));

        //modifiedType 1 for a ticket that has already been nullified, 0 if not
        if(this._ticket.modifiedType == 0 && this._ticket.nextDocument == null)
        avc.addAction(MUIAlertAction.alertActionWithTitle("Nullify", MUIAlertActionStyle.Destructive, this, function(){
            this.nullifyTicket();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);
    }

    //add action to nullify a receipt (ticket)
    private nullifyTicket(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.nullifyReceipt(this._ticket.identifier, this, function(code, status){
            if (code == 200){
                AppHelper.showAlert(this, "Information", "Nullify receipt was create");
                this.reloadTicket(this._ticket.identifier);
            }
            else {
                AppHelper.showAlert(this, "Error", "Unable to create the nullify receipt");
            }
        });
    }

    private changeClient(){
        let vc = AppHelper.sharedInstance().selectViewController("AddClient", "name", false, false, null, this, null) as SelectEntityViewController;
        vc.allowSearch = true;
        this.presentViewController(vc, true);
    }

    private unlinkClient(){
        this._ticket.ticketLegalEntity = null;
        this._ticket.ticketLegalEntityAddress = null;
        this._ticket.ticketLegalEntityAddress2 = null;
        this._ticket.ticketLegalEntityDocumentID = null;
        this._ticket.ticketLegalEntityName = null;
        
        DBHelper.saveMainContext();
        this.updateUI();
    }

    private showChangePAXAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle('Change the number of people', null, MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            textField.formatter = ad.numberFormatter;
            textField.placeholderText = this._ticket.numberOfPeople;
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, this, function(){
            let tf = avc.textFields[0];
            if (tf.text == null) return;
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let pax = ad.numberFormatter.numberFromString(tf.text);
            this.changePax(pax);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private changePax(pax:number){
        this._ticket.numberOfPeople = pax;
        DBHelper.saveMainContext();
        this.updateUI();
    }

    private createInvoice(){
        let vc = AppHelper.sharedInstance().selectViewController("CreateInovice", "name", false, false, null, this, null) as SelectEntityViewController;
        vc.allowSearch = true;
        this.presentViewController(vc, true);
    }

    //
    // SelectEntityViewControllerDelegate
    //

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "AddClient"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Client", sd, null);
            fetchRequest.fetchLimit = 50;
            fetchRequest.relationshipKeyPathsForPrefetching = ['address', 'mobilePhone', 'phone'];

            return fetchRequest;
        }
        else if (controller.identifier == "CreateInovice"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Client", sd, null);
            fetchRequest.fetchLimit = 50;
            fetchRequest.relationshipKeyPathsForPrefetching = ['address', 'mobilePhone', 'phone'];

            return fetchRequest;
        }

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){
        
        let dismiss = true;

        if (controller.identifier == "AddClient"){

            let client = item as Client;
            this._ticket.ticketLegalEntity = client;
            this._ticket.ticketLegalEntityName = client.name;
            this._ticket.ticketLegalEntityDocumentID = client.document;
            this._ticket.ticketLegalEntityAddress = client.address != null ? client.address.address1 : null;
            this._ticket.ticketLegalEntityAddress2 = client.address != null ? client.address.address2 : null;

			DBHelper.saveMainContext();
			this.updateUI();

        }
        else if (controller.identifier == "CreateInovice"){
            let client = item as Client;
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            ad.webService.createInvoiceFromTicket(this._ticket.identifier, client, this, function(code, data){
                if (code == 200) {
                    DBHelper.queryObjectsWithCompletion("ArchivedDocument", null, MIOPredicate.predicateWithFormat("identifier == " + this._ticket.identifier), ['nextDocument'], this, function(objects){
                        this.updateUI();
                        MIONotificationCenter.defaultCenter().postNotification("UpdateTicketList", null);                        
                    });
                }
            });
        }

        return dismiss;
    }

    private getMappings(){
        if (this._ticket.booking == null) return;
        
        let predicate = MIOPredicate.predicateWithFormat("ownID == " + this._ticket.booking.identifier + " AND integrator == 'SERVME'");
        DBHelper.queryObjectsWithCompletion("IntegratorsMappings", null, predicate, [], this, function(objects){
            if (objects.length > 0) this.syncServme(objects[0])
        });
    }

    private syncServme(mappingEntity:IntegratorsMappings){

        //
        // Login First
        // "app_key":"alphanumeric",
        // "app_secret":"alphanumeric"

        // https://pos.servmeco.com/api/v1/outlet/:outlet_id/orders/:order_id
        // outlet_id = 63 dev
        // order_id = our booking id

        let json = {};
        json["id"] = null; // again our booking id 
        json["status"] = "closed";
        json["start_date"] = null; // open date
        json["updated_date"] = null; // close date
        json["waiter_id"] = this._ticket.endWorker.identifier;
        json["table"] = this._ticket.locationCategoryName + "#" + this._ticket.locationName;
        json["reservation_ref"] = mappingEntity.foreignID;
        json["guest_count"] = this._ticket.numberOfPeople;
        
        let items = [];
        for (let index = 0; index < this.lines.length; index){
            let l = this.lines[index];
            let i = {};
            i["id"] = null; // id fo the product
            i["name"] = l["Title"];
            i["categories"] = [];  
            i["price"] = l["Price"];
            i["quantity"] = l["Quantity"];
            i["total_price"] = l["Total"];
            //i["voided"] = 0;
        }

        json["items"] = items;
        json["invoice"] = {};
        json["invoice"]["subtotal"] = this._ticket.totalPriceBase;
        json["invoice"]["discount"] = this._ticket.totalDiscount;
        json["invoice"]["tax"] = this._ticket.totalTaxes;
        json["invoice"]["total"] = this._ticket.totalPrice;  
        
        let payments = [];
        
        json["invoice"]["payments"] = payments;        
    }

    /*
{
    "id": "alphanumeric",
    "status": "open|closed|voided",
    "start_date": "yyyy-MM-dd hh:mm:ss",
    "updated_date": "yyyy-MM-dd hh:mm:ss",
    "waiter_id": "string",
    "table": "string",
    "reservation_ref": "string",
    "guest_count": "numeric",
    "items": [{
        "id": "alphanumeric",
        "name": "string",
        "categories": [{
            "id": "alphanumeric",
            "name": "string"
        }],
        "price": "double",
        "quantity": "double",
        "total_price": "double",
        "voided": "0|1"
    }],
    "invoice": {
        "subtotal": "double",
        "discount": "double",
        "tax": "double",
        "total": "double",
        "payments": [{
            "method": "alphanumeric",
            "amount": "double"
        }]
    }
}
    */
}