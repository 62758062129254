/**
 * Created by godshadow on 11/07/16.
 */

class NoShowCell extends MUITableViewCell
{
    private paxLabel:MUILabel = null;
    private nameLabel:MUILabel = null;    
    private sourceLabel:MUILabel = null;
    private penalizedLabel:MUILabel = null;
    private amountTextField:MUITextField = null;
    private payButton:MUIButton = null;
    private closeButton:MUIButton = null;
    
    awakeFromHTML(){     

        this.paxLabel = MUIOutlet(this, "pax-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, "name-lbl", 'MUILabel');
        this.sourceLabel = MUIOutlet(this, "source-lbl", "MUILabel");
        this.penalizedLabel = MUIOutlet(this, "penalized-lbl", 'MUILabel');
        this.amountTextField = MUIOutlet(this, "amount-tf", 'MUITextField');
        this.payButton = MUIOutlet(this, "pay-btn", 'MUIButton');
        this.payButton.setAction(this, function(){
            this.chargeAmount();
        });

        this.closeButton = MUIOutlet(this, "close-btn", 'MUIButton');
        this.closeButton.setAction(this, function(){
            this._booking.isPayed = true;
            DBHelper.saveMainContext();
        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private _booking:Booking = null;
    set booking(item:Booking){
        this._booking = item;

        this.nameLabel.text = item.clientName;
        this.paxLabel.text= item.pax;
        this.sourceLabel.text = null;
        this.penalizedLabel.text =  item.penalizedImport;
        
        if (item.hasPaymentToken == false){
            this.amountTextField.setAlpha(0);
            this.payButton.setEnabled(false);
        }
        else{
            this.amountTextField.setAlpha(1);
            this.payButton.setEnabled(true);
        }

        if (item.status == 8){
            // NO SHOW
            this.nameLabel.setTextRGBColor(167, 1, 203);
            this.paxLabel.setTextRGBColor(167, 1, 203);
            //cell.paxAssitedLabel.setTextRGBColor(167, 1, 203);
            //cell.listTypeLabel.setTextRGBColor(167, 1, 203);
            //cell.sourceLabel.setTextRGBColor(167, 1, 203);
        }
        else if (item.status == 6){
            this.nameLabel.setTextRGBColor(200, 120, 120);
            this.paxLabel.setTextRGBColor(200, 120, 120);
        }
        else
            {
            this.nameLabel.setTextRGBColor(0, 0, 0);
            this.paxLabel.setTextRGBColor(0, 0, 0);
            //cell.paxAssitedLabel.setTextRGBColor(0, 0, 0);
            //cell.listTypeLabel.setTextRGBColor(0, 0, 0);
            //cell.sourceLabel.setTextRGBColor(0, 0, 0);
        }
    }

    private chargeAmount(){
        if (this.amountTextField.text.length == 0) {
            this.showAlert("Payment error", "Invalid ammount to make the charge.");
            return;
        }

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let cf = ad.currencyFormatter;
    
        let value = cf.numberFromString(this.amountTextField.text);
        if (value == null || value == 0){
            this.showAlert("Payment error", "Invalid ammount to make the charge.");
            return;
        }
                
        let concept = this._booking.day + " " + this._booking.clientName + " CANCELLATION / NO SHOW";
        ad.webService.chargeAmountToBooking(this._booking.identifier, this.amountTextField.text, concept, this, function(code, data){
            if (code == 200 && data["status"] == "OK") {
                this.showAlert("Payment message", "The transacion was successfull.");
            }
            else {
                this.showAlert("Payment error", "The transacion couldn't complete.");
            }
        });
    }

    private showAlert(title:string, message:string){
        AppHelper.showAlert(null, title, message);
    }
    
}
