


class CashDeskSummaryChartView extends MUIView
{
    private totalLabel = null;
    private typeCombobox = null;
    private loadingView = null;
    
    private comboBox:MUIComboBox = null;
    private chartView:MUIChartView = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private chartType = MUIChartViewType.Bar;
    
    private type = 0;
    private item = null;
    private dateFrom = null;
    private dateTo = null;
    private mode = null;

    awakeFromHTML() {
    
        this.loadingView = MUIOutlet(this, 'activity-indicator', "MUIView");

        this.totalLabel = MUIOutlet(this, 'cdsv_total_graphic_lbl','MUILabel');

        // this.comboBox = MUIOutlet(this, 'cdsv_graphic_cb', 'MUIComboBox');
        // this.comboBox.addItem(MIOLocalizeString("SALES COMPARISON","Sales comparison"), 0);
        // this.comboBox.addItem(MIOLocalizeString("SALES BY HOUR","Sales by hour"), 1);
        // this.comboBox.setOnChangeAction(this, function(control, value) {

        //     this.type = value;
        //     this.render();
        // });

        this.chartView = MUIOutlet(this, 'chart-view', 'MUIChartView');         
    }
    
    setDataSet(item, dateFrom, dateTo, mode = CalendarMode.Day){
        this.item = item;
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.mode = mode;
        this.totalLabel.text = this.cf.stringFromNumber(item.total);
        this.render();
    }

    render(){
        this.downloadSalesComparationDayChart();
    }

    private downloadSalesComparationDayChart(){
        
        this.loadingView.setHidden(false);

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;
        let identifier = this.item.identifier;
        let identifierType = this.typeIdentifierString(this.item.type);        

        ws.downloadSalesComparationDaysChart(identifier, identifierType ,this.dateFrom, this.dateTo, this.mode, this, function(error, labels, values){

            this.loadingView.setHidden(true);

            if (error == false) {
                this.chartView.title = this.title;
                this.chartView.labels = labels;
                this.chartView.values = values;
                this.chartView.renderWithType(MUIChartViewType.Bar);
            }
        });
    }

    typeIdentifierString(type){

        switch (type) {
            case 0:
                return "company";
            case 1:
                return "place";
            case 2:
                return "device";
            case 3:
                return "place"; // Manager cashdesk
        }

        return null;
    }
        
}
