
class SelectedTicketDataSource extends MIOObject
{
    delegate = null;
    tickets = null;

    numberOfSections(tableview){
        return 1;
    }
    
    numberOfRowsInSection(tableview, section){
        return this.tickets.length || 0;
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let ticket = this.tickets[indexPath.row] as ArchivedDocument;

        let cell = tableview.dequeueReusableCellWithIdentifier('TicketCell') as SelectTicketCell;            
        cell.item = ticket;           
            
        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        //let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);
        //this.zone = obj;
    }
    
    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:MUITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let ticket = this.tickets[indexPath.row] as ArchivedDocument;

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            if (this.delegate != null) this.delegate.removeSelectedTicket(ticket);
        }
    }

}