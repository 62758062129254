class ProductFormatDataSource extends MIOObject
{
    private delegate = null;
    private tableView:MUITableView = null;
    initWithTableView(tableview:MUITableView, delegate){
        this.delegate = delegate;
        this.tableView = tableview;
    }

    private _product:Product = null;
    set product(product:Product){
        this._product = product;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview:MUITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview:MUITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){        
        let cell = tableview.dequeueReusableCellWithIdentifier("ProductFormatCell") as ProductFormatSaleCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        cell.item = item;            
        
        return cell;
    }

    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let pf = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductFormat;
        if (this.delegate != null) {
            this.delegate.productFormatDidSelect(pf);        
        }
    }


    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let productFormat = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductFormat;

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            DBHelper.removeProductFormat(productFormat);
        }
    }


    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let predicateFormat = "product.identifier = " + this._product.identifier;
        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductFormat", sds, predicateFormat);        
        fetchRequest.relationshipKeyPathsForPrefetching = ['format', 'productFormatRates'];        
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;            
        
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

}