/**
 * Created by godshadow on 13/08/16.
 */


    /// <reference path="PaymentViewController.ts" />

class PaymentPenaltyViewController extends MUIViewController
{
    cancelButton = null;
    doneButton = null;

    amount = 0;
    bookingID = 0;
    paymentToken = 0;

    get preferredContentSize()
        {
            return new MIOSize(800, 700);
        }

    viewDidLoad()
    {
        super.viewDidLoad();


        this.cancelButton = MUIOutlet(this, "ppv_cancel", "MUIButton");
        this.cancelButton.setAction(this, function () {

        //    MUIWebApplication.sharedInstance().endSheetViewController();
        });

        this.doneButton = MUIOutlet(this, "ppv_done", "MUIButton");
        this.doneButton.setAction(this, function () {

            var vc = new PaymentViewController("payment-view");
            vc.initWithResource("layout/PaymentView.html");

            vc.amount = this.amount;
            vc.bookingID = this.bookingID;
            vc.paymentToken = this.paymentToken;

            this.navigationController.pushViewController(vc, true);
        });
    }
}
