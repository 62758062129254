


class PlaceSettingsViewController extends MUIViewController
{        
    private saveButton:MUIButton = null;
    private tableView:MUITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    numberOfSections(tableView:MUITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:MUITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    titleForHeaderInSection(tableView:MUITableView, section:number) {        
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as Configuration;

        return item.section;
    }
    
    cellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Configuration;

        let cell = null;
        switch (item.type){
            case ConfigurationValueType.Boolean:
            cell = tableView.dequeueReusableCellWithIdentifier("SwitchSettingCell") as PlaceSettingCell;
            break;

            case ConfigurationValueType.Entity:
            cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as PlaceSettingCell;
            break;
                
            default:
            cell = tableView.dequeueReusableCellWithIdentifier("TextFieldSettingCell") as PlaceSettingCell;
            break;
        }
                                        
        cell.item = item;
    
        return cell;
    }

    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){        
    }
     
    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('section', true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Configuration", sortDescriptors, null);        
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "section");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }
}