//
// Generated class IssuedInvoiceGroupLine
//

/// <reference path="IssuedInvoiceGroupLine_ManagedObject.ts" />

class IssuedInvoiceGroupLine extends IssuedInvoiceGroupLine_ManagedObject
{
        
/*    setObject(object, order, moc)
    {
        this.invoice        = object['invoice'];
        this.isArchived     = this.invoice['isArchived'];
        this.identifier     = object['id'];
        this.id             = object['id']; 
        this.tax_name       = object['taxname'];
        this.tax_quantity   = object['tax'];
        this.category_name  = object['categoryname'];
        this.format_name    = object['formatname'];
        this.menu_name      = object['menuname'];
        this.product_name   = object['productname'];
        this.product_price  = object['productprice'];
        this.id_product     = object['productid'];
        this.noteid         = object['noteid'];
        this.rate           = object['rate'];
        this.tags           = object['tags'];
        this.discount       = object['discount'];
        this.groupid        = object['groupid'];
        this.totalpricebase = object['totalpricebase'] || 0;
        this.totaltaxes     = object['totaltaxes'] || 0;
        this.totalprice     = object['totalprice'] || 0;
        this.totaldiscount  = object['totaldiscount'] || 0; 
        this.totalinvited   = object['totalinvited'] || 0;
        this.totaldeleted   = object['totaldeleted'] || 0;
        this.parent         = object['parent'];
        this.id_app         = object['id_app'];
        this.bulkmeasureunit = object['bulkmeasureunit'];
        this.bulkquantity = object['bulkquantity'];
        this.origindocumentid = object['origindocumentid'];
            
        
        this.orderindex  = object['orderindex'] || order;

        let key = this.isArchived ? 'archivedTicketLines' : 'documentLines';
        let lines = object[key] || [];
        this.quantity    = object['quantity'] ? object['quantity'] : lines.length;
        
        this.lines = MIOSet.set();
        if(lines){
            var instance = this;
            
            lines.forEach(function (line, index) {
                var p = MIOEntityDescription.insertNewObjectForEntityForName("IssuedInvoiceLine", moc) as IssuedInvoiceLine;
                line['group'] = instance;
                p.setObject(line,index, moc);
                instance.lines.addObject(p);
            }, instance);
        }
    }
    */
    // get id_invoice()
    // {
    //     return this.invoice.identifier;
    // }

    // get price()
    // {
    //     return parseFloat(this.valueForKey('quantity')) * parseFloat(this.valueForKey('product_price')) - parseFloat(this.valueForKey('discount') || 0);
    // }
    
    // get priceWithTax()
    // {
    //     return (1 + parseFloat(this.valueForKey('tax_quantity'))) * this.price;
    // }
        
}
