

class PartyDetailInfoViewController extends MUIViewController
{
    private nameTextField:MUITextField = null;
    private offerComboBox:MUIComboBox = null;
    private descriptionTextArea:MUITextArea = null;

    private addImageButton:MUIButton = null;
    private collectionView:MUICollectionView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(control, value){
            this._party.title = value;
        });

        this.offerComboBox = MUIOutlet(this, "offer-cb", "MUIComboBox");
        this.fillOfferComboBox(this.offerFetchedResultsController.fetchedObjects);

        this.descriptionTextArea = MUIOutlet(this, "description-ta", "MUITextArea");
        this.descriptionTextArea.setOnChangeText(this, function(control, value){
            this._party.description = value;
        });

        this.setupFileDialogHack();
        this.addImageButton = MUIOutlet(this, "add-image-btn", "MUIButton");
        this.addImageButton.setAction(this, function(){
            this.openFileDialog();
        });

        this.collectionView = MUIOutlet(this, "collection-view", "MUICollectionView");                
        this.collectionView.collectionViewLayout.itemSize = new MIOSize(140, 140);
        this.collectionView.dataSource = this;        
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _party:Party = null;
    set party(party:Party){
        this._party = party;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._party == null) return;

        this.nameTextField.text = this._party.title;
        this.descriptionTextArea.text = this._party.description;

        this.fetchedResultsController = null;
        this.collectionView.reloadData();
    }

    numberOfSections(collectionView:MUICollectionView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfItemsInSection(collectionView:MUICollectionView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellForItemAtIndexPath(collectionView:MUICollectionView, indexPath:MIOIndexPath){
        let cell = collectionView.dequeueReusableCellWithIdentifier("ImageCell") as PartyImageCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as PartyImage;

        cell.item = item;

        return cell;
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("PartyImage");
        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("url", false)];
        fetchRequest.predicate = MIOPredicate.predicateWithFormat("party.identifier == " + this._party.identifier);
                
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private _offerFetchedResultsController:MIOFetchedResultsController = null;
    set offerFetchedResultsController(value){
        if (value == null && this._offerFetchedResultsController != null)
            this._offerFetchedResultsController.delegate = null;
    
        this._offerFetchedResultsController = value;
    }
    
    get offerFetchedResultsController(){
        if (this._offerFetchedResultsController != null)
            return this._offerFetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("Offer");
        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("name", false)];
        //fetchRequest.predicate = MIOPredicate.predicateWithFormat("party.identifier == " + this._party.identifier);
                
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._offerFetchedResultsController = fetchedResultsController;    
        return this._offerFetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        if (this.fetchedResultsController === controller) {
            this.collectionView.reloadData();
        }
        else if (this.offerFetchedResultsController === controller){
            this.fillOfferComboBox(controller.fetchedObjects);
        }
    }

    private fillOfferComboBox(offers){
        this.offerComboBox.removeAllItems();
        this.offerComboBox.addItem("None", -1);

        for (let index = 0; index < offers.length; index++){
            let o = offers[index] as Offer;
            this.offerComboBox.addItem(o.name, o.identifier);
        }
        
    }
    
    private _inputLayer = null;
    private setupFileDialogHack(){

        //Web hack to open dialog
        let instance = this;

        this._inputLayer =  document.createElement("INPUT");
        this._inputLayer.setAttribute("type", "file");
        this._inputLayer.style.display = "none";        
        this._inputLayer.addEventListener('change', function(ev){
            let files = ev.target.files; // FileList object
            instance.filesDidSelect(files);
        }, false);
        
        MUICoreLayerAddSublayer(this.view.layer, this._inputLayer);
    }

    private openFileDialog(){
        this._inputLayer.click();
    }

    filesDidSelect(files){
        for (let index = 0; index < files.length; index++){
            let f = files[index];
            this.uploadFileToServer(f);
        }
    }

    uploadFileToServer(file){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let ws = ad.webService;

        ws.uploadImage(file, this, function(code, data){
            if (code == 200){
                let imageID = data["id"];
                let url = data["url"];
                MIOLog("Upload image OK!" + url);
                this.addPartyNewImage(imageID, url);
            }
            else {
                MIOLog("Upload image FAIL!");
            }
        });
    }

    private addPartyNewImage(imageID:string, urlString:string){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let pi = MIOEntityDescription.insertNewObjectForEntityForName("PartyImage", ad.managedObjectContext) as PartyImage;
        pi.imageID = imageID;
        pi.urlString = urlString;
        pi.party = this._party;
        this._party.addImagesObject(pi);
        ad.managedObjectContext.save();
    }
}