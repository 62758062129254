//
// Generated class IntegratorSync
//

/// <reference path="IntegratorSync_ManagedObject.ts" />

class IntegratorSync extends IntegratorSync_ManagedObject
{

}
