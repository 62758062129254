/**
 * Created by miguel on 1/2/17.
 */




class AddNewWorkerViewController extends MUIViewController
{
    private _worker:Employee = null;
    
    private nameTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private documentTextField:MUITextField = null;
    private address1TextField:MUITextField = null;
    //private address2TextField:MUITextField = null;
    private cityTextField:MUITextField = null;
    private countryDropdown:MUIButton = null;
    private postalCodeTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private mobilePhoneTextField:MUITextField = null;
    private passwordTextField:MUITextField = null;
    private pincodeTextField:MUITextField = null;

    private cancelButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private imageButton:MUIButton = null;

    get preferredContentSize()
    {
        return new MIOSize(500, 510);
    }

    viewDidLoad() {
        super.viewDidLoad();

        this.imageButton = MUIOutlet(this, 'anwv_c1_worker_image', 'MUIButton');
        this.nameTextField = MUIOutlet(this, "anwv_c2_worker_text", "MUITextField");
        this.emailTextField = MUIOutlet(this, "anwv_c2_email_text", "MUITextField");
        this.documentTextField = MUIOutlet(this, "anwv_c2_document_text", "MUITextField");
        this.passwordTextField = MUIOutlet(this, "anwv_c2_password_text", "MUITextField");
        this.pincodeTextField = MUIOutlet(this, "anwv_c2_pin_code_text", "MUITextField");
        this.address1TextField = MUIOutlet(this, "anwv_c2_address_text", "MUITextField");
        this.cityTextField = MUIOutlet(this, "anwv_c2_city_text", "MUITextField");
        this.countryDropdown = MUIOutlet(this, "country-dd", "MUIButton");
        this.postalCodeTextField = MUIOutlet(this, "anwv_c2_postal_code_text", "MUITextField");
        this.phoneTextField = MUIOutlet(this, "anwv_c2_phone_number_text", "MUITextField");
        this.mobilePhoneTextField = MUIOutlet(this, "anwv_c2_mobile_number_text", "MUITextField");
        
        this.cancelButton = MUIOutlet(this, 'anwv_nb_cancel_btn', 'MUIButton');     
        //If called from invoices, must change text
        if(this.navigationController != null) {
            this.cancelButton.setTitle(MIOLocalizeString('BACK','Back'));
            MUICoreLayerAddStyle(this.cancelButton.layer, 'back_button');
  
        }
        this.cancelButton.setAction(this, function ()
        {
            //if called from invoices, must behave as a navigation view
            if(this.navigationController != null) {
                this.navigationController.popViewController();
            }
            else
                this.dismissViewController(true);
        });

        this.saveButton = MUIOutlet(this, 'anwv_nb_ok_btn', 'MUIButton');
        this.saveButton.setAction(this, function ()
        {
            let name = this.nameTextField.text.trim();
            this._worker = DBHelper.createEmployee(name);

            this._worker.document = this.documentTextField.text.trim();
            this._worker.email = this.emailTextField.text.trim();
            this._worker.password = this.passwordTextField.text.trim();
            this._worker.pinCode = this.pincodeTextField.text.trim();
            this.address.address1 = this.address1TextField.text.trim();
            this.address.postalCode = this.postalCodeTextField.text.trim();
            this.address.country = this.country;
            this.address.countryName = this.country.name;
            this.address.city = this.cityTextField.text.trim();
            this.phone.number = this.phoneTextField.text.trim();
            this.mobilePhone.number = this.mobilePhoneTextField.text.trim();

            DBHelper.saveMainContext();

            if(this.navigationController != null) {
                this.navigationController.popViewController();
            }
            else
                this.dismissViewController(true);

        });

        
        this.imageButton.setAction(this, function ()
        {
            console.log('open image file');
        });

        this.countryDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropdown, null, this, function(controller:SelectEntityViewController, country:Country){                
                this.country = country;
                this.countryDropdown.title = country.name;                

                if (this._worker != null) {
                    this._worker.address.country = country;
                    this._worker.address.countryName = country.name;
                    this._worker.address.countryISOA2 = country.iso2;
                    this._worker.address.countryISOA3 = country.iso3;
                    this._worker.address.countryISON = country.ison;
                }
            });
        });   

        this.updateUI();
    }

    get address()
    {
        if(this._worker.address == null)
        {
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this._worker.address = MIOEntityDescription.insertNewObjectForEntityForName("Address", moc) as Address;
            this._worker.address.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._worker.address;
    }

    get phone()
    {
        if(this._worker.phone == null)
        {
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this._worker.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber;
            this._worker.phone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._worker.phone;
    }

    get mobilePhone()
    {
        if(this._worker.mobilePhone == null)
        {
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this._worker.mobilePhone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber;
            this._worker.mobilePhone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._worker.mobilePhone;
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private updateUI()
    {
        this.nameTextField.text = null;
        this.emailTextField.text = null;
        this.documentTextField.text = null;
        this.address1TextField.text = null;
        this.cityTextField.text = null;
        this.countryDropdown.title = null;
        this.postalCodeTextField.text = null;
        this.phoneTextField.text = null;
        this.mobilePhoneTextField.text = null;
    }
}