
/// <reference path="ProductDetailGeneralViewController.ts" />

class ProductDetailViewController extends MUIViewController
{            
    private saveButton:MUIButton = null;
    private actionButton:MUIButton = null;    

    private pageController:MUIPageController = null;
    private segmentedControl:MUISegmentedControl = null;

    private generalViewController:ProductDetailGeneralViewController = null;    
    private informationViewController:ProductDetailInformationViewController = null;    
    private componentsViewController:ProductDetailIngredientsViewController = null;    

    viewDidLoad(){
        super.viewDidLoad();

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this,function() {
            DBHelper.saveMainContextWithCompletion(this, function(){
                MIONotificationCenter.defaultCenter().postNotification("ItemDidChange", this._item);
            });
        });

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(){
           this.showActionAlert();
        });        

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function (control, index){            
            this.setPageIndex(index);
        });
        
        this.pageController = MUIOutlet(this, "page-controller", "MUIPageController");

        this.generalViewController = new ProductDetailGeneralViewController("product-detail-general-view");
        this.generalViewController.initWithResource("layout/products/ProductDetailGeneralView.html");
        this.pageController.addPageViewController(this.generalViewController);

        this.componentsViewController = new ProductDetailIngredientsViewController("product-ingredients-info-view");
        this.componentsViewController.initWithResource("layout/products/ProductIngredientsInfoView.html");
        this.pageController.addPageViewController(this.componentsViewController);

        this.informationViewController = new ProductDetailInformationViewController("product-information-view");
        this.informationViewController.initWithResource("layout/products/ProductInformationView.html");
        this.pageController.addPageViewController(this.informationViewController);

        MIONotificationCenter.defaultCenter().addObserver(this, "ProductReloadNotification", function(notification:MIONotification){
            if (this._item != null) this.item = this._item;
        });
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();

        let lastPageIndex = MIOUserDefaults.standardUserDefaults().valueForKey("LastProductDetailPageIndex");
        if (lastPageIndex != null) {
            let index = parseInt(lastPageIndex);
            this.segmentedControl.selectSegmentedAtIndex(index);
            this.setPageIndex(index);
        }
    }
    
    private product:Product = null;
    private _item:ProductListItem = null;
    set item(item:ProductListItem){                   
        if (this._item != null) this._item.unregisterObserver();
        this._item = item;                

        let predicate = MIOPredicate.predicateWithFormat('identifier = ' + this._item.itemID);
        let relationships = ["image", 'category.tax','source','ellaborationCenter','tax','taxBuy','productRates','productModifiersCategories','productWarehouses.warehouse', 'defaultProductFormat.format', 'stockCategory', 'productPlace'];
        DBHelper.queryObjectsWithCompletion("Product", null, predicate, relationships, this, function(objects){
            if (objects.length > 0) {
                this.product = objects[0];                
                this._item.registerObserverForProduct(this.product);
                this.updateUI();
            }
        });        
    }   
    
    private _productType = ProductType.None;
    set productType(value:ProductType){
        this._productType = value;

        if (this.generalViewController != null) this.generalViewController.productType = value;
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this.product == null) return;
                
        this.generalViewController.productType = this._productType;
        
        this.generalViewController.product = this.product;        
        this.componentsViewController.product = this.product;
        this.informationViewController.product = this.product;
    }

    private setPageIndex(index){  
        MIOUserDefaults.standardUserDefaults().setValueForKey("LastProductDetailPageIndex", index);
        this.pageController.showPageAtIndex(index);
    }
    
    private showActionAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Actions", "Choose an action", MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle("Delete", MUIAlertActionStyle.Destructive, this, function(){
            this.deleteProduct();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Default, null, null));

        this.presentViewController(avc, true);
    }

    private deleteProduct(){
        if (this._item != null) this._item.unregisterObserver();
        
        DBHelper.deleteObjectFromMainContext(this.product, false);
        DBHelper.saveMainContextWithCompletion(this, function(){
            MIONotificationCenter.defaultCenter().postNotification('ProductDidDeleted', this._item);    
            this.product = null;
            this._item = null;                    
        });        
    }
}


