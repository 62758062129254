
class ReturnOrderCell extends UITableViewCell
{
    private documentLabel:MUILabel = null;
    private dateLabel:MUILabel = null;
    private sourceLabel:MUILabel = null;    
    private destinationLabel:MUILabel = null;

    awakeFromHTML(){
        this.documentLabel = MUIOutlet(this, 'document-lbl', 'MUILabel');
        this.dateLabel = MUIOutlet(this, 'date-lbl', 'MUILabel');        
        this.sourceLabel = MUIOutlet(this, 'source-lbl', 'MUILabel');  
        this.destinationLabel = MUIOutlet(this, "destination-lbl", "MUILabel");
    }

    set item(item:StockNote){
        let dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;

        if (item.internalOrder != null) {
            this.documentLabel.text = "<strong>" + item.documentID + "</strong> <small>(" + item.internalOrder.documentID + ")</small>";
        }
        else {
            this.documentLabel.text = item.documentID;
        }
        this.dateLabel.text = dtf.stringFromDate(item.validationDate);
        if (item.type == StockNoteType.InternalOrder) this.sourceLabel.text = item.originName;
        // else if (item.type == StockNoteType.InternalOrderResponse) this.sourceLabel.text = item.destinationName;
        // else this.sourceLabel.text = null;

        this.sourceLabel.text = item.originName;
        this.destinationLabel.text = item.destinationName;
    }
}
