

/// <reference path="../model/ReportItem.ts" />
/// <reference path="../model/ReportChartItem.ts" />
/// <reference path="../model/ReportListItem.ts" />

class ReportHelper extends MIOObject {
    private static _sharedInstance: ReportHelper = null;
    static sharedInstance(): ReportHelper {

        if (this._sharedInstance == null) {
            this._sharedInstance = new ReportHelper();
            this._sharedInstance.init();
        }

        return this._sharedInstance;
    }

    static formattedValueForType(value, type:string, showNull?:boolean){
        if (value == null && showNull == false) return null;

        let v = value;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        switch (type){
            case "localized-string":
            v = MIOLocalizeString(value, value);
            break;

            case "number":
            v = ad.numberFormatter.stringFromNumber(value);
            break;

            case "percentage":
            v = ad.percentNumberFormatter.stringFromNumber(value);
            break;

            case "integer":
            v = ad.integerFormatter.stringFromNumber(value);
            break;

            case "currency":
            v = ad.currencyFormatter.stringFromNumber(value);
            break;

            case "long-currency":
            v = ad.longCurrencyFormatter.stringFromNumber(value);
            break;

            case "date":            
            v = ad.dateFormatter.stringFromDate(ad.serverDateFormatter.dateFromString(value));
            break;

            case "time":
            v = ad.timeFormatter.stringFromDate(ad.serverDateFormatter.dateFromString(value));
            break;

            case "date-time":
            v = ad.dateTimeFormatter.stringFromDate(ad.serverDateFormatter.dateFromString(value));
            break;

            case "long-date":            
            v = ad.longDateFormatter.stringFromDate(ad.serverDateFormatter.dateFromString(value));
            break;

            case "long-date-time":
            v = ad.longDateTimeFormatter.stringFromDate(ad.serverDateFormatter.dateFromString(value));
            break;

        }

        return v;
    }


    init(){
        super.init();
        MIONotificationCenter.defaultCenter().addObserver(this, "WorkspaceDidChange", function(notification:MIONotification){            
            this.reset();
        });
    }

    private _selectedDateTimeFrom:Date = null;
    private _selectedServerDateTimeFromString = null;
    private initDateTimeFrom(){
        let today = MIODateToday();
        let from = MIODateGetDateString(today) + " 00:00:00";            
        this._selectedDateTimeFrom = MIODateFromString(from);
        this._selectedServerDateTimeFromString = from;
    }

    set selectedDateTimeFrom(value:Date) {
        this._selectedDateTimeFrom = value;
        let sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter as MIODateFormatter;
        this._selectedServerDateTimeFromString = sdtf.stringFromDate(value);
    }    

    get selectedDateTimeFrom():Date {
        if (this._selectedDateTimeFrom == null) this.initDateTimeFrom();
        return this._selectedDateTimeFrom;
    }
    
    get selectedServerDateTimeFromString(){
        if (this._selectedServerDateTimeFromString == null) this.initDateTimeFrom();
        return this._selectedServerDateTimeFromString;
    }

    private _selectedDateTimeTo:Date = null;
    private _selectedServerDateTimeToString = null;
    private initDateTimeTo(){
        let today = MIODateToday();
        let to = MIODateGetDateString(today) + " 23:59:59";
        this._selectedDateTimeTo = MIODateFromString(to);
        this._selectedServerDateTimeToString = to;        
    }
    
    set selectedDateTimeTo(value:Date) {
        this._selectedDateTimeTo = value;
        let sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter as MIODateFormatter;
        this._selectedServerDateTimeToString = sdtf.stringFromDate(value);
    }
    get selectedDateTimeTo():Date {
        if (this._selectedDateTimeTo == null) this.initDateTimeTo();        
        return this._selectedDateTimeTo;
    }
    
    get selectedServerDateTimeToString(){
        if (this._selectedServerDateTimeToString == null) this.initDateTimeTo();
        return this._selectedServerDateTimeToString;
    }

    static reportFromJSON(item):ReportItem {
                
        let report = new ReportItem();
        report.identifier = item["id"];
        report.title = item["name"];
        report.type = item["type"];
        
        return report;
    }

    chartReportByID = {};
    listReportByID = {};    
    private reports = [];
    
    private isReportsDownloaded = false;

    private reset(){
        this.chartReportByID = {};
        this.listReportByID = {};    
        this.reports = [];
        
        this.isReportsDownloaded = false; 
        this._selectedDateTimeFrom = null;
        this._selectedDateTimeTo = null;   
    }

    //
    // Report queries
    //

    downloadReports(target?, completion?){

        if (this.isReportsDownloaded == true) {
            if (completion != null) completion.call(target, this.reports);
            return;
        }        

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws:WebService = ad.webService;

        ws.getReportItems(this, function(code, data) {            

            if (code != 200) return;
            let reports = data["data"];
            if (reports == null) return;

            this.isReportsDownloaded = true;

            ad.managedObjectContext.removeAllObjectsForEntityName("ReportChartItem");
            ad.managedObjectContext.removeAllObjectsForEntityName("ReportListItem");
            ad.managedObjectContext.save();

            this.chartReportByID = {};
            let charts = reports["charts"] != null ? reports["charts"] : [];
            for (let index = 0; index < charts.length; index++) {
                let chart = charts[index];                
                //let report:ReportItemChart = ReportItemChart.chartReportFromJSON(chart);
                let report:ReportChartItem = MIOEntityDescription.insertNewObjectForEntityForName("ReportChartItem", ad.managedObjectContext) as ReportChartItem;
                report.parseJSON(chart);
                this.chartReportByID[report.identifier] = report;
                this.reports.push(report);
            }

            this.listReportByID = {};
            let lists = reports["lists"] != null ? reports["lists"] : [];
            for (let index = 0; index < lists.length; index++){
                let list = lists[index];
                let report:ReportListItem = MIOEntityDescription.insertNewObjectForEntityForName("ReportListItem", ad.managedObjectContext) as ReportListItem;
                //let report:ReportItemList = ReportItemList.listReportFromJSON(list);
                report.parseJSON(list);
                this.listReportByID[report.identifier] = report;
                this.reports.push(report);
            }

            ad.managedObjectContext.save();

            if (completion != null) completion.call(target, this.reports);           
        });
    }

    executeReportQuery(report: ReportItem, where:string, target?, completion?) {
        let ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
        report.executeQuery(this.selectedDateTimeFrom, this.selectedDateTimeTo, where, ad.selectedIdentifier, ad.selectedIdentifierType, target, completion);
    }

    filtersFromReport(report:Report, target, completion) {
        DBHelper.queryObjectsWithCompletion("ReportFilter", [MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)], MIOPredicate.predicateWithFormat("report.identifier == " + report.identifier), [], this, function(objects){
            // Convert report filters to filter option
            let filters = [];
            for (let index = 0; index < objects.length; index++){
                let obj = objects[index] as ReportFilter;

                if (obj.type == FilterFieldType.DropDown) {
                    let ft = FilterOption.filterWithFetchRequest(obj.identifier, obj.title, obj.key, obj.type, obj.entityName, obj.entityTitleKey, obj.entityValueKey != null ? obj.entityValueKey : "identifier");
                    ft.predicateFormat = obj.entityPredicateFormat;
                    ft.allowMultipleSelection = obj.allowMultipleSelection;
                    filters.addObject(ft);
                }
                else {
                    let ft = FilterOption.filterWithTitle(obj.identifier, obj.title, obj.key, obj.type, null, null, null, null, obj.values);
                    filters.addObject(ft);
                }
            }

            if (target != null && completion != null) completion.call(target, filters);
        });
    }

    //
    // Export     
    //

    showExportActions(filename:string, title:string, dateInterval:string, data){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Export options", "File options to export", MUIAlertViewStyle.Default);

        // avc.addAction(MUIAlertAction.alertActionWithTitle("PDF", MUIAlertActionStyle.Default, this, function(){
        //     this.export2PDF();
        // }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("XLS", MUIAlertActionStyle.Default, this, function(){
            this.export2XLS(filename, title, dateInterval, data);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("CSV", MUIAlertActionStyle.Default, this, function(){
            this.export2CSV(filename, title, dateInterval, data);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "Cancel"), MUIAlertActionStyle.Cancel, null, null));
        AppHelper.sharedInstance().presentViewController(avc, true);
    }

    private export2PDF(){        
        let pdf = new jsPDF('p', 'pt', 'a4');
        pdf.html(this.resultsView.layer, {callback: function(doc) {
            doc.save('informe.pdf');
        }});
        // pdf.fromHTML(this.resultsView.layer, 15, 15, {}, function() {
         
        // });

        // html2canvas(this.resultsView.layer, {
        //     onrendered: function(canvas) {         
        //         let imgData = canvas.toDataURL(
        //             'image/png');              
        //         let doc = new jsPDF('p', 'mm');
        //         doc.addImage(imgData, 'PNG', 10, 10);
        //         doc.save('sample-file.pdf');
        //     }
        // });                
    }

    private export2XLS(filename:string, title:string, dateInterval:string, data){        
        let fullFileName = filename + ".xlsx";

        let wb = this.report2XLS(title, dateInterval, data);
        XLSX.writeFile(wb, fullFileName, {cellStyles: true});
    }

    private export2CSV(filename:string, title:string, dateInterval:string, data){
        let fullFileName = filename + ".csv";

        let wb = this.report2XLS(title, dateInterval, data);
        XLSX.writeFile(wb, fullFileName);
    }

    private titleColor = 0x0B8081;
    private startColumn = 1;
    
    private cellid_by_index(colIndex):string{
        let startIndex = "A".charCodeAt(0);
        if (colIndex < 26) return String.fromCharCode(startIndex + colIndex);

        let times = (colIndex / 26) | 0;
        let m = colIndex % 26;

        let colID:string = "";
        for (let index = 0; index < times; index++){
            colID += "A";
        }

        colID += String.fromCharCode(startIndex + m);
        return colID;
    }

    private report2XLS(title:string, dateInterval:string, reportData){        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("Sheet");        
        let ws_data = [["", "", ""],
                        ["", "Business:", ad.selectedBusinessName],
                        ["", "Report name:", title],
                        ["", "Date interval:", dateInterval]];
        let ws = XLSX.utils.aoa_to_sheet(ws_data);        
        wb.Sheets["Sheet"] = ws;

        ws.B2.s = {sz: 18, color: { rgb: this.titleColor } };
        ws.B3.s = {sz: 18, color: { rgb: this.titleColor } };
        ws.B4.s = {sz: 18, color: { rgb: this.titleColor } };        
        ws.C2.s = {sz: 18, bold:true, color: { rgb: this.titleColor } };
        ws.C3.s = {sz: 18, bold:true, color: { rgb: this.titleColor } };
        ws.C4.s = {sz: 18, bold:true, color: { rgb: this.titleColor } };

        //this.add_cell_to_sheet(ws, "A5", "Test");        
        let cellRowIndex = 7;
        let cellColIndex = this.startColumn;
        let cellColPosition = 0;
        let maxColLength = 0;

        for (let index = 0; index < reportData.length; index++){            
            let cellID = this.cellid_by_index(cellColIndex) + cellRowIndex;
            let line = reportData[index];
            let type = line["Type"];            

            if (type != "table") continue;

            let tl = line["Title"];
            this.add_cell_to_sheet(ws, cellID , tl, null, {sz: 18, bold:true, color: { rgb: this.titleColor }});
            cellRowIndex++;

            let columns = line["Columns"];            
            for (let colIndex = 0; colIndex < columns.length; colIndex++){
                let col = columns[colIndex];
                tl = col["Title"];
                cellID = this.cellid_by_index(colIndex + cellColIndex) + cellRowIndex;
                this.add_cell_to_sheet(ws, cellID, tl, "string", {sz: 14, color: { rgb: this.titleColor }, top: { style: "medium", color: { rgb: this.titleColor }}});                
            }

            if (columns.length > maxColLength) maxColLength = columns.length;
            cellColIndex = this.startColumn;
            cellRowIndex++;
            
            let rows = line["Rows"];
            for (let rowIndex = 0; rowIndex < rows.length; rowIndex++){
                let r = rows[rowIndex];
                let subRow = r["__sub_row__"] == true ? true : false;                
                for (let colIndex = 0; colIndex < columns.length; colIndex++){
                    cellID = this.cellid_by_index(cellColIndex + colIndex) + cellRowIndex;                                        
                    let col = columns[colIndex];
                    let key = col["Key"];                                        
                    let [value, t] = this.type2XLSType(r[key], r["__" + key + "__formatter__"], col["Type"]);                    
                    let s = null;
                    if (subRow == true){
                        s = {color:{ rgb: 0x808080}};
                         if (colIndex == 0) s.alignment = {indent : 1};
                    }                                             
                    if (value != null) this.add_cell_to_sheet(ws, cellID, value, t, s);                    
                }
                cellRowIndex++;
                cellColIndex = this.startColumn;  
            }

            
            let subTotals = line["SubTotals"];
            if (Object.keys(subTotals).length === 0) {
                cellRowIndex += 2;
                cellColIndex = this.startColumn;          
                continue;
            }

            // Totals            
            for (let colIndex = 0; colIndex < columns.length; colIndex++){                            
                cellID = this.cellid_by_index(cellColIndex + colIndex) + cellRowIndex;                    
                let col = columns[colIndex];
                let key = col["Key"];                
                //let align = col["Align"];                
                let [value, t] = this.type2XLSType(subTotals[key], null, col["Type"]);     
                if (colIndex == 0) {
                    value = MIOLocalizeString("TOTAL", "Total"); 
                    this.add_cell_to_sheet(ws, cellID, value, "string", {bold:true});                    
                }
                else {                                        
                    if (value != null)this.add_cell_to_sheet(ws, cellID, value, t, {bold:true});
                }                
            }

            cellRowIndex += 2;
            cellColIndex = this.startColumn;          
        }
        
        /// Auto width in very column
        if(!ws['!cols']) ws['!cols'] = [];
        ws['!cols'][0] = {wch: 2};
        for (let index = 0; index < maxColLength; index++){
            ws['!cols'][index + 1] = { auto: 1};
        }        

        return wb;
    }

    private type2XLSType(value, cellFormatter, colFormatter){                        
        let type = "string";
        
        let formatter = cellFormatter;        
        if (formatter == null) formatter = colFormatter;        
                    
        switch(formatter){
            case "currency":
            type = "currency";
            if (value == null) value = 0;
            break;

            case "number":
            case "integer":
            case "float":
            case "decimal":
            type = "number";
            if (value == null) value = 0;
            break;

            case "date":
            case "time":
            case "date-time":
            case "long-date":
            case "long-date-time":
            type = "date";
            break;
        }

        return [value, type];
    }

    private add_cell_to_sheet(worksheet, address, value, type?, style?) {
        /* cell object */
        let cell = {t:'?', v:value};        
    
        /* assign type */        
        if (type == null) {
            if(typeof value == "string") cell.t = 's'; // string
            else if(typeof value == "number") cell.t = 'n'; // number
            else if(value === true || value === false) cell.t = 'b'; // boolean
            else if(value instanceof Date) cell.t = 'd';
            else throw new Error("cannot store value");
        }
        else {
            if(type == "string") cell.t = 's'; // string
            else if(type == "date") {                                 
                if (value.length > 1){
                    let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
                    let date = ad.serverDateFormatter.dateFromString(value);
                    cell["v"] = date.getTime();// ad.dateFormatter.stringFromDate(date);                    
                    cell.t = 'd';
                }                                
            }
            else if(type == "number") cell.t = 'n'; // number            
            else if(type == "currency") {
                cell.t = 'n'; // number
                //TODO: Add currency format
            }            
        }
    
        /* add to worksheet, overwriting a cell if it exists */
        worksheet[address] = cell;

        if (style != null) {            
            worksheet[address].s = style;
        }
    
        /* find the cell range */
        let range = XLSX.utils.decode_range(worksheet['!ref']);
        let addr = XLSX.utils.decode_cell(address);
    
        /* extend the range to include the new cell */
        if(range.s.c > addr.c) range.s.c = addr.c;
        if(range.s.r > addr.r) range.s.r = addr.r;
        if(range.e.c < addr.c) range.e.c = addr.c;
        if(range.e.r < addr.r) range.e.r = addr.r;
    
        /* update range */
        worksheet['!ref'] = XLSX.utils.encode_range(range);
    }

    private nextColumn(cellColumnIndex:string):string {
        let colLen = cellColumnIndex.length;
        
        //colLen += 

        String.fromCharCode(cellColIndex.charCodeAt(cellColPosition) + 1);
    }

}