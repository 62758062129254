class WorkspaceViewController extends MUIViewController
{    
    private backButton:MUIButton = null;
    private closeButton:MUIButton = null;
    private addButton:MUIButton = null;
    private searchTextField:MUITextField = null;
    private tableView:MUITableView = null;
            
    private searchString:string = null;   
    private searchTimer:MIOTimer = null;     
    
    get preferredContentSize(){
        return new MIOSize(400, 400);
    }

    viewDidLoad(){
        super.viewDidLoad();        

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");        
        this.backButton.setAction(this, function(){
            this.navigationController.popViewController(true);
        });        

        this.closeButton = MUIOutlet(this, 'close-btn', 'MUIButton');        
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.hidden = MIOCoreIsPhone() ? true : false;
        this.addButton.setAction(this, function(){
            this.showOptionsAlertView();
        });                

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.searchTextField.setOnChangeText(this, function (textfield, value) {            
            this.searchString = value.length > 0 ? value : null;
            if (this.searchTimer != null) this.searchTimer.invalidate();

            this.searchTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(){
                this.fetchedResultsController = null;
                this.tableView.reloadData();
            });            
        });
        
        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.reloadData();
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);

        this.backButton.hidden = this.isModal == true ? true : false;
        this.closeButton.hidden = this.isModal == true ? false : true;
 
        this.selectedItem = null;
    }

    private _isModal = true;
    set isModal(value:boolean) {
        this._isModal = value;        
    }

    get isModal():boolean{
        return this._isModal;
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell = null;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Business;        
        if(item.type == BusinessType.Company)
            cell = tableview.dequeueReusableCellWithIdentifier('CompanyCell') as BusinessCell;
        else     
            cell = tableview.dequeueReusableCellWithIdentifier('PlaceCell') as BusinessCell;
        
        cell.item = item;                
    
        return cell;
    }    
    
    private selectedItem = null;
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.selectedItem = item;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;        

        if (SettingsHelper.sharedInstance().isNewServersLoaded) {
            ad.setUserBusiness(item, this, function(){
                this.dismissViewController(true);    
                tableView.deselectCellAtIndexPath(indexPath);
            });    
        }

        else {
            ad.setBusiness(item, this, function(){
                this.dismissViewController(true);    
                tableView.deselectCellAtIndexPath(indexPath);
            });    
        }
    }

    accessoryButtonTappedForRowWithIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
                
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Business;
        
        let vc = new BusinessViewController('add-place-co-view');            
        vc.initWithResource('layout/workspace/AddPlaceCoView.html');                        
        vc.item = item;
            
        this.navigationController.pushViewController(vc, true);
    }  

    get fetchRequest(){
        
        if (SettingsHelper.sharedInstance().isNewServersLoaded){

            let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('UserBusiness');    
            fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('indexPath', true)];
        
            if (this.searchString != null)
                fetchRequest.predicate = MIOPredicate.predicateWithFormat('name CONTAINS "' + this.searchString + '"');

            return fetchRequest;
        }
        else {
            let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Business');    
            fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('indexPath', true)];
        
            if (this.searchString != null)
                fetchRequest.predicate = MIOPredicate.predicateWithFormat('name CONTAINS "' + this.searchString + '"');

            return fetchRequest;
        }
    }

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;    
        
        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(this.fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    // Options alert view method

    private showOptionsAlertView(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('CHOOSE THE TYPE',"Choose the type"), MIOLocalizeString('SELECT THE TYPE TO CREATE A PLACE OR COMPANY',"Select the type to create a place or company "), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('COMPANY',"Company"), MUIAlertActionStyle.Default, this, function(){
            let vc = new BusinessViewController('add-place-co-view');
            vc.initWithResource('layout/workspace/AddPlaceCoView.html');
            
            vc.mode = BusinessType.Company;
            //vc.delegate = this;
            this.navigationController.pushViewController(vc, true);    
            
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('PLACE',"Place"), MUIAlertActionStyle.Default, this, function(){
            let vc = new BusinessViewController('add-place-co-view');
            vc.initWithResource('layout/workspace/AddPlaceCoView.html');

            vc.mode = BusinessType.Place;
            //this._addvc.delegate = this;
            this.navigationController.pushViewController(vc, true);    
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }
    
}

