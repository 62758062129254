/**
 * Created by crisan on 7/12/16.
 */



/// <reference path="../../model/ProductListItem.ts" />


class ProductCategoryCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private imageView:MUIImageView = null;
    
    private _separator = null;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, "pclv_category_lbl", "MUILabel");
        this.imageView = MUIOutlet(this, 'pclv_category_image','MUIImageView');
    }

    set item(i:Category)
    {
        this.nameLabel.text = i.name;
        this.imageView.setImage( i.image ? i.image.urlByType(DBImageType.Category) : null );        
    }

}