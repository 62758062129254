
/// <reference path="../notes/NoteDetailHeaderView.ts" />

class MovementDetailHeaderView extends NoteDetailHeaderView
{
    private documentIDLabel:MUILabel = null;    
    private originNameLabel:MUILabel = null;
    private destinationNameLabel:MUILabel = null;        

    private referenceTextField:MUITextField = null;
    private referenceLabel:MUILabel = null;

    private documentDateLabel:MUILabel = null;
    private documentDateTextField:MUITextField = null;
    private documentDateButton:MUIButton = null;

    private validationDateLabel:MUILabel = null;
    private validationDateTextField:MUITextField = null;
    private validationDateButton:MUIButton = null;

    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter as MIODateFormatter;

    awakeFromHTML(){
        super.awakeFromHTML();
        
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.originNameLabel = MUIOutlet(this, "origin-lbl", "MUILabel");
        this.destinationNameLabel = MUIOutlet(this, "destination-lbl", "MUILabel");

        this.referenceTextField = MUIOutlet(this, "reference-tf", "MUITextField");
        if (this.referenceTextField != null) this.referenceTextField.setOnChangeText(this, function (control, value){
            this.stockNote.externalReference = value;
        });
        this.referenceLabel = MUIOutlet(this, "reference-lbl", "MUILabel");        

        this.documentDateLabel = MUIOutlet(this, "document-date-lbl", "MUILabel");
        this.documentDateTextField = MUIOutlet(this, "document-date-tf", "MUITextField");
        this.setupDocumentDateTextField(this.documentDateTextField);
        this.documentDateButton = MUIOutlet(this, "document-date-btn", 'MUIButton');
        this.setupCalendarButton(this.documentDateButton, NoteDetailHeaderViewDateType.DocumentDate);

        this.validationDateLabel = MUIOutlet(this, "validation-date-lbl", "MUILabel");
        this.validationDateTextField = MUIOutlet(this, "validation-date-tf", "MUITextField");
        this.setupValidationDateTextField(this.validationDateTextField);
        this.validationDateButton = MUIOutlet(this, "validation-date-btn", "MUIButton");
        this.setupCalendarButton(this.validationDateButton, NoteDetailHeaderViewDateType.ValidationDate);
    }
    
    set note(value:StockNote){
        this.stockNote = value;

        this.documentIDLabel.text = value.documentID;
        this.originNameLabel.text = value.originName ? value.originName : MIOLocalizeString("DEFAULT WAREHOUSE", "DEFAULT WAREHOUSE");
        this.destinationNameLabel.text = value.destinationName ? value.destinationName : MIOLocalizeString("DEFAULT WAREHOUSE", "DEFAULT WAREHOUSE");

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let documentDateString = ad.dateTimeFormatter.stringFromDate(value.stockDate);
        if (this.documentDateLabel != null) this.documentDateLabel.text = documentDateString;
        if (this.documentDateTextField != null) this.documentDateTextField.text = documentDateString;

        let stockDateString = ad.dateTimeFormatter.stringFromDate(value.validationDate);
        if (this.validationDateLabel != null) this.validationDateLabel.text = stockDateString;
        if (this.validationDateTextField != null) this.validationDateTextField.text = stockDateString;

        if (this.referenceTextField != null) this.referenceTextField.text = value.externalReference;
        if (this.referenceLabel != null) this.referenceLabel.text = value.externalReference;
    }

    protected dateDidChange(type:NoteDetailHeaderViewDateType, date:Date){
        switch(type){
            case NoteDetailHeaderViewDateType.DocumentDate:
            this.documentDateTextField.text = this.dtf.stringFromDate(date);
            break;

            case NoteDetailHeaderViewDateType.ValidationDate:
            this.validationDateTextField.text = this.dtf.stringFromDate(date);
            break;
        }
    }

}