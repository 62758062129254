//
// Generated class Configuration
//

/// <reference path="Configuration_ManagedObject.ts" />

enum ConfigurationValueType
{
    String = 0, 
    Boolean = 1,
    Text = 2,
    JSON = 3,
    XML = 4,
    Entity = 5
}

class Configuration extends Configuration_ManagedObject
{
    get stringValue():string {
        let stringValue = this.removeQuotes(this.value);
        return stringValue;
    }

    set stringValue(value:string) {
        this.value = this.addQuotes(value);
    }

    get boolValue():boolean {
        let boolValue = this.removeQuotes(this.value);
        return (boolValue == 'true') ? true : false;
    }

    set boolValue(value:boolean) {
        this.value = this.addQuotes(value);
    }

    private removeQuotes(value) {
        return value.replace(/['"]+/g, '');
    }

    private addQuotes(value) {
        return '"' + value + '"';
    }
}
