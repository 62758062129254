

class CashDeskSummarySalesSubCell extends MUITableViewCell
{
    nameLabel = null;
    totalLabel = null;
    private _separator = null;

    cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, 'cdsv_subsales_title_lbl','MUILabel');
        this.totalLabel = MUIOutlet(this, 'cdsv_subsales_total_lbl','MUILabel');
    }

    set item(i)
    {
        this.nameLabel.text = i.concept;
        this.totalLabel.text = this.cf.stringFromNumber(i.quantity);
    }
}
