
class TicketSnapshotLogCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private timeLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private locationLabel:MUILabel = null;

    awakeFromHTML(){;
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.timeLabel = MUIOutlet(this, "time-lbl", "MUILabel");
        this.conceptLabel = MUIOutlet(this, "concept-lbl", "MUILabel");
        this.locationLabel = MUIOutlet(this, "location-lbl", "MUILabel");
    }

    setItem(item:LogOperation, index, locationName:string, lines){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.timeLabel.text = ad.timeFormatter.stringFromDate(item.date);
        this.conceptLabel.text =  " Print #" + index + ": " + " - " + item.concept;        
        this.locationLabel.text = locationName;
        this.nameLabel.text = item.employeeName;


        // Found the employyee        
        // let ls = _MIOSortDescriptorSortObjects(lines, [MIOSortDescriptor.sortDescriptorWithKey("date", false)]);
        // for (let i = 0; i < ls.length; i++){
        //     let l = ls[i] as ArchivedTicketLine;
        //     if (l.statusDate < item.date) continue;

        //     this.nameLabel.text = l.workerName;            
        //     break;
        // }
        
    }
}