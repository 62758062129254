class SupplierProductCell extends MUITableViewCell
{    
    private nameLabel:MUILabel = null;
    private nameTextField:MUITextField = null;
    private referenceTextField:MUITextField = null;            
    private lastPriceLabel:MUILabel = null;
    private priceTextField:MUITextField = null;
    private discountTextField:MUITextField = null;    
    
    private cf = MUIWebApplication.sharedInstance().delegate.longCurrencyFormatter as MIONumberFormatter;
    
    awakeFromHTML(){
        
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.setupProductTextField(this.nameTextField);
                
        this.referenceTextField = MUIOutlet(this, 'reference-tf','MUITextField');
        this.setupTextField(this.referenceTextField, this, this.referenceDidChange);

        this.lastPriceLabel = MUIOutlet(this, "last-price-lbl", "MUILabel");

        this.priceTextField = MUIOutlet(this, 'price-tf','MUITextField'); 
        this.priceTextField.formatter = this.cf;
        this.setupTextField(this.priceTextField, this, this.priceDidChange);

        this.discountTextField = MUIOutlet(this, "discount-tf", "MUITextField");
        this.discountTextField.formatter = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter;
        this.setupTextField(this.discountTextField, this, this.discountDidChange);

        this.selectionStyle = MUITableViewCellSelectionStyle.None;

        this.reset();
    }

    private product:Product = null;
    private reference:string = null;
    private price = null;
    private productPrice = null;
    private discount = null;

    private _supplier:Supplier = null;
    set supplier(supplier:Supplier){
        this._supplier = supplier;
    }

    private supplierProduct:SupplierProduct = null;
    set item(item:SupplierProduct){        
        this.reset();        
        this.supplierProduct = item;
        this.product = item.product;
        this.reference = item.supplierReference;
        this.price = item.price;
        this.discount = item.discountString;
        
        if (this.nameLabel != null) this.nameLabel.text = this.productName();
        if (this.nameTextField != null) this.nameTextField.text = this.productName();
        this.referenceTextField.text = item.supplierReference;
        this.lastPriceLabel.text = this.cf.stringFromNumber(item.productLastPrice);
        this.priceTextField.text = this.cf.stringFromNumber(item.productPrice);
        this.discountTextField.text = this.discount;
    }

    private reset(){
        if (this.nameLabel != null) this.nameLabel.text = null;
        if (this.nameTextField != null) this.nameTextField.text = null;
        this.referenceTextField.text = null;
        this.lastPriceLabel.text = null;
        this.priceTextField.text = null; 
        this.discountTextField.text = null;       
    }

    private setupProductTextField(textField:MUITextField){
        if (textField == null) return;
        textField.setOnChangeText(this, function(control, value){
            AppHelper.sharedInstance().showSelectProductViewControllerFromView(textField, value, {"SectionNameKeyPath":"productTypeString"}, this, this.productDidSelect, this, this.addProduct);
        });        
    }

    private productDidSelect(controller:SelectEntityViewController, product:Product){
        this.product = product;
        this.nameTextField.text = product.name;
        this.lastPriceLabel.text = null;
    }

    private addProduct(value:string){
        AppHelper.sharedInstance().showAddStockProductAlertViewController(value, null, this, this.productDidAdd);
    }

    private productDidAdd(product:Product){
        this.product = product;
        this.nameTextField.text = product.name;
    }

    private setupTextField(textField:MUITextField, target, completion){
        textField.setOnChangeText(this, function(textfield, value){            
            completion.call(target, value);
        });    

        textField.setOnEnterPress(this, function(){
            if (this.supplierProduct != null) return;
            DBHelper.createSupplierProduct(this._supplier, this.product, this.price, this.productPrice, this.reference, this.discount);
            DBHelper.saveMainContext();
            this.reset();    
        });
    }

    private referenceDidChange(value){
        let objs = this._supplier.supplierProducts.filterWithPredicate(MIOPredicate.predicateWithFormat("supplierReference == '" + value + "'"));
        if (objs.length > 0) {
            AppHelper.showErrorMessage(null, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("FOUND DULICATED REFERENCES, PLEASE CHECK.", "FOUND DULICATED REFERENCES, PLEASE CHECK."));            
        } 

        this.reference = value;
        this.updateSupplierProduct();
    }

    private priceDidChange(value){        
        this.productPrice = this.cf.numberFromString(value);
        this.price = DBHelper.calculateMinumCostFromProductPrice(this.productPrice, this.product);
        this.updateSupplierProduct();
    }

    private discountDidChange(value){
        this.discount = value;
        this.updateSupplierProduct();
    }

    private updateSupplierProduct(){
        if (this.supplierProduct == null) return;

        this.supplierProduct.supplierReference = this.reference;
        this.supplierProduct.price = this.price;
        this.supplierProduct.productPrice = this.productPrice;
        this.supplierProduct.discountString = this.discount;
    }

    private productName(){
        if (this.supplierProduct.product != null) return this.supplierProduct.product.name;
        return MIOLocalizeString("DELETED PRODUCT!", "DELETED PRODUCT!");
    }
    
}
