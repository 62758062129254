

class ProductModifierDetailCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private descTextField:MUITextField = null;
    private defaultSwitchButton:MUISwitchButton = null;
    private visibleSwitchButton:MUISwitchButton = null;
    private modifiedPriceTF:MUITextField = null;
    private taxDropDown:MUIButton = null;
    private consumptionTF:MUITextField = null;
    private productConsumptionTF:MUITextField = null;
    private shrinkageTextField:MUITextField = null;
    private imageView:MUIImageView = null;

    private ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, 'product-name-lbl', 'MUILabel');
        this.descTextField = MUIOutlet(this, "modifier-desc", "MUITextField");
        if (this.descTextField != null) {
            this.descTextField.setOnChangeText(this, function(control, value:string){
                this.productModifier.name = value;
            })
        }

        this.defaultSwitchButton = MUIOutlet(this, "default-switch", "MUISwitchButton");
        this.defaultSwitchButton.setOnChangeValue(this, function(defaultSwitchButton:MUISwitchButton, value:boolean){
            this.productModifier.isDefault = value;
        });

        this.visibleSwitchButton = MUIOutlet(this, "visible-switch", "MUISwitchButton");
        this.visibleSwitchButton.setOnChangeValue(this, function(visibleSwitchButton:MUISwitchButton, value:boolean){
            this.productModifier.isVisible = value;
        });

        this.modifiedPriceTF = MUIOutlet(this, "modify-price-tf", "MUITextField");
        this.modifiedPriceTF.setOnChangeText(this, function(control, value:number){
            this.productModifier.price = value;
        });

        this.consumptionTF = MUIOutlet(this, "consumption-tf", "MUITextField");
        this.consumptionTF.setOnChangeText(this, function(control, value:number){
            this.productModifier.consumptionQuantity = value;
        });

        this.shrinkageTextField = MUIOutlet(this, 'shrinkage-tf', 'MUITextField');
        if (this.shrinkageTextField != null){
            this.shrinkageTextField.setOnChangeText(this, function(control, value:number){
                this.productModifier.lossInProduction = value;
            });
        }

        this.productConsumptionTF = MUIOutlet(this, 'product-consumption-tf', 'MUITextField');
        if (this.productConsumptionTF != null){
            this.productConsumptionTF.setOnChangeText(this, function(control, value:number){
                this.productModifier.productModifierCount = value;
            });
        }

        this.imageView = MUIOutlet(this, 'product-img','MUIImageView');

        this.taxDropDown = MUIOutlet(this, 'tax-btn', 'MUIButton');
        this.taxDropDown.setAction(this, function() {
            //view popup for tax
            this.showTaxSelectEntityViewController(this.taxDropDown, "Tax");
        });

        this.separatorStyle = MUITableViewCellSeparatorStyle.None;
    }

    private productModifier:ProductModifier = null;
    set item(item:ProductModifier){
        this.productModifier = item;  //ERROR: the related Product is not being sent the first time

        // switch (item.productModifierCategory.productModifierCategoryType){
            
        //     case ProductModifierCategoryType.Manual:
        //         this.descTextField.text = this.productModifier.name;
        //     break;

        //     case ProductModifierCategoryType.Category || ProductModifierCategoryType.Product:
        //         this.nameLabel.text = this.productModifier.name;
        //         //set product imageURL if exists
        //         if(this.productModifier.product != null) {
        //             this.imageView.setImage(this.productModifier.product.imageURL)
        //         }
        //     break;

        //     case ProductModifierCategoryType.Product:
        //         this.nameLabel.text = this.productModifier.name;
        //         //set product imageURL if exists
        //         if(this.productModifier.product != null) {
        //             this.imageView.setImage(this.productModifier.product.imageURL)
        //         }
        //     break;
        // }        

        if (this.descTextField != null) {
            this.descTextField.text = this.productModifier.name;
        } else if (this.nameLabel != null) {
            this.nameLabel.text = this.productModifier.name;
        }

        if(this.productModifier.product != null && this.imageView != null) {
            this.imageView.setImage(this.productModifier.product.imageURL)
        }

        this.defaultSwitchButton.on = this.productModifier.isDefault;
        this.visibleSwitchButton.on =this.productModifier.isVisible;
        this.modifiedPriceTF.text = (this.productModifier.price != null) ? this.ad.currencyFormatter.stringFromNumber(this.productModifier.price) : null;
        this.taxDropDown.title = (this.productModifier.tax != null) ? this.productModifier.tax.name : null;
        this.consumptionTF.text = (this.productModifier.consumptionQuantity != null) ? this.ad.numberFormatter.stringFromNumber(this.productModifier.consumptionQuantity) : null;
        if(this.shrinkageTextField != null) {
            this.shrinkageTextField.text = (this.productModifier.lossInProduction != null) ? this.ad.numberFormatter.stringFromNumber(this.productModifier.lossInProduction) : null;
        }
        if(this.productConsumptionTF != null) {
            this.productConsumptionTF.text = (this.productModifier.productModifierCount != null) ? this.ad.numberFormatter.stringFromNumber(this.productModifier.productModifierCount) : null;
        }
    }

    private showTaxSelectEntityViewController(dropdownButton, entityName) {
        let predicateFormat = null;        
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(dropdownButton, entityName, "name", predicateFormat, this, function(controller, object:MIOManagedObject){

            let title = object.valueForKey("name");
            //let value = object.valueForKey("identifier");
            
            dropdownButton.title = title;
            this.productModifier.tax = object;
        });        
    }

}