

/// <reference path="ClientDetailViewController.ts" />
/// <reference path="ClientDebtViewController.ts" />
/// <reference path="ClientDataViewController.ts" />
/// <reference path="AddNewClientViewController.ts" />


class ClientsListViewController extends BaseTableViewController
{        
    private addButton:MUIButton = null;     

    viewDidLoad(){
        super.viewDidLoad();
        
        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function (){
            this.showAddClientViewController();
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();
       
        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("ClientCell") as ClientCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Client;

        cell.item = item;

        return cell;
    }

    didSelectCellAtIndexPath(tableView,indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Client;
        this.showSelectedClient(item);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];    
        let predicateFormat = this.searchString != null ? "name CONTAINS '" + this.searchString + "'" : null;
            
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Client", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 50;
        fetchRequest.relationshipKeyPathsForPrefetching = ["accountBalance", "loyaltyBalance", "debtBalance"];
        
        let fetchedResultsController = new MIOFetchedResultsController();        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private addNewClientViewController = null;
    private showAddClientViewController(){
        if(this.addNewClientViewController == null) {
            this.addNewClientViewController = new AddNewClientViewController('add-new-client-view');            
            this.addNewClientViewController.initWithResource('layout/clients/AddNewClientView.html');
            this.addNewClientViewController.delegate = this;
        }
        this.presentViewController(this.addNewClientViewController, true);
    }
    
    public clientDidInserted(client:Client){
        //this.client = client;
        this.tableView.reloadData();
    }

    private showNoItemSelected(){        
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Client", NoItemSelectedViewControllerType.Client);
        this.splitViewController.showDetailViewController(vc);
    }

    private detailViewController:ClientDetailViewController = null;
    private showSelectedClient(client:Client){
        if(this.detailViewController == null) {
            this.detailViewController  = new ClientDetailViewController("clients-detail-view");
            this.detailViewController.initWithResource("layout/clients/ClientsDetailView.html");
        }

        this.detailViewController.client = client;
        this.splitViewController.showDetailViewController(this.detailViewController);        
    }
}
