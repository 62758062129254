


class ClientDocumentCell extends MUITableViewCell
{
    delegate = null;

    private dateLabel:MUILabel = null;
    private documentIDLabel:MUILabel = null;
    private paxLabel:MUILabel = null;
    private locationCategoryLabel:MUILabel = null;
    private priceLabel:MUILabel = null;
    private printButton:MUIButton = null;
    //private nextDocumentIDLabel:MUILabel = null;

    awakeFromHTML(){        
        this.dateLabel = MUIOutlet(this, 'date-lbl','MUILabel');        
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.paxLabel = MUIOutlet(this, "pax-lbl", "MUILabel");
        this.priceLabel = MUIOutlet(this, 'amount-lbl','MUILabel');
        this.locationCategoryLabel = MUIOutlet(this, "location-category-lbl", "MUILabel");
        
        this.printButton = MUIOutlet(this, 'print-btn','MUIButton');
        this.printButton.setAction(this, function(){
            AppHelper.sharedInstance().printTicket(this._item);
        });

        //this.nextDocumentIDLabel = MUIOutlet(this, "next-document-id-lbl", "MUILabel");

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private _item:ArchivedDocument = null;
    set item(i:ArchivedDocument){
        this._item = i;
        
        let cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
        let dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter as MIODateFormatter;

        this.dateLabel.text = dtf.stringFromDate(i.date);
        this.documentIDLabel.text = i.legalDocumentID;        
        this.paxLabel.text = i.numberOfPeople;
        this.locationCategoryLabel.text = i.locationCategoryName;
        this.priceLabel.text = cf.stringFromNumber(i.totalPrice);
        //this.nextDocumentIDLabel.text = i.nextDocument != null ? i.nextDocument.legalDocumentID : null;
    }

}