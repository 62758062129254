
class SelectTicketCell extends MUITableViewCell
{
    delegate = null;

    private dateLabel:MUILabel = null;
    private documentIDLabel:MUILabel = null;
    private clientNameLabel:MUILabel = null;
    private totalLabel:MUILabel = null;
    private addButton:MUIButton = null;

    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.clientNameLabel = MUIOutlet(this, "client-name-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        if (this.addButton != null) this.addButton.setAction(this, this.addAction);

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private _item:ArchivedDocument = null;
    set item (item:ArchivedDocument){
        this._item = item;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        if (this.dateLabel != null) this.dateLabel.text = ad.dateTimeFormatter.stringFromDate(item.date);
        this.documentIDLabel.text = item.legalDocumentID;
        if (this.clientNameLabel != null) this.clientNameLabel.text = item.ticketLegalEntityName;
        if (this.totalLabel != null) this.totalLabel.text = ad.currencyFormatter.stringFromNumber(item.totalPrice);
    }
    
    private addAction(){
        if (this.delegate != null) this.delegate.addTicketFromCell(this, this._item);        
    }

    set selectedTicket(value:boolean){
        this.addButton.hidden = value;
        if (value == true){
            if (this.dateLabel != null) MUICoreLayerAddStyle(this.dateLabel.layer, "light");
            if (this.documentIDLabel != null) MUICoreLayerAddStyle(this.documentIDLabel.layer, "light");
            if (this.clientNameLabel != null) MUICoreLayerAddStyle(this.clientNameLabel.layer, "light");
            if (this.totalLabel != null) MUICoreLayerAddStyle(this.totalLabel.layer, "light");
        }
        else {
            if (this.dateLabel != null) MUICoreLayerRemoveStyle(this.dateLabel.layer, "light");
            if (this.documentIDLabel != null) MUICoreLayerRemoveStyle(this.documentIDLabel.layer, "light");
            if (this.clientNameLabel != null) MUICoreLayerRemoveStyle(this.clientNameLabel.layer, "light");
            if (this.totalLabel != null) MUICoreLayerRemoveStyle(this.totalLabel.layer, "light");            
        }        

    }
}