

// Generated class CashDeskSummaryItem_ManagedObject

class CashDeskSummaryItem_ManagedObject extends MIOManagedObject
{

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }

    // Property: section
    set section(value:string) {
        this.setValueForKey(value, 'section');
    }
    get section():string {
        return this.valueForKey('section');
    }
    set sectionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'section');
    }
    get sectionPrimitiveValue():string {
        return this.primitiveValueForKey('section');
    }

    // Property: isTotal
    set isTotal(value:boolean) {
        this.setValueForKey(value, 'isTotal');
    }
    get isTotal():boolean {
        return this.valueForKey('isTotal');
    }
    set isTotalPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isTotal');
    }
    get isTotalPrimitiveValue():boolean {
        return this.primitiveValueForKey('isTotal');
    }

    // Property: isSubtotal
    set isSubtotal(value:boolean) {
        this.setValueForKey(value, 'isSubtotal');
    }
    get isSubtotal():boolean {
        return this.valueForKey('isSubtotal');
    }
    set isSubtotalPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isSubtotal');
    }
    get isSubtotalPrimitiveValue():boolean {
        return this.primitiveValueForKey('isSubtotal');
    }

    // Property: isHeader
    set isHeader(value:boolean) {
        this.setValueForKey(value, 'isHeader');
    }
    get isHeader():boolean {
        return this.valueForKey('isHeader');
    }
    set isHeaderPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isHeader');
    }
    get isHeaderPrimitiveValue():boolean {
        return this.primitiveValueForKey('isHeader');
    }

    // Property: isCurrency
    set isCurrency(value:boolean) {
        this.setValueForKey(value, 'isCurrency');
    }
    get isCurrency():boolean {
        return this.valueForKey('isCurrency');
    }
    set isCurrencyPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isCurrency');
    }
    get isCurrencyPrimitiveValue():boolean {
        return this.primitiveValueForKey('isCurrency');
    }

    // Property: value
    set value(value:number) {
        this.setValueForKey(value, 'value');
    }
    get value():number {
        return this.valueForKey('value');
    }
    set valuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'value');
    }
    get valuePrimitiveValue():number {
        return this.primitiveValueForKey('value');
    }

    // Property: baseValue
    set baseValue(value:number) {
        this.setValueForKey(value, 'baseValue');
    }
    get baseValue():number {
        return this.valueForKey('baseValue');
    }
    set baseValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'baseValue');
    }
    get baseValuePrimitiveValue():number {
        return this.primitiveValueForKey('baseValue');
    }

    // Property: taxValue
    set taxValue(value:number) {
        this.setValueForKey(value, 'taxValue');
    }
    get taxValue():number {
        return this.valueForKey('taxValue');
    }
    set taxValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxValue');
    }
    get taxValuePrimitiveValue():number {
        return this.primitiveValueForKey('taxValue');
    }

    // Property: orderindex
    set orderindex(value:number) {
        this.setValueForKey(value, 'orderindex');
    }
    get orderindex():number {
        return this.valueForKey('orderindex');
    }
    set orderindexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderindex');
    }
    get orderindexPrimitiveValue():number {
        return this.primitiveValueForKey('orderindex');
    }

    // Property: cashDeskSession
    set cashDeskSession(value:string) {
        this.setValueForKey(value, 'cashDeskSession');
    }
    get cashDeskSession():string {
        return this.valueForKey('cashDeskSession');
    }
    set cashDeskSessionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'cashDeskSession');
    }
    get cashDeskSessionPrimitiveValue():string {
        return this.primitiveValueForKey('cashDeskSession');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
