

// Generated class AccountBalance_ManagedObject

class AccountBalance_ManagedObject extends MIOManagedObject
{

    // Property: amount
    set amount(value:number) {
        this.setValueForKey(value, 'amount');
    }
    get amount():number {
        return this.valueForKey('amount');
    }
    set amountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'amount');
    }
    get amountPrimitiveValue():number {
        return this.primitiveValueForKey('amount');
    }

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }
}
