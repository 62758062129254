
class CashDeskSessionTicketLinesDataSource extends MIOObject
{
    private tableView:MUITableView = null;
    initWithTableView(tableView:MUITableView){
        super.init();
        this.tableView = tableView;   
    }

    private archivedDocument:ArchivedDocument = null;
    set item(item:ArchivedDocument){
        this.archivedDocument = item;
        this.fetchedResultsController = null;
        this.mapLines(this.fetchedResultsController.fetchedObjects);
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return 1;
        //return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();
        return this.lines.count;
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell:TicketDetailCell = tableview.dequeueReusableCellWithIdentifier('LineCell');    
        //let item:ArchivedTicketLinesGroup = this.fetchedResultsController.objectAtIndexPath(indexPath);    
        let item = this.lines[indexPath.row];
        cell.item = item;
        
        return cell;
    }
    
    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
        
        let ad = MUIWebApplication.sharedInstance().delegate;
            
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderindex', true)];    
        let predicateFormat = 'document.identifier == ' + this.archivedDocument.identifier;    
        
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ArchivedTicketLinesGroup", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["lines"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.mapLines(controller.fetchedObjects);
        this.tableView.reloadData();
    }

    private lines = [];
    private mapLines(linesGroup){

        this.lines = [];
        for (let index = 0; index < linesGroup.length; index++){
            let grp = linesGroup[index];
            if (grp.menuName == null || (grp.menuName != null && grp.menuName == grp.productName)) {
                let line = this.createLineFromGroup(grp);
                if (line != null) this.lines.addObject(line);    
            }
        }
    }

    private createLineFromGroup(group:ArchivedTicketLinesGroup){
        let line = {};
        line["Title"] = group.productName;        
        line["UnitPrice"] = group.productPrice;        
        line["TaxName"] = group.taxName;
        
        let quantity = 0;
        let invited = 0;
        let discount = 0;
        let total = 0;
        let linesCount = 0;

        for (let index = 0; index < group.lines.count; index++){
            let l = group.lines.objectAtIndex(index) as ArchivedTicketLine;

            if (this.archivedDocument.modifiedType == 1 && l.modifiedType == 1 && l.nullifyBy != null) continue;
            
            linesCount++;
            quantity++;
            discount += l.totalDiscount;
            invited += l.totalInvited;
            total += l.totalPrice;
        }        

        if (group.bulkQuantity != null) {
            line["Quantity"] = group.bulkQuantity;        
        }
        else {
            line["Quantity"] = quantity;        
        }

        line["Total"] = total;
        
        return linesCount > 0 ? line : null;
    }
}