
class SupplierOrderLineCell extends NoteLineCell
{
    private inputFormatDropdown:MUIButton = null;
    private inportFormatLabel:MUILabel = null;
    private quantityTextField:MUITextField = null;
    private quantityLabel:MUILabel = null;
    private productQuantityLabel:MUILabel = null;            
    private productPriceLabel:MUILabel = null;    
    private discountLabel:MUILabel = null;   
    private discountTextField:MUITextField = null; 
    private totalLabel:MUILabel = null;    
    
    awakeFromHTML(){
        super.awakeFromHTML();
    
        this.inputFormatDropdown = MUIOutlet(this, "input-format-dd", "MUIButton");
        this.setupInputFormatDropdown(this.inputFormatDropdown);
        this.inportFormatLabel = MUIOutlet(this, "input-format-lbl", "MUILabel");
    
        this.quantityTextField = MUIOutlet(this, 'quantity-tf', 'MUITextField');   
        this.setupQuantityTextField(this.quantityTextField);        
        this.quantityLabel = MUIOutlet(this, "quantity-lbl", "MUILabel");
        
        this.productQuantityLabel = MUIOutlet(this, "product-quantity-lbl", "MUILabel");        
        this.productPriceLabel = MUIOutlet(this, "product-price-lbl", "MUILabel");

        this.discountTextField = MUIOutlet(this, "discount-tf", "MUITextField");
        this.setupDiscountTextField(this.discountTextField);
        this.discountLabel = MUIOutlet(this, "discount-lbl", "MUILabel");

        this.totalLabel = MUIOutlet(this, "total-price-lbl", "MUILabel");

        this.reset();
        
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    setLine(item:StockNoteLine){
        super.setLine(item);        
        
        this.productLabel.text = this.productNameString();
                
        if (this.inportFormatLabel != null) this.inportFormatLabel.text = this.inputFormatString();
        if (this.inputFormatDropdown != null) {
            this.inputFormatDropdown.title = this.inputFormatString();
            this.inputFormatDropdown.enabled = true;
        }
        
        let quantityString = this.nf.stringFromNumber(item.quantity);
        if (this.quantityLabel != null) this.quantityLabel.text = quantityString;
        if (this.quantityTextField != null) {
            this.quantityTextField.text = quantityString;
            this.quantityTextField.enabled = true;
        }       
                
        this.productQuantityLabel.text = this.productQuantityString();
        this.productPriceLabel.text = this.productPriceString();

        if (this.discountLabel != null) this.discountLabel.text = this.discount;
        if (this.discountTextField != null) {
            this.discountTextField.text = this.discount;
            this.discountTextField.enabled = true;
        }

        this.totalLabel.text = this.totalPriceString();
    }
        
    productDidSelect(controller:SelectEntityViewController, product:Product, supplierProduct?:SupplierProduct){
        this.setProduct(product);
        
        if (supplierProduct != null) {
            this.productPrice = DBHelper.calculateCostFromSupplierProduct(product, supplierProduct);            
        }
        else {
            let supplier = null;
            if (this.delegate != null) supplier = this.delegate.supplierFromNote(this);
            [this.productPrice, this.discount] = DBHelper.costFromProductAndSupplier(product, supplier);            
        }

        this.price = this.productPrice;
        
        this.productTextField.text = this.productNameString();
                
        this.inputFormatDropdown.enabled = true;
        this.inputFormatDropdown.title = product.defaultInputFormatString();        

        this.quantityTextField.enabled = true;
        this.quantityTextField.becomeFirstResponder();
                
        this.productQuantityLabel.text = this.productQuantityString();
        this.productPriceLabel.text = this.productPriceString(); 

        this.discountTextField.enabled = true;        
        this.discountTextField.text = this.discount;   

        this.totalLabel.text = this.totalPriceString();

        this.updateStockLine();
    }

    protected inputFormatDidSelect(controller:SelectInputFormatViewController, inputFormat:StockInputFormat, inputType:MeasureUnitType){
        super.inputFormatDidSelect(controller, inputFormat, inputType);
        this.inputFormatDropdown.title = this.inputFormatString();
        this.productPriceLabel.text = this.productPriceString(); 
        this.totalLabel.text = this.totalPriceString();
    }
    
    protected quantityDidChange(quantity){
        super.quantityDidChange(quantity);        
        this.productQuantityLabel.text = this.productQuantityString();
        this.totalLabel.text = this.totalPriceString();
    }

    enterDidPress(){
        if (this.stockNoteLine != null) return;        
        if (this.quantity == 0 || this.quantity == null) return;
                
        let line = DBHelper.createSupplierOrderLine(this.product, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.measureQuantity, this.price, this.productPrice, this.totalPrice, this.stockNote);
        if (line != null) {
            line.discountString = this.discount;
            line.discountValue = this.discountValue;
            this.lineDidInserted(line);
            this.reset();
        }
    }
    
    reset(){
        this.product = null;        
        this.inputFormat = null;
        this.quantity = 1;
        this.productQuantity = 0;
        this.price = 0;
        this.productPrice = 0;
        
        if (this.productTextField != null) this.productTextField.text = null;

        if (this.inputFormatDropdown != null) {
            this.inputFormatDropdown.title = null;
            this.inputFormatDropdown.enabled = false;            
        }
        
        if (this.quantityTextField != null) {
            this.quantityTextField.text = null;
            this.quantityTextField.enabled = false;
        }

        this.productQuantityLabel.text = null;
        this.productPriceLabel.text = null;

        if (this.discountLabel != null) this.discountLabel.text = null;
        if (this.discountTextField != null) {
            this.discountTextField.text = null;
            this.discountTextField.enabled = false;
        }

        this.totalLabel.text = null;
    }    
}