class NoteLineCell extends UITableViewCell
{
    delegate = null;

    protected productTextField:MUITextField = null;
    protected productLabel:MUILabel = null;
           
    protected nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    protected cf = MUIWebApplication.sharedInstance().delegate.longCurrencyFormatter;
    protected pnf = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter;
    
    protected stockNote:StockNote = null;
    protected stockNoteLine:StockNoteLine = null;    
    protected product:Product = null;
    protected productName:string = null;
    protected productMeasureType:MeasureUnitType = null;
    protected productContainerMeasureType:MeasureUnitType = null;
    protected productContainerQuantity = null;
    protected tax:Tax = null;
    protected inputType:MeasureUnitType = MeasureUnitType.None;
    protected inputFormat:StockInputFormat = null;
    protected quantity = null;
    protected productQuantity = null;
    protected measureQuantity = null;
    protected price = 0;
    protected productPrice = 0;
    protected minPrice = 0;
    protected discount = null;
    protected discountValue = null;
    protected totalPrice = null;
    protected warehouseID:string = null;
    protected warehouseName:string = null;
    
    awakeFromHTML(){
        this.productTextField = MUIOutlet(this, 'product-tf', 'MUITextField');
        this.setupProductTextField(this.productTextField, this, this.productDidSelect);
        this.productLabel = MUIOutlet(this, "product-lbl", "MUILabel"); 
    }

    protected productDidSelect(controller:SelectEntityViewController, product:Product, supplierProduct?:SupplierProduct){ 

    }

    set line(line:StockNoteLine){
        this.setLine(line);
    }
    
    setLine(line:StockNoteLine){
        this.stockNoteLine = line;        
        this.product = line.product;
        this.productName = line.productName;
        this.productMeasureType = line.productMeasureType;
        this.productContainerMeasureType = line.productContainerMeasureType;
        this.productContainerQuantity = line.productContainerQuantity;
        this.tax = line.tax;
        this.inputFormat = line.inputFormat;
        this.inputType = line.measureType;
        this.quantity = line.quantity;
        this.productQuantity = line.productQuantity;
        this.measureQuantity = line.measureQuantity;
        this.price = line.price;
        this.productPrice = line.productPrice;
        this.minPrice = line.measurePrice;
        this.discount = line.discountString;
        this.discountValue = line.discountValue || 0;
        this.totalPrice = line.total;
        this.warehouseID = line.warehouseID;
        this.warehouseName = line.warehouseName;    

        if (this.price == null) this.price = line.estimatedPrice;
        if (this.productPrice == null) this.productPrice = line.estimatedProductPrice;
    }

    set note(note:StockNote){
        this.stockNote = note;
    }

    setProduct(product:Product){
        this.product = product;
        this.productName = product.stockName ? product.stockName : product.name;
        this.productMeasureType = product.isContainer ? MeasureUnitType.Container : product.measureUnitType;
        this.productContainerMeasureType = product.isContainer ? product.measureUnitType : null;
        this.productContainerQuantity = product.isContainer ? product.quantity : null;
        this.inputType = product.measureType;
        this.inputFormat = null;                
    }

    private searchTimer:MIOTimer = null;
    private searchString:string = null;
    protected setupProductTextField(textField:MUITextField, target, completion){
        if (textField == null) return;        
        textField.setOnChangeText(this, function(control, value:string){
            this.searchString = value;
            if (this.searchTimer != null) this.searchTimer.invalidate();
            this.searchTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer:MIOTimer){
                this.searchProductTimerFired(textField, this.searchString, target, completion);
                this.searchTimer = null;
            });
        });
    }

    private searchProductTimerFired(textField:MUITextField, value:string, target, completion){
        let supplier:Supplier = null;
        if (this.stockNote.type == StockNoteType.SupplierOrder && (this.stockNote.destinationEntity instanceof Supplier) == true) {
            supplier = this.stockNote.destinationEntity;
        }
        else if (this.stockNote.type == StockNoteType.SupplierNote && (this.stockNote.originEntity instanceof Supplier) == true) {
            supplier = this.stockNote.originEntity;                
        }

        if (supplier != null && supplier.onlySupplierProducts == true){
            let context = {"Supplier": supplier, "Target": target, "Completion": completion};
            AppHelper.sharedInstance().showSelectSupplierProductViewControllerFromView(textField, value, this, this.supplierProductDidSelected, this, this.addProduct, context);
        }
        else {
            AppHelper.sharedInstance().showSelectStockProductViewControllerFromView(textField, value, target, completion, this, this.addProduct);
        }
        
    }

    private supplierProductDidSelected(controller:SelectEntityViewController, supplierProduct:SupplierProduct){
        let context = controller.context || {};
        let target = context["Target"];
        let completion = context["Completion"];
        if (target == null || completion == null) return;
        completion.call(target, controller, supplierProduct.product, supplierProduct);
    }

    private addProduct(value:string){
        AppHelper.sharedInstance().showAddStockProductAlertViewController(value, null, this, function(product:Product){
            if (product != null) this.productDidSelect(null, product);
        });
    }
    
    protected setupTaxDropdown(button:MUIButton, target, completion){
        if (button == null) return;
        button.setAction(this, function(){
            AppHelper.sharedInstance().showSelectTaxViewControllerFromView(button, this.product, this.tax, target, completion);
        });   
    }

    protected taxDidSelect(controller:SelectEntityViewController, tax:Tax){      
        this.taxWillChange(this.tax);

        this.tax = tax;        
        this.updateStockLine();

        this.taxDidChange(this.tax);
    }

    protected setupWarehouseDropdown(button:MUIButton, target, completion){
        if (button == null) return;
        button.setAction(this, function(){
            AppHelper.sharedInstance().showSelectWarehouseViewControllerFromView(button, this.product, true, false, target, completion);
        });   
    }
    
    protected setupInputFormatDropdown(button:MUIButton){
        if (button == null) return;
        button.setAction(this, function(){
            AppHelper.sharedInstance().showSelectInputFormatViewControllerFromView(button, this.product, this.productMeasureType, this.productContainerMeasureType, this.productContainerQuantity, this, this.inputFormatDidSelect);
        });
    }

    protected inputFormatDidSelect(controller:SelectInputFormatViewController, inputFormat:StockInputFormat, inputType:MeasureUnitType){
        this.totalWillChange(this.totalPrice);

        this.inputFormat = inputFormat;
        this.inputType = inputType;        

        if (inputFormat != null) {
            this.price = this.productPrice * inputFormat.quantity;
        }
        else {
            this.price = this.productPrice;
        }

        let factor = 1;
        [this.productQuantity, this.measureQuantity, factor] = DBHelper.calculateQuantityFromStockLine(this.quantity, this.inputFormat, this.inputType, this.productMeasureType, this.productContainerMeasureType, this.productContainerQuantity);        
        if (factor != 1) this.price = this.productPrice * factor;
        [this.totalPrice, this.discountValue] = DBHelper.calculateTotalFromStockLine(this.quantity, this.price, this.discount);

        this.updateStockLine();

        this.totalDidChange(this.totalPrice);
    }

    protected setupQuantityTextField(textField:MUITextField){
        if (textField == null) return;

        textField.setOnChangeText(this, function(textfield, value){
            let q = this.nf.numberFromString(value);
            this.quantityDidChange(q);
        });  

        textField.setOnBeginEditing(this, function(control:MUITextField, value:string){
            control.selectAll(null);
        });
        
        textField.setOnEnterPress(this, function(control:MUITextField){
            control.resignFirstResponder();
            this.enterDidPress()
        });
    }

    protected quantityDidChange(quantity){
        this.totalWillChange(this.totalPrice);

        this.quantity = quantity;                
        [this.productQuantity, this.measureQuantity] = DBHelper.calculateQuantityFromStockLine(this.quantity, this.inputFormat, this.inputType, this.productMeasureType, this.productContainerMeasureType, this.productContainerQuantity);                
        [this.totalPrice, this.discountValue] = DBHelper.calculateTotalFromStockLine(this.quantity, this.price, this.discount);
        this.updateStockLine();

        this.totalDidChange(this.totalPrice);
    }

    protected setupPriceTextField(textField:MUITextField){
        if (textField == null) return;

        textField.setOnChangeText(this, function(textfield, value){
            let p = this.cf.numberFromString(value);
            this.priceDidChange(p);
        });

        textField.setOnBeginEditing(this, function(control:MUITextField, value:string){
            control.selectAll(null);
        });

        textField.setOnEnterPress(this, function(control:MUITextField){
            control.resignFirstResponder();
            this.enterDidPress()
        });
    }

    protected priceDidChange(price){
        this.totalWillChange(this.totalPrice);
        
        this.price = price;
        let factor = 1;
        [this.productQuantity, this.measureQuantity, factor] = DBHelper.calculateQuantityFromStockLine(this.quantity, this.inputFormat, this.inputType, this.productMeasureType, this.productContainerMeasureType, this.productContainerQuantity);                
        this.productPrice = price * factor;
        [this.totalPrice, this.discountValue] = DBHelper.calculateTotalFromStockLine(this.quantity, this.price, this.discount);
        this.updateStockLine();

        this.totalDidChange(this.totalPrice);
    }

    protected setupDiscountTextField(textField:MUITextField){
        if (textField == null) return;

        textField.formatter = this.pnf;
        textField.setOnChangeText(this, function(textfield, value){  
            
            let discount = value.length > 0 ? value : null;
            this.discountDidChange(discount);
        });

        textField.setOnBeginEditing(this, function(control:MUITextField, value:string){
            control.selectAll(null);
        });

        textField.setOnEnterPress(this, function(control:MUITextField){
            control.resignFirstResponder();
            this.enterDidPress()
        });
    }

    protected discountDidChange(discount){
        this.totalWillChange(this.totalPrice);
        
        this.discount = discount;        
        [this.productQuantity, this.measureQuantity] = DBHelper.calculateQuantityFromStockLine(this.quantity, this.inputFormat, this.inputType, this.productMeasureType, this.productContainerMeasureType, this.productContainerQuantity);        
        [this.totalPrice, this.discountValue] = DBHelper.calculateTotalFromStockLine(this.quantity, this.price, this.discount);
        this.updateStockLine();

        this.totalDidChange(this.totalPrice);
    }

    protected enterDidPress(){
    }

    protected lineDidInserted(line:StockNoteLine){
        if (this.delegate != null && typeof this.delegate.lineDidInserted === "function"){
            this.delegate.lineDidInserted(this, line);
        }
    }

    protected   productNameString(){        
        let name = null;
        if (this.productMeasureType == MeasureUnitType.Container) {
            name = this.productName + " (" + this.nf.stringFromNumber(this.productContainerQuantity) +  " " + MeasureUnits.toString(this.productContainerMeasureType) + ")";
        }
        else {
            name = this.productName + " (" + MeasureUnits.toString(this.productMeasureType) + ")";
        }

        return name;        
    }

    protected inputFormatString(){
        if (this.inputFormat != null) {
            return this.inputFormat.name;
        } else {
            if (this.inputType == MeasureUnitType.Container) {
            let name = MeasureUnits.toString(this.inputType);
            return name + " (" + this.productContainerQuantity + MeasureUnits.toString(this.productContainerMeasureType) + ")";
            }
            else {
                return MeasureUnits.toString(this.inputType);            
            }
        }                
    }   

    protected productQuantityString(){
        if (this.productMeasureType == MeasureUnitType.Container){
            return "(" + this.nf.stringFromNumber(this.measureQuantity) + " " + MeasureUnits.toString(this.productContainerMeasureType) + ") <strong>" + this.nf.stringFromNumber(this.productQuantity) + "</strong>";
        }
        else {
            return "<strong>" + this.nf.stringFromNumber(this.productQuantity) + "</strong>";
        }
    }

    protected productPriceString(){
        return this.cf.stringFromNumber(this.productPrice);
    }

    protected totalPriceString(){        
        return this.cf.stringFromNumber(this.totalPrice);
    }

    //
    // Lines Updates
    //

    updateStockLine(){
        if (this.stockNoteLine == null) return;
        
        this.stockNoteLine.tax = this.tax;
        this.stockNoteLine.taxName = this.tax ? this.tax.name : null;
        this.stockNoteLine.taxQuantity = this.tax ? this.tax.taxQuantity : null;
        this.stockNoteLine.inputFormat = this.inputFormat;
        this.stockNoteLine.measureType = this.inputType != null ? this.inputType : this.productMeasureType;
        this.stockNoteLine.quantity = this.quantity;
        this.stockNoteLine.productQuantity = this.productQuantity;
        this.stockNoteLine.measureQuantity = this.measureQuantity;
        this.stockNoteLine.price = this.price;
        this.stockNoteLine.productPrice = this.productPrice;
        this.stockNoteLine.measurePrice = this.minPrice;
        this.stockNoteLine.discountString = this.discount;
        this.stockNoteLine.discountValue = this.discountValue;
        this.stockNoteLine.total = this.totalPrice;
        this.stockNoteLine.warehouseID = this.warehouseID;
        this.stockNoteLine.warehouseName = this.warehouseName;
    }

    //
    // Delegate 
    //

    private totalWillChange(total:number){
        if (this.delegate == null || typeof this.delegate.baseAmountWillChange !== "function") return;
        this.delegate.baseAmountWillChange(total);
    }

    private totalDidChange(total:number){
        if (this.delegate == null || typeof this.delegate.baseAmountDidChange !== "function") return;
        this.delegate.baseAmountDidChange(total);
    }

    private taxWillChange(tax:Tax){
        if (this.delegate == null || typeof this.delegate.taxWillChange !== "function") return;
        this.delegate.taxWillChange(tax);
    }

    private taxDidChange(tax:Tax){
        if (this.delegate == null || typeof this.delegate.taxDidChange !== "function") return;
        this.delegate.taxDidChange(tax);
    }

    //
    // FIRST RESPONDER
    //
    becomeFirstResponder() {
        if(this.productTextField != null) this.productTextField.becomeFirstResponder();
    }
}
