
class RoomBookingListViewController extends BaseTableViewController
{
    private importButton:MUIButton = null;


    viewDidLoad(){
        super.viewDidLoad();

        this.importButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.importButton.setAction(this, this.importRoomBooking);

        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    private importRoomBooking(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        ad.webService.importRoomBooking("206", null, this, function(code, data){
            MIOLog(data);
        });
    }

}