class DBMappingViewController extends BaseViewController
{
    private mappingComboBox:MUIComboBox = null;
    private addButton:MUIButton = null;

    private tableView:UITableView = null;
    private nameFilterTextField:ColumnFilterTextField = null;
    private foreignNameFilterTextField:ColumnFilterTextField = null;
    private foreignIDFilterTextField:ColumnFilterTextField = null;
    
    private matchesSearchBar:MUITextField = null;
    private matchesTableView:MUITableView = null;

    private matchesDataSource:DBMappingMatchesDataSource = null;
    private matchesSearchString:string = null;
    private matchesSearchTimer:MIOTimer = null;

    private columnFilterController:ColumnFilterController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.columnFilterController = new ColumnFilterController();
        this.columnFilterController.initWithDelegate(this);

        this.nameFilterTextField = MUIOutlet(this, "ownname-filter-tf", "ColumnFilterTextField");
        this.nameFilterTextField.filterController = this.columnFilterController;
        this.nameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "ownName", null, null);

        this.foreignNameFilterTextField = MUIOutlet(this, "foreignname-filter-tf", "ColumnFilterTextField");
        this.foreignNameFilterTextField.filterController = this.columnFilterController;
        this.foreignNameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "foreignName", null, null);

        this.foreignIDFilterTextField = MUIOutlet(this, "foreignid-filter-tf", "ColumnFilterTextField");
        this.foreignIDFilterTextField.filterController = this.columnFilterController;
        this.foreignIDFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "foreignID", null, null);


        this.mappingComboBox = MUIOutlet(this, "mapping-cb", "MUIComboBox");                
        this.mappingComboBox.setOnChangeAction(this, function(control, value){
            this.changeMappings(value);
        });
        this.updateMappingComboBox(this.configFetchedResultsController.fetchedObjects);

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.addMappingAction);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        // this.searchBar = MUIOutlet(this, "search-bar", "MUITextField");
        // this.searchBar.setOnChangeText(this, function(control, value){
        //     this.searchByName(value);
        // });
        
        this.matchesSearchBar = MUIOutlet(this, "matches-search-bar", "MUITextField");
        this.matchesSearchBar.setOnChangeText(this, function(control, value){
            this.matchesSearchString = value.length > 0 ? value : null;
            if (this.matchesSearchTimer != null) this.matchesSearchTimer.invalidate();
            this.matchesSearchTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer:MIOTimer){
                this.matchesSearchTimer = null;
                this.matchesDataSource.searchByName(this.matchesSearchString);
            });
        });

        this.matchesTableView = MUIOutlet(this, "matches-table-view", "MUITableView");
        this.matchesDataSource = new DBMappingMatchesDataSource();
        this.matchesDataSource.initWithTableView(this.matchesTableView);
        this.matchesTableView.dataSource = this.matchesDataSource;
        this.matchesTableView.delegate = this.matchesDataSource;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);

        MIONotificationCenter.defaultCenter().addObserver(this, "DBMappingItemDidChange", function(){
            this.selectedItemDidMapped();
        });
    }

    viewWillDisappear(animated?){
        super.viewWillDisappear(animated);

        MIONotificationCenter.defaultCenter().removeObserver(this, "DBMappingItemDidChange");
    }

    numberOfSections(tableview){
       return 2;
    }

    numberOfRowsInSection(tableView:MUITableView, section) {
        //if (this.items.length == 0) return 0;

        if (section == 0) return this.items.count;
        else if (section == 1) return 1;
        
        return 0;
    }

    cellAtIndexPath(tableView:MUITableView, indexPath: MIOIndexPath) {
        let cell = null;

        if (indexPath.section == 1) {
            // More cell
            cell = tableView.dequeueReusableCellWithIdentifier("MoreCell") as DBMappingMoreCell;            
        }
        else {

            cell = tableView.dequeueReusableCellWithIdentifier("MappingCell") as DBMappingCell;
            //let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
            let item = this.items[indexPath.row] as DBMappingEntity;
            cell.item = item;
            console.log(item.ownEntityName + ";" + item.ownName + ";;;");
        }

        return cell;
    }
    
    private selectedItem = null;
    private selectedIndexPath:MIOIndexPath = null;
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){

        this.selectedIndexPath = null;

        if (indexPath.section == 0) {
            //let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Product;
            let item = this.items[indexPath.row] as DBMappingEntity;
            this.selectedItem = item;
            this.matchesDataSource.item = item;
            this.selectedIndexPath = indexPath;
        }
        else if (indexPath.section == 1){
            // Add more cells
            this.nextRequest();
        }
    }

    private _configFetchedResultsController:MIOFetchedResultsController = null;
    get configFetchedResultsController(){
        if (this._configFetchedResultsController != null) return this._configFetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("title", true)];

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("IntegratorsConfig", sortDescriptors, null);
        fetchRequest.predicate = MIOPredicate.predicateWithFormat("type == 1");

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._configFetchedResultsController = fetchedResultsController;   
        return this._configFetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.updateMappingComboBox(controller.fetchedObjects);
    }

    private updateMappingComboBox(objects){
        this.mappingComboBox.removeAllItems();
        this.mappingComboBox.addItem("None", -1);        
        
        for (let index = 0; index < objects.length; index++){
            let cfg = objects[index] as IntegratorsConfig;
            this.mappingComboBox.addItem(cfg.title, index);
        }
    }

    // private updateMappingComboBox(){
    //     this.mappingComboBox.removeAllItems();
    //     this.mappingComboBox.addItem("Select mapping", -1);

    //     // TODO: Get from server
    //     this.mappingComboBox.addItem("SAP: Products - Materials (DEV)", 1);
    //     this.mappingComboBox.addItem("SAP: Products - Materials (PRO)", 2);
    //     this.mappingComboBox.addItem("SAP: Warehouses - CECOS (PRO)", 3);
    //     this.mappingComboBox.addItem("SAP: Places - Plants (PRO)", 4);
    //     this.mappingComboBox.addItem("CSI: Workers - CSI", 5);
    // }

    private selectedIntegrator:string = null;
    private selectedEnviroment:string = null;
    private selectedEntity:string = null;
    private selectedMappingEntity:string = null;    

    private changeMappings(index){
        let row = parseInt(index);

        let ip = MIOIndexPath.indexForRowInSection(row, 0);
        let cfg = this.configFetchedResultsController.objectAtIndexPath(ip) as IntegratorsConfig;

        this.selectedEnviroment = cfg.enviroment;
        this.selectedIntegrator = cfg.integrator;
        this.selectedEntity = "DBMappingEntity" + cfg.ownEntityName;
        this.selectedMappingEntity = cfg.mappingEntityName;

        // switch(index){
        //     case "1":
        //     this.selectedIntegrator = "SAP";
        //     this.selectedEnviroment = "DEV";
        //     this.selectedEntity = "DBMappingEntityProduct";
        //     this.selectedMappingEntity = "MATERIALS";
        //     break;

        //     case "2":
        //     this.selectedIntegrator = "SAP";
        //     this.selectedEnviroment = "PRO";
        //     this.selectedEntity = "DBMappingEntityProduct";
        //     this.selectedMappingEntity = "MATERIALS";
        //     break;

        //     case "3":
        //     this.selectedIntegrator = "SAP";
        //     this.selectedEnviroment = "PRO";
        //     this.selectedEntity = "DBMappingWarehouse";
        //     this.selectedMappingEntity = "WAREHOUSES";
        //     break;

        //     case "4":
        //     this.selectedIntegrator = "SAP";
        //     this.selectedEnviroment = "PRO";
        //     this.selectedEntity = "DBMapping";
        //     this.selectedMappingEntity = "PLANTS";            
        //     break;
        // }

        this.matchesDataSource.integrator = this.selectedIntegrator;
        this.matchesDataSource.enviroment = this.selectedEnviroment;
        this.matchesDataSource.entity = this.selectedEntity;
        this.matchesDataSource.mappingEntity = this.selectedMappingEntity;

        this.invalidateFetch();
    }

    private timer:MIOTimer = null;
    private searchString:string = null;
    private searchByName(name:string){
        this.searchString = name.length > 0 ? name : null;
        if (this.timer != null) this.timer.invalidate();
        this.timer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer){
            this.timer = null;
            this.resetRequest();
            this.nextRequest();
        });
    }

    private items = [];
    private itemOffset = 0;
    
    private newFetchRequest(identifier?:string){
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            
        let predicateFormat = "(integrator == '" + this.selectedIntegrator + "' OR integrator == null)";
        predicateFormat += " AND (enviroment == '" + this.selectedEnviroment + "' OR enviroment == null)";        
        if (identifier) predicateFormat += " AND identifier == " + identifier;        

        let filterFormat = this.columnFilterController.filterPredicateFormat();
        if (filterFormat != null) {
            predicateFormat += " AND (" + filterFormat + ")";
        }

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName(this.selectedEntity);
        fetchRequest.sortDescriptors = sortDescriptors;
        fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);        
        fetchRequest.fetchLimit = 100;
        fetchRequest.fetchOffset = this.itemOffset;

        return fetchRequest;
    }  
    
    private get fetchRequest(){
        return this.newFetchRequest();
    }

    private resetRequest(){
        this.items = [];
        this.itemOffset = 0;   
        this.needsReloadData();        
    }

    private requesting = false;
    private nextRequest(){     
        if (this.requesting == true) return;
                
        let ip = MIOIndexPath.indexForRowInSection(0, 1);
        let cell = this.tableView.cellAtIndexPath(ip) as DBMappingMoreCell;
        if (cell != null) cell.activityIndicator.startAnimating();
        
        let request = this.fetchRequest;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webPersistentStore.fetchObjects(request, ad.managedObjectContext, this, function(objects){
            this.addItems(objects);
            this.itemOffset += 100;
            this.requesting = false;
            
            cell = this.tableView.cellAtIndexPath(ip) as DBMappingMoreCell;
            if (cell != null) {
                cell.activityIndicator.stopAnimating();
                //this.tableView.deselectCellAtIndexPath(ip);
            }
        });
    }

    private addedMoreCell = false;
    private addItems(objects){
        let indexPaths = [];
        let row = this.items.length;
        for (let index = 0; index < objects.count; index++){
            let ip = MIOIndexPath.indexForRowInSection(index + row, 0);
            indexPaths.addObject(ip);
            let item = objects[index];
            this.items.addObject(item);
        }

        if (this.addedMoreCell == false){
            let moreip = MIOIndexPath.indexForRowInSection(0, 1);
            indexPaths.addObject(moreip);
            this.addedMoreCell = true;
        }

        this.tableView.insertRowsAtIndexPaths(indexPaths, UITableViewRowAnimation.None);
    }

    private selectedItemDidMapped(){
        if (this.selectedItem == null) return;

        let off = this.itemOffset - 100;
        this.resetRequest();        
        this.itemOffset = off;        
        this.nextRequest();
        this.setFetchedResultsController(null);
        this.tableView.reloadData();

        // let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        // ad.managedObjectContext.executeFetch(this.newFetchRequest(this.selectedItem.identifier));

        // let cell = this.tableView.cellAtIndexPath(this.selectedIndexPath) as DBMappingCell;
        // let indexPath = this.tableView.indexPathForCell(cell);
        // let item = this.items[indexPath.row] as DBMappingEntity;
        // cell.item = item;        
    }

    // protected invalidateFetch(){

    // }

    protected invalidateFetch(){
        this.resetRequest();        
        this.nextRequest();
        super.invalidateFetch();
    }

    protected reloadData(){
        this.tableView.reloadData();
    }

    private addMappingAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Add mapping", "Add new mapping relation", MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Name");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("ID");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "Ok"), MUIAlertActionStyle.Default, this, function(){
            let name = avc.textFields[0].text;
            let identifier = avc.textFields[1].text;

            this.addNewMappingEntity(name, identifier);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "Cancel"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private addNewMappingEntity(name:string, identifier:string){
        if (name == null || name.length == 0) return;
        if (identifier == null || identifier.length == 0) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate
        let me = MIOEntityDescription.insertNewObjectForEntityForName("DBMappingEntity", ad.managedObjectContext) as DBMappingEntity;

        me.foreignEntityName = this.selectedMappingEntity;
        me.foreignName = name;
        me.foreignID = identifier;
        me.enviroment = this.selectedEnviroment;
        me.integrator = this.selectedIntegrator;

        DBHelper.saveMainContext();

        this.matchesDataSource.searchByCode(me.foreignID);
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.invalidateFetch();
    }


}