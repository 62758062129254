class SettingsHelper extends MIOObject
{
    private static _sharedInstance: SettingsHelper = null;
    static sharedInstance(): SettingsHelper {

        if (this._sharedInstance == null) {
            this._sharedInstance = new SettingsHelper();
            this._sharedInstance.init();
        }

        return this._sharedInstance;
    }

    init(){
        super.init();

        MIONotificationCenter.defaultCenter().addObserver(this, MWSPersistentStoreDidUpdateEntity, this.entityDidUpdateNotification);
    }

    private _config = null;
    get config(){
        return this._config;
    }

    private _isNewServersLoaded = false;
    get isNewServersLoaded(){
        return this._isNewServersLoaded;
    }

    loadAppSettings(target?, completion?){
        
        this._config = {}

        let configData = MIOBundle.mainBundle().pathForResourceOfType("config", "plist");
        this._config = MIOPropertyListSerialization.propertyListWithData(configData, 0, 0, null);

        if (this._config["UseNewServerConfigFile"] != null && this._config["UseNewServerConfigFile"] == true) {
            this._isNewServersLoaded = true; 
        }
        else {
            let appData = MIOBundle.mainBundle().pathForResourceOfType("app", "plist");
            let items = MIOPropertyListSerialization.propertyListWithData(appData, 0, 0, null);
            for (let key of items) {
                let value = items[key];
                this._config[key] = value;
            }            
        }                

        if (target != null && completion != null) completion.call(target, this._config);        
    }
    
    loadConfig(target, completion) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        DBHelper.queryObjectsWithCompletion("Configuration", null, null, [], this, function(items){
            //this._config = {}
            for (let index = 0; index < items.length; index++){
                let cfg = items[index] as Configuration;
                this._config[cfg.name] = cfg.value;
            }
            
            if (target != null && completion != null) completion.call(target, this._config);
        });
    }

    loadPermissions(target, completion){        

        DBHelper.queryObjectsWithCompletion("UserPermission", null, MIOPredicate.predicateWithFormat("identifier == " + AppHelper.sharedInstance().userID), [], this, function(objects){
            let obj = objects[0] as UserPermission;
            this._permissions = obj.sections;
            
            if (target != null && completion != null) completion.call(target, this._config);
        });
    }

    loadPermissions_deprecated(target, completion){        

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
        ad.webService.userBusinessData(this, function(code, json){
            // transform config array in dictionary
            this._permissions = json["data"]["permissions"];
            let data = json["data"]["configuration"];            
            //this._config = {};
            for (let index = 0; index < data.length; index++){
                let cfg = data[index];
                this._config[cfg.name] = cfg.value;
            }    

            if (target != null && completion != null) completion.call(target, this._config);
        });        
    }

    private _permissions = null;
    get permisisons(){
        return this._permissions;
    }
    
    entityDidUpdateNotification(note:MIONotification){
        let object = note.object as MIOManagedObject;
        let entityName = object.entity.name;

        if (entityName != "Configuration") return;  
        
        let cfg = object as Configuration;
        this._config[cfg.name]  = cfg.value;

        MIONotificationCenter.defaultCenter().postNotification("ConfigurationDidChange", this._config);
    }

    private _templateLanguages = null;
    get templateLanguages(){
        if (this._templateLanguages == null) {
            let langs = this._config["booking-available-language-template"] || [];
            this._templateLanguages = [];

            let languages = DBCacheHelper.sharedInstance().objectsForEntityName("Language", [MIOSortDescriptor.sortDescriptorWithKey("name", true)], null);            
            for (let index = 0; index < languages.length; index++){
                let lang = languages[index] as Language;
                if (langs.indexOf(lang.iso2) != -1) this._templateLanguages.push(lang);
            }

        }

        return this._templateLanguages;
    }

    get currentLanguage(){
        return MIOCoreGetBrowserLanguage();
    }

    private supportedLanguages = ["en", "es", "nl", "ar"];
    get currentSupportedLanguage(){
        let lang = this.currentLanguage;
        if (this.supportedLanguages.indexOf(lang) == -1) return "en";
        return lang;
    }
    
}