/**
 * Created by crisan on 7/12/16.
 */



/// <reference path="../../model/ProductListItem.ts" />


class ProductCell extends UITableViewCell
{
    productType = ProductType.Sales;

    private nameLabel:MUILabel = null;
    private priceLabel:MUILabel = null;    
    private imageView:MUIImageView = null;    

    awakeFromHTML(){
        this.imageView = MUIOutlet(this, "image-view", "MUIImageView");
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.priceLabel = MUIOutlet(this, "price-lbl", "MUILabel");                
    }

    set item(i:ProductListItem){

        // if ( this.imageView && product.image != null){
        //     const url = product.image.url.substr(0, product.image.url.length - 9)  + "0%400.png";
        //     setImage( this.imageView, url ) ;
        // }


        this.imageView.setImage(i.imageURL);        

        let name = this.productType == ProductType.Stock ? i.stockName : i.name;
        if (name == null) name = i.name;

        this.nameLabel.text = name;
        let cf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
        this.priceLabel.text = i.type == ProductListItemType.Product ? cf.stringFromNumber(i.price) : "";                
    }


}