

/// <reference path="../../model/PhoneNumber.ts" />
/// <reference path="../../model/Address.ts" />
/// <reference path="../../model/Employee.ts" />

class WorkerDetailGeneralViewController extends MUIViewController
{        
    private nameTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private documentTextField:MUITextField = null;
    private address1TextField:MUITextField = null;
    //private address2TextField:MUITextField = null;
    private cityTextField:MUITextField = null;
    private stateTextField:MUITextField = null;
    private countryDropdown:MUIButton = null;    
    private postalCodeTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private mobilePhoneTextField:MUITextField = null;
    private passwordTextField:MUITextField = null;
    private pincodeTextField:MUITextField = null;
    private tagsTextField:MUITextField = null;

    private permissionsButton = null;
  //  private saveButton = null;
    
    private moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    

    viewDidLoad(){
        super.viewDidLoad();

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(textfield, value){
            if (this._worker != null) this._worker.name = value;          
        });

        this.documentTextField = MUIOutlet(this, "wdv_c1_ub_document_text", "MUITextField");
        this.documentTextField.setOnChangeText(this, function(textfield, value){
            if (this._worker != null) this._worker.document = value;          
        });

        this.emailTextField = MUIOutlet(this, "wdv_c2_db1_email_text", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(textfield, value) {
            if (this._worker != null) this._worker.email =  value;         
        });

        this.passwordTextField = MUIOutlet(this, "password-tf", "MUITextField");
        this.passwordTextField.setOnChangeText(this, function(textfield, value) {
            if (this._worker != null) this._worker.password =  value;          
        });

        this.pincodeTextField = MUIOutlet(this, "wdv_c2_pin_code_text", "MUITextField");
        this.pincodeTextField.setOnChangeText(this, function(textfield, value) {
            if (this._worker != null) this._worker.pinCode = value;         
        });

        this.phoneTextField = MUIOutlet(this, "wdv_c2_db3_phone_number_text", "MUITextField");
        this.phoneTextField.setOnChangeText(this, function(textfield, value) {
            if (this._worker != null) this.phone.number = value;
        });

        this.mobilePhoneTextField = MUIOutlet(this, "wdv_c2_db2_mobile_phone_text", "MUITextField");
        this.mobilePhoneTextField.setOnChangeText(this, function(textfield, value) {
            if (this._worker != null) this.mobilePhone.number = value;
        });

        this.postalCodeTextField = MUIOutlet(this, "wdv_c2_db6_postal_code_text", "MUITextField");
        this.postalCodeTextField.setOnChangeText(this, function(textfield, value) {
            if (this._worker != null) this.address.postalcode = value;
        });

        this.address1TextField = MUIOutlet(this, "wdv_c2_db4_address_text", "MUITextField");
        this.address1TextField.setOnChangeText(this, function(textfield, value) {
            if (this._worker != null) this.address.address1 = value;
        });

    /*     this.address2TextField = MUIOutlet(this, "wdv_c2_db5_address_text", "MUITextField");
        this.address2TextField.setOnChangeText(this, function(textfield, value) {
            if (this._worker != null) this.address.address2 = value;
        }); */

        this.cityTextField = MUIOutlet(this, "wdv_c2_db7_city_text", "MUITextField");
        this.cityTextField.setOnChangeText(this, function(textfield, value) {
            if (this._worker != null) this.city = value;
        });

        this.countryDropdown = MUIOutlet(this, "country-dd", "MUIButton");
        this.countryDropdown.setAction(this, function(){
            if (this._worker == null) return;
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropdown, null, this, function(controller:SelectEntityViewController, country:Country){
                this.address.country = country.name;
                this.address.countryISON = country.ison;
                this.address.countryISOA2 = country.iso2;
                this.address.countryISOA3 = country.iso3;
                this.countryDropdown.title = country.name;
            });
        });
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _worker:Employee = null;
    set worker(value){
        this._worker = value;    
        this.updateUI();
    }
    
    private updateUI(){
        if (this.viewIsLoaded == false || this._worker == null) return;

        this.nameTextField.text = this._worker.name;
        this.emailTextField.text = this._worker.email;
        this.documentTextField.text = this._worker.document;

        this.updateAddress();
        this.phoneTextField.text = this.phone ? this.phone.number : null;
        this.mobilePhoneTextField.text = this.mobilePhone ? this.mobilePhone.number : null;

        this.passwordTextField.text = this._worker.password;
        this.pincodeTextField.text = this._worker.pinCode;
    }
    
    private updateAddress(){
        if (this.address == null) return;

        this.address1TextField.text = this.address.address1;
        //this.address2TextField.text = this.address.address2;
        this.countryDropdown.title = getCountryName( this.address.countryISOA2 );
        // was: this.countryDropdown.title = this.address.countryName;
        this.cityTextField.text = this.address.city;
        this.postalCodeTextField.text = this.address.postalCode;
    }

    get address(){
        if (this._worker == null) return null;

        if(this._worker.address == null){
            this._worker.address = MIOEntityDescription.insertNewObjectForEntityForName("Address", this.moc) as Address;
            this._worker.address.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._worker.address;
    }

    get phone(){
        if (this._worker == null) return null;

        if(this._worker.phone == null){
            this._worker.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", this.moc) as PhoneNumber;
            this._worker.phone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._worker.phone;
    }

    get mobilePhone(){
        if (this._worker == null) return null;

        if(this._worker.mobilePhone == null){
            this._worker.mobilePhone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", this.moc) as PhoneNumber;
            this._worker.mobilePhone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._worker.mobilePhone;
    }

    set city (city:string){
        let address = this.address;
        if (address == null) return;

        address.city = city;
    }

}
