

// Generated class Address_ManagedObject

class Address_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: address1
    set address1(value:string) {
        this.setValueForKey(value, 'address1');
    }
    get address1():string {
        return this.valueForKey('address1');
    }
    set address1PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'address1');
    }
    get address1PrimitiveValue():string {
        return this.primitiveValueForKey('address1');
    }

    // Property: address2
    set address2(value:string) {
        this.setValueForKey(value, 'address2');
    }
    get address2():string {
        return this.valueForKey('address2');
    }
    set address2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'address2');
    }
    get address2PrimitiveValue():string {
        return this.primitiveValueForKey('address2');
    }

    // Property: city
    set city(value:string) {
        this.setValueForKey(value, 'city');
    }
    get city():string {
        return this.valueForKey('city');
    }
    set cityPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'city');
    }
    get cityPrimitiveValue():string {
        return this.primitiveValueForKey('city');
    }

    // Property: state
    set state(value:string) {
        this.setValueForKey(value, 'state');
    }
    get state():string {
        return this.valueForKey('state');
    }
    set statePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'state');
    }
    get statePrimitiveValue():string {
        return this.primitiveValueForKey('state');
    }

    // Property: countryISON
    set countryISON(value:string) {
        this.setValueForKey(value, 'countryISON');
    }
    get countryISON():string {
        return this.valueForKey('countryISON');
    }
    set countryISONPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'countryISON');
    }
    get countryISONPrimitiveValue():string {
        return this.primitiveValueForKey('countryISON');
    }

    // Property: countryISOA2
    set countryISOA2(value:string) {
        this.setValueForKey(value, 'countryISOA2');
    }
    get countryISOA2():string {
        return this.valueForKey('countryISOA2');
    }
    set countryISOA2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'countryISOA2');
    }
    get countryISOA2PrimitiveValue():string {
        return this.primitiveValueForKey('countryISOA2');
    }

    // Property: countryISOA3
    set countryISOA3(value:string) {
        this.setValueForKey(value, 'countryISOA3');
    }
    get countryISOA3():string {
        return this.valueForKey('countryISOA3');
    }
    set countryISOA3PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'countryISOA3');
    }
    get countryISOA3PrimitiveValue():string {
        return this.primitiveValueForKey('countryISOA3');
    }

    // Property: countryName
    set countryName(value:string) {
        this.setValueForKey(value, 'countryName');
    }
    get countryName():string {
        return this.valueForKey('countryName');
    }
    set countryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'countryName');
    }
    get countryNamePrimitiveValue():string {
        return this.primitiveValueForKey('countryName');
    }

    // Property: postalCode
    set postalCode(value:string) {
        this.setValueForKey(value, 'postalCode');
    }
    get postalCode():string {
        return this.valueForKey('postalCode');
    }
    set postalCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'postalCode');
    }
    get postalCodePrimitiveValue():string {
        return this.primitiveValueForKey('postalCode');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }
}
