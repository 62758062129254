//
// Generated class InventoryTemplateItem
//

/// <reference path="InventoryTemplateItem_ManagedObject.ts" />

class InventoryTemplateItem extends InventoryTemplateItem_ManagedObject
{

}
