//
// Generated class ProductAttributeType
//

/// <reference path="ProductAttributeType_ManagedObject.ts" />

class ProductAttributeType extends ProductAttributeType_ManagedObject
{

}
