

// Generated class StockNoteLine_ManagedObject

class StockNoteLine_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: placeID
    set placeID(value:string) {
        this.setValueForKey(value, 'placeID');
    }
    get placeID():string {
        return this.valueForKey('placeID');
    }
    set placeIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'placeID');
    }
    get placeIDPrimitiveValue():string {
        return this.primitiveValueForKey('placeID');
    }

    // Property: appID
    set appID(value:string) {
        this.setValueForKey(value, 'appID');
    }
    get appID():string {
        return this.valueForKey('appID');
    }
    set appIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appID');
    }
    get appIDPrimitiveValue():string {
        return this.primitiveValueForKey('appID');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }

    // Property: productName
    set productName(value:string) {
        this.setValueForKey(value, 'productName');
    }
    get productName():string {
        return this.valueForKey('productName');
    }
    set productNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productName');
    }
    get productNamePrimitiveValue():string {
        return this.primitiveValueForKey('productName');
    }

    // Property: taxName
    set taxName(value:string) {
        this.setValueForKey(value, 'taxName');
    }
    get taxName():string {
        return this.valueForKey('taxName');
    }
    set taxNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxName');
    }
    get taxNamePrimitiveValue():string {
        return this.primitiveValueForKey('taxName');
    }

    // Property: taxQuantity
    set taxQuantity(value:number) {
        this.setValueForKey(value, 'taxQuantity');
    }
    get taxQuantity():number {
        return this.valueForKey('taxQuantity');
    }
    set taxQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxQuantity');
    }
    get taxQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('taxQuantity');
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }

    // Property: productMeasureType
    set productMeasureType(value:number) {
        this.setValueForKey(value, 'productMeasureType');
    }
    get productMeasureType():number {
        return this.valueForKey('productMeasureType');
    }
    set productMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMeasureType');
    }
    get productMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productMeasureType');
    }

    // Property: productContainerMeasureType
    set productContainerMeasureType(value:number) {
        this.setValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureType():number {
        return this.valueForKey('productContainerMeasureType');
    }
    set productContainerMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productContainerMeasureType');
    }

    // Property: productContainerQuantity
    set productContainerQuantity(value:number) {
        this.setValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantity():number {
        return this.valueForKey('productContainerQuantity');
    }
    set productContainerQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('productContainerQuantity');
    }

    // Property: measureType
    set measureType(value:number) {
        this.setValueForKey(value, 'measureType');
    }
    get measureType():number {
        return this.valueForKey('measureType');
    }
    set measureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'measureType');
    }
    get measureTypePrimitiveValue():number {
        return this.primitiveValueForKey('measureType');
    }
    // Relationship: inputFormat
    set inputFormat(value:StockInputFormat) {
        this.setValueForKey(value, 'inputFormat');
    }
    get inputFormat():StockInputFormat {
        return this.valueForKey('inputFormat') as StockInputFormat;
    }

    // Property: inputFormatName
    set inputFormatName(value:string) {
        this.setValueForKey(value, 'inputFormatName');
    }
    get inputFormatName():string {
        return this.valueForKey('inputFormatName');
    }
    set inputFormatNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'inputFormatName');
    }
    get inputFormatNamePrimitiveValue():string {
        return this.primitiveValueForKey('inputFormatName');
    }

    // Property: inputFormatQuantity
    set inputFormatQuantity(value:number) {
        this.setValueForKey(value, 'inputFormatQuantity');
    }
    get inputFormatQuantity():number {
        return this.valueForKey('inputFormatQuantity');
    }
    set inputFormatQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'inputFormatQuantity');
    }
    get inputFormatQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('inputFormatQuantity');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }

    // Property: productQuantity
    set productQuantity(value:number) {
        this.setValueForKey(value, 'productQuantity');
    }
    get productQuantity():number {
        return this.valueForKey('productQuantity');
    }
    set productQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productQuantity');
    }
    get productQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('productQuantity');
    }

    // Property: measureQuantity
    set measureQuantity(value:number) {
        this.setValueForKey(value, 'measureQuantity');
    }
    get measureQuantity():number {
        return this.valueForKey('measureQuantity');
    }
    set measureQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'measureQuantity');
    }
    get measureQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('measureQuantity');
    }

    // Property: estimatedQuantity
    set estimatedQuantity(value:number) {
        this.setValueForKey(value, 'estimatedQuantity');
    }
    get estimatedQuantity():number {
        return this.valueForKey('estimatedQuantity');
    }
    set estimatedQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'estimatedQuantity');
    }
    get estimatedQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('estimatedQuantity');
    }

    // Property: estimatedProductQuantity
    set estimatedProductQuantity(value:number) {
        this.setValueForKey(value, 'estimatedProductQuantity');
    }
    get estimatedProductQuantity():number {
        return this.valueForKey('estimatedProductQuantity');
    }
    set estimatedProductQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'estimatedProductQuantity');
    }
    get estimatedProductQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('estimatedProductQuantity');
    }

    // Property: estimatedPrice
    set estimatedPrice(value:number) {
        this.setValueForKey(value, 'estimatedPrice');
    }
    get estimatedPrice():number {
        return this.valueForKey('estimatedPrice');
    }
    set estimatedPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'estimatedPrice');
    }
    get estimatedPricePrimitiveValue():number {
        return this.primitiveValueForKey('estimatedPrice');
    }

    // Property: estimatedProductPrice
    set estimatedProductPrice(value:number) {
        this.setValueForKey(value, 'estimatedProductPrice');
    }
    get estimatedProductPrice():number {
        return this.valueForKey('estimatedProductPrice');
    }
    set estimatedProductPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'estimatedProductPrice');
    }
    get estimatedProductPricePrimitiveValue():number {
        return this.primitiveValueForKey('estimatedProductPrice');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: productPrice
    set productPrice(value:number) {
        this.setValueForKey(value, 'productPrice');
    }
    get productPrice():number {
        return this.valueForKey('productPrice');
    }
    set productPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productPrice');
    }
    get productPricePrimitiveValue():number {
        return this.primitiveValueForKey('productPrice');
    }

    // Property: measurePrice
    set measurePrice(value:number) {
        this.setValueForKey(value, 'measurePrice');
    }
    get measurePrice():number {
        return this.valueForKey('measurePrice');
    }
    set measurePricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'measurePrice');
    }
    get measurePricePrimitiveValue():number {
        return this.primitiveValueForKey('measurePrice');
    }

    // Property: discountString
    set discountString(value:string) {
        this.setValueForKey(value, 'discountString');
    }
    get discountString():string {
        return this.valueForKey('discountString');
    }
    set discountStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'discountString');
    }
    get discountStringPrimitiveValue():string {
        return this.primitiveValueForKey('discountString');
    }

    // Property: discountValue
    set discountValue(value:number) {
        this.setValueForKey(value, 'discountValue');
    }
    get discountValue():number {
        return this.valueForKey('discountValue');
    }
    set discountValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discountValue');
    }
    get discountValuePrimitiveValue():number {
        return this.primitiveValueForKey('discountValue');
    }

    // Property: total
    set total(value:number) {
        this.setValueForKey(value, 'total');
    }
    get total():number {
        return this.valueForKey('total');
    }
    set totalPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'total');
    }
    get totalPrimitiveValue():number {
        return this.primitiveValueForKey('total');
    }

    // Property: warehouseID
    set warehouseID(value:string) {
        this.setValueForKey(value, 'warehouseID');
    }
    get warehouseID():string {
        return this.valueForKey('warehouseID');
    }
    set warehouseIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseID');
    }
    get warehouseIDPrimitiveValue():string {
        return this.primitiveValueForKey('warehouseID');
    }

    // Property: warehouseName
    set warehouseName(value:string) {
        this.setValueForKey(value, 'warehouseName');
    }
    get warehouseName():string {
        return this.valueForKey('warehouseName');
    }
    set warehouseNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseName');
    }
    get warehouseNamePrimitiveValue():string {
        return this.primitiveValueForKey('warehouseName');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: note
    set note(value:StockNote) {
        this.setValueForKey(value, 'note');
    }
    get note():StockNote {
        return this.valueForKey('note') as StockNote;
    }

    // Property: legalEntityName
    set legalEntityName(value:string) {
        this.setValueForKey(value, 'legalEntityName');
    }
    get legalEntityName():string {
        return this.valueForKey('legalEntityName');
    }
    set legalEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalEntityName');
    }
    get legalEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('legalEntityName');
    }
    // Relationship: legalEntity
    set legalEntity(value:Supplier) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():Supplier {
        return this.valueForKey('legalEntity') as Supplier;
    }
}
