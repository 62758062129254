/** 
 * This data is the same as the enum in the ios application 
 */
enum MapItem {
    MapItemTypeTable = 0,
    MapItemTypePlant = 1,
    MapItemTypeHorizontalBar = 2,
    MapItemTypeVerticalBar = 3,
    MapItemTypeStool = 4,
    MapItemTypeBarStandTable = 5,
    MapItemTypeBarStandLargePlant = 6,
    MapItemTypePool = 7,
    MapItemTypeTableSoccer = 8,
    MapItemTypePiano = 9,
    MapItemTypeGame = 10,
    MapItemTypeCigar = 11,
    MapItemTypeSlotMachine = 12,
    MapItemTypeStair = 13,
    MapItemTypeSofa1 = 14,
    MapItemTypeSofa2 = 15,
    MapItemTypeSofa3 = 16,
    MapItemTypeRoundedTable = 17,
    MapItemTypeVipSofa = 18,
    MapItemTypeVipTable = 19,
    MapItemTypeVipJacuzzi = 20,
    MapItemTypeHostelcoTable = 21,
    MapItemTypeHostelcoCenter = 22,
    MapItemTypeHostelcoLight = 23,
    MapItemTypeHostelcoLight2 = 24,

    MapItemTypeHeartStool = 25,
    MapItemTypeHeartBrownTable = 26,
    MapItemTypeHeartGrayTable = 27,
    MapItemTypeHeartRedTable = 28,

    MapItemTypeBeachBedBamboo = 29,
    MapItemTypeBeachBed = 30,
    MapItemTypeBeachGazeboBamboo = 31,
    MapItemTypeBeachGazebo = 32,
    MapItemTypeBeachHammock = 33,
    MapItemTypeBeachHammockTriple = 34,
    MapItemTypeBeachHammockVIP = 35,
    MapItemTypeBeachTable = 36,
    MapItemTypeBeachUmbrella = 37,
    MapItemTypeBeachHammockXL = 38,
    MapItemTypeBeachHammockDouble = 39,

    MapItemTypeSNDLamp = 40,
    MapItemTypeSNDPlug = 41,
    MapItemTypeSNDThermostat = 42,
};

interface IMapItem {
    styleEmpty: string;
    mapping: MapItem;
    hasHD: boolean;
}

interface IMapInteraction {
    styleMove: string;
    styleFull: string;
    occupied: boolean;
}

interface IMapItemData {
    type: number,
    x: number,
    y: number,
    angle?: number
}

class Table implements IMapItem, IMapInteraction {
    constructor(
        public mapping: MapItem,
        public styleEmpty: string,
        public styleMove: string,
        public styleFull: string,
        public hasHD: boolean = true,
        public occupied: boolean = false
    ) { }
}

class Stool implements IMapItem, IMapInteraction {
    constructor(
        public mapping: MapItem,
        public styleEmpty: string,
        public styleMove: string,
        public styleFull: string,
        public hasHD: boolean = true,
        public occupied: boolean = false
    ) { }
}

class Decoration implements IMapItem {
    constructor(
        public mapping: MapItem,
        public styleEmpty: string,
        public styleToggled: string = "",
        public hasHD: boolean = true
    ) { }
}

enum MapBackground {
    MapItemFloorType1 = 0,
    MapItemFloorType2 = 1,
    MapItemFloorType3 = 2,
    MapItemFloorTypeHeart = 3,
    MapItemFloorTypeBeach = 4,
    MapItemFloorTypeBeachUD = 5
}

const MapBackgroundData = {
    MapItemFloorType1: "map-background-grass",
    MapItemFloorType2: "map-background-wood",
    MapItemFloorType3: "map-background-tiles",
    MapItemFloorTypeHeart: "map-background-wood2",
    MapItemFloorTypeBeach: "map-background-beach",
    MapItemFloorTypeBeachUD: "map-background-beach-ud"
}

function MapBackgroundFactory(backgroundId: number) {
    const background = MapBackground[backgroundId];
    if (background) {
        return MapBackgroundData[background];
    } else {
        return "map-fonsoplanoad";
    }
}

/**
 * Mapping between the *itemtypes* and the *css class*es.
 * 
 * The css classes are generated: 
 * 1. Generate the scss variables: `npm run generate-map-items`
 * 1. Generate the css classes: `gulp scss`
 */
const MapItemData = {
    MapItemTypeTable: new Table(MapItem.MapItemTypeTable, "map-mesa-vacia", "map-mesa-singente", "map-mesa-gente"), //How does it look like
    MapItemTypePlant: new Decoration(MapItem.MapItemTypePlant, "map-planta"),
    MapItemTypeHorizontalBar: new Decoration(MapItem.MapItemTypeHorizontalBar, "map-barrahorizontal"),
    MapItemTypeVerticalBar: new Decoration(MapItem.MapItemTypeVerticalBar, "map-barravertical"),
    MapItemTypeStool: new Stool(MapItem.MapItemTypeStool, "map-taburete_vacio", "map-taburete_arrastre", "map-taburete_lleno"),
    MapItemTypeBarStandTable: new Table(MapItem.MapItemTypeBarStandTable, "map-mesa-vacia", "map-mesa-singente", "map-mesa-gente"),
    MapItemTypeBarStandLargePlant: new Decoration(MapItem.MapItemTypeBarStandLargePlant, "map-planta-larga"),
    MapItemTypePool: new Decoration(MapItem.MapItemTypePool, "map-billar"),
    MapItemTypeTableSoccer: new Decoration(MapItem.MapItemTypeTableSoccer, "map-futbolin"),
    MapItemTypePiano: new Decoration(MapItem.MapItemTypePiano, "map-piano"),
    MapItemTypeGame: new Decoration(MapItem.MapItemTypeGame, "map-recreativa"), //It is animated, the app return Nil
    MapItemTypeCigar: new Decoration(MapItem.MapItemTypeCigar, "map-tabaco"),
    MapItemTypeSlotMachine: new Decoration(MapItem.MapItemTypeSlotMachine, "map-tragaperras"),

    MapItemTypeStair: new Decoration(MapItem.MapItemTypeStair, "map-escalera"),
    MapItemTypeSofa1: new Decoration(MapItem.MapItemTypeSofa1, "map-sofa1"),
    MapItemTypeSofa2: new Decoration(MapItem.MapItemTypeSofa2, "map-sofa2"),
    MapItemTypeSofa3: new Decoration(MapItem.MapItemTypeSofa3, "map-sofa3"),
    MapItemTypeRoundedTable: new Table(MapItem.MapItemTypeRoundedTable, "map-rounded-table-1", "map-rounded-table-2", "map-rounded-table-3"),
    MapItemTypeVipSofa: new Stool(MapItem.MapItemTypeVipSofa, "map-sofa-sin", "map-sofavip-mov", "map-sofa2-con"),
    MapItemTypeVipTable: new Table(MapItem.MapItemTypeVipTable, "map-mesavip-sin", "map-mesavip-mov", "map-mesavip-con"),
    MapItemTypeVipJacuzzi: new Decoration(MapItem.MapItemTypeVipJacuzzi, "map-jacuzzi"),
    MapItemTypeHostelcoTable: new Table(MapItem.MapItemTypeHostelcoTable, "map-mesahostelco", "map-mesaplatoshostelco", "map-mesallenahostelco"),
    MapItemTypeHostelcoCenter: new Decoration(MapItem.MapItemTypeHostelcoCenter, "map-barra-h"),
    MapItemTypeHostelcoLight: new Decoration(MapItem.MapItemTypeHostelcoLight, "map-luz", "map-luzon"),
    MapItemTypeHostelcoLight2: new Decoration(MapItem.MapItemTypeHostelcoLight2, "map-farola", "map-farola-on"),

    MapItemTypeHeartStool: new Stool(MapItem.MapItemTypeHeartStool, "map-stool-empty", "map-stool-without", "map-stool-with"),
    MapItemTypeHeartBrownTable: new Table(MapItem.MapItemTypeHeartBrownTable, "map-table-brown-empty", "map-table-brown-without", "map-table-empty-with"),
    MapItemTypeHeartGrayTable: new Table(MapItem.MapItemTypeHeartGrayTable, "map-table-gray-empty", "map-table-gray-without", "map-table-gray-with"),
    MapItemTypeHeartRedTable: new Table(MapItem.MapItemTypeHeartRedTable, "map-table-red-empty", "map-table-red-without", "map-table-red-with"),

    MapItemTypeBeachBedBamboo: new Table(MapItem.MapItemTypeBeachBedBamboo, "map-beach-bed-bamboo-empty", "map-beach-bed-bamboo-move", "map-beach-gazebo-bamboo-full"),
    MapItemTypeBeachBed: new Table(MapItem.MapItemTypeBeachBed, "map-beach-bed-empty", "map-beach-bed-move", "map-beach-bed-full"),
    MapItemTypeBeachGazeboBamboo: new Table(MapItem.MapItemTypeBeachGazeboBamboo, "map-beach-gazebo-bamboo-empty", "map-beach-gazebo-bamboo-move", "map-beach-gazebo-bamboo-full"),
    MapItemTypeBeachGazebo: new Table(MapItem.MapItemTypeBeachGazebo, "map-beach-gazebo-empty", "map-beach-gazebo-move", "map-beach-gazebo-full"),
    MapItemTypeBeachHammock: new Stool(MapItem.MapItemTypeBeachHammock, "map-hammock-empty", "map-hammock-move", "map-hammock-full"),
    MapItemTypeBeachHammockTriple: new Stool(MapItem.MapItemTypeBeachHammockTriple, "map-beach-hammock-triple-empty", "map-beach-hammock-triple-move", "map-beach-hammock-triple-full"),
    MapItemTypeBeachHammockVIP: new Stool(MapItem.MapItemTypeBeachHammockVIP, "map-beach-hammcock-vip-empty", "map-beach-hammcock-vip-move", "map-beach-hammock-triple-full"),
    MapItemTypeBeachTable: new Table(MapItem.MapItemTypeBeachTable, "map-beach-table-empty", "map-beach-table-move", "map-beach-table-full"),
    MapItemTypeBeachUmbrella: new Decoration(MapItem.MapItemTypeBeachUmbrella, "map-beach-umbrella"),
    MapItemTypeBeachHammockXL: new Table(MapItem.MapItemTypeBeachHammockXL, "map-hammock-xl-empty", "map-hammock-xl-move", "map-hammock-xl-full"),
    MapItemTypeBeachHammockDouble: new Table(MapItem.MapItemTypeBeachHammockDouble, "map-hammock-double-empty", "map-hammock-double-move", "map-hammock-double-full"),

    MapItemTypeSNDLamp: new Decoration(MapItem.MapItemTypeSNDLamp, "map-lamp-off", "map-lamp-on"),
    MapItemTypeSNDPlug: new Decoration(MapItem.MapItemTypeSNDPlug, "map-plug-off", "map-plug-on"),
    MapItemTypeSNDThermostat: new Decoration(MapItem.MapItemTypeSNDThermostat, "map-thermostat-off", "map-thermostat-on")
}

/**
 * Create instances of the elements from the database to show them in the map.
 * 
 * @param itemData MapItem data from the server
 */
function MapItemFactory(itemData: IMapItemData) {
    const mapping = MapItem[itemData.type];
    if (mapping) {
        const parent = MapItemData[mapping];
        const instance = { ...parent, ...itemData }; //Spread syntax to copy the properties
        return instance;
    } else {
        return null;
    }
}

interface ILocationData {
    angle?: number,
    id: string,
    x: number,
    y: number,
    name: string,
    type: number,
    idIn: number,
    idPlace: string,
    category: string,
    createdAt: string,
    updatedAt: string
}

/**
 * Create a location element to put on the map.
 * 
 * @param data Data from the server
 */
function createLocation(data: ILocationData & IMapItem & IMapInteraction) {
    //console.log("Add element(%o)", data);
    // Create the container
    const containerDiv = document.createElement("div");
    containerDiv.classList.add("map-item");
    containerDiv.style.position = 'absolute';
    containerDiv.style.top = `${data.y}%`;
    containerDiv.style.left = `${data.x}%`;
    const scale = 0.5;
    containerDiv.style.transform = `scale(${scale}) translate( -100% , -100% )`;

    // Create the text label
    const textLabel = document.createTextNode(`${data.name}`);
    containerDiv.appendChild(textLabel);

    // Create the image
    const imageItem = document.createElement("div");
    imageItem.style.transformOrigin = "50%, 50%";
    imageItem.classList.add(data.styleEmpty);
    imageItem.style.transform = data.angle ? `rotate(${360 / data.angle}deg) ` : '';
    containerDiv.appendChild(imageItem);

    return containerDiv;
}

function createLocationCategory(id: string, index: number) {
    const locationCategory = document.createElement("div");

    locationCategory.id = id;
    locationCategory.style.display = index ? 'none' : 'block';
    locationCategory.style.position = 'absolute';
    locationCategory.style.left = '10px';
    locationCategory.style.top = '90px';
    locationCategory.style.width = '99%';
    locationCategory.style.height = '80%';
    locationCategory.style.backgroundSize = 'cover';

    return locationCategory;
}