

// Generated class BookingLog_ManagedObject

class BookingLog_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: idPlace
    set idPlace(value:string) {
        this.setValueForKey(value, 'idPlace');
    }
    get idPlace():string {
        return this.valueForKey('idPlace');
    }
    set idPlacePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'idPlace');
    }
    get idPlacePrimitiveValue():string {
        return this.primitiveValueForKey('idPlace');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: action
    set action(value:string) {
        this.setValueForKey(value, 'action');
    }
    get action():string {
        return this.valueForKey('action');
    }
    set actionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'action');
    }
    get actionPrimitiveValue():string {
        return this.primitiveValueForKey('action');
    }

    // Property: bookingID
    set bookingID(value:string) {
        this.setValueForKey(value, 'bookingID');
    }
    get bookingID():string {
        return this.valueForKey('bookingID');
    }
    set bookingIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingID');
    }
    get bookingIDPrimitiveValue():string {
        return this.primitiveValueForKey('bookingID');
    }

    // Property: userid
    set userid(value:string) {
        this.setValueForKey(value, 'userid');
    }
    get userid():string {
        return this.valueForKey('userid');
    }
    set useridPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'userid');
    }
    get useridPrimitiveValue():string {
        return this.primitiveValueForKey('userid');
    }

    // Property: userName
    set userName(value:string) {
        this.setValueForKey(value, 'userName');
    }
    get userName():string {
        return this.valueForKey('userName');
    }
    set userNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'userName');
    }
    get userNamePrimitiveValue():string {
        return this.primitiveValueForKey('userName');
    }

    // Property: userEmail
    set userEmail(value:string) {
        this.setValueForKey(value, 'userEmail');
    }
    get userEmail():string {
        return this.valueForKey('userEmail');
    }
    set userEmailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'userEmail');
    }
    get userEmailPrimitiveValue():string {
        return this.primitiveValueForKey('userEmail');
    }

    // Property: workerid
    set workerid(value:string) {
        this.setValueForKey(value, 'workerid');
    }
    get workerid():string {
        return this.valueForKey('workerid');
    }
    set workeridPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerid');
    }
    get workeridPrimitiveValue():string {
        return this.primitiveValueForKey('workerid');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }

    // Property: workerEmail
    set workerEmail(value:string) {
        this.setValueForKey(value, 'workerEmail');
    }
    get workerEmail():string {
        return this.valueForKey('workerEmail');
    }
    set workerEmailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerEmail');
    }
    get workerEmailPrimitiveValue():string {
        return this.primitiveValueForKey('workerEmail');
    }

    // Property: data
    set data(value:string) {
        this.setValueForKey(value, 'data');
    }
    get data():string {
        return this.valueForKey('data');
    }
    set dataPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'data');
    }
    get dataPrimitiveValue():string {
        return this.primitiveValueForKey('data');
    }

    // Property: process
    set process(value:boolean) {
        this.setValueForKey(value, 'process');
    }
    get process():boolean {
        return this.valueForKey('process');
    }
    set processPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'process');
    }
    get processPrimitiveValue():boolean {
        return this.primitiveValueForKey('process');
    }

    // Property: processerrors
    set processerrors(value:string) {
        this.setValueForKey(value, 'processerrors');
    }
    get processerrors():string {
        return this.valueForKey('processerrors');
    }
    set processerrorsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'processerrors');
    }
    get processerrorsPrimitiveValue():string {
        return this.primitiveValueForKey('processerrors');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
