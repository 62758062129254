class TimeRangeViewController extends MUIViewController
{
    private backButton:MUIButton = null;
    private addButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private tableView:UITableView = null;
    private timeRangeGroups:TimeRangeGroup[] = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(control){
            this.navigationController.popViewController(true);
        });

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function(){
           this.addTimeRange();
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.titleLabel = MUIOutlet(this, "page-title", "MUILabel");

        //set page title
        if (this._timeRangeGroupType == TimeRangeGroupType.TakeAway) {
            this.titleLabel.text = MIOLocalizeString("TAKE AWAY SCHEDULE","TAKE AWAY SCHEDULE");
        } else if (this._timeRangeGroupType == TimeRangeGroupType.Delivery){
            this.titleLabel.text = MIOLocalizeString("DELIVERY SCHEDULE","DELIVERY SCHEDULE");
        }

        //GET A LIST OF THE TIME RANGE GROUPS FOR COMPARISON LATER
        //let predicate = MIOPredicate.predicateWithFormat("deletedAt == null AND type == " + _timeRangeGroupType);
        DBHelper.queryObjectsWithCompletion("TimeRangeGroup", null, null, [], this, function(objects:TimeRangeGroup[]){  
            this.timeRangeGroups = ((objects.length > 0) ? objects : null);
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.reloadData();
    }

    private _timeRangeGroupType:TimeRangeGroupType = null;
    set timeRangeGroupType(type:TimeRangeGroupType){
        this._timeRangeGroupType = type;
    }

    updateUI(){
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){

        if (this.fetchedResultsController == null) return 0;
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){

        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects() + 1;
    }

    // titleForHeaderInSection(tableView:UITableView, section:number) {}
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){

        let cell = null;
        let item = null;

        if ( indexPath.row == 0) {
            cell = tableView.dequeueReusableCellWithIdentifier("TimeRangeGroupCell") as TimeRangeGroupCell;
            item  = this.fetchedResultsController.objectAtIndexPath(indexPath) as TimeRange;
            cell.item = item.timeRangeGroup; 
        } else {
            cell = tableView.dequeueReusableCellWithIdentifier("TimeRangeCell") as TimeRangeCell;
            let realIndexPath = MIOIndexPath.indexForRowInSection(indexPath.row-1, indexPath.section);
            item = this.fetchedResultsController.objectAtIndexPath(realIndexPath) as TimeRange;
            cell.item = item;
        }

        return cell;
    }

    //currently not working
    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) { 
        if( indexPath.row == 0 ) {
            return UITableViewCellEditingStyle.Delete;
        } 

        return UITableViewCellEditingStyle.None;
    }

    //currently not working
    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            alert("delete test");
            //DBHelper.deleteObjectFromMainContext(item, true);
            //also get TimeRanges and delete those as well
        }
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){}

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('TimeRange');
        //fetchRequest.predicate = MIOPredicate.predicateWithFormat('timeRangeGroup.type == "' + this._timeRangeGroupType + '"');   
        fetchRequest.sortDescriptors = [    MIOSortDescriptor.sortDescriptorWithKey('groupName', true),
                                            MIOSortDescriptor.sortDescriptorWithKey('time', true)];                 
        fetchRequest.relationshipKeyPathsForPrefetching = ["timeRangeGroup"];
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "groupName");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.updateUI();
    }

    private addTimeRange(){
        
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('TIME RANGE','TIME RANGE'), MIOLocalizeString('CREATE NEW TIME RANGE GROUP','CREATE NEW TIME RANGE GROUP'), MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("START TIME (EG. 9:00)","START TIME (EG. 9:00)"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("END TIME (EG. 22:00","END TIME (EG. 22:00"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("TIME INTERVAL IN MINUTES (EG. 15, 30, ETC.)","TIME INTERVAL IN MINUTES (EG. 15, 30, ETC.)"));
        });
        
        // avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
        //     textField.setPlaceholderText("Quantity");
        //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        //     textField.formatter = ad.numberFormatter;
        // });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','DONE'), MUIAlertActionStyle.Default, this, function(){
            
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

            //get main variables from view
            let name = avc.textFields[0].text.trim();
            let startTime = avc.textFields[1].text;
            let endTime = avc.textFields[2].text
            let interval = ad.numberFormatter.numberFromString(avc.textFields[3].text);

            //convert to date objects for comparison
            let startDate:any = ad.timeFormatter.dateFromString(startTime);
            let endDate:any = ad.timeFormatter.dateFromString(endTime);

            //get the interval in minutes
            let minDifference = null;
            if( endDate - startDate == 0 ){
                //not valid
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('START AND END TIME MUST BE DIFFERENT','START AND END TIME MUST BE DIFFERENT'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            } else if ( endDate - startDate > 0 ) {
                minDifference =  (endDate - startDate) / 60000; //millisecons to min
            } else {
                //next day
                endDate.setDate(endDate.getDate() + 1);
                minDifference =  (endDate - startDate) / 60000; //millisecons to min
            }

            //check interval validity
            if( minDifference % interval != 0 ){
                //throw some error and return
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('UNACCEPTABLE TIME INTERVAL','UNACCEPTABLE TIME INTERVAL'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            }

            //check for TimeRangeGroup overlaps
            let isValid = this.checkTimeRangeValidity(startDate, endDate, this.timeRangeGroups);
            if (!isValid) {
                //alert error and return
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('TIME RANGES CANNOT OVERLAP','TIME RANGES CANNOT OVERLAP'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            }

            // Creat new TimeRangeGroup
            let newTimeRangeGroup = MIOEntityDescription.insertNewObjectForEntityForName("TimeRangeGroup", moc) as TimeRangeGroup;
            newTimeRangeGroup.name = name;
            newTimeRangeGroup.identifier = MIOUUID.UUID().UUIDString;
            newTimeRangeGroup.startHour = avc.textFields[1].text;
            newTimeRangeGroup.endHour = avc.textFields[2].text;
            newTimeRangeGroup.duration = avc.textFields[3].text;
            newTimeRangeGroup.type = this._timeRangeGroupType;
            //newTimeRangeGroup.maxPax = ??;

            moc.save() //save added here to ensure that group is saved before ranges (workaround for error in API)

            // Creat TimeRanges
            let newTimeRange:TimeRange;
            let newDate:Date;
            for (var index = 0; index < (minDifference/interval); index++) {
                newTimeRange = MIOEntityDescription.insertNewObjectForEntityForName("TimeRange", moc) as TimeRange;
                newDate = new Date(startDate.getTime() + (interval*60000*index))
                newTimeRange.identifier =  MIOUUID.UUID().UUIDString;
                newTimeRange.hour = ad.timeFormatter.stringFromDate(newDate);
                newTimeRange.title = newTimeRange.title;
                newTimeRange.groupName = name;
                newTimeRange.nextDay = this.sameDay(startDate, newDate);
                newTimeRange.timeRangeGroup = newTimeRangeGroup;
            }

            moc.save() //see above note one saves

        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }

    //checks if two dates are in the same day
    private sameDay(d1:Date, d2:Date) {
        return !(d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate());
    }

    //checks if a time range (startDate and endDate) have overlaps with TimeRangeGroups (passed as an array of TimeRangeGroup)
    private checkTimeRangeValidity(startDate, endDate, ranges:TimeRangeGroup[]) {

        if (ranges == null) return true;

        let isValid = true;
        let tempStartDate = null;
        let tempEndDate = null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        //loop through timeRanges that exist and return false if overlap
        for(var index = 0; index < ranges.length; index++) {

            tempStartDate = ad.timeFormatter.dateFromString(ranges[index].startHour);
            tempEndDate = ad.timeFormatter.dateFromString(ranges[index].endHour);

            //check for nextDay endTime
            if (tempStartDate - tempEndDate > 0) {
                tempEndDate.setDate(tempEndDate.getDate() + 1); //add a day to the end date
            }

            // CHECKS FOR POSITIVE CASES (new date ranges is completly before or after existing ranges)
            if ( !((startDate < tempStartDate && endDate < tempStartDate) || (tempEndDate < startDate && tempEndDate < endDate)) ) {
                isValid = false;
            }

            // CHECKS FOR NEGATIVE CASES (Either check for positive cases or negative)
            // if( (timetempStartDate < start && start < timetempEndDate) || (timetempStartDate < end && end < timetempEndDate) ) {
            //     isValid = false;
            // } else if (start < timetempStartDate && timetempEndDate < end) {
            //     isValid = false;
            // }
        }

        return isValid; //no overlaping cases found, return true
    }

}