/// <reference path="../notes/NoteLineCell.ts" />

class SupplierNoteLineCell extends NoteLineCell
{
    private warehouseDropdown:MUIButton = null;
    private warehouseLabel:MUILabel = null;
    private taxDropdown:MUIButton = null;
    private taxLabel:MUILabel = null;
    private orderedQuantityLabel:MUILabel = null;
    private inputFormatDropdown:MUIButton = null;
    private inputFormatLabel:MUILabel = null;
    private quantityTextField:MUITextField = null;
    private quantityLabel:MUILabel = null;
    private priceTextField:MUITextField = null;
    private priceLabel:MUILabel = null;  
    private productQuantityLabel:MUILabel = null;  
    private productPriceLabel:MUILabel = null;  
    private discountTextField:MUITextField = null;
    private discountLabel:MUILabel = null;
    private totalLabel:MUILabel = null;
    
    awakeFromHTML(){
        super.awakeFromHTML();

        this.warehouseDropdown = MUIOutlet(this, "warehouse-dd", "MUIButton");
        this.setupWarehouseDropdown(this.warehouseDropdown, this, this.warehouseDidSelect);
        this.warehouseLabel = MUIOutlet(this, "warehouse-lbl", "MUILabel");

        this.taxDropdown = MUIOutlet(this, "product-tax-dd", "MUIButton");
        this.setupTaxDropdown(this.taxDropdown, this, this.taxDidSelect);
        this.taxLabel = MUIOutlet(this, "product-tax-lbl", "MUILabel");
    
        this.orderedQuantityLabel = MUIOutlet(this, "ordered-quantity-lbl", "MUILabel");

        this.inputFormatDropdown = MUIOutlet(this, "input-format-dd", "MUIButton");
        this.setupInputFormatDropdown(this.inputFormatDropdown);
        this.inputFormatLabel = MUIOutlet(this, "input-format-lbl", "MUILabel");
    
        this.quantityTextField = MUIOutlet(this, 'quantity-tf', 'MUITextField');   
        this.setupQuantityTextField(this.quantityTextField);        
        this.quantityLabel = MUIOutlet(this, "quantity-lbl", "MUILabel");

        this.priceTextField = MUIOutlet(this, "price-tf", "MUITextField");
        this.setupPriceTextField(this.priceTextField);
        this.priceLabel = MUIOutlet(this, "price-lbl", "MUILabel");

        this.productQuantityLabel = MUIOutlet(this, "product-quantity-lbl", "MUILabel");
        this.productPriceLabel = MUIOutlet(this, "product-price-lbl", "MUILabel");
        
        this.discountTextField = MUIOutlet(this, "discount-tf", "MUITextField");        
        this.setupDiscountTextField(this.discountTextField);
        this.discountLabel = MUIOutlet(this, "discount-lbl", "MUILabel");

        this.totalLabel = MUIOutlet(this, "total-price-lbl", "MUILabel");        

        this.reset();
        
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }
    
    setLine(item:StockNoteLine){
        super.setLine(item);    
        
        this.productLabel.text = this.productNameString();

        if (this.taxLabel != null) this.taxLabel.text = item.stockTaxString();
        if (this.taxDropdown != null) {
            this.taxDropdown.title = item.stockTaxString();
            this.taxDropdown.enabled = true;
        }                

        if (this.warehouseLabel != null) this.warehouseLabel.text = this.warehouseNameString();
        if (this.warehouseDropdown != null) {
            this.warehouseDropdown.title = this.warehouseNameString();
            this.warehouseDropdown.enabled = true;
        }

        this.orderedQuantityLabel.text = this.nf.stringFromNumber(item.estimatedQuantity);
        
        if (this.inputFormatLabel != null) this.inputFormatLabel.text = this.inputFormatString();
        if (this.inputFormatDropdown != null) {
            this.inputFormatDropdown.title = this.inputFormatString();
            this.inputFormatDropdown.enabled = true;
        }
        
        let q = this.nf.stringFromNumber(item.quantity);
        if (this.quantityLabel != null) this.quantityLabel.text = q;
        if (this.quantityTextField != null) {
            this.quantityTextField.text = q;
            this.quantityTextField.enabled = true;
        }        

        this.productQuantityLabel.text = this.productQuantityString();

        let p = this.cf.stringFromNumber(item.price);
        if (this.priceLabel != null) this.priceLabel.text = p;
        if (this.priceTextField != null) {
            this.priceTextField.text = p;
            this.priceTextField.enabled = true;
        }

        if (this.discountLabel != null) this.discountLabel.text = this.discount;
        if (this.discountTextField != null) {
            this.discountTextField.text = this.discount;
            this.discountTextField.enabled = true;
        }

        this.productPriceLabel.text = this.productPriceString();
        this.totalLabel.text = this.totalPriceString();
    }

    productDidSelect(controller:SelectEntityViewController, product:Product, supplierProduct?:SupplierProduct){
        if (product == null && supplierProduct == null) return;
        
        this.setProduct(product);
        if (product.defaultWarehouse != null) {
            this.warehouseID = product.defaultWarehouse.identifier;
            this.warehouseName = product.defaultWarehouseName;    
        }

        if (supplierProduct != null) {
            this.productPrice = DBHelper.calculateCostFromSupplierProduct(product, supplierProduct);            
        }
        else {
            let supplier = null;
            if (this.delegate != null) supplier = this.delegate.supplierFromNote(this);
            [this.productPrice, this.discount] = DBHelper.costFromProductAndSupplier(product, supplier);            
        }
        
        this.price = this.productPrice;
        this.tax = DBHelper.stockTaxFromProduct(product);
        
        this.productTextField.text = this.productNameString();
                
        this.warehouseDropdown.enabled = true;
        this.warehouseDropdown.title = this.warehouseNameString();

        this.taxDropdown.enabled = true;        
        this.taxDropdown.title = StockNoteLine.stockTaxStringFromProduct(product);

        this.inputFormatDropdown.enabled = true;
        this.inputFormatDropdown.title = product.defaultInputFormatString();                

        this.quantityTextField.enabled = true;
        this.quantityTextField.becomeFirstResponder();        

        this.priceTextField.enabled = true;
        this.priceTextField.text = this.cf.stringFromNumber(this.productPrice);

        this.productQuantityLabel.text = this.productQuantityString();
        this.productPriceLabel.text = this.productPriceString();        
        
        this.discountTextField.enabled = true;        
        this.discountTextField.text = this.discount;

        this.totalLabel.text = this.totalPriceString();
    }

    taxDidSelect(controller:SelectEntityViewController, tax:Tax){
        super.taxDidSelect(controller, tax);        
        this.taxDropdown.title = tax.name;        
    }
    
    warehouseDidSelect(controller:SelectEntityViewController, warehouse:Warehouse){        
        if (warehouse != null) {
            this.warehouseID = warehouse.identifier;
            this.warehouseName = warehouse.name;
            this.warehouseDropdown.title = warehouse.name;
        } 
        else {
            this.warehouseID = null;
            this.warehouseName = MIOLocalizeString("DEFAULT WAREHOUSE", "DEFAULT WAREHOUSE");
            this.warehouseDropdown.title = MIOLocalizeString("DEFAULT WAREHOUSE", "DEFAULT WAREHOUSE");
        }
        this.updateStockLine();
    }

    protected inputFormatDidSelect(controller:SelectInputFormatViewController, inputFormat:StockInputFormat, inputType:MeasureUnitType){
        super.inputFormatDidSelect(controller, inputFormat, inputType);        
        this.inputFormatDropdown.title = this.inputFormatString();
        this.productQuantityLabel.text = this.productQuantityString();
        this.priceTextField.text = this.cf.stringFromNumber(this.price);
        this.productPriceLabel.text = this.productPriceString(); 
        this.totalLabel.text = this.totalPriceString();           
    }
    
    quantityDidChange(quantity){
        super.quantityDidChange(quantity);
        this.productQuantityLabel.text = this.productQuantityString();
        this.productPriceLabel.text = this.productPriceString();                
        this.totalLabel.text = this.totalPriceString();
    }
            
    priceDidChange(price){
        super.priceDidChange(price);
        this.productPriceLabel.text = this.productPriceString();
        this.totalLabel.text = this.totalPriceString();
    }
    
    discountDidChange(discount){
        super.discountDidChange(discount);        
        this.totalLabel.text = this.totalPriceString();
    }

    enterDidPress(){
        if (this.stockNoteLine != null) return;        
        if (this.quantity == 0 || this.quantity == null) return;
                
        let line = DBHelper.createSupplierNoteLine(this.product, this.tax, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.measureQuantity, this.price, this.productPrice, this.totalPrice, this.warehouseID, this.warehouseName, this.stockNote);
        if (line != null) {
            line.discountString = this.discount;
            line.discountValue = this.discountValue;
            this.lineDidInserted(line);            
            this.reset();
        }
    }
    
    reset(){
        this.product = null;        
        this.inputFormat = null;
        this.quantity = 0;
        this.productQuantity = 0;
        this.measureQuantity = 0;

        
        if (this.productTextField != null) this.productTextField.text = null;

        if (this.warehouseDropdown != null) {
            this.warehouseDropdown.enabled = false;
            this.warehouseDropdown.title = null;
        }

        if (this.taxDropdown != null) {
            this.taxDropdown.title = null;
            this.taxDropdown.enabled = false;
        }

        this.orderedQuantityLabel.text = null;

        if (this.inputFormatDropdown != null) {
            this.inputFormatDropdown.title = null;
            this.inputFormatDropdown.enabled = false;            
        }        

        if (this.quantityTextField != null) {
            this.quantityTextField.text = null;
            this.quantityTextField.enabled = false;
        }        

        if (this.priceTextField != null) {
            this.priceTextField.text = null;
            this.priceTextField.enabled = false;
        }

        this.productQuantityLabel.text = null;
        this.productPriceLabel.text = null;

        if (this.discountLabel != null) this.discountLabel.text = null;
        if (this.discountTextField != null) {
            this.discountTextField.text = null;
            this.discountTextField.enabled = false;
        }

        this.totalLabel.text = null;
    }    

    private warehouseNameString(){        
        let name = this.warehouseID != null ? this.warehouseName : MIOLocalizeString("DEFFAULT WAREHOUSE", "Default Warehouse");
        return name;        
    }
    
}
