/**
 * Created by crisan on 7/12/16.
 */

class ClientCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private accountLabel:MUILabel = null;
    private emailLabel:MUILabel = null;
    private debtLabel:MUILabel = null;        

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.accountLabel = MUIOutlet(this, "account-lbl", "MUILabel");
        this.emailLabel = MUIOutlet(this, "email-lbl", "MUILabel");
        this.debtLabel = MUIOutlet(this, 'debt-lbl','MUILabel');

        this.separatorStyle = MUITableViewCellSeparatorStyle.None;
    }

    set item(i:Client){
        this.nameLabel.text = i.name;
        this.emailLabel.text = i.email;        
            
        let cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

        this.accountLabel.text = i.accountBalance != null ? cf.stringFromNumber(i.accountBalance.amount) : cf.stringFromNumber(0);        
        this.debtLabel.text = (i.debtBalance != null && i.debtBalance.amount > 0) ? cf.stringFromNumber(i.debtBalance.amount) : null;
    }

}