
class SupplierOrderDetailViewController extends NoteDetailViewController
{
    private noEditHeaderView:SupplierOrderDetailHeader = null;
    private editHeaderView:SupplierOrderDetailHeader = null;   

    private importProductButton:MUIButton = null;
    
    viewDidLoad(){
        super.viewDidLoad();

        this.noEditHeaderView = MUIOutlet(this, "no-edit-header-view", "SupplierOrderDetailHeader");
        this.editHeaderView = MUIOutlet(this, "edit-header-view", "SupplierOrderDetailHeader");

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.importProductButton = MUIOutlet(this, "import-products-btn", "MUIButton");
        this.importProductButton.setAction(this, this.fetchSupplierProducts);
    }

    protected updateUI(){        
        this.editHeaderView.note = this.stockNote;
        this.noEditHeaderView.note = this.stockNote;
        this.editHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;
        this.noEditHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? false : true;

        this.fetchedResultsController = null;
        this.tableView.reloadData();                
    }

    protected fetchSupplierProducts() {
        let predicateFormat = "supplier.identifier = " + this.stockNote.destinationEntity.identifier;

        DBHelper.queryObjectsWithCompletion("SupplierProduct", null, MIOPredicate.predicateWithFormat(predicateFormat), ["product"], this, function(supplierProducts:[SupplierProduct]){
            for(let i=0; i<supplierProducts.length; i++) {
                let [price, discount] = DBHelper.costFromProductAndSupplier(supplierProducts[i].product, supplierProducts[i].supplier);
                
                if (price == null && discount == null) continue;

                DBHelper.createSupplierOrderLine(supplierProducts[i].product,
                                                null,
                                                supplierProducts[i].product.measureType,
                                                0, 
                                                0, 
                                                0, 
                                                price,
                                                price,
                                                0, 
                                                this.stockNote);
            }
            DBHelper.saveMainContext();
        }); 
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('createdAt', true)];

        let predicateFormat = 'note.identifier == ' + this.stockNote.identifier;
        if (this.searchString != null) {
            predicateFormat += " AND productName CONTAINS '" + this.searchString + "'";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockNoteLine", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["tax", "inputFormat"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }   
    
    supplierFromNote(sender){
        return this.stockNote.destinationEntity;
    }

}