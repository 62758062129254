//
// Generated class PhoneNumber
//

/// <reference path="PhoneNumber_ManagedObject.ts" />

class PhoneNumber extends PhoneNumber_ManagedObject
{

}
