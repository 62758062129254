//
// Generated class IntegratorsConfig
//

/// <reference path="IntegratorsConfig_ManagedObject.ts" />

class IntegratorsConfig extends IntegratorsConfig_ManagedObject
{

}
