
class CashDeskSummaryCell extends MUITableViewCell
{
    private titleLabel:MUILabel = null;

    private value1Label:MUILabel = null;
    private value2Label:MUILabel = null;
    private value3Label:MUILabel = null;
    private value4Label:MUILabel = null;
    private value5Label:MUILabel = null;
    private value6Label:MUILabel = null;
    private value7Label:MUILabel = null;
    private value8Label:MUILabel = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter as MIONumberFormatter;
    
    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');
        this.value1Label = MUIOutlet(this, 'value1-lbl', 'MUILabel');
        this.value2Label = MUIOutlet(this, 'value2-lbl', 'MUILabel');
        this.value3Label = MUIOutlet(this, 'value3-lbl', 'MUILabel');
        this.value4Label = MUIOutlet(this, 'value4-lbl', 'MUILabel');
        this.value5Label = MUIOutlet(this, 'value5-lbl', 'MUILabel');
        this.value6Label = MUIOutlet(this, 'value6-lbl', 'MUILabel');
        this.value7Label = MUIOutlet(this, 'value7-lbl', 'MUILabel');
        this.value8Label = MUIOutlet(this, 'value8-lbl', 'MUILabel');
    }

    set item(i:CashDeskSummaryItem){                
        let bold = (i.isTotal == true || i.isSubtotal == true) ? true : false;
        
        this.setValue(this.titleLabel, MIOLocalizeString(i.title, i.title), bold, 0);

        this.setValue(this.value1Label, i.value1, bold, i.formatCellType);
        this.setValue(this.value2Label, i.value2, bold, i.formatCellType);
        this.setValue(this.value3Label, i.value3, bold, i.formatCellType);
        this.setValue(this.value4Label, i.value4, bold, i.formatCellType);
        this.setValue(this.value5Label, i.value5, bold, i.formatCellType);
        this.setValue(this.value6Label, i.value6, bold, i.formatCellType);
        this.setValue(this.value7Label, i.value7, bold, i.formatCellType);
        this.setValue(this.value8Label, i.value8, bold, i.formatCellType);
    }

    private setValue(control:MUILabel, value, bold:boolean, cellType){
        if (control == null) return;
        
        if (cellType == 1) {
            control.text = this.nf.stringFromNumber(value);
        }
        else if (cellType == 2) {
            control.text = this.cf.stringFromNumber(value);
        }
        else {
            control.text = value;
        }
        
        if (bold) {
            MUICoreLayerAddStyle(control.layer, "strong");
        }
        else {
            MUICoreLayerRemoveStyle(control.layer, "strong");
        }
    }
}
