class SectionCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
    }

    set item (item:UserSection){
        this.nameLabel.text = MIOLocalizeString(item.name, item.name);
    }
}