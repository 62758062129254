//
// Generated class CashDeskEntity
//

/// <reference path="CashDeskEntity_ManagedObject.ts" />

enum CashDeskEntityType {
    TypeCompany,
    TypePlace,
    TypeDevice
}

class CashDeskEntity extends CashDeskEntity_ManagedObject {

    parentID:string = null;

    setObject(item) {
        this.type = item['type'];
        if (this.type == 3) {
            this.identifier = "MNG_" + item['id'];
        }
        else {
            this.identifier = item['id'];        
        }
        this.name = item['name'];        
        this.total = item['total'];
        
        this.idApp = item['id_app'];

        // no managed property
        this.parentID = item['parent'];        
    }

    get indexPathString() {
        if(this.type == -1) return '0.';
        else return "1." + this._followParent(this.parent, this.name);                        
    }
        
    private _followParent(parent, name) {
        if (parent == null) return name;
        return this._followParent(parent.parent, parent.name) + "." + name;
    }
}
