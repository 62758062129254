


class WorkerDetailViewController extends MUIViewController
{
    private segmentedControl:MUISegmentedControl = null;
    private sectionsPageController:MUIPageController = null;
    private actionButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private addSessionButton:MUIButton = null;
        
    private wdgvc:WorkerDetailGeneralViewController = null;
    private workerSessionViewController:WorkerSessionViewController = null;
    private workerLoginViewController:WorkerLoginViewController = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private avc = null;

    viewDidLoad(){
        super.viewDidLoad();
       
        //SEGMENTED BUTTON
        this.sectionsPageController = MUIOutlet(this, 'wdpage_controller', 'MUIPageController');
        this.segmentedControl = MUIOutlet(this, 'segmented_workers', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function (control, index) {
            this.sectionsPageController.showPageAtIndex(index);
            this.addSessionButton.hidden = index == 0 || index == 2 ? true : false;
            this.saveButton.hidden = index == 2 ? true : false;
        });

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, this.actionButtonPress);

        this.saveButton = MUIOutlet(this, 'wdv_nb_save_btn','MUIButton');
        this.saveButton.setAction(this, function(){
           DBHelper.saveMainContext();
        });

        this.addSessionButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addSessionButton.hidden = true;
        this.addSessionButton.setAction(this, function(){
            this.showAddWorkSessionAlert();
        });      
        
        this.wdgvc = new WorkerDetailGeneralViewController("worker-detail-general-view");
        this.wdgvc.initWithResource("layout/workers/WorkerDetailGeneralView.html");
        this.wdgvc.navigationController = this.navigationController;        
        this.sectionsPageController.addPageViewController(this.wdgvc);
       
        // this.wdpvc = new WorkerDetailPlaceViewController("worker-detail-place-view");
        // this.wdpvc.initWithResource("layout/workers/WorkerDetailPlaceView.html");
        // this.wdpvc.navigationController = this.navigationController;        
        // this.sectionsPageController.addPageViewController(this.wdpvc);

        this.workerSessionViewController = new WorkerSessionViewController("worker-sessions-view");
        this.workerSessionViewController.initWithResource("layout/workers/WorkerSessionsView.html");
        this.workerSessionViewController.showFromReport = false;
        this.workerSessionViewController.navigationController = this.navigationController;
        this.sectionsPageController.addPageViewController(this.workerSessionViewController);

        this.workerLoginViewController = new WorkerLoginViewController("worker-login-view");
        this.workerLoginViewController.initWithResource("layout/workers/WorkerLoginView.html");
        this.workerLoginViewController.navigationController = this.navigationController;
        this.sectionsPageController.addPageViewController(this.workerLoginViewController);
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI(); 
    }

    private worker:Employee = null;
    private _workerID = null;
    set workerID(value) {
        let predicate = MIOPredicate.predicateWithFormat("identifier == " + value);
        let relationships = ['address','phone','mobilePhone'];
        DBHelper.queryObjectsWithCompletion("Employee", null, predicate, relationships, this, function(objects){
            if (objects.length > 0) {
                this.worker = objects[0];
                this.updateUI();
            }
        });                        
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this.worker == null) return;
            
        this.wdgvc.worker = this.worker;
        //this.wdpvc.worker = this.worker;
        this.workerSessionViewController.worker = this.worker;
        this.workerLoginViewController.worker = this.worker;
    }

    private showAddWorkSessionAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Add Session", "Fill up the values", MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let now = MIODateNow();
            textField.placeholderText = "Begin date time";
            textField.text = ad.dateTimeFormatter.stringFromDate(now);
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let now = MIODateNow();
            textField.placeholderText = "End date time";
            textField.text = ad.dateTimeFormatter.stringFromDate(now);
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(){
            this.addWorkSession(avc.textFields[0].text, avc.textFields[1].text);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "Cancel"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private addWorkSession(beginDateString:string, endDateString:string){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let beginDate = ad.dateTimeFormatter.dateFromString(beginDateString);
        let endDate = ad.dateTimeFormatter.dateFromString(endDateString);

        if (beginDate == null) {
            AppHelper.showErrorMessage(this, "Error", "Begin date can't be null");
            return;
        }

        DBHelper.createWorkSession(beginDate, endDate, this.worker);
        DBHelper.saveMainContext();
    }

    private actionButtonPress(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Actions", "Select an option", MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DELETE", "DELETE"), MUIAlertActionStyle.Destructive, this, function(){
            DBHelper.deleteObjectFromMainContext(this.worker, true);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }
}