//
// Generated class Application
//

/// <reference path="Application_ManagedObject.ts" />

class Application extends Application_ManagedObject
{

}
