
class BankAccountListViewController extends MUIViewController
{
    private bankButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private addButton:MUIButton = null;

    private tableView:MUITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.bankButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.bankButton.setAction(this, function(){
            this.showNoItemSelectionViewController();
            this.navigationController.popViewController(true);
        });        

        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            this.addAccount();
        });

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;          
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private bankType:BankType = null;
    set type (index){
        this.bankType = index;
        this.updateUI();        
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;
        this.login();
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        // let sections = this.fetchedResultsController.sections.length;
        // return sections;
        return 1;
    }
    
    numberOfRowsInSection(tableview, section){
        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();        

        if (this.type == 1) return 0;

        return 1;
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){                                
        let cell = tableview.dequeueReusableCellWithIdentifier('BankAccountCell') as BankAccountCell;        
        //let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockNoteLine;
        
        cell.item = "Test Account";

        return cell;            
    }
    
    private selectedItem = null;
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){        
        this.selectedItem = indexPath.row;
        this.showSelectedItemViewController();
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        // let ad = MUIWebApplication.sharedInstance().delegate;

        // let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('createdAt', true)];
        // let predicateFormat = 'note.identifier == ' + this.stockNote.identifier;
        // let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockNoteLine", sortDescriptors, predicateFormat);
        // fetchRequest.relationshipKeyPathsForPrefetching = ["product.taxBuy", "product.tax", "product.stockInputFormats", "product.supplierProducts"];
        
        // let fetchedResultsController = new MIOFetchedResultsController();
        // fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        // fetchedResultsController.delegate = this;
    
        // fetchedResultsController.performFetch();
    
        // this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){        
        this.tableView.reloadData();
    }    

    private detailViewController:BankAccountDetailViewController = null;
    private showSelectedItemViewController(){
        if (this.detailViewController == null) {
            this.detailViewController = new BankAccountDetailViewController("bank-account-detail-view");
            this.detailViewController.initWithResource("layout/bankaccount/BankAccountDetailView.html");
        }

        this.detailViewController.bankType = this.selectedItem;
        this.navigationController.splitViewController.showDetailViewController(this.detailViewController);
    }

    private showNoItemSelectionViewController(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("BankAccount", NoItemSelectedViewControllerType.BankAccount);
        this.navigationController.splitViewController.showDetailViewController(vc);   
    }

    private login(){
        if (this.bankType == 1) this.loginBBVA();
    }
    
    private token = null;
    private tokenType = null;
    private accessToken = null;
    private loginBBVA(){
        let request = new MWSJSONRequest();
        request.initWithURL(MIOURL.urlWithString("https://connect.bbva.com/token?grant_type=client_credentials"), null, "POST");
        request.setHeaderValue("Basic YXBwLmJidmEuZGx3ZWJtYW5hZ2VyOjF5eTZSYzJNKm9mOW1oVHlHdGtoNTJRRzB4OHN4OFRhMzZ2Q3hFQVpmbFNJS2ZZTXdBNnNZd2lMa1lsNDBITnM=", "Authorization");
        request.setHeaderValue("application/json", "Content-Type");
        

        request.execute(this, function (code, json) {
            this.token = json["access_token"];
            this.tokenType = json["token_type"];
            this.accessToken = this.tokenType + " " + this.token;
            MIOLog("LOGIN OK");
        });
    }

    private addAccount(){        

        let request = new MWSJSONRequest();
        request.initWithURL(MIOURL.urlWithString("https://apis.bbva.com/business-accounts-sbx/v1"), this.getUserInfo(), "POST");
        request.setHeaderValue(this.accessToken, "Authorization");
        request.setHeaderValue(MIOUUID.UUID().UUIDString, "X-Unique-Transaction-ID");        
        request.setHeaderValue("12.03.1.3", "X-Customer-IP");        

        request.execute(this, function (code, json) {
            MIOLog(json);
        });
    }

    private getUserInfo(){
        let userInfo = {
            "first_name": "Paul",
            "middle_name": "Antonio",
            "last_name": "Smith",
            "ssn": "5433837071287255",
            "dob": "1980-02-15",
            "contact": [
              {
                "type": "phone",
                "value": "+14153214567"
              },
              {
                "type": "email",
                "value": "paulsmith@gmail.com"
              }
            ],
            "citizenship_status": "us_citizen",
            "citizenship_country": "USA",
            "occupation": "finance_accounting_tax",
            "income": [
              "SALARY",
              "INVESTMENTS",
              "INHERITANCE",
              "PROPERTY_SALE",
              "DIVORCE_SETTLEMENT",
              "LIFE_INSURANCE",
              "OTHER"
            ],
            "expected_activity": [
              "CASH",
              "CHECK",
              "DOMESTIC_ACH",
              "DOMESTIC_WIRE_TRANSFER",
              "INTERNATIONAL_ACH",
              "INTERNATIONAL_WIRE_TRANSFER"
            ],
            "address": [
              {
                "type": "LEGAL",
                "line1": "201 mission st",
                "city": "san francisco",
                "state": "CA",
                "zip_code": "94104"
              }
            ],
            "identification": [
              {
                "document": "passport",
                "issuing_state": "ca",
                "number": "123456789",
                "issuing_country": "USA",
                "issued_date": "2015-01-01",
                "expiration_date": "2020-01-01"
              }
            ],
            "pep": {
              "association": "no"
            }

        }

        return userInfo;
    }
}