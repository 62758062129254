
enum NoteDetailHeaderViewDateType
{
    CreateDate,
    DocumentDate,
    ValidationDate,
}

class NoteDetailHeaderView extends MUIView
{
    protected processButton:MUIButton = null;
    protected stockNote:StockNote = null;

    awakeFromHTML(){
        this.processButton = MUIOutlet(this, "change-status-btn", "MUIButton");
        if (this.processButton != null) this.processButton.setAction(this, this.processStockNote);
    }

    protected setupDocumentDateTextField(textField:MUITextField){
        if (textField == null) return;

        textField.setOnChangeText(this, function(control:MUITextField, value:string){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            this.stockNote.documentDate = ad.dateTimeFormatter.dateFromString(value);    
        });
    }

    protected setupValidationDateTextField(textField:MUITextField){
        if (textField == null) return;        

        textField.setOnChangeText(this, function(control:MUITextField, value:string){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            this.stockNote.stockDate = ad.dateTimeFormatter.dateFromString(value);
        });
    }

    protected setupCalendarButton(button:MUIButton, type:NoteDetailHeaderViewDateType){
        if (button == null) return;

        button.setAction(this, function(){

            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;        
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;
            dp.tag = type;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = button.frame;
            pc.sourceView = button;

            AppHelper.sharedInstance().presentViewController(dp, true);
        });
    }

    didSelectDate(datePickerController:MUIDatePickerController, date:Date) {
        let source = datePickerController.popoverPresentationController.sourceView;
        if (datePickerController.tag == NoteDetailHeaderViewDateType.DocumentDate) {
            let newDate = this.newDateFromValue(this.stockNote.documentDate, date);            
            this.stockNote.documentDate = newDate;
            this.dateDidChange(datePickerController.tag, newDate);
        }
        else if (datePickerController.tag == NoteDetailHeaderViewDateType.ValidationDate) {    
            let newDate = this.newDateFromValue(this.stockNote.stockDate, date);                    
            this.stockNote.stockDate = date;
            this.dateDidChange(datePickerController.tag, newDate);
        }        
    }

    private newDateFromValue(oldDate:Date, newDate:Date):Date {
        if (oldDate == null) {
            let now = MIODateNow();            
            newDate.setHours(now.getHours());
            newDate.setMinutes(now.getMinutes());
            newDate.setSeconds(now.getSeconds());
            return newDate;
        }
        else {
            newDate.setHours(oldDate.getHours());
            newDate.setMinutes(oldDate.getMinutes());
            newDate.setSeconds(oldDate.getSeconds());
            return newDate;
        }
    }

    protected dateDidChange(type:NoteDetailHeaderViewDateType, date:Date){

    }

    protected processStockNote(){
        //check that stock note exists
        if (this.stockNote == null) {
            return;
        }

        //check that the stock note has lines ("El Documento esta Vacío, Añade una Línea")
        if (this.stockNote.lines.length <= 0) {
            AppHelper.showErrorMessage(null, "Info", "The document is empty, add a new line");
            return;
        }

        //if supplier note, show unprocess alert
        if (this.stockNote.status == StockNoteStatus.Processed) {
            if (this.stockNote.type != StockNoteType.SupplierNote) {
                AppHelper.showErrorMessage(null, "Info", "You can't process an already processed note");
            }                    
            else {
                this.showUnporcessAlert();
            }
            return;
        }

        //if Supplier note - check for lines and that measureType != 0
        if (this.stockNote.type == StockNoteType.SupplierNote && this.stockNote.status != StockNoteStatus.Processed) {
            //check all lines for measureType of 0
            let lines = this.stockNote.lines.allObjects;
            for (let index = 0; index < lines.length; index++){
                let l = lines[index] as StockNoteLine;

                //check if measureType is 2 (no unit format)
                if (l.measureType == 0){
                    AppHelper.showErrorMessage(null, "Info", "Please make sure all products have a correct unit format");
                    return;
                }
                //check for price, product price, and total as null then set to 0 (null product price error catch)
                if (l.price == null) {
                    this.stockNote.lines.allObjects[index].price = 0;
                }
                if (l.productPrice == null) {
                    this.stockNote.lines.allObjects[index].productPrice = 0;
                }
                if (l.total == null) {
                    this.stockNote.lines.allObjects[index].total = 0;
                }
            }
        }

        //set date if not found
        let now = MIODateNow();

        //SHOULD THESE TWO BE SWITCHED???
        if (this.stockNote.stockDate == null) this.stockNote.stockDate = now;
        this.stockNote.validationDate = now;

        DBHelper.saveMainContextWithCompletion(this, function(){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            ad.webService.processStockNote(this.stockNote.identifier, this, function(code, data, response){
                if (code != 200) {   
                    AppHelper.showErrorMessage(null, "Error", "Couldn't process. Something went wrong!");
                }
                else {
                    let status = response["status"];
                    if (status == "Error") {
                        let errorMessage = "Couldn't process. Something went wrong!"
                        let errorCode = response["ErrorCode"];
                        if (errorCode != null) {
                            errorMessage = MIOLocalizeString("ERROR_STOCK_" + errorCode, "ERROR_STOCK_" + errorCode);
                        }
                        AppHelper.showErrorMessage(null, "Error", errorMessage);                        
                    }
                    else {
                        AppHelper.showInfoMessage(null, "Info", "The operation was sucessful");
                        this.updateUI(); 
                    } 
                }
            });    
        });
    }

    private updateUI(){
        //get the processed note and set a notification passing the proccessed note (to be read in the corresponding noteListView)
        DBHelper.queryObjectsWithCompletion("StockNote", null, MIOPredicate.predicateWithFormat("identifier == " + this.stockNote.identifier), [], this, function(objects){
            MIONotificationCenter.defaultCenter().postNotification("StockNoteDidProcess", this.stockNote);
        });
    }

    private showUnporcessAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Warning", "Are you sure you want to unprocess this document?", MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("UNPROCESS", "UNPROCESS"), MUIAlertActionStyle.Destructive, this, function(){
            this.unprocessNote();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        AppHelper.sharedInstance().presentViewController(avc, true);
    }

    private unprocessNote(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.unprocessStockNote(this.stockNote.identifier, this, function(code, data){
            if (code == 200) {   
                AppHelper.showInfoMessage(null, "Info", "The operation was sucessfull");
                this.updateUI();             
            }
            else {
                AppHelper.showErrorMessage(null, "Error", "Couldn't unprocess. Something went wrong!");
            }
        });   
    }
}