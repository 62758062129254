

class OutputNoteDetailViewController extends NoteDetailViewController
{
    private noEditHeaderView:OutputNoteDetailHeaderView = null;
    private editHeaderView:OutputNoteDetailHeaderView = null;    
    
    viewDidLoad(){
        super.viewDidLoad();

        this.noEditHeaderView = MUIOutlet(this, "no-edit-header-view", "OutputNoteDetailHeaderView");
        this.editHeaderView = MUIOutlet(this, "edit-header-view", "OutputNoteDetailHeaderView");
    }

    protected updateUI(){        
        this.editHeaderView.note = this.stockNote;
        this.noEditHeaderView.note = this.stockNote;
        this.editHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;
        this.noEditHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? false : true;

        this.fetchedResultsController = null;
        this.tableView.reloadData();                                    
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
        let predicateFormat = 'note.identifier == ' + this.stockNote.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockNoteLine", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["inputFormat"];        
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }    
}