

// Generated class ProductModifierCategory_ManagedObject

class ProductModifierCategory_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: multipleSelection
    set multipleSelection(value:boolean) {
        this.setValueForKey(value, 'multipleSelection');
    }
    get multipleSelection():boolean {
        return this.valueForKey('multipleSelection');
    }
    set multipleSelectionPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'multipleSelection');
    }
    get multipleSelectionPrimitiveValue():boolean {
        return this.primitiveValueForKey('multipleSelection');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: incremental
    set incremental(value:boolean) {
        this.setValueForKey(value, 'incremental');
    }
    get incremental():boolean {
        return this.valueForKey('incremental');
    }
    set incrementalPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'incremental');
    }
    get incrementalPrimitiveValue():boolean {
        return this.primitiveValueForKey('incremental');
    }

    // Property: allowNegatives
    set allowNegatives(value:boolean) {
        this.setValueForKey(value, 'allowNegatives');
    }
    get allowNegatives():boolean {
        return this.valueForKey('allowNegatives');
    }
    set allowNegativesPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'allowNegatives');
    }
    get allowNegativesPrimitiveValue():boolean {
        return this.primitiveValueForKey('allowNegatives');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: productModifierCategoryType
    set productModifierCategoryType(value:number) {
        this.setValueForKey(value, 'productModifierCategoryType');
    }
    get productModifierCategoryType():number {
        return this.valueForKey('productModifierCategoryType');
    }
    set productModifierCategoryTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productModifierCategoryType');
    }
    get productModifierCategoryTypePrimitiveValue():number {
        return this.primitiveValueForKey('productModifierCategoryType');
    }

    // Property: isRequired
    set isRequired(value:boolean) {
        this.setValueForKey(value, 'isRequired');
    }
    get isRequired():boolean {
        return this.valueForKey('isRequired');
    }
    set isRequiredPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isRequired');
    }
    get isRequiredPrimitiveValue():boolean {
        return this.primitiveValueForKey('isRequired');
    }

    // Property: ignoreModifierSource
    set ignoreModifierSource(value:boolean) {
        this.setValueForKey(value, 'ignoreModifierSource');
    }
    get ignoreModifierSource():boolean {
        return this.valueForKey('ignoreModifierSource');
    }
    set ignoreModifierSourcePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'ignoreModifierSource');
    }
    get ignoreModifierSourcePrimitiveValue():boolean {
        return this.primitiveValueForKey('ignoreModifierSource');
    }

    // Property: limitQuantity
    set limitQuantity(value:number) {
        this.setValueForKey(value, 'limitQuantity');
    }
    get limitQuantity():number {
        return this.valueForKey('limitQuantity');
    }
    set limitQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'limitQuantity');
    }
    get limitQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('limitQuantity');
    }

    // Property: isAbsorbent
    set isAbsorbent(value:boolean) {
        this.setValueForKey(value, 'isAbsorbent');
    }
    get isAbsorbent():boolean {
        return this.valueForKey('isAbsorbent');
    }
    set isAbsorbentPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAbsorbent');
    }
    get isAbsorbentPrimitiveValue():boolean {
        return this.primitiveValueForKey('isAbsorbent');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: category
    set category(value:Category) {
        this.setValueForKey(value, 'category');
    }
    get category():Category {
        return this.valueForKey('category') as Category;
    }

    // Relationship: productModifiers
    protected _productModifiers:MIOManagedObjectSet = null;
    get productModifiers():MIOManagedObjectSet {
        return this.valueForKey('productModifiers');
    }
    addProductModifiersObject(value:ProductModifier) {
        this._addObjectForKey(value, 'productModifiers');
    }
    removeProductModifiersObject(value:ProductModifier) {
        this._removeObjectForKey(value, 'productModifiers');
    }
}
