

enum CalendarSource {
    validFrom,
    validTo
}
class GuestlistDetailViewController extends MUIViewController {

    private weekday = 
      [
     "Monday",
     "Tuesday",
     "Wednesday",
     "Thursday",
     "Friday",
     "Saturday",
     "Sunday"];

    private _guestlist = null;
    
    private saveButton:MUIButton = null;
    private deleteButton:MUIButton = null;
    private tableView:MUITableView = null;

    private validFromDate = null;
    private validFromButton:MUIButton = null;
    private validFromLabel:MUILabel = null;
    private validFromHourTextField:MUITextField = null;
   // private validFromMinuteTextField = null;

    private validToDate = null;
    private validToButton:MUIButton = null;
    private validToLabel:MUILabel = null;
    private validToHourTextField:MUITextField = null;
   // private validToMinuteTextField = null;

    private hourFromTextField:MUITextField = null;
   // private minuteFromTextField = null;

    private hourToTextField:MUITextField = null;
  //  private minuteToTextField = null;

    private nameTextField:MUITextField = null;
    private isPrivateCheckButton:MUISwitchButton = null;

    private df =  MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;
    private sdf = MUIWebApplication.sharedInstance().delegate.serverDateFormatter;
    private tf = MUIWebApplication.sharedInstance().delegate.timeFormatter;

    viewDidLoad()
    {
        super.viewDidLoad();
        this.tf.timeStyle = MIODateFormatterStyle.ShortStyle;
        this.saveButton = MUIOutlet(this, 'gldv_save_btn','MUIButton');
        this.saveButton.setAction(this, function()
        {
           this.saveGuestlist();
        });

        this.deleteButton = MUIOutlet(this, 'gldv_delete_btn','MUIButton');
        this.deleteButton.setAction(this, function()
        {
           this.deleteGuestlist();
        });

        this.nameTextField = MUIOutlet(this, 'gldv_name_text','MUITextField');
        this.isPrivateCheckButton = MUIOutlet(this, 'gldv_switch_btn','MUISwitchButton');
        
        this.validFromButton = MUIOutlet(this, 'gldv_valid_from_calendar_btn','MUIButton');
        this.validFromButton.setAction(this, function()
        {
           var dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            var pc = dp.popoverPresentationController;
            pc.sourceRect = this.validFromButton.frame;
            pc.sourceView = this.validFromButton;

            this.presentViewController(dp, true);
        });

        this.validFromLabel = MUIOutlet(this, 'gldv_valid_from_lbl','MUILabel');
       
        
        this.validFromHourTextField = MUIOutlet(this, 'gldv_valid_from_hour','MUITextField');
        this.validFromHourTextField.formatter = this.tf;
        this.validFromHourTextField.setOnChangeText(this, function(control, text){
            let date = this.tf.dateFromString(text);
            this.validFromDate.setHours(date ? date.getHours(): 0);
            this.validFromDate.setMinutes(date ? date.getMinutes(): 0);
        });
       
        this.hourFromTextField = MUIOutlet(this, 'gldv_hour_from_hour','MUITextField');
        this.hourFromTextField.formatter = this.tf;
       
        //VALID TO
        this.validToButton = MUIOutlet(this, 'gldv_valid_to_calendar_btn','MUIButton');
        this.validToButton.setAction(this, function()
        {
           var dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            var pc = dp.popoverPresentationController;
            pc.sourceRect = this.validToButton.frame;
            pc.sourceView = this.validToButton;

            this.presentViewController(dp, true);
        });

        this.validToLabel = MUIOutlet(this, 'gldv_valid_to_lbl','MUILabel');
        
        this.validToHourTextField = MUIOutlet(this, 'gldv_valid_to_hour','MUITextField');
        this.validToHourTextField.formatter = this.tf;
        this.validToHourTextField.setOnChangeText(this, function(control, text){
            let date = this.tf.dateFromString(text);
            this.validToDate.setHours(date ? date.getHours(): 0);
            this.validToDate.setMinutes(date ? date.getMinutes(): 0);
        });

        this.hourToTextField = MUIOutlet(this, 'gldv_hour_to_hour','MUITextField');
        this.hourToTextField.formatter = this.tf;
        
        this.tableView = MUIOutlet(this, 'gldv_tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.reloadData();
        
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.updateUI();
    }
    set guestlist(value)
    {
        this._guestlist = value;
        this.updateUI();
    }

    numberOfSections(tableview)
    {
        return 1;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        return 7;
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell = tableview.dequeueReusableCellWithIdentifier('WeekdayCell');
        
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;

        let weekdays = this._guestlist.weekDays;
        var day = 1 << indexPath.row;
        let isActive = weekdays & day;
        if(isActive)
            cell.setAccessoryType(MUITableViewCellAccessoryType.Checkmark);
        else
            cell.setAccessoryType(MUITableViewCellAccessoryType.None);

        cell.nameLabel.text = this.weekday[indexPath.row];

        return cell;
    }
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        let weekdays = this._guestlist.weekDays;
        var day = 1 << indexPath.row;
        let isActive = weekdays & day;

        let cell = tableView.cellAtIndexPath(indexPath);
        
        if(!isActive)
            cell.setAccessoryType(MUITableViewCellAccessoryType.Checkmark);
        else
            cell.setAccessoryType(MUITableViewCellAccessoryType.None);

        this._guestlist.weekDays = weekdays ^ day;
    }
    
    private updateUI()
    {
        if(this.viewIsLoaded && this._guestlist != null)
        {
            this.tableView.reloadData();

            this.nameTextField.text = this._guestlist.name;
            this.validFromDate = this._guestlist.activeFrom;
            this.validFromLabel.text = this.df.stringFromDate(this.validFromDate);
            this.validFromHourTextField.text = this.tf.stringFromDate(this.validFromDate);
           // this.validFromMinuteTextField.text = this.validFromDate.getMinutes();

            this.validToDate = this._guestlist.activeTo;
            this.validToLabel.text = this.df.stringFromDate(this.validToDate);
            this.validToHourTextField.text = this.tf.stringFromDate(this.validToDate);
           // this.validToMinuteTextField.text = this.validToDate.getMinutes();

            let hourFrom = this._guestlist.hourFrom;
            this.hourFromTextField.text = hourFrom;
            
            let hourTo = this._guestlist.hourTo;
            this.hourToTextField.text = hourTo;
          
            this.isPrivateCheckButton.setOn(this._guestlist.isPrivate);
        }
    }

    private saveGuestlist()
    {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        
        this._guestlist.name = this.nameTextField.text;
        this._guestlist.activeFrom = this.validFromDate;
        this._guestlist.activeTo = this.validToDate;
        let hourFrom = this.hourFromTextField.text;
        this._guestlist.hourFrom = hourFrom;
        let hourTo = this.hourToTextField.text;
        this._guestlist.hourTo = hourTo;
        this._guestlist.isPrivate = this.isPrivateCheckButton.on;

        moc.save();
       
        //MIONotificationCenter.defaultCenter().postNotification('guestListDidChange', null);
    }

    private deleteGuestlist()
    {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        moc.deleteObject(this._guestlist);
        this._guestlist = null;
        moc.save();
    }

    didSelectDate(datePickerController:MUIDatePickerController, date)
    {
        let source = datePickerController.popoverPresentationController.sourceView;
        
        if(source == this.validFromButton) {
            this.validFromDate = date;
            let time = this.tf.dateFromString(this.validFromHourTextField.text);
            this.validFromDate.setHours(time ? time.getHours() : 0);
            this.validFromDate.setMinutes(time ? time.getMinutes() : 0);
            this.validFromDate.setSeconds(0);
            this.validFromLabel.text = this.df.stringFromDate(this.validFromDate);
            this.validFromHourTextField.text = this.tf.stringFromDate(this.validFromDate);

        }
        else {
            this.validToDate = date;
            let time = this.tf.dateFromString(this.validToHourTextField.text);
            this.validToDate.setHours(time ? time.getHours() : 0);
            this.validToDate.setMinutes(time ? time.getMinutes() : 0);
            this.validToDate.setSeconds(0);
            this.validToLabel.text = this.df.stringFromDate(this.validToDate);
            this.validToHourTextField.text = this.tf.stringFromDate(this.validToDate);

        }
    }
}