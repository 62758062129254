//
// Generated class UserPermission
//

/// <reference path="UserPermission_ManagedObject.ts" />

class UserPermission extends UserPermission_ManagedObject
{

}
