

// Generated class Offer_ManagedObject

class Offer_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: value
    set value(value:number) {
        this.setValueForKey(value, 'value');
    }
    get value():number {
        return this.valueForKey('value');
    }
    set valuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'value');
    }
    get valuePrimitiveValue():number {
        return this.primitiveValueForKey('value');
    }

    // Property: rateID
    set rateID(value:string) {
        this.setValueForKey(value, 'rateID');
    }
    get rateID():string {
        return this.valueForKey('rateID');
    }
    set rateIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'rateID');
    }
    get rateIDPrimitiveValue():string {
        return this.primitiveValueForKey('rateID');
    }

    // Property: excludeOfferProducts
    set excludeOfferProducts(value:boolean) {
        this.setValueForKey(value, 'excludeOfferProducts');
    }
    get excludeOfferProducts():boolean {
        return this.valueForKey('excludeOfferProducts');
    }
    set excludeOfferProductsPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'excludeOfferProducts');
    }
    get excludeOfferProductsPrimitiveValue():boolean {
        return this.primitiveValueForKey('excludeOfferProducts');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }

    // Relationship: offerProducts
    protected _offerProducts:MIOManagedObjectSet = null;
    get offerProducts():MIOManagedObjectSet {
        return this.valueForKey('offerProducts');
    }
    addOfferProductsObject(value:OfferProduct) {
        this._addObjectForKey(value, 'offerProducts');
    }
    removeOfferProductsObject(value:OfferProduct) {
        this._removeObjectForKey(value, 'offerProducts');
    }
}
