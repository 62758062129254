//
// Generated class InventoryTemplate
//

/// <reference path="InventoryTemplate_ManagedObject.ts" />

class InventoryTemplate extends InventoryTemplate_ManagedObject
{

}
