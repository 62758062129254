

// Generated class BusinessLicense_ManagedObject

class BusinessLicense_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: companyName
    set companyName(value:string) {
        this.setValueForKey(value, 'companyName');
    }
    get companyName():string {
        return this.valueForKey('companyName');
    }
    set companyNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'companyName');
    }
    get companyNamePrimitiveValue():string {
        return this.primitiveValueForKey('companyName');
    }

    // Property: type
    set type(value:string) {
        this.setValueForKey(value, 'type');
    }
    get type():string {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():string {
        return this.primitiveValueForKey('type');
    }

    // Property: userName
    set userName(value:string) {
        this.setValueForKey(value, 'userName');
    }
    get userName():string {
        return this.valueForKey('userName');
    }
    set userNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'userName');
    }
    get userNamePrimitiveValue():string {
        return this.primitiveValueForKey('userName');
    }

    // Property: userEmail
    set userEmail(value:string) {
        this.setValueForKey(value, 'userEmail');
    }
    get userEmail():string {
        return this.valueForKey('userEmail');
    }
    set userEmailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'userEmail');
    }
    get userEmailPrimitiveValue():string {
        return this.primitiveValueForKey('userEmail');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
}
